import { memo } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import { MembersListModal } from '.';
import Avatar from '../Avatar';

const ProjectMembers = ({
  members,
  avatarSize,
  totalMembers = 0,
  projectId,
}) => {
  const activeMembers =
    members.length > 0
      ? members.filter(function (el) {
          return el.status !== 'inactive';
        })
      : [];
  return (
    <div className="avatar-group position-relative" style={{ zIndex: 2 }}>
      {activeMembers.slice(0, 4).map((member, memberIndex) => (
        <Tippy
          content={
            member.lastName && member.lastName !== null
              ? `${member.firstName} ${member.lastName}`
              : `${member.firstName}`
          }
          theme="light"
          key={memberIndex}
        >
          <div className={avatarSize === 'lg' ? 'avatar' : 'avatar avatar-xs'}>
            <Avatar
              firstName={member.firstName}
              lastName={member.lastName}
              imgPath={member.avatar ? member.avatar : ''}
            />
          </div>
        </Tippy>
      ))}

      {totalMembers > 3 && (
        <div className={avatarSize === 'lg' ? 'avatar' : 'avatar avatar-xs'}>
          <MembersListModal btnSize={avatarSize} projectId={projectId} />
        </div>
      )}
    </div>
  );
};

ProjectMembers.propTypes = {
  members: PropTypes.array.isRequired,
  avatarSize: PropTypes.string,
  totalMembers: PropTypes.number,
  projectId: PropTypes.string,
};

export default memo(ProjectMembers);
