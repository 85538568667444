import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

const NotesModal = ({ notesList, bottomSpacing = true }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button
        variant="light"
        type="button"
        size="sm"
        className={bottomSpacing ? 'btn-white mb-3' : 'btn-white'}
        onClick={handleShow}
      >
        Notes
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="pt-4" closeButton>
          <Modal.Title>Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="list-group list-group-flush my-n3">
            {notesList.map((note, index) => {
              let noteContent;
              try {
                noteContent = decodeURIComponent(note.note);
              } catch (e) {
                noteContent = note.note;
              }
              return (
                <div key={index} className="list-group-item">
                  <h4 className="mb-1">{noteContent}</h4>
                  <p className="card-text small text-muted">
                    <time>
                      {note.createdDate
                        ? moment(note.createdDate).format(
                            'MMM DD, YYYY h:mm:ss A'
                          )
                        : moment(note.date).format('MMM DD, YYYY h:mm:ss A')}
                    </time>
                  </p>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

NotesModal.propTypes = {
  notesList: PropTypes.array,
  bottomSpacing: PropTypes.bool,
};

export default NotesModal;
