import { PER_PAGE_LIMIT } from '../constants';

const initialState = {
  isLoading: false,
  projects: [],
  memberProjects: [],
  metadata: {},
  error: null,
};

export const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCHING_PROJECTS':
      return {
        ...state,
        isLoading: true,
      };
    case 'SET_PROJECTS':
      return {
        ...state,
        isLoading: false,
        projects: action.payload.projects ? [...action.payload.projects] : [],
        metadata: action.payload.metadata ? action.payload.metadata : {},
      };
    case 'SET_MEMBER_PROJECTS':
      return {
        ...state,
        isLoading: false,
        memberProjects: action.payload.projects
          ? [...action.payload.projects]
          : [],
      };
    case 'FETCH_PROJECTS_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const activeProjects = (
  state = {
    isLoading: false,
    page: 1,
    loadMore: true,
    list: [],
    metadata: {},
    searchParam: '',
  },
  action
) => {
  switch (action.type) {
    case 'FETCH_PROJECTS_LIST':
      return {
        ...state,
        isLoading: true,
      };
    case 'SET_PROJECTS_LIST':
      return {
        ...state,
        isLoading: false,
        list:
          state.page === 1
            ? [...action.payload.projects]
            : [...state.list, ...action.payload.projects],
        loadMore: action.payload.metadata.total / PER_PAGE_LIMIT > state.page,
        page: state.loadMore ? state.page + 1 : state.page,
        metadata: action.payload.metadata,
      };
    case 'SET_PROJECT_SEARCH_PARAM':
      return {
        ...state,
        page: 1,
        searchParam: action.payload,
      };
    case 'FETCH_PROJECTS_LIST_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case 'NO_PROJECTS':
      return {
        ...state,
        isLoading: false,
        page: 1,
        loadMore: false,
        list: [],
        metadata: {},
      };
    case 'ARCHIVE_PROJECT':
      return {
        ...state,
        list: state.list.filter((item) => item._id !== action.payload),
      };
    case 'RESET_PROJECTS_LIST':
      return {
        isLoading: false,
        page: 1,
        loadMore: true,
        list: [],
        metadata: {},
        searchParam: '',
      };
    default:
      return state;
  }
};

export const selectedProjectsReducer = (
  state = { isLoading: true, detectMemberDataChange: 0 },
  action
) => {
  switch (action.type) {
    case 'SELECTED_PROJECT':
      return {
        ...action.payload,
        detectMemberDataChange: state.detectMemberDataChange + 1,
        isLoading: false,
      };
    case 'FETCH_PROJECT_ERROR':
      return {
        ...state,
        isLoading: false,
      };
    case 'REMOVE_SELECTED_PROJECT':
      return {
        isLoading: true,
        detectMemberDataChange: 0,
      };
    default:
      return state;
  }
};
