import PropTypes from 'prop-types';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';

const AddDailyLimit = ({ handleUpdateDailyLimit, dailyLimit, role }) => (
  <Card>
    {role !== 'creator' && (
      <Card.Body>
        <div>
          <p>The maximum number of hours that can be logged in a day.</p>
          <h4> Daily Limit : {dailyLimit ? `${`${dailyLimit} `}hours` : ``}</h4>
        </div>
      </Card.Body>
    )}
    {role === 'creator' && (
      <Card.Body className="pt-4">
        Configure how many hours a day you allow your members to log.
        <Formik
          initialValues={{
            dailyLimit,
          }}
          enableReinitialize
          validationSchema={Yup.object({
            dailyLimit: Yup.number()
              .positive()
              .required('Please enter daily limit hours')
              .min(1, 'Daily limit hours must be greater than or equal to 1')
              .max(24, 'Daily limit hours must be less than or equal to 24'),
          })}
          onSubmit={(values) => {
            handleUpdateDailyLimit(values);
          }}
        >
          {(formikprops) => (
            <div className="col-12 col-md-12">
              <Form
                onSubmit={formikprops.handleSubmit}
                className="needs-validation"
              >
                <div className="px-3 py-2">
                  <Row>
                    <Col className="px-0 col-auto me-auto h-50">
                      <div className="input-group input-group-sm mt-3">
                        <span className="me-2">Daily limit</span>
                        <Field
                          className="form-control form-control-sm"
                          type="number"
                          name="dailyLimit"
                          style={{
                            maxWidth: '42px',
                          }}
                        />
                        <span className="ms-2">hours</span>
                      </div>
                      <ErrorMessage
                        name="dailyLimit"
                        render={(msg) => (
                          <small className="text-danger p-0 pt-2 mt-3">
                            {msg}
                          </small>
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Button
                      className="mt-4"
                      size="sm"
                      style={{
                        height: '28px',
                        maxWidth: '50px',
                      }}
                      type="submit"
                      disabled={!(formikprops.isValid && formikprops.dirty)}
                    >
                      Save
                    </Button>
                  </Row>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </Card.Body>
    )}
  </Card>
);
AddDailyLimit.propTypes = {
  handleUpdateDailyLimit: PropTypes.func,
  dailyLimit: PropTypes.number,
  role: PropTypes.string,
};
export default AddDailyLimit;
