import React, { useContext } from 'react';
import {
  useAccordionToggle,
  AccordionContext,
  Nav,
  Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

const AccordionToggleWithButton = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const isCurrentEventKey = currentEventKey === eventKey;

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey, isCurrentEventKey)
  );

  return (
    <Nav.Link
      style={{
        position: 'relative',
        top: '-6px',
        display: 'inline-block',
      }}
      className={`${
        isCurrentEventKey ? 'opened' : 'collapsed'
      } px-0 hd-accordion-button m-0`}
      onClick={decoratedOnClick}
    >
      <Button
        className="btn-sm btn-light"
        style={{
          position: 'absolute',
          padding: '10.5px',
          top: '6px',
          left: '-10px',
        }}
      />
      {children}
    </Nav.Link>
  );
};

AccordionToggleWithButton.propTypes = {
  children: PropTypes.any,
  eventKey: PropTypes.any,
  callback: PropTypes.any,
};

export default AccordionToggleWithButton;
