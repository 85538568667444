import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { fetchMembers, setSearchParam } from '../../actions/team';
import { PER_PAGE_LIMIT } from '../../constants';

const MemberSearch = () => {
  const dispatch = useDispatch();
  const teamData = useSelector((state) => state.team);

  const handleSearch = () => {
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      page: teamData.page,
      limit: PER_PAGE_LIMIT,
      summaryList: false,
      filter: JSON.stringify({
        memberStatus: 'active',
        keyword: teamData.searchParam,
      }),
    };
    dispatch(fetchMembers(params));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  return (
    <div className="input-group mb-3">
      <input
        className="form-control"
        placeholder="Search team member"
        type="text"
        aria-label="Search team member"
        aria-describedby="Search team member"
        onChange={(e) => dispatch(setSearchParam(e.target.value))}
        onKeyDown={handleKeyDown}
      />
      <Button variant="primary" onClick={handleSearch}>
        <span className="mdi mdi-magnify" />
      </Button>
    </div>
  );
};

export default MemberSearch;
