const initialState = {
  showTimerWidget: false,
  isRunning: false,
  startTime: null,
  currentTime: new Date(),
  checkedInProject: {
    id: '',
    name: '',
  },
  checkedInTask: {
    id: '',
    name: '',
  },
  worksessionId: '',
  projectTasksList: [],
};

export const timerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'START_TIMER':
      return {
        ...state,
        isRunning: true,
        startTime: new Date().toString(),
        currentTime: new Date(),
      };
    case 'UPDATE_TIMER':
      return {
        ...state,
        currentTime: new Date(),
      };
    case 'STOP_TIMER':
      return {
        ...state,
        isRunning: false,
        startTime: null,
      };
    case 'SET_SHOW_TIMER_WIDGET':
      return {
        ...state,
        showTimerWidget: !state.showTimerWidget,
      };
    case 'SET_CHECK_IN_PROJECT':
      return {
        ...state,
        checkedInProject: {
          id: action.payload.projectId,
          name: action.payload.projectName,
        },
      };
    case 'SET_CHECK_IN_TASK':
      return {
        ...state,
        checkedInTask: {
          id: action.payload.taskId,
          name: action.payload.taskName,
        },
      };
    case 'SET_CHECKED_IN_PROJECT_TASKS_LIST':
      return {
        ...state,
        projectTasksList: [...action.payload],
      };
    case 'SET_WORKSESSION_ID':
      return {
        ...state,
        worksessionId: action.payload.wsId,
      };
    case 'RESET_WIDGET':
      return {
        ...initialState,
        showTimerWidget: state.showTimerWidget,
      };
    default:
      return state;
  }
};
