import PropTypes from 'prop-types';

const NoDataAvailable = ({ content, size = 'lg' }) => (
  <div className={size === 'lg' ? 'no-data-box lg' : 'no-data-box sm'}>
    {content}
  </div>
);

NoDataAvailable.propTypes = {
  content: PropTypes.string,
  size: PropTypes.string,
};

export default NoDataAvailable;
