import { Form, Button } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';

const TeamSelectorForm = ({ teamsList, handleTeamSelection }) => {
  // Get the team list
  const teams = Object.values(teamsList).map((team, index) => (
    <option key={index} value={team.teamOwnerId}>
      {team.firstName} {team.lastName || ''}
    </option>
  ));

  return (
    <Formik
      initialValues={{
        teamOwnerId: teamsList[0].teamOwnerId,
        makeDefault: false,
        teamDetails: '',
      }}
      onSubmit={(values) => {
        /* get details of the selected team */
        const selectedTeam = teamsList.filter((e) =>
          e.teamOwnerId === values.teamOwnerId ? e : 0
        );
        values.teamDetails = selectedTeam;

        handleTeamSelection(values);
      }}
    >
      {(formikprops) => (
        <Form onSubmit={formikprops.handleSubmit}>
          <Form.Group>
            <Field
              className="form-control form-select"
              as="select"
              name="teamOwnerId"
            >
              {teams}
            </Field>
          </Form.Group>

          <Form.Group>
            <Form.Check
              type="checkbox"
              checked={formikprops.values.makeDefault}
              name="makeDefault"
              label="Make this Team my default"
              onChange={() =>
                formikprops.setFieldValue(
                  'makeDefault',
                  !formikprops.values.makeDefault
                )
              }
            />
          </Form.Group>

          <Form.Group controlId="buttonSubmit" className="">
            <Button
              variant="primary"
              type="submit"
              className="btn btn-lg btn-block btn-primary mb-3 w-100"
            >
              Continue
            </Button>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

TeamSelectorForm.propTypes = {
  teamsList: PropTypes.any,
  handleTeamSelection: PropTypes.func,
};

export default TeamSelectorForm;
