import { combineReducers } from 'redux';
import loggedReducer from './isLogged';
import billingReducer from './billing';
import userReducer from './user';
import {
  projectsReducer,
  selectedProjectsReducer,
  activeProjects,
} from './projects';
import startTour from './startTour';
import { teamReducer, onlineStatus } from './team';
import { tasksReducer } from './tasks';
import { activityReducer } from './activity';
import { dashboardReducer } from './dashboard';
import {
  timesheetApproval,
  overTimeEnabled,
  scheduleEnabled,
  invoiceEnabled,
  taskNotificationEnabled,
  manageOngoingSessions,
} from './reports';
import { timeOffReducer } from './timeOff';
import { notificationReducer } from './notification';
import { timerReducer } from './timer';
import { freeTrialTourReducer } from './freeTrialTour';
import { wsReducer } from './worksession';
import { collpasedSideNavbarToggle } from './collpasedSideNavbarToggle';

const allReducers = combineReducers({
  isLogged: loggedReducer,
  billingReducer,
  userReducer,
  allProjects: projectsReducer,
  project: selectedProjectsReducer,
  projectsList: activeProjects,
  isStartTour: startTour,
  team: teamReducer,
  tasks: tasksReducer,
  activity: activityReducer,
  dashboard: dashboardReducer,
  onlineStatus,
  timesheetApproval,
  overTimeEnabled,
  scheduleEnabled,
  manageOngoingSessions,
  invoiceEnabled,
  taskNotificationEnabled,
  timeOffReducer,
  notificationReducer,
  timerReducer,
  freeTrialTourReducer,
  wsReducer,
  collpasedSideNavbarToggle,
});

export default allReducers;
