import PropTypes from 'prop-types';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Form, Button } from 'react-bootstrap';
import CompanyLogoUpload from './CompanyLogoUpload';
import { ToggleSwitch } from '../common';

const InvoiceSettings = ({
  invoiceEnabled,
  companyName,
  companyLogo,
  billingAddress,
  taxRate,
  taxNo,
  handleUpdate,
  handleInvoiceToggle,
  handleImgUpload,
  showDeleteModal,
}) => (
  <Formik
    initialValues={{
      invoiceEnabled,
      companyNameField: companyName,
      companyLogoField: companyLogo,
      billingAddressField: billingAddress,
      taxRateField: taxRate,
      taxNoField: taxNo,
    }}
    enableReinitialize
    validationSchema={Yup.object({
      taxRateField: Yup.number().when('invoiceEnabled', {
        is: true,
        then: Yup.number()
          .typeError('Please enter valid tax rate.')
          .nullable()
          .notRequired()
          .test(
            'maxDigitsx',
            'tax rate must be less than 100%',
            (taxRateField) => !taxRateField || taxRateField <= 100
          ),
      }),
    })}
    onSubmit={(values) => {
      if (values.invoiceEnabled === false) {
        values.companyNameField = '';
        values.companyLogoField = '';
        values.billingAddressField = '';
        values.taxRateField = 0;
        values.taxNoField = '';
      }
      handleUpdate(values);
    }}
  >
    {(formikprops) => (
      <div className="col-12 col-md-12">
        <Form onSubmit={formikprops.handleSubmit} className="needs-validation">
          <Row>
            <Col>
              <h4 className="font-weight-base mb-1">Invoice</h4>
              <small className="text-muted">
                Effortlessly create and send custom invoices or auto-generate
                them from tracked time, streamlining client billing with ease
                and precision.
              </small>
            </Col>
            <Col className="col-auto">
              <ToggleSwitch
                id="invoiceEnabled"
                name="invoiceEnabled"
                handleChangeforChecked={handleInvoiceToggle}
                checked={invoiceEnabled}
              />
            </Col>
          </Row>
          <br />
          {formikprops.values.invoiceEnabled && (
            <div>
              <Row>
                <Col>
                  <Row className="mb-2">
                    <Col style={{ maxWidth: '160px' }}>
                      <small>Company name</small>
                    </Col>
                    <Col className="px-0">
                      <div className="form-group mb-2">
                        <Field
                          type="text"
                          className="form-control form-control-sm"
                          style={{
                            maxWidth: '200px',
                          }}
                          name="companyNameField"
                        />
                      </div>
                    </Col>

                    <ErrorMessage
                      name="companyNameField"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Row>
                  <Row className="mb-2">
                    <Col style={{ maxWidth: '160px' }}>
                      <small>Billing address</small>
                    </Col>
                    <Col className="px-0">
                      <div className="form-group mb-2">
                        <Field
                          as="textarea"
                          className="form-control form-control-sm"
                          maxLength="300"
                          style={{
                            maxWidth: '200px',
                          }}
                          rows="3"
                          name="billingAddressField"
                        />
                      </div>
                    </Col>

                    <ErrorMessage
                      name="billingAddressField"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Row>
                  <Row className="mb-2">
                    <Col style={{ maxWidth: '160px' }}>
                      <small>Tax rate</small>
                    </Col>
                    <Col className="px-0 pe-0">
                      <div className="input-group input-group-sm input-group-merge mb-2">
                        <Field
                          type="text"
                          className="form-control form-control-sm"
                          style={{
                            maxWidth: '50px',
                          }}
                          name="taxRateField"
                        />
                        <div className="input-group-text">
                          <span>%</span>
                        </div>
                      </div>
                    </Col>

                    <ErrorMessage
                      name="taxRateField"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Row>
                  <Row>
                    <Col style={{ maxWidth: '160px' }}>
                      <small>Tax/VAT number</small>
                    </Col>
                    <Col className="px-0 pe-0">
                      <div className="form-group mb-2">
                        <Field
                          type="text"
                          className="form-control form-control-sm"
                          style={{
                            maxWidth: '200px',
                          }}
                          name="taxNoField"
                        />
                      </div>
                    </Col>
                    <ErrorMessage
                      name="taxRateField"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <CompanyLogoUpload
                      companyName={companyName}
                      companyLogo={companyLogo}
                      uploadLogo={handleImgUpload}
                      showDelete={showDeleteModal}
                    />
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md="auto">
                  <Button size="sm" type="submit" style={{ height: '26px' }}>
                    Save
                  </Button>
                </Col>
              </Row>
              <br />
            </div>
          )}
        </Form>
      </div>
    )}
  </Formik>
);
InvoiceSettings.propTypes = {
  invoiceEnabled: PropTypes.bool,
  companyName: PropTypes.any,
  companyLogo: PropTypes.any,
  billingAddress: PropTypes.any,
  taxRate: PropTypes.any,
  taxNo: PropTypes.any,
  handleUpdate: PropTypes.func,
  handleInvoiceToggle: PropTypes.func,
  handleImgUpload: PropTypes.func,
  showDeleteModal: PropTypes.func,
};
export default InvoiceSettings;
