import { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from './DashboardLayout';
import {
  MemberSearch,
  TeamMember,
  InvitationsListModal,
  AddMemberModal,
} from '../components/team';
import SwitchView from '../components/SwitchView';
import { resetMembersList, fetchOnlineMembers } from '../actions/team';
import { STATUS_CHECK_DELAY } from '../constants';

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isListView: false,
      isSubmitting: false,
      showInviteModal: false,

      membersCount: 0,
      pageHeading: {
        preTitle: 'Team',
        title: `Active Team Members`,
      },
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const { getOnlineMembers } = this.props;
    // Fetch online members list onMount
    getOnlineMembers();
    // Check for online members every 2 minutes
    this.statusTimer = setInterval(
      () => getOnlineMembers(),
      STATUS_CHECK_DELAY
    );
    if (
      location.state &&
      location.state.action &&
      location.state.action === 'showInviteModal'
    ) {
      this.setState((state) => ({
        ...state,
        showInviteModal: false,
      }));
    }
  }

  componentDidUpdate(prevProps) {
    const { teamData } = this.props;
    if (teamData.membersCount !== prevProps.teamData.membersCount) {
      this.handleMembersCount(teamData.membersCount);
    }
  }

  componentWillUnmount() {
    const { resetMembers } = this.props;
    resetMembers();
    // Clear interval on un mount
    clearInterval(this.statusTimer);
  }

  handleMembersCount = (value) => {
    this.setState((state) => ({
      ...state,
      pageHeading: {
        preTitle: 'Team',
        title: `Active Team Members (${value})`,
      },
    }));
  };

  handleChangeView = () => {
    this.setState((state) => ({
      ...state,
      isListView: !state.isListView,
    }));
  };

  toggleShowInviteModal = (displayState) => {
    this.setState((state) => ({
      ...state,
      showInviteModal: displayState,
    }));
  };

  render() {
    const { isListView, pageHeading, showInviteModal } = this.state;

    return (
      <Layout pageHeading={pageHeading}>
        <Row className="mb-4">
          <Col md="6" lg="3">
            <MemberSearch />
          </Col>
          <Col>
            <SwitchView
              isListView={isListView}
              handleChangeView={this.handleChangeView}
            />
          </Col>
          <Col className="col-auto ml-auto">
            <InvitationsListModal />
            <AddMemberModal
              showInviteModal={showInviteModal}
              toggleShowInviteModal={this.toggleShowInviteModal}
            />
          </Col>
        </Row>
        <Row>
          <TeamMember isListView={isListView} />
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({ teamData: state.team });

const mapDispatchToProps = () => ({
  resetMembers: resetMembersList,
  getOnlineMembers: fetchOnlineMembers,
});

Team.propTypes = {
  teamData: PropTypes.object,
  location: PropTypes.object,
  resetMembers: PropTypes.func,
  getOnlineMembers: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps())(Team);
