import { jsPDF as JSPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { sToHMSColonSeparated } from '../../helpers';

const ViewTimesheetPDF = (timesheetDetails) => {
  // initialize jsPDF
  const doc = new JSPDF('l', 'pt', 'a4');
  const totalPagesExp = '{total_pages_count_string}';
  const pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  const approvalTableColumns = {
    0: {
      halign: 'left',
    },
    1: {
      halign: 'center',
    },
    2: {
      halign: 'center',
    },
    3: {
      halign: 'center',
    },
    4: {
      halign: 'center',
    },
    5: {
      halign: 'center',
    },
    6: {
      halign: 'center',
    },
    7: {
      halign: 'center',
    },
    8: {
      halign: 'center',
    },
  };

  const weeklyStatus = (status) => {
    switch (status) {
      case 'submit':
        return 'Submitted';
      case 'approve':
        return 'Approved';
      case 'reject':
        return 'Rejected';
      case 'withdraw':
        return 'Withdrawn';
      default:
        return 'Not Submitted';
    }
  };

  timesheetDetails.forEach((memberData) => {
    doc.setFontSize(10);
    if (memberData.user) {
      doc.text(
        `Employee: ${memberData.user.firstName} ${
          memberData.user.lastName || ''
        }`,
        40,
        doc.lastAutoTable ? doc.lastAutoTable.finalY + 45 : 45,
        'left'
      );
    }
    doc.text(
      `Period: ${moment
        .utc(memberData.wsWeekDetails.startDate)
        .format('MMM DD, YYYY')} - ${moment
        .utc(memberData.wsWeekDetails.endDate)
        .format('MMM DD, YYYY')}`,
      40,
      doc.lastAutoTable ? doc.lastAutoTable.finalY + 60 : 60,
      'left'
    );
    doc.text(
      `Status: ${weeklyStatus(memberData.status)}`,
      800,
      doc.lastAutoTable ? doc.lastAutoTable.finalY + 60 : 60,
      'right'
    );
    const weekDates = memberData.data[0].wsDetails;
    // Table header columns
    const tableColumn = [
      moment(weekDates[0].date).format(` ddd, MMM DD`),
      moment(weekDates[1].date).format(` ddd, MMM DD`),
      moment(weekDates[2].date).format(` ddd, MMM DD`),
      moment(weekDates[3].date).format(` ddd, MMM DD`),
      moment(weekDates[4].date).format(` ddd, MMM DD`),
      moment(weekDates[5].date).format(` ddd, MMM DD`),
      moment(weekDates[6].date).format(` ddd, MMM DD`),
      'Total',
    ];

    const tableProjectColumn = ['Project', ...tableColumn];
    const tableTaskColumn = ['Task', ...tableColumn];

    // Table body columns
    const tableRows = [];
    // for each worksession pass all its data into an array
    memberData.data.forEach((project) => {
      const worksessionData = [
        project.title,
        project.wsDetails[0].totalWorkSessionSeconds === 0
          ? '-'
          : sToHMSColonSeparated(
              project.wsDetails[0].totalWorkSessionSeconds,
              'hh:mm:ss'
            ),
        project.wsDetails[1].totalWorkSessionSeconds === 0
          ? '-'
          : sToHMSColonSeparated(
              project.wsDetails[1].totalWorkSessionSeconds,
              'hh:mm:ss'
            ),
        project.wsDetails[2].totalWorkSessionSeconds === 0
          ? '-'
          : sToHMSColonSeparated(
              project.wsDetails[2].totalWorkSessionSeconds,
              'hh:mm:ss'
            ),
        project.wsDetails[3].totalWorkSessionSeconds === 0
          ? '-'
          : sToHMSColonSeparated(
              project.wsDetails[3].totalWorkSessionSeconds,
              'hh:mm:ss'
            ),
        project.wsDetails[4].totalWorkSessionSeconds === 0
          ? '-'
          : sToHMSColonSeparated(
              project.wsDetails[4].totalWorkSessionSeconds,
              'hh:mm:ss'
            ),
        project.wsDetails[5].totalWorkSessionSeconds === 0
          ? '-'
          : sToHMSColonSeparated(
              project.wsDetails[5].totalWorkSessionSeconds,
              'hh:mm:ss'
            ),
        project.wsDetails[6].totalWorkSessionSeconds === 0
          ? '-'
          : sToHMSColonSeparated(
              project.wsDetails[6].totalWorkSessionSeconds,
              'hh:mm:ss'
            ),
        sToHMSColonSeparated(project.totalWorkSessionSeconds, 'hh:mm:ss'),
      ];

      // push each session's info into a row
      tableRows.push(worksessionData);
    });

    // Initialize autoTable
    doc.autoTable({
      head: [tableProjectColumn],
      body: tableRows,
      // foot: [tableFoot],
      startY: doc.lastAutoTable ? doc.lastAutoTable.finalY + 75 : 75,
      theme: 'grid',
      styles: { cellPadding: 10 },
      headStyles: {
        fillColor: [237, 242, 249],
        // fontSize: 9,
        textColor: [40, 62, 89],
      },
      columnStyles: approvalTableColumns,
      footStyles: {
        fillColor: [237, 242, 249],
        textColor: [40, 62, 89],
      },
      tableLineColor: [237, 242, 249],
      tableLineWidth: 1,
      showFoot: 'lastPage',
      didDrawPage() {
        // Header
        doc.setFontSize(14);
        doc.setTextColor(40);
        doc.text('Timesheet', pageWidth / 2, 23, 'center');
        doc.setFontSize(10);
        // Footer
        let str = `Page ${doc.internal.getNumberOfPages()}`;
        // Total page number
        if (typeof doc.putTotalPages === 'function') {
          str = `${str} of ${totalPagesExp}`;
        }
        doc.setFontSize(10);
        const { pageSize } = doc.internal;
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, 750, pageHeight - 22);
      },
    });

    const taskRows = [];
    memberData.data.forEach((project) => {
      const taskList =
        project.task.length > 0
          ? project.task.filter((tItem) => '_id' in tItem && 'task' in tItem)
          : [];
      return (
        taskList.length > 0 &&
        taskList.forEach((taskItem) => {
          const taskTotalTime = taskItem.wsDetails.reduce(
            (pValue, cValue) => pValue + cValue.totalWorkSessionSeconds,
            0
          );
          const taskWorksessionData = [
            taskItem.task ? taskItem.task : '--',
            taskItem.wsDetails[0].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  taskItem.wsDetails[0].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
            taskItem.wsDetails[1].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  taskItem.wsDetails[1].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
            taskItem.wsDetails[2].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  taskItem.wsDetails[2].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
            taskItem.wsDetails[3].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  taskItem.wsDetails[3].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
            taskItem.wsDetails[4].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  taskItem.wsDetails[4].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
            taskItem.wsDetails[5].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  taskItem.wsDetails[5].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
            taskItem.wsDetails[6].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  taskItem.wsDetails[6].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
            sToHMSColonSeparated(taskTotalTime, 'hh:mm:ss'),
          ];

          if (taskItem.task && taskItem._id) {
            taskRows.push(taskWorksessionData);
          }
        })
      );
    });

    // If tasks are available create another table with task data
    if (taskRows.length > 0) {
      // Initialize autoTable
      doc.autoTable({
        head: [tableTaskColumn],
        body: taskRows,
        // foot: [tableFoot],
        startY: doc.lastAutoTable ? doc.lastAutoTable.finalY + 15 : 15,
        theme: 'grid',
        styles: { cellPadding: 10 },
        headStyles: {
          fillColor: [237, 242, 249],
          // fontSize: 9,
          textColor: [40, 62, 89],
        },
        columnStyles: approvalTableColumns,
        footStyles: {
          fillColor: [237, 242, 249],
          textColor: [40, 62, 89],
        },
        tableLineColor: [237, 242, 249],
        tableLineWidth: 1,
      });
    }
  });

  // Total page number
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  // Name of PDF file.
  doc.save(`timesheet_report.pdf`);
};

export default ViewTimesheetPDF;
