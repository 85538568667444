import { Form, Button, Row, Col } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import CompanyLogo from '../CompanyLogo.jsx';
import { GENERAL } from '../../constants/index.js';
import '../../assets/styles/company-logo-upload.scss';

const CompanyLogoUpload = ({
  companyName,
  companyLogo,
  uploadLogo,
  showDelete,
}) => (
  <Row className="row align-items-center">
    <Col className="col-auto">
      <div className="company-logo-upload">
        <div className="company-logo-edit">
          <input
            type="file"
            name="companyLogo"
            id="imageUpload"
            accept=".png, .jpg, .jpeg"
            onChange={uploadLogo}
          />
          <input
            type="hidden"
            id="MAX_FILE_SIZE"
            name="MAX_FILE_SIZE"
            value="307200"
          />
          <input type="hidden" id="initials" name="initials" value="" />

          <Tippy content="Change logo" theme="grey">
            <Form.Label htmlFor="imageUpload" />
          </Tippy>
        </div>
        <div className="company-logo-preview company-logo avatar-xl">
          <CompanyLogo companyName={companyName} companyLogo={companyLogo} />
        </div>

        {companyLogo !== '' && (
          <Button
            id="btnRemove"
            type="button"
            className="btn btn-sm btn-icon btn-danger"
            data-toggle="tooltip"
            title="Remove logo"
            onClick={showDelete}
          >
            <i className="mdi mdi-close" />
          </Button>
        )}
      </div>
    </Col>
    <Col className="ml-n2">
      <small className="mb-1">Company logo</small> <br />
      <small className="text-muted">{GENERAL.AVATAR_SPEC}</small>
    </Col>
  </Row>
);

CompanyLogoUpload.propTypes = {
  companyName: PropTypes.string,
  companyLogo: PropTypes.string,
  uploadLogo: PropTypes.func,
  showDelete: PropTypes.func,
};
export default CompanyLogoUpload;
