import React, { memo, Component } from 'react';
import moment from 'moment';
import Layout from './DashboardLayout';
import Loading from '../components/Loader';
import {
  MembersessionChart,
  PerformancesessionChart,
} from '../components/reports';
import { teamService, reportService } from '../services';
import { DATE_AND_TIME } from '../constants';
import { trackJune } from '../utilities/analytics';

class Charts extends Component {
  /**
   * state Component State
   * @type Object
   */
  state = {
    projectsessionData: [],
    isLoading: true,
    isFetchingData: false,
    isGenerated: false,
    teamMembersList: [],
    worksessionData: [],
    projectsList: [],
    isProjectGenerated: false,
  };

  /**
   * Page Heading
   * @type Object
   */
  pageHeading = {
    preTitle: 'Reports',
    title: 'Charts',
  };

  /**
   * @return void
   */
  componentDidMount() {
    this.getTeamsAndProjects().then(() => {
      this.setState((state) => ({
        ...state,
        isLoading: false,
      }));
    });
    // track june
    trackJune(null, this.pageHeading.title);
  }

  handleProjectGenerateData = (formdata) => {
    this.setState((state) => ({
      ...state,
      isFetchingData: true,
    }));
    reportService
      .get_worksession(formdata)
      .then((response) => {
        const { data } = response;
        this.setState((state) => ({
          ...state,
          isFetchingData: false,
          worksessionData: data,
          isProjectGenerated: true,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isFetchingData: false,
        }));
      });
  };

  handleGenerateData = (formdata) => {
    this.setState((state) => ({
      ...state,
      isFetchingData: true,
    }));

    reportService
      .get_worksession(formdata)
      .then((response) => {
        const { data } = response;
        this.setState((state) => ({
          ...state,
          isFetchingData: false,
          worksessionData: data,
          isGenerated: true,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isFetchingData: false,
        }));
      });
  };

  handleProjectGenerateData = (formdata) => {
    this.setState((state) => ({
      ...state,
      isFetchingData: true,
    }));

    reportService
      .get_worksession(formdata)
      .then((response) => {
        const { data } = response;
        this.setState((state) => ({
          ...state,
          isFetchingData: false,
          projectsessionData: data,
          isProjectGenerated: true,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isFetchingData: false,
        }));
      });
  };

  getTeamsAndProjects = () => {
    this.setState((state) => ({
      ...state,
      isUpdatingTeamsList: true,
    }));
    return teamService
      .get_team_members_and_projects({
        teamOwnerId: localStorage.getItem('teamOwnerId'), // @ToDo: Move this to reducer or react-context API
        summaryList: true,
      })
      .then((response) => {
        const { teamMembers, projects } = response.data;
        this.setState((state) => ({
          ...state,
          teamMembersList: teamMembers,
          projectsList: projects,
          isUpdatingTeamsList: false,
        }));
      })
      .catch(() => {
        // Do nothing
      });
  };

  /**
   * @return Object
   */
  render() {
    const {
      isProjectGenerated,
      teamMembersList,
      projectsList,
      isFetchingData,
      isLoading,
      worksessionData,
      projectsessionData,
      isGenerated,
    } = this.state;
    const handleDateChange = (event, picker, start = true) => {
      if (start) {
        return moment(picker.startDate).format(
          DATE_AND_TIME.DATE_RANGE_IN_STATE
        );
      }
      return moment(picker.endDate).format(DATE_AND_TIME.DATE_RANGE_IN_STATE);
    };
    return (
      <Layout pageHeading={this.pageHeading}>
        {(isLoading || isFetchingData) && <Loading contentAreaOnly />}
        <MembersessionChart
          teamMembersList={teamMembersList}
          handleDateChange={handleDateChange}
          handleGenerateData={this.handleGenerateData}
          worksessionData={worksessionData}
          isGenerated={isGenerated}
        />
        <PerformancesessionChart
          projectsList={projectsList}
          handleDateChange={handleDateChange}
          handleProjectGenerateData={this.handleProjectGenerateData}
          projectsessionData={projectsessionData}
          isProjectGenerated={isProjectGenerated}
        />
      </Layout>
    );
  }
}

export default memo(Charts);
