import { Form, Button } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

const PasswordForm = ({ handleUpdate }) => (
  <Formik
    enableReinitialize
    initialValues={{
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }}
    validationSchema={Yup.object({
      currentPassword: Yup.string()
        .trim()
        .required('Please enter your current password'),
      newPassword: Yup.string()
        .matches(/^\S*$/, 'Whitespace is not allowed')
        .required('Please enter your new password')
        .min(6, 'New password must be greater or equal to 6 characters')
        .max(20, 'New password must be less than or equal to 20 characters')
        .notOneOf(
          [Yup.ref('currentPassword'), null],
          'New and current passwords are same'
        ),
      confirmPassword: Yup.string()
        .matches(/^\S*$/, 'Whitespace is not allowed')
        .required('Please confirm your new password')
        .min(6, 'Confirm password must be greater or equal to 6 characters')
        .max(20, 'Confirm password must be less than or equal to 20 characters')
        .equals(
          [Yup.ref('newPassword')],
          'New and confirm passwords must match'
        ),
    })}
    onSubmit={(values, { resetForm }) => {
      handleUpdate(values);
      resetForm();
    }}
  >
    {(formikprops) => (
      <Form onSubmit={formikprops.handleSubmit} noValidate>
        <div className="form-group">
          <Form.Label className="form-control-label">
            Current password
          </Form.Label>
          <Field
            name="currentPassword"
            type="password"
            className="form-control"
            maxLength="20"
            placeholder="Enter your current password"
            as={Form.Control}
            isInvalid={
              !!formikprops.touched.currentPassword &&
              !!formikprops.errors.currentPassword
            }
            isValid={
              !!formikprops.touched.currentPassword &&
              !formikprops.errors.currentPassword
            }
          />
          <ErrorMessage
            name="currentPassword"
            render={(msg) => <small className="text-danger">{msg}</small>}
          />
        </div>
        <div className="form-group">
          <Form.Label className="form-control-label">New password</Form.Label>
          <Field
            name="newPassword"
            type="password"
            className="form-control"
            maxLength="20"
            placeholder="Enter your new password"
            as={Form.Control}
            isInvalid={
              !!formikprops.touched.newPassword &&
              !!formikprops.errors.newPassword
            }
            isValid={
              !!formikprops.touched.newPassword &&
              !formikprops.errors.newPassword
            }
            onFocus={() => {
              formikprops.setFieldTouched('currentPassword');
            }}
          />
          <ErrorMessage
            name="newPassword"
            render={(msg) => <small className="text-danger">{msg}</small>}
          />
        </div>
        <div className="form-group">
          <Form.Label className="form-control-label">
            Confirm new password
          </Form.Label>
          <Field
            name="confirmPassword"
            type="password"
            className="form-control"
            maxLength="20"
            placeholder="Confirm your new password"
            as={Form.Control}
            isInvalid={
              !!formikprops.touched.confirmPassword &&
              !!formikprops.errors.confirmPassword
            }
            isValid={
              !!formikprops.touched.confirmPassword &&
              !formikprops.errors.confirmPassword
            }
            onFocus={() => {
              formikprops.setFieldTouched('currentPassword');
              formikprops.setFieldTouched('newPassword');
            }}
          />
          <ErrorMessage
            name="confirmPassword"
            render={(msg) => <small className="text-danger">{msg}</small>}
          />
        </div>
        <div className="d-grid gap-2">
          <Button
            type="submit"
            disabled={!(formikprops.isValid && formikprops.dirty)}
          >
            Update password
          </Button>
        </div>
      </Form>
    )}
  </Formik>
);
PasswordForm.propTypes = {
  handleUpdate: PropTypes.func,
};
export default PasswordForm;
