import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProjectTabs = ({ selectedTab, permissions }) => {
  const { role } = useSelector((state) => state.userReducer);
  const history = useHistory();
  const { id } = useParams();
  const isTeamMemberTab =
    role !== 'member' || (role === 'member' && permissions.manageMembers);
  const isSettingsTab =
    role !== 'member' || (role === 'member' && permissions.manageProjects);
  return (
    <Tabs
      defaultActiveKey={selectedTab.toLowerCase().replace(' ', '-')}
      className="mb-4"
      onSelect={(nextTab) => history.push(`/projects/${id}/${nextTab}`)}
    >
      <Tab eventKey="overview" title="Overview" />
      {role !== 'member' && <Tab eventKey="timeline" title="Timeline" />}
      <Tab eventKey="task-board" title="Task Board" />
      <Tab
        eventKey="task-list"
        title="Task List"
        tabClassName="task-list-tab"
      />
      {isTeamMemberTab && <Tab eventKey="team-members" title="Team Members" />}
      <Tab eventKey="worksessions" title="Worksessions" />
      {isSettingsTab && <Tab eventKey="settings" title="Settings" />}
    </Tabs>
  );
};
ProjectTabs.propTypes = {
  selectedTab: PropTypes.string,
  permissions: PropTypes.object,
};
export default ProjectTabs;
