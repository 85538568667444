import { BASE_API_URL, WORKSESSION_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

const options = AppService.options();

export const workSessionService = {
  list_worksession: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'GET',
    };
    const URL = `${BASE_API_URL}${WORKSESSION_URLS.LIST}?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  add_worksession: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + WORKSESSION_URLS.LIST,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_worksession: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + WORKSESSION_URLS.LIST,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  delete_worksession: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'DELETE',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL}${WORKSESSION_URLS.LIST}/${data.teamOwnerId}/${data.workSessionId}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  add_worksession_note: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + WORKSESSION_URLS.NOTE,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_worksession_note: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + WORKSESSION_URLS.NOTE,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  delete_worksession_note: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'DELETE',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL}${WORKSESSION_URLS.NOTE}/${data.teamOwnerId}/${data.workSessionId}/${data.noteId}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  delete_screenshots: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + WORKSESSION_URLS.DELETE_SCREENSHOTS,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  web_check_in: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + WORKSESSION_URLS.CHECK_IN,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  web_check_out: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + WORKSESSION_URLS.CHECK_OUT,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  screenshot: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + WORKSESSION_URLS.SCREENSHOT,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_time_edit_requests: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + WORKSESSION_URLS.REQUESTS}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  process_edit_request: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + WORKSESSION_URLS.PROCESS_REQUEST,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_ongoing_work_sessions: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${
        BASE_API_URL + WORKSESSION_URLS.ONGOING_SESSIONS
      }?${new URLSearchParams(data).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  manage_breaks: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + WORKSESSION_URLS.BREAKS,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  force_checkout: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + WORKSESSION_URLS.CHECKOUT,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
};
