import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import EdiText from 'react-editext';
import { toast } from 'react-toastify';
import { workSessionService } from '../../services';
import { UPDATE_FAILURE_ERROR } from '../../constants';
import Loading from '../Loader';

// Add new note to worksession
const AddNote = ({
  workSessionId,
  hideAddNoteForm,
  updateNotesList,
  setIsLoading,
}) => {
  const handleAddNote = (formdata) => {
    // Enable loading
    setIsLoading(true);
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      workSessionId,
      note: formdata.notes,
    };
    workSessionService
      .add_worksession_note(params)
      .then((res) => {
        if (res.status) {
          hideAddNoteForm();
          setIsLoading(false);
          updateNotesList([...res.data.notes]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(UPDATE_FAILURE_ERROR);
      });
  };
  return (
    <Formik
      initialValues={{
        notes: '',
      }}
      validationSchema={Yup.object({
        notes: Yup.string()
          .trim()
          .required('Please enter your note')
          .min(5, 'Note must be more than 5 characters'),
      })}
      onSubmit={(values) => {
        handleAddNote(values);
      }}
    >
      {(formikprops) => (
        <Form onSubmit={formikprops.handleSubmit}>
          <Form.Group controlId="wsNotes" className="mb-3">
            <Field
              as="textarea"
              maxLength="305"
              name="notes"
              rows="2"
              className="form-control"
              placeholder="Draft your note"
            />
            <ErrorMessage
              name="notes"
              render={(msg) => <small className="text-danger">{msg}</small>}
            />
          </Form.Group>
          <Button
            variant="light"
            className="btn-white me-2"
            type="reset"
            size="sm"
            onClick={hideAddNoteForm}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            size="sm"
            disabled={!(formikprops.isValid && formikprops.dirty)}
          >
            Add
          </Button>
        </Form>
      )}
    </Formik>
  );
};
AddNote.propTypes = {
  workSessionId: PropTypes.string,
  hideAddNoteForm: PropTypes.func,
  updateNotesList: PropTypes.func,
  setIsLoading: PropTypes.func,
};

// Notes listing on modal
const NotesList = ({ notes, workSessionId, updateNotesList, setIsLoading }) => {
  const updateWorksessionNote = (noteData) => {
    setIsLoading(true);
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      workSessionId,
      ...noteData,
    };
    workSessionService
      .update_worksession_note(params)
      .then((res) => {
        if (res.status) {
          setIsLoading(false);
          updateNotesList([...res.data.notes]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(UPDATE_FAILURE_ERROR);
      });
  };
  const deleteWorksessionNote = (noteId) => {
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      workSessionId,
      noteId,
    };
    workSessionService
      .delete_worksession_note(params)
      .then((res) => {
        if (res.status) {
          updateNotesList([...res.data.notes]);
        }
      })
      .catch((error) => {
        toast.error(UPDATE_FAILURE_ERROR);
      });
  };
  return (
    <ul className="list-group list-group-flush">
      {notes !== null &&
        notes.length > 0 &&
        notes.map((item) => {
          let noteContent;
          try {
            noteContent = decodeURIComponent(item.note);
          } catch (e) {
            noteContent = item.note;
          }
          return (
            <li key={item._id} className="list-group-item py-2">
              <div className="d-flex justify-content-between align-items-center">
                <div className="font-size-sm mb-0">
                  <EdiText
                    showButtonsOnHover
                    editButtonClassName="edit-text"
                    type="text"
                    validation={(val) => val.length > 0}
                    value={noteContent}
                    onSave={(value) =>
                      updateWorksessionNote({
                        noteId: item._id,
                        note: value,
                      })
                    }
                    editOnViewClick
                    cancelOnUnfocus
                    hideIcons
                    editButtonContent={<i className="mdi mdi-pencil-outline" />}
                    saveButtonContent={<i className="mdi mdi-check" />}
                    saveButtonClassName="btn btn-primary btn-sm me-2"
                    cancelButtonContent={<i className="mdi mdi-close" />}
                    cancelButtonClassName="btn btn-white btn-sm"
                  />
                </div>
                <Button
                  variant="link"
                  onClick={() => deleteWorksessionNote(item._id)}
                  title="Delete"
                >
                  <i className="mdi mdi-trash-can-outline h2 mb-0" />
                </Button>
              </div>
            </li>
          );
        })}
    </ul>
  );
};
NotesList.propTypes = {
  notes: PropTypes.any,
  workSessionId: PropTypes.string,
  updateNotesList: PropTypes.func,
  setIsLoading: PropTypes.func,
};

// Modal Content
const WorkSessionEditNotesModal = ({ wsItem, projectId }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showAddNoteField, setShowAddNoteField] = useState(false);
  const showAddNoteForm = () => setShowAddNoteField(true);
  const hideAddNoteForm = () => setShowAddNoteField(false);
  const [workSessionNotes, setWorkSessionNotes] = useState([...wsItem.notes]);
  // Update notes array
  const updateNotesList = (notes) => setWorkSessionNotes([...notes]);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <Button variant="primary" size="sm" className="ms-2" onClick={handleShow}>
        Edit notes
      </Button>
      {isLoading && <Loading contentAreaOnly />}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title className="m-0">Edit notes</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body className="pt-1">
          <section>
            <NotesList
              notes={workSessionNotes}
              projectId={projectId}
              workSessionId={wsItem._id}
              updateNotesList={updateNotesList}
              setIsLoading={setIsLoading}
            />
            {showAddNoteField ? (
              <AddNote
                workSessionId={wsItem._id}
                projectId={projectId}
                hideAddNoteForm={hideAddNoteForm}
                updateNotesList={updateNotesList}
                setIsLoading={setIsLoading}
              />
            ) : (
              <Button
                variant="link"
                size="sm"
                className="mt-4 px-0"
                onClick={showAddNoteForm}
              >
                + Add note
              </Button>
            )}
          </section>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
WorkSessionEditNotesModal.propTypes = {
  wsItem: PropTypes.object,
  projectId: PropTypes.string,
};

export default WorkSessionEditNotesModal;
