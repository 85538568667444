import { BASE_API_URL, TASKS_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

const options = AppService.options();

export const tasksService = {
  add_section: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TASKS_URLS.SECTION,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  copy_section: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TASKS_URLS.COPY_SECTION,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  edit_section: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + TASKS_URLS.SECTION,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  delete_section: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'DELETE',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL}${TASKS_URLS.SECTION}/${data.teamOwnerId}/${data.projectId}/${data.sectionId}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  get_tasks: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TASKS_URLS.GET_TASKS,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  add_task: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TASKS_URLS.TASK,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  edit_task: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + TASKS_URLS.TASK,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  delete_task: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'DELETE',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL}${TASKS_URLS.TASK}/${data.teamOwnerId}/${data.projectId}/${data.sectionId}/${data.taskId}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  move_task: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + TASKS_URLS.MOVE,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_tasks_data: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const URL = `${BASE_API_URL}${TASKS_URLS.TASK_LIST}?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  change_due_date: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + TASKS_URLS.DUE_DATE,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_tasks_list: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TASKS_URLS.TASK_LIST2,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_sections_list: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const URL = `${BASE_API_URL}${TASKS_URLS.SECTION}?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  import_tasks: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TASKS_URLS.IMPORT,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_comments: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const URL = `${BASE_API_URL}${TASKS_URLS.COMMENT}?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  add_comment: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TASKS_URLS.COMMENT,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  delete_comment: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'DELETE',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL}${TASKS_URLS.COMMENT}/${data.teamOwnerId}/${data.commentId}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  get_tags: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const URL = `${BASE_API_URL}/v1/tag?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  create_tag: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(`${BASE_API_URL}/v1/tag`, {
      ...options,
      ...headers,
      body: JSON.stringify(data),
    });
    return response;
  },
  update_tag: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(`${BASE_API_URL}/v1/tag`, {
      ...reqOptions,
      ...headers,
      body: JSON.stringify(data),
    });
    return response;
  },
  delete_tag: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'DELETE',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL}v1/tag/${data.teamOwnerId}/${data.tagid}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  get_task_details: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const URL = `${BASE_API_URL}/v1/task/details?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
};
