import { useState } from 'react';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  activateLoader,
  deactivateLoader,
  updateMemberRole,
} from '../../actions/team';
import { teamService } from '../../services';
import { TEAM, API_FAILURE_ERROR } from '../../constants';

function MemberRoleModal({ userId, userRole }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isMember = userRole === 'member' || false;

  // promote/demote member
  const handleRoleUpdate = (newRole) => {
    dispatch(activateLoader());
    const params = {
      userId,
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      role: newRole,
    };
    teamService
      .update_user_role(params)
      .then((response) => {
        if (response.data) {
          dispatch(updateMemberRole(response.data));
          toast.success(TEAM.ROLE_UPDATE_SUCCESS);
          handleClose();
        }
        dispatch(deactivateLoader());
      })
      .catch((errResponse) => {
        dispatch(deactivateLoader());
        if ('message' in errResponse) {
          toast.error(errResponse.message);
        } else {
          toast.error(API_FAILURE_ERROR);
        }
      });
  };

  return (
    <>
      <Dropdown.Item onClick={handleShow}>
        {' '}
        {isMember ? 'Promote to Manager' : 'Demote from Manager'}{' '}
      </Dropdown.Item>

      <Modal show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header>
          <Modal.Title className="m-0">
            {' '}
            {isMember ? 'Promote to Manager' : 'Demote from Manager'}{' '}
          </Modal.Title>

          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          {isMember ? (
            <div className="card-body">
              <div className="d-flex align-items-end mb-5">
                <div className="w-100">
                  <strong className="text-dark font-weight-bold text-sm">
                    Basic permissions
                  </strong>
                  <small className="d-block text-muted mb-2">
                    With basic permissions, this Manager will be able to{' '}
                  </small>
                  <ul className="pl-3 mb-0">
                    <li>View all Projects</li>
                    <li>Create new Projects</li>
                  </ul>
                </div>

                <div className="text-right ml-auto">
                  <button
                    type="button"
                    name="btnPromoteBasicManager"
                    className="btn btn-sm btn-primary btn-icon"
                    onClick={() => {
                      handleRoleUpdate('manager');
                    }}
                  >
                    Basic
                  </button>
                </div>
              </div>
              <div className="d-flex align-items-end mb-3">
                <div className="w-100">
                  <strong className="text-dark font-weight-bold text-sm">
                    Full permissions
                  </strong>
                  <small className="d-block text-muted mb-2">
                    With full permission, this Manager has all the basic
                    features plus
                  </small>
                  <ul className="pl-3">
                    <li>Edit all Projects</li>
                    <li>Archive and delete Projects</li>
                    <li>Change account level permissions</li>
                    <li>Delete Team Members</li>
                  </ul>
                </div>

                <div className="text-right ml-auto">
                  <button
                    type="button"
                    name="btnPromoteFullManager"
                    className="btn btn-sm btn-primary btn-icon"
                    style={{ minWidth: '50px' }}
                    onClick={() => {
                      handleRoleUpdate('full_manager');
                    }}
                  >
                    Full
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <p>
                <span>
                  Demoting a Manager to Member will remove all the following
                  permissions:
                  <br />
                </span>
              </p>
              <ul className="pl-3 mb-0">
                <li>View all Projects</li>
                <li>Create new Projects</li>
                <li>Invite other Members to your Projects</li>
              </ul>
              <br />
              <p>
                Additionally, any additional permissions you may have granted
                this user (for example, Manage Roster or Manage Tasks) will also
                be removed. These can be individually re-applied by going to the
                project's Roster.
              </p>
            </>
          )}
        </Modal.Body>
        <section>
          <div className="table-responsive" />
        </section>
        {!isMember && (
          <Modal.Footer className="d-flex justify-content-between">
            <Button variant="link" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className=" ml-auto"
              onClick={() => {
                handleRoleUpdate('member');
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
MemberRoleModal.propTypes = {
  userId: PropTypes.string,
  userRole: PropTypes.string,
};
export default MemberRoleModal;
