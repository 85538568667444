import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';
import { workSessionService } from '../../services';
import { UPDATE_FAILURE_ERROR } from '../../constants';
import { DateAndTimePickerField, TeamMembersDropdown } from '../common';
import { getUserData } from '../../helpers';

const ProjectAddWorkSession = ({
  teamMembersList,
  project,
  userData,
  handleUpdateWsData,
  handleDateChange,
}) => {
  const [checkInDate, setCheckInDate] = useState();
  const [checkOutDate, setCheckOutDate] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    // Reset checkIn and checkOut date
    setCheckInDate();
    setCheckOutDate();
  };
  const handleShow = () => setShow(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const userId = getUserData('_id');
  const handleAddWorkSession = (formdata) => {
    if (Date.parse(formdata.checkOutDate) <= Date.parse(formdata.checkInDate)) {
      toast.error('Check in time is greater or equal to check out time');
    } else {
      setSubmitting(true);
      const params = {
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        workSessionDetails: {
          projectId: project._id,
          teamMemberId: formdata.teamMember,
          checkInDate: moment(checkInDate).format('YYYY-MM-DD HH:mm:ss'),
          checkOutDate: moment(checkOutDate).format('YYYY-MM-DD HH:mm:ss'),
          reason: formdata.reason,
          notes: formdata.notes,
          workSessionType: formdata.workSessionType,
        },
      };

      // Team member id for user role type 'member'.
      if (userData.role === 'member') {
        params.workSessionDetails.teamMemberId = userId;
      }

      workSessionService
        .add_worksession(params)
        .then((response) => {
          if (response.status) {
            setSubmitting(false);
            handleDateChange(checkInDate);
            handleUpdateWsData(moment(checkInDate).format('YYYY-MM-DD'));
            toast.success('Worksession added successfully');
            handleClose();
          }
        })
        .catch((error) => {
          if (error.message === 'Validation error') {
            if (Array.isArray(error.data)) {
              error.data.map((item) => toast.error(item.msg));
            } else {
              toast.error(error.data);
            }
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error(UPDATE_FAILURE_ERROR);
          }
          setSubmitting(false);
        });
    }
  };

  return (
    <>
      <Button variant="primary" className="lift mb-1" onClick={handleShow}>
        Add Worksession
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title className="m-0">Add worksession</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={{
            checkInDate,
            checkOutDate,
            reason: '',
            teamMember: teamMembersList[0].userId,
            notes: '',
            workSessionType: 'desktop',
          }}
          validationSchema={Yup.object({
            checkInDate: Yup.date().required(
              'Please enter check in information'
            ),
            checkOutDate: Yup.date().required(
              'Please enter check out information'
            ),
            reason: Yup.string().required(
              'Please enter a reason for your worksession'
            ),
            notes: Yup.string().min(5, 'Note must be more than 5 characters'),
          })}
          onSubmit={(values) => {
            handleAddWorkSession(values);
          }}
        >
          {(formikprops) => (
            <Form onSubmit={formikprops.handleSubmit}>
              <Modal.Body>
                <section>
                  <DateAndTimePickerField
                    label="Check In"
                    handleDateSelect={setCheckInDate}
                    name="checkInDate"
                    defaultValue=""
                  />
                  <DateAndTimePickerField
                    label="Check Out"
                    handleDateSelect={setCheckOutDate}
                    name="checkOutDate"
                    defaultValue=""
                  />
                  <Form.Group controlId="projectName">
                    <Form.Label>
                      Reason <span className="text-danger">*</span>
                    </Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="reason"
                    />
                    <ErrorMessage
                      name="reason"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Form.Group>
                  {userData.role !== 'member' && (
                    <TeamMembersDropdown teamMembersList={teamMembersList} />
                  )}
                  <Form.Group controlId="wsNotes">
                    <Form.Label>Notes</Form.Label>
                    <Field
                      as="textarea"
                      maxLength="305"
                      name="notes"
                      rows="3"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="notes"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Form.Group>
                  {project.isMobileTracking && (
                    <Form.Group controlId="worksessionType">
                      <Form.Label>Worksession Type</Form.Label>
                      <Field
                        className="form-select"
                        as="select"
                        name="workSessionType"
                      >
                        <option value="desktop">Desktop</option>
                        <option value="mobile">Mobile</option>
                      </Field>
                    </Form.Group>
                  )}
                </section>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

ProjectAddWorkSession.propTypes = {
  teamMembersList: PropTypes.array,
  project: PropTypes.object,
  userData: PropTypes.object,
  handleUpdateWsData: PropTypes.func,
  handleDateChange: PropTypes.func,
};

export default ProjectAddWorkSession;
