import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
const Websocket = forwardRef(
  (
    {
      url,
      protocol,
      reconnect = true,
      debug = false,
      reconnectIntervalInMilliSeconds,
      onOpen,
      onClose,
      onError,
      onMessage,
    },
    ref
  ) => {
    const [attempts, setAttempts] = useState(1);
    const ws = useRef(null);
    const shouldReconnect = useRef(reconnect);
    const timeoutID = useRef(null);

    const logging = (message) => {
      if (debug) {
        // eslint-disable-next-line
        console.log(message);
      }
    };

    const generateInterval = useCallback(
      (k) => {
        if (reconnectIntervalInMilliSeconds > 0) {
          return reconnectIntervalInMilliSeconds;
        }
        // eslint-disable-next-line no-restricted-properties
        return Math.min(30, Math.pow(2, k) - 1) * 1000;
      },
      [reconnectIntervalInMilliSeconds]
    );

    const setupWebSocket = useCallback(() => {
      ws.current = new WebSocket(url, protocol);

      ws.current.onopen = () => {
        logging('WebSocket connected');
        if (typeof onOpen === 'function') {
          onOpen();
        }
        setAttempts(1);
      };

      ws.current.onerror = (e) => {
        if (typeof onError === 'function') {
          onError(e);
        }
      };

      ws.current.onmessage = (evt) => {
        if (typeof onMessage === 'function') {
          onMessage(evt.data);
        }
      };

      ws.current.onclose = (evt) => {
        logging(
          `WebSocket disconnected: reason=${evt.reason}, code=${evt.code}`
        );
        if (typeof onClose === 'function') {
          onClose(evt.code, evt.reason);
        }

        if (shouldReconnect.current) {
          logging('WebSocket reconnect');
          const time = generateInterval(attempts);
          timeoutID.current = setTimeout(() => {
            setAttempts((prev) => prev + 1);
            setupWebSocket();
          }, time);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      attempts,
      generateInterval,
      onClose,
      onError,
      onMessage,
      onOpen,
      protocol,
      url,
    ]);

    const closeWebSocket = useCallback(() => {
      shouldReconnect.current = false;
      clearTimeout(timeoutID.current);
      ws.current?.close();
    }, []);

    const sendMessage = useCallback((message) => {
      if (ws.current?.readyState === WebSocket.OPEN) {
        ws.current.send(message);
      } else {
        logging('WebSocket is not open. Cannot send message.');
      }
    }, []);

    useImperativeHandle(ref, () => ({
      sendMessage,
      closeWebSocket,
      openWebSocket: setupWebSocket,
    }));

    useEffect(() => {
      setupWebSocket();

      window.addEventListener('online', setupWebSocket);
      window.addEventListener('offline', closeWebSocket);

      return () => {
        closeWebSocket();
        window.removeEventListener('online', setupWebSocket);
        window.removeEventListener('offline', closeWebSocket);
      };
    }, [closeWebSocket, setupWebSocket]);

    return null;
  }
);

Websocket.propTypes = {
  url: PropTypes.string.isRequired,
  protocol: PropTypes.string,
  reconnect: PropTypes.bool,
  debug: PropTypes.bool,
  reconnectIntervalInMilliSeconds: PropTypes.number,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  onMessage: PropTypes.func.isRequired,
};

export default Websocket;
