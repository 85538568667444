import { useEffect, useState, useRef } from 'react';
import {
  Card,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  Button,
  Spinner,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from '../Loader';
import { settingsService } from '../../services';
import {
  API_FAILURE_ERROR,
  SETTINGS_BILLING,
  DATE_AND_TIME,
} from '../../constants';
import '../../assets/styles/editable-box.scss';

import { converToDate } from '../../helpers/functions';
import { setBillingDetails } from '../../actions/billing';

const yup = require('yup');

const PaymentInfo = () => {
  const [details, setdetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [emailState, setEmailState] = useState({
    show: false,
    valid: true,
    isSubmitting: false,
  });

  const schema = yup.object().shape({
    billingEmail: yup
      .string()
      .required('Email address is required')
      .email(SETTINGS_BILLING.INVALID_EMAIL),
  });

  const orginEmail = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    settingsService
      .payment_info(localStorage.getItem('teamOwnerId'))
      .then((response) => {
        if ('data' in response) {
          setdetails(response.data);
          orginEmail.current = response.data.billingEmail;

          /* Used in invoicelist */
          const paymentDetails = {};
          paymentDetails.digits = response.data.cardDigits;
          paymentDetails.email = response.data.billingEmail;
          if (response.data.billingAddress) {
            paymentDetails.address = response.data.billingAddress;
          }

          dispatch(setBillingDetails(paymentDetails));
        }
        setIsLoading(true);
      })
      .catch((errResp) => {
        setIsLoading(false);
      });
  }, []);

  /* Load all payment method paths in array and return based on the param */
  function getImagePath(cardType) {
    const reqSvgs = require.context(
      '../../assets/img/payment-methods',
      true,
      /\.svg$/
    );

    return reqSvgs
      .keys()
      .map((path) => {
        const key = path.substring(
          path.lastIndexOf('/') + 1,
          path.lastIndexOf('.')
        );

        return key === cardType ? reqSvgs(path).default : 0;
      })
      .filter((el) => el !== 0);
  }

  const cardUpdate = () => {
    setIsLoading(true);
    const data = {
      ownerId: localStorage.getItem('teamOwnerId'),
      pageType: 1,
      planId: 0,
      memberCount: 0,
      planType: 0,
    };
    settingsService
      .get_hosted_page_url(data)
      .then((response) => {
        if ('data' in response) {
          window.location.href = response.data.url;
        }
        setIsLoading(false);
      })
      .catch((errResp) => {
        toast.error(API_FAILURE_ERROR);
        setIsLoading(false);
      });
  };

  const updateEmail = () => {
    schema
      .validate({
        billingEmail: details.billingEmail,
      })
      .then(function (emailValid) {
        if (emailValid.billingEmail === orginEmail.current) {
          setEmailState({
            ...emailState,
            show: true,
            valid: false,
            isSubmitting: false,
          });

          document.getElementById('errBlock').innerHTML =
            SETTINGS_BILLING.EMAIL_EXISTS;
          return;
        }
        setEmailState({
          ...emailState,
          show: false,
          valid: true,
          isSubmitting: true,
        });

        const data = {
          teamOwnerId: localStorage.getItem('teamOwnerId'),
          email: details.billingEmail,
        };
        settingsService
          .update_billing_email(data)
          .then(() => {
            orginEmail.current = details.billingEmail;
            toast.success(SETTINGS_BILLING.EMAIL_UPDATE);
            setEmailState({
              ...emailState,
              show: false,
              valid: true,
              isSubmitting: false,
            });
          })
          .catch((err) => {
            setEmailState({
              ...emailState,
              show: false,
              valid: true,
              isSubmitting: false,
            });
            toast.error(API_FAILURE_ERROR);
          });
      })
      .catch((err) => {
        setEmailState({
          ...emailState,
          show: true,
          valid: false,
          isSubmitting: false,
        });

        const errors = err.errors[0];
        document.getElementById('errBlock').innerHTML = errors;
      });
  };

  const handleChange = (newVal) => {
    setdetails({ ...details, billingEmail: newVal });
  };

  const handleCancel = () => {
    setEmailState({
      ...emailState,
      show: false,
      valid: true,
      isSubmitting: false,
    });
    if (orginEmail.current !== details.billingEmail) {
      setdetails({ ...details, billingEmail: orginEmail.current });
    }
  };

  return isLoading ? (
    <Card>
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h4 className="card-header-title">Payment information</h4>
          </Col>
          <Col md="auto">
            <Button className="btn btn-sm btn-primary" onClick={cardUpdate}>
              Update card
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <ListGroup variant="flush" className=" my-n3">
          {details.cardType && (
            <ListGroupItem className="px-0">
              <Row className="align-items-center">
                <Col md="auto">
                  <img
                    src={getImagePath(details.cardType)}
                    alt="..."
                    style={{
                      maxWidth: '38px',
                    }}
                  />
                </Col>
                <Col className="ms-n2">
                  <h4 className="mb-1">
                    <span className="capitalize">
                      {details.cardType.replace('_', ' ')}
                    </span>{' '}
                    ending in {details.cardDigits}
                  </h4>
                  <small className="text-muted d-block">
                    {`${details.firstName} ${details.lastName || ''}`}
                  </small>
                  <small className="text-muted">Expires {details.expiry}</small>
                </Col>
              </Row>
            </ListGroupItem>
          )}
          <ListGroupItem className="px-0">
            <Row className="align-items-center">
              <Col>
                <h5 className="mb-1">
                  Billing email
                  {!emailState.show && (
                    <span
                      tabIndex={0}
                      role="button"
                      className="text-muted ms-3"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setEmailState({
                          ...emailState,
                          show: true,
                        });
                      }}
                      onKeyDown={() => {
                        setEmailState({
                          ...emailState,
                          show: true,
                        });
                      }}
                    >
                      {details.billingEmail ? details.billingEmail : 'Empty'}
                    </span>
                  )}
                  {emailState.show && (
                    <span className="editable-container editable-inline ms-2">
                      <form className="form-inline editableform">
                        <div className="control-group form-group">
                          <div
                            className="editable-input"
                            style={{ position: 'relative' }}
                          >
                            <input
                              name="billingEmail"
                              type="text"
                              className="form-control form-control-sm"
                              value={
                                details.billingEmail ? details.billingEmail : ''
                              }
                              onChange={(e) => handleChange(e.target.value)}
                            />
                          </div>
                          <div className="editable-buttons">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm "
                              onClick={updateEmail}
                            >
                              <i className="mdi mdi-check" aria-hidden="true" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-white btn-sm ms-2"
                              onClick={handleCancel}
                            >
                              <i className="mdi mdi-close" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </span>
                  )}
                  {emailState.isSubmitting && (
                    <Spinner
                      animation="border"
                      className="text-primary ms-2"
                      role="status"
                      size="sm"
                    />
                  )}
                  <div className="editable-error-block">
                    {!emailState.valid && <span id="errBlock" />}
                  </div>
                </h5>
              </Col>
              {/* <Col md="auto">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary"
                  onClick={() => {
                    setEmailState({
                      ...emailState,
                      show: true,
                    });
                  }}
                >
                  Edit
                </button>
              </Col> */}
            </Row>
          </ListGroupItem>

          <ListGroupItem className="px-0">
            <h5 className="mb-1">
              Next billing date
              <span
                className="text-muted ms-3"
                id="billingEmail"
                data-original-title=""
                title=""
              >
                {details.nextBilling
                  ? converToDate(details.nextBilling, DATE_AND_TIME.DATE_RANGE)
                  : 'Date not available'}
              </span>
            </h5>
          </ListGroupItem>
        </ListGroup>
      </Card.Body>
    </Card>
  ) : (
    <Loader contentAreaOnly />
  );
};

export default PaymentInfo;
