import { Component } from 'react';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';

import Layout from '../DashboardLayout';
import GeneralForm from '../../components/settings/GeneralForm';
import AvatarUpload from '../../components/settings/AvatarUpload';
import SettingsTabs from '../../components/settings/Tabs';
import DeleteModal from '../../components/DeleteModal';
import Loader from '../../components/Loader';
import { settingsService } from '../../services';
import { GENERAL, AVATAR_SIZE, LOCAL_STORAGE_USER_DATA } from '../../constants';
import { updateLocalStorage } from '../../helpers';

class Profile extends Component {
  userdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));

  /* properties for delete confirmation modal */
  modalProps = {
    title: GENERAL.AVATAR_DELETE_MODAL,
    size: 'sm',
    style: 'width:500px',
    body: false,
  };

  pageHeading = {
    preTitle: 'Settings',
    title: 'Profile',
  };

  state = {
    isSubmitting: false,
    avatar: {
      imgPath: /^https:\/\//.test(this.userdata.avatar)
        ? this.userdata.avatar
        : '',
      fname: this.userdata.firstName,
      lname: this.userdata.lastName || '',
      showDelModal: false,
    },
    contactInfo: {
      fname: this.userdata.firstName,
      lname: this.userdata.lastName || '',
      email: this.userdata.email,
      phone: this.userdata.phoneNumber,
      timezone: this.userdata.timeZone,
      mobile: this.userdata.isMobileTracking === true ? 1 : 0,
      currency: this.userdata.defaultCurrency,
    },
  };

  handleImgUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      const { files } = event.target;
      if (!files[0].name.match(/.(jpg|jpeg|png)$/i)) {
        toast.error(GENERAL.AVATAR_TYPE);
        return;
      }

      if (files[0].size > AVATAR_SIZE) {
        toast.error(GENERAL.AVATAR_SIZE);
        return;
      }
      reader.readAsDataURL(files[0]);
      this.setState((state) => ({
        ...state,
        isSubmitting: true,
      }));

      reader.onload = (e) => {
        /* resize the image */
        const img = document.createElement('img');
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          const MAX_WIDTH = 75;
          const MAX_HEIGHT = 75;
          let { width, height } = img;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
          canvas.width = width;
          canvas.height = height;
          ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          const dataurl = canvas.toDataURL('image/png');
          /* if the image data is migrated and doesnot have url */
          let imgExists = 0;
          if (/^https:\/\//.test(this.userdata.avatar)) {
            imgExists = 1;
          }
          /* send resized image to server */
          const formData = {
            image: dataurl,
            exists: imgExists,
          };
          settingsService
            .update_avatar(formData)
            .then((succMsg) => {
              toast.success(GENERAL.AVATAR_SUCCESS);
              const avatar = {
                imgPath: succMsg.data,
                imgExist: true,
                fname: this.userdata.firstName,
                lname: this.userdata.lastName || '',
              };
              this.setState((state) => ({
                ...state,
                avatar,
                isSubmitting: false,
              }));
              /* update avatar in local storage */
              updateLocalStorage({ avatar: avatar.imgPath });
            })
            .catch((errResp) => {
              let newMsg = errResp.message;
              if (errResp.data[0].msg) {
                newMsg += ` ${errResp.data[0].msg}`;
              }
              toast.error(newMsg);
              this.setState((state) => ({
                ...state,
                isSubmitting: false,
              }));
            });
        }; /* end img onload */
        img.src = e.target.result;
      }; /* end filereader */
    } /* end if */
  }; /* end handle img */

  /* show delete confirmation modal */
  showDeleteModal = () => {
    this.setState((state) => ({
      ...state,
      showDelModal: true,
    }));
  };

  /* hide delete confirmation modal */
  hideDeleteModal = () => {
    this.setState((state) => ({
      ...state,
      showDelModal: false,
    }));
  };

  deleteAvatar = () => {
    this.hideDeleteModal();
    this.setState((state) => ({
      ...state,
      isSubmitting: true,
      avatar: {
        showDelModal: false,
        fname: this.userdata.firstName,
        lname: this.userdata.lastName || '',
      },
    }));
    /*  if avatar exists, send request to delete */
    if (this.userdata.avatar !== '') {
      settingsService
        .delete_avatar()
        .then(() => {
          toast.success(GENERAL.AVATAR_DELETE);

          this.setState((state) => ({
            ...state,
            isSubmitting: false,
            avatar: {
              imgPath: '',
              showDelModal: false,
              fname: this.userdata.firstName,
              lname: this.userdata.lastName,
            },
          }));
          updateLocalStorage({ avatar: '' });
        })
        .catch((errResp) => {
          toast.error(errResp.message);
        });
    } else {
      this.setState((state) => ({
        ...state,
        isSubmitting: false,
        avatar: {
          imgPath: '',
          showDelModal: false,
          fname: this.userdata.firstName,
          lname: this.userdata.lastName,
        },
      }));
    }
  };

  handleUserUpdate = (formdata) => {
    const newFormData = {
      firstName: formdata.firstname,
      lastName: formdata.lastname,
      email: formdata.emailaddress,
      phoneNumber: formdata.phone,
      timeZone: formdata.timezone,
      defaultCurrency: formdata.currency,
      isMobileTracking: formdata.mobilecheck === true ? 1 : 0,
    };
    this.setState((state) => ({
      ...state,
      isSubmitting: true,
    }));

    settingsService
      .user_update({ ...newFormData })
      .then(() => {
        const { avatar } = this.state;
        const stateUpdate = {
          avatar,
        };

        if (avatar.imgPath === '') {
          stateUpdate.avatar.fname = newFormData.firstName;
          stateUpdate.avatar.lname = newFormData.lastName;
        }

        this.setState((state) => ({
          ...state,
          isSubmitting: false,
          stateUpdate,
        }));
        toast.success(GENERAL.USER_UPDATE_SUCCESS);
        // Update user details in storage
        this.userdata.email = newFormData.email;
        this.userdata.firstName = newFormData.firstName;
        this.userdata.lastName = newFormData.lastName;
        this.userdata.phoneNumber = newFormData.phoneNumber;
        this.userdata.defaultCurrency = newFormData.defaultCurrency;
        this.userdata.isMobileTracking = !!newFormData.isMobileTracking;
        this.userdata.timeZone = newFormData.timeZone;
        if (this.userdata._id === this.userdata.defaultTeam.teamOwnerId) {
          this.userdata.defaultTeam.isMobileTracking =
            !!newFormData.isMobileTracking;
        }
        updateLocalStorage(this.userdata);
      })
      .catch((errResponse) => {
        this.setState((state) => ({
          ...state,
          isSubmitting: false,
        }));
        if ('data' in errResponse) {
          errResponse.data.forEach((elem) => {
            toast.error(`${elem.msg} ${elem.param}`);
          });
        } else if ('message' in errResponse) {
          toast.error(errResponse.message);
        } else {
          toast.error(errResponse);
        }
      });
  };

  render() {
    const { isSubmitting, avatar, showDelModal, contactInfo } = this.state;

    return (
      <Layout pageHeading={this.pageHeading}>
        {isSubmitting && <Loader contentAreaOnly />}
        <DeleteModal
          modalProps={this.modalProps}
          showModal={showDelModal}
          handleHideModal={this.hideDeleteModal}
          handleDelete={this.deleteAvatar}
        />
        <Row className="justify-content-center">
          <Col className="col-12 col-lg-10 col-xl-8">
            <SettingsTabs selectedTab={this.pageHeading.title} />
            <div className="tab-content">
              <div className="tab-pane show active">
                <AvatarUpload
                  avatarState={avatar}
                  uploadAvatar={this.handleImgUpload}
                  showDelete={this.showDeleteModal}
                />
                <GeneralForm
                  contactInfo={contactInfo}
                  handleUpdate={this.handleUserUpdate}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default Profile;
