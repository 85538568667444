import {
  BASE_API_URL,
  SURVEY_QUESTIONS,
  SURVEY_ID,
  SURVEY_NAME,
} from '../constants';
import { AppService, HTTPHeaders } from './app.service';

const options = AppService.options();
export const SurveyService = {
  get_survey_question_list: async () => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${
        BASE_API_URL + SURVEY_QUESTIONS.GET_SURVEY_QUESTIONS
      }/${`?surveyId=${SURVEY_ID}&surveyName=${SURVEY_NAME}`}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_name: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = { method: 'POST' };
    const response = await AppService.makeRequest(
      BASE_API_URL + SURVEY_QUESTIONS.UPDATE_NAME,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_member: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = { method: 'PUT' };
    const response = await AppService.makeRequest(
      BASE_API_URL + SURVEY_QUESTIONS.UPDATE_ACCOUNT,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_survey_answer: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = { method: 'POST' };
    const response = await AppService.makeRequest(
      BASE_API_URL + SURVEY_QUESTIONS.UPDATE_SURVEY_ANSWER,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  invite_accept: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = { method: 'PUT' };
    const response = await AppService.makeRequest(
      BASE_API_URL + SURVEY_QUESTIONS.INVITE_ACCEPT,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  send_owner_email: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = { method: 'POST' };
    const response = await AppService.makeRequest(
      BASE_API_URL + SURVEY_QUESTIONS.SEND_OWNER_EMAIL,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  create_project: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + SURVEY_QUESTIONS.CREATE_PROJECT_BASIC,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
};
