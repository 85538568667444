import PropTypes from 'prop-types';
import { Tabs, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function SettingsTabs({ selectedTab }) {
  const history = useHistory();

  const userDetails = useSelector((state) => state.userReducer);
  return (
    <Tabs
      defaultActiveKey={selectedTab.toLowerCase().replace(' ', '-')}
      className="mb-4"
      onSelect={(nextTab) =>
        history.push(`/settings/${nextTab}`, { from: 'HomePage' })
      }
    >
      {userDetails.role === 'creator' && (
        <Tab eventKey="general" title="General" />
      )}
      <Tab eventKey="profile" title="Profile" />
      <Tab eventKey="password" title="Password" />
      {userDetails.role === 'creator' && (
        <Tab eventKey="billing" title="Billing" />
      )}
      <Tab eventKey="teams" title="Teams" />
      {userDetails.role !== 'member' && (
        <Tab eventKey="email-summaries" title="Email summaries" />
      )}
      {userDetails.role === 'creator' && (
        <Tab eventKey="integration-status" title="Integrations" />
      )}
    </Tabs>
  );
}
SettingsTabs.propTypes = {
  selectedTab: PropTypes.string,
};
export default SettingsTabs;
