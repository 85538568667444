import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';

const WorkSessionApprovalToggle = ({
  status,
  wsDetails,
  wsRequestStatusUpdate,
  handleSelectedRequest,
  handleEditOnApprovalModal,
}) => {
  const [sessionProjectId] = useState(
    wsDetails.newProject && wsDetails.newProject !== null
      ? wsDetails.newProject._id
      : wsDetails.oldProject._id
  );
  switch (status) {
    case 'approve':
      return (
        <Dropdown>
          <Dropdown.Toggle
            as={Button}
            size="sm"
            variant="white"
            className="mt-2"
            to={{ hash: '#' }}
          >
            <i className="mdi mdi-check-circle me-1 text-success" />
            Approved
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="dropdown-menu-right p-3 mt-1"
            style={{ minWidth: '300px' }}
          >
            <ul className="list-unstyled mb-0">
              <li>
                <small>
                  <span className="text-muted">
                    {moment(wsDetails.requestedDate).format('MMM D, hh:mm A')}{' '}
                  </span>
                  {`${wsDetails.requestedBy.firstName} ${
                    wsDetails.requestedBy.lastName || ''
                  } requested a work session edit`}
                </small>
              </li>
              <li>
                <small>
                  <span className="text-muted">
                    {moment(wsDetails.updatedDate).format('MMM D, hh:mm A')}{' '}
                  </span>
                  {`${wsDetails.updatedBy.firstName} ${
                    wsDetails.updatedBy.lastName || ''
                  } approved the request`}
                  {wsDetails.updateRemark && (
                    <span>
                      , adding the remark: <em>{wsDetails.updateRemark}</em>
                    </span>
                  )}
                </small>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      );
    case 'reject':
      return (
        <Dropdown>
          <Dropdown.Toggle
            as={Button}
            size="sm"
            variant="white"
            className="mt-2"
            to={{ hash: '#' }}
          >
            <i className="mdi mdi-close-circle me-1 text-danger" />
            Rejected
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="dropdown-menu-right p-3 mt-1"
            style={{ minWidth: '300px' }}
          >
            <ul className="list-unstyled mb-0">
              <li>
                <small>
                  <span className="text-muted">
                    {moment(wsDetails.requestedDate).format('MMM D, hh:mm A')}{' '}
                  </span>
                  {`${wsDetails.requestedBy.firstName} ${
                    wsDetails.requestedBy.lastName || ''
                  } requested a work session edit`}
                </small>
              </li>
              <li>
                <small>
                  <span className="text-muted">
                    {moment(wsDetails.updatedDate).format('MMM D, hh:mm A')}{' '}
                  </span>
                  {`${wsDetails.updatedBy.firstName} ${
                    wsDetails.updatedBy.lastName || ''
                  } rejected the request`}
                  {wsDetails.updateRemark && (
                    <span>
                      , adding the remark: <em>{wsDetails.updateRemark}</em>
                    </span>
                  )}
                </small>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      );
    default:
      return (
        <Dropdown>
          <Dropdown.Toggle
            as={Button}
            size="sm"
            variant="white"
            className="mt-2"
            to={{ hash: '#' }}
          >
            <i className="mdi mdi-check-circle me-1 text-warning" />
            Pending for approval
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="dropdown-menu-right p-3 mt-1"
            style={{ minWidth: '300px' }}
          >
            <Formik
              initialValues={{
                remarks: '',
              }}
              onSubmit={(values, { resetForm }) => {
                wsRequestStatusUpdate({
                  workSessionId: wsDetails.workSessionId,
                  requestId: wsDetails._id,
                  status: 'approve',
                  updateRemark: values.remarks,
                });
                resetForm({ notes: '' });
              }}
            >
              {(formikprops) => (
                <Form onSubmit={formikprops.handleSubmit}>
                  <Form.Group controlId="wsNotes" className="mb-3">
                    <Field
                      as="textarea"
                      maxLength="300"
                      name="remarks"
                      rows="2"
                      className="form-control"
                      placeholder="Remarks"
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-start">
                    <Button
                      variant="primary"
                      type="submit"
                      size="sm"
                      className="me-2"
                    >
                      Approve
                    </Button>
                    <Button
                      variant="white"
                      type="button"
                      size="sm"
                      className="me-2"
                      onClick={() => {
                        handleSelectedRequest({
                          ...wsDetails,
                          remark: formikprops.values.remarks,
                        });
                        handleEditOnApprovalModal(true, sessionProjectId);
                      }}
                    >
                      Edit & Approve
                    </Button>
                    <Button
                      variant="white"
                      type="button"
                      size="sm"
                      onClick={() => {
                        if (formikprops.values.remarks.length > 0) {
                          wsRequestStatusUpdate({
                            workSessionId: wsDetails.workSessionId,
                            requestId: wsDetails._id,
                            status: 'reject',
                            updateRemark: formikprops.values.remarks,
                          });
                        } else {
                          toast.error('Please enter rejection remarks');
                        }
                      }}
                    >
                      Reject
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <ul className="list-unstyled mt-4">
              <li>
                <small>
                  <span className="text-muted">
                    {moment(wsDetails.requestedDate).format('MMM D, hh:mm A')}{' '}
                  </span>
                  {`${wsDetails.requestedBy.firstName} ${
                    wsDetails.requestedBy.lastName || ''
                  } requested a work session edit`}
                </small>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      );
  }
};

WorkSessionApprovalToggle.propTypes = {
  status: PropTypes.string,
  wsDetails: PropTypes.object,
  wsRequestStatusUpdate: PropTypes.func,
  handleSelectedRequest: PropTypes.func,
  handleEditOnApprovalModal: PropTypes.func,
};

export default WorkSessionApprovalToggle;
