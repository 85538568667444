import React, { useRef, useEffect, useState, useMemo } from 'react';
import {
  Card,
  ButtonGroup,
  Button,
  Row,
  Col,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { useSelector } from 'react-redux';
import { DATE_AND_TIME } from '../../constants';
import '../../assets/styles/full-calendar.scss';
import AddSession from './AddSession';
import EditSession from './EditSession';
import { projectService } from '../../services';
import { getUserData } from '../../helpers';

const Calendar = ({
  handleGetEvents,
  handleShowAddSessionModal,
  showAddSessionModal,
  showEditSessionModal,
  handleShowEditSessionModal,
  handleUpdateWorkSession,
  isRefetchEvents,
  handleAddWorkSession,
  handleLoader,
}) => {
  const timelineCalendarRef = useRef(null);
  const weekPicker = useRef(null);
  const [calenderTitle, setCalendarTitle] = useState('');
  const [selectedView, setSelectedView] = useState('timeGridWeek');
  const [weekStart, setWeekStart] = useState(moment().day(0));
  const [weekEnd, setWeekEnd] = useState(moment().day(6));
  const [dayStart, setDayStart] = useState(moment());
  const userDetails = useSelector((state) => state.userReducer);
  const [userPermissions, setUserPermissions] = useState(null);
  const [isCheckingPermissions, setIsCheckingPermissions] = useState(false);

  useEffect(() => {
    if (timelineCalendarRef.current) {
      const fullCalendar = timelineCalendarRef.current;
      setCalendarTitle(fullCalendar.calendar.view.title);
    }
  }, []);

  const getEventsData = useMemo(
    () => handleGetEvents(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Function to re fetch worksession data whenever required.
  // eslint-disable-next-line no-unused-vars
  const handleReFetchEvents = useMemo(() => {
    const { current: calendarDom } = timelineCalendarRef;
    const calendarApi = calendarDom ? calendarDom.getApi() : null;
    if (calendarDom) {
      calendarApi.refetchEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetchEvents]);

  // Event block and content
  const renderEventContent = (eventInfo) => (
    <>
      {/* Display task, checkIn and checkOut only when workSessionSeconds more then one hour */}
      {eventInfo.event.extendedProps.workSessionSeconds > 3600 ? (
        <div className="p-2 text-wrap">
          <p className="calender-task-title mb-0">
            <strong>{eventInfo.event.extendedProps.project}</strong>
            <br />
            {eventInfo.event.extendedProps.task !== null &&
              eventInfo.event.extendedProps.task}
          </p>
          {eventInfo.event.extendedProps.isEditable ? (
            <span className="pt-3">
              {moment(eventInfo.event.start).format(DATE_AND_TIME.TIME)} -{' '}
              {moment(eventInfo.event.end).format(DATE_AND_TIME.TIME)}
            </span>
          ) : (
            <span className="pt-3">
              {moment(eventInfo.event.start).format(DATE_AND_TIME.TIME)} -{' '}
            </span>
          )}
        </div>
      ) : (
        <div className="p-2 text-wrap">
          <p className="calender-task-title mb-0">
            <strong>{eventInfo.event.extendedProps.project}</strong>
          </p>
        </div>
      )}
    </>
  );

  const CustomCalendarButtons = ({ calendar }) => {
    const handleNextClick = () => {
      const fullCalendar = calendar.current.getApi();
      fullCalendar.next();
      const { activeStart, title } = fullCalendar.view;
      setCalendarTitle(title);
      setWeekStart(moment(activeStart));
      setWeekEnd(moment(activeStart).day(6));
      setDayStart(moment(activeStart));
    };
    const handlePrevClick = () => {
      const fullCalendar = calendar.current.getApi();
      fullCalendar.prev();
      const { activeStart, title } = fullCalendar.view;
      setCalendarTitle(title);
      setWeekStart(moment(activeStart));
      setWeekEnd(moment(activeStart).day(6));
      setDayStart(moment(activeStart));
    };
    return (
      <ButtonGroup className="mb-2">
        <Button onClick={handlePrevClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#ffffff"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
          </svg>
        </Button>
        <Button onClick={handleNextClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#ffffff"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
          </svg>
        </Button>
      </ButtonGroup>
    );
  };

  CustomCalendarButtons.propTypes = {
    calendar: PropTypes.any,
  };

  const CustomViewSwitchButtons = ({ calendar }) => {
    const handleWeekViewClick = () => {
      const fullCalendar = calendar.current.getApi();
      fullCalendar.changeView('timeGridWeek');
      setSelectedView('timeGridWeek');
      const { activeStart, title } = fullCalendar.view;
      setCalendarTitle(title);
      setDayStart(moment(activeStart));
    };

    const handleDayViewClick = () => {
      const fullCalendar = calendar.current.getApi();
      fullCalendar.changeView('timeGridDay');
      setSelectedView('timeGridDay');
      const { activeStart, title } = fullCalendar.view;
      setCalendarTitle(title);
      setDayStart(moment(activeStart));
    };

    return (
      <DropdownButton
        title={selectedView === 'timeGridWeek' ? 'Week View' : 'Day View'}
        variant="white"
      >
        <Dropdown.Item as="button" onClick={handleWeekViewClick}>
          Week View
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={handleDayViewClick}>
          Day View
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  CustomViewSwitchButtons.propTypes = {
    calendar: PropTypes.any,
  };

  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);

  const handleEventClick = ({ event }) => {
    if (event.extendedProps.isEditable) {
      if (userDetails.role === 'member') {
        handleLoader(true);
        setIsCheckingPermissions(true);
        // Fetch project details to verify if user have permission to edit the worksession
        projectService
          .get_projects_details({
            teamOwnerId: localStorage.getItem('teamOwnerId'),
            projectId: event.extendedProps.projectId,
          })
          .then((response) => {
            if (response.data) {
              const userId = getUserData('_id');
              const currentUser = response.data.members.find(
                (member) => userId === member.userId
              );
              setTimeout(() => {
                setUserPermissions(currentUser.permissions);
              }, 0);
            }
          })
          .then(() => {})
          .finally(() => {
            handleLoader(false);
            setIsCheckingPermissions(false);
          });
      }
      setSelectedEvent(event);
      handleShowEditSessionModal(true);
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    handleShowAddSessionModal(true);
  };

  const handleDragAndResize = (eInfo) => {
    const { event } = eInfo;
    handleUpdateWorkSession({
      scheduleId: event.id,
      checkInDate: event.start,
      checkOutDate: event.end,
      reason: 'session update',
      project: event.extendedProps.projectId,
      task: event.extendedProps.taskId,
    });
  };

  const handleDateChange = (event, picker, dateRangePicker) => {
    setWeekStart(moment(picker.startDate).day(0));
    setWeekEnd(moment(picker.endDate).day(6));
    if (dateRangePicker === 'wPicker') {
      setDayStart(moment(picker.startDate).day(0));
    } else {
      setDayStart(moment(picker.startDate));
    }

    // Update calendar
    if (timelineCalendarRef.current) {
      const fullCalendar = timelineCalendarRef.current.getApi();
      const startDate = new Date(picker.startDate);
      // Set the start date for the view
      fullCalendar.gotoDate(startDate);
      const { title } = fullCalendar.view;
      setCalendarTitle(title);
    }
  };

  const handleSelect = (sInfo) => {
    setSelectedDate(sInfo);
    handleShowAddSessionModal(true);
  };

  return (
    <Card className="card-calendar">
      <Card.Body className="p-0">
        <section className="hd-calendar hd-track-calendar">
          <Row className="pt-3 pb-2 px-3">
            <Col>
              <h4 className="mt-3 mb-0">{calenderTitle}</h4>
            </Col>
            <Col className="col-auto">
              {selectedView === 'timeGridWeek' ? (
                <div id="week-picker-wrapper" style={{ position: 'relative' }}>
                  <DateRangePicker
                    ref={weekPicker}
                    onApply={(event, picker) =>
                      handleDateChange(event, picker, 'wPicker')
                    }
                    initialSettings={{
                      autoUpdateInput: false,
                      singleDatePicker: true,
                      startDate: weekStart,
                      endDate: weekEnd,
                      locale: {
                        format: 'MMM D, YYYY',
                      },
                      alwaysShowCalendars: true,
                      autoApply: true,
                      parentEl: '#week-picker-wrapper',
                    }}
                  >
                    <input
                      type="text"
                      className="form-control icon-calendar week-selector"
                      value={`${moment(weekStart).format(
                        'MMM D, YYYY'
                      )} - ${moment(weekEnd).format('MMM D, YYYY')}`}
                      style={{ minWidth: '250px' }}
                    />
                  </DateRangePicker>
                </div>
              ) : (
                <div id="day-picker-wrapper" style={{ position: 'relative' }}>
                  <DateRangePicker
                    onApply={(event, picker) =>
                      handleDateChange(event, picker, 'dPicker')
                    }
                    initialSettings={{
                      autoUpdateInput: true,
                      singleDatePicker: true,
                      startDate: dayStart,
                      locale: {
                        format: 'MMM D, YYYY',
                      },
                      alwaysShowCalendars: true,
                      autoApply: true,
                      parentEl: '#day-picker-wrapper',
                    }}
                  >
                    <input
                      type="text"
                      className="form-control icon-calendar day-selector"
                      value={`${moment(dayStart).format('MMM D, YYYY')}`}
                    />
                  </DateRangePicker>
                </div>
              )}
            </Col>
            <Col className="col-auto">
              <CustomViewSwitchButtons calendar={timelineCalendarRef} />
            </Col>
            <Col className="col-auto">
              <CustomCalendarButtons calendar={timelineCalendarRef} />
            </Col>
          </Row>
          <FullCalendar
            ref={timelineCalendarRef}
            plugins={[interactionPlugin, timeGridPlugin]}
            headerToolbar={false}
            defaultView="timeGridWeek"
            dayMaxEvents={3}
            events={getEventsData}
            allDaySlot={false}
            nowIndicator
            eventContent={renderEventContent}
            eventDisplay="block"
            eventDrop={handleDragAndResize}
            eventResize={handleDragAndResize}
            eventClick={handleEventClick}
            dateClick={handleDateClick}
            selectable
            select={handleSelect}
            slotEventOverlap={false}
            displayEventTime
            slotLabelFormat={{
              hour: 'numeric',
              omitZeroMinute: true,
            }}
          />
          {showAddSessionModal && (
            <AddSession
              showAddSessionModal={showAddSessionModal}
              handleShowAddSessionModal={handleShowAddSessionModal}
              dateInfo={selectedDate}
              handleAddWorkSession={handleAddWorkSession}
              handleLoader={handleLoader}
            />
          )}
          {showEditSessionModal && (
            <EditSession
              sessionInfo={selectedEvent}
              showEditSessionModal={showEditSessionModal}
              handleShowEditSessionModal={handleShowEditSessionModal}
              handleUpdateWorkSession={handleUpdateWorkSession}
              userPermissions={userPermissions}
              userRole={userDetails.role}
              isCheckingPermissions={isCheckingPermissions}
            />
          )}
        </section>
      </Card.Body>
    </Card>
  );
};

Calendar.propTypes = {
  handleGetEvents: PropTypes.func,
  handleShowAddSessionModal: PropTypes.func,
  showAddSessionModal: PropTypes.bool,
  showEditSessionModal: PropTypes.bool,
  handleShowEditSessionModal: PropTypes.func,
  handleUpdateWorkSession: PropTypes.func,
  isRefetchEvents: PropTypes.bool,
  handleAddWorkSession: PropTypes.func,
  handleLoader: PropTypes.func,
};

export default Calendar;
