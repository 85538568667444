import { PER_PAGE_LIMIT } from '../constants';

const initialState = {
  members: [],
  activeMembers: [],
  metadata: {},
  membersCount: 0,
  isLoading: false,
  loadMore: true,
  page: 1,
  searchParam: '',
  invitationsData: {},
  inactiveMembers: [],
  inactiveMembersMetadata: {},
  inactiveMembersCount: 0,
  inactiveMembersLoadMore: true,
  inactiveMembersPage: 1,
};

export const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TEAM_MEMBERS':
      return {
        ...state,
        members: action.payload,
      };
    case 'FETCHING_MEMBERS':
      return {
        ...state,
        isLoading: true,
      };
    case 'ACTIVATE_LOADER':
      return {
        ...state,
        isLoading: true,
      };
    case 'DEACTIVATE_LOADER':
      return {
        ...state,
        isLoading: false,
      };
    case 'SET_ACTIVE_MEMBERS':
      return {
        ...state,
        isLoading: false,
        activeMembers:
          state.page === 1
            ? [...action.payload.members]
            : [...state.activeMembers, ...action.payload.members],
        loadMore: action.payload.metadata.total / PER_PAGE_LIMIT > state.page,
        page: state.loadMore ? state.page + 1 : state.page,
        metadata: action.payload.metadata,
        membersCount: action.payload.metadata.total,
      };
    case 'SET_INACTIVE_MEMBERS':
      return {
        ...state,
        isLoading: false,
        inactiveMembers:
          state.inactiveMembersPage === 1
            ? [...action.payload.members]
            : [...state.inactiveMembers, ...action.payload.members],
        inactiveMembersLoadMore:
          action.payload.metadata.total / PER_PAGE_LIMIT >
          state.inactiveMembersPage,
        inactiveMembersPage: state.inactiveMembersLoadMore
          ? state.inactiveMembersPage + 1
          : state.inactiveMembersPage,
        inactiveMembersMetadata: action.payload.metadata,
        inactiveMembersCount: action.payload.metadata.total,
      };
    case 'SET_INVITATIONS_DATA':
      return {
        ...state,
        invitationsData: [...action.payload],
      };
    case 'NO_INVITATIONS':
      return {
        ...state,
        invitationsData: {},
      };
    case 'SET_SEARCH_PARAM':
      return {
        ...state,
        page: 1,
        searchParam: action.payload,
      };
    case 'UPDATE_MEMBER_DATA':
      return {
        ...state,
        activeMembers: state.activeMembers.map((item) => {
          if (item.userId === action.payload.userId) {
            return action.payload;
          }
          return item;
        }),
      };
    case 'UPDATE_INACTIVE_MEMBER_DATA':
      return {
        ...state,
        inactiveMembers: state.inactiveMembers.map((item) => {
          if (item.userId === action.payload.userId) {
            return action.payload;
          }
          return item;
        }),
      };
    case 'DEACTIVATE_MEMBER':
      return {
        ...state,
        activeMembers: state.activeMembers.filter(
          (item) => item.userId !== action.payload.userId
        ),
      };
    case 'REACTIVATE_MEMBER':
      return {
        ...state,
        inactiveMembers: state.inactiveMembers.filter(
          (item) => item.userId !== action.payload.userId
        ),
      };
    case 'DELETE_MEMBER':
      return {
        ...state,
        inactiveMembers: state.inactiveMembers.filter(
          (item) => item.userId !== action.payload.userId
        ),
      };
    case 'UPDATE_MEMBER_ROLE':
      return {
        ...state,
        activeMembers: state.activeMembers.map((item) => {
          if (item.userId === action.payload.userId) {
            item.role = action.payload.role;
          }
          return item;
        }),
      };
    case 'NO_RESULTS':
      return {
        ...state,
        isLoading: false,
        activeMembers: [],
        loadMore: false,
        page: 1,
        metadata: {},
        membersCount: 0,
      };
    case 'NO_INACTIVE_RESULTS':
      return {
        ...state,
        isLoading: false,
        inactiveMembers: [],
        inactiveMembersMetadata: {},
        inactiveMembersCount: 0,
        inactiveMembersLoadMore: false,
        inactiveMembersPage: 1,
      };
    case 'RESET_ACTIVE_MEMBERS':
      return {
        ...state,
        isLoading: false,
        activeMembers: [],
        loadMore: true,
        page: 1,
        searchParam: '',
        metadata: {},
        membersCount: 0,
      };
    case 'RESET_INACTIVE_MEMBERS':
      return {
        ...state,
        isLoading: false,
        inactiveMembers: [],
        inactiveMembersMetadata: {},
        inactiveMembersCount: 0,
        inactiveMembersLoadMore: true,
        inactiveMembersPage: 1,
      };
    case 'FETCH_MEMBERS_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const onlineStatus = (state = { members: [] }, action) => {
  switch (action.type) {
    case 'SET_ONLINE_MEMBERS':
      return {
        ...state,
        members: action.payload,
      };
    case 'FETCH_ONLINE_MEMBERS_ERROR':
      return {
        ...state,
        members: [],
      };
    default:
      return state;
  }
};
