import { jsPDF as JSPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { sToHMSColonSeparated, groupBy } from '../../helpers';
import { HELPER_TEXT_BILLABLE_BREAKS } from '../../constants';

const ViewTimecardPDF = (timesheetDetails, fromDate, toDate) => {
  // initialize jsPDF
  const doc = new JSPDF('l', 'pt', 'a3');
  const totalPagesExp = '{total_pages_count_string}';
  const pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  // Table columns
  const tableColumns = {};
  for (let i = 0; i < 20; i += 1) {
    tableColumns[i] = {
      halign: i === 0 ? 'left' : 'center',
    };
  }

  const weeklyStatus = (status) => {
    switch (status) {
      case 'submit':
        return 'Submitted';
      case 'approve':
        return 'Approved';
      case 'reject':
        return 'Rejected';
      case 'withdraw':
        return 'Withdrawn';
      default:
        return 'Not Submitted';
    }
  };

  function calculateTotalBreakTime(jsonData, key) {
    // Check if the key exists in jsonData
    // eslint-disable-next-line no-prototype-builtins
    if (!jsonData.hasOwnProperty(key)) {
      return '-'; // If the key doesn't exist, return 0
    }

    // Get the array associated with the key
    const entries = jsonData[key];
    const allBreaks = entries.map(
      (entry) =>
        `
      ${sToHMSColonSeparated(entry.breakTime / 1000, 'hh:mm:ss')}
        ${moment(entry.startTime).format('hh:mm:ss A')} - ${moment(
          entry.endTime
        ).format('hh:mm:ss A')}
        `
    );

    return allBreaks.join(' ');
  }

  timesheetDetails.forEach((weekData) => {
    doc.setFontSize(10);
    if (weekData.user) {
      doc.text(
        `Employee: ${weekData.user.firstName} ${weekData.user.lastName || ''}`,
        40,
        doc.lastAutoTable ? doc.lastAutoTable.finalY + 45 : 45,
        'left'
      );
    }
    if (fromDate && toDate) {
      doc.text(
        `Period: ${moment(fromDate).format('MMM DD, YYYY')} - ${moment(
          toDate
        ).format('MMM DD, YYYY')}`,
        40,
        doc.lastAutoTable ? doc.lastAutoTable.finalY + 60 : 60,
        'left'
      );
    } else {
      doc.text(
        `Period: ${moment
          .utc(weekData.wsWeekDetails.startDate)
          .format('MMM DD, YYYY')} - ${moment
          .utc(weekData.wsWeekDetails.endDate)
          .format('MMM DD, YYYY')}`,
        40,
        doc.lastAutoTable ? doc.lastAutoTable.finalY + 60 : 60,
        'left'
      );
    }

    doc.text(
      `Status: ${weeklyStatus(weekData.status)}`,
      1150,
      doc.lastAutoTable ? doc.lastAutoTable.finalY + 45 : 45,
      'right'
    );
    doc.text(
      HELPER_TEXT_BILLABLE_BREAKS,
      1150,
      doc.lastAutoTable ? doc.lastAutoTable.finalY + 60 : 60,
      'right'
    );

    let allBreakTypesList = [];
    let breakTypeHeaders = [];
    let breakTypeColumnHeaders = [];
    let breakTypeArray = [];
    weekData.data.forEach((day) => {
      if (day.workSessions.length > 0 && day.totalBreakTimeSeconds > 0) {
        day.workSessions.map((item) =>
          item.breakTimeSeconds > 0
            ? (allBreakTypesList = [...allBreakTypesList, ...item.break])
            : null
        );
      }

      // Group break list by name
      breakTypeArray = groupBy(allBreakTypesList, 'type');
    });

    breakTypeHeaders = Object.values(breakTypeArray).map((bItem) =>
      bItem[0].type && bItem[0].breakName
        ? { breakName: bItem[0].breakName, type: bItem[0].type }
        : { breakName: 'Other', type: 'Other' }
    );

    breakTypeColumnHeaders = Object.entries(breakTypeArray).map(
      ([key, value]) =>
        value[0].type && value[0].breakName
          ? `${value[0].breakName} ${value[0].billable ? ' *' : ''}`
          : 'Other'
    );

    // Table header columns
    const tableColumn = [
      '',
      'Clock In',
      'Clock Out',
      'Total Duration',
      'Productive',
      'Total Breaks',
      ...breakTypeColumnHeaders,
    ];

    // Table body columns
    const tableRows = [];

    // for each worksession pass all its data into an array
    weekData.data.forEach((day) => {
      let breakTypesList = [];
      // eslint-disable-next-line no-unused-vars
      const sessionBreakTypes =
        day.workSessions.length > 0 && day.totalBreakTimeSeconds > 0
          ? day.workSessions.map((item) =>
              item.breakTimeSeconds > 0
                ? (breakTypesList = [...breakTypesList, ...item.break])
                : null
            )
          : [];

      const dayBreakTypes = groupBy(breakTypesList, 'type');

      const breakTimeLogs = breakTypeHeaders.map((breakItem) =>
        calculateTotalBreakTime(
          dayBreakTypes,
          breakItem.type === 'Other' ? 'null' : breakItem.type
        )
      );

      const worksessionData = [
        `${moment(day.date).format('ddd, MMM DD')} ${
          day.schedule && day.schedule.isHoliday ? ' (Holiday)' : ''
        }`,
        day.clockIn ? moment(day.clockIn).format('hh:mm:ss A') : '-',
        day.clockOut ? moment(day.clockOut).format('hh:mm:ss A') : '-',
        day.totalWorkSessionSeconds > 0
          ? sToHMSColonSeparated(day.totalWorkSessionSeconds, 'hh:mm:ss')
          : '00:00:00',
        day.totalWorkSessionSeconds > 0
          ? sToHMSColonSeparated(
              day.totalWorkSessionSeconds -
                day.totalNonBillableBreakTimeSeconds,
              'hh:mm:ss'
            )
          : '00:00:00',
        day.totalBreakTimeSeconds > 0
          ? sToHMSColonSeparated(day.totalBreakTimeSeconds, 'hh:mm:ss')
          : '00:00:00',
        ...breakTimeLogs,
      ];

      // push each session's info into a row
      tableRows.push(worksessionData);
    });

    // Initialize autoTable
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      // foot: [tableFoot],
      startY: doc.lastAutoTable ? doc.lastAutoTable.finalY + 75 : 75,
      theme: 'grid',
      styles: { cellPadding: 10 },
      headStyles: {
        fillColor: [237, 242, 249],
        // fontSize: 9,
        textColor: [40, 62, 89],
        halign: 'center',
      },
      columnStyles: tableColumns,
      footStyles: {
        fillColor: [237, 242, 249],
        textColor: [40, 62, 89],
      },
      tableLineColor: [237, 242, 249],
      tableLineWidth: 1,
      showFoot: 'lastPage',
      didDrawPage() {
        // Header
        doc.setFontSize(14);
        doc.setTextColor(40);
        doc.text('Timecard', pageWidth / 2, 23, 'center');
        doc.setFontSize(10);
        // Footer
        let str = `Page ${doc.internal.getNumberOfPages()}`;
        // Total page number
        if (typeof doc.putTotalPages === 'function') {
          str = `${str} of ${totalPagesExp}`;
        }
        doc.setFontSize(10);
        const { pageSize } = doc.internal;
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, 1100, pageHeight - 22);
      },
    });
  });

  // Total page number
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  // Name of PDF file.
  doc.save(`timecard_detailed_report.pdf`);
};

export default ViewTimecardPDF;
