import PropTypes from 'prop-types';
import { Card, Row } from 'react-bootstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Avatar from '../../Avatar';
import { PAGE_URLS } from '../../../constants';

const RecentActivityCard = ({
  cardTitle,
  recentActivity,
  projectId,
  projectName,
}) => (
  <>
    <Card>
      <Card.Header>
        <h4 className="m-0">{cardTitle}</h4>
        <div className="col-6 text-end">
          <Link
            to={{
              pathname: PAGE_URLS.ACTIVITY.MEMBER,
              search: `?project=${projectId}`,
              state: {
                bredCrumbs: [
                  { name: 'Active Projects', path: PAGE_URLS.PROJECTS.ACTIVE },
                  {
                    name: projectName,
                    path: `/projects/${projectId}/overview`,
                  },
                  { name: 'Member Activity', path: '' },
                ],
              },
            }}
          >
            View all
          </Link>
        </div>
      </Card.Header>
      <Card.Body>
        {recentActivity.length > 0
          ? recentActivity.map((ws, index) => (
              <Row key={index}>
                <div className="d-flex align-items-center">
                  <div className="avatar me-2">
                    <Avatar
                      firstName={ws.user.firstName}
                      lastName={ws.user.lastName}
                      imgPath={ws.user.avatar ? ws.user.avatar : ''}
                    />
                  </div>
                  <div className="mt-3">
                    <span className="name">
                      {ws.user.firstName} {ws.user.lastName || ''}{' '}
                      {'logged a worksession'}
                    </span>
                    <p className="small text-muted mb-3">
                      {ws.checkInDate !== null && (
                        <>
                          {ws.workSessionType === 'desktop' && (
                            <span
                              className="font-size-lg"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="Desktop"
                            >
                              <i className="mdi mdi-desktop-mac" />
                            </span>
                          )}
                          {ws.workSessionType === 'extension' && (
                            <span
                              className="font-size-lg"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="Extension"
                            >
                              <i className="mdi mdi mdi-puzzle" />
                            </span>
                          )}
                          {ws.workSessionType === 'mobile' && (
                            <span
                              className="font-size-lg"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="Mobile"
                            >
                              <i className="mdi mdi-cellphone-iphone" />
                            </span>
                          )}
                          {ws.workSessionType === 'web' && (
                            <span
                              className="font-size-lg"
                              data-toggle="tooltip"
                              title=""
                              data-original-title="Web"
                            >
                              <i className="mdi mdi-web" />
                            </span>
                          )}

                          <time className="small text-muted">
                            {' '}
                            {moment(ws.checkInDate).fromNow()}
                          </time>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </Row>
            ))
          : 'No activity recorded for past two days'}
      </Card.Body>
    </Card>
  </>
);
RecentActivityCard.propTypes = {
  cardTitle: PropTypes.string,
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  recentActivity: PropTypes.array,
};
export default RecentActivityCard;
