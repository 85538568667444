import moment from 'moment';

const { REACT_APP_JUNE_WRITE_KEY } = process.env;
// Auth
export const AUTH_TOKEN_NAME = 'access_token';
export const AUTH_KEY = 'cmVhY3Rfd2ViX2FwcDpuNGEzY25zMWRlMGM5ZTJlOHRk';
export const THIRD_PARTY_AUTH_KEY =
  'N0d3dWhtY3JUN1VqUDBkS0tlVVdFRFMybTpBdExsUk8wMVI2aHNYVnU3RUVibFhSS3BmWUoxQjFJS2dROU1zUzV0UWtIVTdyYnRaUw==';
export const CRYPTO_KEY = '6fa979f20126cb08aa645a8f495f6d85';
export const CRYPTO_IV = '7777777a72ddc2f1';
export const AUTH_REFRESH_TOKEN_NAME = 'refresh_token';
export const AUTH_EXPIRY = 'expiresIn';
export const TOKEN_CREATED_AT = 'createdAt';
export const MASTER_LOGIN = 'masterLogin';
export const LOCAL_STORAGE_USER_DATA = 'USER_DATA';
export const LOCAL_STORAGE_USER_STATE = 'USER_STATE';
export const LOCAL_STORAGE_TEAM_OWNER = 'teamOwnerId';
export const TEAM_SELECTION_PAGE_TEXT =
  'Our records indicate that you belong to more than one team. Select the team you wish to access from the drop-down list and click Continue. You may change this settings in future by going to Settings - Teams.';
export const AVATAR_COLOR_PALETTE = [
  '#FF968A',
  '#55CBCD',
  '#97C1A9',
  '#ABDEE6',
  '#CBAACB',
  '#FFC8A2',
];
export const AVATAR_SIZE = '307200';
export const LOGO_SIZE = '102400';
export const IMPORT_XLS_SIZE = '524288';
export const CURRENCIES = {
  ALL: 'Albania Lek',
  AFN: 'Afghanistan Afghani',
  ARS: 'Argentina Peso',
  AWG: 'Aruba Guilder',
  AUD: 'Australia Dollar',
  AZN: 'Azerbaijan New Manat',
  BSD: 'Bahamas Dollar',
  BBD: 'Barbados Dollar',
  BDT: 'Bangladeshi taka',
  BYR: 'Belarus Ruble',
  BZD: 'Belize Dollar',
  BMD: 'Bermuda Dollar',
  BOB: 'Bolivia Boliviano',
  BAM: 'Bosnia and Herzegovina Convertible Marka',
  BWP: 'Botswana Pula',
  BGN: 'Bulgaria Lev',
  BRL: 'Brazil Real',
  BND: 'Brunei Darussalam Dollar',
  KHR: 'Cambodia Riel',
  CAD: 'Canada Dollar',
  KYD: 'Cayman Islands Dollar',
  CLP: 'Chile Peso',
  CNY: 'China Yuan Renminbi',
  COP: 'Colombia Peso',
  CRC: 'Costa Rica Colon',
  HRK: 'Croatia Kuna',
  CUP: 'Cuba Peso',
  CZK: 'Czech Republic Koruna',
  DKK: 'Denmark Krone',
  DOP: 'Dominican Republic Peso',
  XCD: 'East Caribbean Dollar',
  EGP: 'Egypt Pound',
  SVC: 'El Salvador Colon',
  EEK: 'Estonia Kroon',
  EUR: 'Euro Member Countries',
  FKP: 'Falkland Islands (Malvinas) Pound',
  FJD: 'Fiji Dollar',
  GHC: 'Ghana Cedis',
  GIP: 'Gibraltar Pound',
  GTQ: 'Guatemala Quetzal',
  GGP: 'Guernsey Pound',
  GYD: 'Guyana Dollar',
  HNL: 'Honduras Lempira',
  HKD: 'Hong Kong Dollar',
  HUF: 'Hungary Forint',
  ISK: 'Iceland Krona',
  INR: 'India Rupee',
  IDR: 'Indonesia Rupiah',
  IRR: 'Iran Rial',
  IMP: 'Isle of Man Pound',
  ILS: 'Israel Shekel',
  JMD: 'Jamaica Dollar',
  JPY: 'Japan Yen',
  JEP: 'Jersey Pound',
  KZT: 'Kazakhstan Tenge',
  KES: 'kenyan shilling',
  KPW: 'Korea (North) Won',
  KRW: 'Korea (South) Won',
  KGS: 'Kyrgyzstan Som',
  LAK: 'Laos Kip',
  LVL: 'Latvia Lat',
  LBP: 'Lebanon Pound',
  LRD: 'Liberia Dollar',
  LTL: 'Lithuania Litas',
  MKD: 'Macedonia Denar',
  MYR: 'Malaysia Ringgit',
  MUR: 'Mauritius Rupee',
  MXN: 'Mexico Peso',
  MNT: 'Mongolia Tughrik',
  MZN: 'Mozambique Metical',
  NAD: 'Namibia Dollar',
  NPR: 'Nepal Rupee',
  ANG: 'Netherlands Antilles Guilder',
  NZD: 'New Zealand Dollar',
  NIO: 'Nicaragua Cordoba',
  NGN: 'Nigeria Naira',
  NOK: 'Norway Krone',
  OMR: 'Oman Rial',
  PKR: 'Pakistan Rupee',
  PAB: 'Panama Balboa',
  PYG: 'Paraguay Guarani',
  PEN: 'Peru Nuevo Sol',
  PHP: 'Philippines Peso',
  PLN: 'Poland Zloty',
  QAR: 'Qatar Riyal',
  RON: 'Romania New Leu',
  RUB: 'Russia Ruble',
  SHP: 'Saint Helena Pound',
  SAR: 'Saudi Arabia Riyal',
  RSD: 'Serbia Dinar',
  SCR: 'Seychelles Rupee',
  SGD: 'Singapore Dollar',
  SBD: 'Solomon Islands Dollar',
  SOS: 'Somalia Shilling',
  ZAR: 'South Africa Rand',
  LKR: 'Sri Lanka Rupee',
  SEK: 'Sweden Krona',
  CHF: 'Switzerland Franc',
  SRD: 'Suriname Dollar',
  SYP: 'Syria Pound',
  TWD: 'Taiwan New Dollar',
  THB: 'Thailand Baht',
  TTD: 'Trinidad and Tobago Dollar',
  TRY: 'Turkey Lira',
  TRL: 'Turkey Lira',
  TVD: 'Tuvalu Dollar',
  UAH: 'Ukraine Hryvna',
  GBP: 'United Kingdom Pound',
  UGX: 'Uganda Shilling',
  USD: 'United States Dollar',
  UYU: 'Uruguay Peso',
  UZS: 'Uzbekistan Som',
  VEF: 'Venezuela Bolivar',
  VND: 'Viet Nam Dong',
  YER: 'Yemen Rial',
  ZWD: 'Zimbabwe Dollar',
};
export const IDLE_TIME_LIMIT = 3600 * 1000; // 60 Minutes in millisecond's
export const DATE_AND_TIME = {
  DATE_WITH_TIME: 'MMM Do, YYYY h:mm:ss A', // Jan 1st, 2020 12:30:12 AM
  TIME: 'hh:mm A', // 03:15 AM
  DATE: 'ddd, MMM D', // Fri, Sep 04
  DATE_RANGE: 'MMM D, YYYY', // Jan 17, 2020
  DATE_RANGE_IN_STATE: 'YYYY-MM-DD', // 2020-09-08
  MONTH_AND_DAY: 'MMM D', // Oct 15
};

export const PER_PAGE_LIMIT = 9; // TeamMember/Project listing page, no of cards on a fetch request
export const MEMBER_VALIDATION_LIMIT = 1000; // keeping it disabled for now by increasing the limit.
export const PAGINATION_LIMIT = 10;

export const DATE_RANGE_OPTIONS = {
  Today: [moment().toDate(), moment().toDate()],
  Yesterday: [
    moment().subtract(1, 'days').toDate(),
    moment().subtract(1, 'days').toDate(),
  ],
  'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
  'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
  'This Month': [
    moment().startOf('month').toDate(),
    moment().endOf('month').toDate(),
  ],
  'Last Month': [
    moment().subtract(1, 'month').startOf('month').toDate(),
    moment().subtract(1, 'month').endOf('month').toDate(),
  ],
};

export const DATE_RANGE_OPTIONS01 = {
  'This Week': [
    moment().startOf('week').toDate(),
    moment().endOf('week').toDate(),
  ],
  'Last Week': [
    moment().subtract(1, 'week').startOf('week').toDate(),
    moment().subtract(1, 'week').endOf('week').toDate(),
  ],
};

export const DATE_RANGE_OPTIONS02 = {
  Today: [moment().toDate(), moment().toDate()],
  Yesterday: [
    moment().subtract(1, 'days').toDate(),
    moment().subtract(1, 'days').toDate(),
  ],
  'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
};
// Member online status check setInterval delay
export const STATUS_CHECK_DELAY = 120000; // 2 Minutes
export const SEND_SS_DELAY = 60000 * 2; // 3 Minutes

// Image lightbox gallery setting options
export const LIGHTBOX_OPTIONS = {
  buttons: {
    showAutoplayButton: false,
    showCloseButton: true,
    showDownloadButton: false,
    showFullscreenButton: false,
    showNextButton: true,
    showPrevButton: true,
    showThumbnailsButton: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
};

export const TESTIMONIALS = [
  {
    content: `“HiveDesk has gotten rid of over-paying employees with our policy of
    “If it wasn't tracked, it didn't happen”. HiveDesk has given me
    peace of mind, I don't think about our team members being
    accountable, it's done for them and all I have to do is check at a
    glance what they've completed.”`,
    author: 'Jared Woodruff',
    company: 'Founder, Harrier Media',
  },
  {
    content: `“We have used every timekeeping system under the sun, and they all
    fall short in some area that we need, but overall HiveDesk has met
    our needs. Their PDF screen shot reports are clean and easy to use
    and understand. Our clients prefer them over the complicated reports
    of other systems. The support is great, and I am able to speak
    directly to someone who can help me when I need help.”`,
    author: 'Lindsey Meadows',
    company: 'Owner, Meadows Resources',
  },
];

export const DAYS_LIST = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const CURRENT_YEAR = new Date().getFullYear();

export const DEFAULT_HOLIDAYS_LIST = [
  {
    _id: 'holiday_01',
    name: "New Year's Day",
    date: `${CURRENT_YEAR}-01-01T00:00:00.000Z`,
    isDefault: true,
  },
  {
    _id: 'holiday_02',
    name: 'Good Friday',
    date: `${CURRENT_YEAR}-03-29T00:00:00.000Z`,
    isDefault: true,
  },
  {
    _id: 'holiday_03',
    name: 'Easter Sunday',
    date: `${CURRENT_YEAR}-03-31T00:00:00.000Z`,
    isDefault: true,
  },
  {
    _id: 'holiday_04',
    name: 'Memorial Day',
    date: `${CURRENT_YEAR}-05-27T00:00:00.000Z`,
    isDefault: true,
  },
  {
    _id: 'holiday_05',
    name: 'Labor Day',
    date: `${CURRENT_YEAR}-09-04T00:00:00.000Z`,
    isDefault: true,
  },
  {
    _id: 'holiday_06',
    name: 'Thanksgiving',
    date: `${CURRENT_YEAR}-11-23T00:00:00.000Z`,
    isDefault: true,
  },
  {
    _id: 'holiday_07',
    name: 'Christmas Day',
    date: `${CURRENT_YEAR}-12-25T00:00:00.000Z`,
    isDefault: true,
  },
];
export const DAYS_LIMIT = 90;
export const SURVEY_ID = '65eae5738d081a0fcbe7cbed';
export const SURVEY_NAME = 'onboarding_survey';
export const JUNE_WRITE_KEY = REACT_APP_JUNE_WRITE_KEY;
