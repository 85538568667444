import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Crisp } from 'crisp-sdk-web';
import Layout from './DashboardLayout';
import BillingModal from '../components/settings/BillingModals';
// import { userService } from '../services';
// import DownloadModal from '../components/dashboard/ClientDownloadModal';
import {
  LOCAL_STORAGE_USER_DATA,
  SETTINGS_BILLING,
  INTEGRATION_URLS,
} from '../constants';
import {
  ProjectCard,
  RecentActivityCard,
  UpcomingTasksCard,
  TaskChart,
  Calendar,
  // Chat,
} from '../components/dashboard';
import { startTour } from '../actions';
import { fetchCardStatus } from '../actions/user';
import { fetchRecentActivity } from '../actions/activity';
import { fetchOnlineMembers } from '../actions/team';
import { fetchTasks } from '../actions/dashboard';
import { fetchMemberProjects } from '../actions/projects';
import Loading from '../components/Loader';

class Dashboard extends Component {
  pageHeading = {
    preTitle: 'Overview',
    title: 'Dashboard',
  };

  componentDidMount() {
    const {
      getRecentActivity,
      getAllTasks,
      getOnlineMembers,
      getCardStatus,
      getMemberProjects,
      startInitTour,
    } = this.props;
    getRecentActivity();
    getAllTasks();
    getMemberProjects();
    // Fetch online members list onMount
    getOnlineMembers();
    getCardStatus();
    // Crisp Chat
    Crisp.configure(INTEGRATION_URLS.CRISP_CHAT_ID);
    Crisp.user.setNickname(
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA)).firstName
    );
    Crisp.user.setEmail(
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA)).email
    );

    /* userService.login_count().then((response) => {
      if (
        response.data.loginCount >= 3 &&
        response.data.loginCount < 10 &&
        response.data.interviewMessageTriggered === false
      ) {
        Crisp.message.sendText('join interview');
        userService.crisp_event_update({ interviewMessageTriggered: true });
      }
      if (
        response.data.loginCount >= 10 &&
        response.data.loginCount < 15 &&
        response.data.interviewMessageTriggered === false
      ) {
        Crisp.message.sendText('join interview');
        userService.crisp_event_update({ interviewMessageTriggered: true });
      }
      if (
        response.data.loginCount >= 15 &&
        response.data.interviewMessageTriggered === false
      ) {
        Crisp.message.sendText('join interview');
        userService.crisp_event_update({ interviewMessageTriggered: true });
      }
      if (
        response.data.trackCount === 2 &&
        response.data.surveyEventTrackedTime === false
      ) {
        Crisp.session.pushEvent('tracked_time');
        userService.crisp_event_update({ surveyEventTrackedTime: true });
      }
    }); */
    // Auto start of the guided tour.
    if (localStorage.getItem('TourAutoStart')) {
      startInitTour();
    }
  }

  render() {
    const { recentActivity, dashboardData, billingData, userData } = this.props;
    // const downloadLink = localStorage.getItem('DownloadLinkModal');
    const taskSummary = [
      {
        status: 'complete',
        tasks: dashboardData.tasks.filter((item) => item.status === 'complete')
          .length,
      },
      {
        status: 'no due date',
        tasks: dashboardData.tasks.filter(
          (item) => item.status === 'no due date'
        ).length,
      },
      {
        status: 'over due',
        tasks: dashboardData.tasks.filter((item) => item.status === 'over due')
          .length,
      },
      {
        status: 'upcoming',
        tasks: dashboardData.tasks.filter((item) => item.status === 'upcoming')
          .length,
      },
    ];

    return (
      <Layout pageHeading={this.pageHeading}>
        {billingData.accountStatus !== 'cancel' &&
          userData.cardExpired === 1 && (
            <BillingModal
              modalProps={{
                showClose: true,
                actionVariant: 'primary',
                secondaryActionText: '',
                title: 'Card Expired',
                actionText: 'Update my credit card',
                content: SETTINGS_BILLING.CARD_EXPIRED,
                type: 'expired',
              }}
            />
          )}
        {/* {downloadLink && (
          <DownloadModal
            modalProps={{
              showClose: true,
              actionVariant: 'primary',
              secondaryActionText: '',

              content: SETTINGS_BILLING.CARD_EXPIRED,
              type: 'download',
            }}
          />
        )} */}
        <section className="main-dashboard">
          <Row>
            <Col xl={8}>
              <ProjectCard isPageLoading={dashboardData.isLoading} />
              {dashboardData.tasks && dashboardData.tasks.length > 0 && (
                <UpcomingTasksCard taskData={dashboardData.tasks} />
              )}
            </Col>
            <Col xl={4}>
              {recentActivity && recentActivity.length > 0 && (
                <RecentActivityCard
                  cardTitle="Recent activity"
                  recentActivity={recentActivity.slice(0, 3)}
                />
              )}

              {taskSummary && taskSummary.length > 0 && (
                <TaskChart cardTitle="Task completion" taskData={taskSummary} />
              )}
            </Col>
          </Row>
          {dashboardData.tasks && dashboardData.tasks.length > 0 && (
            <Row>
              <Col>
                <Calendar taskData={dashboardData.tasks} />
              </Col>
            </Row>
          )}
        </section>
        {/* <Chat /> */}
        {dashboardData.isLoading && <Loading contentAreaOnly />}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  recentActivity: state.activity.recentActivity,
  dashboardData: state.dashboard,
  billingData: state.billingReducer,
  userData: state.userReducer,
});
const mapDispatchToProps = () => ({
  getRecentActivity: fetchRecentActivity,
  getAllTasks: fetchTasks,
  getOnlineMembers: fetchOnlineMembers,
  getCardStatus: fetchCardStatus,
  getMemberProjects: fetchMemberProjects,
  startInitTour: startTour,
});

Dashboard.propTypes = {
  getRecentActivity: PropTypes.func,
  getAllTasks: PropTypes.func,
  recentActivity: PropTypes.array,
  dashboardData: PropTypes.object,
  getOnlineMembers: PropTypes.func,
  getCardStatus: PropTypes.func,
  billingData: PropTypes.object,
  userData: PropTypes.object,
  getMemberProjects: PropTypes.func,
  startInitTour: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps())(Dashboard);
