import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

function Loading({ contentAreaOnly }) {
  return contentAreaOnly ? (
    <section className="d-flex align-items-center justify-content-center content-loader">
      <Spinner
        animation="border"
        className="text-primary"
        role="status"
        size="md"
      />
    </section>
  ) : (
    <section className="d-flex align-items-center justify-content-center bg-auth border-top border-top-2 border-primary vh-100">
      <Spinner
        animation="border"
        className="text-primary"
        role="status"
        size="md"
      />
    </section>
  );
}

Loading.propTypes = {
  contentAreaOnly: PropTypes.bool,
};

export default Loading;
