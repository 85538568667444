import { useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { MemberMultiSelectDropdown } from '../common';

const AddTeamGroup = ({
  teamMembersList,
  showAddTeam,
  handleAddTeamDisplay,
  handleCreateTeam,
}) => {
  const selectedTeamMembers = [];
  const sortedMembersList = teamMembersList.filter(function (el) {
    return el.status !== 'inactive';
  });
  const selectInputRef = useRef(null);

  return (
    <>
      <Button
        size="sm"
        variant="primary"
        onClick={() => {
          handleAddTeamDisplay(true);
        }}
      >
        Create Team Group
      </Button>

      <Modal
        show={showAddTeam}
        onHide={() => {
          handleAddTeamDisplay(false);
        }}
        centered
      >
        <Modal.Header>
          <Modal.Title className="m-0">Create Team Group</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => {
              handleAddTeamDisplay(false);
            }}
          />
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={{
            teamName: '',
            selectedMembers: selectedTeamMembers,
          }}
          validationSchema={Yup.object({
            teamName: Yup.string().required('Please enter a team name'),
            selectedMembers: Yup.array().min(1, 'Please select a team member'),
          })}
          onSubmit={(values) => {
            handleCreateTeam(values);
          }}
        >
          {(formikprops) => (
            <Form onSubmit={formikprops.handleSubmit}>
              <Modal.Body>
                <section>
                  <Form.Group controlId="worksessionType">
                    <Form.Label>Team Name</Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter team name"
                      name="teamName"
                    />
                    <ErrorMessage
                      name="teamName"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Form.Group>
                  <MemberMultiSelectDropdown
                    id="selectInputRef"
                    name="selectedMembers"
                    labelText="Team Member(s)"
                    fromArray={sortedMembersList}
                    formProps={formikprops}
                    isDisabled={sortedMembersList.length < 1 && true}
                    innerRef={selectInputRef}
                    requiredValueAs="userId"
                    showErrorMessage
                    isAllTeamMemberOption
                  />
                </section>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  variant="link"
                  onClick={() => {
                    handleAddTeamDisplay(false);
                  }}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Create
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

AddTeamGroup.propTypes = {
  teamMembersList: PropTypes.array,
  showAddTeam: PropTypes.bool,
  handleAddTeamDisplay: PropTypes.func,
  handleCreateTeam: PropTypes.func,
};

export default AddTeamGroup;
