import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import { Link } from 'react-router-dom';
import QuickSearch from './QuickSearch';

const QuickSearchModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  return (
    <>
      <Link
        className="btn text-white font-size-lg"
        to={{ hash: '#' }}
        role="button"
        onClick={handleShow}
      >
        <Tippy content="Quick Search" theme="light">
          <strong>
            <i className="mdi icon-20px mdi-magnify" />
          </strong>
        </Tippy>
      </Link>

      <Modal show={show} onHide={handleClose} style={{ marginTop: '100px' }}>
        <Modal.Body>
          <QuickSearch />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuickSearchModal;
