import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ToggleSwitch } from '../common';

const AutoCheckout = ({
  checkoutSetting,
  checkoutMinutes,
  handleUpdate,
  handleAutoCheckOutToggle,
}) => (
  <Formik
    initialValues={{
      autoCheckOut: checkoutSetting,
      autoCheckOutMinutes: checkoutMinutes,
    }}
    enableReinitialize
    validationSchema={Yup.object({
      autoCheckOut: Yup.boolean(),
      autoCheckOutMinutes: Yup.number().when('autoCheckOut', {
        is: true,
        then: Yup.number()
          .typeError('Auto Checkout minutes must be greater than or equal to 2')
          .positive()
          .required('Please enter your auto logout time')
          .min(2, 'Auto Checkout minutes must be greater than or equal to 2'),
      }),
    })}
    onSubmit={(values) => {
      if (values.autoCheckOut === false) {
        values.autoCheckOutMinutes = '';
      }

      handleUpdate(values);
    }}
  >
    {(formikprops) => (
      <div className="col-12 col-md-12">
        <Form onSubmit={formikprops.handleSubmit} className="needs-validation">
          <Row>
            <Col>
              <h4 className="font-weight-base mb-1">
                Auto checkout from project
              </h4>
              <small className="text-muted">
                By enabling this feature provide the ability to checkout Devices
                automatically after given time of inactivity. Please add idle
                time in minutes.
              </small>
            </Col>
            <Col className="col-auto">
              <ToggleSwitch
                id="autoCheckOut"
                name="autoCheckOut"
                handleChangeforChecked={handleAutoCheckOutToggle}
                checked={checkoutSetting}
              />
            </Col>
          </Row>
          {formikprops.values.autoCheckOut && (
            <div className="px-3 py-2">
              <Row>
                <Col className="px-0 col-auto me-2">
                  <small>Idle time</small>
                </Col>
                <Col className="px-0 col-auto">
                  <Field
                    className="form-control form-control-sm"
                    type="text"
                    name="autoCheckOutMinutes"
                    style={{
                      maxWidth: '50px',
                    }}
                  />
                </Col>
                <Col className="ps-0">
                  <span
                    className="d-inline-block"
                    style={{ marginLeft: '6px' }}
                  >
                    <small>Minutes</small>
                  </span>
                </Col>
              </Row>
              <Row>
                <Col className="px-0 mt-3">
                  <Button
                    size="sm"
                    style={{
                      height: '26px',
                      maxWidth: '50px',
                    }}
                    type="submit"
                    disabled={!formikprops.isValid}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Form>
      </div>
    )}
  </Formik>
);
AutoCheckout.propTypes = {
  checkoutSetting: PropTypes.bool,
  checkoutMinutes: PropTypes.any,
  handleUpdate: PropTypes.func,
  handleAutoCheckOutToggle: PropTypes.func,
};
export default AutoCheckout;
