import { teamService, projectService } from '../services';
import { LOCAL_STORAGE_TEAM_OWNER } from '../constants';

export const fetchTeamMembers = () => async (dispatch) => {
  try {
    const params = {
      teamOwnerId: localStorage.getItem(LOCAL_STORAGE_TEAM_OWNER),
      summaryList: true,
    };
    const response = await teamService.get_team_members_data(params);
    if (response.status) {
      dispatch({ type: 'SET_TEAM_MEMBERS', payload: response.data.members });
    }
  } catch {
    // Do nothing
  }
};

export const fetchMembers = (data) => async (dispatch) => {
  dispatch({ type: 'ACTIVATE_LOADER' });
  try {
    const response = await teamService.get_team_members_data(data);
    if (response.data) {
      dispatch({ type: 'SET_ACTIVE_MEMBERS', payload: response.data });
    } else {
      dispatch({ type: 'NO_RESULTS' });
    }
  } catch {
    dispatch({ type: 'FETCH_MEMBERS_ERROR' });
  }
};

export const fetchInactiveMembers = (data) => async (dispatch) => {
  dispatch({ type: 'ACTIVATE_LOADER' });
  try {
    const response = await teamService.get_team_members_data(data);
    if (response.data) {
      dispatch({ type: 'SET_INACTIVE_MEMBERS', payload: response.data });
    } else {
      dispatch({ type: 'NO_INACTIVE_RESULTS' });
    }
  } catch {
    dispatch({ type: 'FETCH_MEMBERS_ERROR' });
  }
};

export const fetchInvitations = () => async (dispatch) => {
  try {
    const response = await projectService.get_received_invitations_data();
    if (response.data) {
      dispatch({ type: 'SET_INVITATIONS_DATA', payload: response.data });
    } else {
      dispatch({ type: 'NO_INVITATIONS' });
    }
  } catch {
    dispatch({ type: 'FETCH_MEMBERS_ERROR' });
  }
};

export const resetMembersList = () => ({
  type: 'RESET_ACTIVE_MEMBERS',
});

export const resetInactiveMembersList = () => ({
  type: 'RESET_INACTIVE_MEMBERS',
});

export const activateLoader = () => ({
  type: 'ACTIVATE_LOADER',
});

export const deactivateLoader = () => ({
  type: 'DEACTIVATE_LOADER',
});

export const setSearchParam = (data) => ({
  type: 'SET_SEARCH_PARAM',
  payload: data,
});

export const updateMemberData = (data) => ({
  type: 'UPDATE_MEMBER_DATA',
  payload: data,
});

export const deactivateMember = (data) => ({
  type: 'DEACTIVATE_MEMBER',
  payload: data,
});

export const reactivateMember = (data) => ({
  type: 'REACTIVATE_MEMBER',
  payload: data,
});

export const deleteMember = (data) => ({
  type: 'DELETE_MEMBER',
  payload: data,
});

export const updateMemberRole = (data) => ({
  type: 'UPDATE_MEMBER_ROLE',
  payload: data,
});

export const fetchOnlineMembers = () => async (dispatch) => {
  const teamOwnerId = localStorage.getItem(LOCAL_STORAGE_TEAM_OWNER);
  try {
    const response = await teamService.get_online_members(teamOwnerId);
    if (response.data) {
      dispatch({ type: 'SET_ONLINE_MEMBERS', payload: response.data });
    }
  } catch {
    dispatch({ type: 'FETCH_ONLINE_MEMBERS_ERROR' });
  }
};
