import PropTypes from 'prop-types';
import moment from 'moment';
import { getDuration, msToHMS } from '../../helpers';
import { NO_DATA } from '../../constants';

const ProjectWorkSessionEditLog = ({ worksessionList, project }) => (
  <div className="table-responsive">
    {worksessionList.length > 0 ? (
      <table
        id="groupedDataByProject"
        className="table table-sm table-nowrap table-hover card-table"
      >
        <thead className="thead-light">
          <tr>
            <th>Team Member</th>
            <th>Original Worksession</th>
            <th>Updated Worksession</th>
            <th>Edited by</th>
            <th>Reason</th>
            {project.isMobileTracking && <th>Worksession Type</th>}
          </tr>
        </thead>
        <tbody>
          {worksessionList.map(
            (wsItem) =>
              wsItem.workSessionEdits.length > 0 &&
              wsItem.workSessionEdits.map((editLog, index) => {
                const originalDuration = getDuration(
                  editLog.oldCheckInDate,
                  editLog.oldCheckOutDate
                );
                const newDuration = getDuration(
                  editLog.newCheckInDate,
                  editLog.newCheckOutDate
                );
                return (
                  <tr key={`wsEdit-${index}`}>
                    <td>{`${editLog.userFirstName} ${
                      editLog.userLastName || ''
                    }`}</td>
                    <td>
                      Duration: {msToHMS(originalDuration)}
                      <br />
                      Check-in: {moment(editLog.oldCheckInDate).format('lll')}
                      <br />
                      Check-out: {moment(editLog.oldCheckOutDate).format('lll')}
                    </td>
                    <td>
                      Duration: {msToHMS(newDuration)}
                      <br />
                      Check-in: {moment(editLog.newCheckInDate).format('lll')}
                      <br />
                      Check-out: {moment(editLog.newCheckOutDate).format('lll')}
                    </td>
                    <td>{`${editLog.editedByFirstName} ${
                      editLog.editedByLastName || ''
                    }`}</td>
                    <td>{editLog.reason}</td>
                    {project.isMobileTracking && (
                      <td>
                        <span className="text-capitalize">
                          {wsItem.workSessionType}
                        </span>
                      </td>
                    )}
                  </tr>
                );
              })
          )}
        </tbody>
      </table>
    ) : (
      <p className="m-4">{NO_DATA.WORKSESSION_EDIT_LOG}</p>
    )}
  </div>
);

ProjectWorkSessionEditLog.propTypes = {
  worksessionList: PropTypes.array,
  project: PropTypes.object,
};

export default ProjectWorkSessionEditLog;
