import PropTypes from 'prop-types';
import { Card, Row, Alert } from 'react-bootstrap';
import React from 'react';
import moment from 'moment';
import { NO_DATA, PAGINATION_LIMIT } from '../../../constants';
import Pagination from '../../common/Pagination';
import { sToHMSColonSeparated } from '../../../helpers';

const Report = ({
  attendanceMetadata,
  attendanceData,
  userRole,
  setCurrentPage,
  getAttendanceData,
  reportFormData,
  pageNumber,
}) => {
  if (attendanceMetadata && attendanceMetadata.total > 0) {
    return (
      <>
        <Row />
        <Card>
          <div className="table-responsive">
            <table className="table table-sm table-hover card-table">
              <thead className="thead-light">
                <tr>
                  <th>Team member</th>
                  <th>Status</th>
                  <th>Shift Start Time</th>
                  <th>Actual Start Time </th>
                  <th>Total Hours </th>
                  <th>Exepected Hours</th>
                </tr>
              </thead>

              <tbody>
                {attendanceData.map((entry, index) => (
                  <React.Fragment key={index}>
                    <>
                      <tr className="table-light">
                        <td colSpan="11" className="px-4 py-2">
                          <strong>
                            {' '}
                            {`${entry.user.firstName} ${
                              entry.user.lastName || ''
                            }`}{' '}
                          </strong>
                        </td>
                      </tr>
                      {entry.attendance.map((entries) => (
                        <>
                          <tr>
                            <td>
                              {' '}
                              {moment(entries.date).format('dddd, MMMM D')}
                            </td>
                            <td>
                              <span className="badge bg-warning">
                                {entries.status}
                              </span>
                            </td>
                            <td>
                              {entries.shiftStart === null
                                ? '-'
                                : `${moment
                                    .utc(entries.shiftStart)
                                    .format('hh:mm A')}`}
                            </td>
                            <td>
                              {' '}
                              {entries.checkInDate === null
                                ? '-'
                                : `${moment
                                    .utc(entries.checkInDate)
                                    .format('hh:mm A')}`}
                            </td>
                            <td>
                              {' '}
                              {sToHMSColonSeparated(
                                Math.round(entries.workSessionMinutes * 60)
                              )}
                            </td>
                            <td>
                              {sToHMSColonSeparated(
                                Math.round(entries.shiftDurationMinutes * 60)
                              )}
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  </React.Fragment>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={userRole !== 'member' ? 6 : 5}>
                    {attendanceMetadata &&
                      attendanceMetadata.total &&
                      attendanceMetadata.total > PAGINATION_LIMIT && (
                        <section className="d-flex justify-content-center py-3">
                          <Pagination
                            className="pagination-bar"
                            currentPage={pageNumber}
                            totalCount={attendanceMetadata.total}
                            pageSize={PAGINATION_LIMIT}
                            onPageChange={(page) => {
                              setCurrentPage(page);
                              getAttendanceData({
                                ...reportFormData,
                                page,
                                limit: PAGINATION_LIMIT,
                              });
                            }}
                          />
                        </section>
                      )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Card>
      </>
    );
  }
  return (
    <Alert variant="info" className="text-center">
      {NO_DATA.ATTENDANCE}
    </Alert>
  );
};
Report.propTypes = {
  attendanceData: PropTypes.any,
  attendanceMetadata: PropTypes.object,
  userRole: PropTypes.string,
  setCurrentPage: PropTypes.func,
  getAttendanceData: PropTypes.func,
  reportFormData: PropTypes.object,
  pageNumber: PropTypes.number,
};

export default Report;
