import { toast } from 'react-toastify';
import { workSessionService } from '../services';
import { fetchTasks } from './tasks';

export const startTimer = () => ({
  type: 'START_TIMER',
});
export const updateTimer = () => ({
  type: 'UPDATE_TIMER',
});
export const stopTimer = () => ({
  type: 'STOP_TIMER',
});
// Timer Widget Actions
export const setShowTimerWidget = () => ({
  type: 'SET_SHOW_TIMER_WIDGET',
});
export const setTimerProject = (data) => ({
  type: 'SET_CHECK_IN_PROJECT',
  payload: data,
});
export const setTimerTask = (data) => ({
  type: 'SET_CHECK_IN_TASK',
  payload: data,
});
export const setCheckedInProjectTasksList = (data) => ({
  type: 'SET_CHECKED_IN_PROJECT_TASKS_LIST',
  payload: data,
});
export const resetWidget = () => ({
  type: 'RESET_WIDGET',
});

export const enableScreenshots = (data) => async (dispatch) => {
  try {
    const response = await workSessionService.screenshot({
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      workSessionId: data.wsId,
    });
    response.catch((error) => {
      if (error.data === 'Invalid work session.') {
        dispatch({ type: 'RESET_WIDGET' });
        toast.error(
          'Your current session has been closed as another session has started in a different client'
        );
      }
    });
  } catch (error) {
    if (error.data === 'Invalid work session.') {
      dispatch({ type: 'RESET_WIDGET' });
      toast.error(
        'Your current session has been closed as another session has started in a different client'
      );
    }
  }
};

export const checkIn = (data) => async (dispatch) => {
  const params = {
    teamOwnerId: localStorage.getItem('teamOwnerId'),
    projectId: data.projectId,
    taskId: data.taskId,
  };
  if (data.taskId === '') {
    delete params.taskId;
  }
  try {
    const response = await workSessionService.web_check_in(params);
    if (response.data) {
      const { _id: wsId } = response.data;
      // Set WS ID on checkIn success
      dispatch({ type: 'SET_WORKSESSION_ID', payload: { wsId } });
    }
  } catch (error) {
    // Do nothing;
  }
};

export const checkOut = (data) => async (dispatch) => {
  try {
    const response = await workSessionService.web_check_out({
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      workSessionId: data.wsId,
    });
    if (response.data) {
      // Reset WS ID on checkIn success
      dispatch({ type: 'SET_WORKSESSION_ID', payload: { wsId: '' } });
      if (data.reFetchTasks) {
        dispatch(
          fetchTasks({
            teamOwnerId: localStorage.getItem('teamOwnerId'),
            projectId: data.projectId,
          })
        );
      }
    }
  } catch (error) {
    // Do nothing;
  }
};
