import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Archive = ({ handleShow }) => (
  <Button variant="danger" className=" ml-auto" onClick={handleShow}>
    Archive
  </Button>
);

Archive.propTypes = {
  handleShow: PropTypes.func,
};

export default Archive;
