import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

const MemberName = ({ hourlyRate, defaultCurrency, memberName }) => {
  const [showInfo, setShowInfo] = React.useState(false);
  return (
    <h2
      className="card-title"
      onMouseEnter={() => setShowInfo(true)}
      onMouseLeave={() => setShowInfo(false)}
    >
      <Dropdown navbar={false} className="info-dropdown">
        <Dropdown.Toggle
          as={Link}
          to={{ hash: '#' }}
          className="disabled"
          style={{ cursor: 'default' }}
        >
          <span className="name text-dark">{memberName}</span>{' '}
          <i className="mdi mdi-information-outline text-muted" />
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="right"
          style={{ minWidth: '200px' }}
          show={showInfo}
        >
          <div className="d-flex justify-content-between align-items-center dropdown-item px-3">
            <h5 className="my-0 ms-0 me-4">
              Hourly Rate (
              {defaultCurrency && defaultCurrency !== null
                ? defaultCurrency
                : 'USD'}
              )
            </h5>{' '}
            <span className="text-muted fw-normal">{hourlyRate || '0.00'}</span>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </h2>
  );
};

MemberName.propTypes = {
  hourlyRate: PropTypes.number,
  defaultCurrency: PropTypes.string,
  memberName: PropTypes.string,
};

export default MemberName;
