import { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userService } from '../../services';
import { updateLocalStorage } from '../../helpers';
import Layout from '../DashboardLayout';
import CurrentPlan from '../../components/settings/CurrentPlan';
import SeatsUsed from '../../components/settings/SeatsUsed';
import PaymentInfo from '../../components/settings/PaymentInfo';
import SettingsTabs from '../../components/settings/Tabs';
import InvoiceList from '../../components/settings/InvoiceList';
import CancelAccount from '../../components/settings/CancelAccountForm';
import ChangePlan from '../../components/settings/ChangePLan';
import TrialChangeEmails from '../../components/settings/TrialChangeEmails';

import { SETTINGS_BILLING, LOCAL_STORAGE_USER_DATA } from '../../constants';
import BillingModal from '../../components/settings/BillingModals';

class Billing extends Component {
  pageHeading = {
    preTitle: 'Settings',
    title: 'Billing',
  };

  cancelAccountProps = {
    showClose: false,
    title: 'Cancel your account',
    actionText: 'Cancel your account',
    actionVariant: 'danger',
    content: <CancelAccount />,
  };

  userdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));

  componentDidMount() {
    /* Show error if trial upgrade/ card update failed, F=failed */
    const { pageStatus } = this.props;

    this.getAccountInfo();

    if (pageStatus === 'F') {
      toast.error(SETTINGS_BILLING.UPDATE_ERROR);
    }
  }

  getAccountInfo = () => {
    userService
      .get_account_details({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
      })
      .then((response) => {
        if (response.data) {
          updateLocalStorage({
            activeMembers: response.data.activeMembers,
            maxMembersAllowed: response.data.maxMembersAllowed,
            isMobileTracking: response.data.isMobileTracking,
          });
        }
      });
  };

  render() {
    const { cancelAccountProps } = this;
    const { planName, accountStatus } = this.props;

    return (
      <Layout pageHeading={this.pageHeading}>
        <Row className="justify-content-center">
          <Col className="col-12 col-lg-10 col-xl-8">
            <SettingsTabs selectedTab={this.pageHeading.title} />
            <div className="tab-content">
              <div className="tab-pane show active">
                <Row>
                  <CurrentPlan />
                  <SeatsUsed />
                </Row>
                <ChangePlan />
                {planName && planName.toLowerCase().includes('trial') && (
                  <TrialChangeEmails />
                )}
                {planName &&
                  !planName.toLowerCase().includes('trial') &&
                  accountStatus !== 'cancel' && (
                    <>
                      <PaymentInfo />
                      <InvoiceList />
                      <BillingModal modalProps={cancelAccountProps} />
                    </>
                  )}
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}
const mapStateToProps = (state) => {
  const { billingReducer } = state;
  return {
    pageStatus: billingReducer.pageStatus,
    planName: billingReducer.planName,
    accountStatus: billingReducer.accountStatus,
  };
};

Billing.propTypes = {
  pageStatus: PropTypes.string,
  planName: PropTypes.string,
  accountStatus: PropTypes.string,
};

export default connect(mapStateToProps)(Billing);
