import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Table, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { settingsService } from '../../services';
import {
  DATE_AND_TIME,
  API_FAILURE_ERROR,
  PAGINATION_LIMIT,
} from '../../constants';
import { formatAmount, converToDate } from '../../helpers/functions';
import successSmall from '../../assets/img/success_small.png';
import failureSmall from '../../assets/img/failure_small.png';

const InvoiceList = () => {
  const [details, setdetails] = useState([]);
  const [offset, setOffset] = useState([0, 0]);
  const [loading, setLoading] = useState(false);

  const billingDetails = useSelector((state) => state.billingReducer);
  /* showLoader is true only onclick of More button */
  const getInvoices = (showLoader = false) => {
    if (showLoader) setLoading(true);
    const data = {
      ownerId: localStorage.getItem('teamOwnerId'),
      limit: PAGINATION_LIMIT,
      offset,
    };

    settingsService
      .invoice_list(data)
      .then((response) => {
        if (Array.isArray(response.data) && response.data[0] !== 'No Records') {
          setdetails(details.concat(response.data[0].invoices));

          setOffset([
            response.data.metadata.nextOffset1,
            response.data.metadata.nextOffset2,
          ]);
          /* when load more is clicked, hide loader and scroll to end of list */
          if (showLoader) {
            setLoading(false);
            window.scrollTo(
              0,
              document.querySelector('#invoicelist').scrollHeight
            );
          }
        }
      })
      .catch(() => {
        if (showLoader) {
          setLoading(false);
          toast.error(API_FAILURE_ERROR);
        }
      });
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const downloadInvoice = (id) => {
    const data = {
      ownerId: localStorage.getItem('teamOwnerId'),
      id,
    };

    settingsService
      .download_invoice(data)
      .then((response) => {
        if ('url' in response.data) {
          window.location.href = response.data.url;
        }
      })
      .catch(() => {
        toast.error(API_FAILURE_ERROR);
      });
  };

  const showInvoice = (id) => {
    document.getElementById(`tr${id}`).className =
      document.getElementById(`tr${id}`).className === 'd-none' ? '' : 'd-none';
    document.getElementById(id).style.display =
      document.getElementById(id).style.display === 'none' ? 'inline' : 'none';
  };

  return (
    <Card id="invoicelist">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h4 className="card-header-title">Invoices</h4>
          </Col>
        </Row>
      </Card.Header>
      <Table
        hover
        responsive="sm"
        size="sm"
        className="table-nowrap card-table"
      >
        <thead>
          <tr>
            <th>Invoice ID</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Status</th>
            <th>&nbsp;</th>
          </tr>
        </thead>

        <tbody className="font-size-base">
          {details.length > 0
            ? details.map((val, i) => (
                <React.Fragment key={i}>
                  <tr>
                    <td>
                      <button
                        type="button"
                        className="btn btn-link"
                        style={{ padding: '0px' }}
                        onClick={() => {
                          showInvoice(val.invoiceId);
                        }}
                      >
                        {val.invoiceId}
                      </button>
                    </td>
                    <td>
                      {converToDate(val.startDate, DATE_AND_TIME.DATE_RANGE)}
                    </td>
                    <td>${formatAmount(val.total)}</td>
                    <td>
                      {val.status === 'paid' && (
                        <span className="badge bg-primary">{val.status}</span>
                      )}
                      {val.status === 'not_paid' && (
                        <span className="badge bg-light">{val.status}</span>
                      )}
                      {val.status === 'payment_due' && (
                        <span className="badge bg-danger">{val.status}</span>
                      )}
                    </td>
                    <td>
                      <Button
                        type="button"
                        className="btn btn-sm btn-white"
                        onClick={() => {
                          downloadInvoice(val.invoiceId);
                        }}
                      >
                        Download
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="5"
                      id={`tr${val.invoiceId}`}
                      className="d-none"
                    >
                      <div
                        className="p-2"
                        id={val.invoiceId}
                        style={{ display: 'none' }}
                      >
                        <div className="row">
                          <div className="col text-end">
                            {val.status === 'paid' && (
                              <div className="badge bg-primary">
                                {val.status}
                              </div>
                            )}
                            {val.status === 'not_paid' && (
                              <div className="badge bg-light">{val.status}</div>
                            )}
                            {val.status === 'payment_due' && (
                              <div className="badge bg-danger">
                                {val.status}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col text-center">
                            <h2 className="mb-2">Invoice from HiveDesk</h2>
                            <p className="text-muted mb-6">
                              {`Invoice #${val.invoiceId} for ${val.billingName}`}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <h6 className="text-uppercase text-muted">
                              Subscription
                            </h6>
                            <p className="text-muted mb-4">
                              <strong className="text-body">
                                {val.subscriptionId}
                              </strong>
                              <br />
                              <span>{val.billingName}</span>
                              <br />
                              <span>
                                {billingDetails.email
                                  ? billingDetails.email
                                  : ''}
                              </span>
                            </p>
                            <h6 className="text-uppercase text-muted">
                              Invoice Amount
                            </h6>
                            <p className="mb-4">
                              ${formatAmount(val.amountPaid)}
                            </p>
                          </div>
                          <div className="col-12 col-md-6 text-md-end">
                            <h6 className="text-uppercase text-muted">
                              Billed to
                            </h6>
                            <p className="text-muted mb-4">
                              {billingDetails.address ? (
                                <>
                                  <span>
                                    {billingDetails.address.line1
                                      ? billingDetails.address.line1
                                      : ''}
                                    <br />
                                  </span>
                                  <span>
                                    {billingDetails.address.line2
                                      ? billingDetails.address.line2
                                      : ''}
                                    <br />
                                  </span>
                                  <span>
                                    {billingDetails.address.city
                                      ? billingDetails.address.city
                                      : ''}
                                    {billingDetails.address.zip
                                      ? billingDetails.address.zip
                                      : ''}
                                    <br />
                                  </span>
                                  <span>
                                    {billingDetails.address.state
                                      ? billingDetails.address.state
                                      : ''}
                                    <br />
                                  </span>
                                  <span>
                                    {billingDetails.address.country
                                      ? billingDetails.address.country
                                      : ''}
                                    <br />
                                  </span>
                                </>
                              ) : (
                                <strong className="text-body">
                                  {val.billingName}
                                </strong>
                              )}
                            </p>
                            <br />
                            <h6 className="text-uppercase text-muted mt-4">
                              Invoice Date
                            </h6>
                            <p className="mb-4">
                              <time dateTime={val.paidOn}>
                                {converToDate(
                                  val.paidOn,
                                  DATE_AND_TIME.DATE_RANGE
                                )}
                              </time>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <h6 className="text-uppercase text-muted">
                              Line Items
                            </h6>
                            <Table className="my-4" responsive="sm">
                              <thead>
                                <tr>
                                  <th className="px-0 bg-transparent border-top-0">
                                    <span className="h6">Description</span>
                                  </th>
                                  <th className="px-0 bg-transparent border-top-0">
                                    <span className="h6">Units</span>
                                  </th>
                                  <th className="px-0 bg-transparent border-top-0 text-end">
                                    <span className="h6">Unit Price</span>
                                  </th>
                                  <th className="px-0 bg-transparent border-top-0 text-end">
                                    <span className="h6">Amount</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {val.lineItems.map((item, k) => (
                                  <tr key={k}>
                                    <td className="px-0">{item.description}</td>
                                    <td className="px-0">{item.units}</td>
                                    <td className="px-0 text-end">
                                      ${formatAmount(item.unitPrice)}
                                    </td>
                                    <td className="px-0 text-end">
                                      ${formatAmount(item.amount)}
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                  <td className="px-0 border-top border-top-2">
                                    <strong>Amount due</strong>
                                  </td>
                                  <td
                                    colSpan="3"
                                    className="px-0 text-end border-top border-top-2"
                                  >
                                    <span className="h3">
                                      ${formatAmount(val.amountPaid)}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <h6 className="text-uppercase text-muted">
                              Payment Details
                            </h6>
                          </div>
                          <Table className="my-4" responsive="sm">
                            <thead>
                              <tr>
                                <th className="px-0 bg-transparent border-top-0">
                                  <span className="h6">Date</span>
                                </th>
                                <th className="px-0 bg-transparent border-top-0">
                                  <span className="h6">Payment Method</span>
                                </th>
                                <th className="px-0 bg-transparent border-top-0 text-end">
                                  <span className="h6">Status</span>
                                </th>
                                <th className="px-0 bg-transparent border-top-0 text-end">
                                  <span className="h6">Amount</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {val.transactions.length > 0 ? (
                                val.transactions.map((trans, m) => (
                                  <React.Fragment key={m}>
                                    <tr>
                                      <td className="px-0">
                                        {converToDate(
                                          trans.applied_at,
                                          DATE_AND_TIME.DATE_RANGE
                                        )}
                                      </td>
                                      <td className="px-0">
                                        Card Ending with {billingDetails.digits}
                                      </td>
                                      <td className="px-0 text-end">
                                        {trans.txn_status === 'success' && (
                                          <img alt="" src={successSmall} />
                                        )}
                                        {val.txn_status === 'failure' && (
                                          <img alt="" src={failureSmall} />
                                        )}
                                      </td>
                                      <td className="px-0 text-end">
                                        ${formatAmount(trans.applied_amount)}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="px-0 border-top border-top-2">
                                        <strong>Total</strong>
                                      </td>
                                      <td
                                        colSpan="4"
                                        className="px-0 text-end border-top border-top-2"
                                      >
                                        <span className="h3">
                                          {trans.txn_status === 'success'
                                            ? `$${formatAmount(
                                                trans.txn_amount
                                              )}`
                                            : ''}
                                        </span>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="4">
                                    No transactions available for this invoice
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))
            : ''}
          {offset[0] > 0 ? (
            <tr>
              <td colSpan="4">
                {loading && (
                  <Spinner
                    animation="border"
                    className="text-primary"
                    role="status"
                    size="md"
                  />
                )}
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    getInvoices(true);
                  }}
                  id="btnmore"
                  name="btnmore"
                >
                  More
                </button>
              </td>
            </tr>
          ) : (
            ''
          )}
        </tbody>
      </Table>
    </Card>
  );
};
export default InvoiceList;
