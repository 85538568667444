import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import moment from 'moment';

const DateAndTimePickerField = ({
  label,
  handleDateSelect,
  name,
  defaultValue,
  defaultValueDisplay,
  showRequiredField = true,
}) => (
  <Form.Group>
    <Form.Label>
      {label} {showRequiredField && <span className="text-danger">*</span>}
    </Form.Label>
    {/* Display default date after label text */}
    {defaultValueDisplay && (
      <span className="text-muted small ms-2">
        {moment(defaultValue).format('MMM Do, YYYY h:mm a')}
      </span>
    )}
    <Field
      component={Flatpickr}
      data-enable-time
      onChange={([date]) => {
        handleDateSelect(date);
      }}
      options={{
        defaultDate: defaultValue,
        allowInvalidPreload: true,
        dateFormat: 'm/d/Y h:i K',
        maxDate: 'today',
        monthSelectorType: 'static',
        minuteIncrement: 1,
        static: true,
      }}
      className="form-control icon-calendar"
      name={name}
    />
    <ErrorMessage
      name={name}
      render={(msg) => <small className="text-danger">{msg}</small>}
    />
  </Form.Group>
);

DateAndTimePickerField.propTypes = {
  handleDateSelect: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  defaultValueDisplay: PropTypes.bool,
  showRequiredField: PropTypes.bool,
};

export default DateAndTimePickerField;
