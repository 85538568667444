import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import { Field, ErrorMessage, FieldArray } from 'formik';
import Select from 'react-select';

const InviteNewTeamMember = ({
  formProps,
  showPlanText,
  maxInputField,
  fieldName,
  maxFieldAllowed,
}) => {
  const options = [
    {
      value: 'member',
      label: 'Member',
    },
    {
      value: 'full_manager',
      label: 'Full Manager',
    },
    { value: 'manager', label: 'Basic Manager' },
  ];
  return (
    <Form.Group>
      {showPlanText && <div className="alert alert-info">{showPlanText}</div>}
      <div className="row">
        <div className="col-12 col-md-7">
          <Form.Label>Invite your team members</Form.Label>
        </div>
        <div className="col-12 col-md-4 ps-0">
          <Form.Label>Select the role</Form.Label>
        </div>
      </div>
      <div className="scroll-invite-team-member-email">
        <FieldArray
          name={fieldName}
          render={(arrayHelpers) => (
            <div>
              {formProps.values[fieldName] &&
                formProps.values[fieldName].length > 0 &&
                formProps.values[fieldName].map((emails, index) => (
                  <div
                    key={index}
                    className={
                      formProps.values[fieldName].length > 4
                        ? 'pe-2 row'
                        : 'pe-0 row'
                    }
                  >
                    <div className="col-12 col-md-7">
                      <Form.Group
                        key={index}
                        className="form-control-label label-required mb-3"
                      >
                        <Field
                          className="form-control inputhovershow"
                          type="text"
                          placeholder="name@example.com"
                          name={`${fieldName}.${index}.email`}
                        />
                        <ErrorMessage
                          name={`${fieldName}.${index}.email`}
                          render={(msg) => (
                            <small className="text-danger">{msg}</small>
                          )}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-4 mr-1 ps-0">
                      <Form.Group
                        key={index}
                        className="form-control-label label-required mb-3"
                      >
                        <Select
                          name={`${fieldName}.${index}.role`}
                          options={options}
                          defaultValue={options[0]}
                          menuPortalTarget={document.querySelector('body')}
                          onChange={(event) => {
                            formProps.setFieldValue(
                              `${fieldName}.${index}.role`,
                              event.value
                            );
                          }}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary: '#0649b5',
                              neutral10: '#edf2f9',
                              neutral20: '#d2ddec',
                              neutral30: '#d2ddec',
                              neutral50: '#b1c2da',
                              neutral80: '#12263f',
                              dangerLight: '#DEEBFF',
                              danger: '#12263f',
                            },
                          })}
                          styles={{
                            indicatorSeparator: () => {}, // Disable indicator separator
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                        />

                        <ErrorMessage
                          name={`${fieldName}.${index}.role`}
                          render={(msg) => (
                            <small className="text-danger">{msg}</small>
                          )}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-md-1 mr-1  ps-3 position-relative">
                      {formProps.values[fieldName].length > 1 && (
                        <Button
                          className="email-invite-close-button"
                          variant="link"
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        >
                          <i className="mdi mdi-window-close " />
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              {maxInputField > 0 ? (
                <div className="mb-4">
                  {formProps.values[fieldName] &&
                    formProps.values[fieldName].length < maxInputField && (
                      <a
                        href="true"
                        onClick={(e) => {
                          e.preventDefault();
                          arrayHelpers.push({ email: '', role: '' });
                        }}
                      >
                        + Add another
                      </a>
                    )}
                </div>
              ) : (
                formProps.values[fieldName].length < maxFieldAllowed && (
                  <div className="mb-4">
                    <a
                      href="true"
                      onClick={(e) => {
                        e.preventDefault();
                        arrayHelpers.push({ email: '', role: '' });
                      }}
                    >
                      + Add another
                    </a>
                  </div>
                )
              )}
            </div>
          )}
        />
      </div>
    </Form.Group>
  );
};

InviteNewTeamMember.propTypes = {
  formProps: PropTypes.any,
  showPlanText: PropTypes.string,
  maxInputField: PropTypes.number,
  fieldName: PropTypes.string,
  maxFieldAllowed: PropTypes.number,
};

export default InviteNewTeamMember;
