import { activityService } from '../services';
import { LOCAL_STORAGE_TEAM_OWNER } from '../constants';

export const fetchRecentActivity = () => async (dispatch) => {
  dispatch({ type: 'FETCHING_RECENT_ACTIVITY' });
  try {
    const params = {
      teamOwnerId: localStorage.getItem(LOCAL_STORAGE_TEAM_OWNER),
    };
    const response = await activityService.get_recent_activity(params);
    if (response.status) {
      dispatch({ type: 'SET_RECENT_ACTIVITY', payload: response.data });
    }
  } catch {
    dispatch({ type: 'FETCH_RECENT_ACTIVITY_ERROR' });
  }
};
