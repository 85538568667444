import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { ToggleSwitch } from '../common';

const CreateProjectStep2 = ({ prevStep, nextStep, formProps }) => {
  const [screenshotSettings, setScreenshotSettings] = useState(true);
  // Update view if screenshot disable option is selected
  const handleSsIntervalChange = (event) => {
    if (event.target.value === '0') {
      setScreenshotSettings(false);
    } else {
      setScreenshotSettings(true);
    }
  };

  // Toggle switch
  const handleToggleSwitch = (id) => {
    formProps.setFieldValue(id, !formProps.values[id]);
    if (id === 'displayScreenshotsOnClient') {
      formProps.setFieldValue('canDeleteScreenshot', false);
      formProps.setFieldValue('allowScreenshotDiscard', false);
    }
  };

  // Reset step two fields
  const resetStepTwo = () => {
    formProps.setFieldValue('screenshotIntervalSeconds', 600);
    formProps.setFieldValue('displayScreenshotsOnClient', false);
    formProps.setFieldValue('canDeleteScreenshot', false);
    formProps.setFieldValue('allowScreenshotDiscard', false);
    formProps.setFieldValue('allowBlurring', false);
    formProps.setFieldValue('allowForceScreenshot', false);
    formProps.setFieldValue('displayContactDetails', false);
    formProps.setFieldValue('allowTaskCreation', true);
  };

  return (
    <section>
      <div className="text-center">
        <h5 className="text-uppercase text-muted fw-light">Step 2 of 3</h5>
        <h2>Now, Let’s look at project settings</h2>
        <p className="text-muted font-14 font-weight-normal mb-5">
          We’ll learn how we should tailor our <br />
          screenshots and settings to your team’s needs
        </p>
      </div>
      <Row className="mb-4">
        <Col md="5">
          <Form.Group controlId="ScreenshotInterval">
            <Form.Label>Screenshot interval</Form.Label>
            <div className="text-muted mb-1">
              How often do you want to take a screenshot of your employees’
              computer?
            </div>
            <Field
              className="form-select"
              as="select"
              name="screenshotIntervalSeconds"
              onChange={(e) => {
                formProps.handleChange(e);
                handleSsIntervalChange(e);
              }}
            >
              <option value="420">Every 4-10 Minutes</option>
              <option value="600" selected>
                Every 7-13 Minutes
              </option>
              <option value="900">Every 12-18 Minutes</option>
              <option value="1800">Every 27-33 Minutes</option>
              <option value="2700">Every 42-48 Minutes</option>
              <option value="3600">Every 57-63 Minutes</option>
              <option value="0">Disable Screenshots</option>
            </Field>
          </Form.Group>
        </Col>
        {screenshotSettings && (
          <Col md="5" className="ms-auto">
            <div>
              <ToggleSwitch
                id="displayScreenshotsOnClient"
                name="displayScreenshotsOnClient"
                checked={formProps.values.displayScreenshotsOnClient}
                handleChange={handleToggleSwitch}
                labelText="Hide screenshots"
                optionalText="Team members will not be able to see their screenshots in the desktop app or in the web portal"
              />
            </div>
          </Col>
        )}
      </Row>
      {screenshotSettings && (
        <>
          {!formProps.values.displayScreenshotsOnClient && (
            <Row className="mb-4">
              <Col md="5">
                <ToggleSwitch
                  id="canDeleteScreenshot"
                  name="canDeleteScreenshot"
                  checked={formProps.values.canDeleteScreenshot}
                  handleChange={handleToggleSwitch}
                  labelText="Delete screenshots"
                  optionalText="Team members will be able to delete screenshots from inside the web portal"
                />
              </Col>
              <Col md="5" className="ms-auto">
                <div>
                  <ToggleSwitch
                    id="allowScreenshotDiscard"
                    name="allowScreenshotDiscard"
                    checked={formProps.values.allowScreenshotDiscard}
                    handleChange={handleToggleSwitch}
                    labelText="Discard screenshots"
                    optionalText="Team members will be able to discard screenshots from desktop app"
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row className="mb-4">
            <Col md="5">
              <ToggleSwitch
                id="allowBlurring"
                name="allowBlurring"
                checked={formProps.values.allowBlurring}
                handleChange={handleToggleSwitch}
                labelText="Blur screenshots"
                optionalText="Automatically blurs screenshots to hide sensitive information"
              />
            </Col>
            <Col md="5" className="ms-auto">
              <div>
                <ToggleSwitch
                  id="allowForceScreenshot"
                  name="allowForceScreenshot"
                  checked={formProps.values.allowForceScreenshot}
                  handleChange={handleToggleSwitch}
                  labelText="Instant screenshots"
                  optionalText="Team members will be able to take a screenshot instantly rather than waiting for the next random screenshot"
                />
              </div>
            </Col>
          </Row>
        </>
      )}
      <Row className="mb-4">
        <Col md="5">
          <div>
            <ToggleSwitch
              id="allowTaskCreation"
              name="allowTaskCreation"
              checked={formProps.values.allowTaskCreation}
              handleChange={handleToggleSwitch}
              labelText="Create tasks"
              optionalText="Team members will be able to create their own tasks"
            />
          </div>
        </Col>
        {/*         <Col md="5">
          <ToggleSwitch
            id="displayContactDetails"
            name="displayContactDetails"
            checked={formProps.values.displayContactDetails}
            handleChange={handleToggleSwitch}
            labelText="Hide contact information"
            optionalText="Can team members see each other’s contact information?"
          />
        </Col> */}
      </Row>
      <hr className="my-5" />
      <Row className="align-items-center">
        <Col className="col-auto">
          <Button
            className="btn btn-lg btn-white"
            type="button"
            onClick={resetStepTwo}
          >
            Cancel
          </Button>
        </Col>
        <Col className="text-center">
          <h6 className="text-uppercase text-muted mb-0">Step 2 of 3</h6>
        </Col>
        <Col className="col-auto">
          <Button
            variant="secondary"
            size="lg"
            type="button"
            className="me-2"
            onClick={prevStep}
          >
            Back
          </Button>
          <Button
            variant="primary"
            size="lg"
            type="button"
            onClick={() => {
              nextStep(formProps);
            }}
          >
            Continue
          </Button>
        </Col>
      </Row>
    </section>
  );
};

CreateProjectStep2.propTypes = {
  nextStep: PropTypes.func,
  prevStep: PropTypes.func,
  formProps: PropTypes.any,
};

export default CreateProjectStep2;
