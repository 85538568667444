import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import Routes from './routes';
import './assets/styles/theme.scss';
import 'react-toastify/dist/ReactToastify.css';
// import IdleTimerContainer from './components/IdleTimerContainer';
import { userService } from './services';
import { AUTH_EXPIRY, TOKEN_CREATED_AT } from './constants';
import { shareSessionAcrossTabs } from './helpers';

function App() {
  shareSessionAcrossTabs();
  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = moment(new Date());
      const createdTime = moment(
        new Date(sessionStorage.getItem(TOKEN_CREATED_AT))
      );
      // Diff of currentTime and createdTime
      const currentSessionDuration = currentTime.diff(createdTime, 'seconds');
      // Deduct 5 minutes from expiresIn to make room for the refresh_token request
      const expiryTime = sessionStorage.getItem(AUTH_EXPIRY) - 300;
      // if currentSessionDuration equal to expiryTime - trigger refresh token request
      if (currentSessionDuration === expiryTime) {
        userService.refresh_token();
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  return (
    <div className="App">
      <Routes />
      <ToastContainer pauseOnHover={false} />
      {/* <IdleTimerContainer /> */}
    </div>
  );
}

export default App;
