import PropTypes from 'prop-types';
import { Card, Row } from 'react-bootstrap';
import moment from 'moment';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar';
import ScreenshotSlider from '../ScreenshotSlider';
import { NoDataAvailable } from '../common';
import { NO_DATA, PAGE_URLS, LIGHTBOX_OPTIONS } from '../../constants';

const ProjectRecentActivityCard = ({
  cardTitle,
  recentActivity,
  userId,
  memberName,
}) => (
  <Card>
    <Card.Header>
      <h4 className="m-0">{cardTitle}</h4>
      <div className="col-6 text-end">
        <div role="tablist" id="myTabs">
          {recentActivity.length > 0 && (
            <Link
              to={{
                pathname: PAGE_URLS.ACTIVITY.TEAM,
                search: `?teamMember=${userId}`,
                state: {
                  bredCrumbs: [
                    {
                      name: 'Active Team Members',
                      path: PAGE_URLS.TEAM.ACTIVE,
                    },
                    { name: 'Projects', path: `/team/projects/${userId}` },
                    { name: memberName, path: '' },
                  ],
                },
              }}
            >
              View all
            </Link>
          )}
        </div>
      </div>
    </Card.Header>
    <Card.Body>
      <SimpleReactLightbox>
        <SRLWrapper options={LIGHTBOX_OPTIONS}>
          {recentActivity.length > 0
            ? recentActivity.map((ws, index) => (
                <div
                  className="list-group list-group-flush list-group-activity my-n3"
                  key={`recentActivity-${index}`}
                >
                  <div className="list-group-item">
                    <Row key={index}>
                      <div className="col-auto">
                        <div className="avatar me-1">
                          <Avatar
                            firstName={ws.user.firstName}
                            lastName={ws.user.lastName}
                            imgPath={ws.user.avatar ? ws.user.avatar : ''}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <span className="name">
                          {ws.activity === 'worksession' && (
                            <small>Logged a worksession</small>
                          )}
                          {ws.activity === 'task' && (
                            <small>
                              Completed task,
                              <b> {ws.task}</b>
                            </small>
                          )}
                          {'project' in ws && ws.project.title && (
                            <small>
                              {' in '}
                              <b>{ws.project.title}</b>
                            </small>
                          )}
                        </span>
                        <p className="small text-muted mb-3">
                          {ws.checkInDate !== null && (
                            <>
                              {ws.workSessionType === 'desktop' && (
                                <span
                                  className="font-size-lg"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Desktop"
                                >
                                  <i className="mdi mdi-desktop-mac" />
                                </span>
                              )}
                              {ws.workSessionType === 'extension' && (
                                <span
                                  className="font-size-lg"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Extension"
                                >
                                  <i className="mdi mdi mdi-puzzle" />
                                </span>
                              )}
                              {ws.workSessionType === 'mobile' && (
                                <span
                                  className="font-size-lg"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Mobile"
                                >
                                  <i className="mdi mdi-cellphone-iphone" />
                                </span>
                              )}
                              {ws.workSessionType === 'web' && (
                                <span
                                  className="font-size-lg"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Web"
                                >
                                  <i className="mdi mdi-web" />
                                </span>
                              )}

                              <time className="small text-muted">
                                {' '}
                                {ws.activity === 'worksession' &&
                                  ws.checkInDate !== null &&
                                  moment(ws.checkInDate).fromNow()}
                                {ws.activity === 'task' &&
                                  ws.completedDate !== null &&
                                  moment(ws.completedDate).fromNow()}
                              </time>
                            </>
                          )}
                        </p>
                      </div>
                      <div className="col-lg-7 ml-auto">
                        {ws.activity === 'worksession' && (
                          <div>
                            {ws.isManual ? (
                              <NoDataAvailable
                                content={NO_DATA.MANUAL_SESSION}
                              />
                            ) : (
                              <ScreenshotSlider
                                screenshots={ws.screenshots}
                                showCheckboxSelection={false}
                                workSessionType={ws.workSessionType}
                                memberName={`${ws.user.firstName} ${
                                  ws.user.lastName || ''
                                }`}
                                screenshotDisabled={ws.screenshotDisabled}
                                sliderSize="sm"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </Row>
                  </div>
                </div>
              ))
            : 'No activity recorded for past two days'}
        </SRLWrapper>
      </SimpleReactLightbox>
    </Card.Body>
  </Card>
);
ProjectRecentActivityCard.propTypes = {
  cardTitle: PropTypes.string,
  recentActivity: PropTypes.array,
  userId: PropTypes.string,
  memberName: PropTypes.string,
};
export default ProjectRecentActivityCard;
