import React, { useState, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import Draggable from 'react-draggable';
import { StopWatch } from '.';
import { getUserData } from '../../helpers';
import {
  setShowTimerWidget,
  setTimerProject,
  setTimerTask,
  resetWidget,
  checkIn,
  checkOut,
  setCheckedInProjectTasksList,
  startTimer,
  stopTimer,
} from '../../actions/timer';
import { setTourStepIndex } from '../../actions/freeTrialTour';
import { startTour, endTour } from '../../actions';
import '../../assets/styles/timer.scss';
import { tasksService } from '../../services';
import { CLIENT_DOWNLOAD_URL } from '../../constants';
import { trackJune } from '../../utilities/analytics';

const TimerWidget = () => {
  const timer = useSelector((state) => state.timerReducer);
  const { trackTaskTimeType } = useSelector(
    (state) => state.freeTrialTourReducer
  );
  const {
    isRunning,
    checkedInProject,
    checkedInTask,
    worksessionId,
    projectTasksList,
  } = timer;
  const { memberProjects } = useSelector((state) => state.allProjects);
  const projectsList =
    memberProjects && memberProjects.length > 0
      ? memberProjects.map((project) => {
          const pItem = {
            value: project._id,
            label: project.title,
          };
          return pItem;
        })
      : [];
  const dispatch = useDispatch();
  const [isFetchingTasks, setIsFetchingTasks] = useState(false);
  const tasksList =
    projectTasksList && projectTasksList.length > 0
      ? projectTasksList
          .filter((item) => item.status !== 'complete')
          .map((task) => {
            const pItem = {
              value: task._id,
              label: task.task,
            };
            return pItem;
          })
      : [];

  const taskSelectRef = useRef(null);
  const getProjectTasks = (projectId) => {
    const memberId = getUserData('_id');
    const taskParams = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      projects: [projectId],
      members: [memberId],
    };
    if (projectId && projectId !== '') {
      setIsFetchingTasks(true);
      tasksService
        .get_tasks_list(taskParams)
        .then((result) => {
          if (result.data) {
            const { tasks } = result.data;
            dispatch(setCheckedInProjectTasksList([...tasks]));
          }
          setIsFetchingTasks(false);
        })
        .catch(() => {
          setIsFetchingTasks(false);
        });
    }
    // Clear value for task selection
    setTimeout(() => {
      taskSelectRef.current.select.clearValue();
    }, 0);
  };

  return (
    <Draggable>
      <div className="timer-widget bg-white p-4 rounded-3">
        <div className="widget-controls d-flex justify-content-end position-relative">
          <div className="position-absolute widget-buttons">
            <Button
              variant="link"
              size="sm"
              className="m-0 p-0"
              onClick={() => {
                dispatch(setShowTimerWidget());
              }}
            >
              <span className="mdi mdi-close" />
            </Button>
          </div>
        </div>
        <div className="timer-controls d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center start-stop-timer">
            {isRunning ? (
              <Button
                className="m-0 rounded-circle web-checkin-btn p-0"
                onClick={() => {
                  dispatch(stopTimer());
                  // checkOut
                  dispatch(
                    checkOut({
                      wsId: worksessionId,
                    })
                  );
                  if (
                    trackTaskTimeType &&
                    trackTaskTimeType.toLowerCase() === 'time tracking'
                  ) {
                    dispatch(setShowTimerWidget());
                    dispatch(endTour());
                    dispatch(setTourStepIndex({ stepIndex: 3 }));
                    setTimeout(() => {
                      dispatch(startTour());
                    }, 500);
                  }
                }}
                title="stop"
                variant="outline-danger"
              >
                <span className="mdi mdi-stop" />
              </Button>
            ) : (
              <Button
                className="m-0 rounded-circle web-checkin-btn p-0"
                onClick={() => {
                  dispatch(startTimer());
                  // checkIn
                  dispatch(
                    checkIn({
                      projectId: checkedInProject.id,
                      taskId: checkedInTask.id,
                    })
                  );

                  // dispatch(setShowTimerWidget());
                  if (
                    trackTaskTimeType &&
                    trackTaskTimeType.toLowerCase() === 'time tracking'
                  ) {
                    dispatch(endTour());
                    dispatch(setTourStepIndex({ stepIndex: 2 }));
                    setTimeout(() => {
                      dispatch(startTour());
                    }, 2000);
                  }
                  // june
                  trackJune('web check-in');
                }}
                title="start"
                variant="primary"
                disabled={
                  !(checkedInProject.name && checkedInProject.name.length > 0)
                }
              >
                <span className="mdi mdi-play" />
              </Button>
            )}

            <div className="mx-3">
              <p className="m-0 overflow-ellipses">
                {checkedInProject &&
                checkedInProject.name &&
                checkedInProject.name.length > 0
                  ? checkedInProject.name
                  : '-'}
              </p>
              <p className="m-0 overflow-ellipses">
                <small className="overflow-ellipses">
                  {checkedInTask &&
                  checkedInTask.name &&
                  checkedInTask.name.length > 0
                    ? checkedInTask.name
                    : ''}
                </small>
              </p>
            </div>
          </div>
          <span className="clock-lg">
            <StopWatch />
          </span>
        </div>
        <hr />
        <div className="pt-3 select-project-name">
          <Form>
            <Form.Group controlId="project">
              <Form.Label>Project</Form.Label>
              <Select
                options={projectsList}
                className="basic-multi-select"
                placeholder="Select Project"
                name="project"
                isClearable
                defaultValue={
                  checkedInProject &&
                  checkedInProject.name &&
                  checkedInProject.name.length > 0 && {
                    label: checkedInProject.name,
                    value: checkedInProject.id,
                  }
                }
                onChange={(event) => {
                  if (!event) {
                    // Reset widget if there is not event change
                    dispatch(resetWidget());
                    // checkOut
                    if (isRunning && worksessionId !== '') {
                      dispatch(
                        checkOut({
                          wsId: worksessionId,
                        })
                      );
                    }
                  } else {
                    // Store selected project and id
                    if (
                      trackTaskTimeType &&
                      trackTaskTimeType.toLowerCase() === 'time tracking'
                    ) {
                      dispatch(endTour());
                      dispatch(setTourStepIndex({ stepIndex: 1 }));
                      dispatch(startTour());
                    }

                    dispatch(
                      setTimerProject({
                        projectId: event.value,
                        projectName: event.label,
                      })
                    );
                    // Stop timer if user change project in between
                    if (isRunning && worksessionId !== '') {
                      dispatch(stopTimer());
                      // checkOut
                      dispatch(
                        checkOut({
                          wsId: worksessionId,
                        })
                      );
                    }
                    // Get all tasks under the selected project
                    getProjectTasks(event.value);
                  }
                }}
                noOptionsMessage={() => 'No project available'}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0649b5',
                    neutral10: '#edf2f9',
                    neutral20: '#d2ddec',
                    neutral30: '#d2ddec',
                    neutral50: '#b1c2da',
                    neutral80: '#12263f',
                    dangerLight: '#DEEBFF',
                    danger: '#12263f',
                  },
                })}
              />
            </Form.Group>
            <Form.Group controlId="task" className="mb-2">
              <Form.Label>Task</Form.Label>
              <Select
                ref={taskSelectRef}
                options={tasksList}
                className="basic-multi-select"
                placeholder="Select Task"
                name="task"
                isClearable
                isDisabled={isFetchingTasks}
                defaultValue={
                  checkedInTask &&
                  checkedInTask.name.length > 0 && {
                    label: checkedInTask.name,
                    value: checkedInTask.id,
                  }
                }
                onChange={(event) => {
                  if (!event) {
                    dispatch(stopTimer());
                    dispatch(
                      setTimerTask({
                        taskId: '',
                        taskName: '',
                      })
                    );
                    if (isRunning && worksessionId !== '') {
                      dispatch(
                        checkOut({
                          wsId: worksessionId,
                        })
                      );
                    }
                  } else {
                    // Store selected project and id
                    dispatch(
                      setTimerTask({
                        taskId: event.value,
                        taskName: event.label,
                      })
                    );
                    // Stop timer if user change task in between
                    if (isRunning && worksessionId !== '') {
                      dispatch(stopTimer());
                      // checkOut
                      dispatch(
                        checkOut({
                          wsId: worksessionId,
                        })
                      );
                    }
                  }
                }}
                noOptionsMessage={() => 'No task available'}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#0649b5',
                    neutral10: '#edf2f9',
                    neutral20: '#d2ddec',
                    neutral30: '#d2ddec',
                    neutral50: '#b1c2da',
                    neutral80: '#12263f',
                    dangerLight: '#DEEBFF',
                    danger: '#12263f',
                  },
                })}
              />
            </Form.Group>
          </Form>
        </div>
        {/* Download client for screenshot info message */}
        <div className="pt-3">
          <small>
            <i className="mdi mdi-information-outline" /> If you need to record
            screenshots, please{' '}
            <a href={CLIENT_DOWNLOAD_URL} target="_blank" rel="noreferrer">
              download
            </a>{' '}
            the desktop app.
          </small>
        </div>
      </div>
    </Draggable>
  );
};

export default TimerWidget;
