import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Card, Row, Col, Button, Alert } from 'react-bootstrap';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import { NO_DATA, DATE_AND_TIME, LIGHTBOX_OPTIONS } from '../../constants';
import { groupBy } from '../../helpers';
import ScreenshotSlider from '../ScreenshotSlider';
import { NoDataAvailable, NotesModal } from '../common';

const ScreenshotsList = ({
  screenshotData,
  selectedMemberInfo,
  generatePDF,
}) => {
  // Array with checkin date - for Grouping byDate
  const screenshotWithDate = screenshotData.map((item) => {
    const dateAndTime = item.checkInDate.split(' ');
    const checkInDate = dateAndTime[0];
    item.checkIn = checkInDate;
    return item;
  });

  const allScreenshots = screenshotData.flatMap((a) =>
    a.screenshots.filter((b) => b.screenshotPath)
  );
  const groupedByDate = groupBy(screenshotWithDate, 'checkIn');
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isSelected, setIsSelected] = useState([]);
  const [perPage, setPerPage] = useState(4);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIsSelected(allScreenshots.map((item) => item._id));
    if (isCheckAll) {
      setIsSelected([]);
    }
  };

  const setPerPageValue = (event) => {
    setPerPage(event.target.value);
  };

  const handleScreenshotSelection = (e) => {
    const { id, checked } = e.target;
    setIsSelected([...isSelected, id]);
    if (!checked) {
      setIsSelected(isSelected.filter((item) => item !== id));
      setIsCheckAll(false);
    }
    if (checked && allScreenshots.length === isSelected.length + 1) {
      setIsCheckAll(true);
    }
  };

  const generatePDFselected = () => {
    generatePDF(isSelected, perPage);
  };
  if (screenshotData.length > 0) {
    return (
      <Card className="screenshot-list">
        <Card.Header className="border-0">
          <Row>
            <Col className="col-auto d-flex align-items-center">
              <h4 className="card-header-title">
                {selectedMemberInfo.lastName
                  ? `${selectedMemberInfo.firstName} ${selectedMemberInfo.lastName}`
                  : selectedMemberInfo.firstName}
                {`${
                  selectedMemberInfo.status === 'inactive' ? ' (Inactive)' : ''
                }`}{' '}
                screenshots
              </h4>
            </Col>
            <Col className="form-inline">
              <div className="d-flex justify-content-end align-items-center">
                Screenshots per page{' '}
                <select
                  className="form-select tiny-select-input form-control form-control-sm ms-2 me-3"
                  onChange={setPerPageValue}
                >
                  <option>4</option>
                  <option>6</option>
                </select>
                <label
                  className="form-check-label mx-3"
                  htmlFor="select_all_screenshot"
                >
                  <input
                    className="form-check-input form-check-border"
                    id="select_all_screenshot"
                    name="selectAllScreenshot"
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={isCheckAll}
                  />{' '}
                  Select all
                </label>
                <Button
                  variant="white"
                  type="button"
                  size="sm"
                  disabled={isSelected.length === 0 ? 'disabled' : ''}
                  className="ms-3"
                  onClick={() => generatePDFselected(isSelected)}
                >
                  Create PDF
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <SimpleReactLightbox>
          <SRLWrapper options={LIGHTBOX_OPTIONS}>
            {Object.entries(groupedByDate).map((key, i) => {
              const workSession = [...key[1]];
              return (
                <React.Fragment key={`workSession-${i}`}>
                  <div className="bg-light px-4 py-3">
                    {moment(workSession[0].checkIn).format(DATE_AND_TIME.DATE)}
                  </div>
                  <Card.Body>
                    {workSession.map((session, index) => {
                      const checkInDateAndTime = session.checkInDate.split(' ');
                      const checkInTime = moment(checkInDateAndTime[1], [
                        'HH:mm:ss',
                      ]).format(DATE_AND_TIME.TIME);
                      const checkOutDateAndTime =
                        session.checkOutDate !== null &&
                        session.checkOutDate.split(' ');
                      const checkOutTime =
                        session.checkOutDate !== null &&
                        moment(checkOutDateAndTime[1], ['HH:mm:ss']).format(
                          DATE_AND_TIME.TIME
                        );
                      return (
                        <React.Fragment key={`worksession-${index}`}>
                          <Row>
                            <Col>
                              {session.checkOutDate === null ? (
                                <h4 className="mb-3">{checkInTime}</h4>
                              ) : (
                                <h4 className="mb-3">
                                  {checkInTime} to {checkOutTime}
                                </h4>
                              )}
                            </Col>
                            <Col className="col-auto">
                              {session.notes && session.notes.length > 0 && (
                                <NotesModal notesList={session.notes} />
                              )}
                            </Col>
                          </Row>
                          <section>
                            {session.isManual ? (
                              <NoDataAvailable
                                content={NO_DATA.MANUAL_SESSION}
                              />
                            ) : (
                              <ScreenshotSlider
                                screenshots={session.screenshots}
                                showCheckboxSelection
                                isSelected={isSelected}
                                handleScreenshotSelection={
                                  handleScreenshotSelection
                                }
                                workSessionType={session.workSessionType}
                                screenshotDisabled={session.screenshotDisabled}
                                memberName={`${selectedMemberInfo.firstName} ${
                                  selectedMemberInfo.lastName || ''
                                }${
                                  selectedMemberInfo.status === 'inactive'
                                    ? ' (Inactive)'
                                    : ''
                                }`}
                                sliderSize="lg"
                              />
                            )}
                          </section>
                          <hr className="my-4" />
                        </React.Fragment>
                      );
                    })}
                  </Card.Body>
                </React.Fragment>
              );
            })}
          </SRLWrapper>
        </SimpleReactLightbox>
      </Card>
    );
  }
  return (
    <Alert variant="info" className="text-center mx-4">
      {NO_DATA.WORKSESSION}
    </Alert>
  );
};

ScreenshotsList.propTypes = {
  screenshotData: PropTypes.array,
  selectedMemberInfo: PropTypes.any,
  generatePDF: PropTypes.func,
};

export default ScreenshotsList;
