import JoyRide, { STATUS } from 'react-joyride';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import '../assets/styles/tour.scss';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import Loader from './Loader';
import { endTour, startTour } from '../actions';
import {
  setTourStepIndex,
  setTourTrackTaskTime,
} from '../actions/freeTrialTour';
import { TourService } from '../services';
import ThumbsUpDown from './ThumbsUpDown';
import { TOUR_INPUT, SURVEY_ID } from '../constants';
import { setShowTimerWidget } from '../actions/timer';

// Tour component
const Tour = ({ start }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [continuousEnableIndex, setContinuousEnableIndex] = useState([]);
  const userDetails = useSelector((state) => state.userReducer);
  const timer = useSelector((state) => state.timerReducer);
  const { trackTaskTimeType, stepIndex } = useSelector(
    (state) => state.freeTrialTourReducer
  );
  const dispatch = useDispatch();

  const handleChangeSelectLikeDisLike = (selectedValue) => {
    setTimeout(() => {
      setIsSubmitting(true);
      const data = {
        surveyId: SURVEY_ID,
        response: [
          {
            question: 'Do you think HiveDesk is a good fit for your company?',
            answer: selectedValue.answer,
          },
          { question: 'Feedback', answer: selectedValue.feedback },
        ],
      };
      dispatch(setTourTrackTaskTime({ trackTaskTimeType: '' }));
      dispatch(setTourStepIndex({ stepIndex: 0 }));
      dispatch(endTour());
      TourService.submit_tour(data)
        .then(() => {
          toast.success(TOUR_INPUT);
        })
        .catch(() => {})
        .finally(() => {
          localStorage.removeItem('TourAutoStart');
          setIsSubmitting(false);
        });
    }, 700);
  };

  const setStepIndexForActivityTour = (index) => {
    dispatch(endTour());
    dispatch(setTourStepIndex({ stepIndex: index }));
    dispatch(startTour());
  };
  // This is for non-members time tracking
  const TIME_TRACKING_TOUR_STEPS = [
    {
      target: '.select-project-name',
      spotlightClicks: true,
      content: <p>Select your Project from the dropdown.</p>,
      placement: 'left',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 11,
      offset: 0,
      disableOverlayClose: true,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideFooter: false,
      continuous: false,
      styles: {
        options: {
          width: 290,
        },
      },
    },
    {
      target: '.start-stop-timer',
      spotlightClicks: true,

      content: <p>Hit the play button to start the timer.</p>,
      placement: 'left',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 11,
      offset: 0,
      disableOverlayClose: true,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideFooter: false,
      continuous: false,
      hideBackButton: true,
      styles: {
        options: {
          width: 290,
        },
      },
    },

    {
      target: '.start-stop-timer',
      spotlightClicks: true,

      content: <p>Hit the stop button to stop the timer.</p>,
      placement: 'left',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 11,
      offset: 0,
      disableOverlayClose: true,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideFooter: false,
      continuous: false,
      hideBackButton: true,
      styles: {
        options: {
          width: 290,
        },
      },
    },
    {
      target: '.nav-timesheet',
      content: <div>Great! Let's have a look at your first Timesheet.</div>,
      hideCloseButton: true,
      disableOverlayClose: true,
      spotlightClicks: false,
      placement: 'right',
      disableBeacon: true,
      spotlightPadding: 0,
      offset: 0,
      showSkipButton: true,
      disableCloseOnEsc: true,
      hideBackButton: false,
      locale: {
        next: 'Go to Timesheet',
        back: 'Back',
        close: 'Close',
      },
    },
    {
      target: '.timesheet-filter-tour',
      content: (
        <div>
          You can filter by Team Members, Team Group, Projects, Task or Date
          Range.
        </div>
      ),
      hideCloseButton: true,
      disableOverlayClose: true,
      continuous: true,
      spotlightClicks: true,
      placement: 'left',
      disableBeacon: true,
      spotlightPadding: 5,
      offset: 0,
      showSkipButton: true,
      disableCloseOnEsc: true,
      hideBackButton: false,
      locale: {
        next: 'Next',
        back: 'Back',
      },
    },
    {
      target: '.timesheet-group-by-report',
      content: (
        <div>Group your report by Team Member, Projects, Tasks or Date.</div>
      ),
      hideCloseButton: true,
      disableOverlayClose: true,
      spotlightClicks: true,
      placement: 'top',
      disableBeacon: true,
      spotlightPadding: 5,
      offset: 0,
      showSkipButton: true,
      disableCloseOnEsc: true,
      hideBackButton: false,
      locale: {
        next: 'Next',
        back: 'Back',
      },
    },
    {
      target: '.main-content',
      content: (
        <div className="text-center mb-5">
          <h4 className="mb-4">
            Do you think HiveDesk is a good <br /> fit for your company?
          </h4>
          <ThumbsUpDown handleChangeSelect={handleChangeSelectLikeDisLike} />
        </div>
      ),
      hideCloseButton: false,
      disableOverlayClose: true,
      continuous: true,
      spotlightClicks: false,
      placement: 'center',
      disableBeacon: true,
      spotlightPadding: 0,
      offset: 0,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideBackButton: true,
      locale: {
        next: 'Next',
      },
      hideFooter: true,
    },
  ];

  // This is for non-members task management
  const TASK_MANAGEMENT_TOUR_STEPS = [
    {
      target: '.kanban-add-link-section',
      spotlightClicks: true,
      content: (
        <div>
          Sections are a way to divide your project into chunks. For example,
          sections could be project
          <b> phases, task bundles, milestones, deliverables</b> or the
          different roles working on your project.
        </div>
      ),
      placement: 'left',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 11,
      offset: 0,
      disableOverlayClose: true,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideFooter: false,
      styles: {
        options: {
          width: 390,
        },
      },
    },
    {
      target: '.add-section-form',
      spotlightClicks: true,
      content: <p className="mb-0">Enter your first section name.</p>,
      placement: 'left',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 11,
      offset: 0,
      disableOverlayClose: true,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideBackButton: true,
      hideFooter: false,
      styles: {
        options: {
          width: 230,
        },
      },
    },
    {
      target: '.kanban-add-link-task',
      spotlightClicks: true,
      content: <p>Add your first task.</p>,
      placement: 'left',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 11,
      offset: 0,
      disableOverlayClose: true,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideFooter: false,
      hideBackButton: true,
      styles: {
        options: {
          width: 170,
        },
      },
    },
    {
      target: '.kanban-add-form',
      spotlightClicks: true,
      content: <p>Enter your first task.</p>,
      placement: 'top',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 17,
      offset: 0,
      disableOverlayClose: true,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideFooter: false,
      hideBackButton: true,
      styles: {
        options: {
          width: 172,
        },
      },
    },
    {
      target: '.card-task',
      content: (
        <p>
          On click the three dots to mark the task as complete, edit, copy or
          delete it.
          <br />
          <br />
          In edit mode you can add extra content to your task, such as a
          description, priority level and due date.
        </p>
      ),
      hideCloseButton: true,
      disableOverlayClose: true,
      continuous: true,
      spotlightClicks: false,
      placement: 'left',
      disableBeacon: true,
      spotlightPadding: 0,
      offset: 0,
      showSkipButton: true,
      disableCloseOnEsc: true,
      hideBackButton: false,
      locale: {
        next: 'Next',
        back: 'Back',
        close: 'Close',
      },
    },
    {
      target: '.task-checkin-btn-inactive',
      content: (
        <p className="mb-0">
          You can track time worked on each task by hitting the play button.
          <br />
          This button only appears for tasks that are assigned to you.
        </p>
      ),
      hideCloseButton: true,
      disableOverlayClose: true,
      continuous: true,
      spotlightClicks: false,
      placement: 'left',
      disableBeacon: true,
      spotlightPadding: 5,
      offset: 0,
      showSkipButton: true,
      disableCloseOnEsc: true,
      hideBackButton: false,
      styles: {
        options: {
          width: 430,
        },
      },
      locale: {
        next: 'Next',
        back: 'Back',
        close: 'Close',
      },
    },
    {
      target: '.task-board-filter',
      content: (
        <div>Filter your tasks by Team Members, Due Date, or Status.</div>
      ),
      hideCloseButton: true,
      disableOverlayClose: true,
      continuous: true,
      spotlightClicks: true,
      placement: 'bottom',
      disableBeacon: true,
      spotlightPadding: 3,
      offset: 0,
      showSkipButton: true,
      disableCloseOnEsc: true,
      hideBackButton: false,
      locale: {
        next: 'Next',
        back: 'Back',
        close: 'Close',
      },
    },
    {
      target: '.task-list-tab',
      content: <div>you can also view your tasks in list format.</div>,
      hideCloseButton: true,
      disableOverlayClose: true,
      continuous: true,
      spotlightClicks: false,
      placement: 'left',
      disableBeacon: true,
      spotlightPadding: 5,
      offset: 0,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideBackButton: false,
      locale: {
        back: 'Back',
      },
      styles: {
        options: {
          width: 310,
        },
      },
    },
  ];

  // This is for non-members
  const TOUR_STEPS_AO = [
    {
      target: '.main-content',
      title: (
        <>
          <span>Welcome to HiveDesk</span>
          <span className="badge bg-primary-soft">1/6</span>
        </>
      ),
      content: (
        <p>
          This tour will show you how to access the key features of HiveDesk -
          Let's go!
        </p>
      ),
      placement: 'center',
      hideCloseButton: true,
      disableOverlayClose: true,
    },

    {
      target: '.nav-projects',
      title: (
        <>
          <span>View, Create, and Manage Projects</span>
          <span className="badge bg-primary-soft">2/6</span>
        </>
      ),
      content: (
        <>
          <p>
            Access project features of HiveDesk via Projects{' '}
            <span className="mdi mdi-arrow-right" /> Active. From here, you can:
          </p>
          <ul>
            <li>Create new projects</li>
            <li>Invite team members</li>
            <li>Assign tasks</li>
            <li>View work sessions</li>
          </ul>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 0,
      offset: 0,
      disableOverlayClose: true,
    },
    {
      target: '.nav-team',
      title: (
        <>
          <span>Add, Delete, and Review Team Members</span>
          <span className="badge bg-primary-soft">3/6</span>
        </>
      ),
      content: (
        <>
          <p>
            View Team Member data from Team{' '}
            <span className="mdi mdi-arrow-right" /> Active. You can:
          </p>
          <ul>
            <li>View team member info</li>
            <li>Add team member</li>
            <li>Email or download work sessions of team members</li>
          </ul>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 0,
      offset: 0,
      disableOverlayClose: true,
    },
    {
      target: '.nav-timesheet',
      title: (
        <>
          <span>Generate and Email Reports</span>
          <span className="badge bg-primary-soft">4/6</span>
        </>
      ),
      content: (
        <>
          <p>
            Gain insight into team member activity via Reports. Reports
            currently supported include
          </p>
          <ul>
            <li>Timesheet reports</li>
            <li>Work session charts</li>
            <li>Daily work summaries</li>
            <li>Weekly reports</li>
            <li>Screenshot reports</li>
          </ul>
          <p>Create reports and email them to yourself and/or others.</p>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 0,
      offset: 0,
      disableOverlayClose: true,
    },
    {
      target: '.nav-activity',
      title: (
        <>
          <span>View Team Member's Activity</span>
          <span className="badge bg-primary-soft">5/6</span>
        </>
      ),
      content: <p>Review your team member's activity here.</p>,
      placement: 'right',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 0,
      offset: 0,
      disableOverlayClose: true,
    },
    {
      target: '.main-content',
      title: (
        <>
          <span>Get Started</span>
          <span className="badge bg-primary-soft">6/6</span>
        </>
      ),
      content: (
        <p>
          If you would like to start the tour again, go to{' '}
          <i className="mdi mdi-map-outline" /> on the top right of the page.
          <br />
          If you have questions or need help, go to{' '}
          <i className="mdi mdi-help-circle-outline" /> on the top right of the
          page.
        </p>
      ),
      placement: 'center',

      hideCloseButton: true,
      disableOverlayClose: true,
    },
  ];
  // This is for team members
  const TOUR_STEPS_TM = [
    {
      target: '.main-content',
      title: (
        <>
          <span>Welcome to HiveDesk</span>
          <span className="badge bg-primary-soft">1/6</span>
        </>
      ),
      content: (
        <p>
          This tour will show you how to access the key features of HiveDesk -
          Let's go!
        </p>
      ),
      placement: 'center',
      hideCloseButton: true,
      disableOverlayClose: true,
    },

    {
      target: '.nav-projects',
      title: (
        <>
          <span>Accept the Project Invitation</span>
          <span className="badge bg-primary-soft">2/6</span>
        </>
      ),
      content: (
        <p>
          You can accept invitation for a project by clicking an Accept
          invitation button. After accepting, you will be a team member of this
          project.
        </p>
      ),
      placement: 'right',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 0,
      offset: 0,
      disableOverlayClose: true,
    },
    {
      target: '.main-content',
      title: (
        <>
          <span>Download the HiveDesk Client</span>
          <span className="badge bg-primary-soft">3/6</span>
        </>
      ),
      content: (
        <p>
          Download the HiveDesk client - You can choose your HiveDesk client
          based on your operating system. <br />
          Use the same username and password to connect to the HiveDesk client
          that you did to log into the web interface
        </p>
      ),
      placement: 'center',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 0,
      offset: 0,
      disableOverlayClose: true,
    },
    {
      target: '.main-content',
      title: (
        <>
          <span>Start Working with HiveDesk Client</span>
          <span className="badge bg-primary-soft">4/6</span>
        </>
      ),
      content: (
        <p>
          After login, select the project you want to work on from the project
          list and click the Check In button.
          <br />
          From there, you can see your tasks in the task list. Mark tasks as
          completed as they are finished.
        </p>
      ),
      placement: 'center',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 0,
      offset: 0,
      disableOverlayClose: true,
    },
    {
      target: '.nav-projects',
      title: (
        <>
          <span>Reading Project Notes</span>
          <span className="badge bg-primary-soft">5/6</span>
        </>
      ),
      content: (
        <p>
          If the Project Creator has posted any Project Notes, you can view them
          after logging into the HiveDesk client.
        </p>
      ),
      placement: 'right',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 0,
      offset: 0,
      disableOverlayClose: true,
    },
    {
      target: '.main-content',
      title: (
        <>
          <span>Get Started</span>
          <span className="badge bg-primary-soft">6/6</span>
        </>
      ),
      content: (
        <p>
          If you would like to start the tour again, go to{' '}
          <i className="mdi mdi-map-outline" /> on the top right of the page.
          <br />
          If you have questions or need help, go to{' '}
          <i className="mdi mdi-help-circle-outline" /> on the top right of the
          page.
        </p>
      ),
      placement: 'center',
      hideCloseButton: true,
      disableOverlayClose: true,
    },
  ];

  // This is for activity monitoring
  const ACTIVITY_MONITIRING_TOUR_STEPS = [
    {
      target: '.App',
      disableBeacon: true,
      content: (
        <div className="text-center">
          <h2 className="mt-2">
            Activity monitoring requires your employees to download our desktop
            software.
          </h2>

          <h4 className="text fs-5" style={{ marginBottom: '30px' }}>
            Come back to this page after you start tracking in desktop software
            to view your screenshot report.
          </h4>
          <div className="m-5">
            <Button
              onClick={() => {
                setStepIndexForActivityTour(1);
                window.open('https://www.hivedesk.com/download/', '_blank');
              }}
            >
              Download
            </Button>
          </div>
        </div>
      ),
      placement: 'center',
      hideCloseButton: false,
      disableOverlayClose: true,
      hideFooter: true,
      styles: {
        options: {
          width: 600,
        },
      },
    },
    {
      target: '.tour-for-generate',
      spotlightClicks: true,
      content: <p>Click Generate to run activity report.</p>,
      placement: 'left',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 5,
      offset: 0,
      disableOverlayClose: true,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideFooter: true,
      continuous: false,
      styles: {
        options: {
          width: 275,
        },
      },
    },
    {
      target: '.tour-for-generate',
      content: (
        <p className="mb-0">
          We haven't found any activity to report on.
          <br />
          <br />
          Please start tracking in our desktop software and then click on
          Generate to run activity report.
        </p>
      ),
      hideCloseButton: true,
      disableOverlayClose: true,
      continuous: false,
      spotlightClicks: true,
      placement: 'left',
      disableBeacon: true,
      spotlightPadding: 5,
      offset: 0,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideBackButton: true,
      locale: {
        next: 'Close',
      },
      styles: {
        options: {
          width: 479,
        },
      },
    },
    {
      target: '.tour-activity',
      spotlightClicks: false,
      content: (
        <div>
          Activity is calculated based on mouse movements and key strokes.
        </div>
      ),
      placement: 'left',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 3,
      offset: 0,
      disableOverlayClose: true,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideBackButton: true,
      locale: {
        next: 'Next',
      },
    },
    {
      target: '.tour-activity-total-hour',
      spotlightClicks: false,
      content: <div>Total time logged is displayed here.</div>,
      placement: 'left',
      disableBeacon: true,
      hideCloseButton: true,
      spotlightPadding: 0,
      offset: 0,
      disableOverlayClose: true,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideBackButton: true,
      continuous: true,
      locale: {
        next: 'Next',
      },
      styles: {
        options: {
          width: 265,
        },
      },
    },
    {
      target: '.tour-activity-time',
      content: <div>Time logged per session is shown here.</div>,
      hideCloseButton: true,
      disableOverlayClose: true,
      continuous: true,
      spotlightClicks: false,
      placement: 'left',
      disableBeacon: true,
      spotlightPadding: 5,
      offset: 0,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideBackButton: true,
      locale: {
        next: 'Next',
      },
      styles: {
        options: {
          width: 287,
        },
      },
    },
    {
      target: '.swiper-slide',
      content: <div>Screenshots in session are displayed here.</div>,
      hideCloseButton: true,
      disableOverlayClose: true,
      continuous: true,
      spotlightClicks: false,
      placement: 'left',
      disableBeacon: true,
      spotlightPadding: 5,
      offset: 0,
      showSkipButton: false,
      disableCloseOnEsc: true,
      hideBackButton: true,
      styles: {
        options: {
          width: 287,
        },
      },
    },
  ];
  const handleJoyrideCallback = (data) => {
    const { status, index, action, type } = data;

    if (userDetails.role === 'member' || !trackTaskTimeType) {
      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        localStorage.removeItem('TourAutoStart');
        dispatch(endTour());
      }
    }

    if (
      trackTaskTimeType &&
      trackTaskTimeType.toLowerCase() === 'time tracking'
    ) {
      setContinuousEnableIndex([3, 4, 5]);
      if (index === 3 && action === 'prev' && type === 'step:after') {
        if (timer.showTimerWidget) {
          dispatch(setShowTimerWidget());
        }
        dispatch(setShowTimerWidget());
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 1 }));
        dispatch(startTour());
      } else if (action === 'prev' && type === 'step:after') {
        if (
          index === 4 &&
          window.location.pathname === '/timesheet/detailed' &&
          !document
            .querySelector('li.nav-timesheet > a')
            .classList.contains('collsape')
        ) {
          document.querySelector('li.nav-timesheet > a').click();
        }

        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: index - 1 }));
        dispatch(startTour());
      }

      if (action === 'close' && type === 'step:after') {
        dispatch(endTour());
        setTimeout(() => {
          dispatch(setTourTrackTaskTime({ trackTaskTimeType: '' }));
          dispatch(setTourStepIndex({ stepIndex: 0 }));
          localStorage.removeItem('TourAutoStart');
        }, 500);
      }

      if (index === 3 && action === 'next' && type === 'step:after') {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 4 }));
        window.location.href = '/timesheet/detailed';
      }

      if (index === 4 && action === 'next' && type === 'step:after') {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 5 }));
        dispatch(startTour());
      }
      if (index === 5 && action === 'next' && type === 'step:after') {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 6 }));
        setTimeout(() => {
          dispatch(startTour());
        }, 2000);
      }

      if (action === 'close' && type === 'step:after') {
        dispatch(endTour());
        setTimeout(() => {
          dispatch(setTourTrackTaskTime({ trackTaskTimeType: '' }));
          dispatch(setTourStepIndex({ stepIndex: 0 }));
          localStorage.removeItem('TourAutoStart');
        }, 500);
      }

      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        dispatch(endTour());
        setTimeout(() => {
          dispatch(setTourTrackTaskTime({ trackTaskTimeType: '' }));
          dispatch(setTourStepIndex({ stepIndex: 0 }));
          localStorage.removeItem('TourAutoStart');
        }, 500);
      }
    }

    if (
      trackTaskTimeType &&
      trackTaskTimeType.toLowerCase() === 'task management'
    ) {
      setContinuousEnableIndex([4, 5, 6]);

      if (index === 1 && !document.querySelector('.add-section-form')) {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 0 }));
        setTimeout(() => {
          dispatch(startTour());
        }, 500);
      }

      if (index === 3 && !document.querySelector('.kanban-add-form')) {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 2 }));
        setTimeout(() => {
          dispatch(startTour());
        }, 500);
      }

      if (index === 4 && action === 'prev' && type === 'step:after') {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 2 }));
        dispatch(startTour());
      } else if (action === 'prev' && type === 'step:after') {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: index - 1 }));
        dispatch(startTour());
      }

      if (index === 4 && action === 'next' && type === 'step:after') {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 5 }));
        setTimeout(() => {
          dispatch(startTour());
        }, 500);
      }
      if (index === 5 && action === 'next' && type === 'step:after') {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 6 }));
        setTimeout(() => {
          dispatch(startTour());
        }, 500);
      }
      if (index === 6 && action === 'next' && type === 'step:after') {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 7 }));
        setTimeout(() => {
          dispatch(startTour());
        }, 500);
      }
      if (index === 7 && action === 'next' && type === 'step:after') {
        dispatch(endTour());
        setTimeout(() => {
          dispatch(setTourTrackTaskTime({ trackTaskTimeType: '' }));
          dispatch(setTourStepIndex({ stepIndex: 0 }));
          localStorage.removeItem('TourAutoStart');
        }, 500);
      }
      if (action === 'close' && type === 'step:after') {
        dispatch(endTour());
        setTimeout(() => {
          dispatch(setTourTrackTaskTime({ trackTaskTimeType: '' }));
          dispatch(setTourStepIndex({ stepIndex: 0 }));
          localStorage.removeItem('TourAutoStart');
        }, 500);
      }
      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        dispatch(endTour());
        setTimeout(() => {
          dispatch(setTourTrackTaskTime({ trackTaskTimeType: '' }));
          dispatch(setTourStepIndex({ stepIndex: 0 }));
          localStorage.removeItem('TourAutoStart');
        }, 500);
      }
    }

    if (
      trackTaskTimeType &&
      trackTaskTimeType === 'Yes, I need activity monitoring'
    ) {
      setContinuousEnableIndex([3, 4, 5]);
      if (action === 'close' && type === 'step:after') {
        dispatch(endTour());
        setTimeout(() => {
          dispatch(setTourTrackTaskTime({ trackTaskTimeType: '' }));
          dispatch(setTourStepIndex({ stepIndex: 0 }));
          localStorage.removeItem('TourAutoStart');
        }, 500);
      }
      if (index === 2 && action === 'next' && type === 'step:after') {
        dispatch(endTour());
        setTimeout(() => {
          dispatch(setTourTrackTaskTime({ trackTaskTimeType: '' }));
          dispatch(setTourStepIndex({ stepIndex: 0 }));
          localStorage.removeItem('TourAutoStart');
        }, 500);
      }
      if (index === 3 && action === 'next' && type === 'step:after') {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 4 }));
        dispatch(startTour());
      }
      if (index === 4 && action === 'next' && type === 'step:after') {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 5 }));
        dispatch(startTour());
      }
      if (index === 5 && action === 'next' && type === 'step:after') {
        dispatch(endTour());
        dispatch(setTourStepIndex({ stepIndex: 6 }));
        dispatch(startTour());
      }
      if (index === 6 && action === 'next' && type === 'step:after') {
        dispatch(endTour());
        setTimeout(() => {
          dispatch(setTourTrackTaskTime({ trackTaskTimeType: '' }));
          dispatch(setTourStepIndex({ stepIndex: 0 }));
          localStorage.removeItem('TourAutoStart');
        }, 500);
      }

      if (action === 'close' && type === 'step:after') {
        dispatch(endTour());
        setTimeout(() => {
          dispatch(setTourTrackTaskTime({ trackTaskTimeType: '' }));
          dispatch(setTourStepIndex({ stepIndex: 0 }));
          localStorage.removeItem('TourAutoStart');
        }, 500);
      }

      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        localStorage.removeItem('TourAutoStart');
        dispatch(endTour());
      }
    }
  };

  const TEMP_NON_MEMBER =
    trackTaskTimeType.toLowerCase() === 'time tracking'
      ? TIME_TRACKING_TOUR_STEPS
      : TASK_MANAGEMENT_TOUR_STEPS;
  const ALL_TOUR_LIST =
    trackTaskTimeType === 'Yes, I need activity monitoring'
      ? ACTIVITY_MONITIRING_TOUR_STEPS
      : TEMP_NON_MEMBER;
  const htmlPart = trackTaskTimeType ? (
    <JoyRide
      steps={ALL_TOUR_LIST}
      callback={handleJoyrideCallback}
      run={start}
      continuous={continuousEnableIndex.indexOf(stepIndex) > -1}
      showSkipButton
      stepIndex={stepIndex}
      locale={{
        last: 'Close',
        skip: 'Close',
        back: 'Previous',
      }}
      styles={{
        options: {
          zIndex: 10000,
          width: 400,
          primaryColor: '#0649b5',
          textColor: '#12263f',
          overlayColor: 'rgba(49, 58, 70, 0.7)',
        },
        tooltip: {
          borderRadius: 7,
          padding: 0,
        },
        tooltipContainer: {
          textAlign: 'left',
        },
        tooltipTitle: {
          fontSize: 15,
          margin: '5px 0 15px',
          padding: '20px 20px 0',
          display: 'flex',
          justifyContent: 'space-between',
        },
        tooltipContent: {
          fontSize: 14,
          padding: '20px 20px 5px',
        },
        tooltipFooter: {
          padding: '10px',
          marginTop: 0,
        },
        buttonSkip: {
          backgroundColor: '#edf2f9',
          borderRadius: '4px',
          color: '#283e59',
        },
      }}
    />
  ) : (
    <JoyRide
      steps={TOUR_STEPS_AO}
      callback={handleJoyrideCallback}
      run={start}
      continuous
      showSkipButton
      locale={{
        last: 'Close',
        skip: 'Close',
        back: 'Previous',
      }}
      styles={{
        options: {
          zIndex: 10000,
          width: 600,
          primaryColor: '#0649b5',
          textColor: '#12263f',
          overlayColor: 'rgba(49, 58, 70, 0.7)',
        },
        tooltip: {
          borderRadius: 7,
          padding: 0,
        },
        tooltipContainer: {
          textAlign: 'left',
        },
        tooltipTitle: {
          fontSize: 15,
          margin: '5px 0 15px',
          padding: '20px 20px 0',
          display: 'flex',
          justifyContent: 'space-between',
        },
        tooltipContent: {
          fontSize: 14,
          padding: '20px 20px 5px',
          borderTop: '1px solid #edf2f9',
          borderBottom: '1px solid #edf2f9',
        },
        tooltipFooter: {
          padding: '20px',
          marginTop: 0,
        },
        buttonSkip: {
          backgroundColor: '#edf2f9',
          borderRadius: '4px',
          color: '#283e59',
        },
      }}
    />
  );

  return (
    <div>
      {isSubmitting && <Loader contentAreaOnly />}
      {userDetails.role === 'member' ? (
        <JoyRide
          steps={TOUR_STEPS_TM}
          callback={handleJoyrideCallback}
          run={start}
          continuous
          showSkipButton
          locale={{
            last: 'Close',
            skip: 'Close',
            back: 'Previous',
          }}
          styles={{
            options: {
              zIndex: 10000,
              width: 600,
              primaryColor: '#0649b5',
              textColor: '#12263f',
              overlayColor: 'rgba(49, 58, 70, 0.7)',
            },
            tooltip: {
              borderRadius: 7,
              padding: 0,
            },
            tooltipContainer: {
              textAlign: 'left',
            },
            tooltipTitle: {
              fontSize: 15,
              margin: '5px 0 15px',
              padding: '20px 20px 0',
              display: 'flex',
              justifyContent: 'space-between',
            },
            tooltipContent: {
              fontSize: 14,
              padding: '20px 20px 5px',
              borderTop: '1px solid #edf2f9',
              borderBottom: '1px solid #edf2f9',
            },
            tooltipFooter: {
              padding: '20px',
              marginTop: 0,
            },
            buttonSkip: {
              backgroundColor: '#edf2f9',
              borderRadius: '4px',
              color: '#283e59',
            },
          }}
        />
      ) : (
        htmlPart
      )}
    </div>
  );
};
Tour.propTypes = {
  start: PropTypes.any,
};

export default Tour;
