import { useState } from 'react';
import { Modal, Dropdown, Form, Row, Col, Button } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { teamService, settingsService } from '../../services';

import {
  activateLoader,
  deactivateLoader,
  updateMemberData,
} from '../../actions/team';
import { TEAM, API_FAILURE_ERROR } from '../../constants';

const AddDailyLimitModal = ({ userId }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dailyLimit, setDailyLimit] = useState(0);

  const getDailyLimit = () => {
    settingsService
      .get_daily_limit({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        userId,
      })
      .then((response) => {
        if (response.data) {
          setDailyLimit(response.data.settings.dailyLimit);
        }
      });
  };

  const handleDailyLimitUpdate = (dailyLimitinHour) => {
    dispatch(activateLoader());
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      userId,
      dailyLimit: dailyLimitinHour,
    };
    teamService
      .update_user_daily_limit(params)
      .then(() => {
        teamService
          .get_team_members_data({
            teamOwnerId: localStorage.getItem('teamOwnerId'),
            summaryList: false,
            filter: JSON.stringify({
              memberId: userId,
            }),
          })
          .then((response) => {
            if (response.data) {
              const { members } = response.data;
              dispatch(updateMemberData(members[0]));
              dispatch(deactivateLoader());
              toast.success(TEAM.DAILY_LIMIT_UPDATE_SUCCESS);
              handleClose();
            }
          });
      })
      .catch(() => {
        dispatch(deactivateLoader());
        toast.error(API_FAILURE_ERROR);
      });
  };

  return (
    <>
      <Dropdown.Item
        onClick={() => {
          handleShow();
          getDailyLimit();
        }}
      >
        Add daily limit
      </Dropdown.Item>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="sm"
        centered
      >
        <Modal.Header>
          <Modal.Title className="m-0">Add daily limit</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Formik
          initialValues={{
            handleRateUpdate: '',
            dailyLimit,
          }}
          validationSchema={Yup.object({
            dailyLimit: Yup.number()
              .typeError(
                'Please enter day limit in hours. The field cannot be left blank.'
              )
              .positive('Must be a positive number.')
              .required('Please enter daily limit in hours')
              .min(1, 'Daily limit must be greater than or equal to 1')
              .max(24, 'Daily limit must be less than or equal to 24'),
          })}
          onSubmit={() => {
            const dailyLimitinHour = dailyLimit;
            handleDailyLimitUpdate(dailyLimitinHour);
          }}
        >
          {(formikprops) => (
            <Form
              onSubmit={formikprops.handleSubmit}
              className="needs-validation"
            >
              <section>
                <div>
                  <div className="modal-body pt-3 pb-2">
                    <div className="form-group">
                      <Row>
                        <Col className="col-auto pt-4">
                          <Form.Label className="form-control-label">
                            Daily limit in hours
                          </Form.Label>
                        </Col>
                        <Col className="px-2 col-auto">
                          <Field
                            name="dailyLimit"
                            id="dailyLimit"
                            style={{
                              maxWidth: '40px',
                              marginTop: '23px',
                              marginLeft: '18px',
                            }}
                            type="text"
                            value={dailyLimit}
                            onChange={(e) => {
                              formikprops.setFieldValue(
                                'dailyLimit',
                                e.target.value
                              );
                              setDailyLimit(e.target.value);
                            }}
                            className="form-control form-control-sm"
                            as={Form.Control}
                            isInvalid={
                              !!formikprops.touched.dailyLimit &&
                              !!formikprops.errors.dailyLimit
                            }
                            isValid={
                              !!formikprops.touched.dailyLimit &&
                              !formikprops.errors.dailyLimit
                            }
                          />
                        </Col>
                        <ErrorMessage
                          name="dailyLimit"
                          render={(msg) => (
                            <small className="text-danger">{msg}</small>
                          )}
                        />
                      </Row>
                    </div>
                  </div>
                  <div className="modal-footer d-flex justify-content-between pt-2 pb-2">
                    <button
                      type="button"
                      className="btn btn-link"
                      data-dismiss="modal"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <Button
                      type="submit"
                      className="btn btn-primary"
                      size="sm"
                      style={{ height: '26px' }}
                      name="btndailyLimit"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

AddDailyLimitModal.propTypes = {
  userId: PropTypes.string,
};

export default AddDailyLimitModal;
