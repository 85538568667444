import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import ChangeTeam from './ChangeTeam.jsx';

const CurrentTeam = ({
  defaultTeam,
  handleUpdateDefault,
  propChecked,
  teamList,
  handleTeamSelection,
}) => (
  <Col md={6} className="col-12">
    <Card>
      <Card.Body>
        <h6 className="text-uppercase text-muted mb-2">Current team</h6>
        <Row className="align-items-center">
          <span className="h2 mb-2 col-md-12">{defaultTeam}</span>
          <Col>
            <div className="form-check">
              <label className="form-check-label" htmlFor="customCheck2">
                Make this my default team
                <input
                  className="form-check-input form-check-border"
                  name="customCheck2"
                  type="checkbox"
                  defaultChecked={propChecked}
                  onChange={handleUpdateDefault}
                />
              </label>
            </div>
          </Col>

          <ChangeTeam
            handleTeamSelection={handleTeamSelection}
            teamList={teamList}
          />
        </Row>
      </Card.Body>
    </Card>
  </Col>
);
CurrentTeam.propTypes = {
  defaultTeam: PropTypes.string,
  handleUpdateDefault: PropTypes.func,
  propChecked: PropTypes.bool,
  teamList: PropTypes.any,
  handleTeamSelection: PropTypes.func,
};
export default CurrentTeam;
