import React, { useState } from 'react';
import { Button, Modal, Row, Col, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import MonthYearPicker from '../common/MonthYearPicker';
import { getStartAndEndDates } from '../../helpers';
import ViewTimecardPDF from './ViewTimecardPDF';
import ApprovalTimecardExcel from './ApprovalTimecardExcel';

const MonthlyReportModal = ({
  showMonthlyReportModal,
  setShowMonthlyReportModal,
  handleMonthlyReport,
  monthlyReportData,
  selectedMonthForReport,
}) => {
  const handleClose = () => setShowMonthlyReportModal(false);
  const initialDates = getStartAndEndDates(new Date());
  const [fromDate, setFromDate] = useState(initialDates.startDate);
  const [toDate, setToDate] = useState(initialDates.endDate);
  const handleDateChange = ({ startDate, endDate }) => {
    setFromDate(startDate);
    setToDate(endDate);
  };
  return (
    <Modal show={showMonthlyReportModal} centered onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="m-0">Generate Monthly Report</Modal.Title>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <p>Select the required month and generate the report.</p>
        <Row className="mb-4">
          <Col md={5}>
            <MonthYearPicker onDateChange={handleDateChange} />
          </Col>
          <Col className="col-auto ps-0">
            <Button
              variant="primary"
              onClick={() => {
                handleMonthlyReport({
                  fromDate,
                  toDate,
                });
              }}
            >
              Generate
            </Button>
          </Col>
        </Row>
        {monthlyReportData && monthlyReportData.length > 0 && (
          <div>
            <Alert variant="light" className="mb-0">
              <p>
                The report for{' '}
                {moment(selectedMonthForReport).format('MMM, YYYY')} is ready.
                Download it using the link below.
              </p>
              <Button
                variant="link"
                size="sm"
                onClick={() =>
                  ViewTimecardPDF(monthlyReportData, fromDate, toDate)
                }
                className="me-2 ps-0"
              >
                <i className="mdi mdi-file-document" /> Download PDF
              </Button>
              <Button
                variant="link"
                size="sm"
                onClick={() => ApprovalTimecardExcel(monthlyReportData)}
              >
                <i className="mdi mdi-file-document" /> Download Excel
              </Button>
            </Alert>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

MonthlyReportModal.propTypes = {
  showMonthlyReportModal: PropTypes.bool,
  setShowMonthlyReportModal: PropTypes.func,
  handleMonthlyReport: PropTypes.func,
  monthlyReportData: PropTypes.array,
  selectedMonthForReport: PropTypes.string,
};
export default MonthlyReportModal;
