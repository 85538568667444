import { BASE_API_URL, TOUR_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

const options = AppService.options();

export const TourService = {
  submit_tour: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TOUR_URLS.LIKEDISLIKE,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
};
