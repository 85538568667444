import React, { Component } from 'react';
import { Button, Row, Col, Form, Tabs, Tab } from 'react-bootstrap';
import { Formik } from 'formik';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { toast } from 'react-toastify';
import Layout from './DashboardLayout';
import Loading from '../components/Loader';
import { TeamMembersDropdown } from '../components/common';
import { teamService, timesheetService } from '../services';
import {
  ApproveTimesheetContent,
  ApproveTimeCardContent,
} from '../components/timesheet';
import { getUserData } from '../helpers';
import { MEMBER_VALIDATION_LIMIT } from '../constants';
import { trackJune } from '../utilities/analytics';

class TimesheetApproval extends Component {
  state = {
    isLoading: false,
    isUpdatingTeamsList: false,
    teamMembersList: [],
    timesheetDetails: [],
    timecardDetails: [],
    startDate: moment().startOf('week'),
    endDate: moment().endOf('week'),
    monthlyReportData: [],
    selectedMonthForReport: new Date(),
    selectedMember: 'all',
  };

  pageHeading = {
    preTitle: 'Timesheet',
    title: 'Approval',
  };

  componentDidMount() {
    const teamStrength = getUserData('maxMembersAllowed') || 0;
    this.getTeamsData();
    if (teamStrength <= MEMBER_VALIDATION_LIMIT) {
      this.getTimesheetApprovalData();
    }
    // track june
    trackJune(null, `${this.pageHeading.preTitle} ${this.pageHeading.title}`);
  }

  getTimesheetApprovalData = (formData) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
      selectedMember: formData?.teamMember ? formData.teamMember : 'all',
    }));
    const { startDate, endDate } = this.state;
    const dataFromDate = moment(startDate).format('YYYY-MM-DD');
    const dataToDate = moment(endDate).format('YYYY-MM-DD');
    const teamOwnerId = localStorage.getItem('teamOwnerId');
    const params = {
      teamOwnerId,
      teamMember: formData?.teamMember ? [formData.teamMember].flat() : 'all',
      fromDate: dataFromDate.concat(' 00:00:00'),
      toDate: dataToDate.concat(' 23:59:59'),
    };
    if (
      (formData && formData.teamMember === 'all') ||
      params.teamMember === 'all'
    ) {
      delete params.teamMember;
    }

    Promise.all([
      timesheetService.approvals_timesheet({
        ...params,
        groupBy: 'project',
      }),
      timesheetService.approvals_timesheet({
        ...params,
        groupBy: 'date',
      }),
    ])
      .then((results) => {
        const [timesheetView, timecardView] = results;
        this.setState((state) => ({
          ...state,
          isLoading: false,
          timesheetDetails: [...timesheetView.data.timesheetDetails],
          timecardDetails: [...timecardView.data.timesheetDetails],
        }));
        // track june
        trackJune('timesheet approval (approve)');
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  handleDateSelect = (event, picker) => {
    picker.element.val(
      `${moment(picker.startDate).day(0).format('MMM D, YYYY')} - ${moment(
        picker.endDate
      )
        .day(6)
        .format('MMM D, YYYY')}`
    );
    this.setState((state) => ({
      ...state,
      startDate: moment(picker.startDate).day(0),
      endDate: moment(picker.endDate).day(6),
    }));
  };

  getTeamsData = () => {
    this.setState((state) => ({
      ...state,
      isUpdatingTeamsList: true,
    }));
    teamService
      .get_team_members_data({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        summaryList: true,
      })
      .then((response) => {
        const { members } = response.data;
        this.setState((state) => ({
          ...state,
          teamMembersList: [...members],
          isUpdatingTeamsList: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isUpdatingTeamsList: false,
        }));
      });
  };

  handleWeeklyStatusUpdate = (data) => {
    const teamOwnerId = localStorage.getItem('teamOwnerId');
    const teamStrength = getUserData('maxMembersAllowed') || 0;
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const params = {
      teamOwnerId,
      teamMemberId: data.teamMember,
      status: data.status,
      note: data.note,
    };
    if (data.weeklySubmissionId) {
      params.weeklySubmissionId = data.weeklySubmissionId;
    }
    // Not submitted sessions now can be approved with start and end date
    if (data.weekStartDate && data.weekEndDate) {
      params.weekStartDate = moment
        .utc(data.weekStartDate)
        .format('YYYY-MM-DD')
        .concat(' 00:00:00');
      params.weekEndDate = moment
        .utc(data.weekEndDate)
        .format('YYYY-MM-DD')
        .concat(' 23:59:59');
    }

    timesheetService
      .status_update(params)
      .then((result) => {
        if (result.status) {
          const tmDetails = { teamMember: data.teamMember };
          // eslint-disable-next-line no-unused-expressions
          teamStrength > MEMBER_VALIDATION_LIMIT
            ? this.getTimesheetApprovalData(tmDetails)
            : this.getTimesheetApprovalData();
        }
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  handleMonthlyReport = (data) => {
    if (data.fromDate && data.toDate) {
      this.setState((state) => ({
        ...state,
        isLoading: true,
      }));
      const { selectedMember } = this.state;
      const params = {
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        fromDate: moment(data.fromDate)
          .format('YYYY-MM-DD')
          .concat(' 00:00:00'),
        toDate: moment(data.toDate).format('YYYY-MM-DD').concat(' 23:59:59'),
        teamMember: selectedMember,
      };
      if (selectedMember && selectedMember === 'all') {
        delete params.teamMember;
      }
      timesheetService
        .timecard_report(params)
        .then((response) => {
          this.setState((state) => ({
            ...state,
            monthlyReportData: response.data.timesheetDetails,
            selectedMonthForReport: data.fromDate,
          }));
          if (response.data.timesheetDetails.length === 0) {
            toast.error('No data available for selected month');
          }
        })
        .finally(() => {
          this.setState((state) => ({
            ...state,
            isLoading: false,
          }));
        });
    }
  };

  render() {
    const {
      isLoading,
      teamMembersList,
      isUpdatingTeamsList,
      timesheetDetails,
      timecardDetails,
      startDate,
      endDate,
      monthlyReportData,
      selectedMonthForReport,
      selectedMember,
    } = this.state;

    const teamStrength = getUserData('maxMembersAllowed') || 0;
    // Validate form only if not a member and for accounts which has large size than specified limit
    const isValidateForm = teamStrength > MEMBER_VALIDATION_LIMIT;
    return (
      <Layout pageHeading={this.pageHeading}>
        <Formik
          initialValues={{
            teamMember: isValidateForm ? '' : 'all',
            fromDate: startDate,
            toDate: endDate,
          }}
          onSubmit={(values) => {
            this.getTimesheetApprovalData(values);
          }}
        >
          {(formikprops) => (
            <Form onSubmit={formikprops.handleSubmit}>
              <Row>
                <Col md={4} lg={3}>
                  {isValidateForm ? (
                    <TeamMembersDropdown
                      teamMembersList={teamMembersList}
                      isUpdatingTeamsList={isUpdatingTeamsList}
                      isAllOptionRequired={false}
                      isValidate
                      isRequired
                      defaultPlaceholder="Select a Team Member"
                    />
                  ) : (
                    <TeamMembersDropdown
                      teamMembersList={teamMembersList}
                      isUpdatingTeamsList={isUpdatingTeamsList}
                      isAllOptionRequired
                    />
                  )}
                </Col>
                <Col md={4} lg={3}>
                  <Form.Group>
                    <Form.Label>Date range</Form.Label>
                    <div
                      id="week-picker-wrapper"
                      style={{ position: 'relative' }}
                    >
                      <DateRangePicker
                        onEvent={this.handleDateSelect}
                        initialSettings={{
                          autoUpdateInput: false,
                          singleDatePicker: true,
                          startDate: moment().toDate(),
                          locale: {
                            format: 'MMM D, YYYY',
                          },
                          alwaysShowCalendars: true,
                          autoApply: true,
                          parentEl: '#week-picker-wrapper',
                        }}
                      >
                        <input
                          type="text"
                          className="form-control icon-calendar week-selector"
                          placeholder={`${moment()
                            .day(0)
                            .format('MMM D, YYYY')} - ${moment()
                            .day(6)
                            .format('MMM D, YYYY')}`}
                        />
                      </DateRangePicker>
                    </div>
                  </Form.Group>
                </Col>
                <Col md={4} lg={3}>
                  <Form.Group controlId="generateData">
                    <Form.Label>&nbsp;</Form.Label>
                    <div>
                      {isValidateForm ? (
                        <Button
                          variant="primary"
                          type="Submit"
                          disabled={!(formikprops.isValid && formikprops.dirty)}
                        >
                          Generate
                        </Button>
                      ) : (
                        <Button variant="primary" type="Submit">
                          Generate
                        </Button>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>

        <Tabs
          defaultActiveKey="approve-timesheet"
          id="approve-tabs"
          className="mb-5"
        >
          <Tab eventKey="approve-timesheet" title="Timesheet">
            <ApproveTimesheetContent
              timesheetDetails={timesheetDetails}
              isLoading={isLoading}
              statusUpdate={this.handleWeeklyStatusUpdate}
            />
          </Tab>
          <Tab eventKey="approve-timecard" title="Timecard">
            <ApproveTimeCardContent
              timecardDetails={timecardDetails}
              isLoading={isLoading}
              statusUpdate={this.handleWeeklyStatusUpdate}
              handleMonthlyReport={this.handleMonthlyReport}
              monthlyReportData={monthlyReportData}
              selectedMonthForReport={selectedMonthForReport}
              selectedMember={selectedMember}
            />
          </Tab>
        </Tabs>

        {/* Loader */}
        {isLoading && <Loading contentAreaOnly />}
      </Layout>
    );
  }
}

export default TimesheetApproval;
