import { useSelector, useDispatch } from 'react-redux';
import { setFreeTrialStatusFalse } from '../actions/user';

function FreeTrialHeader() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userReducer);
  const { remainingTrialPeriod } = userDetails.subscription;

  return (
    <div className="topmost bg-dark p-2 fixed-top text-center text-white">
      <span className="text-danger">{Math.round(remainingTrialPeriod)}</span>{' '}
      days left of your free trial.{' '}
      <a href="/settings/billing/" className="btn btn-sm btn-primary">
        Upgrade plan
      </a>
      <a
        href="void:javascript(0);"
        onClick={() => {
          dispatch(setFreeTrialStatusFalse());
        }}
        className="position-absolute text-white"
        style={{ top: '7px', right: '8px' }}
      >
        <i className="mdi mdi-window-close mdi mdi-window-close" />
      </a>
    </div>
  );
}

export default FreeTrialHeader;
