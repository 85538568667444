import { Component } from 'react';
import Layout from './DashboardLayout';
import { ArchivedProject } from '../components/project';

class Project extends Component {
  pageHeading = {
    preTitle: 'Projects',
    title: 'Archived Projects',
  };

  componentDidMount() {}

  render() {
    return (
      <Layout pageHeading={this.pageHeading}>
        <ArchivedProject />
      </Layout>
    );
  }
}

export default Project;
