import {
  SETTINGS_BILLING,
  LOCAL_STORAGE_USER_DATA,
  SUPPORT_CONTACT,
} from '../../constants';

const BillingModalHelper = () => {
  const userdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  const userPlan =
    userdata &&
    userdata.subscriptionDetails.currentPlan !== null &&
    userdata.subscriptionDetails.currentPlan.toLowerCase();

  const billingModalProps = {
    showClose: true,
    title: '',
    actionText: '',
    actionVariant: 'primary',
    content: '',
    secondaryActionText: '',
    currentPlan: '',
  };
  billingModalProps.currentPlan = userPlan;
  if (userdata.paymentDue === 1) {
    billingModalProps.title = 'Payment Due';
    billingModalProps.actionText = 'Update my credit card';
    billingModalProps.content = SETTINGS_BILLING.PAYMENT_DUE;
    billingModalProps.type = 'due';
  } else if (userdata.userStatus === 'cancel') {
    billingModalProps.title =
      userPlan === 'trial'
        ? 'Your free trial has expired'
        : 'Your account is cancelled';
    billingModalProps.actionText =
      userPlan === 'trial' ? 'Upgrade my account' : 'Upgrade my plan';

    if (userPlan === 'trial') {
      billingModalProps.type = 'trial';
      billingModalProps.content = SETTINGS_BILLING.TRIAL_MODAL;
      if (userdata.isMember === 1) {
        billingModalProps.type = 'ismember';

        billingModalProps.content = `${SETTINGS_BILLING.TRIAL_MODAL}\n\n${SETTINGS_BILLING.ISMEMBER_MODAL}`;
        billingModalProps.secondaryActionText = 'Continue as a member';
      }
    } else {
      billingModalProps.type = 'paid';
      billingModalProps.content = SETTINGS_BILLING.PAID_MODAL;
    }
  }

  billingModalProps.content =
    billingModalProps.title !== ''
      ? `${billingModalProps.content}\n\n${SUPPORT_CONTACT}`
      : '';
  return billingModalProps;
};
export default BillingModalHelper;
