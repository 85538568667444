import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import Layout from './DashboardLayout';
import { TimesheetTable } from '../components/timesheet';
import {
  teamService,
  projectService,
  timesheetService,
  tasksService,
} from '../services';
import Loading from '../components/Loader';
import { fetchOnBoardProgress } from '../actions/user';
import {
  TeamMembersDropdown,
  ProjectListDropdown,
  DateRangePickerField,
  WorkSessionTypeDropdown,
} from '../components/common';
import {
  DATE_RANGE_OPTIONS,
  TIMESHEET_DATE_RANGE,
  MEMBER_VALIDATION_LIMIT,
} from '../constants';
import { getUserData } from '../helpers';
import { trackJune } from '../utilities/analytics';

class SummarizedTimesheet extends Component {
  state = {
    isLoading: true,
    teamMembersList: [],
    teamGroupList: [],
    projectsList: [],
    tasksList: [],
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    timesheet: [],
    timesheetMetadata: {},
    isLoadingTimesheet: true,
    isFetchingTimesheetData: false,
    isSummarizedReport: true,
    isUpdatingTeamsList: false,
    isUpdatingProjectsList: false,
    isUpdatingTasksList: false,
    isSendingEmail: false,
    numberOfDaysSelected: 1,
    daysLimit: 90,
  };

  pageHeading = {
    preTitle: 'Timesheet',
    title: 'Summarized Timesheet',
  };

  sTimesheetFormRef = React.createRef();

  componentDidMount() {
    const { startDate, endDate } = this.state;
    const { userRole } = this.props;
    const memberId = getUserData('_id');
    const teamStrength = getUserData('maxMembersAllowed') || 0;
    teamService
      .get_team_members_and_projects({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
      })
      .then((result) => {
        const { teamMembers, projects } = result.data;
        this.setState((state) => ({
          ...state,
          isLoading: false,
          projectsList: [...projects],
          teamMembersList: [...teamMembers],
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
    this.getTeamGroupList();
    this.getTaskData({
      teamOwnerId: localStorage.getItem('teamOwnerId'),
    });
    if (teamStrength <= MEMBER_VALIDATION_LIMIT) {
      this.handleGenerateData({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        fromDate: startDate,
        toDate: endDate,
        project: 'all',
        task: 'all',
        teamMember: userRole !== 'member' ? 'all' : memberId,
        workSessionType: 'all',
      });
    }
  }

  handleDateSelect = (event, picker) => {
    this.setState((state) => ({
      ...state,
      startDate: moment(picker.startDate).format('YYYY-MM-DD'),
      endDate: moment(picker.endDate).format('YYYY-MM-DD'),
    }));
  };

  handleDateRangeValidation = (start, end) => {
    const { daysLimit } = this.state;
    const daysSelected = Math.abs(moment(start).diff(moment(end), 'd'));
    if (daysSelected > daysLimit) {
      toast.error(
        `${TIMESHEET_DATE_RANGE.LIMIT} ${TIMESHEET_DATE_RANGE.ERROR}`
      );
    }
    this.setState((state) => ({
      ...state,
      numberOfDaysSelected: daysSelected,
    }));
  };

  getProjectData = (data) => {
    this.setState((state) => ({
      ...state,
      isUpdatingProjectsList: true,
    }));

    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      summaryList: true,
    };
    if (data && data.members) {
      params.filter = JSON.stringify({
        teamMembers: [...data.members],
      });
    }
    projectService
      .get_projects_data(params)
      .then((response) => {
        const {
          data: { projects },
        } = response;
        this.setState((state) => ({
          ...state,
          projectsList: [...projects],
          isUpdatingProjectsList: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isUpdatingProjectsList: false,
        }));
      });
  };

  getTeamGroupList = () => {
    teamService
      .get_team_group_list({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
      })
      .then((response) => {
        if (response.data) {
          this.setState(() => ({
            teamGroupList: response.data,
          }));
        }
      });
  };

  getTaskData = (data) => {
    this.setState((state) => ({
      ...state,
      isUpdatingTasksList: true,
    }));
    tasksService
      .get_tasks_list(data)
      .then((result) => {
        const { tasks } = result.data;
        this.setState((state) => ({
          ...state,
          isLoading: false,
          tasksList: [...tasks],
          isUpdatingTasksList: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isUpdatingTasksList: false,
        }));
      });
  };

  // Update project list on changing team members selection
  handleTeamMemberChange = () => {
    const { teamGroupList } = this.state;
    const { teamMember, team, project } = this.sTimesheetFormRef.current.values;
    let selectedGroupMembers = [];
    let allSelectedMembers = [];
    // if team is selected create array of selected group members
    if (team !== 'all' && team !== '') {
      const selectedGroup = teamGroupList.find((tGroup) => tGroup._id === team);
      selectedGroupMembers =
        selectedGroup.members && selectedGroup.members.length > 0
          ? selectedGroup.members.map((sMember) => sMember._id)
          : [];
    }
    if (teamMember !== 'all' && team !== 'all') {
      if (selectedGroupMembers.includes(teamMember)) {
        allSelectedMembers = [...selectedGroupMembers];
      } else {
        allSelectedMembers = [teamMember, ...selectedGroupMembers];
      }
    } else if (teamMember !== 'all') {
      allSelectedMembers = [teamMember];
    } else if (team !== 'all') {
      allSelectedMembers = [...selectedGroupMembers];
    }

    // Update projects list based on selected members
    this.getProjectData({ members: [...allSelectedMembers] });

    const taskParams = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      members: [...allSelectedMembers],
      projects: [project],
    };
    if (teamMember === 'all' && team === 'all') {
      delete taskParams.members;
    }
    if (project === 'all') {
      delete taskParams.projects;
    }
    this.getTaskData(taskParams);
  };

  handleProjectChange = (event) => {
    const { teamGroupList } = this.state;
    const { teamMember, team, project } = this.sTimesheetFormRef.current.values;
    let selectedGroupMembers = [];
    let allSelectedMembers = [];

    // if team is selected create array of selected group members
    if (team !== 'all' && team !== '') {
      const selectedGroup = teamGroupList.find((tGroup) => tGroup._id === team);
      selectedGroupMembers =
        selectedGroup.members && selectedGroup.members.length > 0
          ? selectedGroup.members.map((sMember) => sMember._id)
          : [];
    }
    if (teamMember !== 'all' && team !== 'all') {
      if (selectedGroupMembers.includes(teamMember)) {
        allSelectedMembers = [...selectedGroupMembers];
      } else {
        allSelectedMembers = [teamMember, ...selectedGroupMembers];
      }
    } else if (teamMember !== 'all') {
      allSelectedMembers = [teamMember];
    } else if (team !== 'all') {
      allSelectedMembers = [...selectedGroupMembers];
    }

    const taskParams = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      projects: [event.target.value],
      members: [...allSelectedMembers],
    };
    if (project === 'all') {
      delete taskParams.projects;
    }
    if (teamMember === 'all' && team === 'all') {
      delete taskParams.members;
    }
    this.getTaskData(taskParams);
  };

  handleGenerateData = (formdata) => {
    this.setState((state) => ({
      ...state,
      isFetchingTimesheetData: true,
    }));
    const { startDate, endDate } = this.state;
    const params = {
      teamOwnerId: formdata.teamOwnerId,
      team: [formdata.team].flat(),
      teamMember: [formdata.teamMember].flat(),
      project: [formdata.project].flat(),
      task: [formdata.task].flat(),
      workSessionType: formdata.workSessionType,
      fromDate: startDate.concat(' 00:00:00'),
      toDate: endDate.concat(' 23:59:59'),
      summarizedTimesheet: true,
    };
    if (formdata.teamMember === 'all' || formdata.teamMember === '') {
      delete params.teamMember;
    }
    if (formdata.team === 'all') {
      delete params.team;
    }
    if (formdata.project === 'all') {
      delete params.project;
    }
    if (formdata.task === 'all') {
      delete params.task;
    }
    if (formdata.workSessionType === 'all') {
      delete params.workSessionType;
    }

    // Store form data on formParams for sending email report
    this.setState(() => ({
      formParams: { ...params },
    }));
    const { getOnBoardingProgress } = this.props;
    timesheetService
      .get_timesheet_data(params)
      .then((response) => {
        trackJune('view timesheet report (summarized)');
        const subscriptionDetails = getUserData('subscriptionDetails');
        if (subscriptionDetails.currentPlan === 'trial') {
          getOnBoardingProgress();
        }
        const {
          data: { metadata, timesheetDetails },
        } = response;

        this.setState((state) => ({
          ...state,
          isFetchingTimesheetData: false,
          timesheet: timesheetDetails,
          timesheetMetadata: metadata,
          isLoadingTimesheet: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isFetchingTimesheetData: false,
          isLoadingTimesheet: false,
        }));
      });
  };

  handleEmailReport = () => {
    const { formParams } = this.state;
    this.setState((state) => ({
      ...state,
      isSendingEmail: true,
    }));
    timesheetService
      .email_timesheet_report({ ...formParams })
      .then((response) => {
        toast.success('Timesheet has been emailed successfully');
        this.setState((state) => ({
          ...state,
          isSendingEmail: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isSendingEmail: false,
        }));
      });
  };

  render() {
    const {
      teamMembersList,
      projectsList,
      tasksList,
      startDate,
      endDate,
      timesheet,
      timesheetMetadata,
      isLoadingTimesheet,
      isFetchingTimesheetData,
      isSummarizedReport,
      isLoading,
      isUpdatingTeamsList,
      isUpdatingProjectsList,
      isUpdatingTasksList,
      isSendingEmail,
      numberOfDaysSelected,
      daysLimit,
      teamGroupList,
    } = this.state;
    const { userRole } = this.props;

    // Team owner ID
    const teamOwnerId = localStorage.getItem('teamOwnerId');
    const { isMobileTracking } = getUserData('defaultTeam');
    const teamStrength = getUserData('maxMembersAllowed') || 0;
    // Validate form only if not a member and for accounts which has large size than specified limit
    const isValidateForm =
      userRole !== 'member' && teamStrength > MEMBER_VALIDATION_LIMIT;
    return (
      <Layout pageHeading={this.pageHeading}>
        <Formik
          initialValues={{
            teamOwnerId,
            teamMember: isValidateForm ? '' : 'all',
            team: 'all',
            project: 'all',
            task: 'all',
            fromDate: startDate,
            toDate: endDate,
          }}
          onSubmit={(values) => {
            this.handleGenerateData(values);
          }}
          innerRef={this.sTimesheetFormRef}
        >
          {(formikprops) => (
            <Form onSubmit={formikprops.handleSubmit}>
              <Row>
                <Col md={6} lg={3}>
                  {isValidateForm ? (
                    <TeamMembersDropdown
                      teamMembersList={teamMembersList}
                      handleChange={formikprops.handleChange}
                      handleTeamMemberChange={this.handleTeamMemberChange}
                      isDependentFiled
                      isUpdatingTeamsList={isUpdatingTeamsList}
                      isAllOptionRequired={false}
                      isValidate
                      isRequired
                    />
                  ) : (
                    <TeamMembersDropdown
                      teamMembersList={teamMembersList}
                      handleChange={formikprops.handleChange}
                      handleTeamMemberChange={this.handleTeamMemberChange}
                      isDependentFiled
                      isUpdatingTeamsList={isUpdatingTeamsList}
                    />
                  )}
                </Col>
                <Col md={6} lg={3}>
                  <Form.Group controlId="team">
                    <Form.Label>Team Group</Form.Label>
                    <Field
                      className="form-select"
                      as="select"
                      name="team"
                      onChange={(e) => {
                        formikprops.handleChange(e);
                        setTimeout(() => {
                          this.handleTeamMemberChange();
                        }, 0);
                      }}
                    >
                      <option value="all">All</option>
                      {teamGroupList &&
                        teamGroupList.length > 0 &&
                        teamGroupList.map((teamGroup, index) => (
                          <option
                            key={`teamGroup${index}`}
                            value={teamGroup._id}
                          >
                            {teamGroup.name}
                          </option>
                        ))}
                    </Field>
                  </Form.Group>
                </Col>
                <Col md={6} lg={3}>
                  <ProjectListDropdown
                    projectsList={projectsList}
                    isUpdatingProjectsList={isUpdatingProjectsList}
                    handleChange={formikprops.handleChange}
                    handleProjectChange={this.handleProjectChange}
                    isDependedField
                  />
                </Col>
                <Col md={6} lg={3}>
                  <Form.Group controlId="task">
                    <Form.Label>Task</Form.Label>
                    <Field
                      className="form-select"
                      as="select"
                      name="task"
                      disabled={isUpdatingTasksList || !tasksList.length > 0}
                    >
                      <option value="all">All</option>
                      {tasksList &&
                        tasksList.length > 0 &&
                        tasksList.map((item, index) => (
                          <option key={`${index}-${item._id}`} value={item._id}>
                            {item.task}
                          </option>
                        ))}
                    </Field>
                  </Form.Group>
                </Col>
                {isMobileTracking && (
                  <Col md={6} lg={3}>
                    <WorkSessionTypeDropdown />
                  </Col>
                )}
                <Col md={6} lg={3}>
                  <DateRangePickerField
                    handleDateSelect={this.handleDateSelect}
                    showRangeOptions
                    preDefinedRanges={DATE_RANGE_OPTIONS}
                    handleDateRangeValidation={this.handleDateRangeValidation}
                    isValidate
                    numberOfDaysSelected={numberOfDaysSelected}
                    daysLimit={daysLimit}
                  />
                </Col>
                <Col md={6} lg={3}>
                  <Form.Group>
                    <Form.Label>&nbsp;</Form.Label>
                    <div>
                      {isValidateForm ? (
                        <Button
                          variant="primary"
                          type="Submit"
                          disabled={
                            isUpdatingTeamsList ||
                            isUpdatingProjectsList ||
                            numberOfDaysSelected > daysLimit ||
                            !(formikprops.isValid && formikprops.dirty) ||
                            (formikprops.values.teamMember === '' &&
                              formikprops.values.project === 'all')
                          }
                        >
                          Generate
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          type="Submit"
                          disabled={
                            isUpdatingProjectsList ||
                            numberOfDaysSelected > daysLimit
                          }
                        >
                          Generate
                        </Button>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        {!isLoadingTimesheet && (
          <TimesheetTable
            timesheetMetadata={timesheetMetadata}
            timesheet={timesheet}
            startDate={startDate}
            endDate={endDate}
            isSummarizedReport={isSummarizedReport}
            handleEmailReport={this.handleEmailReport}
            userRole={userRole}
            isMobileTracking={isMobileTracking}
            isSendingEmail={isSendingEmail}
          />
        )}
        {/* Loader */}
        {(isLoading || isFetchingTimesheetData) && <Loading contentAreaOnly />}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { userReducer } = state;
  return {
    userRole: userReducer.role,
  };
};

const mapDispatchToProps = () => ({
  getOnBoardingProgress: fetchOnBoardProgress,
});

SummarizedTimesheet.propTypes = {
  userRole: PropTypes.string,
  getOnBoardingProgress: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(SummarizedTimesheet);
