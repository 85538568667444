import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Card, Row, Col, ListGroup } from 'react-bootstrap';

const PdfList = ({ screenshotPdfData }) => {
  const pdfs = screenshotPdfData;

  const getNameFromUrl = (url) => {
    const splitUrl = url.pdfFileName.split('/');
    return splitUrl[3].substr(0, splitUrl[3].indexOf('?'));
  };

  return (
    <Card className="screenshot-list p-1">
      <Card.Body>
        <ListGroup>
          <ListGroup.Item className="border-2">
            {pdfs.map((pdf) => (
              <Row key={pdf.pdfFileName} className="pb-3">
                <Col md={1} key={pdf.pdfFileName} className="align-to-right">
                  <a
                    href={pdf.pdfFileName}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    <i className="mdi mdi-download" />
                  </a>
                </Col>
                <Col md={11}>
                  <h4 className="mb-1">{getNameFromUrl(pdf)}</h4>
                  <p className="text-muted">
                    Auto deletion date:
                    {moment(pdf.date)
                      .add(pdf.expires, 'hours')
                      .format('MMM DD, YYYY h:mm:ss A')}
                  </p>
                </Col>
              </Row>
            ))}
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

PdfList.propTypes = { screenshotPdfData: PropTypes.array };

export default PdfList;
