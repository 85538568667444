import { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { msToHMS } from '../../../helpers';

const ProjectCard = ({ memberProjectData, isLoading }) => {
  const [searchWord, setSearchWord] = useState('');
  const tableColumns = [
    {
      name: 'PROJECT',
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => row.project,
      grow: 4,
    },
    {
      name: 'TOTAL HOURS (last 7 days)',
      selector: (row) => row.hours,
      sortable: true,
      cell: (row) => row.total_hours,
    },

    {
      name: 'TOTAL TASKS',
      selector: (row) => row.totalTasks,
      sortable: true,
      cell: (row) => row.total_tasks,
    },
    {
      name: 'TASKS',
      selector: (row) => row.totalTasks,
      sortable: true,
      cell: (row) => row.tasks,
    },
  ];

  const tableData = [];

  memberProjectData.forEach(function (project, index) {
    const { tasks } = project;
    let completeTasks = 0;
    let totalTasks = '';
    if (tasks && tasks.length >= 1) {
      completeTasks =
        tasks.filter((task) => task.status === 'complete').length >= 1
          ? tasks.filter((task) => task.status === 'complete')[0].tasks
          : 0;
      totalTasks = tasks.reduce((total, task) => total + task.tasks, 0);
    }
    tableData.push({
      id: `prj${index}`,
      project: (
        <Link to={`/projects/${project._id}/task-board`}>
          <h4 className="fw-normal lh-base mb-0">{project.title}</h4>
        </Link>
      ),
      total_hours:
        project.weekWorkSessionSeconds && project.weekWorkSessionSeconds > 0 ? (
          msToHMS(project.weekWorkSessionSeconds * 1000)
        ) : (
          <span className="badge bg-secondary-soft">No hours logged</span>
        ),
      total_tasks: totalTasks,
      tasks: (
        <>
          {tasks && tasks.length >= 1 && (
            <Row className="align-items-center no-gutters progress-info w-100 m-0">
              <Col className="col-auto p-0">
                {completeTasks}/{totalTasks}
              </Col>
              <Col md={6} xs={12}>
                <div className="progress progress-sm">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${(completeTasks / totalTasks) * 100}%`,
                    }}
                    aria-valuenow={{
                      width: `${(completeTasks / totalTasks) * 100}%`,
                    }}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </Col>
            </Row>
          )}
        </>
      ),
      name: project.title,
      totalTasks,
      hours:
        project.weekWorkSessionSeconds && project.weekWorkSessionSeconds > 0
          ? Math.round(project.weekWorkSessionSeconds * 100) / 100
          : 0,
    });
  });

  function search(rows) {
    return rows.filter(
      (row) => row.name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1
    );
  }

  return (
    <Card>
      <Card.Body className="p-0">
        <div className="col-sm-12 text-muted" style={{ padding: '0 15px' }}>
          <small className="text-uppercase" style={{ fontSize: '10px' }}>
            Search
          </small>
          <label htmlFor="search" className="m-3 pb-2">
            <input
              id="search"
              type="text"
              name="search"
              value={searchWord}
              className="form-control form-control-sm"
              onChange={(e) => setSearchWord(e.target.value)}
            />{' '}
          </label>
        </div>
        <DataTable
          columns={tableColumns}
          data={search(tableData)}
          noDataComponent={isLoading ? '' : 'No Active Projects'}
          highlightOnHover
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 15, 25, 50]}
          paginationComponentOptions={{
            rowsPerPageText: 'Show Results',
            rangeSeparatorText: 'out of',
          }}
          progressPending={isLoading}
          progressComponent={
            !isLoading && (
              <div className="p-4">
                <Spinner
                  animation="border"
                  className="text-primary"
                  size="md"
                />
              </div>
            )
          }
        />
      </Card.Body>
    </Card>
  );
};

ProjectCard.propTypes = {
  memberProjectData: PropTypes.array,
  isLoading: PropTypes.bool,
};
export default ProjectCard;
