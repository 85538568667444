import PropTypes from 'prop-types';
import { useState } from 'react';

import { Form, Button, Modal } from 'react-bootstrap';
import { Formik, Field } from 'formik';

const ChangeTeam = ({ handleTeamSelection, teamList }) => {
  // Get the team list
  const teams = Object.values(teamList).map((team, index) => (
    <option key={index} value={team.teamOwnerId}>
      {team.firstName} {team.lastName || ''}
    </option>
  ));

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  return (
    <Formik
      initialValues={{
        teamOwners: localStorage.getItem('teamOwnerId'),
        teamDetails: '',
      }}
      onSubmit={(values) => {
        /* get details of the selected team */
        const selectedTeam = teamList.filter((e) =>
          e.teamOwnerId === values.teamOwners ? e : 0
        );
        values.teamDetails = selectedTeam;

        handleClose();
        handleTeamSelection(values);
      }}
    >
      {(formikprops) => (
        <div className="col-auto">
          <Button size="sm" variant="light" onClick={handleShow}>
            Change
          </Button>

          <Form id="change-team" onSubmit={formikprops.handleSubmit}>
            <Modal show={show} onHide={handleClose} animation={false} centered>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4 className="card-header-title"> Change Team </h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Field as="select" name="teamOwners" className="form-select">
                  {teams}
                </Field>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  form="change-team"
                  type="submit"
                  variant="primary"
                  className=" ml-auto"
                >
                  Submit
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        </div>
      )}
    </Formik>
  );
};
ChangeTeam.propTypes = {
  handleTeamSelection: PropTypes.func,
  teamList: PropTypes.any,
};
export default ChangeTeam;
