import PropTypes from 'prop-types';
import { Card, Col } from 'react-bootstrap';

const ProgressCard = ({
  cardTitle,
  cardDetails,
  md = 4,
  cardStyle = '',
  displayStyle = '',
}) => {
  const width =
    ((cardDetails.filter((task) => task.status === 'complete').length >= 1
      ? cardDetails.filter((task) => task.status === 'complete')[0].tasks
      : 0) /
      cardDetails.reduce((total, task) => total + task.tasks, 0)) *
    100;
  return (
    <Col md={md} className="col-12">
      <Card className={`${cardStyle} mb-0`}>
        <Card.Body>
          {displayStyle !== 'compact' ? (
            <Card.Title>
              <h4 className="mb-0">{cardTitle}</h4>
            </Card.Title>
          ) : (
            <Card.Title>
              <h6 className="text-uppercase text-muted mb-2">{cardTitle}</h6>
            </Card.Title>
          )}
          {cardDetails && cardDetails.length >= 1 ? (
            <div className="row align-items-center no-gutters progress-info">
              <div className="col-auto">
                <div
                  className={
                    displayStyle !== 'compact' ? 'mr-2 mb-2' : 'h2 mb-0'
                  }
                >
                  {cardDetails.filter((task) => task.status === 'complete')
                    .length >= 1
                    ? cardDetails.filter(
                        (task) => task.status === 'complete'
                      )[0].tasks
                    : 0}
                  /{cardDetails.reduce((total, task) => total + task.tasks, 0)}
                </div>
              </div>
              <div className="col">
                <div className="progress progress-sm">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: `${width}%`,
                    }}
                    aria-valuenow={{
                      width: `${width}%`,
                    }}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="col-auto">
              <span className="badge bg-secondary-soft">Not available</span>
            </div>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};
ProgressCard.propTypes = {
  cardTitle: PropTypes.string,
  cardDetails: PropTypes.array,
  cardStyle: PropTypes.string,
  md: PropTypes.number,
  displayStyle: PropTypes.string,
};
export default ProgressCard;
