import { Component } from 'react';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import Layout from './DashboardLayout';
import {
  ClientSearch,
  AddNewClientModal,
  ImportClientModal,
  EditClientModal,
} from '../components/client';
import { clientsService } from '../services';
import Loading from '../components/Loader';
import { NO_DATA } from '../constants';
import { NoDataAlert } from '../components/common';

class Clients extends Component {
  state = {
    clientsData: [],
    isLoading: false,
  };

  pageHeading = {
    preTitle: 'Clients',
    title: 'Manage Clients',
  };

  componentDidMount() {
    this.getClientsData();
  }

  getClientsData = (payload) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));

    clientsService
      .get_clients(
        payload || {
          teamOwnerId: localStorage.getItem('teamOwnerId'),
          status: 'active',
        }
      )
      .then((clientData) => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
        if (clientData.data) {
          this.setState((state) => ({
            ...state,
            clientsData: [...clientData.data],
          }));
        }
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  addNewClient = (data) => {
    clientsService
      .add_client({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        name: data.clientName,
        description: data.description,
        url: data.url,
        breakTypes: data.breakTypes,
      })
      .then((response) => {
        if (response.data) {
          this.getClientsData();
        }
      });
  };

  editClient = (data) => {
    clientsService
      .edit_client({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        clientId: data.clientId,
        name: data.updatedName,
      })
      .then((response) => {
        if (response.data) {
          this.getClientsData();
        }
      });
  };

  addBreakType = (data) => {
    clientsService
      .add_break({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        clientId: data.clientId,
        breakType: data.breakType,
      })
      .then((response) => {
        if (response.data) {
          this.getClientsData();
        }
      });
  };

  render() {
    const { isLoading, clientsData } = this.state;
    return (
      <Layout pageHeading={this.pageHeading}>
        <Row className="mt-2 mb-4 justify-content-between">
          <Col style={{ maxWidth: '300px' }}>
            <ClientSearch updateClientList={this.getClientsData} />
          </Col>
          <Col className="col-auto">
            <ImportClientModal updateClientList={this.getClientsData} />
            <AddNewClientModal handleUpdateClientsList={this.getClientsData} />
          </Col>
        </Row>

        {clientsData && clientsData.length > 0 && (
          <Row>
            <Col>
              <Card className="px-0">
                <div className="table-responsive">
                  <table className="table table-sm table-hover card-table">
                    <thead>
                      <tr>
                        <th>Client Name</th>
                        <th>Address</th>
                        <th>Website</th>
                        <th>Break Types</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientsData.map((client) => {
                        const clientBreaks =
                          (client.breakTypes &&
                            client.breakTypes.length > 0 &&
                            client.breakTypes.filter(
                              (item) => item.status !== 'delete'
                            )) ||
                          [];
                        return (
                          <tr key={client._id}>
                            <td>
                              <h5 className="m-0">{client.name}</h5>
                            </td>
                            <td>
                              {client.address && (
                                <p className="m-0">
                                  {client.address.split('\n').map((str) => (
                                    <>
                                      {str}
                                      <br />
                                    </>
                                  ))}
                                </p>
                              )}
                            </td>
                            <td>{client.url && client.url}</td>
                            <td>
                              {clientBreaks.length > 0 ? (
                                clientBreaks.map((breakType, index) => (
                                  <h4 className="d-inline" key={`${index}-bt`}>
                                    <Badge className="badge bg-secondary-soft me-3">
                                      {breakType.name}
                                    </Badge>
                                  </h4>
                                ))
                              ) : (
                                <span className="text-muted">
                                  No break types available
                                </span>
                              )}
                            </td>
                            <td align="right">
                              <EditClientModal
                                selectedClient={client}
                                existingBreakTypes={client.breakTypes}
                                handleUpdateClientsList={this.getClientsData}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
          </Row>
        )}

        {!isLoading && clientsData.length === 0 && (
          <NoDataAlert content={NO_DATA.CLIENTS} displayBorder />
        )}

        {/* Loader */}
        {isLoading && <Loading contentAreaOnly />}
      </Layout>
    );
  }
}

export default Clients;
