import React from 'react';
import moment from 'moment';
import { Col, Button, Spinner, Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { WithConfirmation } from '../common';
import DeleteButton from '../DeleteButton';
import { MemberName, ReactivateMemberModal, InactiveTeamKababMenu } from '.';
import Avatar from '../Avatar';
import Loading from '../Loader';
import { TEAM, API_FAILURE_ERROR, PER_PAGE_LIMIT } from '../../constants';
import { convertSecondsToHoursAndMinutes } from '../../helpers';
import {
  fetchInactiveMembers,
  reactivateMember,
  deleteMember,
  activateLoader,
  deactivateLoader,
} from '../../actions/team';
import { teamService } from '../../services';

const RemoveMember = ({ removeMember }) => {
  const RemoveWithConfirmation = WithConfirmation(DeleteButton);
  const RemoveModalProps = {
    title: `Delete Team Member`,
    action: removeMember,
    content: [
      'Deleting this team member will cause all data, including time logs and screenshots, to be permanently deleted without restoration',
    ],
    actionBtnText: 'Delete',
  };
  return <RemoveWithConfirmation modalProps={RemoveModalProps} />;
};

RemoveMember.propTypes = {
  removeMember: PropTypes.func,
};
const InactiveTeamMember = () => {
  // PER_PAGE_LIMIT = 2;
  const dispatch = useDispatch();
  const teamData = useSelector((stateData) => stateData.team);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMembers = () => {
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      page: teamData.inactiveMembersPage,
      limit: PER_PAGE_LIMIT,
      summaryList: false,
      filter: JSON.stringify({
        memberStatus: 'inactive',
      }),
    };
    dispatch(fetchInactiveMembers(params));
  };

  // Reactivate member
  const handleStatusUpdate = (userId, userStatus, reason) => {
    dispatch(activateLoader());
    const params = {
      userId,
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      status: userStatus,
      reason,
    };
    teamService
      .update_user_status(params)
      .then((response) => {
        dispatch(deactivateLoader());
        if (response.message === 'Success') {
          toast.success(TEAM.STATUS_UPDATE_ACTIVATE_SUCCESS);
          dispatch(reactivateMember(response.data));
        }
      })
      .catch((errResponse) => {
        dispatch(deactivateLoader());
        if ('message' in errResponse) {
          toast.error(errResponse.message);
        } else {
          toast.error(API_FAILURE_ERROR);
        }
      });
  };

  // delete member
  const handleMemberDelete = (userId) => {
    dispatch(activateLoader());
    const params = {
      userId,
      teamOwnerId: localStorage.getItem('teamOwnerId'),
    };
    teamService
      .delete_team_member(params)
      .then(() => {
        dispatch(deactivateLoader());
        toast.success(TEAM.MEMBER_DELETE_SUCCESS);
        dispatch(deleteMember({ userId }));
      })
      .catch((errResponse) => {
        dispatch(deactivateLoader());
        if ('message' in errResponse) {
          toast.error(errResponse.message);
        } else {
          toast.error(API_FAILURE_ERROR);
        }
      });
  };

  const dataFetcher = React.useRef(getMembers);
  const observer = React.useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          dataFetcher.current();
        }
      },
      { threshold: 1 }
    )
  );
  const [element, setElement] = React.useState(null);
  // Update snapshot of getMembers function whenever page number changes.
  React.useEffect(() => {
    dataFetcher.current = getMembers;
  }, [getMembers]);

  // Observe for the load more spinner element
  React.useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  return (
    <>
      {teamData.inactiveMembers && teamData.inactiveMembers.length > 0 ? (
        <section className="card-list-view">
          {teamData.inactiveMembers.map((el, i) => {
            // Get the totalTasks count
            const totalTasks =
              (el.tasks &&
                el.tasks.reduce((total, task) => total + task.tasks, 0)) ||
              0;

            const costRate = el.rates && el.rates.costRate;
            const { userId } = el;
            return (
              <div className="card" key={i} data-id={`${userId}`}>
                <div className="card-body">
                  <span className="avatar-lg">
                    <Avatar
                      firstName={el.firstName}
                      lastName={el.lastName}
                      imgPath={el.avatar ? el.avatar : ''}
                    />
                  </span>
                  <div className="member-info">
                    <MemberName
                      memberName={
                        el.lastName && el.lastName !== null
                          ? `${el.firstName} ${el.lastName}`
                          : `${el.firstName}`
                      }
                      hourlyRate={costRate}
                      defaultCurrency={
                        teamData.inactiveMembersMetadata &&
                        teamData.inactiveMembersMetadata.defaultCurrency
                      }
                    />
                    <p className="small text-center text-muted mb-3">
                      {el.email}
                    </p>
                    <p className="small text-center text-muted mb-3">
                      last worked{' '}
                      {el.workSession.lastWorkSession !== null ? (
                        <time className="small text-muted">
                          {moment(el.workSession.lastWorkSession).fromNow()}
                        </time>
                      ) : (
                        <span className="badge bg-secondary-soft">
                          Not available
                        </span>
                      )}
                    </p>
                    <p className="small text-center text-muted mb-3">
                      Reason : {el.deactivationReason}
                    </p>
                  </div>
                  <div className="list-group list-group-flush mb-4 worksession-info">
                    <div className="list-group-item">
                      <div className="col py-4 text-center align-to-left">
                        <h6 className="text-uppercase text-muted">
                          DEACTIVATION DATE
                        </h6>
                        <h2 className="mb-0">
                          {moment
                            .utc(el.deactivationDate)
                            .format('MMM DD, YYYY')}
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters border-left align-to-left">
                    <div className="col py-4 text-center align-to-left">
                      <h6 className="text-uppercase text-muted">TOTAL HOURS</h6>

                      {el.workSession.totalWorksessionSeconds > 0 ? (
                        <h2 className="mb-0">
                          {convertSecondsToHoursAndMinutes(
                            el.workSession.totalWorksessionSeconds
                          )}
                        </h2>
                      ) : (
                        <h2 className="mb-0">0h 0m</h2>
                      )}
                    </div>
                    <div className="col py-4 text-center border-left align-to-left">
                      <h6 className="text-uppercase text-muted">TOTAL TASKS</h6>
                      {totalTasks >= 1 ? (
                        <h2 className="mb-0">{totalTasks}</h2>
                      ) : (
                        <span className="badge bg-secondary-soft">
                          No tasks
                        </span>
                      )}
                    </div>
                  </div>

                  {/* Footer */}
                  <div className="d-flex justify-content-between">
                    {el.role !== 'manager' && (
                      <div>
                        <ReactivateMemberModal
                          userId={userId}
                          handleStatusUpdate={handleStatusUpdate}
                        />
                        <RemoveMember
                          removeMember={() => handleMemberDelete(userId)}
                        />
                      </div>
                    )}
                  </div>
                  {/* Dropdown */}
                  <InactiveTeamKababMenu userId={userId} />
                </div>
              </div>
            );
          })}
        </section>
      ) : (
        !teamData.isLoading && (
          <Col>
            <Alert variant="info" className="text-center">
              No inactive Members
            </Alert>
          </Col>
        )
      )}

      {/* Loading spinner overlay */}
      {teamData.isLoading && <Loading contentAreaOnly />}

      {/* Observing button to check if more data available to fetch */}
      {!teamData.isLoading && teamData.inactiveMembersLoadMore && (
        <div className="d-flex justify-content-center mb-5">
          <Button variant="primary" disabled ref={setElement}>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{' '}
            <span>Loading...</span>
          </Button>
        </div>
      )}
    </>
  );
};

export default InactiveTeamMember;
