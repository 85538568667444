import { BASE_API_URL, PROJECT_URLS, TEAM_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

import { makeEncodeFormData } from '../helpers';

const options = AppService.options();

export const projectService = {
  get_projects_data: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const response = await AppService.makeRequest(
      BASE_API_URL + PROJECT_URLS.LIST,
      {
        ...options,
        ...headers,
        body: makeEncodeFormData(data),
      }
    );
    return response;
  },
  create_project: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + PROJECT_URLS.PROJECT,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_project_status: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const response = await AppService.makeRequest(
      BASE_API_URL + PROJECT_URLS.PROJECT_STATUS_UPDATE,
      {
        ...options,
        ...headers,
        body: makeEncodeFormData(data),
      }
    );
    return response;
  },
  get_projects_details: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + PROJECT_URLS.PROJECT}/${data.projectId}/${
        data.teamOwnerId
      }`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_project: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + PROJECT_URLS.PROJECT,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_overview_summary: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'GET',
    };
    const URL = `${BASE_API_URL}${PROJECT_URLS.SUMMARY}?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  get_received_invitations_data: async () => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + TEAM_URLS.TEAM_RECEIVED_INVITATIONS}`,
      {
        ...reqOptions,
        ...headers,
      }
    );

    return response;
  },
  get_projects_invitations: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + TEAM_URLS.TEAM_SENT_INVITATIONS}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_invitation_status: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.INVITATION,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  remove_project_member: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'DELETE',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL}${PROJECT_URLS.USER}/${data.teamOwnerId}/${data.projectId}/${data.userId}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  get_tasks_data: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const URL = `${BASE_API_URL}${TEAM_URLS.TASK_LIST}?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  get_projects_total_hours: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + PROJECT_URLS.WORKSESSION}/${data.projectId}/${
        data.teamOwnerId
      }`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  get_project_members: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + PROJECT_URLS.MEMBERS}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
};
