const initialState = {
  stepIndex: 0,
  trackTaskTimeType: '',
};

export const freeTrialTourReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TOUR_STEP_INDEX':
      return {
        ...state,
        stepIndex: action.payload.stepIndex,
      };
    case 'FETCH_TOUR_STEP_INDEX':
      return {
        ...state,
      };
    case 'SET_TOUR_TRACK_TASK_TIME':
      return {
        ...state,
        trackTaskTimeType: action.payload.trackTaskTimeType,
      };
    case 'FETCH_TOUR_TRACK_TASK_TIME':
      return {
        ...state,
      };
    case 'FETCH_TOUR_STEP_INDEX_ERROR':
      return {
        ...state,
        error: action.error,
      };
    case 'FETCH_TOUR_TRACK_TASK_TIME_ERROR':
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
