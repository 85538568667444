import PropTypes from 'prop-types';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ToggleSwitch } from '../common';

const NotificationSettings = ({
  isTaskNotificationEnabled,
  ndaysBeforeDuedate,
  dueDateEmailEnabled,
  mailOnTaskCreation,
  handleNotificationSettingToggle,
  handleUpdateNotificationSetting,
}) => (
  <Formik
    initialValues={{
      isTaskNotificationEnabled,
      ndaysBeforeDuedate: ndaysBeforeDuedate || 1,
      dueDateEmailEnabled,
      mailOnTaskCreation,
    }}
    enableReinitialize
    validationSchema={Yup.object({
      isTaskNotificationEnabled: Yup.boolean(),
      ndaysBeforeDuedate: Yup.number().when('isTaskNotificationEnabled', {
        is: true,
        then: Yup.number()
          .typeError('Days must be greater than or equal to 1')
          .positive()
          .required('Please enter number of days')
          .min(1, 'Days must be greater than or equal to 1')
          .max(31, 'Days must be less than or equal to 31'),
      }),
      dueDateEmailEnabled: Yup.boolean(),
      mailOnTaskCreation: Yup.boolean(),
    })}
    onSubmit={(values) => {
      const setvalues = JSON.parse(JSON.stringify(values));
      if (!values.dueDateEmailEnabled) {
        setvalues.ndaysBeforeDuedate = 1;
      }
      handleUpdateNotificationSetting(setvalues);
    }}
  >
    {(formikprops) => (
      <Form onSubmit={formikprops.handleSubmit} className="needs-validation">
        <Row>
          <Col>
            <h4 className="font-weight-base mb-1">Task notifications</h4>
            <small className="text-muted">
              This feature allows you to set team member task notifications.
            </small>
          </Col>
          <Col className="col-auto">
            <ToggleSwitch
              id="isTaskNotificationEnabled"
              name="isTaskNotificationEnabled"
              handleChangeforChecked={handleNotificationSettingToggle}
              checked={isTaskNotificationEnabled}
            />
          </Col>
        </Row>

        {isTaskNotificationEnabled && (
          <div>
            <Row className="mt-3">
              <Col className="col-auto pt-2 pe-0" style={{ paddingTop: '9px' }}>
                <small>
                  <Form.Check
                    type="checkbox"
                    id="beforeDuedate-checkbox"
                    label="Notify"
                    className="f-1"
                    checked={formikprops.values.dueDateEmailEnabled}
                    name="dueDateEmailEnabled"
                    onChange={() =>
                      formikprops.setFieldValue(
                        'dueDateEmailEnabled',
                        !formikprops.values.dueDateEmailEnabled
                      )
                    }
                  />
                </small>
              </Col>

              <Col className="px-2 col-auto">
                <div className="form-group mb-2">
                  <Field
                    type="text"
                    className="form-control form-control-sm"
                    style={{
                      maxWidth: '41px',
                      marginTop: '6px',
                      marginLeft: '0px',
                    }}
                    name="ndaysBeforeDuedate"
                    placeholder="Day"
                  />
                </div>
              </Col>

              <Col className="col-auto ps-0">
                <span className="pt-2 d-inline-block">
                  <small>
                    day
                    {formikprops.values &&
                      formikprops.values.ndaysBeforeDuedate > 1 && (
                        <span>s</span>
                      )}{' '}
                    before task due date
                  </small>
                </span>
              </Col>
              <Col className="col-auto ps-0">
                <ErrorMessage
                  name="ndaysBeforeDuedate"
                  render={(msg) => (
                    <small
                      className="text-danger ps-5"
                      style={{
                        'line-height': '35px',
                        'text-align': 'center',
                      }}
                    >
                      {msg}
                    </small>
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col className="col-auto pt-2">
                <small>
                  <Form.Check
                    type="checkbox"
                    id="mailOnTaskCreate-checkbox"
                    label="Email notification on new task creation."
                    className="f-1"
                    checked={formikprops.values.mailOnTaskCreation}
                    name="mailOnTaskCreation"
                    onChange={() =>
                      formikprops.setFieldValue(
                        'mailOnTaskCreation',
                        !formikprops.values.mailOnTaskCreation
                      )
                    }
                  />
                </small>
              </Col>
            </Row>

            <br />

            <Row>
              <Col md="auto">
                <Button
                  size="sm"
                  type="submit"
                  style={{ height: '26px' }}
                  disabled={!formikprops.isValid}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Form>
    )}
  </Formik>
);
NotificationSettings.propTypes = {
  isTaskNotificationEnabled: PropTypes.bool,
  handleNotificationSettingToggle: PropTypes.func,
  handleUpdateNotificationSetting: PropTypes.func,
  ndaysBeforeDuedate: PropTypes.number,
  dueDateEmailEnabled: PropTypes.bool,
  mailOnTaskCreation: PropTypes.bool,
};
export default NotificationSettings;
