export const setBillingDetails = (data) => ({
  type: 'SET_BILLING_DETAILS',
  payload: data,
});

export const setAccountStatus = (data) => ({
  type: 'SET_ACCOUNT_STATUS',
  payload: data,
});

export const setCurrentPlan = (data) => ({
  type: 'SET_CURRENT_PLAN',
  payload: data,
});

export const setHostedPagestatus = (data) => ({
  type: 'SET_PAGE_STATUS',
  payload: data,
});
