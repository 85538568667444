import PropTypes from 'prop-types';

const SwitchView = ({ isListView, handleChangeView }) => (
  <div className="nav btn-group d-none d-md-block switch-view">
    <button
      className={isListView ? 'btn btn-white' : 'btn btn-white active'}
      type="button"
      disabled={!isListView && 'disabled'}
      onClick={() => handleChangeView()}
    >
      <span className="mdi mdi-view-grid" />
    </button>
    <button
      className={isListView ? 'btn btn-white active' : 'btn btn-white'}
      type="button"
      onClick={() => handleChangeView()}
    >
      <span className="mdi mdi-format-list-bulleted" />
    </button>
  </div>
);

SwitchView.propTypes = {
  isListView: PropTypes.bool,
  handleChangeView: PropTypes.func,
};

export default SwitchView;
