import React, { Component } from 'react';
import { Tabs, Tab, Row, Col, Form, Button } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { toast } from 'react-toastify';
import Layout from './DashboardLayout';
import {
  teamService,
  projectService,
  reportService,
  tasksService,
} from '../services';
import Loading from '../components/Loader';
import {
  TeamMembersDropdown,
  ProjectListDropdown,
  DateRangePickerField,
} from '../components/common';
import { ScreenshotsList, PdfList } from '../components/reports';
import { DATE_AND_TIME, SS_DATE_RANGE, API_FAILURE_ERROR } from '../constants';
import { trackJune } from '../utilities/analytics';

class ScreenshotReport extends Component {
  state = {
    isLoading: true,
    teamMembersList: [],
    projectsList: [],
    tasksList: [],
    pdfs: [],
    startDate: moment().format(DATE_AND_TIME.DATE_RANGE_IN_STATE),
    endDate: moment().format(DATE_AND_TIME.DATE_RANGE_IN_STATE),
    screenshotData: 0,
    isUpdatingProjectsList: false,
    isUpdatingTasksList: false,
    selectedMemberInfo: [],
    numberOfDaysSelected: 1,
    isFetchingData: false,
    isPDFgenerated: false,
    isUpdatingTeamsList: false,
    daysLimit: 7,
    selectedTeamMember: '',
  };

  pageHeading = {
    preTitle: 'Reports',
    title: 'Screenshot Report',
  };

  createPDFData = {};

  componentDidMount() {
    const teamOwnerId = localStorage.getItem('teamOwnerId');
    this.createPDFData = {
      teamOwnerId,
    };
    Promise.all([
      teamService.get_team_members_and_projects({
        teamOwnerId,
        summaryList: true,
      }),
      reportService.get_pdfs({
        teamOwnerId,
      }),
      this.getTaskData({
        teamOwnerId,
      }),
    ])
      .then((response) => {
        const [team, pdf] = response;
        this.setState((state) => ({
          ...state,
          isLoading: false,
          teamMembersList: team.data.teamMembers,
          projectsList: team.data.projects,
          pdfs: [...pdf.data.pdfs],
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
    // track june
    trackJune(null, this.pageHeading.title);
  }

  handleDateSelect = (event, picker) => {
    this.setState((state) => ({
      ...state,
      startDate: moment(picker.startDate).format(
        DATE_AND_TIME.DATE_RANGE_IN_STATE
      ),
      endDate: moment(picker.endDate).format(DATE_AND_TIME.DATE_RANGE_IN_STATE),
    }));
  };

  handleDateRangeValidation = (start, end) => {
    const { daysLimit } = this.state;
    const daysSelected = Math.floor(
      (Date.parse(end) - Date.parse(start)) / 86400000
    );
    if (daysSelected > daysLimit) {
      toast.error(`${SS_DATE_RANGE.LIMIT} ${SS_DATE_RANGE.ERROR}`);
    }
    this.setState((state) => ({
      ...state,
      numberOfDaysSelected: daysSelected,
    }));
  };

  getProjectData = (member) => {
    this.setState((state) => ({
      ...state,
      isUpdatingProjectsList: true,
    }));

    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      summaryList: true,
    };
    if (member) {
      params.filter = JSON.stringify({
        teamMembers: [member],
      });
    }
    projectService
      .get_projects_data(params)
      .then((response) => {
        const {
          data: { projects },
        } = response;
        this.setState((state) => ({
          ...state,
          projectsList: [...projects],
          isUpdatingProjectsList: false,
        }));
      })
      .catch(() => {
        // Do nothing
      });
  };

  getTaskData = (data) => {
    this.setState((state) => ({
      ...state,
      isUpdatingTasksList: true,
    }));
    tasksService
      .get_tasks_data(data)
      .then((result) => {
        const { tasks } = result.data;
        this.setState((state) => ({
          ...state,
          isLoading: false,
          tasksList: [...tasks],
          isUpdatingTasksList: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isUpdatingTasksList: false,
        }));
      });
  };

  handleTeamMemberChange = (event) => {
    this.getProjectData(event.target.value);
    const taskParams = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      teamMemberId: event.target.value,
    };
    if (taskParams.teamMemberId === 'all') {
      delete taskParams.teamMemberId;
    }
    this.getTaskData(taskParams);
    this.setState((state) => ({
      ...state,
      selectedTeamMember: event.target.value,
    }));
  };

  handleProjectChange = (event) => {
    const { selectedTeamMember } = this.state;
    const taskParams = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      projectId: event.target.value,
      teamMemberId: selectedTeamMember,
    };
    if (taskParams.projectId === 'all') {
      delete taskParams.projectId;
    }
    if (taskParams.teamMemberId === 'all') {
      delete taskParams.teamMemberId;
    }
    this.getTaskData(taskParams);
  };

  handleGenerateData = (formdata) => {
    this.setState((state) => ({
      ...state,
      isFetchingData: true,
    }));

    const { startDate, endDate } = this.state;

    const reportParams = {
      teamOwnerId: formdata.teamOwnerId,
      teamMember: formdata.teamMember,
      project: formdata.project,
      task: formdata.task,
      fromDate: startDate.concat(' 00:00:00'),
      toDate: endDate.concat(' 23:59:59'),
    };

    // If user selected all this param is not required
    if (formdata.project === 'all') {
      delete reportParams.project;
    }
    if (formdata.task === 'all') {
      delete reportParams.task;
    }

    this.createPDFData = {
      ...this.createPDFData,
      ...reportParams,
    };
    const { teamMembersList } = this.state;
    const selectedMember = teamMembersList.filter(
      (member) => member.userId === formdata.teamMember
    );
    this.setState((state) => ({
      ...state,
      selectedMemberInfo: selectedMember[0],
    }));

    reportService
      .get_screenshots(reportParams)
      .then((response) => {
        trackJune('view report (screenshot)');
        const {
          data: { screenshots },
        } = response;
        this.setState((state) => ({
          ...state,
          isFetchingData: false,
          screenshotData: screenshots,
        }));
        document
          .getElementById('screenshot-report-tabs-tab-screenshot')
          .click();
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isFetchingData: false,
        }));
      });
  };

  generatePDF = (selectedScreenshot, perPage) => {
    this.setState((state) => ({
      ...state,
      isFetchingData: true,
    }));

    const data = {
      screenshotData: selectedScreenshot,
      perPage,
      ...this.createPDFData,
    };
    const { startDate, endDate } = this.state;
    this.createPDFData.fromDate = startDate.concat(' 00:00:00');
    this.createPDFData.toDate = endDate.concat(' 23:59:59');
    reportService
      .generate_pdf(data, 'POST', this.createPDFData)
      .then((response) => {
        this.setState((state) => ({
          ...state,
          isFetchingData: false,
          isPDFgenerated: true,
        }));
        toast.success(response.message);
      })
      .catch(() => {
        toast.error(API_FAILURE_ERROR);
      });
  };

  render() {
    const {
      teamMembersList,
      projectsList,
      tasksList,
      pdfs,
      startDate,
      endDate,
      isFetchingData,
      isLoading,
      isUpdatingProjectsList,
      isUpdatingTasksList,
      screenshotData,
      selectedMemberInfo,
      numberOfDaysSelected,
      isUpdatingTeamsList,
      daysLimit,
    } = this.state;

    // Team owner ID
    const teamOwnerId = localStorage.getItem('teamOwnerId');
    return (
      <Layout pageHeading={this.pageHeading}>
        {(isLoading || isFetchingData) && <Loading contentAreaOnly />}
        <Formik
          initialValues={{
            teamOwnerId,
            teamMember: '',
            project: 'all',
            task: 'all',
            fromDate: startDate,
            toDate: endDate,
          }}
          validationSchema={Yup.object({
            teamMember: Yup.string().required('Please select any team member'),
          })}
          onSubmit={(values) => {
            this.handleGenerateData(values);
          }}
        >
          {(formikprops) => (
            <Form onSubmit={formikprops.handleSubmit}>
              <Row>
                <Col md={6} lg={3}>
                  <TeamMembersDropdown
                    teamMembersList={teamMembersList}
                    handleChange={formikprops.handleChange}
                    handleTeamMemberChange={this.handleTeamMemberChange}
                    isDependentFiled
                    isValidate
                    isRequired
                  />
                </Col>
                <Col md={6} lg={3}>
                  <ProjectListDropdown
                    projectsList={projectsList}
                    isUpdatingProjectsList={isUpdatingProjectsList}
                    handleChange={formikprops.handleChange}
                    handleProjectChange={this.handleProjectChange}
                    isDependedField
                  />
                </Col>
                <Col md={6} lg={3}>
                  <Form.Group controlId="task">
                    <Form.Label>Task</Form.Label>
                    <Field
                      className="form-select"
                      as="select"
                      name="task"
                      disabled={isUpdatingTasksList || !tasksList.length > 0}
                    >
                      <option value="all">All</option>
                      {tasksList &&
                        tasksList.length > 0 &&
                        tasksList.map((item, index) => (
                          <option key={`${index}-${item._id}`} value={item._id}>
                            {item.task}
                          </option>
                        ))}
                    </Field>
                  </Form.Group>
                </Col>
                <Col md={6} lg={3}>
                  <DateRangePickerField
                    handleDateSelect={this.handleDateSelect}
                    handleDateRangeValidation={this.handleDateRangeValidation}
                    numberOfDaysSelected={numberOfDaysSelected}
                    isValidate
                    showRangeOptions
                    preDefinedRanges={{
                      Today: [moment().toDate(), moment().toDate()],
                      Yesterday: [
                        moment().subtract(1, 'days').toDate(),
                        moment().subtract(1, 'days').toDate(),
                      ],
                      'Last 7 Days': [
                        moment().subtract(6, 'days').toDate(),
                        moment().toDate(),
                      ],
                    }}
                    daysLimit={daysLimit}
                  />
                </Col>
                <Col md={6} lg={3}>
                  <Form.Group controlId="TeamMembers">
                    <Form.Label className="w-100">&nbsp;</Form.Label>
                    <Button
                      variant="primary"
                      type="Submit"
                      disabled={
                        numberOfDaysSelected > 7 ||
                        isUpdatingTeamsList ||
                        isUpdatingProjectsList
                      }
                    >
                      Generate
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <Tabs
          defaultActiveKey="screenshot"
          id="screenshot-report-tabs"
          className="mb-3"
        >
          <Tab eventKey="screenshot" title="Screenshots">
            {screenshotData !== 0 && (
              <ScreenshotsList
                screenshotData={screenshotData}
                generatePDF={this.generatePDF}
                selectedMemberInfo={selectedMemberInfo}
              />
            )}
          </Tab>
          <Tab eventKey="report" title="Screenshot report files">
            {pdfs !== 0 && <PdfList screenshotPdfData={pdfs} />}
          </Tab>
        </Tabs>
      </Layout>
    );
  }
}

export default ScreenshotReport;
