import { Card, Row, Col } from 'react-bootstrap';
import { LOCAL_STORAGE_USER_DATA } from '../../constants';

const SeatsUsed = () => {
  const userdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  return (
    <Col xs={12} md={4}>
      <Card>
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <h6 className="text-uppercase text-muted mb-2">Seats used</h6>
              <span className="h2 mb-0">
                {userdata.activeMembers} of {userdata.maxMembersAllowed}
              </span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};
export default SeatsUsed;
