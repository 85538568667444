import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import EdiText from 'react-editext';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Avatar from '../../Avatar';
import { MemberStatus } from '../../common';
import { MemberRole } from '../../team';
import { teamService } from '../../../services';
import {
  UPDATE_FAILURE_ERROR,
  TEAM,
  PAGINATION_LIMIT,
} from '../../../constants';
import Loading from '../../Loader';
import { convertSecondsToHoursAndMinutes } from '../../../helpers';
import Pagination from '../../common/Pagination';

const TeamMemberCard = ({
  cardTitle,
  teamMemberData,
  projectId,
  userRole,
  getTeamMembers,
  isFetchingTeamData,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const query = searchParams.get('query');
  const [searchKey, setSearchKey] = useState(query || '');
  const updateRate = (updateData) => {
    setIsLoading(true);
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      projectId,
      ...updateData,
    };
    teamService
      .update_user_rate(params)
      .then((res) => {
        if (res.status) {
          setIsLoading(false);
          toast.success(TEAM.RATE_UPDATE_SUCCESS);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(UPDATE_FAILURE_ERROR);
      });
  };
  const tableColumns = [
    {
      name: 'MEMBER',
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => row.member,
    },
    {
      name: 'ONLINE',
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => row.online,
    },
  ];
  if (userRole !== 'member') {
    tableColumns.push(
      {
        name: 'TOTAL HOURS',
        selector: (row) => row.hours,
        sortable: true,
      },
      {
        name: 'TASKS',
        selector: (row) => row.totalTasks,
        sortable: true,
        cell: (row) => row.tasks,
      },
      {
        name: 'HOURLY RATE',
        selector: (row) => row.rate,
        sortable: true,
      }
    );
  }
  const tableData = [];
  const { members, metadata } = teamMemberData;
  // eslint-disable-next-line no-unused-expressions
  members &&
    members.length > 0 &&
    members.forEach(function (member, index) {
      const { tasks } = member;
      let completeTasks = 0;
      let totalTasks = 0;
      if (tasks && tasks.length >= 1) {
        completeTasks =
          tasks.filter((task) => task.status === 'complete').length >= 1
            ? tasks.filter((task) => task.status === 'complete')[0].tasks
            : 0;
        totalTasks = tasks.reduce((total, task) => total + task.tasks, 0);
      }
      const details = {
        id: `tm${index}`,
        member: (
          <Row>
            <Col>
              <div className="d-flex align-items-center">
                <div className="avatar me-2" style={{ minWidth: '48px' }}>
                  <Avatar
                    firstName={member.firstName}
                    lastName={member.lastName}
                    imgPath={member.avatar ? member.avatar : ''}
                  />
                </div>
                <div className="mt-1">
                  <span>
                    {member.firstName} {member.lastName || ''}{' '}
                    {member.status === 'inactive' && ' (Inactive)'}
                  </span>
                  <br />
                  <span className="text-muted">{member.email}</span>
                  <p className="role mt-2 mb-1">
                    <MemberRole role={'role' in member ? member.role : ''} />
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        ),
        online: <MemberStatus memberId={member.userId} />,
        status: member.status,
        name: `${member.firstName} ${member.lastName || ''}`,
      };

      if (userRole !== 'member') {
        details.hours = member.workSession.totalWorksessionSeconds
          ? convertSecondsToHoursAndMinutes(
              member.workSession.totalWorksessionSeconds
            )
          : 0;
        details.tasks = (
          <>
            {tasks && tasks.length >= 1 ? (
              <Row className="align-items-center no-gutters progress-info w-100">
                <Col className="col-auto p-0">
                  {completeTasks}/{totalTasks}
                </Col>
                <Col md={6} xs={12}>
                  <div className="progress progress-sm">
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${(completeTasks / totalTasks) * 100}%`,
                      }}
                      aria-valuenow={{
                        width: `${(completeTasks / totalTasks) * 100}%`,
                      }}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              <div className="col-auto">
                <span className="badge bg-secondary-soft">Not available</span>
              </div>
            )}
          </>
        );
        details.rate = (
          <EdiText
            type="text"
            validationMessage="Invalid rate"
            validation={(val) =>
              val.match(/^(?!0\d)\d*(\.\d+)?$/) &&
              val >= 0 &&
              val.trim().length > 0
            }
            value={
              member.rates.costRate ? member.rates.costRate.toFixed(2) : '0.00'
            }
            showButtonsOnHover
            className="link-primary"
            onSave={(value) =>
              updateRate({
                userId: member.userId,
                costRate: value,
              })
            }
            editOnViewClick
            cancelOnUnfocus
            hideIcons
            editButtonContent={<i className="mdi mdi-pencil-outline" />}
            saveButtonContent={<i className="mdi mdi-check" />}
            saveButtonClassName="btn btn-primary btn-sm me-2"
            cancelButtonContent={<i className="mdi mdi-close" />}
            cancelButtonClassName="btn btn-white btn-sm"
            inputProps={{ maxLength: 8 }}
          />
        );
        details.totalTasks = totalTasks;
      }
      tableData.push(details);
    });

  const [currentPage, setCurrentPage] = useState(
    metadata && metadata.page ? metadata.page : 1
  );

  // Team member search function
  const handleSearch = () => {
    history.push(`?query=${searchKey}`);
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      page: 1,
      limit: 10,
      filter: JSON.stringify({
        projectId,
        keyword: searchKey,
      }),
    };
    setCurrentPage(1);
    getTeamMembers(params);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      {(isLoading || isFetchingTeamData) && <Loading contentAreaOnly />}
      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col>
              <h4 className="m-0">{cardTitle}</h4>
            </Col>
            <Col lg={3}>
              <div className="input-group">
                <input
                  className="form-control form-control-sm"
                  placeholder="Search"
                  type="text"
                  aria-label="Search"
                  aria-describedby="Search"
                  onChange={(e) => setSearchKey(e.target.value)}
                  onKeyDown={handleKeyDown}
                  value={searchKey}
                />
                <Button variant="primary" size="sm" onClick={handleSearch}>
                  <span className="mdi mdi-magnify" />
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0 pb-2">
          <div className="overview-member-table">
            <DataTable
              columns={tableColumns}
              data={tableData}
              noDataComponent={
                isLoading ? (
                  ''
                ) : (
                  <p className="py-3 text-muted m-0">No members found</p>
                )
              }
            />
          </div>
        </Card.Body>
        {metadata && metadata.total && metadata.total > 10 && (
          <Card.Footer>
            <section className="d-flex justify-content-center">
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={metadata.total}
                pageSize={PAGINATION_LIMIT}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  getTeamMembers({
                    teamOwnerId: localStorage.getItem('teamOwnerId'),
                    page,
                    limit: 10,
                    filter: JSON.stringify({
                      projectId,
                      keyword: searchKey,
                    }),
                  });
                }}
              />
            </section>
          </Card.Footer>
        )}
      </Card>
    </>
  );
};

TeamMemberCard.propTypes = {
  cardTitle: PropTypes.string,
  teamMemberData: PropTypes.any,
  projectId: PropTypes.string,
  userRole: PropTypes.string,
  getTeamMembers: PropTypes.func,
  isFetchingTeamData: PropTypes.bool,
};
export default TeamMemberCard;
