import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import { Field, ErrorMessage, FieldArray } from 'formik';

const AddMemberWithoutRole = ({
  formProps,
  showPlanText,
  formLabel,
  fieldName,
}) => (
  <Form.Group>
    <Form.Label>{formLabel}</Form.Label>
    {showPlanText && <div className="alert alert-info">{showPlanText}</div>}
    <div className="scroll-invite-team-member-email">
      <FieldArray
        name={fieldName}
        render={(arrayHelpers) => (
          <div>
            {formProps.values[fieldName] &&
              formProps.values[fieldName].length > 0 &&
              formProps.values[fieldName].map((emails, index) => (
                <>
                  <div className="position-relative">
                    <Form.Group
                      key={index}
                      controlId="projectName"
                      className="form-control-label label-required mb-3"
                    >
                      <Field
                        className="form-control inputhovershow"
                        type="text"
                        placeholder="name@example.com"
                        name={`${fieldName}.${index}.email`}
                      />
                      {formProps.values[fieldName].length > 1 && (
                        <Button
                          className="email-invite-close-button"
                          variant="link"
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        >
                          <i className="mdi mdi-window-close " />
                        </Button>
                      )}

                      <ErrorMessage
                        name={`${fieldName}.${index}.email`}
                        render={(msg) => (
                          <small className="text-danger">{msg}</small>
                        )}
                      />
                    </Form.Group>
                  </div>
                </>
              ))}
            <div className="mb-4">
              <a
                href="true"
                onClick={(e) => {
                  e.preventDefault();
                  arrayHelpers.push({ email: '' });
                }}
              >
                + Add another
              </a>
            </div>
          </div>
        )}
      />
    </div>
  </Form.Group>
);

AddMemberWithoutRole.propTypes = {
  formProps: PropTypes.any,
  showPlanText: PropTypes.string,
  formLabel: PropTypes.string,
  fieldName: PropTypes.string,
};

export default AddMemberWithoutRole;
