import { Form, Button, Row } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import { CURRENCIES, API_FAILURE_ERROR } from '../../constants';
import { settingsService } from '../../services';

const GeneralForm = ({ contactInfo, handleUpdate, isSubmitting }) => {
  const [timezones, settimezones] = useState([]);
  const userDetails = useSelector((state) => state.userReducer);
  useEffect(() => {
    settingsService
      .timezone_list()
      .then((response) => {
        settimezones(response.data);
      })
      .catch((errResp) => {
        toast.error(API_FAILURE_ERROR);
      });
  }, []);
  if (userDetails.role === 'creator' && !contactInfo.currency) {
    contactInfo.currency = 'USD';
  }
  return (
    <Formik
      initialValues={{
        firstname: contactInfo.fname,
        lastname: contactInfo.lname,
        emailaddress: contactInfo.email,
        phone: contactInfo.phone || '',
        timezone: contactInfo.timezone || Object.keys(timezones)[0],
        currency: userDetails.role === 'creator' ? contactInfo.currency : '',
        mobilecheck: contactInfo.mobile === 1 ? true : false || false,
      }}
      validationSchema={Yup.object({
        firstname: Yup.string().required('Please enter your name'),
        emailaddress: Yup.string()
          .trim()
          .email('Please enter a valid email address')
          .required('Please enter your email address'),
        phone: Yup.string()
          .matches(/^[0-9 -]+$|^$|^\s$/, {
            message: 'Please enter valid phone number.',
            excludeEmptyString: true,
          })
          .max(16, 'Max digits allowed is 16')
          .nullable(),
      })}
      onSubmit={(values) => {
        handleUpdate(values);
      }}
    >
      {(formikprops) => (
        <Form onSubmit={formikprops.handleSubmit} className="needs-validation">
          <hr className="my-3" />
          <Row>
            {contactInfo.lname !== null && contactInfo.lname.length !== 0 ? (
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <Form.Label className="form-control-label">
                    First name
                  </Form.Label>
                  <Field
                    name="firstname"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="firstname"
                    render={(msg) => (
                      <small className="text-danger">{msg}</small>
                    )}
                  />
                </div>
              </div>
            ) : (
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <Form.Label className="form-control-label">
                    Full Name
                  </Form.Label>
                  <Field
                    name="firstname"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="firstname"
                    render={(msg) => (
                      <small className="text-danger">{msg}</small>
                    )}
                  />
                </div>
              </div>
            )}
            {contactInfo.lname !== null && contactInfo.lname.length !== 0 && (
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <Form.Label className="form-control-label">
                    Last name
                  </Form.Label>
                  <Field
                    name="lastname"
                    type="text"
                    className="form-control"
                    maxLength="19"
                  />
                  <ErrorMessage
                    name="lastname"
                    render={(msg) => (
                      <small className="text-danger">{msg}</small>
                    )}
                  />
                </div>
              </div>
            )}
            <div className="col-12 col-md-6">
              <div className="form-group">
                <Form.Label className="form-control-label">
                  Email address
                </Form.Label>
                <Field
                  name="emailaddress"
                  type="text"
                  className="form-control"
                />
                <ErrorMessage
                  name="emailaddress"
                  render={(msg) => <small className="text-danger">{msg}</small>}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <Form.Label className="form-control-label">Phone</Form.Label>
                <Field
                  name="phone"
                  type="text"
                  className="form-control"
                  maxLength="16"
                />
                <ErrorMessage
                  name="phone"
                  render={(msg) => <small className="text-danger">{msg}</small>}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="form-group">
                <Form.Label className="form-control-label">Timezone</Form.Label>
                <Field as="select" name="timezone" className="form-select">
                  {Object.keys(timezones).map((e, i) => (
                    <option value={e} key={i}>
                      {timezones[e]}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          </Row>
          <Row>
            {userDetails.role === 'creator' && (
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <Form.Label className="form-control-label">
                    Currency
                  </Form.Label>
                  <Field as="select" name="currency" className="form-select">
                    {Object.keys(CURRENCIES).map((e, i) => (
                      <option value={e} key={i}>
                        {CURRENCIES[e]}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            )}
            {/* {userDetails.role === 'creator' && (
                <div className="col-12 col-md-8">
                  <div className="form-check ml-2">
                    <Field
                      className="form-check-input form-check-border"
                      name="mobilecheck"
                      type="checkbox"
                    />
                    <Form.Label
                      className="form-control-label"
                      htmlFor="mobilecheck"
                    >
                      Enable mobile tracking
                    </Form.Label>
                  </div>
                  <p className="text-muted small">{GENERAL.MOBILE_TRACKING}</p>
                </div>
              )} */}
          </Row>

          <p className="text-end mb-5">
            <Button
              className="btn btn-primary"
              type="submit"
              disabled={
                !(formikprops.isValid && formikprops.dirty) || isSubmitting
              }
            >
              Save changes
            </Button>
          </p>
        </Form>
      )}
    </Formik>
  );
};

GeneralForm.propTypes = {
  isSubmitting: PropTypes.bool,
  contactInfo: PropTypes.object,
  handleUpdate: PropTypes.func,
};

export default GeneralForm;
