import { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from './DashboardLayout';
import { workSessionService, activityService } from '../services';
import Loading from '../components/Loader';
import { UPDATE_FAILURE_ERROR } from '../constants';
import Calendar from '../components/tracking/Calendar';
import { getUserData } from '../helpers';
import { fetchMemberProjects } from '../actions/projects';
import { trackJune } from '../utilities/analytics';
import { setShowTimerWidget } from '../actions/timer';
import { endTour, startTour } from '../actions';

class Tracking extends Component {
  state = {
    isLoading: false,
    projectsList: [],
    startDate: moment().startOf('week').format('YYYY-MM-DD'),
    endDate: moment().startOf('week').day(7).format('YYYY-MM-DD'),
    selectedProject: '',
    showAddSessionModal: false,
    showEditSessionModal: false,
    isRefetchEvents: false,
  };

  pageHeading = {
    preTitle: 'Track',
    title: 'Calendar',
  };

  componentDidMount() {
    const { fetchMemberInitProjects, startInitTour, endInitTour, stepIndex } =
      this.props;
    fetchMemberInitProjects();
    // Auto start of the guided tour.
    if (
      localStorage.getItem('TourAutoStart') &&
      stepIndex.trackTaskTimeType === 'Time Tracking'
    ) {
      endInitTour();
      setTimeout(() => {
        startInitTour();
      }, 2000);
    }
    // track june
    trackJune(null, this.pageHeading.title);
  }

  // Page Loader
  handleLoader = (pageState) => {
    this.setState((state) => ({
      ...state,
      isLoading: pageState,
    }));
  };

  handleChangeDate = (sDate, eDate) => {
    this.setState((state) => ({
      ...state,
      startDate: moment(eDate).day(0).format('YYYY-MM-DD'),
      endDate: moment(eDate).day(7).format('YYYY-MM-DD'),
    }));
  };

  handleShowAddSessionModal = (displayState) => {
    this.setState((state) => ({
      ...state,
      showAddSessionModal: displayState,
    }));
  };

  // Edit session modal display
  handleShowEditSessionModal = (displayState) => {
    this.setState((state) => ({
      ...state,
      showEditSessionModal: displayState,
    }));
  };

  handleGetEvents = () => (info, successCallback) => {
    const sDate = moment(info.start).format('YYYY-MM-DD');
    const eDate = moment(info.end).subtract(1, 'days').format('YYYY-MM-DD');
    const memberId = getUserData('_id');
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      teamMember: memberId,
      fromDate: sDate.concat(' 00:00:00'),
      toDate: eDate.concat(' 23:59:59'),
    };

    // Enable loader
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));

    activityService.get_activity_data(params).then((result) => {
      if (result.data) {
        const { activityData } = result.data;
        // Extract schedules item from allSchedulesList and create a single array [schedules]
        const worksessionData =
          activityData && activityData.length > 0
            ? activityData.map((wsItem) => {
                let worksession = {};
                worksession = {
                  id: wsItem._id,
                  title: wsItem.project.title,
                  start: moment(wsItem.checkInDate).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  ),
                  end: wsItem.checkOutDate
                    ? moment(wsItem.checkOutDate).format('YYYY-MM-DDTHH:mm:ss')
                    : moment().format('YYYY-MM-DDTHH:mm:ss'),
                  backgroundColor: '#DEEBFF',
                  borderColor: '#DEEBFF',
                  textColor: '#3c495ab3',
                  editable: wsItem.checkOutDate && wsItem.checkOutDate !== null,
                  project: wsItem.project.title,
                  projectId: wsItem.project._id,
                  task: wsItem.taskId ? wsItem.task : null,
                  taskId: wsItem.taskId ? wsItem.taskId : null,
                  isEditable:
                    wsItem.checkOutDate && wsItem.checkOutDate !== null,
                  workSessionMinutes: wsItem.workSessionMinutes,
                };
                return worksession;
              })
            : [];
        // Update calendar data
        successCallback(worksessionData);
      }
      // Disable loader
      this.setState({
        isLoading: false,
      });
    });
  };

  // ReFetch schedules data
  handleReFetchEvents = () => {
    this.setState((state) => ({
      ...state,
      isRefetchEvents: !state.isRefetchEvents,
    }));
  };

  handleUpdateWorkSession = (data) => {
    if (Date.parse(data.checkOutDate) <= Date.parse(data.checkInDate)) {
      toast.error('Check in time is greater or equal to check out time');
    } else {
      const params = {
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        workSessionId: data.scheduleId,
        workSessionDetails: {
          checkInDate: moment(data.checkInDate).format('YYYY-MM-DD HH:mm:ss'),
          checkOutDate: moment(data.checkOutDate).format('YYYY-MM-DD HH:mm:ss'),
          reason: data.reason,
          projectId: data.project,
        },
      };
      if (data.task && data.task !== null) {
        params.workSessionDetails.taskId = data.task;
      }

      // Enable loader
      this.setState((state) => ({
        ...state,
        isLoading: true,
      }));

      workSessionService
        .update_worksession(params)
        .then((response) => {
          if (response.status) {
            this.handleReFetchEvents();
            this.handleShowEditSessionModal(false);
            if (response.data._id) {
              toast.success('Worksession has been updated successfully');
            } else {
              toast.success(response.data);
            }
          }
        })
        .catch((error) => {
          // Disable loader
          this.setState((state) => ({
            ...state,
            isLoading: false,
          }));
          // Close modal window
          this.handleShowEditSessionModal(false);
          if (error.message === 'Validation error') {
            if (Array.isArray(error.data)) {
              error.data.map((item) => toast.error(item.msg));
            } else {
              toast.error(error.data);
            }
            // Refetch events list to update calendar view
            this.handleReFetchEvents();
          } else if (error.info) {
            toast.error(error.info);
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error(UPDATE_FAILURE_ERROR);
          }
        });
    }
  };

  handleAddWorkSession = (formdata) => {
    const memberId = getUserData('_id');
    if (Date.parse(formdata.checkOutDate) <= Date.parse(formdata.checkInDate)) {
      toast.error('Check in time is greater or equal to check out time');
    } else {
      const params = {
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        workSessionDetails: {
          projectId: formdata.project,
          teamMemberId: memberId,
          checkInDate: moment(formdata.checkInDate).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          checkOutDate: moment(formdata.checkOutDate).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          reason: formdata.reason || 'New session',
          workSessionType: 'Desktop',
        },
      };

      if (formdata.task && formdata.task !== '') {
        params.workSessionDetails.taskId = formdata.task;
      }

      // Enable loader
      this.setState((state) => ({
        ...state,
        isLoading: true,
      }));

      workSessionService
        .add_worksession(params)
        .then((response) => {
          if (response.status) {
            this.handleReFetchEvents();
            toast.success('Worksession added successfully');
            this.handleShowAddSessionModal(false);
            // track june
            trackJune('track on calendar');
          }
        })
        .catch((error) => {
          // Disable loader
          this.setState((state) => ({
            ...state,
            isLoading: false,
          }));
          // Close modal window
          this.handleShowAddSessionModal(false);
          if (error.message === 'Validation error') {
            if (Array.isArray(error.data)) {
              error.data.map((item) => toast.error(item.msg));
            } else {
              toast.error(error.data);
            }
          } else if (error.info) {
            toast.error(error.info);
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error(UPDATE_FAILURE_ERROR);
          }
        });
    }
  };

  render() {
    const {
      isLoading,
      startDate,
      endDate,
      showAddSessionModal,
      showEditSessionModal,
      isRefetchEvents,
    } = this.state;

    return (
      <Layout pageHeading={this.pageHeading}>
        {isLoading && <Loading contentAreaOnly />}
        <Row>
          <Col>
            <Calendar
              startDate={startDate}
              endDate={endDate}
              handleGetEvents={this.handleGetEvents}
              handleShowAddSessionModal={this.handleShowAddSessionModal}
              showAddSessionModal={showAddSessionModal}
              handleChangeDate={this.handleChangeDate}
              showEditSessionModal={showEditSessionModal}
              handleShowEditSessionModal={this.handleShowEditSessionModal}
              handleUpdateWorkSession={this.handleUpdateWorkSession}
              isRefetchEvents={isRefetchEvents}
              handleAddWorkSession={this.handleAddWorkSession}
              handleLoader={this.handleLoader}
            />
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  stepIndex: state.freeTrialTourReducer,
});

const mapDispatchToProps = () => ({
  startInitTour: startTour,
  endInitTour: endTour,
  fetchMemberInitProjects: fetchMemberProjects,
  setInitShowTimerWidget: setShowTimerWidget,
});

Tracking.propTypes = {
  fetchMemberInitProjects: PropTypes.func,
  endInitTour: PropTypes.any,
  startInitTour: PropTypes.any,
  stepIndex: PropTypes.any,
};

export default connect(mapStateToProps, mapDispatchToProps())(Tracking);
