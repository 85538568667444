import { useState, useRef } from 'react';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import uuid from 'react-uuid';
import { teamService } from '../../services';
import { LOCAL_STORAGE_USER_DATA, API_FAILURE_ERROR } from '../../constants';

function WorksessionReportModal({ userId }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setLoading] = useState(false);
  const userdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));

  const [workSessionData, setworkSessionData] = useState([]);
  const csvLink = useRef(); // setup the ref for the hidden CsvLink click after data fetch
  const getFileName = () => {
    const filename = `${uuid()}.csv`;
    return filename;
  };
  const getWorkSessionData = async (action) => {
    const params = {
      userId,
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      action,
    };
    setLoading(true);
    teamService
      .user_worksession_report(params)
      .then((response) => {
        setLoading(false);
        if (action === 'email' && response.message) {
          toast.success(response.message);
        } else if (response.data) {
          // update state, and click on the csv download link using the ref
          setworkSessionData(response.data);
          csvLink.current.link.click();
        }
      })
      .catch((errResponse) => {
        setLoading(false);
        if (errResponse.message) {
          toast.error(errResponse.message);
        } else {
          toast.error(API_FAILURE_ERROR);
        }
      });
  };

  return (
    <>
      <Dropdown.Item onClick={handleShow}>Worksession report</Dropdown.Item>
      <Modal show={show} onHide={handleClose} animation={false} size="md">
        <Modal.Header>
          <Modal.Title className="m-0">Worksession report</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>

        <section>
          <div className="modal-body">
            <div className="d-flex align-items-end mb-3">
              <div className="w-100">
                <span className="text-dark font-weight-600 text-sm">Email</span>
                <small className="d-block text-muted">
                  The report will be emailed to {userdata.email}
                </small>
              </div>
              <div className="text-right ml-auto">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    getWorkSessionData('email');
                  }}
                  disabled={isLoading}
                  style={{ minWidth: '76px' }}
                >
                  Email
                </Button>
              </div>
            </div>
            <div className="d-flex align-items-end mb-3">
              <div className="w-100">
                <span className="text-dark font-weight-600 text-sm">
                  Download
                </span>
                <small className="d-block text-muted">
                  The report will be automatically downloaded in CSV format
                </small>
              </div>
              <div className="text-right ml-auto">
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    getWorkSessionData('download');
                  }}
                  disabled={isLoading}
                >
                  Download
                </Button>
                <CSVLink
                  data={workSessionData}
                  filename={getFileName()}
                  className="hidden"
                  ref={csvLink}
                  separator=";"
                  target="_blank"
                />
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}
WorksessionReportModal.propTypes = {
  userId: PropTypes.string,
};
export default WorksessionReportModal;
