import React, { useState } from 'react';
import { Modal, Form, Spinner, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';
import { MemberStatus } from '../common';
import { projectService } from '../../services';
import Loading from '../Loader';

const MembersListModal = ({ btnSize, projectId }) => {
  const [show, setShow] = useState(false);
  const [searchParam, setSearchParam] = useState('');
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(true);
  const [projectMembers, setProjectMembers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const handleShow = () => {
    setShow(true);
    setIsLoadingMore(true);
  };
  const handleClose = () => {
    setShow(false);
    setPageNumber(1);
    setProjectMembers([]);
    setSearchParam('');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getProjectMembers = (pageN) => {
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      projectId,
      page: pageN || pageNumber,
      limit: 10,
      status: 'active',
      keyword: searchParam,
    };
    if (params.keyword.trim().length < 3) {
      delete params.keyword;
    }
    setIsLoadingMembers(true);
    projectService
      .get_project_members(params)
      .then((response) => {
        if (response.data.members) {
          const { page, total } = response.data.metadata;
          setProjectMembers(
            page === 1
              ? [...response.data.members]
              : [...projectMembers, ...response.data.members]
          );
          setIsLoadingMore(total / 10 >= page);
          setPageNumber(isLoadingMore ? page + 1 : page);
        }
        setTimeout(() => {
          setIsLoadingMembers(false);
        }, 0);
      })
      .catch(() => {
        setIsLoadingMembers(false);
        setIsLoadingMore(false);
      });
  };

  const handleSearch = () => {
    setPageNumber(1);
    getProjectMembers(1);
  };

  const dataFetcher = React.useRef(getProjectMembers);
  const observer = React.useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          dataFetcher.current();
        }
      },
      { threshold: 1 }
    )
  );
  const [element, setElement] = React.useState(null);
  // Update snapshot of getMembers function whenever page number changes.
  React.useEffect(() => {
    dataFetcher.current = getProjectMembers;
  }, [getProjectMembers]);

  // Observe for the load more spinner element
  React.useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <button
        className={
          btnSize === 'lg'
            ? 'btn btn-lg btn-rounded-circle btn-white lift'
            : 'btn btn-sm btn-rounded-circle btn-white lift'
        }
        onClick={handleShow}
        title="view all"
        type="button"
      >
        <i className="mdi mdi-dots-horizontal" />
      </button>

      <Modal show={show} onHide={handleClose} style={{ overflow: 'hidden' }}>
        <Modal.Header>
          <Modal.Title className="m-0">Members</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <div className="card-header pt-4 px-4">
          <Form onSubmit={(event) => event.preventDefault()}>
            <div className="input-group">
              <input
                type="text"
                value={searchParam}
                className="form-control list-search"
                onChange={(e) => setSearchParam(e.target.value)}
                placeholder="Search (Enter min 3 characters)"
                autoComplete="off"
                onKeyDown={handleKeyDown}
              />
              <Button variant="primary" onClick={handleSearch}>
                <span className="mdi mdi-magnify" />
              </Button>
            </div>
          </Form>
        </div>
        <Modal.Body className="pt-0 ">
          <section
            className="modal-scroll-content"
            style={{ minHeight: '350px' }}
          >
            <ul className="list-group list-group-flush list">
              {projectMembers.map((member, index) => (
                <li className="list-group-item" key={`${index}${member._id}`}>
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="avatar">
                        <Avatar
                          firstName={member.firstName}
                          lastName={member.lastName}
                          imgPath={member.avatar ? member.avatar : ''}
                        />
                      </div>
                    </div>
                    <div className="col ml-n2">
                      <h4 className="mb-1 names">
                        {member.lastName
                          ? `${member.firstName} ${member.lastName}`
                          : member.firstName}
                      </h4>
                      <MemberStatus memberId={member._id || member.userId} />
                    </div>
                  </div>
                </li>
              ))}
              {/* Empty search results */}
              {projectMembers.length === 0 && !isLoadingMembers && (
                <p className="pt-4 pb-3 text-muted m-0 text-center">
                  No members found
                </p>
              )}
              {/* Loading spinner overlay */}
              {isLoadingMembers && <Loading contentAreaOnly />}
              {/* Observing button to check if more data available to fetch */}
              {!isLoadingMembers && isLoadingMore && (
                <div className="d-flex justify-content-center py-5 mb-5">
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="text-muted"
                    ref={setElement}
                  />{' '}
                  <span>Loading...</span>
                </div>
              )}
            </ul>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

MembersListModal.propTypes = {
  btnSize: PropTypes.string,
  projectId: PropTypes.string,
};

export default MembersListModal;
