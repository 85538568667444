import { userService } from '../services';
import { updateLocalStorage, getUserData } from '../helpers';

export const setUserRole = (data) => ({
  type: 'SET_ROLE',
  payload: data,
});
export const setPrivacyAcceptanceStatus = (data) => ({
  type: 'ACCEPT_POLICY',
  payload: data,
});
export const setSubscription = (data) => ({
  type: 'SET_SUBSCRIPTION',
  payload: data,
});

export const setOnboardingProgressForTrackedWorkSession = () => ({
  type: 'SET_ONBOARD_PROGRESS',
  payload: { workSession: true, project: true },
});
export const fetchOnBoardProgress = () => async (dispatch) => {
  try {
    const response = await userService.onboarding_progress();
    if (response.status) {
      dispatch({ type: 'SET_ONBOARD_PROGRESS', payload: response.data });
    }
  } catch (error) {
    // Do nothing
  }
};

export const fetchCardStatus = () => async (dispatch) => {
  const response = await userService.card_status();
  if (response.status) {
    dispatch({ type: 'SET_CARD_EXPIRY', payload: response.data });
  }
};

export const fetchAccountDetails = () => async (dispatch) => {
  const response = await userService.get_account_details({
    teamOwnerId: localStorage.getItem('teamOwnerId')
      ? localStorage.getItem('teamOwnerId')
      : getUserData('_id'),
  });
  if (response.data) {
    updateLocalStorage({
      accountOwnerEmail: response.data.email,
      accountPlan: response.data.subscriptionDetails.currentPlan,
      activeMembers: response.data.activeMembers,
      maxMembersAllowed: response.data.maxMembersAllowed,
      isMobileTracking: response.data.isMobileTracking,
      isTimesheetApproval: response.data.isTimesheetApproval,
      isSchedulingEnabled: response.data.isSchedulingEnabled,
      isInvoiceEnabled: response.data.isInvoiceEnabled,
      mailOnTaskCreation: response.data.mailOnTaskCreation,
      isSessionMgmtEnabled: response.data.isSessionMgmtEnabled,
    });
    if (response.data.isTimesheetApproval) {
      dispatch({ type: 'ENABLE_TS_APPROVAL' });
    } else {
      dispatch({ type: 'DISABLE_TS_APPROVAL' });
    }
    if (response.data.isOverTimeEnabled) {
      dispatch({ type: 'ENABLE_OT' });
    } else {
      dispatch({ type: 'DISABLE_OT' });
    }
    if (response.data.isSchedulingEnabled) {
      dispatch({ type: 'ENABLE_SCHEDULE' });
    } else {
      dispatch({ type: 'DISABLE_SCHEDULE' });
    }
    if (response.data.isInvoiceEnabled) {
      dispatch({ type: 'ENABLE_INVOICE' });
    } else {
      dispatch({ type: 'DISABLE_INVOICE' });
    }
    if (response.data.isEmailNotificationEnabled) {
      dispatch({ type: 'ENABLE_TASK_NOTIFICATION' });
    } else {
      dispatch({ type: 'DISABLE_TASK_NOTIFICATION' });
    }
    if (response.data.isSessionMgmtEnabled) {
      dispatch({ type: 'ENABLE_MANAGE_ACTIVE_SESSIONS' });
    } else {
      dispatch({ type: 'DISABLE_MANAGE_ACTIVE_SESSIONS' });
    }
  }
};

export const setFreeTrialStatusFalse = () => ({
  type: 'SET_FREE_TRIAL_STATUS_FALSE',
  payload: { freeTrialStatus: false },
});
