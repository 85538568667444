import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';

const QuickAdd = ({ role }) => {
  const isInvoiceEnabled = useSelector((state) => state.invoiceEnabled);
  const QUICKADDLIST = [
    {
      icon: 'mdi-folder-open',
      name: 'Create a project',
      redirectlink: '/projects/create',
      status: true,
      showPermission: ['creator', 'full_manager', 'manager'],
    },
    {
      icon: 'mdi-account-group-outline',
      name: 'Add team members',
      redirectlink: '/team/active',
      status: true,
      showPermission: ['creator', 'full_manager', 'manager'],
    },
    {
      icon: 'mdi-folder-clock-outline',
      name: 'Get timesheet reports',
      redirectlink: '/timesheet/detailed',
      status: true,
      showPermission: ['creator', 'full_manager', 'manager'],
    },
    {
      icon: 'mdi-fullscreen',
      name: 'Get screenshot reports',
      redirectlink: '/reports/screenshot',
      status: true,
      showPermission: ['creator', 'full_manager', 'manager'],
    },
    {
      icon: 'mdi-coin',
      name: 'Add employee cost rates',
      redirectlink: '/team/active',
      status: true,
      showPermission: ['creator', 'full_manager', 'manager'],
    },
    {
      icon: 'mdi-file-document-box',
      name: 'Create an invoice',
      redirectlink: '/invoices',
      status: isInvoiceEnabled || false,
      showPermission: ['creator'],
    },
  ];
  return (
    <Link
      className="btn text-white font-size-lg"
      to="#"
      style={{ padding: '0px' }}
    >
      <Dropdown>
        <Dropdown.Toggle
          className="dropdown-ellipses text-white"
          data-bs-toggle="button"
        >
          <Tippy content="Quick Add" theme="light">
            <i className="mdi mdi-plus-circle text-white" />
          </Tippy>
        </Dropdown.Toggle>
        <div>
          <Dropdown.Menu
            align="left"
            className="quick-add-tooltip"
            style={{
              minWidth: '245px',
              maxWidth: '245px',
              border: '1px solid rgba(0,0,0,.15)',
              boxShadow: '0 6px 12px rgba(0, 0, 0, .175)',
            }}
            popperConfig={{
              show: true,
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 18],
                  },
                },
                {
                  name: 'arrow',
                  show: true,
                  enabled: true,
                  options: {
                    element: '.arrow',
                  },
                },
              ],
            }}
          >
            {QUICKADDLIST.map(
              (item, index) =>
                item.status &&
                item.showPermission.includes(role) && (
                  <Dropdown.Item
                    key={index}
                    as={Link}
                    to={{
                      pathname: item.redirectlink,
                      ...(item.name === 'Add team members'
                        ? { state: { action: 'showInviteModal' } }
                        : {}),
                    }}
                    className="quick-add-dropdown-item"
                  >
                    <div className="row">
                      <div className="col-2">
                        <i
                          className={`quick-add-item-icon-color mdi ${item.icon}`}
                        />
                      </div>
                      <div className="col ps-0 pt-1">
                        <span className="ms-1">{item.name}</span>
                      </div>
                    </div>
                  </Dropdown.Item>
                )
            )}
          </Dropdown.Menu>
        </div>
      </Dropdown>
    </Link>
  );
};

QuickAdd.propTypes = {
  role: PropTypes.string,
};

export default QuickAdd;
