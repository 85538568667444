import { useState } from 'react';
import { Modal, Dropdown, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  activateLoader,
  deactivateLoader,
  deactivateMember,
} from '../../actions/team';
import { teamService } from '../../services';
import { TEAM, API_FAILURE_ERROR } from '../../constants';

function DeactivateMemberModal({ userId }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // deactivate member
  const handleStatusUpdate = (userStatus, reason) => {
    dispatch(activateLoader());
    const params = {
      userId,
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      status: userStatus,
      reason,
    };
    teamService
      .update_user_status(params)
      .then((response) => {
        if (response.data) {
          dispatch(deactivateMember(response.data));
          toast.success(TEAM.STATUS_UPDATE_SUCCESS);
          handleClose();
        }
        dispatch(deactivateLoader());
      })
      .catch((errResponse) => {
        dispatch(deactivateLoader());
        if ('message' in errResponse) {
          toast.error(errResponse.message);
        } else {
          toast.error(API_FAILURE_ERROR);
        }
      });
  };

  return (
    <>
      <Dropdown.Item onClick={handleShow}>Deactivate Team Member</Dropdown.Item>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="md"
        centered
      >
        <Modal.Header>
          <Modal.Title className="m-0">Deactivate Team Member</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Formik
          initialValues={{
            deactivateReason: '',
          }}
          validationSchema={Yup.object({
            deactivateReason: Yup.string()
              .trim()
              .required('Please enter reason for deactivation'),
          })}
          onSubmit={(values) => {
            handleStatusUpdate('inactive', values.deactivateReason);
          }}
        >
          {(formikprops) => (
            <Form
              onSubmit={formikprops.handleSubmit}
              className="needs-validation"
            >
              <section>
                <div id="load_members">
                  <div className="modal-body">
                    <p className="text-muted text-sm">
                      Deactivating this Team Member will cause them to be unable
                      to log time on any of their Projects. They can be
                      reactivated at any point in the future.
                    </p>
                    <div className="form-group">
                      <Form.Label className="form-control-label">
                        Reason for deactivation
                      </Form.Label>
                      <Field
                        name="deactivateReason"
                        type="text"
                        className="form-control"
                        as={Form.Control}
                        isInvalid={
                          !!formikprops.touched.deactivateReason &&
                          !!formikprops.errors.deactivateReason
                        }
                        isValid={
                          !!formikprops.touched.currentPassword &&
                          !formikprops.errors.deactivateReason
                        }
                      />
                      <ErrorMessage
                        name="deactivateReason"
                        render={(msg) => (
                          <small className="text-danger">{msg}</small>
                        )}
                      />
                    </div>
                  </div>
                  <div className="modal-footer d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-link"
                      data-dismiss="modal"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-warning  ml-auto"
                      name="btnDeactivateMemberCard"
                    >
                      Deactivate
                    </button>
                  </div>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
DeactivateMemberModal.propTypes = {
  userId: PropTypes.string,
};
export default DeactivateMemberModal;
