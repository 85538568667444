const initialState = {
  isLoading: false,
  tasks: [],
  metadata: {},
  error: null,
};

export const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ENABLE_TASKS_SPINNER':
      return {
        ...state,
        isLoading: true,
      };
    case 'DISABLE_TASKS_SPINNER':
      return {
        ...state,
        isLoading: false,
      };
    case 'UPDATING_TASKS':
      return {
        ...state,
        isUpdating: true,
      };
    case 'SET_TASKS':
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        tasks: action.payload.taskDetails,
        metadata: action.payload.metadata,
      };
    case 'FETCH_TASKS_ERROR':
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        error: action.error,
      };
    case 'CURRENT_TASK_STATUS':
      return {
        ...state,
        selectedTaskStatus: action.payload,
      };
    default:
      return state;
  }
};
