import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { WorksessionReportModal } from '.';

const InactiveTeamKababMenu = ({ userId }) => (
  <Dropdown>
    <Dropdown.Toggle
      as={Link}
      to={{ hash: '#' }}
      className="project-kabab-menu dropdown-ellipses dropdown-toggle"
    >
      <i className="mdi mdi-dots-horizontal" />
    </Dropdown.Toggle>

    <Dropdown.Menu align="left">
      <WorksessionReportModal userId={userId} />
    </Dropdown.Menu>
  </Dropdown>
);
InactiveTeamKababMenu.propTypes = {
  userId: PropTypes.string,
};

export default InactiveTeamKababMenu;
