import { BASE_API_URL, NOTIFICATION_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';
import { getTeamId, getUserData } from '../helpers/functions';

const options = AppService.options();

export const notificationService = {
  get_notification_list: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const userId = `&userId=${getUserData('_id')}`;
    const response = await AppService.makeRequest(
      `${
        BASE_API_URL + NOTIFICATION_URLS.NOTIFICATION_LIST
      }?teamOwnerId=${getTeamId('teamOwnerId')}&type=${data.usertype}${
        data.usertype === 'member' ? userId : ''
      }`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_notification_status: async (data) => {
    const reqData = {
      teamOwnerId: getTeamId('teamOwnerId'),
      notificationIds: data,
    };
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + NOTIFICATION_URLS.NOTIFICATION_READ,
      {
        ...options,
        ...headers,
        body: JSON.stringify(reqData),
      }
    );
    return response;
  },
};
