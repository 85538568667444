import moment from 'moment';
import XLSX from 'sheetjs-style';
import { sToHMSColonSeparated, groupBy } from '../../helpers';
import { HELPER_TEXT_BILLABLE_BREAKS } from '../../constants';

const ApprovalTimecardExcel = (timesheetDetails) => {
  let ws = [];
  let user = '';
  const wb = XLSX.utils.book_new();
  const excelRows = [];
  let subHeading = '';
  const weeklyStatus = (status) => {
    switch (status) {
      case 'submit':
        return 'Submitted';
      case 'approve':
        return 'Approved';
      case 'reject':
        return 'Rejected';
      case 'withdraw':
        return 'Withdrawn';
      default:
        return 'Not Submitted';
    }
  };

  const calculateTotalBreakTime = (jsonData, key) => {
    // Check if the key exists in jsonData
    // eslint-disable-next-line no-prototype-builtins
    if (!jsonData.hasOwnProperty(key)) {
      return '-'; // If the key doesn't exist, return 0
    }

    // Get the array associated with the key
    const entries = jsonData[key];

    const allBreaks = entries.map(
      (entry) =>
        `${sToHMSColonSeparated(entry.breakTime / 1000, 'hh:mm:ss')}\n ${moment(
          entry.startTime
        ).format('hh:mm:ss A')} - ${moment(entry.endTime).format('hh:mm:ss A')}

        `
    );
    return allBreaks.join(' ');
  };

  const breakTypes = timesheetDetails
    .flatMap((item) =>
      item.data.flatMap((day) => {
        if (day.workSessions.length > 0 && day.totalBreakTimeSeconds > 0) {
          return day.workSessions
            .filter((dItem) => dItem.breakTimeSeconds > 0)
            .map((dItem) => dItem.break); // Collecting dItem.break directly
        }
        return [];
      })
    )
    .flat();

  const breakTypeGroups = groupBy(breakTypes, 'type');
  let breakTypeHeaders = [];
  let breakTypeColumnHeaders = [];
  timesheetDetails.forEach((weekData) => {
    breakTypeHeaders = Object.values(breakTypeGroups).map((bItem) =>
      bItem[0].type && bItem[0].breakName
        ? { breakName: bItem[0].breakName, type: bItem[0].type }
        : { breakName: 'Other', type: 'Other' }
    );
    breakTypeColumnHeaders = Object.entries(breakTypeGroups).map(
      ([key, value]) =>
        value[0].type && value[0].breakName
          ? `${value[0].breakName} ${value[0].billable ? ' *' : ''}`
          : 'Other'
    );

    weekData.data.forEach((day) => {
      let breakTypesList = [];
      // eslint-disable-next-line no-unused-vars
      const sessionBreakTypes =
        day.workSessions.length > 0 && day.totalBreakTimeSeconds > 0
          ? day.workSessions.map((item) =>
              item.breakTimeSeconds > 0
                ? (breakTypesList = [...breakTypesList, ...item.break])
                : null
            )
          : [];

      const dayBreakTypes = groupBy(breakTypesList, 'type');

      const breakTimeLogs = {};
      breakTypeHeaders.forEach((breakItem, index) => {
        breakTimeLogs[`break-${index}`] = calculateTotalBreakTime(
          dayBreakTypes,
          breakItem.type === 'Other' ? 'null' : breakItem.type
        );
      });

      // For admin timesheet excel
      if (weekData.user) {
        subHeading = [
          'Team member',
          'Week Days',
          'Clock In',
          'Clock Out',
          'Total Duration',
          'Productive',
          'Total Breaks',
          ...breakTypeColumnHeaders,
          'Status',
        ];
        user = {
          name: weekData.user.firstName,
          day: `${moment(day.date).format('ddd, MMM DD')} ${
            day.schedule && day.schedule.isHoliday ? ' (Holiday)' : ''
          }`,
          clockIn: day.clockIn ? moment(day.clockIn).format('hh:mm:ss A') : '-',
          clockOut: day.clockOut
            ? moment(day.clockOut).format('hh:mm:ss A')
            : '-',
          Total:
            day.totalWorkSessionSeconds > 0
              ? sToHMSColonSeparated(day.totalWorkSessionSeconds, 'hh:mm:ss')
              : '00:00:00',
          Productive:
            day.totalWorkSessionSeconds > 0
              ? sToHMSColonSeparated(
                  day.totalWorkSessionSeconds -
                    day.totalNonBillableBreakTimeSeconds,
                  'hh:mm:ss'
                )
              : '00:00:00',
          breakTime:
            day.totalBreakTimeSeconds > 0
              ? sToHMSColonSeparated(day.totalBreakTimeSeconds, 'hh:mm:ss')
              : '00:00:00',
          ...breakTimeLogs,
          status: weeklyStatus(weekData.status),
        };
        // For teammember timesheet excel
      } else {
        subHeading = [
          'Week Days',
          'Clock In',
          'Clock Out',
          'Total Duration',
          'Total Breaks',
          ...breakTypeColumnHeaders,
          'Productive',
          'Status',
        ];
        user = {
          day: `${moment(day.date).format('ddd, MMM DD')} ${
            day.schedule && day.schedule.isHoliday ? ' (Holiday)' : ''
          }`,
          clockIn: day.clockIn ? moment(day.clockIn).format('hh:mm:ss A') : '-',
          clockOut: day.clockOut
            ? moment(day.clockOut).format('hh:mm:ss A')
            : '-',
          Total:
            day.totalWorkSessionSeconds > 0
              ? sToHMSColonSeparated(day.totalWorkSessionSeconds, 'hh:mm:ss')
              : '00:00:00',
          breakTime:
            day.totalBreakTimeSeconds > 0
              ? sToHMSColonSeparated(day.totalBreakTimeSeconds, 'hh:mm:ss')
              : '00:00:00',
          ...breakTimeLogs,
          Productive:
            day.totalWorkSessionSeconds > 0
              ? sToHMSColonSeparated(
                  day.totalWorkSessionSeconds -
                    day.totalNonBillableBreakTimeSeconds,
                  'hh:mm:ss'
                )
              : '00:00:00',
          status: weeklyStatus(weekData.status),
        };
      }
      excelRows.push(user);
    });
    ws = XLSX.utils.json_to_sheet(excelRows, {
      skipHeader: true,
      origin: 'A3',
    });

    // const mainHeading = ['Timecard'];
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        // mainHeading,
        [HELPER_TEXT_BILLABLE_BREAKS],
        subHeading,
      ],
      {
        skipHeader: true,
        origin: 'A1',
      }
    );

    // Get the range of the sheet
    const range = XLSX.utils.decode_range(ws['!ref']);

    // Apply bold font to the entire second row
    for (let C = 0; C < 26; C += 1) {
      // Loop through columns A to Z
      const cellAddress = { c: C, r: 2 }; // Row index 1 for the second row (0-indexed)
      const cellRef = XLSX.utils.encode_cell(cellAddress);

      if (!ws[cellRef]) ws[cellRef] = { t: 's', v: '' }; // Ensure the cell exists

      ws[cellRef].s = {
        font: {
          bold: true,
        },
        fill: {
          fgColor: { rgb: 'dddddd' },
        },
      };
    }

    // Set a minimum width of 100 pixels for all columns
    const totalColumns = range.e.c + 1;
    ws['!cols'] = Array(totalColumns).fill({ wpx: 80 });
  });

  XLSX.utils.book_append_sheet(wb, ws, 'Timecard');
  XLSX.writeFile(wb, 'timesheet_report.xlsx');
};

export default ApprovalTimecardExcel;
