import PropTypes from 'prop-types';
import moment from 'moment';
import { msToHMS } from '../../helpers';
import { NO_DATA } from '../../constants';

const ProjectWorkSessionEditRequests = ({ worksessionList }) => {
  const requestStatus = {
    pending: 'Pending',
    approve: 'Approved',
    reject: 'Rejected',
  };
  return (
    <div className="table-responsive">
      {worksessionList.length > 0 ? (
        <table className="table table-sm table-hover card-table mt-1">
          <thead>
            <tr>
              <th>Team Member</th>
              <th>Original Worksession</th>
              <th>Updated Worksession</th>
              <th>Updated Remark</th>
              <th>Status</th>
              <th>Reason</th>
              <th>Requested By</th>
              <th>Updated By</th>
            </tr>
          </thead>
          <tbody>
            {worksessionList.map((request, index) => (
              <tr key={index}>
                <td>
                  {`${request.user.firstName} ${request.user.lastName || ''}`}
                </td>
                <td>
                  Duration: {msToHMS(request.oldWorkSessionSeconds * 1000)}
                  <br />
                  Check-in: {moment(request.oldCheckInDate).format('lll')}
                  <br />
                  Check-out: {moment(request.oldCheckOutDate).format('lll')}
                </td>
                <td>
                  Duration: {msToHMS(request.newWorkSessionSeconds * 1000)}
                  <br />
                  Check-in: {moment(request.newCheckInDate).format('lll')}
                  <br />
                  Check-out: {moment(request.newCheckOutDate).format('lll')}
                </td>
                <td>{request.updateRemark ? request.updateRemark : ' - '}</td>
                <td>{requestStatus[request.status]} </td>
                <td>{request.reason}</td>
                <td>{`${request.requestedBy.firstName} ${
                  request.requestedBy.lastName || ''
                }`}</td>
                <td>
                  {request.updatedBy
                    ? `${request.updatedBy.firstName} ${
                        request.updatedBy.lastName || ''
                      }`
                    : ' - '}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="m-4">{NO_DATA.WS_CHANGE_REQUESTS}</p>
      )}
    </div>
  );
};

ProjectWorkSessionEditRequests.propTypes = {
  worksessionList: PropTypes.array,
};

export default ProjectWorkSessionEditRequests;
