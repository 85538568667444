import { Link, useLocation } from 'react-router-dom';
import { Dropdown, Badge } from 'react-bootstrap';
import { userService } from '../services';
import Avatar from './Avatar';
import Logout from './Logout';
import { WithConfirmation } from './common';
import {
  LOCAL_STORAGE_USER_DATA,
  CONFIRM_LOGOUT_MODAL_TITLE,
} from '../constants';

const MyAccountDropdown = () => {
  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  const { firstName, email, lastName, avatar, defaultTeam } = userData || {};
  const fullName =
    lastName !== null ? `${firstName} ${lastName}` : `${firstName}`;
  // HOC - Confirmation modal
  const LogoutWithConfirmation = WithConfirmation(Logout);
  const logoutModalProps = {
    title: CONFIRM_LOGOUT_MODAL_TITLE,
    action: userService.logout,
    actionBtnText: 'Yes, logout',
    btnVariant: 'primary',
  };
  const location = useLocation();
  const browserUrl = location.pathname;
  return (
    <Dropdown>
      <Dropdown.Toggle
        as={Link}
        id="dropdown-basic"
        className="avatar avatar-sm"
        to={{ hash: '#' }}
      >
        {userData && (
          <Avatar
            firstName={firstName}
            lastName={lastName}
            defaultBg="#b1c2d9"
            imgPath={avatar || ''}
          />
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-right my-account-dropdown">
        {userData && (
          <>
            <div className="text-center px-3 pt-2">
              <h5 className="text-overflow mb-0">{fullName}</h5>
              <small className="d-block text-muted">{email}</small>
              {defaultTeam && defaultTeam.role && (
                <Badge
                  variant="light"
                  bg="info"
                  className="badge bg-primary-soft text-capitalize ms-3"
                >
                  {defaultTeam.role === 'member' && 'Member'}
                  {defaultTeam.role === 'creator' && 'Administrator'}
                  {defaultTeam.role === 'full_manager' && 'Full Manager'}
                  {defaultTeam.role === 'manager' && 'Basic Manager'}
                </Badge>
              )}
            </div>
            <Dropdown.Divider />
          </>
        )}
        {!browserUrl.includes('team-selection') && (
          <Dropdown.Item href="/settings/profile">
            <i className="mdi mdi-shield-account-outline" />{' '}
            <span>My profile</span>
          </Dropdown.Item>
        )}
        <LogoutWithConfirmation modalProps={logoutModalProps} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MyAccountDropdown;
