import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';

const ThumbsUpDown = ({ handleChangeSelect }) => {
  const [isSelected, selectedValue] = useState(null);

  return (
    <div className="row mb-4 pe-0">
      <Formik
        initialValues={{
          feedback: '',
        }}
        onSubmit={(values) => {
          const valuesSubmit = { ...values, answer: isSelected };
          handleChangeSelect(valuesSubmit);
        }}
      >
        {(formikprops) => (
          <Form
            onSubmit={formikprops.handleSubmit}
            className="needs-validation"
          >
            <Form.Group controlId="formEmail">
              <Field
                className="form-control"
                type="textarea"
                as="textarea"
                placeholder="Enter your feedback"
                name="feedback"
              />

              <ErrorMessage
                name="feedback"
                render={(msg) => <small className="text-danger">{msg}</small>}
              />
            </Form.Group>

            <div className="col">
              <div
                className="d-flex flex-wrap flex-md-nowrap justify-content-center btn-group-toggle"
                data-toggle="buttons"
              >
                <Button
                  type="submit"
                  style={{
                    minWidth: '60px',
                    minHeight: '60px',
                    fontSize: '27px',
                    background: '#fff',
                  }}
                  className="btn btn-light btn-outline-primary rounded-circle m-3 survey-question-answer-btn-change align-middle"
                  onClick={() => {
                    selectedValue('like');
                  }}
                >
                  <i
                    className={`mdi ${
                      isSelected === 'like'
                        ? 'mdi-thumb-up'
                        : 'mdi-thumb-up-outline'
                    } h1 text-primary`}
                  />
                </Button>

                <Button
                  type="submit"
                  style={{
                    minWidth: '60px',
                    minHeight: '60px',
                    fontSize: '27px',
                    background: '#fff',
                  }}
                  className="btn btn-light btn-outline-primary rounded-circle m-3 survey-question-answer-btn-change align-middle"
                  onClick={() => {
                    selectedValue('dislike');
                  }}
                >
                  <i
                    className={`mdi ${
                      isSelected === 'dislike'
                        ? 'mdi-thumb-down'
                        : 'mdi-thumb-down-outline'
                    } h1 text-primary`}
                  />
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

ThumbsUpDown.propTypes = {
  handleChangeSelect: PropTypes.func,
};

export default ThumbsUpDown;
