import { workSessionService } from '../services';

export const fetchWsRequests = (params) => async (dispatch) => {
  dispatch({ type: 'FETCHING_WS_REQUESTS' });
  try {
    const response = await workSessionService.get_time_edit_requests(params);
    if (response.data) {
      dispatch({ type: 'SET_WS_REQUESTS', payload: response.data });
    }
  } catch {
    dispatch({ type: 'FETCH_WS_REQUESTS_COMPLETE' });
  }
};

export const fetchDataStart = () => ({
  type: 'FETCHING_WS_REQUESTS',
});

export const fetchDataCompleted = () => ({
  type: 'FETCH_WS_REQUESTS_COMPLETE',
});
