import { projectService } from '../services';
import { LOCAL_STORAGE_TEAM_OWNER } from '../constants';
import { getUserData } from '../helpers';

export const fetchActiveProjects = (data) => async (dispatch) => {
  dispatch({ type: 'FETCHING_PROJECTS' });
  try {
    const params = {
      teamOwnerId: localStorage.getItem(LOCAL_STORAGE_TEAM_OWNER),
      summaryList: data.summaryList ? data.summaryList : false,
      filter: JSON.stringify({
        projectStatus: 'active',
      }),
    };
    const response = await projectService.get_projects_data(params);
    if (response.status) {
      dispatch({ type: 'SET_PROJECTS', payload: response.data });
    }
  } catch {
    dispatch({ type: 'FETCH_PROJECTS_ERROR' });
  }
};

export const fetchMemberProjects = () => async (dispatch) => {
  dispatch({ type: 'FETCHING_PROJECTS' });
  const memberId = getUserData('_id');
  try {
    const params = {
      teamOwnerId: localStorage.getItem(LOCAL_STORAGE_TEAM_OWNER),
      summaryList: true,
      filter: JSON.stringify({
        projectStatus: 'active',
        teamMembers: [memberId],
      }),
    };
    const response = await projectService.get_projects_data(params);
    if (response.status) {
      dispatch({ type: 'SET_MEMBER_PROJECTS', payload: response.data });
    }
  } catch {
    dispatch({ type: 'FETCH_PROJECTS_ERROR' });
  }
};

export const fetchProjectsList = (data) => async (dispatch) => {
  dispatch({ type: 'FETCH_PROJECTS_LIST' });
  try {
    const response = await projectService.get_projects_data(data);
    if (response.data.projects) {
      dispatch({ type: 'SET_PROJECTS_LIST', payload: response.data });
    } else {
      dispatch({ type: 'NO_PROJECTS' });
    }
  } catch {
    dispatch({ type: 'FETCH_PROJECTS_LIST_ERROR' });
  }
};

export const resetProjectsList = () => ({
  type: 'RESET_PROJECTS_LIST',
});

export const archiveProject = (data) => ({
  type: 'ARCHIVE_PROJECT',
  payload: data,
});

export const setProjectSearchParam = (data) => ({
  type: 'SET_PROJECT_SEARCH_PARAM',
  payload: data,
});

export const fetchProjectDetail = (params) => async (dispatch) => {
  try {
    const response = await projectService.get_projects_details(params);
    if (response.status) {
      dispatch({ type: 'SELECTED_PROJECT', payload: response.data });
    }
  } catch {
    dispatch({ type: 'FETCH_PROJECT_ERROR' });
  }
};

export const removeSelectedProduct = () => ({
  type: 'REMOVE_SELECTED_PROJECT',
});
