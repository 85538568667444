import { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { Formik, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Flatpickr from 'react-flatpickr';
import { workSessionService } from '../../services';
import { UPDATE_FAILURE_ERROR } from '../../constants';
import { DateAndTimePickerField } from '../common';

const ProjectEditWorkSessionModal = ({
  wsItem,
  projectsList,
  handleUpdateWsData,
  editSessionAccess,
  allBreaksList,
  handleGetAllBreakTypes,
}) => {
  const { user, project: selectedProject } = wsItem;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [checkInDate, setCheckInDate] = useState(wsItem.checkInDate);
  const [checkOutDate, setCheckOutDate] = useState(wsItem.checkOutDate);
  const checkInDateISOformat = new Date(wsItem.checkInDate).toISOString();
  const checkOutDateISOformat = new Date(wsItem.checkOutDate).toISOString();
  const [sessionBreaks, setSessionBreaks] = useState(
    wsItem.break.map((item) => {
      const breakDetails = {
        type: item.type !== null ? item.type._id : 'other',
        startTime: moment(item.startTime).format('YYYY-MM-DD HH:mm:ss'),
        endTime: item.endTime
          ? moment(item.endTime).format('YYYY-MM-DD HH:mm:ss')
          : '',
      };
      return breakDetails;
    })
  );

  const memberProjects =
    projectsList && projectsList.length > 0
      ? projectsList.filter(
          (item) =>
            item._id !== selectedProject._id &&
            item.members.some((member) => user._id === member._id)
        )
      : [];
  const userDetails = useSelector((state) => state.userReducer);

  useEffect(() => {
    setCheckInDate(wsItem.checkInDate);
    setCheckOutDate(wsItem.checkOutDate);
    setSessionBreaks(
      wsItem.break.map((item) => {
        const breakDetails = {
          type: item.type !== null ? item.type._id : 'other',
          startTime: moment(item.startTime).format('YYYY-MM-DD HH:mm:ss'),
          endTime: item.endTime
            ? moment(item.endTime).format('YYYY-MM-DD HH:mm:ss')
            : '',
        };
        return breakDetails;
      })
    );
  }, [wsItem]);

  const handleUpdateWorkSession = (formdata) => {
    if (Date.parse(checkOutDate) <= Date.parse(checkInDate)) {
      toast.error('Check in time is greater or equal to check out time');
    } else {
      setSubmitting(true);
      const params = {
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        workSessionId: wsItem._id,
        workSessionDetails: {
          checkInDate: moment(checkInDate).format('YYYY-MM-DD HH:mm:ss'),
          checkOutDate: moment(checkOutDate).format('YYYY-MM-DD HH:mm:ss'),
          reason: formdata.reason,
          projectId: formdata.project,
        },
      };

      if (formdata.breaks && formdata.breaks.length > 0) {
        params.workSessionDetails.break = formdata.breaks.map((wsBreak) => {
          const selectedBreak = {
            type: wsBreak.type === 'other' ? null : wsBreak.type,
            startTime: wsBreak.startTime,
            endTime: wsBreak.endTime,
          };
          return selectedBreak;
        });
      }

      workSessionService
        .update_worksession(params)
        .then((response) => {
          if (response.data) {
            handleUpdateWsData();
            setSubmitting(false);
            if (response.data._id) {
              toast.success('Worksession has been updated successfully');
            } else {
              toast.success(response.data);
            }
            handleClose();
          }
        })
        .catch((error) => {
          if (error.message === 'Validation error') {
            if (Array.isArray(error.data)) {
              error.data.map((item) => toast.error(item.msg));
            } else {
              toast.error(error.data);
            }
          } else if (error.message) {
            toast.error(error.message);
          } else {
            toast.error(UPDATE_FAILURE_ERROR);
          }
          setSubmitting(false);
        });
    }
  };

  return (
    <>
      <Button variant="light" size="sm" onClick={handleShow}>
        {editSessionAccess ? 'Edit Worksession' : 'Edit Worksession Request'}
      </Button>

      <Modal
        show={show}
        onHide={() => {
          handleClose();
          setSessionBreaks([]);
        }}
        centered
      >
        <Modal.Header>
          <Modal.Title className="m-0">
            {editSessionAccess
              ? 'Edit Worksession'
              : 'Edit Worksession Request'}
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Formik
          initialValues={{
            checkInDate,
            checkOutDate,
            reason:
              wsItem.manualEntryReason !== null ? wsItem.manualEntryReason : '',
            project: selectedProject._id,
            breaks: sessionBreaks,
          }}
          validationSchema={Yup.object({
            checkInDate: Yup.date().required(
              'Please enter check in information'
            ),
            checkOutDate: Yup.date().required(
              'Please enter check out information'
            ),
            reason: Yup.string().required(
              'Please enter a reason for your worksession'
            ),
          }).shape({
            breaks: Yup.array().of(
              Yup.object().shape({
                type: Yup.string().required('Please select a break').nullable(),
                startTime: Yup.date().required('Required'),
                endTime: Yup.date().required('Required'),
              })
            ),
          })}
          onSubmit={(values) => {
            handleUpdateWorkSession(values);
          }}
        >
          {(formikprops) => (
            <Form onSubmit={formikprops.handleSubmit}>
              <Modal.Body>
                <section>
                  <Form.Group controlId="memberName">
                    <Form.Label>Team Member </Form.Label>
                    <h3>{`${user.firstName} ${user.lastName || ''}`}</h3>
                  </Form.Group>

                  <Row>
                    <Col>
                      <DateAndTimePickerField
                        label="Check In"
                        handleDateSelect={setCheckInDate}
                        name="checkInDate"
                        defaultValue={checkInDateISOformat}
                        defaultValueDisplay
                      />
                    </Col>
                    <Col>
                      <DateAndTimePickerField
                        label="Check Out"
                        handleDateSelect={setCheckOutDate}
                        name="checkOutDate"
                        defaultValue={checkOutDateISOformat}
                        defaultValueDisplay
                      />
                    </Col>
                  </Row>
                  <Form.Group controlId="Project">
                    <Form.Label>
                      Project <span className="text-danger">*</span>
                    </Form.Label>
                    <Field
                      className="form-select"
                      as="select"
                      name="project"
                      disabled={userDetails.role !== 'creator'}
                      onChange={(e) => {
                        formikprops.setFieldValue('project', e.target.value);
                        handleGetAllBreakTypes(e.target.value);
                        const updatedBreaks =
                          formikprops.values.breaks.length > 0
                            ? formikprops.values.breaks.map((breakItem) => {
                                const updatedBreak = {
                                  type: '',
                                  startTime: breakItem.startTime,
                                  endTime: breakItem.endTime,
                                };
                                return updatedBreak;
                              })
                            : [];
                        formikprops.setFieldValue(`breaks`, updatedBreaks);
                      }}
                    >
                      <option value={selectedProject._id}>
                        {selectedProject.title}
                      </option>
                      {memberProjects.length > 0 &&
                        memberProjects.map((project, index) => (
                          <option key={index} value={project._id}>
                            {project.title}
                          </option>
                        ))}
                    </Field>
                  </Form.Group>
                  <h4 className="my-3">Manage Breaks</h4>
                  {formikprops.values.breaks &&
                    formikprops.values.breaks.length > 0 && (
                      <Row className="mb-2">
                        <Col md={5}>Break Type</Col>
                        <Col md={3}>Start Time</Col>
                        <Col md={3}>End Time</Col>
                        <Col md={1} />
                      </Row>
                    )}

                  <FieldArray
                    name="breaks"
                    render={(arrayHelpers) => (
                      <div>
                        {formikprops.values.breaks &&
                          formikprops.values.breaks.length > 0 &&
                          formikprops.values.breaks.map((breakItem, index) => (
                            <Row key={index} className="mb-2">
                              <Col md={5}>
                                <Form.Group
                                  controlId="selectBreak"
                                  className="mb-2"
                                >
                                  <Field
                                    as="select"
                                    name={`breaks.${index}.type`}
                                    className="form-select form-select-sm"
                                    placeholder="Select break"
                                    onChange={(event) => {
                                      formikprops.setFieldValue(
                                        `breaks.${index}.type`,
                                        event.target.value
                                      );
                                    }}
                                  >
                                    <option>Select Break</option>
                                    {allBreaksList.map((bItem) => (
                                      <option
                                        value={bItem._id}
                                        selected={breakItem.type === bItem._id}
                                        key={bItem._id}
                                      >
                                        {bItem.name}
                                      </option>
                                    ))}
                                    <option
                                      value="other"
                                      selected={breakItem.type === 'other'}
                                    >
                                      Other
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name={`breaks.${index}.type`}
                                    render={(msg) => (
                                      <small className="text-danger">
                                        {msg}
                                      </small>
                                    )}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={3}>
                                <Field
                                  component={Flatpickr}
                                  name={`breaks.${index}.startTime`}
                                  placeholder="Start Time"
                                  onChange={([date]) => {
                                    formikprops.setFieldValue(
                                      `breaks.${index}.startTime`,
                                      moment(date).format('YYYY-MM-DD HH:mm:ss')
                                    );
                                  }}
                                  value={
                                    breakItem.startTime &&
                                    new Date(breakItem.startTime).toISOString()
                                  }
                                  options={{
                                    defaultDate:
                                      breakItem.startTime &&
                                      new Date(
                                        breakItem.startTime
                                      ).toISOString(),
                                    allowInvalidPreload: true,
                                    dateFormat: 'M j, h:i K',
                                    maxDate: 'today',
                                    monthSelectorType: 'static',
                                    minuteIncrement: 1,
                                    static: true,
                                    position: 'above left',
                                    enableTime: true,
                                  }}
                                  className="form-control form-control-sm"
                                />
                                <ErrorMessage
                                  name={`breaks.${index}.startTime`}
                                  render={(msg) => (
                                    <small className="text-danger">{msg}</small>
                                  )}
                                />
                              </Col>
                              <Col md={3}>
                                <Field
                                  component={Flatpickr}
                                  name={`breaks.${index}.endTime`}
                                  placeholder="End Time"
                                  onChange={([date]) => {
                                    formikprops.setFieldValue(
                                      `breaks.${index}.endTime`,
                                      moment(date).format('YYYY-MM-DD HH:mm:ss')
                                    );
                                  }}
                                  value={
                                    breakItem.endTime &&
                                    new Date(breakItem.endTime).toISOString()
                                  }
                                  options={{
                                    defaultDate:
                                      breakItem.endTime &&
                                      new Date(breakItem.endTime).toISOString(),
                                    allowInvalidPreload: true,
                                    dateFormat: 'M j, h:i K',
                                    maxDate: 'today',
                                    monthSelectorType: 'static',
                                    minuteIncrement: 1,
                                    static: true,
                                    position: 'above left',
                                    enableTime: true,
                                  }}
                                  className="form-control form-control-sm"
                                />
                                <ErrorMessage
                                  name={`breaks.${index}.endTime`}
                                  render={(msg) => (
                                    <small className="text-danger">{msg}</small>
                                  )}
                                />
                              </Col>
                              <Col xs={1} className="px-0">
                                <Button
                                  variant="link"
                                  size="sm"
                                  onClick={() => arrayHelpers.remove(index)}
                                  title="Remove Break"
                                >
                                  <i className="mdi mdi-window-close" />
                                </Button>
                              </Col>
                            </Row>
                          ))}
                        <div className="mb-4">
                          <Button
                            variant="outline-primary"
                            type="button"
                            size="sm"
                            onClick={() =>
                              arrayHelpers.push({
                                type: '',
                                startTime: '',
                                endTime: '',
                              })
                            }
                          >
                            Add Break To Worksession
                          </Button>
                        </div>
                      </div>
                    )}
                  />

                  <Form.Group controlId="reason">
                    <Form.Label>
                      Reason <span className="text-danger">*</span>
                    </Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="reason"
                    />
                    <ErrorMessage
                      name="reason"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Form.Group>
                </section>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button variant="link" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {editSessionAccess ? 'Save' : 'Submit Request'}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

ProjectEditWorkSessionModal.propTypes = {
  wsItem: PropTypes.object,
  projectsList: PropTypes.array,
  handleUpdateWsData: PropTypes.func,
  editSessionAccess: PropTypes.bool,
  allBreaksList: PropTypes.array,
  handleGetAllBreakTypes: PropTypes.func,
};

export default ProjectEditWorkSessionModal;
