import { useState, useRef } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { getUserData } from '../../helpers';
import { tasksService } from '../../services';
import { DateAndTimePickerField } from '../common';

const AddSession = ({
  dateInfo,
  showAddSessionModal,
  handleShowAddSessionModal,
  handleAddWorkSession,
  handleLoader,
}) => {
  const checkInDateISOformat =
    dateInfo && dateInfo.start
      ? moment(dateInfo.start).toISOString()
      : moment(dateInfo.date).toISOString();

  const checkOutDateISOformat =
    dateInfo && dateInfo.end
      ? moment(dateInfo.end).toISOString()
      : moment(dateInfo.date).add(1, 'h').toISOString();

  const { memberProjects } = useSelector((state) => state.allProjects);
  const projectsList =
    memberProjects && memberProjects.length > 0
      ? memberProjects.map((project) => {
          const pItem = {
            value: project._id,
            label: project.title,
          };
          return pItem;
        })
      : [];
  const [checkInDate, setCheckInDate] = useState(
    dateInfo && dateInfo.start ? dateInfo.start : dateInfo.date
  );
  const [checkOutDate, setCheckOutDate] = useState(
    dateInfo && dateInfo.end ? dateInfo.end : moment(dateInfo.date).add(1, 'h')
  );
  const [tasksList, seTasksList] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedTask, setSelectedTask] = useState('');
  const [isFetchingTasks, setIsFetchingTasks] = useState(false);
  const tasksListItems =
    tasksList && tasksList.length > 0
      ? tasksList
          .filter((item) => item.status !== 'complete')
          .map((task) => {
            const pItem = {
              value: task._id,
              label: task.task,
            };
            return pItem;
          })
      : [];
  const taskSelectRef = useRef(null);
  const getProjectTasks = (projectId) => {
    const memberId = getUserData('_id');
    const taskParams = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      projects: [projectId],
      members: [memberId],
    };
    if (projectId && projectId !== '') {
      setIsFetchingTasks(true);
      handleLoader(true);
      tasksService
        .get_tasks_list(taskParams)
        .then((result) => {
          if (result.data) {
            const { tasks } = result.data;
            seTasksList([...tasks]);
          }
          handleLoader(false);
          setIsFetchingTasks(false);
        })
        .catch(() => {
          handleLoader(false);
          setIsFetchingTasks(false);
        });
    }
    // Clear value for task selection
    setTimeout(() => {
      taskSelectRef.current.select.clearValue();
    }, 0);
  };

  return (
    <>
      <Modal
        show={showAddSessionModal}
        onHide={() => {
          handleShowAddSessionModal(false);
        }}
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title className="m-0">Add a work session</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => {
              handleShowAddSessionModal(false);
            }}
          />
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={{
            project: selectedProject ? selectedProject.value : '',
            task: selectedTask ? selectedTask.value : '',
            checkInDate,
            checkOutDate,
          }}
          validationSchema={Yup.object({
            project: Yup.string().required('Please select a project'),
            checkInDate: Yup.date().required(
              'Please enter check in information'
            ),
            checkOutDate: Yup.date().required(
              'Please enter check out information'
            ),
          })}
          onSubmit={(values, { resetForm }) => {
            handleAddWorkSession(values);
            resetForm();
          }}
        >
          {(formikprops) => (
            <Form onSubmit={formikprops.handleSubmit}>
              <Modal.Body>
                <section>
                  <Form.Group controlId="project">
                    <Form.Label>Project</Form.Label>
                    <Select
                      options={projectsList}
                      className="basic-multi-select"
                      placeholder="Select Project"
                      name="project"
                      isClearable
                      value={selectedProject}
                      onChange={(event) => {
                        if (event) {
                          formikprops.setFieldValue('project', event.value);
                          getProjectTasks(event.value);
                          setSelectedProject({
                            value: event.value,
                            label: event.label,
                          });
                        } else {
                          setSelectedProject('');
                          // Clear task input value and reset list array
                          taskSelectRef.current.select.clearValue();
                          seTasksList([]);
                        }
                      }}
                      noOptionsMessage={() => 'No project available'}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0649b5',
                          neutral10: '#edf2f9',
                          neutral20: '#d2ddec',
                          neutral30: '#d2ddec',
                          neutral50: '#b1c2da',
                          neutral80: '#12263f',
                          dangerLight: '#DEEBFF',
                          danger: '#12263f',
                        },
                      })}
                    />
                    <ErrorMessage
                      name="project"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Form.Group>
                  <Form.Group controlId="task">
                    <Form.Label>Task</Form.Label>
                    <Select
                      ref={taskSelectRef}
                      options={tasksListItems}
                      className="basic-multi-select"
                      placeholder="Select Task"
                      name="task"
                      isClearable
                      isDisabled={isFetchingTasks}
                      onChange={(event) => {
                        if (event) {
                          formikprops.setFieldValue('task', event.value);
                          setSelectedTask({
                            value: event.value,
                            label: event.label,
                          });
                        } else {
                          setSelectedTask('');
                        }
                      }}
                      noOptionsMessage={() => 'No task available'}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary: '#0649b5',
                          neutral10: '#edf2f9',
                          neutral20: '#d2ddec',
                          neutral30: '#d2ddec',
                          neutral50: '#b1c2da',
                          neutral80: '#12263f',
                          dangerLight: '#DEEBFF',
                          danger: '#12263f',
                        },
                      })}
                    />
                  </Form.Group>
                  <Row>
                    <Col>
                      <DateAndTimePickerField
                        label="Check In"
                        handleDateSelect={setCheckInDate}
                        name="checkInDate"
                        defaultValue={checkInDateISOformat}
                        showRequiredField={false}
                      />
                    </Col>
                    <Col>
                      <DateAndTimePickerField
                        label="Check Out"
                        handleDateSelect={setCheckOutDate}
                        name="checkOutDate"
                        defaultValue={checkOutDateISOformat}
                        showRequiredField={false}
                      />
                    </Col>
                  </Row>
                </section>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  variant="link"
                  onClick={() => {
                    handleShowAddSessionModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit">
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

AddSession.propTypes = {
  showAddSessionModal: PropTypes.bool,
  handleShowAddSessionModal: PropTypes.func,
  dateInfo: PropTypes.object,
  handleAddWorkSession: PropTypes.func,
  handleLoader: PropTypes.func,
};

export default AddSession;
