import { memo } from 'react';
import PropTypes from 'prop-types';

const CompanyLogo = ({ companyName, companyLogo = '' }) =>
  companyLogo === '' || !/^https:\/\//.test(companyLogo) ? (
    <span>
      <small>{companyName || 'Upload logo'}</small>
    </span>
  ) : (
    <div
      className="avatar-img img-fluid"
      style={{ backgroundImage: `url("${companyLogo}?${Date.now()}")` }}
    />
  );

CompanyLogo.propTypes = {
  companyName: PropTypes.string,
  companyLogo: PropTypes.string,
};

export default memo(CompanyLogo);
