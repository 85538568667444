import { useState, memo, useEffect } from 'react';
import { Row, Col, Card, Spinner, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { msToHMS } from '../../helpers';
import { ProjectMembers } from '../project';
import { projectService } from '../../services';
import { LOCAL_STORAGE_TEAM_OWNER } from '../../constants';

const ProjectCard = ({ isPageLoading }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [searchWord, setSearchWord] = useState('');

  const getProjects = (page) => {
    setLoading(true);
    projectService
      .get_projects_data({
        teamOwnerId: localStorage.getItem(LOCAL_STORAGE_TEAM_OWNER),
        page,
        summaryList: false,
        status: 'active',
        limit: perPage,
        memberLimit: 4,
      })
      .then((response) => {
        setProjects(response.data.projects);
        setTotalRows(response.data.metadata.total);
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    getProjects(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setLoading(true);
    projectService
      .get_projects_data({
        teamOwnerId: localStorage.getItem(LOCAL_STORAGE_TEAM_OWNER),
        page,
        summaryList: false,
        status: 'active',
        limit: newPerPage,
        memberLimit: 4,
      })
      .then((response) => {
        setProjects(response.data.projects);
        setPerPage(newPerPage);
        setLoading(false);
      });
  };

  useEffect(() => {
    getProjects(1); // fetch page 1 of projects
  }, []);

  const tableColumns = [
    {
      name: 'PROJECT',
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => row.project,
      filterable: true,
    },
    {
      name: 'TASKS',
      selector: (row) => row.totalTasks,
      sortable: true,
      cell: (row) => row.tasks,
    },
    {
      name: 'TOTAL HOURS (last 7 days)',
      selector: (row) => row.hours,
      sortable: true,
      cell: (row) => row.total_hours,
    },
    {
      name: 'Team',
      sortable: false,
      cell: (row) => row.member,
    },
  ];

  const data = [];

  projects.forEach(function (project, index) {
    const { tasks } = project;
    let completeTasks = 0;
    let totalTasks = '';
    if (tasks && tasks.length >= 1) {
      completeTasks =
        tasks.filter((task) => task.status === 'complete').length >= 1
          ? tasks.filter((task) => task.status === 'complete')[0].tasks
          : 0;
      totalTasks = tasks.reduce((total, task) => total + task.tasks, 0);
    }
    const { members, totalMembers } = project;
    data.push({
      id: `tm${index}`,
      project: (
        <Link to={`/projects/${project._id}/task-board`} className="text-dark">
          <span>{project.title}</span>
        </Link>
      ),
      member: (
        <div className="avatar me-2">
          <ProjectMembers
            members={members}
            totalMembers={totalMembers}
            projectId={project._id}
          />
        </div>
      ),
      tasks:
        tasks && tasks.length >= 1 ? (
          <Row className="align-items-center no-gutters progress-info w-100 m-0">
            <Col className="col-auto p-0">
              {completeTasks}/{totalTasks}
            </Col>
            <Col md={6} xs={12}>
              <div className="progress progress-sm">
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${(completeTasks / totalTasks) * 100}%`,
                  }}
                  aria-valuenow={{
                    width: `${(completeTasks / totalTasks) * 100}%`,
                  }}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            </Col>
          </Row>
        ) : (
          <div className="col-auto">
            <span className="badge bg-secondary-soft" />
          </div>
        ),
      total_hours:
        project.weekWorkSessionSeconds && project.weekWorkSessionSeconds > 0 ? (
          msToHMS(project.weekWorkSessionSeconds * 1000)
        ) : (
          <span className="badge bg-secondary-soft">No hours logged</span>
        ),
      hours:
        project.weekWorkSessionSeconds && project.weekWorkSessionSeconds > 0
          ? Math.round(project.weekWorkSessionSeconds * 100) / 100
          : 0,
      name: project.title,
      totalTasks,
    });
  });
  function search(rows) {
    return rows.filter(
      (row) => row.name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1
    );
  }
  return (
    <Card>
      <>
        <Card.Header>
          <h4 className="m-0">Active Projects</h4>
        </Card.Header>
        <Card.Body className="p-0">
          <div
            className="col-sm-12 text-muted"
            style={{ padding: '15px 24px', maxWidth: '250px' }}
          >
            <div className="input-group">
              <input
                id="search"
                type="text"
                name="search"
                value={searchWord}
                className="form-control form-control-sm"
                onChange={(e) => setSearchWord(e.target.value)}
                placeholder="Search"
              />{' '}
              <Button variant="primary" size="sm">
                <span className="mdi mdi-magnify" />
              </Button>
            </div>
          </div>

          <div>
            <DataTable
              columns={tableColumns}
              data={search(data)}
              noDataComponent={loading ? '' : 'No Active Projects'}
              highlightOnHover
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              paginationRowsPerPageOptions={[5, 15, 25, 50]}
              paginationComponentOptions={{
                rowsPerPageText: 'Show Results',
                rangeSeparatorText: 'out of',
              }}
              progressPending={loading}
              progressComponent={
                !isPageLoading && (
                  <div className="p-4">
                    <Spinner
                      animation="border"
                      className="text-primary"
                      size="md"
                    />
                  </div>
                )
              }
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
            />
          </div>
        </Card.Body>
      </>
    </Card>
  );
};

ProjectCard.propTypes = {
  isPageLoading: PropTypes.bool,
};

export default memo(ProjectCard);
