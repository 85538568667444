import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Archive from '../Archive';
import { WithConfirmation } from '../common';

const EditProjectArchive = ({ handleArchive }) => {
  // HOC - Confirmation modal
  const ArchiveWithConfirmation = WithConfirmation(Archive);
  const ArchiveModalProps = {
    title: 'Archive Project',
    action: handleArchive,
    content: [
      'You are about to archive this project.',
      'All data will be archived including time logs and screenshots.',
      'Continue?',
    ],
    actionBtnText: 'Archive',
  };

  return (
    <section className="d-flex align-items-center justify-content-between mb-5">
      <div className="me-2">
        <h4 className="card-header-title mb-2">Archive project</h4>
        <span className="d-block text-muted">
          Please note, archiving this project will move it to the archive, where
          it can be permanently deleted or restored
        </span>
      </div>
      <div className="text-right ml-auto">
        <ArchiveWithConfirmation modalProps={ArchiveModalProps} />
      </div>
    </section>
  );
};

EditProjectArchive.propTypes = {
  handleArchive: PropTypes.func,
};

export default memo(EditProjectArchive);
