import { getUserData } from '../helpers';

const isTimesheetApproval = getUserData('isTimesheetApproval');
const isOverTimeEnabled = getUserData('isOverTimeEnabled');
const isScheduleEnabled = getUserData('isSchedulingEnabled');
const isInvoiceEnabled = getUserData('isInvoiceEnabled');
const isTaskNotificationEnabled = getUserData('isEmailNotificationEnabled');
const isSessionManagementEnabled = getUserData('isSessionMgmtEnabled');

export const timesheetApproval = (state = isTimesheetApproval, action) => {
  switch (action.type) {
    case 'ENABLE_TS_APPROVAL':
      return true;
    case 'DISABLE_TS_APPROVAL':
      return false;
    default:
      return state;
  }
};
export const overTimeEnabled = (state = isOverTimeEnabled, action) => {
  switch (action.type) {
    case 'ENABLE_OT':
      return true;
    case 'DISABLE_OT':
      return false;
    default:
      return state;
  }
};

export const scheduleEnabled = (state = isScheduleEnabled, action) => {
  switch (action.type) {
    case 'ENABLE_SCHEDULE':
      return true;
    case 'DISABLE_SCHEDULE':
      return false;
    default:
      return state;
  }
};

export const invoiceEnabled = (state = isInvoiceEnabled, action) => {
  switch (action.type) {
    case 'ENABLE_INVOICE':
      return true;
    case 'DISABLE_INVOICE':
      return false;
    default:
      return state;
  }
};

export const taskNotificationEnabled = (
  state = isTaskNotificationEnabled,
  action
) => {
  switch (action.type) {
    case 'ENABLE_TASK_NOTIFICATION':
      return true;
    case 'DISABLE_TASK_NOTIFICATION':
      return false;
    default:
      return state;
  }
};

export const manageOngoingSessions = (
  state = isSessionManagementEnabled,
  action
) => {
  switch (action.type) {
    case 'ENABLE_MANAGE_ACTIVE_SESSIONS':
      return true;
    case 'DISABLE_MANAGE_ACTIVE_SESSIONS':
      return false;
    default:
      return state;
  }
};
