import { BASE_API_URL, TEAM_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

import { makeEncodeFormData } from '../helpers';

const options = AppService.options();

export const teamService = {
  get_teams_data: async () => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.TEAM,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_default_team: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.UPDATE_TEAM,
      {
        ...options,
        ...headers,
        body: makeEncodeFormData(data),
      }
    );
    return response;
  },
  get_team_members_data: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.TEAM_MEMBERS,
      {
        ...options,
        ...headers,
        body: makeEncodeFormData(data),
      }
    );
    return response;
  },
  get_team_members_and_projects: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.MEMBERS_AND_PROJECTS,
      {
        ...options,
        ...headers,
        body: makeEncodeFormData(data),
      }
    );
    return response;
  },
  get_team_invitations_data: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + TEAM_URLS.TEAM_SENT_INVITATIONS}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );

    return response;
  },
  delete_invitations: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.DELETE_INVITATION,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_invitation_data: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + TEAM_URLS.INVITATION}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  user_worksession_report: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.USER_WORKSESSION_REPORT,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_invitation_status: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.INVITATION_UPDATE,
      {
        ...options,
        ...headers,
        body: makeEncodeFormData(data),
      }
    );
    return response;
  },
  resend_invitation: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.INVITATION_RESEND,
      {
        ...options,
        ...headers,
        body: makeEncodeFormData(data),
      }
    );
    return response;
  },
  update_team_invitations: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.UPDATE_TEAM_INVITATIONS,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_user_role: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.UPDATE_USER_ROLE,
      {
        ...reqOptions,
        ...headers,
        body: makeEncodeFormData(data),
      }
    );
    return response;
  },
  update_user_status: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.UPDATE_USER_STATUS,
      {
        ...reqOptions,
        ...headers,
        body: makeEncodeFormData(data),
      }
    );
    return response;
  },
  delete_team_member: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const reqOptions = {
      method: 'DELETE',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + TEAM_URLS.DELETE_TEAM_MEMBER}/${data.teamOwnerId}/${
        data.userId
      }`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_user_rate: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    let url = `${BASE_API_URL + TEAM_URLS.UPDATE_USER_RATE}/${
      data.teamOwnerId
    }/${data.userId}`;
    url += data.projectId ? `/${data.projectId}` : '';
    const response = await AppService.makeRequest(url, {
      ...options,
      ...headers,
      body: makeEncodeFormData(data),
    });
    return response;
  },
  update_user_permissions: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.UPDATE_PERMISSIONS,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  // Get member status - online or offline
  get_online_members: async (teamOwnerId) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL}${TEAM_URLS.STATUS}${teamOwnerId}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  get_team_member_total_hours: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + TEAM_URLS.WS_TOTAL}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  get_team_group_list: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + TEAM_URLS.GROUP}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  create_team_group: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.GROUP,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_team_group: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL}${TEAM_URLS.GROUP}`,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  delete_team_group: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      method: 'DELETE',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL}${TEAM_URLS.GROUP}/${data.teamOwnerId}/${data.teamId}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  remove_team_group_member: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      `${BASE_API_URL}${TEAM_URLS.GROUP}/delete-members`,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_user_daily_limit: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.UPDATE_USER_DAILY_LIMIT,
      {
        ...options,
        ...headers,
        body: makeEncodeFormData(data),
      }
    );
    return response;
  },
};
