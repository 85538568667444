import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { Card, Row, Alert } from 'react-bootstrap';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NO_DATA, LIGHTBOX_OPTIONS } from '../../constants';
import { groupBy, msToHMS } from '../../helpers';
import { NoDataAvailable, NotesModal } from '../common';
import ScreenshotSlider from '../ScreenshotSlider';

const ActivityList = ({ activityList, activityFor = 'member' }) => {
  const history = useHistory();
  const userDetails = useSelector((state) => state.userReducer);
  // Array with checkin date - for Grouping by Date
  const activityWithDate = activityList
    .map((item) => {
      const checkInDate = moment(item.checkInDate).format('YYYY-MM-DD');
      item.checkIn = checkInDate;
      return item;
    })
    .sort((a, b) => (a.checkInDate < b.checkInDate ? 1 : -1));
  const groupedByDate = groupBy(activityWithDate, 'checkIn');
  if (activityList.length > 0) {
    return (
      <Card className="activity-list">
        <SimpleReactLightbox>
          <SRLWrapper options={LIGHTBOX_OPTIONS}>
            {Object.entries(groupedByDate).map((key, value) => {
              const memberActivity = [...key[1]];
              let totalHrs = 0;
              if (memberActivity.length > 1) {
                totalHrs = memberActivity.reduce(
                  (n, { workSessionSeconds }) =>
                    n + Math.round(workSessionSeconds),
                  0
                );
              } else {
                totalHrs = Math.round(memberActivity[0].workSessionSeconds)
                  ? Math.round(memberActivity[0].workSessionSeconds)
                  : 0;
              }
              totalHrs = totalHrs > 0 ? totalHrs * 1000 : 0;
              totalHrs = msToHMS(totalHrs);

              return (
                <React.Fragment key={`${value}-activity-list`}>
                  <div className="bg-light px-4 py-3">
                    <div className="row align-items-center ">
                      <div className="col-lg-4">
                        {' '}
                        <h4>
                          {moment(memberActivity[0].checkIn).format(
                            'ddd, MMM D'
                          )}
                        </h4>
                      </div>
                      <div className="col-lg-1 text-start">
                        <h4 className="mb-0 tour-activity-total-hour">
                          {totalHrs}
                        </h4>
                      </div>
                      <div className="col-lg-7" />
                    </div>
                  </div>
                  <Card.Body>
                    {memberActivity.map((activity, index) => {
                      activity.workSessionSeconds =
                        activity.workSessionSeconds < 0
                          ? 0
                          : activity.workSessionSeconds;
                      const activityTime = msToHMS(
                        Math.round(activity.workSessionSeconds) * 1000
                      );
                      // Discarded screenshots
                      const discardedScreenshots = activity.screenshots.filter(
                        (item) => item.screenshotDiscard
                      );
                      const discardedScreenshotsCount =
                        discardedScreenshots.length;
                      const isScreenshotsAvailable =
                        activity.screenshots !== null &&
                        activity.screenshots.length > 0 &&
                        activity.screenshotDisabled === false;
                      const showViewScreenshotsButton =
                        (isScreenshotsAvailable &&
                          activity.workSessionType === 'desktop' &&
                          userDetails.role !== 'member') ||
                        (isScreenshotsAvailable &&
                          activity.workSessionType === 'desktop' &&
                          activity.project.hideScreenshots === false);
                      return (
                        <React.Fragment key={`${index}-activity`}>
                          <Row className="align-items-center">
                            <div className="col-lg-4">
                              {activityFor === 'member' ? (
                                <h4 title="project">
                                  {activity.project.title}
                                </h4>
                              ) : (
                                <h4>
                                  {activity.user.firstName}{' '}
                                  {activity.user.lastName}
                                  {activity.user.status === 'inactive' &&
                                    ' (Inactive)'}
                                </h4>
                              )}
                              {activity.taskId !== null && activity.task && (
                                <p className="text-muted">{activity.task}</p>
                              )}
                              {activity.notes !== null &&
                                activity.notes.length > 0 && (
                                  <NotesModal notesList={activity.notes} />
                                )}

                              {activity.workSessionType === 'mobile' ? (
                                <div className="mr-3">
                                  {'geoLocation' in activity &&
                                  activity.geoLocation &&
                                  'address' in activity.geoLocation ? (
                                    <p>
                                      <i className="mdi mdi-map-marker-outline mr-2 " />
                                      <small className="text-muted">
                                        {activity.geoLocation.address}
                                      </small>
                                    </p>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              ) : (
                                <div className="mr-3">
                                  <p>
                                    <span className="tour-activity">
                                      Activity{' '}
                                      {activity.workSessionType ===
                                      'desktop' ? (
                                        `${activity.activityPercentage}%`
                                      ) : (
                                        <span className="text-muted">N/A</span>
                                      )}
                                    </span>
                                  </p>
                                </div>
                              )}
                              {showViewScreenshotsButton &&
                                activity.workSessionType === 'desktop' && (
                                  <div className="mr-3">
                                    <Link
                                      className="btn btn-sm btn-primary"
                                      role="button"
                                      to={{
                                        pathname: `/activity/worksession`,
                                        search: `?wsid=${activity._id}`,
                                        state: history.location.pathname,
                                      }}
                                    >
                                      View Screenshots
                                    </Link>
                                  </div>
                                )}
                            </div>
                            <div className="col-lg-1 text-start">
                              <h4 className="tour-activity-time">
                                {activityTime}
                              </h4>
                              {activity.workSessionType === 'mobile' && (
                                <i className="mdi mdi-cellphone-iphone" />
                              )}
                              {activity.workSessionType === 'desktop' && (
                                <i className="mdi mdi-desktop-mac" />
                              )}
                              {activity.workSessionType === 'extension' && (
                                <i className="mdi mdi mdi-puzzle" />
                              )}
                              {activity.workSessionType === 'web' && (
                                <i className="mdi mdi mdi-web" />
                              )}
                            </div>
                            <div className="col-lg-7">
                              <p className="text-start text-muted mb-3">
                                {activity.checkOutDate === null
                                  ? `${moment(activity.checkInDate).format(
                                      'LT'
                                    )}`
                                  : `${moment(activity.checkInDate).format(
                                      'LT'
                                    )}  to ${moment(
                                      activity.checkOutDate
                                    ).format('LT')}`}
                                {discardedScreenshotsCount > 0 &&
                                  activity.screenshotDisabled === false &&
                                  ` (${discardedScreenshotsCount} Screenshot discarded)`}
                              </p>

                              <div>
                                {activity.isManual ? (
                                  <NoDataAvailable
                                    content={NO_DATA.MANUAL_SESSION}
                                  />
                                ) : (
                                  <>
                                    {activity.project.hideScreenshots &&
                                    userDetails.role === 'member' ? (
                                      <NoDataAvailable
                                        content={NO_DATA.SCREENSHOTS}
                                      />
                                    ) : (
                                      <ScreenshotSlider
                                        screenshots={activity.screenshots}
                                        showCheckboxSelection={false}
                                        workSessionType={
                                          activity.workSessionType
                                        }
                                        screenshotDisabled={
                                          activity.screenshotDisabled
                                        }
                                        hideScreenshots={
                                          activity.project.hideScreenshots
                                        }
                                        memberName={`${
                                          activity.user.firstName
                                        } ${activity.user.lastName || ''}`}
                                        sliderSize="sm"
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </Row>
                          <hr className="my-4" />
                        </React.Fragment>
                      );
                    })}
                  </Card.Body>
                </React.Fragment>
              );
            })}
          </SRLWrapper>
        </SimpleReactLightbox>
      </Card>
    );
  }
  return (
    <Alert variant="info" className="text-center">
      {NO_DATA.WORKSESSION}
    </Alert>
  );
};

ActivityList.propTypes = {
  activityList: PropTypes.array,
  activityFor: PropTypes.string,
};

export default ActivityList;
