import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Form } from 'react-bootstrap';
import { ToggleSwitch } from '../common';

const MobileTrackingApproval = ({
  isEnabledMobileTracking,
  UpdateMobileTrackingApproval,
}) => (
  <Formik
    initialValues={{
      excludeInvitationProcess: isEnabledMobileTracking,
    }}
    enableReinitialize
    validationSchema={Yup.object({
      isTsheeApprovalEnabled: Yup.boolean(),
    })}
    onSubmit={(values) => {
      UpdateMobileTrackingApproval(values);
    }}
  >
    {(formikprops) => (
      <div className="col-12 col-md-12">
        <Form onSubmit={formikprops.handleSubmit} className="needs-validation">
          <Row>
            <Col>
              <h4 className="font-weight-base mb-1">Mobile tracking</h4>
              <small className="text-muted">
                Mobile tracking feature is a process of recording working time
                with mobile devices. Due to the limitations placed by mobile
                providers, the app can only track time and locations and cannot
                record screenshots or measure activity levels.
              </small>
            </Col>
            <Col className="col-auto">
              <ToggleSwitch
                id="excludeInvitationProcess"
                name="excludeInvitationProcess"
                handleChangeforChecked={UpdateMobileTrackingApproval}
                checked={isEnabledMobileTracking}
              />
            </Col>
          </Row>
        </Form>
      </div>
    )}
  </Formik>
);
MobileTrackingApproval.propTypes = {
  isEnabledMobileTracking: PropTypes.bool,
  UpdateMobileTrackingApproval: PropTypes.func,
};
export default MobileTrackingApproval;
