import { Component } from 'react';
import {
  Row,
  Col,
  Card,
  ListGroup,
  Button,
  Dropdown,
  Accordion,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Layout from './DashboardLayout';
import Avatar from '../components/Avatar';
import AddTeamGroup from '../components/team/AddTeamGroup';
import EditTeamGroup from '../components/team/EditTeamGroup';
import { teamService } from '../services';
import Loading from '../components/Loader';
import {
  WithConfirmation,
  AccordionToggle,
  NoDataAlert,
} from '../components/common';

// Delete team group - link
const DeleteLink = ({ handleShow }) => (
  <Dropdown.Item onClick={handleShow}>Delete Team</Dropdown.Item>
);
DeleteLink.propTypes = {
  handleShow: PropTypes.func,
};

// Delete team group - confirmation modal
const DeleteTeam = ({ deleteProp }) => {
  const DeleteSectionWithConfirmation = WithConfirmation(DeleteLink);
  const DeleteModalProps = {
    title: `Delete Team Group`,
    action: deleteProp,
    content: [
      `You're about to delete a team. All data will be permanently deleted. You will not be able to restore it later.`,
      'Do you want to delete it?',
    ],
    actionBtnText: 'Delete',
  };
  return <DeleteSectionWithConfirmation modalProps={DeleteModalProps} />;
};
DeleteTeam.propTypes = {
  deleteProp: PropTypes.func,
};

class TeamGroups extends Component {
  state = {
    isLoading: false,
    teamList: [],
    teamMembersList: [],
    showAddTeam: false,
    showEditTeam: false,
    selectedTeam: {},
  };

  pageHeading = {
    preTitle: 'Team',
    title: `Groups`,
  };

  componentDidMount() {
    this.getTeamGroupList();
    this.getAllTeamsMembers();
  }

  getTeamGroupList = () => {
    this.setState(() => ({
      isLoading: true,
    }));
    teamService
      .get_team_group_list({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
      })
      .then((response) => {
        if (response.data) {
          this.setState(() => ({
            isLoading: false,
            teamList: response.data,
          }));
        }
      });
  };

  // Get team members data
  getAllTeamsMembers = () => {
    teamService
      .get_team_members_data({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        summaryList: true,
      })
      .then((response) => {
        const { members } = response.data;
        this.setState((state) => ({
          ...state,
          teamMembersList: [...members],
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  // Add New Team Modal Display
  handleAddTeamDisplay = (dState) => {
    this.setState((state) => ({
      ...state,
      showAddTeam: dState,
    }));
  };

  // handle create new team group
  handleCreateTeamGroup = (data) => {
    this.setState(() => ({
      isLoading: true,
    }));
    teamService
      .create_team_group({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        name: data.teamName,
        members: data.selectedMembers,
      })
      .then((response) => {
        if (response.data) {
          this.getTeamGroupList();
          this.setState(() => ({
            isLoading: false,
          }));
        }
        this.handleAddTeamDisplay(false);
      })
      .catch(() => {
        this.setState(() => ({
          isLoading: false,
        }));
      });
  };

  // Handle edit/update team group name and members
  handleUpdateTeamGroup = (data) => {
    this.setState(() => ({
      isLoading: true,
    }));
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      teamName: data.teamName,
      teamId: data.teamId,
      members: data.selectedMembers,
    };
    if (data.selectedMembers.length === 0) {
      delete params.members;
    }
    teamService
      .update_team_group(params)
      .then((response) => {
        if (response.data) {
          this.getTeamGroupList();
          this.setState(() => ({
            isLoading: false,
          }));
        }
        this.handleEditTeamDisplay(false);
      })
      .catch(() => {
        this.setState(() => ({
          isLoading: false,
        }));
      });
  };

  // Team group deletion
  handleTeamGroupDeletion = (teamId) => {
    this.setState(() => ({
      isLoading: true,
    }));
    const { teamList } = this.state;
    teamService
      .delete_team_group({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        teamId,
      })
      .then((response) => {
        if (response.status) {
          const updatedTeamList = [...teamList].filter(
            (tItem) => tItem._id !== teamId
          );
          this.setState(() => ({
            isLoading: false,
            teamList: [...updatedTeamList],
          }));
        }
      })
      .catch(() => {
        this.setState(() => ({
          isLoading: false,
        }));
      });
  };

  // Add New Member Modal Display
  handleEditTeamDisplay = (dState) => {
    this.setState((state) => ({
      ...state,
      showEditTeam: dState,
    }));
  };

  // Add New Member Modal Display
  handleSelectedTeam = (teamInfo) => {
    this.setState((state) => ({
      ...state,
      selectedTeam: { ...teamInfo },
    }));
  };

  // Team member deletion form a team group
  handleTeamGroupMemberDeletion = (data) => {
    this.setState(() => ({
      isLoading: true,
    }));
    teamService
      .remove_team_group_member({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        teamId: data.teamId,
        members: [data.selectedMember],
      })
      .then((response) => {
        if (response.data) {
          this.getTeamGroupList();
          this.setState(() => ({
            isLoading: false,
          }));
        }
      })
      .catch(() => {
        this.setState(() => ({
          isLoading: false,
        }));
      });
  };

  render() {
    const {
      isLoading,
      teamList,
      teamMembersList,
      showAddTeam,
      showEditTeam,
      selectedTeam,
    } = this.state;
    return (
      <Layout pageHeading={this.pageHeading}>
        <Row className="justify-content-center">
          <Col className="col-12">
            <Card className="mb-5">
              <Card.Header>
                <h4 className="m-0">Team Groups</h4>
                <AddTeamGroup
                  teamMembersList={teamMembersList}
                  showAddTeam={showAddTeam}
                  handleAddTeamDisplay={this.handleAddTeamDisplay}
                  handleCreateTeam={this.handleCreateTeamGroup}
                />
              </Card.Header>
              <Card.Body>
                {teamList.length > 0 && (
                  <ListGroup>
                    <Accordion>
                      {teamList.map((team, index) => {
                        const activeMembers = team.members.filter(
                          (teamGItem) => teamGItem.status !== 'inactive'
                        );
                        return (
                          <ListGroup.Item key={`${index}-${team._id}`}>
                            <Row className="align-items-center">
                              <Col>
                                <AccordionToggle
                                  eventKey={`team${index}`}
                                  className="text-start"
                                >
                                  <div className="ps-3">
                                    <span>{team.name}</span>{' '}
                                    <span className="text-muted">
                                      ({activeMembers.length})
                                    </span>
                                  </div>
                                </AccordionToggle>
                              </Col>
                              <Col className="col-auto">
                                <Dropdown
                                  className="card-dropdown"
                                  style={{
                                    position: 'relative',
                                    top: '0',
                                    right: '0',
                                  }}
                                  id={`${team._id}`}
                                >
                                  <Dropdown.Toggle
                                    as={Link}
                                    to={{ hash: '#' }}
                                    className="dropdown-ellipses dropdown-toggle"
                                  >
                                    <i className="mdi mdi-dots-horizontal" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu align="left">
                                    <Dropdown.Item
                                      onClick={() => {
                                        this.handleEditTeamDisplay(true);
                                        this.handleSelectedTeam({
                                          teamName: team.name,
                                          teamId: team._id,
                                          currentMembers: team.members,
                                          teamMembersList,
                                          showEditTeam,
                                        });
                                      }}
                                    >
                                      Edit team
                                    </Dropdown.Item>
                                    <DeleteTeam
                                      deleteProp={() =>
                                        this.handleTeamGroupDeletion(team._id)
                                      }
                                    />
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Row>
                            <Accordion.Collapse eventKey={`team${index}`}>
                              <ul className="list-group list-group-flush list">
                                {activeMembers.map((tMember) => (
                                  <li
                                    className="list-group-item"
                                    key={`${tMember._id}`}
                                  >
                                    <Row className="justify-content-center">
                                      <Col className="col-auto">
                                        <div className="avatar avatar-xs">
                                          <Avatar
                                            firstName={tMember.firstName}
                                            lastName={tMember.lastName}
                                            imgPath={
                                              tMember.avatar
                                                ? tMember.avatar
                                                : ''
                                            }
                                          />
                                        </div>
                                      </Col>
                                      <Col className="ps-0">
                                        {tMember.lastName
                                          ? `${tMember.firstName} ${tMember.lastName}`
                                          : tMember.firstName}
                                      </Col>
                                      <Col className="col-auto">
                                        <Button
                                          variant="link"
                                          title="Delete"
                                          className="mx-0 mb-0 mt-1 p-0"
                                          onClick={() => {
                                            this.handleTeamGroupMemberDeletion({
                                              teamId: team._id,
                                              selectedMember: tMember._id,
                                            });
                                          }}
                                        >
                                          <i className="mdi mdi-trash-can-outline h3 m-0 p-0" />
                                        </Button>
                                      </Col>
                                    </Row>
                                  </li>
                                ))}
                              </ul>
                            </Accordion.Collapse>
                          </ListGroup.Item>
                        );
                      })}
                    </Accordion>
                  </ListGroup>
                )}
                {showEditTeam && (
                  <EditTeamGroup
                    selectedTeam={selectedTeam}
                    showEditTeam={showEditTeam}
                    handleEditTeamDisplay={this.handleEditTeamDisplay}
                    handleUpdateTeam={this.handleUpdateTeamGroup}
                  />
                )}
                {!isLoading && teamList.length === 0 && (
                  <NoDataAlert content="No teams groups are created yet." />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Loader */}
        {isLoading && <Loading contentAreaOnly />}
      </Layout>
    );
  }
}

export default TeamGroups;
