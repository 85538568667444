const initialState = {
  isLoading: false,
  pendingRequests: 0,
  data: {},
};

export const wsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCHING_WS_REQUESTS':
      return {
        ...state,
        isLoading: true,
      };
    case 'SET_WS_REQUESTS':
      return {
        ...state,
        isLoading: false,
        data: { ...action.payload },
        pendingRequests: action.payload.metadata.pendingRequests,
      };
    case 'FETCH_WS_REQUESTS_COMPLETE':
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
