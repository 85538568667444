import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Alert } from 'react-bootstrap';

export default class ErrorBoundary extends Component {
  state = {
    errorInfo: '',
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    const { hasError, errorInfo } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <Card className="my-2">
          <Card.Body>
            <Alert variant="danger">
              An error has occurred in this component.
            </Alert>
            <details className="error-details">
              <summary>Click for error details</summary>
              <pre>{errorInfo && errorInfo.componentStack.toString()}</pre>
            </details>
          </Card.Body>
        </Card>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
