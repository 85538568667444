import { Dropdown } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import { setNotificationAsRead } from '../../actions/notification';

const NotificationList = () => {
  const { requestNotification } =
    useSelector((state) => state.notificationReducer) || [];
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const redirectUrl = (projectId) => {
    if (projectId === '/timeoff/requests') {
      history.push(projectId);
    } else if (location.pathname !== `/projects/${projectId}/task-board`) {
      history.push(`/projects/${projectId}/task-board`);
      history.go(0);
    }
  };
  return (
    <Dropdown className="btn text-white font-size-lg p-0">
      <Link
        to="#"
        onClick={(e) => {
          if (
            requestNotification.length > 0 &&
            requestNotification
              .map((item) => (item.notification ? item._id : ''))
              .filter((itm) => itm).length > 0
          ) {
            dispatch(
              setNotificationAsRead(
                requestNotification
                  .map((item) => (item.notification ? item._id : ''))
                  .filter((itm) => itm)
              )
            );
          }
        }}
      >
        <Dropdown.Toggle className="dropdown-ellipses dropdown-toggle">
          {requestNotification &&
          requestNotification.length > 0 &&
          requestNotification.filter((item) => item.read === false).length >
            0 ? (
            <span className="notification-icon-badge">
              {requestNotification.filter((item) => item.read === false).length}
            </span>
          ) : (
            ''
          )}
          <Tippy content="Notifications" theme="light">
            <i className="mdi mdi-bell-outline text-white icon-20px" />
          </Tippy>
        </Dropdown.Toggle>
      </Link>
      {requestNotification && requestNotification.length > 0 ? (
        <Dropdown.Menu
          align="left"
          style={{ maxHeight: '300px', minWidth: '320px', overflowY: 'auto' }}
        >
          {requestNotification.map((item) =>
            item.notification ? (
              <Dropdown.Item
                key={item._id}
                as={Link}
                to="#"
                style={{ whiteSpace: 'normal' }}
                onClick={() => {
                  if (item.category === 'task_due') {
                    redirectUrl(item.projectId);
                  } else if (item.category === 'timeoff_status') {
                    redirectUrl('/timeoff/requests');
                  }
                }}
              >
                {item.type === 'creator' && (
                  <strong>
                    {item.memberFirstName || ''}
                    {`${item.memberLastName || ''} `}
                  </strong>
                )}
                {item.notification}
                {item.category === 'daily_limit' && item.limit ? (
                  <p className="mb-0">{`Threshold Limit : ${item.limit} hours`}</p>
                ) : (
                  ''
                )}
                {item.category === 'task_due' ? (
                  ''
                ) : (
                  <p className="mb-1" style={{ fontSize: '13px' }}>
                    {moment(item.createdDate).format('MMM D YYYY, h:mm a')}
                  </p>
                )}
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                key={item._id}
                as={Link}
                to="/timeoff/requests"
                style={{ whiteSpace: 'normal' }}
              >
                <strong>
                  {item.MemberFirstName} {item.MemberLastName}{' '}
                </strong>
                <small>has created Time Off request</small>
                <p className="mb-1">
                  <small>
                    {moment(item.createdDate).format('MMM D, YYYY h:mm a')}
                  </small>
                </p>
              </Dropdown.Item>
            )
          )}
        </Dropdown.Menu>
      ) : (
        ''
      )}
    </Dropdown>
  );
};

export default NotificationList;
