import PropTypes from 'prop-types';
import { Card, Row, Tab, Alert } from 'react-bootstrap';
import React from 'react';
import { NO_DATA, PAGINATION_LIMIT } from '../../../constants';
import Pagination from '../../common/Pagination';
import { sToHMSColonSeparated } from '../../../helpers';
import { TextCard } from '../../project/overview';

const Report = ({
  overTimeMetadata,
  overtimeData,
  userRole,
  setCurrentPage,
  getOvertimeData,
  reportFormData,
  pageNumber,
}) => {
  if (overTimeMetadata && overTimeMetadata.total > 0) {
    return (
      <>
        <Row className="mb-4">
          <TextCard
            cardTitle="Total Logged Hours"
            cardDetails={
              overTimeMetadata.totalWorkSessionHours > 0
                ? `${sToHMSColonSeparated(
                    Math.round(overTimeMetadata.totalWorkSessionHours * 60 * 60)
                  )}`
                : `${0}`
            }
            md={3}
          />
          <TextCard
            cardTitle="Total Overtime"
            cardDetails={
              overTimeMetadata.totalOverTimeHours > 0
                ? `${sToHMSColonSeparated(
                    Math.round(overTimeMetadata.totalOverTimeHours * 60 * 60)
                  )}`
                : `${0}`
            }
            md={2}
          />
          <TextCard
            cardTitle="Total Regular Time Pay"
            md={2}
            cardDetails={
              overTimeMetadata.totalRegularPay
                ? `${overTimeMetadata.totalRegularPay.toFixed(2)} (${
                    overTimeMetadata.defaultCurrency
                  })`
                : 0
            }
          />
          <TextCard
            cardTitle="Total Overtime Pay"
            md={2}
            cardDetails={
              overTimeMetadata.totalOverTimePay
                ? `${overTimeMetadata.totalOverTimePay.toFixed(2)} (${
                    overTimeMetadata.defaultCurrency
                  })`
                : 0
            }
          />
          <TextCard
            cardTitle="Gross Pay"
            md={3}
            cardDetails={
              overTimeMetadata.totalRegularPay ||
              overTimeMetadata.totalOverTimePay
                ? `${(
                    overTimeMetadata.totalRegularPay +
                    overTimeMetadata.totalOverTimePay
                  ).toFixed(2)}(${overTimeMetadata.defaultCurrency})`
                : 0
            }
          />
        </Row>
        <Card>
          <div className="card-body py-1 bg-light">
            <p className="small text-end mb-0">
              *The team member's account level hourly pay rate has been used to
              calculate the overtime.
            </p>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="ot" />
            <div className="table-responsive">
              <table
                id="groupedDataByMember"
                className="table table-sm table-hover card-table"
              >
                <thead className="thead-light">
                  <tr>
                    {userRole !== 'member' && <th>Team member</th>}
                    <th>Logged hours</th>
                    <th>Overtime</th>
                    <th>
                      Regular time pay ({overTimeMetadata.defaultCurrency})
                    </th>
                    <th>OT Pay ({overTimeMetadata.defaultCurrency})</th>
                    <th>Gross Pay ({overTimeMetadata.defaultCurrency})</th>
                  </tr>
                </thead>

                <tbody>
                  {overtimeData.map((overtime, index) => (
                    <React.Fragment key={index}>
                      <tr key={`${index}-overtime`}>
                        {userRole !== 'member' && (
                          <td>
                            {overtime.user.lastName
                              ? `${overtime.user.firstName} ${overtime.user.lastName}`
                              : overtime.user.firstName}
                          </td>
                        )}
                        <td>
                          {sToHMSColonSeparated(
                            Math.round(overtime.workSessionHours * 60 * 60)
                          )}
                        </td>
                        <td>
                          {sToHMSColonSeparated(
                            Math.round(overtime.overTimeHours * 60 * 60)
                          )}
                        </td>
                        <td>
                          {overtime.regularPay
                            ? overtime.regularPay.toFixed(2)
                            : 0}
                        </td>
                        <td>
                          {overtime.overTimePay
                            ? overtime.overTimePay.toFixed(2)
                            : 0}
                        </td>
                        <td>
                          {overtime.overTimePay || overtime.regularPay
                            ? (
                                overtime.overTimePay + overtime.regularPay
                              ).toFixed(2)
                            : 0}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={userRole !== 'member' ? 6 : 5}>
                      {overTimeMetadata &&
                        overTimeMetadata.total &&
                        overTimeMetadata.total > PAGINATION_LIMIT && (
                          <section className="d-flex justify-content-center py-3">
                            <Pagination
                              className="pagination-bar"
                              currentPage={pageNumber}
                              totalCount={overTimeMetadata.total}
                              pageSize={PAGINATION_LIMIT}
                              onPageChange={(page) => {
                                setCurrentPage(page);
                                getOvertimeData({
                                  ...reportFormData,
                                  page,
                                  limit: PAGINATION_LIMIT,
                                });
                              }}
                            />
                          </section>
                        )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Tab.Content>
        </Card>
      </>
    );
  }
  return (
    <Alert variant="info" className="text-center">
      {NO_DATA.WORKSESSION}
    </Alert>
  );
};
Report.propTypes = {
  overtimeData: PropTypes.any,
  overTimeMetadata: PropTypes.object,
  userRole: PropTypes.string,
  setCurrentPage: PropTypes.func,
  getOvertimeData: PropTypes.func,
  reportFormData: PropTypes.object,
  pageNumber: PropTypes.number,
};

export default Report;
