import { useState } from 'react';
import { Modal, Dropdown, Form, Row, Col, Button } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { teamService } from '../../services';
import {
  activateLoader,
  deactivateLoader,
  updateMemberData,
} from '../../actions/team';
import { TEAM, API_FAILURE_ERROR } from '../../constants';

const EditHourlyRateModal = ({ userId, userRate, currency }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRateUpdate = (hourlyRate) => {
    dispatch(activateLoader());
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      userId,
      costRate: hourlyRate,
    };
    teamService
      .update_user_rate(params)
      .then(() => {
        teamService
          .get_team_members_data({
            teamOwnerId: localStorage.getItem('teamOwnerId'),
            summaryList: false,
            filter: JSON.stringify({
              memberId: userId,
            }),
          })
          .then((response) => {
            if (response.data) {
              const { members } = response.data;
              dispatch(updateMemberData(members[0]));
              dispatch(deactivateLoader());
              toast.success(TEAM.RATE_UPDATE_SUCCESS);
              handleClose();
            }
          });
      })
      .catch(() => {
        dispatch(deactivateLoader());
        toast.error(API_FAILURE_ERROR);
      });
  };

  return (
    <>
      <Dropdown.Item onClick={handleShow}>Edit hourly rate</Dropdown.Item>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="sm"
        centered
      >
        <Modal.Header>
          <Modal.Title className="m-0">Edit hourly rate</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Formik
          initialValues={{
            handleRateUpdate: '',
            hourlyRate: userRate,
          }}
          validationSchema={Yup.object({
            hourlyRate: Yup.number()
              .positive('Please enter positive hourly rate')
              .required('Please enter hourly rate')
              .typeError('Please enter numeric hourly rate')
              .min(0, 'Please enter valid rate between 0 and 99999.99')
              .max(99999.99, 'Please enter valid rate between 0 and 99999.99'),
          })}
          onSubmit={(values) => {
            const hourlyRate = parseFloat(values.hourlyRate).toFixed(2);
            handleRateUpdate(hourlyRate);
          }}
        >
          {(formikprops) => (
            <Form
              onSubmit={formikprops.handleSubmit}
              className="needs-validation"
            >
              <section>
                <div>
                  <div className="modal-body pt-3 pb-2">
                    <div className="form-group">
                      <Row>
                        <Col className="col-auto pt-4">
                          <Form.Label className="form-control-label">
                            Hourly rate ({currency})
                          </Form.Label>
                        </Col>
                        <Col className="px-2 col-auto">
                          <Field
                            name="hourlyRate"
                            id="hourlyRate"
                            type="text"
                            style={{
                              maxWidth: '40px',
                              marginTop: '23px',
                              marginLeft: '18px',
                            }}
                            onBlur={(e) => {
                              e.currentTarget.value = parseFloat(
                                e.target.value
                              ).toFixed(2);
                            }}
                            onKeyDown={(event) => {
                              if (event.keyCode !== 8) {
                                if (!/^[0-9.\b]+$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }
                            }}
                            className="form-control form-control-sm"
                            as={Form.Control}
                            isInvalid={
                              !!formikprops.touched.hourlyRate &&
                              !!formikprops.errors.hourlyRate
                            }
                            isValid={
                              !!formikprops.touched.hourlyRate &&
                              !formikprops.errors.hourlyRate
                            }
                          />
                        </Col>
                        <ErrorMessage
                          name="hourlyRate"
                          render={(msg) => (
                            <small className="text-danger">{msg}</small>
                          )}
                        />
                      </Row>
                    </div>
                  </div>
                  <div className="modal-footer d-flex justify-content-between pt-2 pb-2">
                    <button
                      type="button"
                      className="btn btn-link"
                      data-dismiss="modal"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <Button
                      type="submit"
                      size="sm"
                      style={{ height: '26px' }}
                      className="btn btn-primary  ml-auto"
                      name="btnDeactivateMemberCard"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

EditHourlyRateModal.propTypes = {
  userId: PropTypes.string,
  userRate: PropTypes.number,
  currency: PropTypes.string,
};

export default EditHourlyRateModal;
