const CancelAccountForm = () => (
  <>
    <div className="d-grid gap-2">
      <div className="invalid-feedback" id="confmsg">
        Please provide a reason for Cancellation
      </div>
      <div>
        <p>
          Please share with us the reason that you are closing your account, so
          that we can learn and improve.
        </p>
        <textarea id="cancelReason" className="form-control mb-2" />

        <p className="text-muted">
          You can reactivate anytime later by logging in.
        </p>
      </div>
    </div>
  </>
);
export default CancelAccountForm;
