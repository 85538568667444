import React, { useState } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import * as Yup from 'yup';
import EdiText from 'react-editext';
import { clientsService } from '../../services';

const EditClientModal = ({
  selectedClient,
  existingBreakTypes,
  handleUpdateClientsList,
}) => {
  const [breakTypes, setBreakTypes] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    setBreakTypes([...existingBreakTypes]);
  };
  const [isSubmitting, setSubmitting] = useState(false);
  const [showBreakRemoveButton, setShowBreakRemoveButton] = useState(true);

  const updateClient = (data) => {
    setSubmitting(true);

    const clientParams = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      clientId: selectedClient._id,
      name: data.name,
      address: data.address,
      description: data.description,
      url: data.url,
      taxNo: data.taxNo,
      breakTypes,
    };
    if (breakTypes.length < 1) {
      delete clientParams.breakTypes;
    }

    clientsService
      .edit_client(clientParams)
      .then((response) => {
        if (response.data) {
          handleUpdateClientsList();
          handleClose();
        }
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  // Add a new break to breakTypes array
  const addBreak = (breakType) => {
    if (breakType.name.trim().length > 0) {
      setBreakTypes([...breakTypes, breakType]);
    }
  };

  // Delete a break type from breakTypes array
  const deleteBreakType = (breakTypeIndex) => {
    if (breakTypes[breakTypeIndex]._id) {
      breakTypes[breakTypeIndex].status = 'delete';
    } else {
      breakTypes.splice(breakTypeIndex, 1);
    }
    setBreakTypes([...breakTypes]);
  };

  // Update a billable break type from breakTypes array #billable #breakType
  const updateBreakType = (breakTypeIndex) => {
    breakTypes[breakTypeIndex].billable = !breakTypes[breakTypeIndex].billable;
    setBreakTypes([...breakTypes]);
  };

  return (
    <>
      <Button variant="white" size="sm" onClick={handleShow}>
        <i className="mdi mdi-pencil" /> Edit
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="pt-4" closeButton>
          <Modal.Title>Edit Client</Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={{
            clientName: selectedClient.name,
            address: selectedClient.address,
            description: selectedClient.description
              ? selectedClient.description
              : '',
            website: selectedClient.url ? selectedClient.url : '',
            taxNo: selectedClient.taxNo,
            addBreak: '',
            billable: false,
          }}
          validationSchema={Yup.object({
            clientName: Yup.string()
              .trim()
              .required('Please enter client name'),
            // description: Yup.string()
            //  .matches(/[a-z]+/g, 'Description should be alphanumeric')
            //  .min(10, 'Description must be more than 10 characters')
            //  .max(300, 'Description must be less than 300 characters'),
            // website: Yup.string()
            //  .matches(
            //    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            //    'Please enter a valid url'
            //   )
            //  .min(4, 'Website url must be more than 3 characters'),
          })}
          onSubmit={(values) => {
            updateClient({
              name: values.clientName,
              address: values.address,
              description: values.description,
              taxNo: values.taxNo,
              url: values.website,
            });
          }}
        >
          {(formikprops) => (
            <Form onSubmit={formikprops.handleSubmit}>
              <Modal.Body>
                <section>
                  <Form.Group controlId="clientName">
                    <Form.Label>
                      Client Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="clientName"
                    />
                    <ErrorMessage
                      name="clientName"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Form.Group>
                  <Form.Group controlId="clientName">
                    <Form.Label>Address</Form.Label>
                    <Tippy
                      content={`${'This will be reflected in invoices.'}`}
                      theme="light"
                      key="2"
                    >
                      <span className="p-2 d-inline-block">
                        <i className="mdi mdi-information-outline" />
                      </span>
                    </Tippy>
                    <Field
                      as="textarea"
                      maxLength="300"
                      name="address"
                      rows="2"
                      className="form-control"
                    />
                  </Form.Group>
                  <Form.Group controlId="projectDescription">
                    <Form.Label>Description</Form.Label>
                    <Field
                      as="textarea"
                      maxLength="300"
                      name="description"
                      rows="2"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="description"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Form.Group>
                  <Form.Group controlId="website">
                    <Form.Label>Website</Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="website"
                    />
                    <ErrorMessage
                      name="website"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Form.Group>
                  <Form.Group controlId="website">
                    <Form.Label>Tax/VAT Number</Form.Label>
                    <Tippy
                      content={`${'This will be reflected in invoices.'}`}
                      theme="light"
                      key="2"
                    >
                      <span className="p-2 d-inline-block">
                        <i className="mdi mdi-information-outline" />
                      </span>
                    </Tippy>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder=""
                      name="taxNo"
                    />
                    <ErrorMessage
                      name="taxNo"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Form.Group>
                  <section className="mt-5">
                    <Form.Label className="mb-3">Break Types</Form.Label>
                    {breakTypes.length > 0 && (
                      <div className="breakTypes-list">
                        <ul className="list-unstyled">
                          {breakTypes.map((breakType, bIndex) => (
                            <li
                              key={bIndex}
                              className={
                                breakType.status === 'delete' ? 'd-none' : ''
                              }
                            >
                              <Row>
                                <Col className="col-auto pe-0">
                                  <EdiText
                                    viewProps={{
                                      className: 'break-type-name',
                                    }}
                                    inputProps={{
                                      className:
                                        'form-control edit-break-field',
                                    }}
                                    showButtonsOnHover
                                    editButtonClassName="edit-break"
                                    type="text"
                                    validation={(val) => val.length > 0}
                                    value={breakType.name}
                                    onEditingStart={() => {
                                      setShowBreakRemoveButton(false);
                                    }}
                                    onCancel={() => {
                                      setShowBreakRemoveButton(true);
                                    }}
                                    onSave={(value) => {
                                      breakTypes[bIndex].name = value;
                                      setShowBreakRemoveButton(true);
                                    }}
                                    editOnViewClick
                                    cancelOnUnfocus
                                    hideIcons
                                    editButtonContent={
                                      <i className="mdi mdi-pencil-outline" />
                                    }
                                    saveButtonContent={
                                      <i className="mdi mdi-check" />
                                    }
                                    saveButtonClassName="btn btn-primary btn-sm me-2"
                                    cancelButtonContent={
                                      <i className="mdi mdi-close" />
                                    }
                                    cancelButtonClassName="btn btn-white btn-sm"
                                  />
                                </Col>
                                <Col className="ps-0">
                                  {showBreakRemoveButton && (
                                    <Button
                                      variant="link"
                                      type="button"
                                      className="m-0 p-1 remove-break-btn"
                                      onClick={() => {
                                        deleteBreakType(bIndex);
                                      }}
                                    >
                                      <i className="mdi mdi-close-circle-outline" />
                                    </Button>
                                  )}
                                </Col>
                                <Col className="col-auto">
                                  <label htmlFor="billable-break">
                                    <input
                                      className="form-check-input mt-1 me-2"
                                      type="checkbox"
                                      value=""
                                      name="billable-break"
                                      checked={breakType.billable}
                                      onChange={(e) => {
                                        updateBreakType(bIndex);
                                      }}
                                    />
                                    <small className="text-muted">
                                      Billable
                                    </small>
                                  </label>
                                </Col>
                              </Row>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <Row className="mt-2 mb-4 justify-content-start">
                      <Col>
                        <div className="input-group mb-3">
                          <Field
                            type="text"
                            name="addBreak"
                            className="form-control"
                            placeholder="Enter new break here"
                          />
                          <ErrorMessage
                            name="addBreak"
                            render={(msg) => (
                              <small className="text-danger">{msg}</small>
                            )}
                          />
                          <div className="input-group-text">
                            <label htmlFor="billable">
                              <input
                                className="form-check-input form-check-border mt-1 me-2"
                                type="checkbox"
                                value=""
                                aria-label="Checkbox for following text input"
                                name="billable"
                                onChange={(e) => {
                                  formikprops.setFieldValue(
                                    'billable',
                                    !formikprops.values.billable
                                  );
                                }}
                                checked={formikprops.values.billable}
                              />
                              Billable
                            </label>
                          </div>
                        </div>
                      </Col>
                      <Col className="col-auto ps-0">
                        <Button
                          variant="outline-primary"
                          type="button"
                          onClick={() => {
                            addBreak({
                              name: formikprops.values.addBreak,
                              billable: formikprops.values.billable,
                            });
                            formikprops.setFieldValue('addBreak', '');
                            formikprops.setFieldValue('billable', false);
                          }}
                          disabled={formikprops.values.addBreak.length === 0}
                        >
                          Add Break
                        </Button>
                      </Col>
                    </Row>
                  </section>
                </section>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button variant="white" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

EditClientModal.propTypes = {
  selectedClient: PropTypes.object,
  existingBreakTypes: PropTypes.array,
  handleUpdateClientsList: PropTypes.func,
};

export default EditClientModal;
