import { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { projectService, userService } from '../../services';
import { UPDATE_FAILURE_ERROR, INVITATIONS } from '../../constants';
import { updateLocalStorage } from '../../helpers';
import { resetProjectsList, fetchMemberProjects } from '../../actions/projects';
import { fetchInvitations } from '../../actions/team';
import { setUserRole } from '../../actions/user';

const ProjectInvitationsModal = ({ invitationsData }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isUserWithNoTeam = localStorage.getItem('userWithNoTeam');
  // Project invitation status update
  const handleInvitationStatusUpdate = (data) => {
    const params = {
      ...data,
    };
    projectService
      .update_invitation_status(params)
      .then((response) => {
        if (response.status) {
          // If user with no team:-
          // Request user details on accepting an invite and update LS and teamOwnerId
          if (isUserWithNoTeam) {
            // Update teamOwnerId on LS
            userService.user_details().then((succResp) => {
              if (succResp.status) {
                if (params.status === 'decline')
                  toast.success(INVITATIONS.DECLINE_INVITATION_SUCCESS);
                else {
                  const { defaultTeam } = succResp.data;
                  localStorage.setItem('teamOwnerId', defaultTeam.teamOwnerId);
                  localStorage.removeItem('userWithNoTeam');
                  updateLocalStorage({
                    defaultTeam: {
                      role: 'member',
                      teamOwnerId: defaultTeam.teamOwnerId,
                    },
                  });
                  dispatch(
                    setUserRole({
                      role: defaultTeam.role,
                      ownerId: defaultTeam.teamOwnerId,
                    })
                  );
                  toast.success(INVITATIONS.ACCEPT_INVITATION_SUCCESS);
                }
                dispatch(fetchInvitations());
                if (params.status === 'accept') {
                  dispatch(resetProjectsList());
                  dispatch(fetchMemberProjects());
                }
                handleClose();
              }
            });
          } else {
            if (params.status === 'decline') {
              toast.success(INVITATIONS.DECLINE_INVITATION_SUCCESS);
            } else {
              toast.success(INVITATIONS.ACCEPT_INVITATION_SUCCESS);
              dispatch(resetProjectsList());
              dispatch(fetchMemberProjects());
              dispatch(fetchInvitations());
            }
            handleClose();
          }
        }
      })
      .catch((error) => {
        handleClose();
        dispatch(fetchInvitations());
        if (error.status === 0) {
          toast.error(error.message);
        } else {
          toast.error(UPDATE_FAILURE_ERROR);
        }
      });
  };

  return (
    <>
      <Button
        variant="light"
        className="btn-white lift mb-1 position-relative"
        onClick={handleShow}
      >
        Pending Invitations
        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
          {invitationsData.length}
        </span>
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title className="m-0">Invitations</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <section className="modal-scroll-content">
            <ul className="list-group list-group-flush list my-n3">
              {invitationsData.length > 0 &&
                invitationsData.map((invite, index) => (
                  <li className="list-group-item px-0" key={index}>
                    <Row>
                      <Col>
                        {invite.projects ? (
                          <h5>{invite.projects.title}</h5>
                        ) : (
                          <h5>
                            {invite.team.lastName
                              ? `${invite.team.firstName} ${invite.team.lastName}`
                              : `${invite.team.firstName} s team`}
                          </h5>
                        )}
                      </Col>
                      <Col className="col-auto">
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={() =>
                            handleInvitationStatusUpdate({
                              status: 'accept',
                              id: invite._id,
                            })
                          }
                        >
                          Accept
                        </Button>
                        <Button
                          variant="light"
                          size="sm"
                          className="btn-white ms-2"
                          onClick={() =>
                            handleInvitationStatusUpdate({
                              status: 'decline',
                              id: invite._id,
                            })
                          }
                        >
                          Decline
                        </Button>
                      </Col>
                    </Row>
                  </li>
                ))}
            </ul>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

ProjectInvitationsModal.propTypes = {
  invitationsData: PropTypes.array,
};

export default ProjectInvitationsModal;
