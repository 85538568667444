import React, { memo } from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { DATE_AND_TIME } from '../../constants';
import { updateTaskDudeDate } from '../../actions/dashboard';
import '../../assets/styles/full-calendar.scss';

const Calendar = ({ taskData }) => {
  const dispatch = useDispatch();

  // Event block and content
  const renderEventContent = (eventInfo) => (
    <>
      <div className="p-2 text-wrap">
        <p className="calender-task-title mb-0">
          <strong
            className={
              eventInfo.event.extendedProps.status === 'complete'
                ? 'text-decoration-line-through'
                : ' '
            }
          >
            {eventInfo.event.title}
          </strong>
        </p>

        {eventInfo.event.extendedProps.status === 'complete' && (
          <span className="text-dark text-decoration-line-through pt-3">
            Completed on{' '}
            {moment(eventInfo.event.extendedProps.completedDate).format(
              DATE_AND_TIME.DATE_RANGE
            )}
            <br />
          </span>
        )}
      </div>
    </>
  );

  // handleChangeDudeDate on drag and drop
  const handleChangeDueDate = (task) => {
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      taskId: task.event.id,
      dueDate: moment(task.event.start)
        .format(DATE_AND_TIME.DATE_RANGE_IN_STATE)
        .concat(' 00:00:00'),
    };
    dispatch(updateTaskDudeDate(params));
  };

  // New array with required items for creating the event calender
  const tasksWithDueDate =
    (taskData.length > 0 &&
      taskData.map((item) => {
        let taskItem = {};
        if (item.dueDate) {
          taskItem = {
            id: item._id,
            title: item.task,
            start: moment.utc(item.dueDate).format('YYYY-MM-DD'),
            status: item.status,
            allDay: true,
            editable: item.status !== 'complete',
            completedDate: item.completedDate,
          };
          // Assign color based on the task status
          if (item.status === 'complete') {
            taskItem.color = '#2C7BE5';
          } else if (item.status === 'over due') {
            taskItem.color = '#39AFD1';
          } else {
            taskItem.color = '#ABDEE6';
          }
        }
        return taskItem;
      })) ||
    [];

  return (
    <Card className="card-calendar">
      <Card.Body className="p-0">
        <section className="hd-calendar">
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'title',
              right: 'prev next dayGridMonth dayGridWeek dayGridDay',
            }}
            defaultView="dayGridMonth"
            dayMaxEvents={3}
            events={tasksWithDueDate}
            eventContent={renderEventContent}
            eventDisplay="block"
            eventDrop={(e) => handleChangeDueDate(e)}
            slotEventOverlap={false}
            displayEventTime={false}
            buttonIcons={{
              prev: ' mdi mdi-arrow-left',
              next: ' mdi mdi-arrow-right',
            }}
          />
        </section>
      </Card.Body>
    </Card>
  );
};

Calendar.propTypes = {
  taskData: PropTypes.array,
};

export default memo(Calendar);
