import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import moment from 'moment';
import Layout from './DashboardLayout';
import Loading from '../components/Loader';
import { timesheetService } from '../services';
import {
  ViewTimesheetContent,
  ViewTimeCardContent,
} from '../components/timesheet';
import { getUserData } from '../helpers';
import { trackJune } from '../utilities/analytics';

class TimesheetView extends Component {
  state = {
    isLoading: false,
    timesheetDetails: [],
    timecardDetails: [],
    startDate: moment().subtract(3, 'w').startOf('week'),
    endDate: moment().endOf('week'),
  };

  pageHeading = {
    preTitle: 'Timesheet',
    title: 'View',
  };

  componentDidMount() {
    this.getTimesheetViewData();
  }

  getTimesheetViewData = (fromDate, toDate) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const { startDate, endDate, timesheetDetails, timecardDetails } =
      this.state;
    const dataFromDate = fromDate
      ? moment(fromDate).format('YYYY-MM-DD')
      : moment(startDate).format('YYYY-MM-DD');
    const dataToDate = toDate
      ? moment(toDate).format('YYYY-MM-DD')
      : moment(endDate).format('YYYY-MM-DD');
    const teamOwnerId = localStorage.getItem('teamOwnerId');

    Promise.all([
      timesheetService.view_timesheet({
        teamOwnerId,
        fromDate: dataFromDate.concat(' 00:00:00'),
        toDate: dataToDate.concat(' 23:59:59'),
        groupBy: 'project',
      }),
      timesheetService.view_timesheet({
        teamOwnerId,
        fromDate: dataFromDate.concat(' 00:00:00'),
        toDate: dataToDate.concat(' 23:59:59'),
        groupBy: 'date',
      }),
    ])
      .then((results) => {
        const [timesheetView, timecardView] = results;
        this.setState((state) => ({
          ...state,
          isLoading: false,
          timesheetDetails:
            fromDate && toDate
              ? [...timesheetView.data.timesheetDetails]
              : [...timesheetDetails, ...timesheetView.data.timesheetDetails],
          timecardDetails:
            fromDate && toDate
              ? [...timecardView.data.timesheetDetails]
              : [...timecardDetails, ...timecardView.data.timesheetDetails],
          startDate: fromDate
            ? moment(fromDate).subtract(4, 'w')
            : moment(startDate).subtract(4, 'w'),
          endDate: fromDate
            ? moment(fromDate).subtract(1, 'd')
            : moment(startDate).subtract(1, 'd'),
        }));

        // track june
        trackJune('timesheet approval (view)');
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  handleSubmitTimesheet = (data) => {
    const teamOwnerId = localStorage.getItem('teamOwnerId');
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));

    timesheetService
      .submit_timesheet({
        teamOwnerId,
        weekStartDate: moment(data.startDate).format('YYYY-MM-DD hh:mm:ss'),
        weekEndDate: moment(data.endDate).format('YYYY-MM-DD hh:mm:ss'),
        status: data.status,
        note: data.note,
      })
      .then((result) => {
        if (result.status) {
          this.getTimesheetViewData(
            moment().subtract(4, 'w').endOf('week'),
            moment().endOf('week')
          );
        }
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  handleWeeklyStatusUpdate = (data) => {
    const memberId = getUserData('_id');
    const teamOwnerId = localStorage.getItem('teamOwnerId');
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));

    timesheetService
      .status_update({
        teamOwnerId,
        weeklySubmissionId: data.weeklySubmissionId,
        teamMemberId: memberId,
        status: data.status,
        note: data.note,
      })
      .then((result) => {
        if (result.status) {
          this.getTimesheetViewData(
            moment().subtract(4, 'w').endOf('week'),
            moment().endOf('week')
          );
        }
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  render() {
    const { isLoading, timesheetDetails, timecardDetails } = this.state;
    return (
      <Layout pageHeading={this.pageHeading}>
        <Tabs defaultActiveKey="view-timesheet" id="view-tabs" className="mb-5">
          <Tab eventKey="view-timesheet" title="Timesheet">
            <ViewTimesheetContent
              timesheetDetails={timesheetDetails}
              isLoading={isLoading}
              loadMoreData={this.getTimesheetViewData}
              submitTimesheet={this.handleSubmitTimesheet}
              statusUpdate={this.handleWeeklyStatusUpdate}
            />
          </Tab>
          <Tab eventKey="view-timecard" title="Timecard">
            <ViewTimeCardContent
              timecardDetails={timecardDetails}
              isLoading={isLoading}
              loadMoreData={this.getTimesheetViewData}
              submitTimesheet={this.handleSubmitTimesheet}
              statusUpdate={this.handleWeeklyStatusUpdate}
            />
          </Tab>
        </Tabs>
        {/* Loader */}
        {isLoading && <Loading contentAreaOnly />}
      </Layout>
    );
  }
}

export default TimesheetView;
