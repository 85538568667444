import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Button, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { projectService } from '../../services';
import Loading from '../Loader';
import { convertSecondsToHoursAndMinutes } from '../../helpers';
import { NO_DATA, API_FAILURE_ERROR } from '../../constants';
import { DeleteProjectButton } from '.';
import {
  fetchMemberProjects, // To update project list on dropdown
} from '../../actions/projects';

const pageNumber = 1;
const pageLimit = 6;

const ArchivedProject = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((newstate) => newstate.userReducer);
  const [state, setState] = useState([]);
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [isLoading, setLoading] = useState(true);
  const [loadProjectData, setloadProjectData] = useState(true);
  const history = useHistory();
  const sendToActiveProject = () => history.push('/projects/active');

  // delete Archived Project
  const deleteproject = (deleteProjectId) => {
    projectService
      .update_project_status({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        projectId: deleteProjectId,
        status: 'delete',
      })
      .then((response) => {
        if (response.message === 'Success') {
          const newprojects = state.filter(
            (projects) => projects._id !== deleteProjectId
          );
          setState([...newprojects]);
          sendToActiveProject();
        }
      })
      .catch(() => {
        toast.error(API_FAILURE_ERROR);
      });
  };
  // Restore Archived Project
  function restoreproject(restoreProjectId) {
    projectService
      .update_project_status({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        projectId: restoreProjectId,
        status: 'active',
      })
      .then((response) => {
        if (response.message === 'Success') {
          const refreshProjects = state.filter(
            (projects) => projects._id !== restoreProjectId
          );
          setState([...refreshProjects]);
          sendToActiveProject();
          dispatch(fetchMemberProjects());
        }
      })
      .catch(() => {
        toast.error(API_FAILURE_ERROR);
      });
  }

  useEffect(() => {
    // List Archived Projects
    projectService
      .get_projects_data({
        teamOwnerId: localStorage.getItem('teamOwnerId'), // @ToDo: Move this to reducer or react-context API
        page: currentPage,
        limit: pageLimit,
        summaryList: false,
        filter: '{"projectStatus":"close"}',
      })
      .then((response) => {
        if (!('projects' in response.data)) {
          response.data = {
            projects: [],
            metadata: {},
          };
        }
        const { metadata, projects } = response.data;

        setState([...state, ...projects]);
        setLoading(false);
        if (Math.ceil(metadata.total / pageLimit) <= currentPage) {
          setloadProjectData(false);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, [currentPage]);

  const updateCurrenPageNumber = () => {
    // Trigger only if more contents are available
    if (loadProjectData) {
      setCurrentPage(currentPage + 1);
    }
  };

  window.onscroll = function () {
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = window.scrollY;

    // If the user is reached bottom of the page
    if (Math.ceil(scrolled) === scrollable) {
      updateCurrenPageNumber();
    }
  };

  return isLoading ? (
    <Loading contentAreaOnly />
  ) : (
    <section className="project-list-view">
      {state.length > 0 ? (
        state.map((project, index) => {
          // Get the totalTasks count
          const totalTasks = project.tasks.reduce(
            (total, task) => total + task.tasks,
            0
          );
          // Get archived Date
          const archivedDate = project.closedDate;
          const { totalWorkSessionSeconds } = project;
          const totalHours =
            totalWorkSessionSeconds > 0 &&
            convertSecondsToHoursAndMinutes(totalWorkSessionSeconds);

          return (
            <div className="card mt-0" key={index}>
              <div className="card-body">
                <Row className="project-title" noGutters>
                  <Col>
                    <>
                      <h4 className="mb-2 name">{project.title}</h4>
                      <p className="small text-muted mb-3">
                        {project.lastWorkSession !== null ? (
                          <time className="small text-muted">
                            last worked{' '}
                            {moment(project.lastWorkSession).fromNow()}
                          </time>
                        ) : (
                          <span className="card-text small text-muted mb-1">
                            {NO_DATA.PROJECT_WORKSESSION_DATA}
                          </span>
                        )}
                      </p>
                    </>
                  </Col>
                </Row>

                <div className="row no-gutters task-and-time-info ml-0 mr-0">
                  <div className="col">
                    {archivedDate ? (
                      <>
                        <h6 className="text-uppercase text-muted">
                          Archived date
                        </h6>

                        <h2 className="mb-0">
                          {moment.utc(archivedDate).format('MMM DD,YYYY')}
                        </h2>
                      </>
                    ) : (
                      <div className="col-auto">
                        <span className="badge bg-secondary-soft">
                          Not Available
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col border-left">
                    <h6 className="text-uppercase text-muted">Total hours</h6>
                    {totalWorkSessionSeconds > 0 ? (
                      <h2 className="mb-0">{totalHours}</h2>
                    ) : (
                      <h2 className="mb-0">0h 0m</h2>
                    )}
                  </div>
                </div>

                <div className="row align-items-center">
                  <div className="col">
                    <div className="row align-items-center no-gutters">
                      <h6 className="text-uppercase text-muted">Total tasks</h6>
                      {totalTasks > 0 ? (
                        <h2 className="mb-0">{totalTasks}</h2>
                      ) : (
                        <h2 className="mb-0">0</h2>
                      )}
                    </div>
                  </div>
                  <div className="col-auto ml-auto">
                    {userDetails.role !== 'member' && (
                      <div>
                        {userDetails.role === 'creator' && (
                          <Button
                            variant="primary"
                            size="sm"
                            className="ml-auto me-2"
                            onClick={() => restoreproject(project._id)}
                          >
                            Restore
                          </Button>
                        )}
                        <DeleteProjectButton
                          projectId={project._id}
                          handleDelete={deleteproject}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="card">
          <div className="card-body d-flex justify-content-center">
            <div className="alert alert-info">No archived projects </div>
          </div>
        </div>
      )}
    </section>
  );
};
export default ArchivedProject;
