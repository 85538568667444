import { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from './DashboardLayout';
import {
  ProjectSearch,
  ActiveProject,
  ProjectInvitationsModal,
} from '../components/project';
import SwitchView from '../components/SwitchView';
import { fetchInvitations } from '../actions/team';
import { resetProjectsList, fetchMemberProjects } from '../actions/projects';

class Project extends Component {
  state = {
    isListView: false,
    invitationsData: {},
  };

  pageHeading = {
    preTitle: 'Projects',
    title: 'Active Projects',
  };

  componentDidMount() {
    const { getInvitations, getMemberProjects } = this.props;
    getInvitations();
    getMemberProjects();
  }

  componentWillUnmount() {
    const { resetProjects } = this.props;
    resetProjects();
  }

  handleChangeView = () => {
    this.setState((state) => ({
      ...state,
      isListView: !state.isListView,
    }));
  };

  render() {
    const { isListView } = this.state;
    const { userData, invitationsData } = this.props;
    return (
      <Layout pageHeading={this.pageHeading}>
        <Row className="mb-4">
          <Col md="6" lg="3">
            <ProjectSearch />
          </Col>
          <Col>
            <SwitchView
              isListView={isListView}
              handleChangeView={this.handleChangeView}
            />
          </Col>
          <Col className="col-auto ml-auto">
            {invitationsData && invitationsData.length > 0 && (
              <ProjectInvitationsModal invitationsData={invitationsData} />
            )}
            {userData.role !== 'member' && (
              <Button
                variant="primary"
                className="lift mb-1 ms-3"
                as={Link}
                to="/projects/create"
              >
                Create Project
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <ActiveProject isListView={isListView} />
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userReducer,
  invitationsData: state.team.invitationsData,
});
const mapDispatchToProps = () => ({
  getInvitations: fetchInvitations,
  getMemberProjects: fetchMemberProjects,
  resetProjects: resetProjectsList,
});

Project.propTypes = {
  userData: PropTypes.object,
  getInvitations: PropTypes.func,
  resetProjects: PropTypes.func,
  invitationsData: PropTypes.object,
  getMemberProjects: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps())(Project);
