// set/get Tour Step Index
export const fetchTourStepIndex = () => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_TOUR_STEP_INDEX' });
  } catch {
    dispatch({ type: 'FETCH_TOUR_STEP_INDEX_ERROR' });
  }
};

export const setTourStepIndex = (data) => ({
  type: 'SET_TOUR_STEP_INDEX',
  payload: data,
});

// set/get track task time
export const fetchTourTrackTaskTime = () => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_TOUR_TRACK_TASK_TIME' });
  } catch {
    dispatch({ type: 'FETCH_TOUR_TRACK_TASK_TIME_ERROR' });
  }
};

export const setTourTrackTaskTime = (data) => ({
  type: 'SET_TOUR_TRACK_TASK_TIME',
  payload: data,
});
