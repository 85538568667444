import { BASE_API_URL, TIMESHEET_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

const options = AppService.options();

export const timesheetService = {
  get_timesheet_data: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMESHEET_URLS.TIMESHEET,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  email_timesheet_report: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMESHEET_URLS.EMAIL_REPORT,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  view_timesheet: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMESHEET_URLS.VIEW,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  timecard_report: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMESHEET_URLS.TIMECARD_REPORT,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  submit_timesheet: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMESHEET_URLS.SUBMISSION,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  status_update: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMESHEET_URLS.STATUS_UPDATE,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  approvals_timesheet: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMESHEET_URLS.APPROVAL,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
};
