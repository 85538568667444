import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

const DateRangePickerField = ({
  handleDateSelect,
  isValidate,
  handleDateRangeValidation,
  numberOfDaysSelected,
  startFrom,
  showRangeOptions = false,
  preDefinedRanges = {},
  daysLimit,
  fieldLabel = 'Date range',
  maxSpan,
}) => {
  const startFromDate = () => {
    switch (startFrom) {
      case 'yesterday':
        return moment().subtract(1, 'days').toDate();
      case 'last 7 days':
        return moment().subtract(6, 'days').toDate();
      case 'this week':
        return moment().startOf('week').toDate();
      case '1 month':
        return moment().subtract(1, 'month').toDate();
      case 'month start':
        return moment().startOf('month').toDate();
      default:
        return moment().toDate();
    }
  };
  const settings = {
    locale: {
      format: 'MMM D, YYYY',
    },
  };
  if (showRangeOptions) {
    settings.ranges = preDefinedRanges;
    settings.alwaysShowCalendars = true;
  }
  if (maxSpan && maxSpan > 0) {
    settings.maxSpan = {
      days: maxSpan,
    };
  }
  return (
    <Form.Group>
      <Form.Label>{fieldLabel}</Form.Label>
      {isValidate ? (
        <>
          <DateRangePicker
            onEvent={handleDateSelect}
            onCallback={handleDateRangeValidation}
            initialSettings={{
              startDate: startFromDate(),
              endDate: moment().toDate(),
              ...settings,
            }}
          >
            <input type="text" className="form-control icon-calendar" />
          </DateRangePicker>
          {numberOfDaysSelected > daysLimit && (
            <small className="text-danger">{`Please try within ${daysLimit} days range.`}</small>
          )}
        </>
      ) : (
        <DateRangePicker
          onEvent={handleDateSelect}
          initialSettings={{
            startDate: startFromDate(),
            endDate: moment().toDate(),
            ...settings,
          }}
        >
          <input type="text" className="form-control icon-calendar" />
        </DateRangePicker>
      )}
    </Form.Group>
  );
};

DateRangePickerField.propTypes = {
  handleDateSelect: PropTypes.func,
  isValidate: PropTypes.bool,
  handleDateRangeValidation: PropTypes.func,
  numberOfDaysSelected: PropTypes.number,
  startFrom: PropTypes.string,
  showRangeOptions: PropTypes.bool,
  preDefinedRanges: PropTypes.object,
  daysLimit: PropTypes.number,
  fieldLabel: PropTypes.string,
  maxSpan: PropTypes.number,
};

export default DateRangePickerField;
