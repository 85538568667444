import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { groupBy, sToHMSColonSeparated } from '../../helpers';

const ExportReport = ({
  reportInfo,
  timesheet,
  grandTotal,
  isSummarizedReport,
  isMobileTracking,
  showNotes,
}) => {
  let colSpan = 5;
  const sortedTimesheet = timesheet
    .sort((a, b) => b.checkInDate - a.checkInDate)
    .sort(
      (c, d) =>
        moment(d.checkInDate).format('hh:mm:ss A') -
        moment(c.checkInDate).format('hh:mm:ss A')
    );
  if (isMobileTracking) {
    colSpan += 1;
  }
  const startDate = moment(reportInfo.dateRange.startDate).format(
    'MMM DD, YYYY'
  );
  const endDate = moment(reportInfo.dateRange.endDate).format('MMM DD, YYYY');
  if (!isSummarizedReport) {
    colSpan += 2;

    return (
      <div className="table-responsive" style={{ display: 'none' }}>
        <table
          id="ExportTimesheetReport"
          className="table table-sm table-nowrap table-hover"
        >
          <thead className="thead-light">
            <tr>
              <td colSpan="10" align="center" height="50">
                <h3 className="mt-5 text-center">
                  Team Member Timesheet Detail Report
                </h3>
              </td>
            </tr>
            <tr>
              <td colSpan="5" align="left">
                <small>
                  Date Range: {startDate} - {endDate}
                </small>
              </td>
              <td colSpan="5" align="right">
                <small>
                  Timezone: {reportInfo.timesheetMetadata.timeZoneFormatted}
                </small>
              </td>
            </tr>
            <tr>
              <th>Project</th>
              <th>Team Member</th>
              <th>Task</th>
              <th align="center">Worksession Date Start</th>
              <th align="center">Worksession Date End</th>
              <th align="center">Worksession Time Start</th>
              <th align="center">Worksession Time End</th>
              {isMobileTracking && <th align="center">Worksession Type</th>}
              <th align="center">
                {isSummarizedReport ? 'Logged time' : 'Duration'}
              </th>
              <th align="center">Active Time</th>
              <th align="center">Activity</th>
              <th align="right">
                Cost (
                {reportInfo.timesheetMetadata.defaultCurrency == null
                  ? 'USD'
                  : reportInfo.timesheetMetadata.defaultCurrency}
                )
              </th>
              {showNotes && <th align="center"> Notes</th>}
            </tr>
          </thead>
          <tbody>
            {sortedTimesheet.map((worksession, index) => (
              <tr key={index}>
                <td>
                  {worksession.projectTitle}
                  {worksession.projectStatus === 'close' ? ' (Closed)' : ''}
                </td>
                <td>
                  {worksession.userName}
                  {worksession.userTeamStatus === 'inactive' && ' (Inactive)'}
                </td>
                <td>{worksession.task ? worksession.task : '--'}</td>
                <td align="center">
                  {worksession.checkInDate &&
                    moment(worksession.checkInDate).format('ll')}
                </td>

                <td align="center">
                  {worksession.checkOutDate &&
                    moment(worksession.checkOutDate).format('ll')}
                </td>

                <td align="center">
                  {worksession.checkInDate &&
                    moment(worksession.checkInDate).format('hh:mm:ss A')}
                </td>

                <td align="center">
                  {worksession.checkOutDate &&
                    moment(worksession.checkOutDate).format('hh:mm:ss A')}
                </td>

                {isMobileTracking && (
                  <td className="text-capitalize" align="center">
                    {worksession.workSessionType
                      ? worksession.workSessionType.charAt(0).toUpperCase() +
                        worksession.workSessionType.substr(1).toLowerCase()
                      : 'Desktop'}
                  </td>
                )}
                <td align="center">
                  {sToHMSColonSeparated(
                    Math.round(worksession.workSessionSeconds)
                  )}
                </td>
                <td align="center">
                  {sToHMSColonSeparated(Math.round(worksession.activeSeconds))}
                </td>
                <td align="center">
                  {worksession.workSessionType === 'desktop'
                    ? `${
                        worksession.activity > 0
                          ? Math.round(worksession.activity)
                          : 0
                      }
                    %`
                    : 'N/A'}
                </td>
                <td align="right">
                  {worksession.rate.costRate == null
                    ? '--'
                    : (
                        Math.round(
                          ((Math.round(worksession.workSessionSeconds) / 3600) *
                            worksession.rate.hourlyCostRate +
                            Number.EPSILON) *
                            100
                        ) / 100
                      ).toFixed(2)}
                </td>
                {showNotes && (
                  <td>
                    {worksession.notes.length > 0
                      ? worksession.notes
                          .map((noteEntry) => noteEntry.note)
                          .join(', ')
                      : '--'}
                  </td>
                )}
              </tr>
            ))}
            <tr className="table-light">
              <th colSpan={colSpan} align="right">
                <strong>
                  <span className="h4">Total:</span>
                </strong>
              </th>
              <th align="center">
                <strong>
                  <span className="h4">
                    {sToHMSColonSeparated(grandTotal.duration)}
                  </span>
                </strong>
              </th>
              <th align="center">
                <strong>
                  <span className="h4">
                    {sToHMSColonSeparated(grandTotal.activeTime)}
                  </span>
                </strong>
              </th>
              <th align="center">
                <strong>
                  <span className="h4">{Math.round(grandTotal.activity)}%</span>
                </strong>
              </th>
              <th align="right">
                <strong>
                  <span className="h4">{grandTotal.cost.toFixed(2)}</span>
                </strong>
              </th>
            </tr>
            <tr>
              <th colSpan={colSpan}>
                <strong>
                  <span className="h4">*Average only for desktop sessions</span>
                </strong>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  // Group by team member
  const memberTimesheet = groupBy(timesheet, 'userId');
  return (
    <div className="table-responsive" style={{ display: 'none' }}>
      <table
        id="ExportTimesheetReport"
        className="table table-sm table-nowrap table-hover"
      >
        <thead className="thead-light">
          <tr>
            <td colSpan="9" align="center" height="50">
              <h3 className="mt-5 text-center">
                Team Member Timesheet Summarized Report
              </h3>
            </td>
          </tr>
          <tr>
            <td colSpan="4" align="left">
              <small>
                Date Range: {startDate} - {endDate}
              </small>
            </td>
            <td colSpan="5" align="right">
              <small>
                Timezone: {reportInfo.timesheetMetadata.timeZoneFormatted}
              </small>
            </td>
          </tr>
          <tr>
            <th>Team Member</th>
            <th>Project</th>
            <th align="center">Hourly Rate</th>
            {isMobileTracking && <th align="center">Worksession Type</th>}
            <th align="center">Logged time</th>
            <th align="center">Active Time</th>
            <th align="center">Activity</th>
            <th align="right">
              Cost (
              {reportInfo.timesheetMetadata.defaultCurrency == null
                ? 'USD'
                : reportInfo.timesheetMetadata.defaultCurrency}
              )
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(memberTimesheet).map((key, value) => {
            let memberSession = [...key[1]];
            let totalCost = 0;
            memberSession = [
              ...memberSession
                .reduce((r, o) => {
                  const gKey = isMobileTracking
                    ? `${o.projectId}-${o.userName}-${o.workSessionType}`
                    : `${o.projectId}-${o.userName}`;

                  const item = r.get(gKey) || {
                    ...o,
                    activeSeconds: 0,
                    activity: 0,
                    idleSeconds: 0,
                    projectTitle: '',
                    hourlyRate: 0,
                    userName: '',
                    workSessionSeconds: 0,
                    activityActiveTime: 0,
                    activityActiveDuration: 0,
                  };

                  item.activeSeconds += Math.round(o.activeSeconds);
                  if (
                    !o.isManual &&
                    o.workSessionType.toLowerCase() === 'desktop'
                  ) {
                    item.activityActiveTime += Math.round(o.activeSeconds);
                    item.activityActiveDuration += Math.round(
                      o.workSessionSeconds
                    );
                  }
                  item.idleSeconds += Math.round(o.idleSeconds);
                  item.projectTitle =
                    o.projectTitle +
                    (o.projectStatus === 'close' ? ' (Closed)' : '');
                  item.hourlyRate = o.rate.hourlyCostRate;
                  item.userName =
                    o.userName +
                    (o.userTeamStatus === 'inactive' ? ' (Inactive)' : '');
                  item.workSessionSeconds += Math.round(o.workSessionSeconds);
                  // Member totalCost
                  const cost =
                    !o.workSessionSeconds || !o.rate.hourlyCostRate
                      ? 0
                      : Math.round(
                          (Math.round(o.workSessionSeconds) / 3600) *
                            o.rate.hourlyCostRate *
                            100
                        ) / 100;
                  totalCost += Math.round((cost + Number.EPSILON) * 100) / 100;
                  return r.set(gKey, item);
                }, new Map())
                .values(),
            ];
            memberSession.map(
              (worksession, index) =>
                (memberSession[index].activity =
                  worksession.activityActiveDuration > 0
                    ? (worksession.activityActiveTime /
                        worksession.activityActiveDuration) *
                      100
                    : 0)
            );

            // Member totalDuration
            const totalDuration = memberSession.reduce(function (sum, val) {
              return sum + Math.round(val.workSessionSeconds);
            }, 0);

            // Member totalActiveTime
            const totalActiveTime = memberSession.reduce(function (sum, val) {
              return sum + Math.round(val.activeSeconds);
            }, 0);

            const totalActivityDuration = memberSession.reduce(function (
              sum,
              val
            ) {
              return sum + Math.round(val.activityActiveDuration);
            },
            0);

            const totalActivityActiveTime = memberSession.reduce(function (
              sum,
              val
            ) {
              return sum + Math.round(val.activityActiveTime);
            },
            0);

            // Member totalActivity
            const totalActivity =
              totalActivityDuration > 0
                ? (totalActivityActiveTime / totalActivityDuration) * 100
                : 0;
            return (
              <React.Fragment key={value}>
                {memberSession.map((worksession, index) => (
                  <tr key={index}>
                    <td>{index === 0 ? worksession.userName : ''}</td>
                    <td>{worksession.projectTitle}</td>
                    <td align="center">
                      {worksession.rate.hourlyCostRate
                        ? worksession.rate.hourlyCostRate
                        : '--'}
                    </td>
                    {isMobileTracking && (
                      <td className="text-capitalize" align="center">
                        {worksession.workSessionType
                          ? worksession.workSessionType
                              .charAt(0)
                              .toUpperCase() +
                            worksession.workSessionType.substr(1).toLowerCase()
                          : 'Desktop'}
                      </td>
                    )}
                    <td align="center">
                      {sToHMSColonSeparated(
                        Math.round(worksession.workSessionSeconds)
                      )}
                    </td>
                    <td align="center">
                      {sToHMSColonSeparated(
                        Math.round(worksession.activeSeconds)
                      )}
                    </td>
                    <td align="center">
                      {worksession.activity > 0
                        ? Math.round(worksession.activity)
                        : 0}
                      %
                    </td>
                    <td align="right">
                      {worksession.rate.costRate == null
                        ? '--'
                        : (
                            Math.round(
                              ((Math.round(worksession.workSessionSeconds) /
                                3600) *
                                worksession.rate.hourlyCostRate +
                                Number.EPSILON) *
                                100
                            ) / 100
                          ).toFixed(2)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={colSpan} align="right">
                    <strong>Total</strong>
                  </td>
                  <td align="center">
                    <strong>{sToHMSColonSeparated(totalDuration)}</strong>
                  </td>
                  <td align="center">
                    <strong>{sToHMSColonSeparated(totalActiveTime)}</strong>
                  </td>
                  <td align="center">
                    <b>{Math.round(totalActivity)}%</b>
                  </td>
                  <td align="right">
                    <b>{totalCost.toFixed(2)}</b>
                  </td>
                  {showNotes && <td>&nbsp;</td>}
                </tr>
              </React.Fragment>
            );
          })}
          <tr className="table-light">
            <th colSpan={colSpan} align="right">
              <strong>
                <span className="h4">Grant Total:</span>
              </strong>
            </th>
            <th align="center">
              <strong>
                <span className="h4">
                  {sToHMSColonSeparated(grandTotal.duration)}
                </span>
              </strong>
            </th>
            <th align="center">
              <strong>
                <span className="h4">
                  {sToHMSColonSeparated(grandTotal.activeTime)}
                </span>
              </strong>
            </th>
            <th align="center">
              <strong>
                <span className="h4">{Math.round(grandTotal.activity)}%</span>
              </strong>
            </th>
            <th align="right">
              <strong>
                <span className="h4">{grandTotal.cost.toFixed(2)}</span>
              </strong>
            </th>
          </tr>
          <tr>
            <th colSpan={colSpan}>
              <strong>
                <span className="h4">*Average only for desktop sessions</span>
              </strong>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

ExportReport.propTypes = {
  timesheet: PropTypes.any,
  reportInfo: PropTypes.object,
  grandTotal: PropTypes.object,
  isSummarizedReport: PropTypes.bool,
  isMobileTracking: PropTypes.bool,
  showNotes: PropTypes.bool,
};

export default ExportReport;
