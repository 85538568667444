import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const DeleteLink = ({ handleShow }) => (
  <Button variant="danger" size="sm" className=" ml-auto" onClick={handleShow}>
    Delete
  </Button>
);

DeleteLink.propTypes = {
  handleShow: PropTypes.func,
};

export default DeleteLink;
