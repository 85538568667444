import PropTypes from 'prop-types';
import moment from 'moment';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NO_DATA, PAGINATION_LIMIT } from '../../constants';
import Avatar from '../Avatar.jsx';
import { msToHMS } from '../../helpers';
import Pagination from '../common/Pagination';

const TeamActivityList = ({
  activityList,
  getActivityData,
  activityFormData,
  pageNumber,
  setCurrentPage,
}) => {
  const onlineMembers = useSelector((state) => state.onlineStatus.members);
  // Array with checkin date - for Grouping byDate
  let dateArr = [];
  const { activities, metadata } = activityList;
  // eslint-disable-next-line no-unused-expressions
  activities &&
    activities.map((item) =>
      item.activity.map((record) => dateArr.push(record.date))
    );
  dateArr = [...new Set(dateArr)].sort();
  if (activities) {
    return (
      <>
        <div className="table-responsive">
          <table
            id="teamActivityDateRange"
            className="table table-sm table-hover card-table table-nowrap"
          >
            <thead className="thead-light">
              <tr>
                <th>Team Member</th>
                {dateArr.map((date) => (
                  <th key={date}>
                    {moment(date).format('MMM DD')} <br />
                    {moment(date).format('ddd')}
                  </th>
                ))}
                <th>Total</th>
                <th>Activity</th>
              </tr>
            </thead>

            <tbody>
              {activities.map((activityDetails, index) => {
                const totalActivityTime = msToHMS(
                  Math.round(activityDetails.totalWorkSessionSeconds) * 1000
                );
                return (
                  <tr key={`${index}-team-activity`}>
                    <td>
                      {activityDetails.firstName && (
                        <div className="d-flex align-items-center">
                          <div
                            className={
                              onlineMembers.includes(activityDetails.userId)
                                ? `avatar me-2 avatar-online`
                                : `avatar me-2 avatar-offline`
                            }
                          >
                            <Avatar
                              firstName={activityDetails.firstName}
                              lastName={activityDetails.lastName}
                              imgPath={
                                activityDetails.avatar
                                  ? activityDetails.avatar
                                  : ''
                              }
                            />
                          </div>
                          <h4 className="mb-0">
                            {activityDetails.lastName
                              ? `${activityDetails.firstName} ${activityDetails.lastName}`
                              : activityDetails.firstName}
                            {activityDetails.userTeamStatus === 'inactive' &&
                              ' (Inactive)'}
                          </h4>
                        </div>
                      )}
                    </td>
                    {dateArr.map((date) => {
                      const obj = activityDetails.activity.find(
                        (x) => x.date === date
                      );
                      let activityTime = '';
                      if (obj) {
                        // ws seconds
                        activityTime = msToHMS(
                          Math.round(
                            activityDetails.activity[
                              activityDetails.activity.indexOf(obj)
                            ].workSessionSeconds
                          ) * 1000
                        );
                      }
                      return (
                        <td key={`date-${index}-${date}`}>
                          {obj && (
                            <Link to={`./day/${date}`}>
                              <strong>{activityTime}</strong>
                            </Link>
                          )}
                        </td>
                      );
                    })}
                    <td>
                      <b>{totalActivityTime}</b>
                    </td>
                    <td>
                      {activityDetails.activityPercentage > 0
                        ? activityDetails.activityPercentage
                        : 0}
                      %
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr className="table-light">
                <td colSpan={dateArr.length + 3}>
                  <span className="h5">
                    *Average only for desktop sessions{' '}
                  </span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <>
          {metadata && metadata.total && metadata.total > PAGINATION_LIMIT && (
            <section className="d-flex justify-content-center py-3">
              <Pagination
                className="pagination-bar"
                currentPage={pageNumber}
                totalCount={metadata.total}
                pageSize={PAGINATION_LIMIT}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  getActivityData({
                    ...activityFormData,
                    page,
                    limit: PAGINATION_LIMIT,
                  });
                }}
              />
            </section>
          )}
        </>
      </>
    );
  }
  return (
    <Alert variant="info" className="text-center mx-4">
      {NO_DATA.WORKSESSION}
    </Alert>
  );
};

TeamActivityList.propTypes = {
  activityList: PropTypes.object,
  getActivityData: PropTypes.func,
  activityFormData: PropTypes.object,
  pageNumber: PropTypes.number,
  setCurrentPage: PropTypes.func,
};

export default TeamActivityList;
