import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  IMPORT_CLIENT_TEMPLATE_URL,
  IMPORT_XLS_SIZE,
  IMPORT_FILE,
} from '../../constants';
import { clientsService } from '../../services';

const ImportClientModal = ({ updateClientList }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [inputFile, setInputFile] = useState(null);

  useEffect(() => {
    setInputFile(document.getElementById('clientsUpload'));
  }, []);

  const handleUpload = () => {
    inputFile?.click();
  };

  const uploadFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      const { files } = event.target;
      if (!files[0].name.match(/.(xls|xlsx|ods)$/i)) {
        toast.error(IMPORT_FILE.XLS_TYPE);
        return;
      }

      if (files[0].size > IMPORT_XLS_SIZE) {
        toast.error(IMPORT_FILE.XLS_SIZE);
        return;
      }
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        clientsService
          .import_client({
            teamOwnerId: localStorage.getItem('teamOwnerId'),
            file: reader.result,
          })
          .then((response) => {
            setSubmitting(false);
            document.getElementById('clientsUpload').value = null;
            if (response.status) {
              const dataLength =
                'data' in response ? response.data.length : null;
              toast.success(
                `Successfully imported ${dataLength} client${
                  dataLength > 1 ? 's' : ''
                }`
              );
              updateClientList();
            } else {
              toast.error('Some error occurred while importing this data.');
            }
            handleClose();
          })
          .catch((errResp) => {
            setSubmitting(false);
            toast.error('Some error occurred while importing this data.');
            handleClose();
          });
      }; // end of file reader
    } // end if
  }; // end upload file
  const handleUploadClick = (values) => {
    setSubmitting(true);
    uploadFile(values);
  };
  return (
    <>
      <input
        className="d-none"
        type="file"
        name="clients"
        id="clientsUpload"
        accept=".xls, .xlsx"
        onChange={handleUploadClick}
      />
      <Button onClick={handleShow} variant="white" className="me-2">
        Import Clients
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="pt-4" closeButton>
          <Modal.Title>Import Clients</Modal.Title>
        </Modal.Header>
        <Formik>
          {(formikprops) => (
            <Form onSubmit={formikprops.handleSubmit}>
              <Modal.Body>
                <section>
                  <Form.Group controlId="description">
                    <Form.Label className="text-muted text-center">
                      Please upload an Excel file containing client details in
                      the specified template format&nbsp;
                      <Link
                        to={{ pathname: `${IMPORT_CLIENT_TEMPLATE_URL}` }}
                        target="_blank"
                        download
                      >
                        (Import Client Template).
                      </Link>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group controlId="description" className="text-center">
                    <input
                      className="d-none"
                      type="file"
                      name="clients"
                      id="clientsUpload"
                      accept=".xls, .xlsx"
                      onChange={uploadFile}
                    />
                    {isSubmitting ? (
                      <Button variant="primary" className="me-2" disabled>
                        Importing clients...
                      </Button>
                    ) : (
                      <Button
                        onClick={handleUpload}
                        variant="primary"
                        className="me-2"
                      >
                        Upload File
                      </Button>
                    )}
                  </Form.Group>
                </section>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

ImportClientModal.propTypes = {
  updateClientList: PropTypes.func,
};

export default ImportClientModal;
