import PropTypes from 'prop-types';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { WEB_URL, SUPPORT_URL, CLIENT_DOWNLOAD_URL } from '../constants';

const LoginForm = ({ handleLogin, isSubmitting }) => (
  <Formik
    initialValues={{
      username: '',
      password: '',
      grant_type: 'password',
    }}
    validationSchema={Yup.object({
      username: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter your email address'),
      password: Yup.string().required('Please enter your password'),
    })}
    onSubmit={(values) => {
      handleLogin(values);
    }}
  >
    {(formikprops) => (
      <Form onSubmit={formikprops.handleSubmit} className="needs-validation">
        <Form.Group controlId="formEmail">
          <Form.Label>Email Address</Form.Label>
          <Field
            className="form-control"
            type="email"
            placeholder="Enter your email address"
            name="username"
          />

          <ErrorMessage
            name="username"
            render={(msg) => <small className="text-danger">{msg}</small>}
          />
        </Form.Group>
        <Form.Group controlId="formPassword" className="passwordBlock">
          <Row>
            <Col>
              <Form.Label>Password</Form.Label>
            </Col>
            <Col className="col-auto ml-auto">
              <Link
                className="form-text small text-muted"
                to="/forgot-password"
              >
                Forgot password?
              </Link>
            </Col>
          </Row>

          <Field
            className="form-control"
            type="password"
            placeholder="Enter your password"
            name="password"
          />
          <ErrorMessage
            name="password"
            render={(msg) => <small className="text-danger">{msg}</small>}
          />
        </Form.Group>
        <Form.Group controlId="buttonSubmit" className="">
          <Button
            variant="primary"
            type="submit"
            className="btn btn-lg btn-block btn-primary mb-3 w-100"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Sign in'
            )}
          </Button>
          <div className="text-center mb-2">
            <small className="text-muted text-center">
              Don't have an account yet?{' '}
              <a href={`${WEB_URL}freetrial`} target="_blank" rel="noreferrer">
                Sign up
              </a>
            </small>
          </div>
          <div className="text-center">
            <small className="text-muted text-center">
              <a href={SUPPORT_URL} target="_blank" rel="noreferrer">
                Contact support
              </a>
            </small>
          </div>
          <div className="text-center">
            <small className="text-muted text-center">
              <a href={CLIENT_DOWNLOAD_URL} target="_blank" rel="noreferrer">
                Download HiveDesk Client
              </a>
            </small>
          </div>
        </Form.Group>
      </Form>
    )}
  </Formik>
);

LoginForm.propTypes = {
  handleLogin: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default LoginForm;
