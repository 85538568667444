import { jsPDF as JSPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { sToHMSColonSeparated, groupBy } from '../../helpers';
import { HELPER_TEXT_BILLABLE_BREAKS } from '../../constants';

const WeeklyTimeCardPdfReport = (timesheetDetails) => {
  // initialize jsPDF
  const doc = new JSPDF('l', 'pt', 'a3');
  const totalPagesExp = '{total_pages_count_string}';
  const pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  // Table columns
  const tableColumns = {};
  for (let i = 0; i < 20; i += 1) {
    tableColumns[i] = {
      halign: i === 0 ? 'left' : 'center',
    };
  }

  const weeklyStatus = (status) => {
    switch (status) {
      case 'submit':
        return 'Submitted';
      case 'approve':
        return 'Approved';
      case 'reject':
        return 'Rejected';
      case 'withdraw':
        return 'Withdrawn';
      default:
        return 'Not Submitted';
    }
  };

  const breakTypes = timesheetDetails
    .flatMap((item) =>
      item.data.flatMap((day) => {
        if (day.workSessions.length > 0 && day.totalBreakTimeSeconds > 0) {
          return day.workSessions
            .filter((dItem) => dItem.breakTimeSeconds > 0)
            .map((dItem) => dItem.break);
        }
        return [];
      })
    )
    .flat();

  const breakTypeGroups = groupBy(breakTypes, 'type');
  let breakTypeHeaders = [];
  let breakTypeColumnHeaders = [];

  // Table body columns
  const tableRows = [];

  const calculateTotalBreakTime = (breaks) => {
    const totalTimes = Object.keys(breaks).reduce((acc, breakType) => {
      const key = breakType === 'null' ? 'Other' : breakType; // Rename 'undefined' breakType to 'Other'
      const totalBreakTime = breaks[breakType].reduce(
        (sum, breakInstance) => sum + breakInstance.breakTime,
        0
      );
      acc[key] = totalBreakTime;
      return acc;
    }, {});

    return totalTimes;
  };

  timesheetDetails.forEach((weekData) => {
    doc.setFontSize(10);
    doc.text(
      `Period: ${moment
        .utc(weekData.wsWeekDetails.startDate)
        .format('MMM DD, YYYY')} - ${moment
        .utc(weekData.wsWeekDetails.endDate)
        .format('MMM DD, YYYY')}`,
      40,
      doc.lastAutoTable ? doc.lastAutoTable.finalY + 60 : 60,
      'left'
    );
    doc.setFontSize(10);
    doc.text(
      HELPER_TEXT_BILLABLE_BREAKS,
      1150,
      doc.lastAutoTable ? doc.lastAutoTable.finalY + 60 : 60,
      'right'
    );

    breakTypeHeaders = Object.values(breakTypeGroups).map((bItem) =>
      bItem[0].type && bItem[0].breakName
        ? { breakName: bItem[0].breakName, type: bItem[0].type }
        : { breakName: 'Other', type: 'Other' }
    );

    breakTypeColumnHeaders = Object.entries(breakTypeGroups).map(
      ([key, value]) =>
        value[0].type && value[0].breakName
          ? `${value[0].breakName} ${value[0].billable ? ' *' : ''}`
          : 'Other'
    );

    let allBreakTypesList = [];
    let breakTypeArray = [];
    weekData.data.forEach((day) => {
      if (day.workSessions.length > 0 && day.totalBreakTimeSeconds > 0) {
        day.workSessions.map((item) =>
          item.breakTimeSeconds > 0
            ? (allBreakTypesList = [...allBreakTypesList, ...item.break])
            : null
        );
      }
      breakTypeArray = groupBy(allBreakTypesList, 'type');
    });

    const userTakenBreaks = calculateTotalBreakTime(breakTypeArray);
    const breakTimeLogs = breakTypeHeaders.map((breakItem) =>
      Object.keys(userTakenBreaks).includes(breakItem.type)
        ? sToHMSColonSeparated(
            userTakenBreaks[breakItem.type] / 1000,
            'hh:mm:ss'
          )
        : '-'
    );

    const worksessionData = [
      `${weekData.user.firstName} ${weekData.user.lastName || ''}`,
      `${weeklyStatus(weekData.status)}`,
      weekData.totalWorkSessionSeconds > 0
        ? sToHMSColonSeparated(weekData.totalWorkSessionSeconds, 'hh:mm:ss')
        : '00:00:00',
      weekData.totalWorkSessionSeconds > 0
        ? sToHMSColonSeparated(
            weekData.totalWorkSessionSeconds -
              weekData.totalNonBillableBreakTimeSeconds,
            'hh:mm:ss'
          )
        : '00:00:00',
      weekData.totalBreakTimeSeconds > 0
        ? sToHMSColonSeparated(weekData.totalBreakTimeSeconds, 'hh:mm:ss')
        : '00:00:00',
      ...breakTimeLogs,
    ];

    // push each session's info into a row
    tableRows.push(worksessionData);
  });

  // Table header columns
  const tableColumn = [
    'Team Member',
    'Status',
    'Total Duration',
    'Productive',
    'Total Breaks',
    ...breakTypeColumnHeaders,
  ];

  // Initialize autoTable
  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    // foot: [tableFoot],
    startY: doc.lastAutoTable ? doc.lastAutoTable.finalY + 75 : 75,
    theme: 'grid',
    styles: { cellPadding: 10 },
    headStyles: {
      fillColor: [237, 242, 249],
      // fontSize: 9,
      textColor: [40, 62, 89],
      halign: 'center',
    },
    columnStyles: tableColumns,
    footStyles: {
      fillColor: [237, 242, 249],
      textColor: [40, 62, 89],
    },
    tableLineColor: [237, 242, 249],
    tableLineWidth: 1,
    showFoot: 'lastPage',
    didDrawPage() {
      // Header
      doc.setFontSize(14);
      doc.setTextColor(40);
      doc.text('Timecard', pageWidth / 2, 23, 'center');
      doc.setFontSize(10);
      // Footer
      let str = `Page ${doc.internal.getNumberOfPages()}`;
      // Total page number
      if (typeof doc.putTotalPages === 'function') {
        str = `${str} of ${totalPagesExp}`;
      }
      doc.setFontSize(10);
      const { pageSize } = doc.internal;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(str, 1100, pageHeight - 22);
    },
  });

  // Total page number
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  // Name of PDF file.
  doc.save(`timecard_consolidated_report.pdf`);
};

export default WeeklyTimeCardPdfReport;
