import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from './DashboardLayout';
import { teamService, activityService } from '../services';
import Loading from '../components/Loader';
import { ActivityList } from '../components/activity';
import { getUserData } from '../helpers';
import {
  TeamMembersDropdown,
  WorkSessionTypeDropdown,
} from '../components/common';
import { DATE_RANGE_OPTIONS } from '../constants';
import { trackJune } from '../utilities/analytics';
import { endTour, startTour } from '../actions';
import { setTourStepIndex } from '../actions/freeTrialTour';
import { setOnboardingProgressForTrackedWorkSession } from '../actions/user';

let params = new URLSearchParams(window.location.search);
class MemberActivity extends Component {
  state = {
    isLoading: true,
    teamMembersList: [],
    projectsList: [],
    startDate: params.get('fromDate') || moment().format('YYYY-MM-DD'),
    endDate: params.get('toDate') || moment().format('YYYY-MM-DD'),
    activityList: 0,
    teamMember: params.get('teamMember') || null,
    activityMetadata: {},
    isFetchingActivityData: false,
    isUpdatingTeamsList: false,
    selectedMember: null,
  };

  pageHeading = {
    preTitle: 'Activity',
    title: 'Member Activity',
  };

  componentDidMount() {
    const { startInitTour, endInitTour } = this.props;
    if (localStorage.getItem('TourAutoStart')) {
      endInitTour();
      setTimeout(() => {
        startInitTour();
      }, 2000);
    }
    params = new URLSearchParams(window.location.search);
    this.getTeamsData();
    const teamMember = params.get('teamMember');
    const startDate = params.get('fromDate');
    const endDate = params.get('toDate');
    if (teamMember && startDate && endDate) {
      this.handleGenerateData({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        teamMember,
        fromDate: moment(startDate).format('YYYY-MM-DD'),
        toDate: moment(endDate).format('YYYY-MM-DD'),
      });
    } else {
      this.setState((state) => ({
        ...state,
        teamMember: null,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }));
    }
    // track june
    trackJune(null, this.pageHeading.title);
  }

  handleDateSelect = (event, picker) => {
    this.setState((state) => ({
      ...state,
      startDate: moment(picker.startDate).format('YYYY-MM-DD'),
      endDate: moment(picker.endDate).format('YYYY-MM-DD'),
    }));
  };

  getTeamsData = () => {
    this.setState((state) => ({
      ...state,
      isUpdatingTeamsList: true,
    }));
    teamService
      .get_team_members_data({
        teamOwnerId: localStorage.getItem('teamOwnerId'), // @ToDo: Move this to reducer or react-context API
        summaryList: true,
      })
      .then((response) => {
        const { members } = response.data;
        this.setState((state) => ({
          ...state,
          isLoading: false,
          teamMembersList: [...members],
          isUpdatingTeamsList: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isUpdatingTeamsList: false,
        }));
      });
  };

  setStepIndexForActivityTour = (index) => {
    const { setInitTourStepIndex, endInitTour, startInitTour } = this.props;
    endInitTour();
    setInitTourStepIndex({ stepIndex: index });
    startInitTour();
  };

  handleGenerateData = (formdata) => {
    const {
      trackTaskTimeType,
      setInitOnboardingProgressForTrackedWorkSession,
    } = this.props;
    this.setState((state) => ({
      ...state,
      isFetchingActivityData: true,
    }));

    const { startDate, endDate } = this.state;
    formdata.fromDate = startDate.concat(' 00:00:00');
    formdata.toDate = endDate.concat(' 23:59:59');
    if (formdata.workSessionType === 'all') {
      delete formdata.workSessionType;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get('project');
    if (projectId) {
      formdata.projectId = projectId;
    }
    activityService
      .get_activity_data({ ...formdata })
      .then((response) => {
        trackJune('view activity report (member)');
        if ('activityData' in response.data) {
          const { activityData, metadata } = response.data;
          this.setState((state) => ({
            ...state,
            isFetchingActivityData: false,
            activityList: activityData,
            activityMetadata: metadata,
            selectedMember: activityData[0].user,
          }));
          if (activityData && activityData.length > 0) {
            setInitOnboardingProgressForTrackedWorkSession();
          }
          if (
            trackTaskTimeType &&
            trackTaskTimeType === 'Yes, I need activity monitoring'
          ) {
            this.setStepIndexForActivityTour(3);
          }
        } else {
          if (
            trackTaskTimeType &&
            trackTaskTimeType === 'Yes, I need activity monitoring'
          ) {
            this.setStepIndexForActivityTour(2);
          }
          this.setState((state) => ({
            ...state,
            isFetchingActivityData: false,
            activityList: [],
            selectedMember: null,
          }));
        }
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isFetchingActivityData: false,
          selectedMember: null,
        }));
      });
  };

  render() {
    const {
      teamMembersList,
      startDate,
      endDate,
      isFetchingActivityData,
      isLoading,
      activityList,
      isUpdatingTeamsList,
      teamMember,
      selectedMember,
    } = this.state;
    const { userRole } = this.props;
    const {
      location: { state: fromState },
    } = this.props;
    const memberId = getUserData('_id');
    // Team owner ID
    const teamOwnerId = localStorage.getItem('teamOwnerId');
    const { isMobileTracking } = getUserData('defaultTeam');
    const bredCrumbLinks =
      fromState && fromState.bredCrumbs ? fromState.bredCrumbs : [];
    return (
      <Layout pageHeading={this.pageHeading} bredCrumbLinks={bredCrumbLinks}>
        {isLoading ? (
          <Loading contentAreaOnly />
        ) : (
          <>
            {selectedMember && (
              <h1 className="mb-5">
                {selectedMember.firstName} {selectedMember.lastName}’s activity
              </h1>
            )}
            <Formik
              initialValues={{
                teamOwnerId,
                teamMember:
                  userRole !== 'member'
                    ? teamMember || teamMembersList[0].userId
                    : memberId,
                fromDate: startDate,
                toDate: endDate,
              }}
              onSubmit={(values) => {
                this.handleGenerateData(values);
              }}
            >
              {(formikprops) => (
                <Form onSubmit={formikprops.handleSubmit} className="mb-5">
                  <Row>
                    <Col md={6} lg={4}>
                      <TeamMembersDropdown
                        teamMembersList={teamMembersList}
                        isUpdatingTeamsList={isUpdatingTeamsList}
                      />
                    </Col>
                    <Col md={6} lg={4}>
                      <Form.Group>
                        <Form.Label>Date range</Form.Label>
                        <DateRangePicker
                          onEvent={this.handleDateSelect}
                          initialSettings={{
                            startDate: moment(startDate).toDate(),
                            endDate: moment(endDate).toDate(),
                            locale: {
                              format: 'MMM D, YYYY',
                            },
                            ranges: DATE_RANGE_OPTIONS,
                            alwaysShowCalendars: true,
                          }}
                        >
                          <input
                            type="text"
                            className="form-control icon-calendar"
                          />
                        </DateRangePicker>
                      </Form.Group>
                    </Col>
                    {isMobileTracking && (
                      <Col md={6} lg={4}>
                        <WorkSessionTypeDropdown />
                      </Col>
                    )}
                    <Col md={6} lg={3}>
                      <Form.Group>
                        <Form.Label>&nbsp;</Form.Label>
                        <div>
                          <Button
                            variant="primary"
                            type="Submit"
                            disabled={isUpdatingTeamsList}
                            className="tour-for-generate"
                          >
                            Generate
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            {isFetchingActivityData && <Loading contentAreaOnly />}
            {activityList !== 0 && <ActivityList activityList={activityList} />}
          </>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { userReducer, freeTrialTourReducer } = state;
  return {
    userRole: userReducer.role,
    trackTaskTimeType: freeTrialTourReducer.trackTaskTimeType,
  };
};

const mapDispatchToProps = () => ({
  startInitTour: startTour,
  endInitTour: endTour,
  setInitTourStepIndex: setTourStepIndex,
  setInitOnboardingProgressForTrackedWorkSession:
    setOnboardingProgressForTrackedWorkSession,
});

MemberActivity.propTypes = {
  userRole: PropTypes.string,
  location: PropTypes.object,
  endInitTour: PropTypes.any,
  startInitTour: PropTypes.any,
  setInitTourStepIndex: PropTypes.any,
  trackTaskTimeType: PropTypes.any,
  setInitOnboardingProgressForTrackedWorkSession: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps())(MemberActivity);
