import PropTypes from 'prop-types';

const DeleteIcon = ({ handleShow }) => (
  <button
    type="button"
    onClick={handleShow}
    className="btn btn-sm btn-link mb-3 "
  >
    <i className="mdi mdi-window-close " />
  </button>
);

DeleteIcon.propTypes = {
  handleShow: PropTypes.func,
};

export default DeleteIcon;
