import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const MemberStatus = ({ memberId }) => {
  const onlineMembers = useSelector((state) => state.onlineStatus.members);
  return (
    <small>
      {onlineMembers.includes(memberId) ? (
        <>
          <span className="text-success font-size-lg">●</span> Online
        </>
      ) : (
        <>
          <span className="text-secondary font-size-lg">●</span> Offline
        </>
      )}
    </small>
  );
};

MemberStatus.propTypes = {
  memberId: PropTypes.string.isRequired,
};

export default MemberStatus;
