import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Form } from 'react-bootstrap';
import { ToggleSwitch } from '../common';

const TimesheetApproval = ({
  isTsheeApprovalEnabled,
  handleUpdateTSApproval,
}) => (
  <Formik
    initialValues={{
      timesheetApproval: isTsheeApprovalEnabled,
    }}
    enableReinitialize
    validationSchema={Yup.object({
      isTsheeApprovalEnabled: Yup.boolean(),
    })}
  >
    {(formikprops) => (
      <Form onSubmit={formikprops.handleSubmit} className="needs-validation">
        <Row>
          <Col>
            <h4 className="font-weight-base mb-1">Timesheet approval</h4>
            <small className="text-muted">
              This feature will allow you to review the team member's timesheet
              before sending it to payroll.
            </small>
          </Col>
          <Col className="col-auto">
            <ToggleSwitch
              id="timesheetApproval"
              name="timesheetApproval"
              handleChangeforChecked={handleUpdateTSApproval}
              checked={isTsheeApprovalEnabled}
            />
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
);
TimesheetApproval.propTypes = {
  isTsheeApprovalEnabled: PropTypes.bool,
  handleUpdateTSApproval: PropTypes.func,
};
export default TimesheetApproval;
