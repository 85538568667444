import { useState, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
  AllowedMembersAlert,
  MemberMultiSelectDropdown,
  InviteNewTeamMember,
} from '../common';
import { teamService } from '../../services';
import { INVITATIONS, LOCAL_STORAGE_USER_DATA } from '../../constants';
import { fetchOnBoardProgress } from '../../actions/user';
import { fetchProjectDetail } from '../../actions/projects';
import { getUserData, calculateInviteField } from '../../helpers';
import { trackJune } from '../../utilities/analytics';

const InviteToProjectModal = ({ members, projectId, invites }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const teamData = useSelector((state) => state.team);
  const sortedMembersList = teamData.members.filter(
    (member) =>
      member.status !== 'inactive' &&
      !members.some((b) => member.userId === b.userId) &&
      !invites.includes(member.email)
  );
  const existingTeamMembers = [];
  let newTeamMembers = [];
  const selectInputRef = useRef(null);

  const handleSendInvites = (formdata) => {
    setSubmitting(true);
    const existingMembers = [...formdata.addExistingTeamMembers];
    const newMembers =
      [...formdata.addNewTeamMembers].length > 0
        ? [...formdata.addNewTeamMembers].filter((item) => item.email !== '')
        : [];
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      projectId,
      newMembers: [...newMembers],
      existingMembers,
    };
    teamService
      .update_team_invitations(params)
      .then((response) => {
        if (response.status) {
          const userData = JSON.parse(
            localStorage.getItem(LOCAL_STORAGE_USER_DATA)
          );
          if (newMembers.length > 0) {
            trackJune('member invite');
          }
          if (userData.subscriptionDetails.currentPlan === 'trial') {
            dispatch(fetchOnBoardProgress());
          }

          dispatch(
            fetchProjectDetail({
              teamOwnerId: localStorage.getItem('teamOwnerId'),
              projectId,
            })
          );

          // Clear input values
          selectInputRef.current.select.clearValue();

          if (params.existingMembers.length > 0) {
            toast.success(INVITATIONS.ADDED_TO_PROJECT);
          }

          if (params.newMembers.length > 0) {
            toast.success(INVITATIONS.INVITATION_SUCCESS);
          }
          handleClose();
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  const activeMembers = getUserData('activeMembers') || 0;
  const maxMembersAllowed = getUserData('maxMembersAllowed') || 0;
  const maxFieldAllowed = maxMembersAllowed - activeMembers;
  newTeamMembers = calculateInviteField(maxFieldAllowed);

  return (
    <>
      <Button
        variant="light"
        size="lg"
        className="btn-rounded-circle btn-white lift"
        onClick={handleShow}
      >
        +
      </Button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title className="m-0">Invite Team Members</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={{
            addExistingTeamMembers: existingTeamMembers,
            addNewTeamMembers: newTeamMembers,
          }}
          validationSchema={Yup.object().shape({
            addNewTeamMembers: Yup.array().of(
              Yup.object().shape({
                email: Yup.string()
                  .trim()
                  .email('Please enter a valid email address'),
              })
            ),
          })}
          onSubmit={(values, { resetForm }) => {
            handleSendInvites(values);
            resetForm();
          }}
        >
          {(formikprops) => (
            <Form onSubmit={formikprops.handleSubmit}>
              <Modal.Body>
                <AllowedMembersAlert />
                <section>
                  <MemberMultiSelectDropdown
                    id="selectInputRef"
                    name="addExistingTeamMembers"
                    labelText="Add existing Team Member(s)"
                    fromArray={sortedMembersList}
                    formProps={formikprops}
                    isDisabled={sortedMembersList.length < 1 && true}
                    innerRef={selectInputRef}
                    requiredValueAs="userId"
                  />
                  {maxMembersAllowed - activeMembers > 0 && (
                    <InviteNewTeamMember
                      formProps={formikprops}
                      showPlanText=""
                      maxInputField={0}
                      fieldName="addNewTeamMembers"
                      maxFieldAllowed={maxMembersAllowed - activeMembers}
                    />
                  )}
                </section>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button variant="link" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={
                    !(formikprops.isValid && formikprops.dirty) || isSubmitting
                  }
                >
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Invite
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

InviteToProjectModal.propTypes = {
  members: PropTypes.array,
  invites: PropTypes.array,
  projectId: PropTypes.string,
};

export default InviteToProjectModal;
