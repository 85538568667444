import { BASE_API_URL, ACTIVITY } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

const options = AppService.options();

export const activityService = {
  get_activity_data: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + ACTIVITY.MEMBER,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_recent_activity: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'GET',
    };
    const URL = `${BASE_API_URL + ACTIVITY.RECENT}?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },

  get_projectRecentActivity_data: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'GET',
    };
    const URL = `${BASE_API_URL + ACTIVITY.RECENT}?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },

  get_team_activity_data: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'GET',
    };
    const URL = `${BASE_API_URL + ACTIVITY.TEAM}?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  get_team_activity_day: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'GET',
    };
    const URL = `${BASE_API_URL + ACTIVITY.TEAM_DAY}?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  view_screenshots: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'GET',
    };

    const response = await AppService.makeRequest(
      `${BASE_API_URL + ACTIVITY.VIEW_SCREENSHOTS}/${data.wsid}/${
        data.teamOwnerId
      }`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
};
