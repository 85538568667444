import { BASE_API_URL, INTEGRATION_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

const options = AppService.options();

export const integrationService = {
  asana_return: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + INTEGRATION_URLS.ASANA_RETURN,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_asana_projects: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + INTEGRATION_URLS.GET_ASANA_PROJECTS,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },

  asana_projects_list: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + INTEGRATION_URLS.LIST_ASANA_PROJECTS}/${
        data.teamOwnerId
      }
      `,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
};
