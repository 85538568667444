/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { Container } from 'react-bootstrap';
import App from './App';
import reportWebVitals from './reportWebVitals';
import allReducers from './reducers';
import { saveToLocalStorage, loadFromLocalStorage } from './helpers';
import { ErrorBoundary } from './components/common';
import errorGraphic from './assets/img/illustrations/warning.svg';

// Redux dev tool
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Load persisted state from localStorage
const persistedState = loadFromLocalStorage();
const store = createStore(
  allReducers,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);
store.subscribe(() => saveToLocalStorage(store.getState()));
// Won't render the application if WebApp is under Clickjacking attack
if (window.self === window.top) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <ErrorBoundary>
      <section className="d-flex align-items-center bg-auth border-top border-top-2 border-primary page_simple_header">
        <Container>
          <div className="row align-items-center">
            <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
              <div className="text-center">
                <h6 className="text-uppercase text-muted mb-4">
                  Unsecured page
                </h6>
                <h1 className="display-4 mb-3">
                  Looks like, Web App link you have clicked on is under
                  Clickjacking security attack.
                </h1>
                <p className="text-muted mb-4">
                  Please inform team with the reference of the application from
                  where you clicked this link.
                </p>
                <a
                  target="_blank"
                  href={window.self.location.href}
                  rel="noreferrer"
                >
                  Return to your dashboard safely
                </a>
              </div>
            </div>
            <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
              <div className="text-center">
                <img src={errorGraphic} alt="HiveDesk" className="img-fluid" />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </ErrorBoundary>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
