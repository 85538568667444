import { memo, useEffect, useState, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userService } from '../../services';
import { updateLocalStorage } from '../../helpers';

const AllowedMembersAlert = () => {
  const userDetails = useSelector((state) => state.userReducer);
  const [accountInfo, setAccountInfo] = useState({});
  const [isFetchingData, setIsFetchingData] = useState(true);
  const isMounted = useRef(null);
  const getAccountInfo = () => {
    userService
      .get_account_details({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
      })
      .then((response) => {
        setIsFetchingData(false);
        if (response.data) {
          setAccountInfo(response.data);
          updateLocalStorage({
            activeMembers: response.data.activeMembers,
            maxMembersAllowed: response.data.maxMembersAllowed,
            isMobileTracking: response.data.isMobileTracking,
          });
        }
      })
      .catch((error) => {
        setIsFetchingData(false);
      });
  };
  useEffect(() => {
    isMounted.current = true;
    getAccountInfo();
    return () => {
      isMounted.current = false;
    };
  }, []);
  const activeMembers = accountInfo.activeMembers || 0;
  const maxMembersAllowed = accountInfo.maxMembersAllowed || 0;
  const allowedMembers = maxMembersAllowed - activeMembers;
  return (
    !isFetchingData && (
      <Alert variant="info">
        {allowedMembers > 0 ? (
          <span>
            You currently have {activeMembers} members on your current plan.You
            can add{' '}
            {activeMembers > maxMembersAllowed
              ? 0
              : maxMembersAllowed - activeMembers}{' '}
            more new members.
          </span>
        ) : (
          <span>
            {userDetails.role === 'creator' ? (
              <>
                Please <Link to="/settings/billing/"> upgrade your plan </Link>{' '}
                to add more new members
              </>
            ) : (
              <>
                The current plan allows {maxMembersAllowed} team members. Please
                contact your Administrator to add new members.
              </>
            )}
          </span>
        )}
      </Alert>
    )
  );
};

export default memo(AllowedMembersAlert);
