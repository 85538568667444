import React from 'react';
import { Form } from 'react-bootstrap';
import { Field } from 'formik';
import PropTypes from 'prop-types';

const PercentageRangeListDropdown = ({ fieldName }) => {
  // Percentage range list
  const range = [
    { id: '0-25', value: '0-25%' },
    { id: '26-50', value: '26-50%' },
    { id: '51-75', value: '51-75%' },
    { id: '76-100', value: '76-100%' },
  ];
  const listOptions = range.map((list, index) => (
    <option key={index} value={list.id}>
      {list.value}
    </option>
  ));
  return (
    <Form.Group controlId="Project">
      <Form.Label>{fieldName || 'Activity'}</Form.Label>
      <Field className="form-select" as="select" name="activity">
        <option value="all">All</option>
        {listOptions}
      </Field>
    </Form.Group>
  );
};
PercentageRangeListDropdown.propTypes = {
  fieldName: PropTypes.string,
};
export default PercentageRangeListDropdown;
