const initialState = {
  sideNavbarCollapsedView: false,
};

export const collpasedSideNavbarToggle = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCHING_SIDE_NAVBAR_COLLAPSED_VIEW':
      return {
        ...state,
      };
    case 'SET_SIDE_NAVBAR_COLLAPSED_VIEW':
      return {
        ...state,
        sideNavbarCollapsedView: action.payload.sideNavbarCollapsedView,
      };
    case 'FETCHING_SIDE_NAVBAR_COLLAPSED_VIEW_ERROR':
      return {
        ...state,
        sideNavbarCollapsedView: false,
        error: action.error,
      };
    default:
      return state;
  }
};
