import { toast } from 'react-toastify';
import { tasksService } from '../services';
import { API_FAILURE_ERROR } from '../constants';
import { trackJune } from '../utilities/analytics';

export const fetchTasks = (params) => async (dispatch, getState) => {
  if (params.isFirstLoad) {
    // Activate spinner for the first page load
    dispatch({ type: 'ENABLE_TASKS_SPINNER' });
  }
  const { selectedTaskStatus } = getState().tasks;
  if (selectedTaskStatus && selectedTaskStatus !== 'all') {
    params.taskStatus = selectedTaskStatus;
  }
  try {
    const response = await tasksService.get_tasks(params);
    if (response.status) {
      dispatch({ type: 'SET_TASKS', payload: response.data });
    }
  } catch {
    dispatch({ type: 'FETCH_TASKS_ERROR' });
  }
};

export const addSection = (params) => async (dispatch) => {
  try {
    const response = await tasksService.add_section(params);
    if (response.status) {
      dispatch(
        fetchTasks({
          teamOwnerId: localStorage.getItem('teamOwnerId'),
          projectId: params.projectId,
        })
      );
    }
  } catch {
    toast.error(API_FAILURE_ERROR);
    dispatch({ type: 'FETCH_TASKS_ERROR' });
  }
};

export const editSection = (params) => async (dispatch) => {
  try {
    const response = await tasksService.edit_section(params);
    if (response.status) {
      dispatch(
        fetchTasks({
          teamOwnerId: localStorage.getItem('teamOwnerId'),
          projectId: params.projectId,
        })
      );
    }
  } catch {
    toast.error(API_FAILURE_ERROR);
  }
};

export const deleteSection = (params) => async (dispatch) => {
  try {
    const response = await tasksService.delete_section(params);
    if (response.status) {
      dispatch(
        fetchTasks({
          teamOwnerId: localStorage.getItem('teamOwnerId'),
          projectId: params.projectId,
        })
      );
      toast.success('Section and related task(s) removed successfully');
    }
  } catch {
    toast.error(API_FAILURE_ERROR);
  }
};

export const addTask = (params) => async (dispatch) => {
  try {
    const response = await tasksService.add_task(params);
    if (response.status) {
      dispatch(
        fetchTasks({
          teamOwnerId: localStorage.getItem('teamOwnerId'),
          projectId: params.projectId,
        })
      );
      // track june
      trackJune('task created');
    }
  } catch {
    toast.error(API_FAILURE_ERROR);
  }
};

export const editTask = (params, handleModal) => async (dispatch) => {
  dispatch({ type: 'UPDATING_TASKS' });
  try {
    const response = await tasksService.edit_task(params);
    if (response.status) {
      dispatch(
        fetchTasks({
          teamOwnerId: localStorage.getItem('teamOwnerId'),
          projectId: params.projectId,
        })
      );
      // track task complete in june
      if (params.tasks.status === 'complete') {
        trackJune('task completed');
      }
      if (handleModal) {
        handleModal();
      }
    }
  } catch {
    toast.error(API_FAILURE_ERROR);
    dispatch({ type: 'FETCH_TASKS_ERROR' });
  }
};

export const deleteTask = (params) => async (dispatch) => {
  try {
    const response = await tasksService.delete_task(params);
    if (response.status) {
      dispatch(
        fetchTasks({
          teamOwnerId: localStorage.getItem('teamOwnerId'),
          projectId: params.projectId,
        })
      );
      toast.success('Task removed successfully');
    }
  } catch {
    toast.error(API_FAILURE_ERROR);
  }
};

export const moveTask = (params) => async (dispatch) => {
  try {
    const response = await tasksService.move_task(params);
    if (response.status) {
      dispatch(
        fetchTasks({
          teamOwnerId: localStorage.getItem('teamOwnerId'),
          projectId: params.projectId,
        })
      );
    }
  } catch {
    toast.error(API_FAILURE_ERROR);
  }
};

// Setting the current status selected in list and board view of tasks
export const setSelectedTaskStatus = (data) => ({
  type: 'CURRENT_TASK_STATUS',
  payload: data,
});

export const copyTask = (params, handleModal) => async (dispatch) => {
  dispatch({ type: 'ENABLE_TASKS_SPINNER' });
  try {
    const response = await tasksService.add_task(params);
    if (response.status) {
      dispatch(
        fetchTasks({
          teamOwnerId: localStorage.getItem('teamOwnerId'),
          projectId: params.currentProject,
        })
      );
      handleModal();
      toast.success('Task copied successfully');
      // track june
      trackJune('task created');
    }
  } catch {
    toast.error(API_FAILURE_ERROR);
    dispatch({ type: 'DISABLE_TASKS_SPINNER' });
  }
};

export const copySection = (params, handleModal) => async (dispatch) => {
  dispatch({ type: 'ENABLE_TASKS_SPINNER' });
  try {
    const response = await tasksService.copy_section(params);
    if (response.status) {
      dispatch(
        fetchTasks({
          teamOwnerId: localStorage.getItem('teamOwnerId'),
          projectId: params.currentProject,
        })
      );
      handleModal();
      toast.success('Section copied successfully');
    }
  } catch {
    toast.error(API_FAILURE_ERROR);
    dispatch({ type: 'DISABLE_TASKS_SPINNER' });
  }
};
