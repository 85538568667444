import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Layout from '../../DashboardLayout';
import Report from '../../../components/features/overtime/Report';
import { teamService, reportService } from '../../../services';
import Loading from '../../../components/Loader';
import { TeamMembersDropdown } from '../../../components/common';
import { OT_DATE_RANGE, PAGINATION_LIMIT } from '../../../constants';
import { trackJune } from '../../../utilities/analytics';

class OvertimeReport extends Component {
  state = {
    isLoading: true,
    teamMembersList: [],
    teamGroupList: [],
    startDate: moment().startOf('week'),
    endDate: moment().endOf('week'),
    overtime: [],
    overtimeMetadata: {},
    isLoadingReport: true,
    isFetchingOvertimeData: false,
    numberOfDaysSelected: 1,
    daysLimit: 7,
    selectedTeamMember: 'all',
    reportFormData: {},
    page: 1,
    limit: PAGINATION_LIMIT,
  };

  pageHeading = {
    preTitle: 'Report',
    title: 'Overtime Report',
  };

  componentDidMount() {
    this.getTeamsData();
    this.getTeamGroupList();
    // track june
    trackJune(null, this.pageHeading.title);
  }

  setCurrentPage = (pageN) => {
    this.setState((state) => ({
      ...state,
      page: pageN,
    }));
  };

  handleGenerateData = (formdata) => {
    this.setState((state) => ({
      ...state,
      isFetchingOvertimeData: true,
    }));

    const { startDate, endDate, page } = this.state;
    const dataFromDate = moment(startDate).format('YYYY-MM-DD');
    const dataToDate = moment(endDate).format('YYYY-MM-DD');
    const params = {
      teamOwnerId: formdata.teamOwnerId,
      team: [formdata.team],
      teamMember: formdata.teamMember,
      fromDate: dataFromDate.concat(' 00:00:00'),
      toDate: dataToDate.concat(' 23:59:59'),
      page: formdata.page || page,
      limit: formdata.limit || PAGINATION_LIMIT,
    };
    if (formdata.team === 'all' || formdata.team === '') {
      delete params.team;
    }
    if (formdata.teamMember === 'all' || formdata.teamMember === '') {
      delete params.teamMember;
    }
    reportService
      .get_overtime_data(params)
      .then((response) => {
        const {
          data: { metadata, overtimeDetails },
        } = response;

        this.setState((state) => ({
          ...state,
          isFetchingOvertimeData: false,
          overtime: overtimeDetails,
          overtimeMetadata: metadata,
          isLoadingReport: false,
          reportFormData: formdata,
        }));
      })
      .catch((error) => {
        this.setState((state) => ({
          ...state,
          isFetchingOvertimeData: false,
          isLoadingReport: false,
        }));
      });
  };

  handleDateSelect = (event, picker) => {
    picker.element.val(
      `${moment(picker.startDate).day(0).format('MMM D, YYYY')} - ${moment(
        picker.endDate
      )
        .day(6)
        .format('MMM D, YYYY')}`
    );
    this.setState((state) => ({
      ...state,
      startDate: moment(picker.startDate).day(0),
      endDate: moment(picker.endDate).day(6),
    }));
  };

  handleDateRangeValidation = (start, end) => {
    const { daysLimit } = this.state;
    const daysSelected = Math.abs(moment(start).diff(moment(end), 'd'));
    if (daysSelected > daysLimit) {
      toast.error(`${OT_DATE_RANGE.LIMIT} ${OT_DATE_RANGE.ERROR}`);
    }
    this.setState((state) => ({
      ...state,
      numberOfDaysSelected: daysSelected,
    }));
  };

  getTeamsData = () => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    teamService
      .get_team_members_data({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        summaryList: true,
      })
      .then((response) => {
        const { members } = response.data;
        this.setState((state) => ({
          ...state,
          teamMembersList: [...members],
          isLoading: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  getTeamGroupList = () => {
    teamService
      .get_team_group_list({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
      })
      .then((response) => {
        if (response.data) {
          this.setState(() => ({
            teamGroupList: response.data,
          }));
        }
      });
  };

  render() {
    const {
      teamMembersList,
      startDate,
      endDate,
      overtime,
      overtimeMetadata,
      isLoadingReport,
      isFetchingOvertimeData,
      isLoading,
      numberOfDaysSelected,
      daysLimit,
      reportFormData,
      page,
      teamGroupList,
    } = this.state;
    const { userRole } = this.props;
    // Team owner ID
    const teamOwnerId = localStorage.getItem('teamOwnerId');
    return (
      <Layout pageHeading={this.pageHeading}>
        <Formik
          initialValues={{
            teamOwnerId,
            team: 'all',
            teamMember: 'all',
            fromDate: startDate,
            toDate: endDate,
            page: 1,
            limit: PAGINATION_LIMIT,
          }}
          onSubmit={(values) => {
            this.handleGenerateData(values);
          }}
        >
          {(formikprops) => (
            <Form onSubmit={formikprops.handleSubmit} className="mb-5">
              <Row>
                <Col md={6} lg={3}>
                  <TeamMembersDropdown
                    teamMembersList={teamMembersList}
                    handleChange={formikprops.handleChange}
                    isUpdatingTeamsList={isLoading}
                    isAllOptionRequired
                  />
                </Col>
                <Col md={6} lg={3}>
                  <Form.Group controlId="team">
                    <Form.Label>Team Group</Form.Label>
                    <Field className="form-select" as="select" name="team">
                      <option value="all">All</option>
                      {teamGroupList &&
                        teamGroupList.length > 0 &&
                        teamGroupList.map((teamGroup, index) => (
                          <option
                            key={`teamGroup${index}`}
                            value={teamGroup._id}
                          >
                            {teamGroup.name}
                          </option>
                        ))}
                    </Field>
                  </Form.Group>
                </Col>
                <Col md={6} lg={3}>
                  <Form.Group>
                    <Form.Label>Date range</Form.Label>
                    <DateRangePicker
                      onEvent={this.handleDateSelect}
                      initialSettings={{
                        autoUpdateInput: false,
                        singleDatePicker: true,
                        startDate: moment().toDate(),
                        locale: {
                          format: 'MMM D, YYYY',
                        },
                        alwaysShowCalendars: true,
                        autoApply: true,
                      }}
                    >
                      <input
                        type="text"
                        className="form-control icon-calendar"
                        placeholder={`${moment()
                          .day(0)
                          .format('MMM D, YYYY')} - ${moment()
                          .day(6)
                          .format('MMM D, YYYY')}`}
                      />
                    </DateRangePicker>
                  </Form.Group>
                </Col>
                <Col md={6} lg={3}>
                  <Form.Group controlId="generateData">
                    <Form.Label>&nbsp;</Form.Label>
                    <div>
                      <Button
                        variant="primary"
                        type="Submit"
                        disabled={numberOfDaysSelected > daysLimit}
                      >
                        Generate
                      </Button>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        {!isLoadingReport && (
          <Report
            overTimeMetadata={overtimeMetadata}
            overtimeData={overtime}
            startDate={startDate}
            endDate={endDate}
            userRole={userRole}
            setCurrentPage={this.setCurrentPage}
            getOvertimeData={this.handleGenerateData}
            reportFormData={reportFormData}
            pageNumber={page}
          />
        )}
        {/* Loader */}
        {(isLoading || isFetchingOvertimeData) && <Loading contentAreaOnly />}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  const { userReducer } = state;
  return {
    userRole: userReducer.role,
  };
};

OvertimeReport.propTypes = {
  userRole: PropTypes.string,
};
export default connect(mapStateToProps)(OvertimeReport);
