import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ViewTimesheetPDF from './ViewTimesheetPDF';
import ViewTimecardPDF from './ViewTimecardPDF';
import ApprovalTimesheetExcel from './ApprovalTimesheetExcel';
import ApprovalTimecardExcel from './ApprovalTimecardExcel';
import WeeklyTimeCardPdfReport from './WeeklyTimeCardPdfReport';
import WeeklyTimeCardExcelReport from './WeeklyTimeCardExcelReport';

const ExportOptions = ({
  timesheetDetails,
  viewTab,
  setShowMonthlyReportModal,
}) => (
  <div className="d-flex justify-content-end align-items-center export-options">
    <small>Download as </small>
    {viewTab === 'timeCard' &&
    window.location.pathname === '/timesheet/approval' ? (
      <>
        <Dropdown>
          <Dropdown.Toggle
            as={Button}
            size="sm"
            variant="white"
            className="ms-2"
            to={{ hash: '#' }}
          >
            PDF
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-right">
            {timesheetDetails && timesheetDetails.length > 0 && (
              <>
                <Dropdown.Item
                  onClick={() => ViewTimecardPDF(timesheetDetails)}
                >
                  Detailed
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => WeeklyTimeCardPdfReport(timesheetDetails)}
                >
                  Consolidated
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Item onClick={() => setShowMonthlyReportModal(true)}>
              Monthly Report
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown>
          <Dropdown.Toggle
            as={Button}
            size="sm"
            variant="white"
            className="ms-2"
            to={{ hash: '#' }}
          >
            Excel
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu-right">
            {timesheetDetails && timesheetDetails.length > 0 && (
              <>
                <Dropdown.Item
                  onClick={() => ApprovalTimecardExcel(timesheetDetails)}
                >
                  Detailed
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => WeeklyTimeCardExcelReport(timesheetDetails)}
                >
                  Consolidated
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Item onClick={() => setShowMonthlyReportModal(true)}>
              Monthly Report
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    ) : (
      <>
        <Button
          variant="white"
          size="sm"
          className="mx-1 border border-1"
          onClick={() =>
            viewTab === 'timeCard'
              ? ViewTimecardPDF(timesheetDetails)
              : ViewTimesheetPDF(timesheetDetails)
          }
          disabled={timesheetDetails && timesheetDetails.length === 0}
        >
          PDF
        </Button>
        <Button
          variant="white"
          size="sm"
          className="ms-1 border border-1"
          onClick={() =>
            viewTab === 'timeCard'
              ? ApprovalTimecardExcel(timesheetDetails)
              : ApprovalTimesheetExcel(timesheetDetails)
          }
          disabled={timesheetDetails && timesheetDetails.length === 0}
        >
          Excel
        </Button>
      </>
    )}
  </div>
);

ExportOptions.propTypes = {
  timesheetDetails: PropTypes.array,
  viewTab: PropTypes.string,
  setShowMonthlyReportModal: PropTypes.func,
};

export default ExportOptions;
