import React from 'react';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';
import '../../assets/styles/react-select.scss';
import allMemberLogo from '../../assets/img/all-member-icon.png';

const MemberMultiSelectDropdown = ({
  id,
  labelText,
  name,
  fromArray,
  formProps,
  isDisabled,
  innerRef,
  isAllTeamMemberOption,
  isRequiredField = false,
  requiredValueAs = 'email',
  showErrorMessage = false,
}) => {
  const OptionForAll = {
    value: 'all',
    label: (
      <>
        <div className="avatar avatar-xs me-3">
          <div
            className="avatar-img rounded-circle"
            style={{ backgroundImage: `url("${allMemberLogo}")` }}
          />
        </div>
        <span className="member-name">Select All</span>
      </>
    ),
    firstName: 'Select',
    lastName: 'All',
  };

  // Select option items
  const options = fromArray.map((member) => ({
    value: requiredValueAs === 'email' ? member.email : member.userId,
    label: (
      <>
        <div className="avatar avatar-xs me-3">
          <Avatar
            firstName={member.firstName}
            lastName={member.lastName}
            imgPath={member.avatar ? member.avatar : ''}
          />
        </div>
        <span className="member-name">
          {member.lastName
            ? `${member.firstName} ${member.lastName}`
            : member.firstName}
        </span>
      </>
    ),
    firstName: member.firstName,
    lastName: member.lastName || '',
  }));
  if (isAllTeamMemberOption) {
    options.unshift(OptionForAll);
  }

  const allMembersList =
    fromArray &&
    fromArray.length > 0 &&
    fromArray.map((mItem) => {
      const memberInfo =
        requiredValueAs === 'email' ? mItem.email : mItem.userId;
      return memberInfo;
    });

  // Filter options based on user input
  const customFilter = (option, searchText) => {
    if (
      option.data.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
      option.data.lastName.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  return (
    <Form.Group controlId={id}>
      <Form.Label>
        {labelText} {isRequiredField && <span className="text-danger">*</span>}
      </Form.Label>
      <Select
        ref={innerRef}
        name={name}
        options={options}
        isMulti
        isClearable
        className="basic-multi-select"
        classNamePrefix="select"
        isDisabled={isDisabled}
        filterOption={customFilter}
        isSearchable
        onChange={(sOptions) => {
          if (
            sOptions.length &&
            sOptions.find((sOption) => sOption.value === 'all')
          ) {
            formProps.setFieldValue(name, [...allMembersList]);
          } else {
            formProps.setFieldValue(
              name,
              sOptions.map((item) => item.value)
            );
          }
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#0649b5',
            neutral10: '#edf2f9',
            neutral20: '#d2ddec',
            neutral30: '#d2ddec',
            neutral50: '#b1c2da',
            neutral80: '#12263f',
            dangerLight: '#DEEBFF',
            danger: '#12263f',
          },
        })}
      />
      {showErrorMessage && (
        <ErrorMessage
          name={name}
          render={(msg) => <small className="text-danger">{msg}</small>}
        />
      )}
    </Form.Group>
  );
};

MemberMultiSelectDropdown.propTypes = {
  id: PropTypes.string,
  fromArray: PropTypes.array,
  name: PropTypes.string,
  labelText: PropTypes.string,
  formProps: PropTypes.any,
  isDisabled: PropTypes.bool,
  isAllTeamMemberOption: PropTypes.bool,
  innerRef: PropTypes.any,
  isRequiredField: PropTypes.bool,
  requiredValueAs: PropTypes.string,
  showErrorMessage: PropTypes.bool,
};

export default MemberMultiSelectDropdown;
