import { LOCAL_STORAGE_USER_DATA } from '../constants';

let userRole = '';
const freeTrialStatuss = true;
const ownerId = localStorage.getItem('teamOwnerId');
let policyStatus = true;
let subscriptionDetails = {};
if (localStorage.getItem(LOCAL_STORAGE_USER_DATA) != null) {
  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  if (userData.defaultTeam != null && userData.defaultTeam.role != null) {
    userRole = userData.defaultTeam.role;
  }
  policyStatus = userData.policyAcceptanceStatus;
  subscriptionDetails = userData.subscriptionDetails;
}

const initialState = {
  role: userRole,
  ownerId,
  policyAcceptanceStatus: policyStatus,
  subscription: subscriptionDetails,
  progress: {},
  freeTrialStatus: freeTrialStatuss,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ROLE':
      return {
        ...state,
        role: action.payload.role,
        ownerId: action.payload.ownerId,
      };
    case 'ACCEPT_POLICY':
      return {
        ...state,
        policyAcceptanceStatus: action.payload.policyAcceptanceStatus,
      };
    case 'SET_SUBSCRIPTION':
      return {
        ...state,
        subscription: action.payload,
      };
    case 'SET_ONBOARD_PROGRESS':
      return {
        ...state,
        progress: { ...action.payload },
      };
    case 'SET_CARD_EXPIRY':
      return {
        ...state,
        cardExpired: action.payload.cardExpired,
      };
    case 'SET_FREE_TRIAL_STATUS_FALSE':
      return {
        ...state,
        freeTrialStatus: action.payload.freeTrialStatus,
      };
    default:
      return state;
  }
};
export default userReducer;
