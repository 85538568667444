import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import moment from 'moment';

const LogsList = ({ logs }) => (
  <ul className="list-unstyled mt-4">
    {logs.map((log, index) => {
      const statusText = (status) => {
        switch (status) {
          case 'submit':
            return 'submitted';
          case 'approve':
            return 'approved';
          case 'reject':
            return 'rejected';
          case 'withdraw':
            return 'withdrawn';
          default:
            return status;
        }
      };
      return (
        <li key={`log-${index}`}>
          <small>
            <span className="text-muted">
              {moment(log.updatedDate).format('MMM D hh:mm A')}{' '}
              {statusText(log.status)} by {log.updatedBy.firstName}
            </span>
            {log.note && `: ${log.note}`}
          </small>
        </li>
      );
    })}
  </ul>
);

LogsList.propTypes = {
  logs: PropTypes.array,
};

const WeeklyStatusToggle = ({
  status,
  logs,
  wsWeekDetails,
  submitTimesheet,
  wsWeekStatusUpdate,
  weeklySubmissionId,
}) => {
  switch (status) {
    case 'submit':
      return (
        <Dropdown>
          <Dropdown.Toggle
            as={Button}
            size="sm"
            variant="white"
            className="mt-2"
            to={{ hash: '#' }}
          >
            <i className="mdi mdi-check-circle me-1 text-warning" />
            Submitted
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="dropdown-menu-right p-3 mt-1"
            style={{ minWidth: '300px' }}
          >
            <p className="mb-2 text-uppercase">
              <small>
                Week of {moment(wsWeekDetails.startDate).format('MMM D')} -{' '}
                {moment(wsWeekDetails.endDate).format('MMM D')}{' '}
              </small>
            </p>
            <Formik
              initialValues={{
                notes: '',
              }}
              onSubmit={(values, { resetForm }) => {
                wsWeekStatusUpdate({
                  weeklySubmissionId,
                  status: 'withdraw',
                  note: values.notes,
                });
                resetForm({ notes: '' });
              }}
            >
              {(formikprops) => (
                <Form onSubmit={formikprops.handleSubmit}>
                  <Form.Group controlId="wsNotes" className="mb-3">
                    <Field
                      as="textarea"
                      maxLength="300"
                      name="notes"
                      rows="2"
                      className="form-control"
                      placeholder="Notes"
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" size="sm">
                    Withdraw Request
                  </Button>
                </Form>
              )}
            </Formik>
            {logs && logs.length > 0 && <LogsList logs={logs} />}
          </Dropdown.Menu>
        </Dropdown>
      );
    case 'approve':
      return (
        <Dropdown>
          <Dropdown.Toggle
            as={Button}
            size="sm"
            variant="white"
            className="mt-2"
            to={{ hash: '#' }}
          >
            <i className="mdi mdi-check-circle me-1 text-success" />
            Approved
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="dropdown-menu-right p-3 mt-1"
            style={{ minWidth: '300px' }}
          >
            <p className="mb-2 text-uppercase">
              <small>
                Week of {moment(wsWeekDetails.startDate).format('MMM D')} -{' '}
                {moment(wsWeekDetails.endDate).format('MMM D')}{' '}
              </small>
            </p>
            {logs && logs.length > 0 && <LogsList logs={logs} />}
          </Dropdown.Menu>
        </Dropdown>
      );
    case 'reject':
      return (
        <Dropdown>
          <Dropdown.Toggle
            as={Button}
            size="sm"
            variant="white"
            className="mt-2"
            to={{ hash: '#' }}
          >
            <i className="mdi mdi-close-circle me-1 text-danger" />
            Rejected
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="dropdown-menu-right p-3 mt-1"
            style={{ minWidth: '300px' }}
          >
            <p className="mb-2 text-uppercase">
              <small>
                Week of {moment(wsWeekDetails.startDate).format('MMM D')} -{' '}
                {moment(wsWeekDetails.endDate).format('MMM D')}{' '}
              </small>
            </p>
            {logs && logs.length > 0 && <LogsList logs={logs} />}
          </Dropdown.Menu>
        </Dropdown>
      );
    default:
      return (
        <Dropdown>
          <Dropdown.Toggle
            as={Button}
            size="sm"
            variant="white"
            className="text-muted mt-2"
            to={{ hash: '#' }}
          >
            <i className="mdi mdi-check-circle me-1" />
            Not Submitted
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="dropdown-menu-right p-3 mt-1"
            style={{ minWidth: '300px' }}
          >
            <p className="mb-2 text-uppercase">
              <small>
                Week of {moment(wsWeekDetails.startDate).format('MMM D')} -{' '}
                {moment(wsWeekDetails.endDate).format('MMM D')}{' '}
              </small>
            </p>
            <Formik
              initialValues={{
                notes: '',
              }}
              onSubmit={(values, { resetForm }) => {
                submitTimesheet({
                  startDate: wsWeekDetails.startDate,
                  endDate: wsWeekDetails.endDate,
                  status: 'submit',
                  note: values.notes,
                });
                resetForm({ notes: '' });
              }}
            >
              {(formikprops) => (
                <Form onSubmit={formikprops.handleSubmit}>
                  <Form.Group controlId="wsNotes" className="mb-3">
                    <Field
                      as="textarea"
                      maxLength="300"
                      name="notes"
                      rows="2"
                      className="form-control"
                      placeholder="Notes"
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit" size="sm">
                    Submit for Approval
                  </Button>
                </Form>
              )}
            </Formik>
            {logs && logs.length > 0 && <LogsList logs={logs} />}
          </Dropdown.Menu>
        </Dropdown>
      );
  }
};

WeeklyStatusToggle.propTypes = {
  status: PropTypes.string,
  logs: PropTypes.array,
  wsWeekDetails: PropTypes.object,
  submitTimesheet: PropTypes.func,
  wsWeekStatusUpdate: PropTypes.func,
  weeklySubmissionId: PropTypes.string,
};

export default WeeklyStatusToggle;
