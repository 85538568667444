const {
  REACT_APP_BASE_API_URL,
  REACT_APP_WEB_URL,
  REACT_APP_BILLING_REACTIVATE_URL,
  REACT_APP_ASANA_CLIENT_ID,
  REACT_APP_CRISP_CHAT_ID,
  REACT_APP_PUBLIC_API_URL,
  REACT_APP_WEB_SOCKET_CONNECTION_URL,
} = process.env;
// export const BASE_API_URL = 'http://localhost:4000/';
export const BASE_API_URL = REACT_APP_BASE_API_URL;
export const SENTRY_DSN =
  'https://8c8956d71a0943118a3dc1930c98091e@o810243.ingest.sentry.io/5814777';
export const WEB_URL = REACT_APP_WEB_URL;
export const SUPPORT_URL = 'http://support.hivedesk.com/';
export const CLIENT_DOWNLOAD_URL = 'https://hivedesk.com/download/';
export const REACTIVATE_URL = REACT_APP_BILLING_REACTIVATE_URL;
export const PUBLIC_API_URL = REACT_APP_PUBLIC_API_URL;
export const WEB_SOCKET_CONNECTION_URL = REACT_APP_WEB_SOCKET_CONNECTION_URL;

export const PROJECT_URLS = {
  LIST: 'v1/project/list',
  PROJECT: 'v1/project', // Used for Create Project, Update Project and Get Project details
  PROJECT_STATUS_UPDATE: 'v1/project/status-update',
  SUMMARY: 'v1/project/summary', // worksession and task summary
  USER: 'v1/project/user',
  WORKSESSION: 'v1/project/work-session',
  MEMBERS: '/v1/project/member',
};
export const FORGOT_PW = `v1/user/forgot-password`;
export const FREE_TRIAL_THANKS = `https://www.hivedesk.com/free-trial-thanks`;

export const TEAM_URLS = {
  TEAM: 'v1/user/teams',
  UPDATE_TEAM: 'v1/user/update-default-team',
  TEAM_MEMBERS: 'v1/team-member/list',
  MEMBERS_AND_PROJECTS: 'v1/team-member/member-project-list',
  TEAM_RECEIVED_INVITATIONS: 'v1/team-member/invitation/received',
  TEAM_SENT_INVITATIONS: 'v1/team-member/invitation/sent',
  TEAM_INVITATIONS: 'v1/team-member/invitation',
  DELETE_INVITATION: 'v1/team-member/invitation/delete',
  INVITATION: 'v1/team-member/invitation',
  INVITATION_UPDATE: 'v1/team-member/invitation/update',
  INVITATION_RESEND: 'v1/team-member/invitation/resend',
  UPDATE_TEAM_INVITATIONS: 'v1/team-member/invitation/send',
  UPDATE_USER_ROLE: 'v1/team-member/update-role',
  UPDATE_USER_STATUS: 'v1/team-member/status',
  DELETE_TEAM_MEMBER: 'v1/team-member',
  USER_WORKSESSION_REPORT: 'v1/work-session/reports',
  UPDATE_USER_RATE: 'v1/team-member/rate',
  UPDATE_PERMISSIONS: 'v1/team-member/permissions',
  STATUS: 'v1/team-member/online/',
  WS_TOTAL: 'v1/work-session/total',
  GROUP: 'v1/team-group',
  UPDATE_USER_DAILY_LIMIT: 'v1/team-member/add-daily-limit',
};

export const CLIENTS_URLS = {
  CLIENTS: 'v1/client',
  BREAK_TYPE: 'v1/client/break-type',
  IMPORT_CLIENT: 'v1/client/import',
  MEMBERPROJLIST: 'v1/client/member-project-list',
};

export const INVOICE_URLS = {
  INVOICE: 'v1/invoice',
  TIMESHEET: 'v1/invoice/timesheet',
};

export const SCHEDULE_URLS = {
  SCHEDULES: 'v1/schedule',
  SHIFT: 'v1/shift',
  HOLIDAY: 'v1/holiday',
  ATTENDANCE: 'v1/schedule/attendance',
  COPYSCHEDULES: 'v1/schedule/copy-days',
};

export const POLICY_URLS = {
  SCHEDULES: 'v1/schedule',
  SHIFT: 'v1/shift',
  HOLIDAY: 'v1/holiday',
};

export const TOUR_URLS = {
  LIKEDISLIKE: 'v1/survey/update-response',
};
export const TIMEOFF_TYPE_URLS = {
  TYPE: 'v1/leave/type',
  LEAVE_REQUEST: 'v1/leave',
  LEAVE_REQUEST_LIST: 'v1/leave/list',
  POLICY: 'v1/leave/policy',
  CHANGE_REQUEST_STATUS: 'v1/leave/status-update',
  UPDATE_TYPE: 'v1/leave/type-update',
  LEAVE_BALANCE: 'v1/leave/balance',
  UPDATE_POLICY: 'v1/leave/policy-update',
};
export const NOTIFICATION_URLS = {
  NOTIFICATION_LIST: 'v1/notifications',
  NOTIFICATION_READ: 'v1/notifications/update-read-status',
};

export const SURVEY_QUESTIONS = {
  GET_SURVEY_QUESTIONS: 'v1/survey/questions',
  UPDATE_NAME: 'v1/user/update-name',
  UPDATE_ACCOUNT: 'v1/user/account-change',
  UPDATE_SURVEY_ANSWER: 'v1/survey/response',
  INVITE_ACCEPT: 'v1/team-member/invitation',
  SEND_OWNER_EMAIL: 'v1/team-member/join-request',
  CREATE_PROJECT_BASIC: 'v1/project/create-project-basic',
};

export const USER_URLS = {
  LOGIN: `v1/oauth/login`,
  WEB_LOGIN: `v1/oauth/web-login`,
  LOGOUT: `v1/oauth/logout`,
  USER: 'v1/user',
  FORGOT_PW: `v1/user/forgot-password`,
  RESET_PW: `v1/user/reset-password`,
  MEMBER_REG: `v1/user/registration`,
  FREE_TRIAL_REG: `v1/user/basic-signup`,
  TIMEZONE: 'v1/user/list-timezone',
  POLICY_ACCEPT: 'v1/policy/accept',
  ONBOARDING_PROGRESS: 'v1/user/onboarding-progress',
  CONFIRM_ACCOUNT: `v1/user/confirm`,
  CARD_STATUS: `v1/user/card-status`,
  ACCOUNT_DETAILS: `v1/user/account-details`,
  WEB_LOGIN_COUNT: `v1/user/login-count`,
  CRISP_EVENT_UPDATE: `v1/user/crisp-event-trigger`,
};

export const TIMESHEET_URLS = {
  TIMESHEET: `v1/timesheet`,
  EMAIL_REPORT: `v1/timesheet/email-timesheet`,
  VIEW: `v1/timesheet/weekly/view`,
  SUBMISSION: `v1/timesheet/timesheet-submission`,
  STATUS_UPDATE: `v1/timesheet/timesheet-approval`,
  APPROVAL: `v1/timesheet/weekly/approval`,
  TIMECARD_REPORT: `v1/timesheet/timecard-report`,
};

export const ACTIVITY = {
  MEMBER: 'v1/activity/member-activity',
  TEAM: 'v1/activity/team',
  TEAM_DAY: 'v1/activity/team-day',
  RECENT: 'v1/activity/recent',
  VIEW_SCREENSHOTS: 'v1/activity/view-screenshots',
};

export const SETTINGS_URLS = {
  UPDATE_AUTOCHECKOUT: 'v1/user/update-settings',
  GET_SETTINGS: 'v1/user/get-settings',
  CHANGE_PASSWORD: 'v1/user/change-password',
  USER_UPDATE: 'v1/user/update-user',
  TIMEZONE: 'v1/user/list-timezone',
  UPDATE_AVATAR: 'v1/user/update-avatar',
  DELETE_AVATAR: 'v1/user/delete-avatar',
  PAYMENT_INFO: 'v1/billing/payment-details',
  CARD_UPDATE: 'v1/billing/hosted-page-url',
  EMAIL_UPDATE: 'v1/billing/payment-details',
  INVOICE_LIST: 'v1/billing/invoices',
  DOWNLOAD_INVOICE: 'v1/billing/download-invoice',
  GET_PLANS: 'v1/billing/plans',
  REACTIVATE_ACCOUNT: 'v1/billing/reactivate-account',
  EMAIL_SUMMARIES: 'v1/email-settings',
  SETTINGS_APPROVAL: 'v1/user/settings-approval',
  UPDATE_OVERTIME: 'v1/settings/overtime',
  UPDATE_SCHEDULE_SETTINGS: 'v1/settings/scheduling',
  UPDATE_INVOICE_SETTINGS: 'v1/settings/invoice',
  DELETE_COMPANY_LOGO: 'v1/settings/invoice/logo',
  UPDATE_DAILY_LIMIT: 'v1/settings/daily-limit',
  GET_DAILY_LIMIT: 'v1/settings/daily-limit',
  NOTIFICATION_SETTING: 'v1/settings/email-notifications',
  ONGOING_SESSIONS: 'v1/settings/ongoing-sessions',
};

export const REPORTS_URLS = {
  SCREENSHOT: 'v1/report/screenshot',
  WORKSESSION: 'v1/report/worksession-abstract',
  REPORT_PDFS: 'v1/report/pdf',
  CREATE_PDF: 'v1/report/create-pdf',
  OVERTIME: 'v1/report/overtime',
};

export const WORKSESSION_URLS = {
  LIST: 'v1/work-session',
  NOTE: 'v1/work-session/note',
  DELETE_SCREENSHOTS: 'v1/work-session/delete-screenshots',
  CHECK_IN: 'v1/work-session/check-in',
  SCREENSHOT: 'v1/work-session/screenshot',
  CHECK_OUT: 'v1/work-session/check-out',
  REQUESTS: 'v1/work-session/list-edit-requests',
  PROCESS_REQUEST: 'v1/work-session/process-edit-requests',
  ONGOING_SESSIONS: 'v1/work-session/ongoing',
  BREAKS: 'v1/work-session/break',
  CHECKOUT: 'v1/work-session/force-check-out',
};

export const TASKS_URLS = {
  SECTION: 'v1/section',
  COPY_SECTION: 'v1/section/copy',
  GET_TASKS: 'v1/section/tasks',
  TASK: 'v1/section/task',
  MOVE: 'v1/section/task/move',
  TASK_LIST: 'v1/task', // QS - Accept single members id
  DUE_DATE: 'v1/task/due-date',
  TASK_LIST2: '/v1/task/list', // POST - Accept array of members id's
  IMPORT: 'v1/section/import',
  COMMENT: 'v1/task/comment',
};

export const PAGE_URLS = {
  DASHBOARD: '/dashboard',
  CLIENTS: '/clients',
  PROJECTS: {
    ACTIVE: '/projects/active',
  },
  TEAM: {
    ACTIVE: '/team/active',
    INACTIVE: '/team/inactive',
    TEAM_GROUPS: '/team/groups',
  },
  ACTIVITY: {
    MEMBER: '/activity/member',
    TEAM: '/activity/team/date-range',
    ONGOING_SESSIONS: '/activity/ongoing-sessions',
  },
  SCHEDULING: '/schedules',
  REPORTS: '/reports',
  TIMEOFF: {
    POLICY: '/timeoff/policy',
    TYPES: '/timeoff/types',
    REQUESTS: '/timeoff/requests',
  },
  INVOICES: '/invoices',
  TRACK: '/track',
  TIMELINE: '/timeline',
};

export const INTEGRATION_URLS = {
  ASANA_RETURN: 'v1/integration/asana-redirect-url',
  GET_ASANA_PROJECTS: 'v1/integration/get-asana-projects',
  LIST_ASANA_PROJECTS: 'v1/integration/asana-projects-list',
  ASANA_CLIENT_ID: REACT_APP_ASANA_CLIENT_ID,
  ZAPIER_CLIENT_VALIDATION: 'v1/user/client-validation',
  ZAPIER_USER_VALIDATION: 'v1/user/user-validation',
  CRISP_CHAT_ID: REACT_APP_CRISP_CHAT_ID,
};

export const RECAPTCHA_V3_URL = `https://www.google.com/recaptcha/api.js?render=`;
export const IMPORT_CLIENT_TEMPLATE_URL = `https://hd20be-uploads.s3.us-west-2.amazonaws.com/templates/import_client_template.xlsx`;
export const IMPORT_TASK_TEMPLATE_URL = `https://hd20be-uploads.s3.us-west-2.amazonaws.com/templates/import_section_template.csv`;
