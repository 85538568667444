import { useEffect, useState, useRef } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useDispatch } from 'react-redux';

import { auto } from '@popperjs/core';
import Loader from '../Loader';
import { updateLocalStorage } from '../../helpers';
import { settingsService } from '../../services';
import {
  API_FAILURE_ERROR,
  SETTINGS_BILLING,
  LOCAL_STORAGE_USER_DATA,
  UPDATE_FAILURE_ERROR,
} from '../../constants';
import { getPlanAndType } from '../../helpers/functions';
import { setCurrentPlan, setAccountStatus } from '../../actions/billing';

const ChangePlan = () => {
  const [plans, setPlans] = useState([]); /* Plan list until 500 member count */
  const [planProp, setPlanProp] = useState(); /* Save the plan price */
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedTab, setSelectedTab] = useState('M');
  const [defaultPlan, setDefaultPlan] = useState(
    {}
  ); /* default value of plan dropdown */

  const plansData = useRef(); /* Save plans fetched from API */
  const currentPlan = useRef(); /* Save user existing plan */
  const dispatch = useDispatch();

  const userdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  const userPlan = getPlanAndType(userdata.subscriptionDetails.currentPlan);

  /* Create plan list of membercount until 500 with step 5 and sort
    Skip trial, quarterly & discount plans (contains d)
  */
  const createPlansList = (type) => {
    const memberLimit = 501;
    const newPlans = [];
    const planFilter = type === 'M' ? 'monthly' : 'annual';

    const filteredPLans = plansData.current.filter(
      (elem) =>
        elem.planType === planFilter &&
        !elem.planName.includes('micro') &&
        !elem.planName.includes('trial') &&
        !elem.planName.includes('quarterly') &&
        !elem.planName.includes('d')
    );

    newPlans.push(
      Array(memberLimit)
        .fill(0)
        .map(function (e, j) {
          if (j % 5 === 0 && j !== 0) {
            if (!filteredPLans.some((el) => el.maxWorkerCount === j)) {
              const annualPrice = j * 5 * 12;
              const AnnualDiscount =
                (annualPrice * 20) / 100; /* 20% disc on annual price */
              return {
                _id: 0,
                planId: 0,
                planName: `XL-${j}`,
                planPrice: type === 'M' ? j * 5 : annualPrice - AnnualDiscount,
                maxWorkerCount: j,
              };
            }
          }
          return 0;
        })
    );

    let sortPlans = newPlans[0].filter((el) => el !== 0);
    sortPlans = [...filteredPLans, ...sortPlans];
    sortPlans.sort((a, b) => (a.maxWorkerCount < b.maxWorkerCount ? -1 : 1));

    setPlans(
      sortPlans.map((el) => ({
        value: `${el.planId}:${el.planPrice}:${el.planName}`,
        label: el.maxWorkerCount,
      }))
    );
  };

  useEffect(() => {
    settingsService
      .get_plans()
      .then((result) => {
        plansData.current = result.data;

        const getCurrent = result.data.filter(
          (elem) =>
            elem.planType === userPlan[1] && elem.planName === userPlan[0]
        );
        if (userPlan[1] === 'monthly' || userPlan[0] === 'trial') {
          setSelectedTab('M');
          createPlansList('M');
        } else {
          setSelectedTab('A');
          createPlansList('A');
        }
        if (getCurrent[0]) {
          setPlanProp(getCurrent[0].planPrice);
          setDefaultPlan({
            label: getCurrent[0].maxWorkerCount,
            value: `${getCurrent[0].planId}:${getCurrent[0].planPrice}:${getCurrent[0].planName}`,
          });
          currentPlan.current = getCurrent;
        }
      })
      .catch((errResp) => {
        toast.error(API_FAILURE_ERROR);
      });
  }, []);

  /* onclick change plan button */
  const submitChangePlan = () => {
    const selectedPlan = defaultPlan.value.split(':');
    const selectedType = selectedTab === 'M' ? 'monthly' : 'annual';
    setIsSubmitting(true);

    if (userPlan[0] === 'trial') {
      const data = {
        ownerId: localStorage.getItem('teamOwnerId'),
        pageType: 0,
        planId: selectedPlan[0],
        memberCount: parseInt(defaultPlan.label),
        planType: selectedType,
      };

      settingsService
        .get_hosted_page_url(data)
        .then((response) => {
          if ('data' in response) {
            window.location.href = response.data.url;
          }
          setIsSubmitting(false);
        })
        .catch((errResp) => {
          toast.error(API_FAILURE_ERROR);
          setIsSubmitting(false);
        });
    } else {
      const data = {
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        planId: selectedPlan[0],
        changePlan: 1,
        totalMembers: parseInt(defaultPlan.label),
        planType: selectedType,
      };

      settingsService
        .user_update(data)
        .then((response) => {
          if (response.status) {
            toast.success(SETTINGS_BILLING.PLAN_UPDATE);
            /* update subscription details, user status & plan in local storage */
            updateLocalStorage({
              userStatus: null,
              maxMembersAllowed: parseInt(defaultPlan.label),
              subscriptionDetails: {
                currentPlan: `${selectedPlan[2]} ${selectedType}`,
              },
            });
            dispatch(setAccountStatus({ accountStatus: null }));
            dispatch(
              setCurrentPlan({ planName: `${selectedPlan[2]} ${selectedType}` })
            );
          }
          setIsSubmitting(false);
        })
        .catch((err) => {
          setIsSubmitting(false);
          if ('message' in err) {
            if (err.data) {
              err.data.forEach((elem) => {
                toast.error(elem.msg);
              });
            } else {
              toast.error(err.message);
            }
          } else {
            toast.error(UPDATE_FAILURE_ERROR);
          }
        });
    }
  };

  const handleChange = (selectedValue) => {
    setDefaultPlan({
      value: selectedValue.value,
      label: selectedValue.label,
    });
    setPlanProp(selectedValue.value.split(':')[1]);
  };

  const colourStyles = {
    option: (styles, { isSelected }) => ({
      ...styles,
      backgroundColor: isSelected ? '#6e84a3' : null,
    }),
  };
  return (
    <>
      <Card>
        {isSubmitting && <Loader contentAreaOnly />}
        <Card.Header>
          <h4 className="card-header-title">Change my plan</h4>
        </Card.Header>
        <Card.Body>
          <Row className="mb-3">
            <Col className="form-inline">
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <span
                  className={`btn btn-light${
                    selectedTab === 'M' ? ' active' : ''
                  }`}
                  onClick={() => {
                    setSelectedTab('M');
                    createPlansList('M');
                    if (userPlan[1] === 'monthly') {
                      setDefaultPlan({
                        value: `${currentPlan.current[0].planId}:${currentPlan.current.planPrice}:${currentPlan.current.planName}`,
                        label: currentPlan.current[0].maxWorkerCount,
                      });
                    } else {
                      setDefaultPlan({
                        value: '',
                        label: '',
                      });
                    }
                  }}
                  onKeyPress={() => {
                    setSelectedTab('M');
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Monthly
                </span>
                <span
                  className={`btn btn-light${
                    selectedTab === 'A' ? ' active' : ''
                  }`}
                  onClick={() => {
                    setSelectedTab('A');
                    createPlansList('A');
                    if (userPlan[1] === 'annual') {
                      setDefaultPlan({
                        value: `${currentPlan.current[0].planId}:${currentPlan.current.planPrice}:${currentPlan.current.planName}`,
                        label: currentPlan.current[0].maxWorkerCount,
                      });
                    } else {
                      setDefaultPlan({
                        value: '',
                        label: '',
                      });
                    }
                    setDefaultPlan({ value: '', label: '' });
                  }}
                  onKeyPress={() => {
                    setSelectedTab('A');
                  }}
                  role="button"
                  tabIndex={0}
                >
                  Annual
                </span>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mb-4">
            <Col md={auto}>
              <span className="col-form-label">Choose Team Members</span>
            </Col>
            <Col md="2" style={{ maxWidth: '105px' }}>
              <Select
                value={defaultPlan}
                onChange={(value) => {
                  handleChange(value);
                }}
                options={plans}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: '',
                    primary: '#edf2f9',
                  },
                })}
                styles={colourStyles}
              />
            </Col>
            <Col md="auto" className="ml-auto">
              <Button onClick={submitChangePlan}>Change my plan</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {defaultPlan.label && (
                <p className="alert bg-light mb-0">
                  You selected {defaultPlan.label} team members: ${planProp}/
                  {selectedTab === 'M' ? 'month' : 'year'}
                </p>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ChangePlan;
