import Select from 'react-select';
import PropTypes from 'prop-types';

const colorOptions = [
  { value: '#91CEF2', label: 'Blue' },
  { value: '#EE7070', label: 'Red' },
  { value: '#D89379', label: 'Light Brown' },
  { value: '#F3CE77', label: 'Yellow' },
  { value: '#EDE37B', label: 'Light Yellow' },
  { value: '#5FCF92', label: 'Green' },
  { value: '#87DBAD', label: 'Light Green' },
  { value: '#79D8D5', label: 'Cyan' },
  { value: '#5FB7EC', label: 'Sky Blue' },
  { value: '#379CD9', label: 'Dark Blue' },
  { value: '#918DEA', label: 'Purple' },
  { value: '#D1A6DB', label: 'Light Purple' },
  { value: '#EDACC7', label: 'Peach' },
  { value: '#DE8BAE', label: 'Pink' },
  { value: '#DAE1E2', label: 'Gray' },
  { value: '#AABBBD', label: 'Silver' },
];

const ColorSelector = ({ name, selectedColor, onSelectColor }) => {
  const handleChange = (selectedOption) => {
    onSelectColor(selectedOption.value);
  };

  return (
    <Select
      options={colorOptions}
      onChange={handleChange}
      value={colorOptions.find((option) => option.value === selectedColor)}
      isOptionSelected={(option) => option.value === selectedColor}
      name={name}
      components={{
        // eslint-disable-next-line react/prop-types
        Option: ({ innerProps, label, value }) => (
          <div {...innerProps} className="d-flex align-items-center px-3 mb-2">
            <div
              style={{
                backgroundColor: value,
                width: '15px',
                height: '15px',
                marginRight: '10px',
              }}
            />
            {label}
          </div>
        ),
      }}
    />
  );
};

ColorSelector.propTypes = {
  name: PropTypes.string,
  selectedColor: PropTypes.string,
  onSelectColor: PropTypes.func,
};

export default ColorSelector;
