import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { StopWatch } from './index';
import {
  setTimerProject,
  setTimerTask,
  resetWidget,
  checkIn,
  checkOut,
  startTimer,
  stopTimer,
} from '../../actions/timer';

const TaskTimer = ({ projectId, projectName, taskId, taskName }) => {
  const dispatch = useDispatch();
  const timer = useSelector((state) => state.timerReducer);
  const { isRunning, checkedInTask, worksessionId } = timer;
  return (
    <div className="task-timer d-inline-block">
      {isRunning && checkedInTask && checkedInTask.id === taskId ? (
        <div className="d-flex align-items-center">
          <Button
            className="m-0 rounded-circle task-checkin-btn p-0"
            onClick={() => {
              dispatch(stopTimer());
              // checkOut
              dispatch(
                checkOut({
                  wsId: worksessionId,
                  reFetchTasks: true,
                  projectId,
                })
              );
              // Reset widget
              dispatch(resetWidget());
            }}
            title="Stop Timer"
            variant="outline-danger"
          >
            <span className="mdi mdi-stop" />
          </Button>
          <span className="clock-lg task-card-stop-watch ms-2">
            <StopWatch />
          </span>
        </div>
      ) : (
        <Button
          className="m-0 rounded-circle task-checkin-btn task-checkin-btn-inactive inactive p-0"
          onClick={() => {
            dispatch(
              setTimerProject({
                projectId,
                projectName,
              })
            );
            dispatch(
              setTimerTask({
                taskId,
                taskName,
              })
            );
            dispatch(startTimer());
            // checkIn
            dispatch(
              checkIn({
                projectId,
                taskId,
              })
            );
          }}
          title="Start Timer"
          variant="primary"
        >
          <span className="mdi mdi-play" />
        </Button>
      )}
    </div>
  );
};

TaskTimer.propTypes = {
  projectId: PropTypes.string,
  projectName: PropTypes.string,
  taskId: PropTypes.string,
  taskName: PropTypes.string,
};

export default TaskTimer;
