import React, { useState } from 'react';
import {
  Button,
  Card,
  Accordion,
  useAccordionToggle,
  Row,
  Col,
  Nav,
  Alert,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import { WeeklyStatusToggle, ExportOptions } from './index';
import { sToHMSColonSeparated, groupBy } from '../../helpers';
import { HELPER_TEXT_BILLABLE_BREAKS } from '../../constants';

const ViewTimeCardContent = ({
  isLoading,
  timecardDetails,
  loadMoreData,
  submitTimesheet,
  statusUpdate,
}) => {
  const [panelIndex, setPanelIndex] = useState();

  const CustomToggle = ({ children, eventKey }) => {
    const customOnClick = useAccordionToggle(eventKey, () => {
      setPanelIndex(eventKey === panelIndex ? null : eventKey);
    });

    const customClass = eventKey === panelIndex ? 'opened' : 'collapsed';

    return (
      <Nav.Link
        className={`${customClass} hd-accordion-button`}
        onClick={customOnClick}
      >
        {children}
      </Nav.Link>
    );
  };

  CustomToggle.propTypes = {
    children: PropTypes.any,
    eventKey: PropTypes.any,
  };

  return (
    <>
      {timecardDetails.length > 0 ? (
        <Card>
          <section>
            <ExportOptions
              timesheetDetails={timecardDetails}
              viewTab="timeCard"
            />
            <Accordion>
              {timecardDetails.map((week) => (
                <section key={`week-tc-${week._id}`}>
                  <Card.Header>
                    <Row className="align-items-center">
                      <Col>
                        <CustomToggle
                          eventKey={week._id}
                          className="text-start"
                        >
                          {moment
                            .utc(week.wsWeekDetails.startDate)
                            .format('MMM D')}{' '}
                          -{' '}
                          {moment
                            .utc(week.wsWeekDetails.endDate)
                            .format('MMM D')}
                        </CustomToggle>
                      </Col>
                      <Col className="col-auto p-0">
                        <WeeklyStatusToggle
                          status={week.status}
                          logs={week.approvalLogs}
                          wsWeekDetails={week.wsWeekDetails}
                          submitTimesheet={submitTimesheet}
                          wsWeekStatusUpdate={statusUpdate}
                          weeklySubmissionId={
                            week.approvalId !== null ? week.approvalId : ''
                          }
                        />
                      </Col>
                    </Row>
                  </Card.Header>
                  <Accordion.Collapse eventKey={week._id}>
                    <Card.Body className="p-0">
                      <div className="table-responsive">
                        <table
                          id="groupedDataByMember"
                          className="table table-sm table-hover card-table"
                        >
                          <thead className="thead-light">
                            <tr>
                              <th>&nbsp;</th>
                              <th className="text-center">Clock in</th>
                              <th className="text-center">Clock out</th>
                              <th className="text-center">Total</th>
                              <th className="text-center">
                                Breaks{' '}
                                <Tippy content={HELPER_TEXT_BILLABLE_BREAKS}>
                                  <i className="mdi mdi-information-outline text-dark" />
                                </Tippy>
                              </th>
                              <th className="text-center">Productive</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {week.data.map((day) => {
                              let breakTypesList = [];
                              // eslint-disable-next-line no-unused-vars
                              const sessionBreakTypes =
                                day.workSessions.length > 0 &&
                                day.totalBreakTimeSeconds > 0
                                  ? day.workSessions.map((item) =>
                                      item.breakTimeSeconds > 0
                                        ? (breakTypesList = [
                                            ...breakTypesList,
                                            ...item.break,
                                          ])
                                        : null
                                    )
                                  : [];

                              // Group break list by name
                              const breakTypeArray = groupBy(
                                breakTypesList,
                                'type'
                              );
                              return (
                                <tr key={`day${day.date}`}>
                                  <td>
                                    {moment(day.date).format('ddd, MMM DD')}
                                    {day.schedule && day.schedule.isHoliday && (
                                      <span className="badge bg-danger-soft ms-2 rounded-1">
                                        Holiday
                                      </span>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {day.clockIn
                                      ? moment(day.clockIn).format('hh:mm:ss A')
                                      : '-'}
                                  </td>
                                  <td className="text-center">
                                    {day.clockOut
                                      ? moment(day.clockOut).format(
                                          'hh:mm:ss A'
                                        )
                                      : '-'}
                                  </td>
                                  <td className="text-center">
                                    {day.totalWorkSessionSeconds > 0
                                      ? sToHMSColonSeparated(
                                          day.totalWorkSessionSeconds,
                                          'hh:mm:ss'
                                        )
                                      : '00:00:00'}
                                  </td>
                                  <td className="text-center">
                                    {day.totalBreakTimeSeconds > 0 ? (
                                      <Tippy
                                        content={
                                          <div
                                            style={{ maxWidth: '200px' }}
                                            className="px-2 pt-1 pb-2"
                                          >
                                            {Object.entries(breakTypeArray)
                                              .sort()
                                              .map((breakArr, breakIndex) => {
                                                const groupedBreakType = [
                                                  ...breakArr[1],
                                                ];
                                                const breakTypeTotal =
                                                  groupedBreakType.reduce(
                                                    (pValue, cValue) =>
                                                      pValue + cValue.breakTime,
                                                    0
                                                  );
                                                return (
                                                  <Row
                                                    key={breakIndex}
                                                    className="pb-2"
                                                  >
                                                    <Col>
                                                      {groupedBreakType[0]
                                                        .breakName
                                                        ? `${
                                                            groupedBreakType[0]
                                                              .breakName
                                                          } ${
                                                            groupedBreakType[0]
                                                              .billable
                                                              ? '*'
                                                              : ''
                                                          }`
                                                        : 'Other'}
                                                    </Col>
                                                    <Col className="col-auto">
                                                      <small>
                                                        {sToHMSColonSeparated(
                                                          breakTypeTotal / 1000,
                                                          'hh:mm:ss'
                                                        )}
                                                      </small>
                                                    </Col>
                                                  </Row>
                                                );
                                              })}
                                          </div>
                                        }
                                        theme="light"
                                      >
                                        <span>
                                          {sToHMSColonSeparated(
                                            day.totalBreakTimeSeconds,
                                            'hh:mm:ss'
                                          )}
                                        </span>
                                      </Tippy>
                                    ) : (
                                      '00:00:00'
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {day.totalWorkSessionSeconds > 0
                                      ? sToHMSColonSeparated(
                                          day.totalWorkSessionSeconds -
                                            day.totalNonBillableBreakTimeSeconds,
                                          'hh:mm:ss'
                                        )
                                      : '00:00:00'}
                                  </td>
                                  <th>&nbsp;</th>
                                </tr>
                              );
                            })}

                            <tr>
                              <td colSpan={3} align="right">
                                &nbsp;
                              </td>
                              <td className="text-center">
                                <strong>
                                  {sToHMSColonSeparated(
                                    week.totalWorkSessionSeconds,
                                    'hh:mm:ss'
                                  )}
                                </strong>
                              </td>
                              <td className="text-center">
                                <strong>
                                  {sToHMSColonSeparated(
                                    week.totalBreakTimeSeconds,
                                    'hh:mm:ss'
                                  )}
                                </strong>
                              </td>
                              <td className="text-center">
                                <strong>
                                  {sToHMSColonSeparated(
                                    week.totalWorkSessionSeconds -
                                      week.totalNonBillableBreakTimeSeconds,
                                    'hh:mm:ss'
                                  )}
                                </strong>
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                        <hr className="m-0" />
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </section>
              ))}
            </Accordion>
            {!isLoading && (
              <Card.Header>
                <Button
                  variant="link"
                  className="text-start text-muted p-0"
                  onClick={() => loadMoreData()}
                >
                  Load more ...
                </Button>
              </Card.Header>
            )}
          </section>
        </Card>
      ) : (
        !isLoading && (
          <Alert variant="info" className="text-center">
            No worksession available
          </Alert>
        )
      )}
    </>
  );
};

ViewTimeCardContent.propTypes = {
  timecardDetails: PropTypes.array,
  isLoading: PropTypes.bool,
  loadMoreData: PropTypes.func,
  submitTimesheet: PropTypes.func,
  statusUpdate: PropTypes.func,
};

export default ViewTimeCardContent;
