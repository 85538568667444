import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  WorksessionReportModal,
  MemberRoleModal,
  DeactivateMemberModal,
  EditHourlyRateModal,
  AddDailyLimitModal,
} from '.';

const TeamKababMenu = ({
  userId,
  userRole,
  userRate,
  currency,
  totalProjects,
}) => {
  const userDetails = useSelector((state) => state.userReducer);
  return (
    <Dropdown className="card-dropdown">
      <Dropdown.Toggle
        as={Link}
        to={{ hash: '#' }}
        className="project-kabab-menu dropdown-ellipses dropdown-toggle"
      >
        <i className="mdi mdi-dots-horizontal" />
      </Dropdown.Toggle>

      <Dropdown.Menu align="left">
        <>
          {(userDetails.role === 'creator' ||
            userDetails.role === 'full_manager') &&
            totalProjects > 0 && (
              <Dropdown.Item href={`/team/worksession/${userId}`}>
                Edit Worksession
              </Dropdown.Item>
            )}
          {userRole !== 'creator' && (
            <>
              <WorksessionReportModal userId={userId} />
              {userDetails.role !== 'manager' && (
                <>
                  <MemberRoleModal userId={userId} userRole={userRole} />
                  <DeactivateMemberModal userId={userId} />
                </>
              )}
            </>
          )}
          <EditHourlyRateModal
            userId={userId}
            userRole={userRole}
            userRate={userRate}
            currency={currency}
          />
          <AddDailyLimitModal userId={userId} userRole={userRole} />
        </>
      </Dropdown.Menu>
    </Dropdown>
  );
};

TeamKababMenu.propTypes = {
  userId: PropTypes.string,
  userRole: PropTypes.string,
  userRate: PropTypes.number,
  currency: PropTypes.string,
  totalProjects: PropTypes.number,
};

export default TeamKababMenu;
