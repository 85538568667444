import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { ReadMore } from '../common';
import { sToHMSColonSeparated } from '../../helpers';

const GroupByDate = ({
  timesheetMetadata,
  dateTimesheet,
  grandTotal,
  showNotes,
  isSummarizedReport,
  isMobileTracking,
}) => {
  let colSpan = isSummarizedReport ? 3 : 5;
  if (isMobileTracking) {
    colSpan += 1;
  }
  return (
    <div className="table-responsive">
      <table
        id="groupedDataByDate"
        className="table table-sm table-hover card-table"
      >
        <thead className="thead-light">
          <tr>
            <th>Project</th>
            <th>Team member</th>
            {!isSummarizedReport && <th>Task</th>}
            {!isSummarizedReport && <th>Worksession Date</th>}
            {!isSummarizedReport && <th>Worksession Time</th>}
            {isSummarizedReport && <th>Hourly Rate</th>}
            {isMobileTracking && <th>Worksession Type</th>}
            <th>{isSummarizedReport ? 'Logged time' : 'Duration'}</th>
            <th>Active Time</th>
            <th>Activity</th>
            <th>
              Cost (
              {timesheetMetadata.defaultCurrency == null
                ? 'USD'
                : timesheetMetadata.defaultCurrency}
              )
            </th>
            {showNotes && <th>Notes</th>}
          </tr>
        </thead>
        <tbody>
          {Object.entries(dateTimesheet).map((key, value) => {
            let totalCost = 0;
            let memberSession = [...key[1]];
            if (isSummarizedReport) {
              memberSession = [
                ...memberSession
                  .reduce((r, o) => {
                    const gKey = isMobileTracking
                      ? `${o.projectId}-${o.userName}-${o.workSessionType}`
                      : `${o.projectId}-${o.userName}`;

                    const item = r.get(gKey) || {
                      ...o,
                      activeSeconds: 0,
                      activity: 0,
                      idleSeconds: 0,
                      projectTitle: '',
                      hourlyRate: 0,
                      userName: '',
                      workSessionSeconds: 0,
                      activityActiveTime: 0,
                      activityActiveDuration: 0,
                    };

                    item.activeSeconds += Math.round(o.activeSeconds);
                    if (
                      !o.isManual &&
                      o.workSessionType.toLowerCase() === 'desktop'
                    ) {
                      item.activityActiveTime += Math.round(o.activeSeconds);
                      item.activityActiveDuration += Math.round(
                        o.workSessionSeconds
                      );
                    }
                    item.idleSeconds += Math.round(o.idleSeconds);
                    item.projectTitle =
                      o.projectTitle +
                      (o.projectStatus === 'close' ? ' (Closed)' : '');
                    item.hourlyRate = o.rate.hourlyCostRate;
                    item.userName = o.userName;
                    item.workSessionSeconds += Math.round(o.workSessionSeconds);
                    // Member totalCost
                    const cost =
                      !o.workSessionSeconds || !o.rate.hourlyCostRate
                        ? 0
                        : Math.round(
                            (Math.round(o.workSessionSeconds) / 3600) *
                              o.rate.hourlyCostRate *
                              100
                          ) / 100;
                    totalCost +=
                      Math.round((cost + Number.EPSILON) * 100) / 100;
                    return r.set(gKey, item);
                  }, new Map())
                  .values(),
              ];

              memberSession.map(
                (worksession, index) =>
                  (memberSession[index].activity =
                    worksession.activityActiveDuration > 0
                      ? (worksession.activityActiveTime /
                          worksession.activityActiveDuration) *
                        100
                      : 0)
              );
            } else {
              // Member totalCost
              totalCost = memberSession.reduce(function (sum, val) {
                const cost =
                  !val.workSessionSeconds || !val.rate.hourlyCostRate
                    ? 0
                    : Math.round(
                        (Math.round(val.workSessionSeconds) / 3600) *
                          val.rate.hourlyCostRate *
                          100
                      ) / 100;
                return sum + Math.round((cost + Number.EPSILON) * 100) / 100;
              }, 0);
            }
            // Member totalDuration
            const totalDuration = memberSession.reduce(function (sum, val) {
              return sum + Math.round(val.workSessionSeconds);
            }, 0);
            const totalActivityDuration = memberSession.reduce(function (
              sum,
              val
            ) {
              if (isSummarizedReport) {
                return sum + Math.round(val.activityActiveDuration);
              }
              if (
                !val.isManual &&
                val.workSessionType.toLowerCase() === 'desktop'
              ) {
                return sum + Math.round(val.workSessionSeconds);
              }
              return sum;
            },
            0);

            // Member totalActiveTime
            const totalActiveTime = memberSession.reduce(function (sum, val) {
              return sum + Math.round(val.activeSeconds);
            }, 0);
            const totalActivityActiveTime = memberSession.reduce(function (
              sum,
              val
            ) {
              if (isSummarizedReport) {
                return sum + Math.round(val.activityActiveTime);
              }
              if (
                !val.isManual &&
                val.workSessionType.toLowerCase() === 'desktop'
              ) {
                return sum + Math.round(val.activeSeconds);
              }
              return sum;
            },
            0);

            // Member totalActivity
            const totalActivity =
              totalActivityDuration > 0
                ? (totalActivityActiveTime / totalActivityDuration) * 100
                : 0;

            return (
              <React.Fragment key={value}>
                <tr className="table-light">
                  <td colSpan="11" className="px-4 py-2">
                    <strong>
                      {moment(memberSession[0].checkIn).format('dddd, MMMM D')}
                    </strong>
                  </td>
                </tr>
                {memberSession.map((worksession, index) => (
                  <tr key={index}>
                    <td>
                      {worksession.projectTitle}
                      {worksession.projectStatus === 'close' ? ' (Closed)' : ''}
                    </td>
                    <td>
                      {worksession.userName}
                      {worksession.userTeamStatus === 'inactive' &&
                        ' (Inactive)'}
                    </td>
                    {!isSummarizedReport && (
                      <td>{worksession.task ? worksession.task : '--'}</td>
                    )}
                    {!isSummarizedReport && (
                      <td>
                        {worksession.checkInDate &&
                          moment(worksession.checkInDate).format('LL')}
                        <br />
                        {worksession.checkOutDate &&
                          moment(worksession.checkOutDate).format('LL')}
                      </td>
                    )}

                    {!isSummarizedReport && (
                      <td>
                        {worksession.checkInDate &&
                          moment(worksession.checkInDate).format('hh:mm:ss A')}
                        <br />
                        {worksession.checkOutDate &&
                          moment(worksession.checkOutDate).format('hh:mm:ss A')}
                      </td>
                    )}
                    {isSummarizedReport && (
                      <td>
                        {worksession.rate.hourlyCostRate
                          ? worksession.rate.hourlyCostRate
                          : '--'}
                      </td>
                    )}
                    {isMobileTracking && (
                      <td className="text-capitalize">
                        {worksession.workSessionType
                          ? worksession.workSessionType
                          : 'Desktop'}
                      </td>
                    )}
                    <td>
                      {sToHMSColonSeparated(
                        Math.round(worksession.workSessionSeconds)
                      )}
                    </td>
                    <td>
                      {sToHMSColonSeparated(
                        Math.round(worksession.activeSeconds)
                      )}
                    </td>
                    {!isSummarizedReport === true ? (
                      <td>
                        {worksession.workSessionType === 'desktop' ? (
                          <>
                            {worksession.activity > 0
                              ? Math.round(worksession.activity)
                              : 0}
                            %
                          </>
                        ) : (
                          <span className="text-muted">N/A</span>
                        )}
                      </td>
                    ) : (
                      <>
                        <td>
                          {worksession.activity > 0
                            ? Math.round(worksession.activity)
                            : 0}
                          %
                        </td>
                      </>
                    )}
                    <td>
                      {!worksession.workSessionSeconds ||
                      !worksession.rate.hourlyCostRate
                        ? '--'
                        : (
                            Math.round(
                              ((Math.round(worksession.workSessionSeconds) /
                                3600) *
                                worksession.rate.hourlyCostRate +
                                Number.EPSILON) *
                                100
                            ) / 100
                          ).toFixed(2)}
                    </td>
                    {showNotes && (
                      <td>
                        {'notes' in worksession ? (
                          <ul className="list-unstyled notes-list">
                            {worksession.notes.map((noteEntry) => (
                              <li className="mb-2" key={noteEntry.createdDate}>
                                <ReadMore note={noteEntry.note} />
                              </li>
                            ))}
                          </ul>
                        ) : (
                          '--'
                        )}
                      </td>
                    )}
                  </tr>
                ))}
                <tr>
                  <td colSpan={colSpan} className="text-end">
                    <b> Total</b>
                  </td>
                  <td>
                    <strong>{sToHMSColonSeparated(totalDuration)}</strong>
                  </td>
                  <td>
                    <strong>{sToHMSColonSeparated(totalActiveTime)}</strong>
                  </td>
                  <td>
                    <b>{Math.round(totalActivity)}%</b>
                  </td>
                  <td>
                    <b>{totalCost.toFixed(2)}</b>
                  </td>
                  {showNotes && <td>&nbsp;</td>}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>

        <tfoot>
          <tr className="table-light">
            <td colSpan={colSpan} className="text-end">
              <span className="h4">Grand total</span>
            </td>
            <td>
              <span className="h4">
                {sToHMSColonSeparated(grandTotal.duration)}
              </span>
            </td>
            <td>
              <span className="h4">
                {sToHMSColonSeparated(grandTotal.activeTime)}
              </span>
            </td>
            <td>
              <span className="h4">{Math.round(grandTotal.activity)}%</span>
            </td>
            <td>
              <span className="h4">{grandTotal.cost.toFixed(2)}</span>
            </td>
            {showNotes && <td>&nbsp;</td>}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

GroupByDate.propTypes = {
  dateTimesheet: PropTypes.any,
  timesheetMetadata: PropTypes.object,
  grandTotal: PropTypes.object,
  showNotes: PropTypes.bool,
  isSummarizedReport: PropTypes.bool,
  isMobileTracking: PropTypes.bool,
};

export default GroupByDate;
