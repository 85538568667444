import React, { useContext } from 'react';
import { useAccordionToggle, AccordionContext, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';

const AccordionToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Nav.Link
      className={`${
        isCurrentEventKey ? 'opened' : 'collapsed'
      } px-0 hd-accordion-button m-0`}
      onClick={decoratedOnClick}
    >
      {children}
    </Nav.Link>
  );
};

AccordionToggle.propTypes = {
  children: PropTypes.any,
  eventKey: PropTypes.any,
  callback: PropTypes.any,
};

export default AccordionToggle;
