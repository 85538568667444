import React from 'react';
import { Redirect } from 'react-router-dom';
import { getUserData } from '../../helpers';
import { PAGE_URLS } from '../../constants';

const RedirectionAfterLogin = () => {
  const subscriptionDetails = getUserData('subscriptionDetails');
  const totalProjects = getUserData('totalProjects');
  const defaultTeam = getUserData('defaultTeam');

  if (
    subscriptionDetails.currentPlan === 'trial' &&
    defaultTeam.role === 'creator' &&
    !totalProjects
  ) {
    return <Redirect to="/projects/create" />;
  }

  if (defaultTeam && defaultTeam.role === 'member') {
    return <Redirect to={PAGE_URLS.PROJECTS.ACTIVE} />;
  }
  return <Redirect to={PAGE_URLS.DASHBOARD} />;
};

export default RedirectionAfterLogin;
