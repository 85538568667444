const initialState = {
  isLoading: false,
  recentActivity: [],
  error: null,
};

export const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCHING_RECENT_ACTIVITY':
      return {
        ...state,
        isLoading: true,
      };
    case 'SET_RECENT_ACTIVITY':
      return {
        ...state,
        isLoading: false,
        recentActivity: [...action.payload],
      };
    case 'FETCH_RECENT_ACTIVITY_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
