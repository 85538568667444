import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

// HOC for confirmation modal
const WithConfirmation = (WrappedComponent) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const WithConfirmationModal = ({ modalProps }) => (
    <>
      <WrappedComponent handleShow={handleShow} />
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title className="m-0">{modalProps.title}</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        {modalProps.content && (
          <Modal.Body>
            <p>
              {modalProps.content.map((item, index) => (
                <span key={index}>
                  {item}
                  <br />
                </span>
              ))}
            </p>
          </Modal.Body>
        )}
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="link" onClick={handleClose}>
            Cancel
          </Button>
          {(modalProps.params && modalProps.params.length) > 0 ? (
            <Button
              variant="danger"
              className=" ml-auto"
              onClick={() => {
                modalProps.action(modalProps.params);

                if (modalProps.id) {
                  modalProps.secondAction(modalProps.id);
                }
                handleClose();
              }}
            >
              {modalProps.actionBtnText}
            </Button>
          ) : (
            <Button
              variant={modalProps.btnVariant ? modalProps.btnVariant : 'danger'}
              className=" ml-auto"
              onClick={() => {
                modalProps.action();
                handleClose();
              }}
            >
              {modalProps.actionBtnText}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
  WithConfirmationModal.propTypes = {
    modalProps: PropTypes.object,
  };
  return WithConfirmationModal;
};

export default WithConfirmation;
