import React from 'react';
import PropTypes from 'prop-types';
import SwiperCore, { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { NoDataAvailable } from './common';
import { NO_DATA, DATE_AND_TIME } from '../constants';
import 'swiper/swiper.scss';
import '../assets/styles/carousel.scss';

// install Swiper components
SwiperCore.use([Navigation, A11y]);

const ScreenshotSlider = ({
  screenshots,
  showCheckboxSelection,
  isSelected,
  handleScreenshotSelection,
  workSessionType,
  screenshotDisabled,
  memberName,
  sliderSize,
}) => {
  let ssContent = '';
  if (workSessionType === 'mobile') {
    ssContent = NO_DATA.MOBILE_SESSION;
  } else if (workSessionType === 'extension') {
    ssContent = NO_DATA.EXTENSION_SESSION;
  } else if (workSessionType === 'web') {
    ssContent = NO_DATA.WEB_SESSION;
  } else if (screenshotDisabled === true) {
    ssContent = NO_DATA.SCREENSHOTS;
  }
  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={5}
      navigation={{
        prevEl: prevRef.current ? prevRef.current : undefined,
        nextEl: nextRef.current ? nextRef.current : undefined,
      }}
      onInit={(swiper) => {
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.update();
      }}
      breakpoints={{
        320: {
          slidesPerView: 2,
          spaceBetween: 6,
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 8,
        },
        1024: {
          slidesPerView: 6,
          spaceBetween: 10,
        },
        1600: {
          slidesPerView: 9,
          spaceBetween: 20,
        },
      }}
    >
      {ssContent ? (
        <NoDataAvailable content={ssContent} />
      ) : (
        screenshots.map((screenshot, index) => {
          const capturedDateAndTime = screenshot.date.split(' ');
          const capturedTime = moment(capturedDateAndTime[1], [
            'HH:mm:ss',
          ]).format(DATE_AND_TIME.TIME);
          const caption = `${memberName} - ${moment(screenshot.date).format(
            'MMM DD, YYYY h:mm:ss A'
          )}`;
          const discardedScreenshot = screenshot.screenshotDiscard;
          return (
            !discardedScreenshot && (
              <SwiperSlide
                key={screenshot._id ? screenshot._id : `screenshot-${index}`}
              >
                {screenshot.screenshotPath !== null && (
                  <>
                    <Tippy
                      content={moment(screenshot.date).format(
                        'MMM DD, YYYY h:mm:ss A'
                      )}
                      theme="light"
                    >
                      <a href={screenshot.screenshotPath} data-attribute="SRL">
                        <img
                          src={
                            screenshot.screenshotThumbnailPath
                              ? screenshot.screenshotThumbnailPath
                              : screenshot.screenshotPath
                          }
                          alt={caption}
                          className="img-fluid mr-2"
                        />
                      </a>
                    </Tippy>
                    {showCheckboxSelection && (
                      <div className="custom-control custom-checkbox bg-light px-2">
                        <label
                          className="form-check-label"
                          htmlFor={screenshot._id}
                        >
                          <input
                            className="form-check-input form-check-border"
                            id={screenshot._id}
                            name={screenshot._id}
                            type="checkbox"
                            onChange={handleScreenshotSelection}
                            checked={isSelected.includes(screenshot._id)}
                          />{' '}
                          <small>{capturedTime}</small>
                        </label>
                      </div>
                    )}
                  </>
                )}
                {(screenshot.screenshotPath === null ||
                  screenshotDisabled === false) && (
                  <>
                    {/* Discarded Screenshot */}
                    {screenshot.screenshotDiscard === 1 && (
                      <NoDataAvailable content={NO_DATA.SCREENSHOTS} />
                    )}
                    {/* Deleted Screenshot */}
                    {screenshot.screenshotPath === null && (
                      <NoDataAvailable
                        content={NO_DATA.DELETED_SCREENSHOT}
                        size={sliderSize}
                      />
                    )}
                  </>
                )}
              </SwiperSlide>
            )
          );
        })
      )}
      {/* Custom nav for Swiper */}
      {workSessionType === 'desktop' && (
        <div
          className={
            screenshots.length > 6
              ? 'swiper-custom-nav'
              : 'swiper-custom-nav d-none'
          }
        >
          <Button
            variant="secondary"
            size="sm"
            className="btn-prev"
            ref={prevRef}
          >
            <i className="mdi mdi-chevron-left font-24" />
          </Button>
          <Button
            variant="secondary"
            size="sm"
            className="btn-next"
            ref={nextRef}
          >
            <i className="mdi mdi-chevron-right font-24" />
          </Button>
        </div>
      )}
    </Swiper>
  );
};

ScreenshotSlider.propTypes = {
  screenshots: PropTypes.array,
  showCheckboxSelection: PropTypes.bool,
  isSelected: PropTypes.array,
  handleScreenshotSelection: PropTypes.func,
  workSessionType: PropTypes.string,
  screenshotDisabled: PropTypes.bool,
  memberName: PropTypes.string,
  sliderSize: PropTypes.string,
};

export default ScreenshotSlider;
