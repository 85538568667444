import { memo } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Avatar from '../Avatar';
import { PAGE_URLS } from '../../constants';

const RecentActivityCard = ({ cardTitle, recentActivity }) => (
  <Card>
    <Card.Header>
      <h4 className="m-0">{cardTitle}</h4>
      <Link
        className="small"
        to={{
          pathname: PAGE_URLS.ACTIVITY.TEAM,
          state: {
            bredCrumbs: [
              { name: 'Dashboard', path: PAGE_URLS.DASHBOARD },
              { name: 'Team Activity', path: '' },
            ],
          },
        }}
      >
        View all
      </Link>
    </Card.Header>
    <Card.Body>
      <div className="list-group list-group-flush list-group-activity">
        {recentActivity.length > 0
          ? recentActivity.map((ws, index) => (
              <div className="list-group-item" key={`recentActivity-${index}`}>
                <Row>
                  <Col className="col-auto">
                    <div className="avatar avatar-sm">
                      <Avatar
                        firstName={ws.user.firstName}
                        lastName={ws.user.lastName || ''}
                        imgPath={ws.user.avatar ? ws.user.avatar : ''}
                      />
                    </div>
                  </Col>
                  <Col className="ps-0">
                    <span className="name">
                      <small>
                        <strong>
                          {ws.user.lastName
                            ? `${ws.user.firstName} ${ws.user.lastName}`
                            : ws.user.firstName}{' '}
                        </strong>
                      </small>
                      {ws.activity === 'worksession' && (
                        <small>logged a worksession</small>
                      )}
                      {ws.activity === 'task' && (
                        <small>
                          completed task,
                          <b>{ws.task}</b>
                        </small>
                      )}
                      {'project' in ws && ws.project.title && (
                        <small>
                          {' in '}
                          <b>{ws.project.title}</b>
                        </small>
                      )}
                    </span>
                    <p className="small text-muted mb-0">
                      <small>
                        {ws.checkInDate !== null && (
                          <>
                            {ws.workSessionType === 'desktop' && (
                              <span
                                className="font-size-lg"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Desktop"
                              >
                                <i className="mdi mdi-desktop-mac" />
                              </span>
                            )}
                            {ws.workSessionType === 'extension' && (
                              <span
                                className="font-size-lg"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Extension"
                              >
                                <i className="mdi mdi mdi-puzzle" />
                              </span>
                            )}
                            {ws.workSessionType === 'mobile' && (
                              <span
                                className="font-size-lg"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Mobile"
                              >
                                <i className="mdi mdi-cellphone-iphone" />
                              </span>
                            )}
                            {ws.workSessionType === 'web' && (
                              <span
                                className="font-size-lg"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Web"
                              >
                                <i className="mdi mdi-web" />
                              </span>
                            )}
                          </>
                        )}
                        <time className="small text-muted">
                          {' '}
                          {moment(ws.date).fromNow()}
                        </time>
                      </small>
                    </p>
                  </Col>
                </Row>
              </div>
            ))
          : 'No activity recorded for past two days'}
      </div>
    </Card.Body>
  </Card>
);

RecentActivityCard.propTypes = {
  cardTitle: PropTypes.string,
  recentActivity: PropTypes.array,
};

export default memo(RecentActivityCard);
