import { Form, Card, Table } from 'react-bootstrap';
import { Formik, Field, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { WithConfirmation } from '../common';
import DeleteIcon from '../DeleteIcon';
import '../../assets/styles/react-select.scss';

import { projectService } from '../../services';

const RemoveEmailSubscriber = ({ deleteMember }) => {
  const RemoveWithConfirmation = WithConfirmation(DeleteIcon);
  const RemoveModalProps = {
    title: `Are you sure you want to delete the recipient . Continue?`,
    action: deleteMember,
    actionBtnText: 'Yes',
  };
  return <RemoveWithConfirmation modalProps={RemoveModalProps} />;
};

RemoveEmailSubscriber.propTypes = {
  deleteMember: PropTypes.func,
};

const EmailSummariesForm = ({ settingsList, handleDelete, handleAddEdit }) => {
  const [projectList, setProjectList] = useState([]);
  useEffect(() => {
    projectService
      .get_projects_data({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        summaryList: true,
        filter: JSON.stringify({
          projectStatus: 'active',
        }),
      })
      .then((response) => {
        if (response.data) {
          const { projects } = response.data;
          setProjectList(projects);
        }
      })
      .catch(() => {});
  }, []);

  const options =
    projectList.length > 0
      ? projectList.map((project) => ({
          label: project.title,
          value: project._id,
        }))
      : [];
  options.splice(0, 0, { label: 'All Projects', value: null, isFixed: true });

  return (
    <Formik
      enableReinitialize
      initialValues={{ settingsList }}
      onSubmit={(values) => {
        handleAddEdit(values);
      }}
    >
      {(formikprops) => (
        <Form
          id="email-summaries"
          onSubmit={formikprops.handleSubmit}
          className="needs-validation"
        >
          <Card>
            <Card.Header>
              <h4 className="card-header-title">Reports</h4>
            </Card.Header>
            <div className="table-responsive" style={{ overflowY: 'hidden' }}>
              <Table hover size="sm" className="card-table">
                <thead className="thead-light">
                  <tr>
                    <th>Team member</th>
                    <th style={{ width: '250px' }}>Projects</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Daily summary</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Weekly report</th>
                    <th style={{ whiteSpace: 'nowrap' }}>BiMonthly report</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray
                    name="settingsList"
                    render={() =>
                      formikprops.values.settingsList.map((elem, index) => {
                        const selectedOptions = options.filter((item) => {
                          if (elem.projectIds !== null) {
                            return elem.projectIds.includes(item.value);
                          }
                          return false;
                        });
                        return (
                          <tr key={index}>
                            <td>
                              {elem.userDetails && (
                                <strong className="d-block">
                                  {elem.userDetails.lastName
                                    ? `${elem.userDetails.firstName} ${elem.userDetails.lastName}`
                                    : elem.userDetails.firstName}
                                </strong>
                              )}
                              <span className="text-muted">{elem.email}</span>
                            </td>
                            <td>
                              <Select
                                options={options}
                                isMulti
                                isClearable={false}
                                defaultValue={options[0]}
                                className="basic-multi-select"
                                name={`settingsList.${index}.projectIds`}
                                menuPosition="fixed"
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    minWidth: '250px',
                                  }),
                                  multiValueRemove: (base, state) =>
                                    state.data.isFixed
                                      ? {
                                          ...base,
                                          display: 'none',
                                        }
                                      : base,
                                }}
                                components={{
                                  DropdownIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                                value={
                                  selectedOptions.length > 0
                                    ? selectedOptions
                                    : options[0]
                                }
                                onChange={(selected) => {
                                  const lastEntry = selected.slice(-1);
                                  if (lastEntry[0].label === 'All Projects') {
                                    formikprops.setFieldValue(
                                      `settingsList.${index}.projectIds`,
                                      null
                                    );
                                  } else {
                                    formikprops.setFieldValue(
                                      `settingsList.${index}.projectIds`,
                                      selected
                                        .map(
                                          (item) =>
                                            item.value !== null && item.value
                                        )
                                        .filter((fItem) => fItem !== false)
                                    );
                                  }
                                }}
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary: '#0649b5',
                                    neutral10: '#edf2f9',
                                    neutral20: '#d2ddec',
                                    neutral30: '#d2ddec',
                                    neutral50: '#b1c2da',
                                    neutral80: '#12263f',
                                    dangerLight: '#DEEBFF',
                                    danger: '#12263f',
                                  },
                                })}
                              />
                            </td>
                            <td align="center">
                              <div className="custom-control custom-checkbox">
                                <Field
                                  className="form-check-input form-check-border"
                                  type="checkbox"
                                  name={`settingsList.${index}.daily`}
                                />
                                <Form.Label
                                  className="form-control-label"
                                  htmlFor={`settingsList.${index}.daily`}
                                />
                              </div>
                            </td>
                            <td align="center">
                              <div className="custom-control custom-checkbox">
                                <Field
                                  className="form-check-input form-check-border"
                                  type="checkbox"
                                  name={`settingsList.${index}.weekly`}
                                />
                                <Form.Label
                                  className="form-control-label"
                                  htmlFor={`settingsList.${index}.weekly`}
                                />
                              </div>
                            </td>
                            <td align="center">
                              <div className="custom-control custom-checkbox">
                                <Field
                                  className="form-check-input form-check-border"
                                  type="checkbox"
                                  name={`settingsList.${index}.bimonthly`}
                                />
                                <Form.Label
                                  className="form-control-label"
                                  htmlFor={`settingsList.${index}.bimonthly`}
                                />
                              </div>
                            </td>
                            <td align="center">
                              <RemoveEmailSubscriber
                                deleteMember={() => handleDelete(elem.id)}
                              />
                            </td>
                          </tr>
                        );
                      })
                    }
                  />
                </tbody>
              </Table>
            </div>
          </Card>
          <hr className="my-5" />
          <div className="d-flex justify-content-end align-items-center my-5">
            <button
              form="email-summaries"
              type="submit"
              className="btn btn-primary"
            >
              Save Changes
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

EmailSummariesForm.propTypes = {
  settingsList: PropTypes.array,
  handleDelete: PropTypes.func,
  handleAddEdit: PropTypes.func,
};

export default EmailSummariesForm;
