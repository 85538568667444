import { useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { MemberMultiSelectDropdown } from '../common';

const EditTeamGroup = ({
  selectedTeam,
  showEditTeam,
  handleEditTeamDisplay,
  handleUpdateTeam,
}) => {
  const selectedTeamMembers = [];
  const sortedMembersList = selectedTeam.teamMembersList.filter(
    (teamMember) =>
      teamMember.status !== 'inactive' &&
      !selectedTeam.currentMembers.some(
        (cMember) => cMember._id === teamMember.userId
      )
  );
  const selectInputRef = useRef(null);
  return (
    <Modal
      show={showEditTeam}
      onHide={() => {
        handleEditTeamDisplay(false);
      }}
      centered
    >
      <Modal.Header>
        <Modal.Title className="m-0">Edit Team Group</Modal.Title>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => {
            handleEditTeamDisplay(false);
          }}
        />
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{
          teamName: selectedTeam.teamName,
          teamId: selectedTeam.teamId,
          selectedMembers: selectedTeamMembers,
        }}
        validationSchema={Yup.object({
          teamName: Yup.string().required('Please enter a team name'),
        })}
        onSubmit={(values, { resetForm }) => {
          handleUpdateTeam(values);
          resetForm();
        }}
      >
        {(formikprops) => (
          <Form onSubmit={formikprops.handleSubmit}>
            <Modal.Body>
              <section>
                <Form.Group controlId="worksessionType">
                  <Form.Label>Team Name</Form.Label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder="Enter team name"
                    name="teamName"
                  />
                  <ErrorMessage
                    name="teamName"
                    render={(msg) => (
                      <small className="text-danger">{msg}</small>
                    )}
                  />
                </Form.Group>
                <MemberMultiSelectDropdown
                  id="selectInputRef"
                  name="selectedMembers"
                  labelText="Add New Member(s)"
                  fromArray={sortedMembersList}
                  formProps={formikprops}
                  isDisabled={sortedMembersList.length < 1 && true}
                  innerRef={selectInputRef}
                  requiredValueAs="userId"
                  showErrorMessage
                  isAllTeamMemberOption
                />
              </section>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button
                variant="link"
                onClick={() => {
                  handleEditTeamDisplay(false);
                }}
              >
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

EditTeamGroup.propTypes = {
  selectedTeam: PropTypes.object,
  showEditTeam: PropTypes.bool,
  handleEditTeamDisplay: PropTypes.func,
  handleUpdateTeam: PropTypes.func,
};

export default EditTeamGroup;
