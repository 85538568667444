import { memo } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';

const TaskChart = ({ cardTitle, taskData }) => {
  const data = taskData;
  const completedTask = data
    .filter((item) => item.status === 'complete')
    .map((item) => item.tasks);

  const upcomingTask = data
    .filter((item) => item.status === 'upcoming')
    .map((item) => item.tasks);
  const overDue = data
    .filter((item) => item.status === 'over due')
    .map((item) => item.tasks);
  const noDueDate = data
    .filter((item) => item.status === 'no due date')
    .map((item) => item.tasks);

  const taskChartData = {
    labels: ['Completed', 'Upcoming', 'Over Due', 'No Due Date'],
    datasets: [
      {
        data: [
          Number(completedTask),
          Number(upcomingTask),
          Number(overDue),
          Number(noDueDate),
        ],
        backgroundColor: ['#2C7BE5', '#ABDEE6', '#39afd1', '#edf2f9'],
        borderWidth: 0,
        offset: 5,
      },
    ],
  };

  return (
    <Card>
      <Card.Header>
        <h4 className="m-0">{cardTitle}</h4>
      </Card.Header>
      <Card.Body className="hd-tasks-chart">
        <Doughnut
          data={taskChartData}
          options={{
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  boxWidth: 10,
                  boxHeight: 7,
                  usePointStyle: true,
                  pointStyleWidth: 10,
                  font: {
                    size: 12,
                  },
                  color: '#95aac9',
                  hidden: false,
                  padding: 12,
                },
              },
              tooltip: {
                backgroundColor: 'rgba(255,255,255,0.95)',
                titleAlign: 'top',
                titleFont: {
                  size: 16,
                },
                titleColor: '#1C3047',
                titleMarginBottom: 10,
                bodyFont: {
                  size: 14,
                },
                bodyColor: '#94aac8',
                bodyAlign: 'center',
                bodySpacing: 10,
                borderColor: '#d5d9de',
                borderWidth: 1,
                caretPadding: 0,
                caretSize: 8,
                padding: 15,
                xAlign: 'center',
                yAlign: 'bottom',
                boxWidth: 0,
                boxHeight: 0,
                usePointStyle: true,
              },
            },
            animation: false,
            maintainAspectRatio: true,
            cutout: '83%',
            radius: '83%',
          }}
        />
      </Card.Body>
    </Card>
  );
};

TaskChart.propTypes = {
  cardTitle: PropTypes.string,
  taskData: PropTypes.array,
};

export default memo(TaskChart);
