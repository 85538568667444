import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ bredCrumbLinks = [] }) => {
  const isLast = (index) => index === bredCrumbLinks.length - 1;
  return (
    <ol className="breadcrumb p-0 ms-4">
      {bredCrumbLinks.map((crumb, ci) => (
        <li key={ci} className="breadcrumb-item">
          <Link
            to={crumb.path}
            className={
              isLast(ci) ? 'btn btn-link disabled p-0' : 'btn btn-link p-0'
            }
          >
            {crumb.name}
          </Link>
        </li>
      ))}
    </ol>
  );
};

Breadcrumb.propTypes = {
  bredCrumbLinks: PropTypes.array,
};

export default Breadcrumb;
