import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Alert, Button, Spinner } from 'react-bootstrap';
import { projectService } from '../../services';
import Loading from '../Loader';
import { ProjectKababMenu, ProjectMembers } from '.';
import { convertSecondsToHoursAndMinutes } from '../../helpers';
import {
  UPDATE_FAILURE_ERROR,
  PROJECT,
  NO_DATA,
  PER_PAGE_LIMIT,
} from '../../constants';
import {
  fetchProjectsList,
  archiveProject,
  fetchMemberProjects, // To update project list on dropdown
} from '../../actions/projects';
import asanaLogo from '../../assets/img/iconAsn.png';

const ActiveProject = ({ isListView }) => {
  const dispatch = useDispatch();
  const activeProjects = useSelector((state) => state.projectsList);
  const userDetails = useSelector((state) => state.userReducer);
  const projectsList = activeProjects.list || [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getProjects = (input) => {
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      page: activeProjects.page,
      limit: PER_PAGE_LIMIT,
      summaryList: false,
      status: 'active',
      memberLimit: 4,
      filter: JSON.stringify({
        projectTitle: input,
      }),
    };
    dispatch(fetchProjectsList(params));
  };

  const dataFetcher = React.useRef(getProjects);
  const observer = React.useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          dataFetcher.current();
        }
      },
      { threshold: 1 }
    )
  );
  const [element, setElement] = React.useState(null);
  // Update snapshot of getMembers function whenever page number changes.
  React.useEffect(() => {
    dataFetcher.current = getProjects;
  }, [getProjects]);

  // Observe for the load more spinner element
  React.useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  // Archive project
  const handleArchiveProject = (projectId) => {
    const params = {
      projectId,
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      status: 'close',
    };
    projectService
      .update_project_status(params)
      .then((response) => {
        if (response.status) {
          dispatch(archiveProject(projectId));
          dispatch(fetchMemberProjects());
          toast.success(PROJECT.ARCHIVE_PROJECT_SUCCESS);
        }
      })
      .catch(() => {
        throw UPDATE_FAILURE_ERROR;
      });
  };

  return (
    <>
      {projectsList.length > 0 ? (
        <section
          className={isListView ? 'project-list-view' : 'project-thumb-view'}
        >
          {projectsList.length > 0 &&
            projectsList.map((project, index) => {
              // Get the totalTasks count
              const totalTasks = project.tasks.reduce(
                (total, task) => total + task.tasks,
                0
              );
              // Get the inProgress task details
              const projectTasks = project.tasks.filter(
                (task) => task.status === 'complete'
              );
              // Get the inProgress task count
              const tasksCompleted =
                projectTasks.length >= 1 ? projectTasks[0].tasks : 0;

              // const openTasks = totalTasks - tasksCompleted;

              const taskProgressValue = (tasksCompleted / totalTasks) * 100;

              const progressWidth = {
                width: `${taskProgressValue}%`,
              };

              const { members, weekWorkSessionSeconds, totalMembers } = project;
              const totalHours =
                weekWorkSessionSeconds > 0 &&
                convertSecondsToHoursAndMinutes(weekWorkSessionSeconds);

              return (
                <div className="card with-link mt-0" key={index}>
                  <div className="card-body">
                    <Row className="project-title" noGutters>
                      <Col>
                        <h4 className="mb-2 name">
                          {project.integratedApp &&
                            project.integratedApp !== null && (
                              <img
                                src={asanaLogo}
                                alt="hd"
                                width="20"
                                height="20"
                              />
                            )}{' '}
                          <Link to={`/projects/${project._id}/task-board`}>
                            {project.title}
                          </Link>
                        </h4>
                      </Col>
                      {/* Dropdown */}
                      {userDetails && userDetails.role !== 'member' && (
                        <Col className="col-auto thumb-view-kababmenu pe-0">
                          <ProjectKababMenu
                            projectId={project._id}
                            handleArchive={handleArchiveProject}
                          />
                        </Col>
                      )}
                    </Row>

                    <div className="row no-gutters task-and-time-info ml-0 mr-0">
                      <div className="col">
                        {project.weekWorkSessionSeconds &&
                        weekWorkSessionSeconds > 0 ? (
                          <>
                            <h6 className="text-uppercase text-muted">
                              Total Hours
                            </h6>
                            <h2 className="mb-0">{totalHours}</h2>
                            <h6 className="text-uppercase text-muted mt-2">
                              (last 7 days)
                            </h6>
                          </>
                        ) : (
                          <span className="badge bg-secondary-soft">
                            No hours logged
                            <br />
                            (last 7 days)
                          </span>
                        )}
                      </div>
                      <div className="col border-left">
                        {totalTasks > 0 ? (
                          <>
                            <h6 className="text-uppercase text-muted">
                              Total tasks
                            </h6>

                            <h2 className="mb-0">{totalTasks}</h2>
                          </>
                        ) : (
                          <span className="badge bg-secondary-soft">
                            No open tasks
                          </span>
                        )}
                      </div>
                    </div>

                    {/* Footer */}
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="row align-items-center no-gutters">
                          {totalTasks > 0 ? (
                            <>
                              <div className="col-auto">
                                <div className="small mr-2">
                                  {tasksCompleted}/{totalTasks}
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div
                                  className="progress progress-sm"
                                  style={{ minWidth: '100px' }}
                                >
                                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={progressWidth}
                                    aria-valuenow={progressWidth}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="col-auto">
                              <span className="badge bg-secondary-soft">
                                Not available
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-auto ml-auto">
                        <ProjectMembers
                          members={members}
                          totalMembers={totalMembers}
                          projectId={project._id}
                        />
                      </div>
                    </div>
                    <div className="list-view-kababmenu">
                      <ProjectKababMenu
                        projectId={project._id}
                        handleArchive={handleArchiveProject}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </section>
      ) : (
        !activeProjects.isLoading && (
          <Col>
            <Alert variant="info">{NO_DATA.ACTIVE_PROJECTS}</Alert>
          </Col>
        )
      )}

      {/* Loading spinner overlay */}
      {activeProjects.isLoading && <Loading contentAreaOnly />}

      {/* Observing button to check if more data available to fetch */}
      {!activeProjects.isLoading && activeProjects.loadMore && (
        <div className="d-flex justify-content-center mb-5">
          <Button variant="primary" disabled ref={setElement}>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{' '}
            <span>Loading...</span>
          </Button>
        </div>
      )}
    </>
  );
};

ActiveProject.propTypes = {
  isListView: PropTypes.bool,
};

export default ActiveProject;
