import { Component } from 'react';
import { Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from './DashboardLayout';
import { InactiveTeamMember } from '../components/team';
import { resetInactiveMembersList } from '../actions/team';

class InactiveTeam extends Component {
  pageHeading = {
    preTitle: 'Team',
    title: `Inactive Team Member `,
  };

  constructor(props) {
    super(props);
    this.state = {
      isListView: false,
      pageHeading: this.pageHeading,
    };
  }

  componentDidUpdate(prevProps) {
    const { teamData } = this.props;
    if (
      teamData.inactiveMembersCount !== prevProps.teamData.inactiveMembersCount
    ) {
      this.updateBreadCrumbTitle(teamData.inactiveMembersCount);
    }
  }

  componentWillUnmount() {
    const { resetMembers } = this.props;
    resetMembers();
    // Clear interval on un mount
    clearInterval(this.statusTimer);
  }

  handleChangeView = () => {
    this.setState((state) => ({
      ...state,
      isListView: !state.isListView,
    }));
  };

  updateBreadCrumbTitle = (titlenew) => {
    this.setState((state) => ({
      ...state,
      pageHeading: {
        preTitle: 'Team',
        title: `Inactive Team Members (${titlenew}) `,
      },
    }));
  };

  render() {
    const { isListView, pageHeading } = this.state;
    return (
      <Layout pageHeading={pageHeading}>
        <Row>
          <InactiveTeamMember isListView={isListView} />
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({ teamData: state.team });

const mapDispatchToProps = () => ({
  resetMembers: resetInactiveMembersList,
});

InactiveTeam.propTypes = {
  teamData: PropTypes.object,
  resetMembers: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps())(InactiveTeam);
