import moment from 'moment';
import CryptoJS from 'crypto-js';
import {
  AUTH_TOKEN_NAME,
  AUTH_REFRESH_TOKEN_NAME,
  LOCAL_STORAGE_USER_DATA,
  AVATAR_COLOR_PALETTE,
  LOCAL_STORAGE_USER_STATE,
  CRYPTO_KEY,
  CRYPTO_IV,
  MASTER_LOGIN,
} from '../constants';
import { storage } from './storage';

/**
 * Get session storage variable value by its name
 * @param {string} name
 * @returns {string} value | null
 */
export const getSessionVariable = (name) => {
  if (typeof document === 'undefined') {
    return null;
  }
  if (name === AUTH_TOKEN_NAME && storage.authToken) return storage.authToken;
  if (name === AUTH_REFRESH_TOKEN_NAME && storage.authRefreshToken)
    return storage.authRefreshToken;
  if (name === MASTER_LOGIN && storage.checkmasterLogin)
    return storage.checkmasterLogin;
};

/**
 * Removes cookies related to login
 * UPDATE: remove tokens from sessionStorage
 */
export const clearSession = () => {
  storage.deleteTokens();
  storage.deleteUserData();
  // logout from all tabs: send signal
  localStorage.setItem('removeSessionStorage', Date.now().toString());
  localStorage.removeItem('removeSessionStorage');
};

/**
 * Convert json object into formData
 * @param {object} data json object to be converted formData
 * @returns {formData} formData
 */
export const makeFormData = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  return formData;
};

/**
 * Convert json object into Encoded formData
 * @param {object} data json object to be converted Encode formData
 * @returns {formData} formData
 */
export const makeEncodeFormData = (data) => {
  let formData = [];

  Object.keys(data).forEach((key) => {
    formData.push(
      `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
    );
  });
  formData = formData.join('&');

  return formData;
};

/**
 * Install cookie in browser
 * UPDATE: Save token in sessionStorage with storage class
 * @param {object} data response consisting of access_token, refresh_token
 */
export const installTokens = (data) => {
  // Store in sessionStorage.
  // Install tokens and its details in sessionStorage
  storage.authToken = data.access_token;
  storage.authRefreshToken = data.refresh_token;
  storage.authExpiry = data.expires_in;
  storage.tokenCreatedAt = data.createdAt;
  if (data.masterLogin) {
    storage.checkmasterLogin = data.masterLogin;
  } else {
    storage.checkmasterLogin = false;
  }
};

/**
 * Get session storage variable value by its name
 * @param {string} name
 * @returns {string} value | null
 */
export const getTeamId = (id) => localStorage.getItem(id);

/**
 * common function to fetch the API data from LocalStorage
 * @param {string} key
 * @return {object} api Data
 */
export const getUserData = (key) => {
  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  if (!userData || !userData[key]) {
    return false;
  }
  return userData[key];
};

/**
 * common function to fetch user details
 * @return {object} user details
 */
export const getHeaderData = () => {
  let userData = {};
  const userDetails = getUserData();
  if (userDetails) {
    const fullName = `${userDetails.firstName} ${userDetails.lastName}`;
    userData = {
      email: userDetails.email,
      name: fullName,
      avatar: userDetails.avatar,
      guidedStatus: userDetails.guidedStatus,
    };
  }
  return userData;
};

/**
 * common function to generate randomColor
 * @return {string} color code
 */
export const randomColor = () => {
  const color =
    AVATAR_COLOR_PALETTE[
      Math.floor(Math.random() * AVATAR_COLOR_PALETTE.length)
    ];
  return color;
};

/**
 * common function to convert seconds to elapsed time
 * @return {string} time - x hours and y minutes
 */
export const convertSecondsToHoursAndMinutes = (seconds) => {
  const hours = seconds / 3600;
  const minutes = (hours % 1) * 60;
  return `${Math.floor(hours)}h ${Math.round(minutes)}m`;
};

/**
 * common function to create groups form array
 * @return {array} of items
 */
// Accepts the array and key
export const groupBy = (array, key) =>
  // Return the end result
  array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object

/* common function to update local storage of user details */
export const updateLocalStorage = (data) => {
  const profile = {
    ...JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA)),
    ...data,
  };
  localStorage.setItem(LOCAL_STORAGE_USER_DATA, JSON.stringify(profile));
};

/* convert cents to dollar and format with comma & decimal */
export const formatAmount = (amount) =>
  (amount / 100)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    .toLocaleString();

/* Convert unix timestamp to readable date format */
export const converToDate = (timestring, dateFormat) =>
  moment.unix(timestring).format(dateFormat);

/* Get the plan name & type from the plan string */
export const getPlanAndType = (userPlan) => {
  if (!userPlan) {
    return ['', ''];
  }
  const planTerms = ['annual', 'monthly'];
  let planType = '';
  if (
    !userPlan.toLowerCase().includes('trial') &&
    (userPlan.toLowerCase().includes('monthly') ||
      userPlan.toLowerCase().includes('annual'))
  ) {
    planType = userPlan.substring(userPlan.lastIndexOf(' ') + 1);
  }

  /* Return plan and type separately in array */
  const planName = planTerms.reduce((acc, val) => {
    const regex = new RegExp(` ${val}`, 'ig');
    return acc.replace(regex, '');
  }, userPlan);
  return [planName.toLowerCase(), planType.toLowerCase()];
};

/* Function to save state to localStorage */
export const saveToLocalStorage = (data) => {
  const appState = JSON.stringify(data);
  localStorage.setItem(LOCAL_STORAGE_USER_STATE, appState);
};

/* Function to load state from localStorage */
export const loadFromLocalStorage = () => {
  const appState = localStorage.getItem(LOCAL_STORAGE_USER_STATE);
  if (appState === null) return undefined;
  return JSON.parse(appState);
};

/* Function to replace comma separated object with new line */
export function replaceCommaNewLine(data) {
  return data.join(', ').split(',').join('\n\n').replace(/\[|\]/g, '');
}

/* Common function to refresh page */
export const refreshPage = () => {
  // If set to true, the browser will do a complete page refresh from the server and not from the cache
  window.location.reload(false);
};

/* Common function to get duration time in milliseconds */
export const getDuration = (a, b) => {
  const startTime = new Date(a);
  const endTime = new Date(b);
  return endTime - startTime;
};

/* Common function to convert milliseconds to Hours Minutes Seconds */
export const msToHMS = (milliseconds, addTimeString = true, display) => {
  const totalSeconds = parseInt(Math.floor(milliseconds / 1000));
  const totalMinutes = parseInt(Math.floor(totalSeconds / 60));
  const totalHours = parseInt(Math.floor(totalMinutes / 60));
  const days = parseInt(Math.floor(totalHours / 24));
  const seconds = parseInt(totalSeconds % 60);
  const minutes = parseInt(totalMinutes % 60);
  const hours = parseInt(totalHours % 24);
  let duration;
  if (days > 0) {
    if (display === 'hrs&mins') {
      duration = `${days * 24 + hours}${addTimeString ? 'h ' : ':'}${minutes}${
        addTimeString ? 'm ' : ':'
      }`;
    } else {
      duration = `${days * 24 + hours}${addTimeString ? 'h ' : ':'}${minutes}${
        addTimeString ? 'm ' : ':'
      }${seconds}${addTimeString ? 's ' : ''}`;
    }
  } else if (hours > 0) {
    if (display === 'hrs&mins') {
      duration = `${hours}${addTimeString ? 'h ' : ':'} ${minutes}${
        addTimeString ? 'm ' : ':'
      }`;
    } else {
      duration = `${hours}${addTimeString ? 'h ' : ':'} ${minutes}${
        addTimeString ? 'm ' : ':'
      } ${seconds}${addTimeString ? 's ' : ''}`;
    }
  } else if (minutes > 0) {
    if (display === 'hrs&mins') {
      duration = `${minutes}${addTimeString ? 'm ' : ':'}`;
    } else {
      duration = `${minutes}${addTimeString ? 'm ' : ':'} ${seconds}${
        addTimeString ? 's ' : ''
      }`;
    }
  } else if (seconds > 0 && display !== 'hrs&mins') {
    duration = `${seconds}${addTimeString ? 's ' : ''}`;
  }
  return duration;
};

/* Common function to convert seconds to Hours Minutes Seconds */
export const sToHMSColonSeparated = (sec, display) => {
  const totalSeconds = parseInt(sec);
  const totalMinutes = parseInt(Math.floor(totalSeconds / 60));
  const totalHours = parseInt(Math.floor(totalMinutes / 60));
  const days = parseInt(Math.floor(totalHours / 24));
  let seconds = parseInt(totalSeconds % 60);
  let minutes = parseInt(totalMinutes % 60);
  let hours = parseInt(totalHours % 24);
  if (days > 0) {
    hours = days * 24 + hours;
  }

  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return display === 'hh:mm'
    ? `${hours}:${minutes}` // Return is HH : MM
    : `${hours}:${minutes}:${seconds}`; // Return is HH : MM : SS
};

/* function encrypt */
export function encrypt(data) {
  const cipher = CryptoJS.AES.encrypt(
    data.toString(),
    CryptoJS.enc.Utf8.parse(CRYPTO_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(CRYPTO_IV),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  );
  return cipher
    .toString()
    .replace(/\+/g, 'xMl3Jk')
    .replace(/\//g, 'Por21Ld')
    .replace(/=/g, 'Ml32');
}

/* Function decrypt */
export function decrypt(encryptedData) {
  const replacedData = encryptedData
    .toString()
    .replace(/xMl3Jk/g, '+')
    .replace(/Por21Ld/g, '/')
    .replace(/Ml32/g, '=');

  const cipher = CryptoJS.AES.decrypt(
    replacedData,
    CryptoJS.enc.Utf8.parse(CRYPTO_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(CRYPTO_IV),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  );

  return cipher.toString(CryptoJS.enc.Utf8);
}

export const copyTextToClipboard = (text) => {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(text);
  }
  return document.execCommand('copy', true, text);
};

/**
 * Listens to storage event and sets sessionStorage data so that sessionStorage is
 * available across tabs
 */
export const shareSessionAcrossTabs = () => {
  if (!sessionStorage.length) {
    // Ask other tabs for session storage
    localStorage.setItem('getSessionStorage', Date.now());
  }

  window.addEventListener('storage', (event) => {
    if (event.key === 'getSessionStorage') {
      // Some tab asked for the sessionStorage -> send it
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    } else if (event.key === 'removeSessionStorage') {
      storage.deleteTokens();
      setTimeout(() => {
        window.location.href = '/login';
      }, 3000);
    } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
      // sessionStorage is empty -> fill it
      const data = JSON.parse(event.newValue);
      if (data && data.access_token && data.refresh_token) {
        storage.authToken = data.access_token;
        storage.authRefreshToken = data.refresh_token;
        storage.tokenCreatedAt = data.createdAt;
        storage.authExpiry = data.expiresIn;
      }
    }
  });
};

export const tConvert = (timeInput) => {
  // Check correct time format and split into components
  let time = timeInput
    .toString()
    .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [timeInput];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
};

export const shiftTimeSlots = () => {
  let hours;
  let minutes;
  let ampm;
  let labelHours;
  const timeSlots = [];
  for (let i = 0; i <= 1425; i += 15) {
    hours = Math.floor(i / 60);
    minutes = i % 60;
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    ampm = hours % 24 < 12 ? 'AM' : 'PM';
    // hours %= 12;
    labelHours = hours % 12;

    // if (hours === 0) {
    //   hours = 12;
    // }
    if (labelHours === 0) {
      labelHours = 12;
    }
    const labelText = `${
      labelHours < 10 ? `0${labelHours}` : labelHours
    }:${minutes} ${ampm}`;
    timeSlots.push({
      value: `${hours < 10 ? `0${hours}` : hours}:${minutes}`,
      // label: `${hours}:${minutes} ${ampm}`,
      label: labelText,
    });
  }
  return timeSlots;
};

/* Function to calculate Days From To Dates */
export const calculateDayFromDates = (fromDate, toDate) => {
  if (fromDate && toDate) {
    const date1 = moment([
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate(),
    ]);
    const date2 = moment([
      toDate.getFullYear(),
      toDate.getMonth(),
      toDate.getDate(),
    ]);

    const diffDay = date2.diff(date1, 'days');
    const diffDays = diffDay + 1;

    if (diffDays <= 0) {
      return { value: 0, message: 'diffDaysless' };
    }
    return { value: diffDays, message: 'diffDays' };
  }
  return { value: 0, message: 'fromOrToDateIsNot' };
};

export const calculateInviteField = (maxFieldAllowed) => {
  const maxPushNewMember = [];
  if (maxFieldAllowed === 0) {
    return [];
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < maxFieldAllowed; i++) {
    maxPushNewMember.push({ email: '', role: 'member' });
    if (maxFieldAllowed >= 5 && i === 4) {
      return maxPushNewMember;
    }
    if (maxFieldAllowed < 5 && i === maxFieldAllowed - 1) {
      return maxPushNewMember;
    }
  }
};

export const calculateInviteFieldwithoutRole = (maxFieldAllowed) => {
  const maxPushNewMember = [];
  if (maxFieldAllowed === 0) {
    return [];
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < maxFieldAllowed; i++) {
    maxPushNewMember.push({ email: '' });

    if (i === maxFieldAllowed - 1) {
      return maxPushNewMember;
    }
  }
};

// Download Link
export const findDownloadLink = () => {
  let osName = '';
  let downLoadLink = '';
  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    operatingSystem = 'Windows OS';
  }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    operatingSystem = 'MacOS';
  }
  if (window.navigator.appVersion.indexOf('X11') !== -1) {
    operatingSystem = 'UNIX OS';
  }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) {
    operatingSystem = 'Linux OS';
  }
  if (operatingSystem.includes('Windows')) {
    osName = 'Windows';
  } else if (operatingSystem.includes('Mac')) {
    osName = 'Mac';
  } else if (operatingSystem.includes('Linux')) {
    osName = 'Linux';
  } else if (operatingSystem.includes('Ubuntu')) {
    osName = 'Linux';
  }

  if (osName === 'Windows') {
    downLoadLink =
      'https://hdclient-prd.s3.us-west-2.amazonaws.com/windows/HiveDesk+Setup+6.0.3.exe';
  } else if (osName === 'Mac') {
    downLoadLink =
      'https://hdclient-prd.s3.us-west-2.amazonaws.com/mac/HiveDesk+6.0.3.dmg';
  } else if (osName === 'Linux') {
    downLoadLink =
      'https://hdclient-prd.s3.us-west-2.amazonaws.com/linux/HiveDesk+6.0.3.AppImage';
  }
  return downLoadLink;
};

export const getStartAndEndDates = (selectedDate) => {
  const year = selectedDate.getFullYear();
  const month = selectedDate.getMonth();

  const startDate = new Date(year, month, 1); // First day of the month
  const endDate = new Date(year, month + 1, 0); // Last day of the month

  return { startDate, endDate };
};
