import { BASE_API_URL, TIMEOFF_TYPE_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

const options = AppService.options();
export const timeOffTypeService = {
  get_timeofftypes: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const URL = `${BASE_API_URL}${TIMEOFF_TYPE_URLS.TYPE}?${new URLSearchParams(
      data
    ).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  create_timeofftype: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMEOFF_TYPE_URLS.TYPE,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_timeoffLeaveRequestList: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMEOFF_TYPE_URLS.LEAVE_REQUEST_LIST,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  create_timeoffLeaveRequest: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMEOFF_TYPE_URLS.LEAVE_REQUEST,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  create_policy: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMEOFF_TYPE_URLS.POLICY,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_policy_list: async () => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + TIMEOFF_TYPE_URLS.POLICY}/${localStorage.getItem(
        'teamOwnerId'
      )}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  change_request_status: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMEOFF_TYPE_URLS.CHANGE_REQUEST_STATUS,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_timeofftype: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMEOFF_TYPE_URLS.UPDATE_TYPE,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  delete_timeofftype: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'DELETE',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + TIMEOFF_TYPE_URLS.TYPE}/${localStorage.getItem(
        'teamOwnerId'
      )} / ${data.typeId}`,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(),
      }
    );
    return response;
  },
  get_leave_balance: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const URL = `${BASE_API_URL}${
      TIMEOFF_TYPE_URLS.LEAVE_BALANCE
    }?${new URLSearchParams(data).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  update_policy: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + TIMEOFF_TYPE_URLS.UPDATE_POLICY,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
};
