import { memo } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { ProjectListDropdown, DateRangePickerField } from '../common';
import { NO_DATA, DATE_RANGE_OPTIONS, DATE_AND_TIME } from '../../constants';
import { WorksessionChart } from '.';

const PerformancesessionChart = ({
  projectsList,
  handleDateChange,
  handleProjectGenerateData,
  projectsessionData,
  isProjectGenerated,
}) => {
  /**
   * handleMemberChartData Handle Generate Data
   * @param  Array
   * @return void
   */
  const handleGenerateData = (values) => {
    handleProjectGenerateData({
      ...values,
      fromDate: values.fromDate.concat(' 00:00:00'),
      toDate: values.toDate.concat(' 23:59:59'),
      projectId: values.project,
    });
  };
  return (
    <>
      <Card>
        <Card.Header
          href="#projectChart"
          type="button"
          data-bs-toggle="collapse"
          aria-expanded="true"
          className="accordion-button border-bottom px-4"
        >
          Performance chart
        </Card.Header>
        <Card.Body id="projectChart" className="show collapse">
          <Formik
            initialValues={{
              teamOwnerId: localStorage.getItem('teamOwnerId'),
              project: '',
              fromDate: moment()
                .subtract(1, 'days')
                .format(DATE_AND_TIME.DATE_RANGE_IN_STATE),
              toDate: moment().format(DATE_AND_TIME.DATE_RANGE_IN_STATE),
            }}
            validationSchema={Yup.object({
              project: Yup.string().required('Please select any project'),
            })}
            onSubmit={(values) => {
              handleGenerateData(values);
            }}
          >
            {(formikprops) => (
              <Form onSubmit={formikprops.handleSubmit}>
                <Row>
                  <Col md={6} lg={4}>
                    <ProjectListDropdown
                      handleProjectChange={(e) => {
                        formikprops.setFieldValue('project', e.target.value);
                      }}
                      handleChange={formikprops.handleChange}
                      projectsList={projectsList}
                      isAllOptionRequired={false}
                      isValidate
                      isRequired
                      label="Project"
                      defaultPlaceholder="Select a project"
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <DateRangePickerField
                      showRangeOptions
                      startFrom="yesterday"
                      preDefinedRanges={DATE_RANGE_OPTIONS}
                      handleDateSelect={(date, picker) => {
                        formikprops.setFieldValue(
                          'fromDate',
                          handleDateChange(date, picker, true)
                        );
                        formikprops.setFieldValue(
                          'toDate',
                          handleDateChange(date, picker, false)
                        );
                      }}
                    />
                  </Col>
                  <Col md={6} lg={4}>
                    <Form.Group controlId="TeamMembers">
                      <Form.Label className="w-100">&nbsp;</Form.Label>
                      <Button variant="primary" type="Submit">
                        Generate
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
          {projectsessionData.length > 0 && (
            <WorksessionChart
              worksessionData={projectsessionData}
              graphType="bar"
            />
          )}
          {isProjectGenerated && projectsessionData.length === 0 && (
            <Alert variant="info" className="text-center mx-4">
              {NO_DATA.PERFORMANCE}
            </Alert>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

PerformancesessionChart.propTypes = {
  projectsList: PropTypes.array,
  handleDateChange: PropTypes.func,
  handleProjectGenerateData: PropTypes.func,
  projectsessionData: PropTypes.array,
  isProjectGenerated: PropTypes.bool,
};

export default memo(PerformancesessionChart);
