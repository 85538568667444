import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Logout = ({ handleShow }) => (
  <Dropdown.Item onClick={handleShow}>
    <i className="mdi mdi-logout" /> <span>Logout</span>
  </Dropdown.Item>
);

Logout.propTypes = {
  handleShow: PropTypes.func,
};

export default Logout;
