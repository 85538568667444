import { Form, Button, Row, Col } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { ToggleSwitch } from '../common';

const EditProjectForm = ({ formProps, isSubmitting, clientsData }) => {
  // Toggle switch
  const handleToggleSwitch = (id) => {
    formProps.setFieldValue(id, !formProps.values[id]);
    if (id === 'displayScreenshotsOnClient') {
      formProps.setFieldValue('canDeleteScreenshot', false);
      formProps.setFieldValue('allowScreenshotDiscard', false);
    }
  };
  // Reset other screenshot options if user choose to disable screenshots
  const resetScreenshotOptions = (event) => {
    if (event.target.value === '0') {
      formProps.setFieldValue('displayScreenshotsOnClient', false);
      formProps.setFieldValue('canDeleteScreenshot', false);
      formProps.setFieldValue('allowScreenshotDiscard', false);
      formProps.setFieldValue('allowBlurring', false);
      formProps.setFieldValue('allowForceScreenshot', false);
    }
  };
  return (
    <section>
      {clientsData.length > 0 && (
        <Form.Group controlId="client">
          <Form.Label>Client</Form.Label>

          <Field
            className="form-select"
            as="select"
            name="client"
            onChange={(e) => {
              formProps.handleChange(e);
            }}
          >
            <option value="">Select client</option>
            {clientsData.map((item) => (
              <option value={item._id} key={item._id}>
                {item.name}
              </option>
            ))}
          </Field>
          <ErrorMessage
            name="client"
            render={(msg) => <small className="text-danger">{msg}</small>}
          />
        </Form.Group>
      )}
      <Form.Group controlId="projectName">
        <Form.Label>
          Project name <span className="text-danger">*</span>
        </Form.Label>
        <Field
          className="form-control"
          type="text"
          placeholder="Enter project name"
          name="title"
        />
        <ErrorMessage
          name="title"
          render={(msg) => <small className="text-danger">{msg}</small>}
        />
      </Form.Group>
      <Form.Group controlId="projectDescription">
        <Form.Label>Project description</Form.Label>
        <div className="text-muted mb-1">
          This is how your team members will learn about the project
        </div>
        <Field
          as="textarea"
          maxLength="300"
          name="description"
          rows="2"
          className="form-control"
        />
        <ErrorMessage
          name="description"
          render={(msg) => <small className="text-danger">{msg}</small>}
        />
      </Form.Group>
      <Form.Group controlId="projectNotes">
        <Form.Label>Project notes</Form.Label>
        <div className="text-muted mb-1">
          This is the place for reminders or specifications for your project so
          team members can see them while working
        </div>
        <Field as="textarea" name="note" rows="3" className="form-control" />
        <ErrorMessage
          name="note"
          render={(msg) => <small className="text-danger">{msg}</small>}
        />
      </Form.Group>

      <Row className="mb-4">
        <Col md="5">
          <Form.Group controlId="ScreenshotInterval">
            <Form.Label>Screenshot interval</Form.Label>
            <div className="text-muted mb-1">
              How often do you want to take a screenshot of your employees’
              computer?
            </div>
            <Field
              className="form-select"
              as="select"
              name="screenshotIntervalSeconds"
              onChange={(e) => {
                formProps.handleChange(e);
                resetScreenshotOptions(e);
              }}
            >
              <option value="420">Every 4-10 Minutes</option>
              <option value="600">Every 7-13 Minutes</option>
              <option value="900">Every 12-18 Minutes</option>
              <option value="1800">Every 27-33 Minutes</option>
              <option value="2700">Every 42-48 Minutes</option>
              <option value="3600">Every 57-63 Minutes</option>
              <option value="0">Disable Screenshots</option>
            </Field>
          </Form.Group>
        </Col>
        {/* eslint-disable-next-line eqeqeq */}
        {formProps.values.screenshotIntervalSeconds != 0 && (
          <Col md="5" className="ms-auto">
            <div>
              <ToggleSwitch
                id="displayScreenshotsOnClient"
                name="displayScreenshotsOnClient"
                checked={formProps.values.displayScreenshotsOnClient}
                handleChange={handleToggleSwitch}
                labelText="Hide screenshots"
                optionalText="Team members will not be able to see their screenshots in the desktop app or in the web portal"
              />
            </div>
          </Col>
        )}
      </Row>
      {/* eslint-disable-next-line eqeqeq */}
      {formProps.values.screenshotIntervalSeconds != 0 && (
        <>
          {!formProps.values.displayScreenshotsOnClient && (
            <Row className="mb-4">
              <Col md="5">
                <ToggleSwitch
                  id="canDeleteScreenshot"
                  name="canDeleteScreenshot"
                  checked={formProps.values.canDeleteScreenshot}
                  handleChange={handleToggleSwitch}
                  labelText="Delete screenshots"
                  optionalText="Team members will be able to delete screenshots from inside the web portal"
                />
              </Col>
              <Col md="5" className="ms-auto">
                <div>
                  <ToggleSwitch
                    id="allowScreenshotDiscard"
                    name="allowScreenshotDiscard"
                    checked={formProps.values.allowScreenshotDiscard}
                    handleChange={handleToggleSwitch}
                    labelText="Discard screenshots"
                    optionalText="Team members will be able to discard screenshots from desktop app"
                  />
                </div>
              </Col>
            </Row>
          )}
          <Row className="mb-4">
            <Col md="5">
              <ToggleSwitch
                id="allowBlurring"
                name="allowBlurring"
                checked={formProps.values.allowBlurring}
                handleChange={handleToggleSwitch}
                labelText="Blur screenshots"
                optionalText="Automatically blurs screenshots to hide sensitive information"
              />
            </Col>
            <Col md="5" className="ms-auto">
              <div>
                <ToggleSwitch
                  id="allowForceScreenshot"
                  name="allowForceScreenshot"
                  checked={formProps.values.allowForceScreenshot}
                  handleChange={handleToggleSwitch}
                  labelText="Instant screenshots"
                  optionalText="Team members will be able to take a screenshot instantly rather than waiting for the next random screenshot"
                />
              </div>
            </Col>
          </Row>
        </>
      )}
      <Row className="mb-4">
        <Col md="5">
          <div>
            <ToggleSwitch
              id="allowTaskCreation"
              name="allowTaskCreation"
              checked={formProps.values.allowTaskCreation}
              handleChange={handleToggleSwitch}
              labelText="Create tasks"
              optionalText="Team members will be able to create their own tasks"
            />
          </div>
        </Col>
        {/*   <Col md="5">
          <ToggleSwitch
            id="displayContactDetails"
            name="displayContactDetails"
            checked={formProps.values.displayContactDetails}
            handleChange={handleToggleSwitch}
            labelText="Hide contact information"
            optionalText="Can team members see each other’s contact information?"
          />
        </Col> */}
      </Row>

      <Row className="justify-content-between my-5">
        <Col>
          <Button
            className="btn-white"
            size="lg"
            type="reset"
            onClick={formProps.handleReset}
          >
            Cancel
          </Button>
        </Col>
        <Col className="col-auto">
          <Button
            variant="primary"
            size="lg"
            type="submit"
            disabled={!(formProps.isValid && formProps.dirty) || isSubmitting}
          >
            Save Changes
          </Button>
        </Col>
      </Row>
      <hr className="my-5" />
    </section>
  );
};

EditProjectForm.propTypes = {
  formProps: PropTypes.any,
  isSubmitting: PropTypes.bool,
  clientsData: PropTypes.array,
};

export default EditProjectForm;
