import { Component } from 'react';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';

import Layout from '../DashboardLayout';
import PasswordForm from '../../components/settings/PasswordForm';
import Loader from '../../components/Loader';
import SettingsTabs from '../../components/settings/Tabs';

import { settingsService } from '../../services';
import { CHANGE_PW_SUCCESS } from '../../constants';

class ChangePassword extends Component {
  pageHeading = {
    preTitle: 'Settings',
    title: 'Password',
  };

  state = {
    isSubmitting: false,
  };

  handleUpdatePassword = (formdata) => {
    this.setState((state) => ({
      ...state,
      isSubmitting: true,
    }));

    settingsService
      .change_password({ ...formdata })
      .then((response) => {
        if (response) {
          toast.success(CHANGE_PW_SUCCESS);
        }
        this.setState((state) => ({
          ...state,
          isSubmitting: false,
        }));
      })
      .catch((errResponse) => {
        this.setState((state) => ({
          ...state,
          isSubmitting: false,
        }));
        if (errResponse.message === 'Validation error') {
          toast.error(errResponse.data);
        } else {
          toast.error(errResponse.message);
        }
      });
  };

  render() {
    const { isSubmitting } = this.state;
    return (
      <Layout pageHeading={this.pageHeading}>
        {isSubmitting && <Loader contentAreaOnly />}
        <Row className="justify-content-center">
          <Col className="col-12 col-lg-10 col-xl-8">
            <SettingsTabs selectedTab={this.pageHeading.title} />
            <div className="tab-content">
              <div className="tab-pane show active">
                <Row className="justify-content-between align-items-center mb-1">
                  <Col md={9} xl={7}>
                    <h2>Change your password</h2>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <PasswordForm handleUpdate={this.handleUpdatePassword} />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}
export default ChangePassword;
