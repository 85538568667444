import React, { memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ProjectTabs, ProjectMembers, InviteToProjectModal } from '.';
import { getUserData } from '../../helpers';

const ProjectHeader = ({ selectedTab }) => {
  const project = useSelector((state) => state.project);
  const { role } = useSelector((state) => state.userReducer);
  const { title, members, _id, invites } = project;
  const userId = getUserData('_id');
  const currentUser =
    members && members.find((member) => member.userId === userId);
  const userPermissions = (currentUser && currentUser.permissions) || {};
  let showInviteButton = false;
  if (role !== 'member') {
    showInviteButton = true;
  } else if (
    currentUser &&
    currentUser.permissions &&
    currentUser.permissions.manageProjects
  ) {
    showInviteButton = true;
  }
  return (
    <>
      <Row className="align-items-center justify-content-between mb-4">
        <Col md="8">
          <h1 className="mb-0">{title}</h1>
        </Col>
        {role !== 'member' && (
          <Col md="auto" className="mt-3 mt-md-0 d-flex">
            <ProjectMembers
              members={members}
              totalMembers={(members && members.length) || 0}
              projectId={_id}
              avatarSize="lg"
            />
            {showInviteButton && (
              <InviteToProjectModal
                members={members}
                projectId={_id}
                invites={invites}
              />
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <ProjectTabs
            selectedTab={selectedTab}
            permissions={userPermissions}
          />
        </Col>
      </Row>
    </>
  );
};

ProjectHeader.propTypes = {
  selectedTab: PropTypes.string,
};

export default memo(ProjectHeader);
