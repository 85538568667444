import React from 'react';
import PropTypes from 'prop-types';

const ToggleSwitch = ({
  id,
  name,
  checked,
  handleChange,
  handleChangeforChecked,
  labelText,
  optionalText,
  disabled,
  formprops,
}) => (
  <div className="form-check form-switch">
    {labelText && (
      <label className="form-check-label" htmlFor={id}>
        {labelText}
      </label>
    )}
    {optionalText && <div className="text-muted my-2">{optionalText}</div>}
    {handleChange ? (
      <input
        className="form-check-input toggle-switch"
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={() => handleChange(id, formprops)}
        disabled={disabled}
      />
    ) : (
      <input
        className="form-check-input toggle-switch"
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={() => handleChangeforChecked(checked)}
        disabled={disabled}
      />
    )}
  </div>
);

ToggleSwitch.defaultProps = {
  disabled: false,
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
  handleChangeforChecked: PropTypes.func,
  name: PropTypes.string,
  labelText: PropTypes.string,
  optionalText: PropTypes.string,
  disabled: PropTypes.bool,
  formprops: PropTypes.object,
};

export default ToggleSwitch;
