import DataTable from 'react-data-table-component';
import {
  Row,
  Popover,
  OverlayTrigger,
  Button,
  ListGroup,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getDuration, convertSecondsToHoursAndMinutes } from '../../helpers';
import { ProjectEditWorkSessionModal } from './index';
import { WorkSessionEditNotesModal, DeleteWorkSession } from '../common';

const ProjectWorkSessionTable = ({
  worksessionList,
  project,
  userData,
  projectsList,
  EditSessionAccess,
  handleUpdateWsData,
  allBreaksList,
  handleGetAllBreakTypes,
}) => {
  const columns = [
    {
      name: 'Team Member',
      selector: (row) => row.teamMember,
      sortable: true,
    },
    {
      name: 'Start',
      selector: (row) => row.start,
      sortable: true,
    },
    {
      name: 'End',
      selector: (row) => row.end,
      sortable: true,
    },
    {
      name: 'Worksession Type',
      selector: (row) => row.worksessionType,
      sortable: true,
    },
    {
      name: 'Duration',
      selector: (row) => row.duration,
      sortable: true,
    },
    {
      name: 'Breaks',
      selector: (row) => row.breaks,
      sortable: true,
    },
    {
      name: '',
      id: 'action',
      selector: (row) => row.action,
      // button: true,
      minWidth: '300px',
    },
  ];

  // Find and remove 'Worksession Type' if mobile tracking is not enabled.
  if (!project.isMobileTracking) {
    const worksessionTypeIndex = columns.findIndex(
      (ws) => ws.name === 'Worksession Type'
    );
    columns.splice(worksessionTypeIndex, 1);
  }

  const worksessionTable = [];
  worksessionList.forEach((wsItem, index) => {
    const { user, checkInDate, checkOutDate, workSessionType } = wsItem;
    const wsDuration = getDuration(checkInDate, checkOutDate);
    const status = user.status === 'inactive' ? ' (Inactive)' : '';
    const worksessionRow = {
      id: `ws-${index}`,
      teamMember: `${user.firstName} ${user.lastName || ''}${status}`,
      start: moment(checkInDate).format('lll'),
      end:
        (checkOutDate &&
          checkOutDate !== null &&
          moment(checkOutDate).format('lll')) ||
        '-',
      worksessionType: (
        <span className="text-capitalize">{workSessionType}</span>
      ),
      duration:
        checkOutDate && checkOutDate !== null
          ? convertSecondsToHoursAndMinutes(wsDuration / 1000)
          : '-',
      breaks: (
        <div>
          {wsItem.break && wsItem.break.length > 0
            ? wsItem.break.slice(0, 2).map((wsBreak, bIndex) => (
                <p className="mb-2" key={`wsBreak${bIndex}`}>
                  {wsBreak.type !== null ? wsBreak.type.name : 'Other'}
                  <br />
                  {`${moment(wsBreak.startTime).format('h:mm A')} to ${
                    wsBreak.endTime
                      ? moment(wsBreak.endTime).format('h:mm A')
                      : ''
                  }`}
                </p>
              ))
            : ''}
          {wsItem.break && wsItem.break.length > 2 && (
            <OverlayTrigger
              trigger="focus"
              placement="right"
              overlay={
                <Popover className="py-0" style={{ minWidth: '180px' }}>
                  <Popover.Content>
                    <ListGroup variant="flush">
                      {wsItem.break.map((bItem, bItemIndex) => (
                        <ListGroup.Item
                          style={{ padding: '8px 0' }}
                          key={bItemIndex}
                        >
                          {bItem.type !== null ? bItem.type.name : 'Other'}
                          <br />
                          {`${moment(bItem.startTime).format('h:mm A')} to ${
                            bItem.endTime
                              ? moment(bItem.endTime).format('h:mm A')
                              : ''
                          }`}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Popover.Content>
                </Popover>
              }
            >
              <Button variant="link" size="sm" className="m-0 px-0">
                View all breaks
              </Button>
            </OverlayTrigger>
          )}
        </div>
      ),
      action: (
        <div>
          <ProjectEditWorkSessionModal
            wsItem={wsItem}
            projectsList={projectsList}
            handleUpdateWsData={handleUpdateWsData}
            editSessionAccess={EditSessionAccess}
            allBreaksList={allBreaksList}
            handleGetAllBreakTypes={handleGetAllBreakTypes}
          />

          {EditSessionAccess && (
            <WorkSessionEditNotesModal
              wsItem={wsItem}
              projectId={project._id}
            />
          )}
          {userData.role === 'creator' && (
            <DeleteWorkSession workSessionId={wsItem._id} />
          )}
        </div>
      ),
    };
    // Remove 'Worksession Type' if mobile tracking is not enabled.
    if (!project.isMobileTracking) {
      delete worksessionRow.worksessionType;
    }
    if (checkOutDate && checkOutDate !== null) {
      worksessionTable.push({ ...worksessionRow });
    }
  });

  const paginationComponentOptions = {
    rowsPerPageText: 'Show',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'All',
  };

  return (
    <Row>
      <DataTable
        columns={columns}
        data={worksessionTable}
        pagination
        paginationPerPage={5}
        paginationRowsPerPageOptions={[5, 10, 20]}
        paginationComponentOptions={paginationComponentOptions}
        responsive
        persistTableHead
      />
    </Row>
  );
};

ProjectWorkSessionTable.propTypes = {
  worksessionList: PropTypes.array,
  project: PropTypes.object,
  userData: PropTypes.object,
  projectsList: PropTypes.array,
  EditSessionAccess: PropTypes.bool,
  handleUpdateWsData: PropTypes.func,
  allBreaksList: PropTypes.array,
  handleGetAllBreakTypes: PropTypes.func,
};

export default ProjectWorkSessionTable;
