export const signIn = () => ({
  type: 'SIGN_IN',
});
export const startTour = () => ({
  type: 'START_TOUR',
});
export const endTour = () => ({
  type: 'END_TOUR',
});
export const enableTimesheetApproval = () => ({
  type: 'ENABLE_TS_APPROVAL',
});
export const disableTimesheetApproval = () => ({
  type: 'DISABLE_TS_APPROVAL',
});
export const enableOvertime = () => ({
  type: 'ENABLE_OT',
});
export const disableOvertime = () => ({
  type: 'DISABLE_OT',
});
export const enableSchedule = () => ({
  type: 'ENABLE_SCHEDULE',
});
export const disableSchedule = () => ({
  type: 'DISABLE_SCHEDULE',
});
export const enableInvoice = () => ({
  type: 'ENABLE_INVOICE',
});
export const disableInvoice = () => ({
  type: 'DISABLE_INVOICE',
});

export const enableTaskNotification = () => ({
  type: 'ENABLE_TASK_NOTIFICATION',
});
export const disableTaskNotification = () => ({
  type: 'DISABLE_TASK_NOTIFICATION',
});
export const enableManageActiveSessions = () => ({
  type: 'ENABLE_MANAGE_ACTIVE_SESSIONS',
});
export const disableManageActiveSessions = () => ({
  type: 'DISABLE_MANAGE_ACTIVE_SESSIONS',
});
