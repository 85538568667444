import { Component } from 'react';
import { toast } from 'react-toastify';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../DashboardLayout';
import Loader from '../../components/Loader';
import TeamList from '../../components/settings/TeamList';
import AddDailyLimit from '../../components/settings/AddDailyLimit';
import CurrentTeam from '../../components/settings/CurrentTeam';

// Add members without invite - commenting out for now
import SettingsTabs from '../../components/settings/Tabs';
import { setUserRole } from '../../actions/user';
import {
  enableTimesheetApproval,
  disableTimesheetApproval,
} from '../../actions';

import { teamService, settingsService } from '../../services';

import { LOCAL_STORAGE_USER_DATA, SETTINGS_TEAMS } from '../../constants';
import { updateLocalStorage, getUserData } from '../../helpers';

class TeamSettings extends Component {
  pageHeading = {
    preTitle: 'Settings',
    title: 'Teams',
  };

  userdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));

  state = {
    defaultTeam:
      this.userdata.defaultTeam != null
        ? `${this.userdata.defaultTeam.firstName} ${this.userdata.defaultTeam.lastName}`
        : '',
    defaultChecked: this.userdata.defaultTeam.isDefault,
    autoCheckOut: false,
    autoCheckOutMinutes: '',
    timeSheetApproval: getUserData('isTimesheetApproval'),
    isLoading: false,
    teamList: [],
    memberList: [],
    role:
      this.userdata.defaultTeam != null
        ? this.userdata.defaultTeam.role
        : 'creator',
    dailyLimit: null,
  };

  componentDidMount() {
    const { role } = this.state;
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));

    this.getMembers(localStorage.getItem('teamOwnerId'));
    this.getTeams();
    if (role !== 'creator') {
      this.getDailyLimit({
        teamOwnerId: this.userdata.defaultTeam.teamOwnerId,
        userId: this.userdata._id,
      });
    }
    if (role === 'creator') {
      this.getDailyLimit({
        teamOwnerId: this.userdata.defaultTeam.teamOwnerId,
      });
    }
  }

  /* Update Daily Limit */
  handleUpdateDailyLimit = (values) => {
    const data = {
      ...values,
      teamOwnerId: localStorage.getItem('teamOwnerId'),
    };
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    settingsService
      .update_daily_limit(data)
      .then((response) => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
        if (response.status) {
          toast.success(SETTINGS_TEAMS.UPDATE_DAILY_LIMIT);
        } else {
          toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
        }
      })
      .catch((error) => {
        toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  /* Update current team as default team */
  handleUpdateDefault = () => {
    const { defaultChecked } = this.state;

    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));

    teamService
      .update_default_team({
        teamOwnerId: !defaultChecked ? localStorage.getItem('teamOwnerId') : '',
      })
      .then((response) => {
        if (defaultChecked) {
          toast.success(SETTINGS_TEAMS.UPDATE_TEAM_UNSET);
        } else {
          toast.success(SETTINGS_TEAMS.UPDATE_TEAM_SET);
        }

        this.setState((state) => ({
          ...state,
          defaultChecked: !defaultChecked,
        }));

        /* update default team in local storage */
        const { defaultTeam } = this.userdata;
        defaultTeam.isDefault = !defaultChecked;
        updateLocalStorage({
          defaultTeam,
        });
      })
      .catch((error) => {
        toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
      })
      .finally(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  /* refresh components after team change */
  handleTeamSelection = (formData) => {
    localStorage.setItem('teamOwnerId', formData.teamOwners);
    toast.success(SETTINGS_TEAMS.TEAM_CHANGE);
    formData.teamDetails[0].isDefault = false;
    updateLocalStorage({ defaultTeam: formData.teamDetails[0] });

    const { dispatch } = this.props;
    dispatch(
      setUserRole({
        role: formData.teamDetails[0].role,
        ownerId: formData.teamOwners,
      })
    );

    this.setState((state) => ({
      ...state,
      defaultTeam: `${formData.teamDetails[0].firstName} ${
        formData.teamDetails[0].lastName || ''
      }`,
      defaultChecked: false,
      autoCheckOut: false,
      autoCheckOutMinutes: '',
      isLoading: false,
      memberList: [],
      role: formData.teamDetails[0].role,
    }));

    if (formData.teamDetails[0].role === 'creator') {
      this.getAutoCheckOutSettings();
    }
    delete formData.teamDetails;
    this.getMembers(formData.teamOwners);
  };

  // get Daily limit */
  getDailyLimit = (obj) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    settingsService
      .get_daily_limit(obj)
      .then((response) => {
        if (response.data && response.data.settings) {
          this.setState((state) => ({
            ...state,
            dailyLimit: response.data.settings.dailyLimit,
            isLoading: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            dailyLimit: null,
            isLoading: false,
          }));
        }
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          dailyLimit: null,
          isLoading: false,
        }));
      });
  };

  /* Data for listing teams in change team */
  getTeams = () => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));

    teamService
      .get_teams_data()
      .then((resp) => {
        if (resp.data.length > 1) {
          this.setState((state) => ({
            ...state,
            teamList: resp.data,
            isLoading: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            teamList: [],
            isLoading: false,
          }));
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  getMembers = (ownerId) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    teamService
      .get_team_members_data({
        teamOwnerId: ownerId,
        summaryList: true,
        filter: '{ "memberStatus": "active" }',
      })
      .then((response) => {
        this.setState((state) => ({
          ...state,
          memberList:
            response && 'data' in response ? response.data.members : [],
          isLoading: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          memberList: [],
          isLoading: false,
        }));
      });
  };

  render() {
    const {
      defaultTeam,
      defaultChecked,
      isLoading,
      teamList,
      memberList,
      role,
      dailyLimit,
    } = this.state;

    return (
      <Layout pageHeading={this.pageHeading}>
        {isLoading && <Loader contentAreaOnly />}
        <Row className="justify-content-center">
          <Col className="col-12 col-lg-10 col-xl-8">
            <SettingsTabs selectedTab={this.pageHeading.title} />
            <div className="tab-content">
              <div className="tab-pane show active">
                <Row>
                  {teamList.length > 1 && (
                    <CurrentTeam
                      defaultTeam={defaultTeam}
                      handleUpdateDefault={this.handleUpdateDefault}
                      propChecked={defaultChecked}
                      teamList={teamList}
                      handleTeamSelection={this.handleTeamSelection}
                    />
                  )}
                </Row>

                <div>
                  <AddDailyLimit
                    handleUpdateDailyLimit={this.handleUpdateDailyLimit}
                    dailyLimit={dailyLimit}
                    role={role}
                  />
                </div>

                {memberList.length > 0 ? (
                  <TeamList memberList={memberList} />
                ) : (
                  memberList !== '' && (
                    <div className="card">
                      <div className="card-body d-flex justify-content-center">
                        <div className="alert alert-info">No members </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}
const mapStateToProps = (state) => ({
  isTimesheetApproval: state.timesheetApproval,
});

const mapDispatchToProps = () => ({
  enableTsApproval: enableTimesheetApproval,
  disableTsApproval: disableTimesheetApproval,
});

TeamSettings.propTypes = {
  dispatch: PropTypes.any,
};
export default connect(mapStateToProps, mapDispatchToProps())(TeamSettings);
