import { memo } from 'react';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import MyAccountDropdown from './MyAccountDropdown';
import Tour from './Tour';
import { Breadcrumb } from './common';
import { CLIENT_DOWNLOAD_URL } from '../constants';
import NotificationList from './notifications/NotificationList';
import QuickAdd from './QuickAdd';

import { HeaderTimer } from './timer';
import { startTour } from '../actions';
import { trackJune } from '../utilities/analytics';
import QuickSearchModal from './common/QuickSearchModal';

const Header = ({ pageHeading, bredCrumbLinks }) => {
  const isStartTour = useSelector((state) => state.isStartTour);
  const userDetails = useSelector((state) => state.userReducer);
  const billingDetails = useSelector((state) => state.billingReducer);
  const sideNavbarCollapsedViewDetails = useSelector(
    (state) => state.collpasedSideNavbarToggle
  );
  const currentPlan = (
    (userDetails &&
      userDetails.subscription.currentPlan !== null &&
      userDetails.subscription.currentPlan) ||
    ''
  ).toLowerCase();
  const accountStatus =
    (billingDetails && billingDetails.accountStatus) || null;
  const isFreeTrial =
    (currentPlan === 'trial' && accountStatus !== 'cancel') || false;

  const dispatch = useDispatch();
  // Restart tour on start tour button click
  const restartTour = () => {
    dispatch(startTour());
  };

  return (
    <header
      className={
        isFreeTrial &&
        userDetails.role !== 'member' &&
        userDetails.freeTrialStatus
          ? `bg-primary topbar free-trial ${
              sideNavbarCollapsedViewDetails &&
              sideNavbarCollapsedViewDetails.sideNavbarCollapsedView
                ? 'collapsed-topbar'
                : ''
            }`
          : `bg-primary topbar ${
              sideNavbarCollapsedViewDetails &&
              sideNavbarCollapsedViewDetails.sideNavbarCollapsedView
                ? 'collapsed-topbar'
                : ''
            }`
      }
    >
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="header m-0">
              <div className="header-body border-0">
                <div className="row align-items-center">
                  {pageHeading && (
                    <div className="col">
                      <h6 className="header-pretitle">
                        {pageHeading.preTitle}
                      </h6>
                      <h2 className="header-title">
                        <span>{pageHeading.title} </span>
                        {bredCrumbLinks && (
                          <Breadcrumb bredCrumbLinks={bredCrumbLinks} />
                        )}
                      </h2>
                    </div>
                  )}
                  <div className="col-auto">
                    <HeaderTimer />
                  </div>

                  <div className="col-auto">
                    {userDetails && userDetails.role !== 'member' && (
                      <QuickAdd role={userDetails && userDetails.role} />
                    )}

                    {((userDetails && userDetails.role === 'creator') ||
                      (userDetails && userDetails.role === 'full_manager')) && (
                      <QuickSearchModal />
                    )}
                    <Link
                      className="btn text-white font-size-lg"
                      to={{ pathname: CLIENT_DOWNLOAD_URL }}
                      target="_blank"
                      role="button"
                    >
                      <Tippy content="Download HiveDesk client" theme="light">
                        <i className="mdi icon-20px mdi-download" />
                      </Tippy>
                    </Link>
                    <Link
                      className="btn text-white font-size-lg d-none d-md-inline-block"
                      to={{ hash: '#' }}
                      role="button"
                      id="guide_start"
                      onClick={() => {
                        restartTour();
                        // june
                        trackJune('start tour');
                      }}
                    >
                      <Tippy content="Start tour" theme="light">
                        {/* <i className="mdi mdi-television-guide" /> */}
                        <i className="mdi icon-20px mdi-presentation-play" />
                      </Tippy>
                    </Link>
                    <Link
                      className="btn text-white font-size-lg"
                      to="/settings/general"
                      role="button"
                    >
                      <Tippy content="Settings" theme="light">
                        <i className="mdi icon-20px mdi-settings-outline" />
                      </Tippy>
                    </Link>
                    <NotificationList userDetails={userDetails} />
                  </div>

                  <div className="col-auto dropdown d-none d-sm-block">
                    <MyAccountDropdown />
                  </div>
                  {isStartTour && <Tour start={isStartTour} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  pageHeading: PropTypes.object,
  bredCrumbLinks: PropTypes.array,
};

export default memo(Header);
