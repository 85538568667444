import PropTypes from 'prop-types';

const DeleteButton = ({ handleShow }) => (
  <button
    type="button"
    onClick={handleShow}
    size="sm"
    className="btn btn-sm btn-danger"
  >
    Delete
  </button>
);

DeleteButton.propTypes = {
  handleShow: PropTypes.func,
};

export default DeleteButton;
