import PropTypes from 'prop-types';
import { useState, memo } from 'react';
import { Card, Badge, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const UpcomingTasksCard = ({ taskData }) => {
  const [searchWord, setSearchWord] = useState('');
  const tableColumns = [
    {
      name: 'TASKS',
      selector: (row) => row.name,
      sortable: true,
      cell: (row) => row.task,
      filterable: true,
    },
    {
      name: 'ASSIGNED TO',
      selector: (row) => row.assignedTo,
      sortable: true,
    },
    {
      name: 'STATUS',
      selector: (row) => row.status,
      sortable: false,
    },
    {
      name: 'DUE DATE',
      sortable: false,
      cell: (row) => row.dueDate,
    },
  ];

  const data = [];

  const upComingTasks = taskData
    .filter((task) => task.status !== 'complete')
    .sort((a, b) => {
      if (a.dueDate < b.dueDate) {
        return 1;
      }
      if (a.dueDate > b.dueDate) {
        return -1;
      }
      return 0;
    });
  upComingTasks.forEach(function (task) {
    const status =
      task.assignedTo && task.assignedTo.status === 'inactive'
        ? ' (Inactive)'
        : '';
    const assignedTo =
      task.assignedTo &&
      `${task.assignedTo.firstName} ${task.assignedTo.lastName || ''}${status}`;
    const taskDueDate =
      task.dueDate && task.dueDate !== null ? (
        <span className="small text-muted mb-0">
          <i className="mdi mdi-clock-outline pr-1" />{' '}
          {moment.utc(task.dueDate).format('MMM DD')}
        </span>
      ) : (
        ''
      );
    data.push({
      id: task._id,
      task: task.task,
      assignedTo,
      status: (
        <Badge
          variant="light"
          className={task.status === 'over due' ? 'bg-info' : 'bg-light'}
        >
          <i className="mdi mdi-calendar-star" />{' '}
          <span className="ml-2 text-capitalize">{task.status}</span>
        </Badge>
      ),
      name: task.task,
      dueDate: taskDueDate,
      due_date: moment.utc(task.dueDate).format('MMM DD'),
      task_status: task.status,
    });
  });
  function search(rows) {
    return rows.filter(
      (row) =>
        row.name.toLowerCase().indexOf(searchWord.toLowerCase()) > -1 ||
        row.assignedTo.toLowerCase().indexOf(searchWord.toLowerCase()) > -1 ||
        row.due_date.toLowerCase().indexOf(searchWord.toLowerCase()) > -1 ||
        row.task_status.toLowerCase().indexOf(searchWord.toLowerCase()) > -1
    );
  }
  return (
    <Card>
      <Card.Header>
        <h4 className="m-0">Upcoming Tasks</h4>
      </Card.Header>
      <Card.Body className="p-0">
        <div
          className="col-sm-12 text-muted"
          style={{ padding: '15px 24px', maxWidth: '250px' }}
        >
          <div className="input-group">
            <input
              id="search"
              type="text"
              name="search"
              value={searchWord}
              className="form-control form-control-sm"
              onChange={(e) => setSearchWord(e.target.value)}
              placeholder="Search"
            />{' '}
            <Button variant="primary" size="sm">
              <span className="mdi mdi-magnify" />
            </Button>
          </div>
        </div>

        <div>
          <DataTable
            columns={tableColumns}
            data={search(data)}
            highlightOnHover
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 15, 25, 50]}
            paginationComponentOptions={{
              rowsPerPageText: 'Show Results',
              rangeSeparatorText: 'out of',
            }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

UpcomingTasksCard.propTypes = {
  taskData: PropTypes.array,
};

export default memo(UpcomingTasksCard);
