import React, { Component } from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Card,
  Popover,
  OverlayTrigger,
  ListGroup,
} from 'react-bootstrap';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from './DashboardLayout';
import Loading from '../components/Loader';
import {
  TeamMembersDropdown,
  DateRangePickerField,
  NoDataAlert,
} from '../components/common';
import { teamService, workSessionService, clientsService } from '../services';

import { getUserData, msToHMS } from '../helpers';
import {
  MEMBER_VALIDATION_LIMIT,
  DATE_RANGE_OPTIONS,
  NO_DATA,
  UPDATE_FAILURE_ERROR,
} from '../constants';
import { trackJune } from '../utilities/analytics';
import {
  WorkSessionApprovalToggle,
  WorkSessionEditOnApprovalModal,
} from '../components/timesheet/index';
import {
  fetchWsRequests,
  fetchDataStart,
  fetchDataCompleted,
} from '../actions/worksession';
import { fetchNotificationAll } from '../actions/notification';

class WorksessionEditRequests extends Component {
  state = {
    isLoading: false,
    isUpdatingTeamsList: false,
    teamMembersList: [],
    teamMember: 'all',
    status: 'pending',
    startDate: moment().startOf('week').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    daysLimit: 90,
    editOnApprovalDisplay: false,
    selectedRequest: {},
    allBreaksList: [],
  };

  pageHeading = {
    preTitle: 'Timesheet',
    title: 'Worksession Edit Requests',
  };

  componentDidMount() {
    const teamStrength = getUserData('maxMembersAllowed') || 0;
    this.getTeamsData();
    if (teamStrength <= MEMBER_VALIDATION_LIMIT) {
      this.getWorksessionChangeRequests();
    }
    // track june
    trackJune(null, `${this.pageHeading.preTitle} ${this.pageHeading.title}`);
  }

  handleDateSelect = (event, picker) => {
    this.setState((state) => ({
      ...state,
      startDate: moment(picker.startDate).format('YYYY-MM-DD'),
      endDate: moment(picker.endDate).format('YYYY-MM-DD'),
    }));
  };

  getWorksessionChangeRequests = () => {
    const { getWsChangeRequests } = this.props;
    const { startDate, endDate, teamMember, status } = this.state;
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      fromDate: startDate.concat(' 00:00:00'),
      toDate: endDate.concat(' 23:59:59'),
      includeBreakDetails: true,
    };
    if (teamMember && teamMember !== 'all') {
      params.userId = teamMember;
    }
    if (status && status !== 'all') {
      params.status = status;
    }
    getWsChangeRequests(params);
  };

  handleWorkSessionEditRequest = (wsData) => {
    const {
      fetchWsDataStart,
      fetchWsDataCompleted,
      reFetchNotifications,
      userData,
    } = this.props;
    fetchWsDataStart();
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      workSessionId: wsData.workSessionId,
      requestId: wsData.requestId,
      status: wsData.status,
    };
    if (wsData.updateRemark && wsData.updateRemark !== '') {
      params.updateRemark = wsData.updateRemark;
    }
    if (wsData.status === 'approve' && wsData.workSessionDetails) {
      params.workSessionDetails = { ...wsData.workSessionDetails };
    }
    workSessionService
      .process_edit_request(params)
      .then((response) => {
        if (response.status) {
          this.getWorksessionChangeRequests();
          // Refetch notifications
          reFetchNotifications(userData.role);
          this.handleEditOnApprovalModal(false);
          toast.success('Work session edit request updated successfully.');
        }
      })
      .catch((error) => {
        if (error.message === 'Validation error') {
          if (Array.isArray(error.data)) {
            error.data.map((item) => toast.error(item.msg));
          } else {
            toast.error(error.data);
          }
        } else if (error.message) {
          toast.error(error.message);
        } else {
          toast.error(UPDATE_FAILURE_ERROR);
        }
        fetchWsDataCompleted();
      });
  };

  getTeamsData = () => {
    this.setState((state) => ({
      ...state,
      isUpdatingTeamsList: true,
    }));
    teamService
      .get_team_members_data({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        summaryList: true,
      })
      .then((response) => {
        const { members } = response.data;
        this.setState((state) => ({
          ...state,
          teamMembersList: [...members],
          isUpdatingTeamsList: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isUpdatingTeamsList: false,
        }));
      });
  };

  handleEditOnApprovalModal = (displayState, pId) => {
    this.setState((state) => ({
      ...state,
      editOnApprovalDisplay: displayState,
    }));
    if (displayState === true && pId) {
      this.handleGetAllBreakTypes(pId);
    }
  };

  handleSelectedRequest = (request) => {
    this.setState((state) => ({
      ...state,
      selectedRequest: { ...request },
    }));
  };

  handleGetAllBreakTypes = (pId) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    clientsService
      .get_all_breaks({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        projectId: pId,
      })
      .then((response) => {
        if (response.data) {
          this.setState((state) => ({
            ...state,
            allBreaksList: [...response.data],
          }));
        }
      })
      .finally(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  render() {
    const {
      isLoading,
      teamMembersList,
      isUpdatingTeamsList,
      startDate,
      endDate,
      daysLimit,
      editOnApprovalDisplay,
      selectedRequest,
      allBreaksList,
    } = this.state;

    const { wsReqData } = this.props;

    const teamStrength = getUserData('maxMembersAllowed') || 0;
    // Validate form only if not a member and for accounts which has large size than specified limit
    const isValidateForm = teamStrength > MEMBER_VALIDATION_LIMIT;
    return (
      <Layout pageHeading={this.pageHeading}>
        <Formik
          initialValues={{
            teamMember: isValidateForm ? '' : 'all',
            fromDate: startDate,
            toDate: endDate,
            status: 'pending',
          }}
          onSubmit={(values) => {
            this.setState((state) => ({
              ...state,
              teamMember: values.teamMember ? values.teamMember : 'all',
              status: values.status ? values.status : 'all',
            }));
            this.getWorksessionChangeRequests(values);
          }}
        >
          {(formikprops) => (
            <Form onSubmit={formikprops.handleSubmit}>
              <Row>
                <Col md={4} lg={3}>
                  {isValidateForm ? (
                    <TeamMembersDropdown
                      teamMembersList={teamMembersList}
                      isUpdatingTeamsList={isUpdatingTeamsList}
                      isAllOptionRequired={false}
                      isValidate
                      isRequired
                      defaultPlaceholder="Select a Team Member"
                    />
                  ) : (
                    <TeamMembersDropdown
                      teamMembersList={teamMembersList}
                      isUpdatingTeamsList={isUpdatingTeamsList}
                      isAllOptionRequired
                    />
                  )}
                </Col>
                <Col md={4} lg={3}>
                  <Form.Group controlId="status">
                    <Form.Label>Status</Form.Label>
                    <Field className="form-select" as="select" name="status">
                      <option value="all">All</option>
                      <option value="pending" selected>
                        Pending
                      </option>
                      <option value="approve">Approved</option>
                      <option value="reject">Rejected</option>
                    </Field>
                  </Form.Group>
                </Col>
                <Col md={4} lg={3}>
                  <DateRangePickerField
                    handleDateSelect={this.handleDateSelect}
                    startFrom="this week"
                    showRangeOptions
                    preDefinedRanges={DATE_RANGE_OPTIONS}
                    handleDateRangeValidation={this.handleDateRangeValidation}
                    isValidate
                    // numberOfDaysSelected={numberOfDaysSelected}
                    daysLimit={daysLimit}
                  />
                </Col>
                <Col md={4} lg={3}>
                  <Form.Group controlId="generateData">
                    <Form.Label>&nbsp;</Form.Label>
                    <div>
                      {isValidateForm ? (
                        <Button
                          variant="primary"
                          type="Submit"
                          disabled={!(formikprops.isValid && formikprops.dirty)}
                        >
                          Generate
                        </Button>
                      ) : (
                        <Button variant="primary" type="Submit">
                          Generate
                        </Button>
                      )}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>

        {wsReqData &&
        wsReqData.data &&
        wsReqData.data.requests &&
        wsReqData.data.requests.length > 0 ? (
          <Row>
            <Col>
              <Card className="px-0">
                <div>
                  <table className="table table-sm table-hover card-table mt-1">
                    <thead>
                      <tr>
                        <th>Team Member</th>
                        <th>Project</th>
                        <th>Original Worksession</th>
                        <th>Updated Worksession</th>
                        <th>Old Breaks</th>
                        <th>Updated Breaks</th>
                        <th>Reason</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {wsReqData.data.requests.map((request, index) => (
                        <tr key={index}>
                          <td>
                            {`${request.user.firstName} ${
                              request.user.lastName || ''
                            }`}
                          </td>
                          <td>
                            {request.newProject && request.newProject !== null
                              ? request.newProject.title
                              : request.oldProject.title}
                          </td>
                          <td>
                            Duration:{' '}
                            {msToHMS(request.oldWorkSessionSeconds * 1000)}
                            <br />
                            Check-in:{' '}
                            {moment(request.oldCheckInDate).format('lll')}
                            <br />
                            Check-out:{' '}
                            {moment(request.oldCheckOutDate).format('lll')}
                          </td>
                          <td>
                            Duration:{' '}
                            {msToHMS(request.newWorkSessionSeconds * 1000)}
                            <br />
                            Check-in:{' '}
                            {moment(request.newCheckInDate).format('lll')}
                            <br />
                            Check-out:{' '}
                            {moment(request.newCheckOutDate).format('lll')}
                          </td>
                          <td>
                            {request.oldBreak &&
                              request.oldBreak.length > 0 &&
                              request.oldBreak
                                .slice(0, 2)
                                .map((obItem, obIndex) => (
                                  <p className="mb-2" key={`wsBreak${obIndex}`}>
                                    {obItem.type !== null
                                      ? obItem.type.name
                                      : 'Other'}
                                    <br />
                                    {`${moment(obItem.startTime).format(
                                      'h:mm A'
                                    )} to ${moment(obItem.endTime).format(
                                      'h:mm A'
                                    )}`}
                                  </p>
                                ))}
                            {request.oldBreak &&
                              request.oldBreak.length > 2 && (
                                <OverlayTrigger
                                  trigger="focus"
                                  placement="right"
                                  overlay={
                                    <Popover
                                      className="py-0"
                                      style={{ minWidth: '180px' }}
                                    >
                                      <Popover.Content>
                                        <ListGroup variant="flush">
                                          {request.oldBreak.map((bItem) => (
                                            <ListGroup.Item
                                              style={{ padding: '8px 0' }}
                                            >
                                              {bItem.type !== null
                                                ? bItem.type.name
                                                : 'Other'}
                                              <br />
                                              {`${moment(
                                                bItem.startTime
                                              ).format('h:mm A')} to ${moment(
                                                bItem.endTime
                                              ).format('h:mm A')}`}
                                            </ListGroup.Item>
                                          ))}
                                        </ListGroup>
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <Button
                                    variant="link"
                                    size="sm"
                                    className="m-0 px-0"
                                  >
                                    View all breaks
                                  </Button>
                                </OverlayTrigger>
                              )}
                          </td>
                          <td>
                            {request.newBreak &&
                              request.newBreak.length > 0 &&
                              request.newBreak
                                .slice(0, 2)
                                .map((nbItem, nbIndex) => (
                                  <p className="mb-2" key={`wsBreak${nbIndex}`}>
                                    {nbItem.type !== null
                                      ? nbItem.type.name
                                      : 'Other'}
                                    <br />
                                    {`${moment(nbItem.startTime).format(
                                      'h:mm A'
                                    )} to ${moment(nbItem.endTime).format(
                                      'h:mm A'
                                    )}`}
                                  </p>
                                ))}
                            {request.newBreak &&
                              request.newBreak.length > 2 && (
                                <OverlayTrigger
                                  trigger="focus"
                                  placement="right"
                                  overlay={
                                    <Popover
                                      className="py-0"
                                      style={{ minWidth: '180px' }}
                                    >
                                      <Popover.Content>
                                        <ListGroup variant="flush">
                                          {request.newBreak.map((bItem) => (
                                            <ListGroup.Item
                                              style={{ padding: '8px 0' }}
                                            >
                                              {bItem.type !== null
                                                ? bItem.type.name
                                                : 'Other'}
                                              <br />
                                              {`${moment(
                                                bItem.startTime
                                              ).format('h:mm A')} to ${moment(
                                                bItem.endTime
                                              ).format('h:mm A')}`}
                                            </ListGroup.Item>
                                          ))}
                                        </ListGroup>
                                      </Popover.Content>
                                    </Popover>
                                  }
                                >
                                  <Button
                                    variant="link"
                                    size="sm"
                                    className="m-0 px-0"
                                  >
                                    View all breaks
                                  </Button>
                                </OverlayTrigger>
                              )}
                          </td>
                          <td>
                            <p style={{ maxWidth: '200px' }}>
                              {request.reason}
                            </p>
                          </td>
                          <td>
                            <WorkSessionApprovalToggle
                              status={request.status}
                              wsDetails={request}
                              wsRequestStatusUpdate={
                                this.handleWorkSessionEditRequest
                              }
                              handleEditOnApprovalModal={
                                this.handleEditOnApprovalModal
                              }
                              handleSelectedRequest={this.handleSelectedRequest}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {editOnApprovalDisplay && (
                    <WorkSessionEditOnApprovalModal
                      wsItem={selectedRequest}
                      editOnApprovalDisplay={editOnApprovalDisplay}
                      handleEditOnApprovalModal={this.handleEditOnApprovalModal}
                      handleWorkSessionEditRequest={
                        this.handleWorkSessionEditRequest
                      }
                      allBreaksList={allBreaksList}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        ) : (
          !wsReqData.isLoading && (
            <NoDataAlert content={NO_DATA.WS_CHANGE_REQUESTS} displayBorder />
          )
        )}

        {/* Loader */}
        {(wsReqData.isLoading || isLoading) && <Loading contentAreaOnly />}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  wsReqData: state.wsReducer,
  userData: state.userReducer,
});

const mapDispatchToProps = () => ({
  getWsChangeRequests: fetchWsRequests,
  fetchWsDataStart: fetchDataStart,
  fetchWsDataCompleted: fetchDataCompleted,
  reFetchNotifications: fetchNotificationAll,
});

WorksessionEditRequests.propTypes = {
  wsReqData: PropTypes.object,
  userData: PropTypes.object,
  getWsChangeRequests: PropTypes.func,
  fetchWsDataStart: PropTypes.func,
  fetchWsDataCompleted: PropTypes.func,
  reFetchNotifications: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(WorksessionEditRequests);
