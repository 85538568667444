import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
// import { toast } from 'react-toastify';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';

const SelectTimeModal = ({
  wsItem,
  manageSelectTimeDisplay,
  handleSelectTimeModal,
  handleForceCheckout,
}) => {
  const handleClose = () => handleSelectTimeModal(false);
  return (
    <Modal show={manageSelectTimeDisplay} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title className="m-0">Select Log Out Time</Modal.Title>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={handleClose}
        />
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{
          checkOut: '',
        }}
        validationSchema={Yup.object({
          checkOut: Yup.date().required('Please enter check out time'),
        })}
        onSubmit={(values) => {
          handleForceCheckout({
            workSessionId: wsItem._id,
            type: 'time',
            time: values.checkOut,
          });
        }}
      >
        {(formikprops) => (
          <Form onSubmit={formikprops.handleSubmit}>
            <Modal.Body>
              <section>
                <Alert variant="light" className="mb-4">
                  <small>
                    <i className="mdi mdi-information-outline" /> Only past
                    times can be selected for log out. Future times are not
                    accepted.
                  </small>
                </Alert>
                <Form.Group controlId="memberName">
                  <Form.Label>Select Log Out Time</Form.Label>
                  <Field
                    component={Flatpickr}
                    name="checkOut"
                    placeholder="Log out time"
                    onChange={([date]) => {
                      formikprops.setFieldValue(
                        `checkOut`,
                        moment(date).format('YYYY-MM-DD HH:mm:ss')
                      );
                    }}
                    options={{
                      allowInvalidPreload: true,
                      dateFormat: 'M j, h:i K',
                      maxDate: 'today',
                      monthSelectorType: 'static',
                      minuteIncrement: 1,
                      static: true,
                      position: 'above left',
                      enableTime: true,
                    }}
                    className="form-control"
                  />
                  <ErrorMessage
                    name="checkOut"
                    render={(msg) => (
                      <small className="text-danger">{msg}</small>
                    )}
                  />
                </Form.Group>
              </section>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button variant="link" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

SelectTimeModal.propTypes = {
  wsItem: PropTypes.object,
  manageSelectTimeDisplay: PropTypes.bool,
  handleSelectTimeModal: PropTypes.func,
  handleForceCheckout: PropTypes.func,
};

export default SelectTimeModal;
