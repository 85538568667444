import { memo } from 'react';
import PropTypes from 'prop-types';
import { randomColor } from '../helpers';

const Avatar = ({ firstName, lastName, defaultBg, imgPath = '' }) =>
  imgPath === '' || !/^https:\/\//.test(imgPath) ? (
    <span
      className="avatar-title rounded-circle"
      style={{ backgroundColor: defaultBg || randomColor() }}
    >
      <span>
        {firstName &&
          firstName.charAt(0).toUpperCase() +
            (lastName
              ? lastName.charAt(0).toUpperCase()
              : firstName.charAt(1).toUpperCase())}
      </span>
    </span>
  ) : (
    <div
      className="avatar-img rounded-circle"
      style={{ backgroundImage: `url("${imgPath}?${Date.now()}")` }}
    />
  );

Avatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  defaultBg: PropTypes.string,
  imgPath: PropTypes.string,
};

export default memo(Avatar);
