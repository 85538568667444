import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getSessionVariable } from '../helpers';
import { AUTH_TOKEN_NAME } from '../constants';
import Loading from '../components/Loader';

// const loggedIn = false;
// eslint-disable-next-line react/prop-types
class PrivateRoute extends Component {
  timeout = null;

  state = { timeToRedirect: false };

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({
        timeToRedirect: true,
      });
    }, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { component: WrappedComponent, ...rest } = this.props;
    const { timeToRedirect } = this.state;
    if (!timeToRedirect && !getSessionVariable(AUTH_TOKEN_NAME)) {
      return <Loading />;
    }
    return (
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={(props) => {
          if (getSessionVariable(AUTH_TOKEN_NAME)) {
            return <WrappedComponent {...props} />;
          }
          return <Redirect to="/login" />;
        }}
      />
    );
  }
}
export default PrivateRoute;
