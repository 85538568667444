import { useSelector } from 'react-redux';

const StopWatch = () => {
  const timer = useSelector((state) => state.timerReducer);
  const { startTime, currentTime = new Date() } = timer;

  const getTimeDifference = () => {
    if (!startTime) return '0:00:00';

    const diffInSeconds = Math.floor(
      (currentTime - new Date(startTime)) / 1000
    );
    const hours = Math.floor((diffInSeconds || 0) / 3600);
    const minutes = Math.floor(((diffInSeconds || 0) % 3600) / 60);
    const seconds = (diffInSeconds || 0) % 60;

    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return <div className="stopwatch">{getTimeDifference()}</div>;
};

export default StopWatch;
