import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import '../assets/styles/modal.scss';

const DeleteModal = ({
  modalProps,
  showModal,
  handleDelete,
  handleHideModal,
}) => (
  <Modal
    show={showModal}
    animation={false}
    centered
    style={{ width: `${modalProps.widthStyle}` }}
    onHide={handleHideModal}
    dialogClassName="modal-50w"
  >
    <Modal.Header closeButton>
      <Modal.Title>
        <h4 className="card-header-title">{modalProps.title}</h4>
      </Modal.Title>
    </Modal.Header>
    {modalProps.body && (
      <Modal.Body>
        <p>{modalProps.content}</p>
      </Modal.Body>
    )}
    <Modal.Footer>
      <Button variant="link" onClick={handleHideModal}>
        No
      </Button>
      <Button variant="danger" className=" ml-auto" onClick={handleDelete}>
        Yes
      </Button>
    </Modal.Footer>
  </Modal>
);

DeleteModal.propTypes = {
  modalProps: PropTypes.object,
  showModal: PropTypes.bool,
  handleDelete: PropTypes.func,
  handleHideModal: PropTypes.func,
};
export default DeleteModal;
