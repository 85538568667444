import { timeOffTypeService, notificationService } from '../services';

export const fetchNotification = () => async (dispatch) => {
  dispatch({ type: 'FETCHING_NOTIFICATION_LIST' });
  try {
    const response = await timeOffTypeService.get_timeoffLeaveRequestList({
      teamOwnerId: localStorage.getItem('teamOwnerId'),
    });
    if (response.status && response.data && response.data.length) {
      const allData = response.data.filter((item) => {
        item.read = false;
        return item.status === 'submit';
      });
      dispatch({
        type: 'SET_NOTIFICATION_LIST',
        payload: allData,
      });
    } else {
      dispatch({
        type: 'SET_NOTIFICATION_LIST',
        payload: [],
      });
    }
  } catch {
    dispatch({ type: 'FETCH_NOTIFICATION_LIST_ERROR' });
  }
};

export const fetchNotificationAll = (usertype) => async (dispatch) => {
  try {
    const newusertype = usertype === 'creator' ? usertype : 'member';
    const response = await notificationService.get_notification_list({
      usertype: newusertype,
    });
    if (response.status && response.data) {
      dispatch({
        type: 'SET_NOTIFICATION_ALL_LIST',
        payload: response.data.length ? response.data : [],
      });
    }
  } catch {
    dispatch({ type: 'FETCH_NOTIFICATION_ALL_LIST_ERROR' });
  }
};

export const setNotificationAsRead = (userids) => async (dispatch) => {
  try {
    const response = await notificationService.update_notification_status(
      userids
    );
    if (response.status) {
      dispatch({
        type: 'SET_NOTIFICATION_AS_READ',
        payload: userids,
      });
    }
  } catch {
    dispatch({ type: 'FETCH_NOTIFICATION_ALL_LIST_ERROR' });
  }
};
