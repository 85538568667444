import moment from 'moment';
import { AnalyticsBrowser } from '@june-so/analytics-next';

import { LOCAL_STORAGE_USER_DATA, JUNE_WRITE_KEY } from '../constants';

// June start
export const trackJune = async (event = null, page = null) => {
  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  if (userData) {
    const accountPlan =
      'accountPlan' in userData ? userData.accountPlan : 'trial';
    const accountOwnerEmail =
      'accountOwnerEmail' in userData
        ? userData.accountOwnerEmail
        : userData.email;
    const role =
      'defaultTeam' in userData ? userData.defaultTeam.role : 'member';
    const analytics = AnalyticsBrowser.load({
      writeKey: JUNE_WRITE_KEY,
    });

    if (analytics) {
      analytics.identify(userData._id, {
        email: userData.email,
        name: `${userData.firstName ? userData.firstName : userData.email} ${
          userData.lastName ? userData.lastName : ''
        }`,
        plan: accountPlan,
        accountOwnerEmail,
        role,
      });
      if (event) {
        analytics.track(event);
      }
      if (page) {
        analytics.page(page);
      }
    }
  }
};

// Sending June Traits
export const trackJuneTraits = async (event = null) => {
  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  const key = Object.keys(event)[0];
  const value = Object.values(event)[0];
  let eventObj = {};
  eventObj = { ...eventObj, [key]: value };

  if (userData) {
    const analytics = AnalyticsBrowser.load({
      writeKey: JUNE_WRITE_KEY,
    });

    if (analytics) {
      analytics.identify(userData._id, eventObj);
    }
  }
};

/* PENDO start */
export const initPendo = (initData = null) => {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  let userData = user;
  if (initData) {
    userData = initData;
  }
  const param = {
    visitor: {
      id: userData._id,
      email: userData.email,
      full_name: `${userData.firstName} ${userData.lastName}`,
    },
    account: {
      id:
        'defaultTeam' in userData && userData.defaultTeam
          ? userData.defaultTeam.teamOwnerId
          : userData._id,
      email:
        'accountOwnerEmail' in userData
          ? userData.accountOwnerEmail
          : userData.email,
      plan:
        'subscriptionDetails' in userData
          ? userData.subscriptionDetails.currentPlan
          : '',
    },
  };
  if (param.account.plan === 'trial') {
    try {
      // eslint-disable-next-line no-undef
      const stat = pendo.get_account_id();
      if (stat === null) {
        // eslint-disable-next-line no-undef
        pendo.initialize(param);
      }
    } catch {
      // eslint-disable-next-line no-undef
      pendo.initialize(param);
    }
  }
  /* PENDO end */
};

/* PENDO start */
export const pendoTrackEvent = (page, property = {}) => {
  let user = null;
  try {
    user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  } catch {
    user = null;
  }
  const plan =
    user && 'subscriptionDetails' in user && user.subscriptionDetails
      ? user.subscriptionDetails.currentPlan
      : property.subscriptionDetails.currentPlan;
  if (plan === 'trial') {
    property.email = property.email || user.email;
    property.uid = property._id || user._id;
    property.role =
      user && 'defaultTeam' in user && user.defaultTeam
        ? user.defaultTeam.role
        : 'creator';
    property.accountOwnerId =
      user && 'defaultTeam' in user && user.defaultTeam
        ? user.defaultTeam.teamOwnerId
        : '';
    property.accountOwnerEmail =
      user && 'accountOwnerEmail' in user ? user.accountOwnerEmail : '';
    property.page = page;
    property.time = moment.utc().format();
    try {
      if (page !== 'free trial start') {
        initPendo();
      }
      // eslint-disable-next-line no-undef
      pendo.track(page, property);
      // eslint-disable-next-line no-empty
    } catch {}
  }
  /* PENDO end */
};
