import { BASE_API_URL, REPORTS_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

const options = AppService.options();

export const reportService = {
  get_screenshots: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };

    const URL = `${BASE_API_URL}${
      REPORTS_URLS.SCREENSHOT
    }?${new URLSearchParams(data).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  get_worksession: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };

    const URL = `${BASE_API_URL}${
      REPORTS_URLS.WORKSESSION
    }?${new URLSearchParams(data).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  get_pdfs: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };

    const URL = `${BASE_API_URL}${
      REPORTS_URLS.REPORT_PDFS
    }?${new URLSearchParams(data).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  generate_pdf: async (data, method, Paramdata) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method,
    };
    const URL = `${BASE_API_URL}${
      REPORTS_URLS.CREATE_PDF
    }?${new URLSearchParams(Paramdata).toString()}`;

    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
      body: JSON.stringify(data),
    });
    return response;
  },
  get_overtime_data: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + REPORTS_URLS.OVERTIME,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
};
