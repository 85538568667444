import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux';

const TeamMembersDropdown = ({
  teamMembersList,
  handleChange,
  handleTeamMemberChange,
  // To confirm if dropdown change will affect any other fields on the same form
  // For Eg: timesheet page team members dropdown change will re-render project list field
  isDependentFiled,
  // Is field validation is required
  isValidate,
  isAllOptionRequired,
  isUpdatingTeamsList,
  defaultPlaceholder,
  fieldName,
  isRequired,
  fieldLabel,
}) => {
  // Team members list
  const teamMembers = teamMembersList
    .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
    .map((member, index) => (
      <option key={index} value={member.userId}>
        {member.lastName
          ? `${member.firstName} ${member.lastName}`
          : member.firstName}
        {member.status === 'inactive' && ' (Inactive)'}
      </option>
    ));
  const userDetails = useSelector((state) => state.userReducer);
  const userData = JSON.parse(localStorage.getItem('USER_DATA'));
  const label = fieldLabel || 'Team members';

  return (
    <Form.Group controlId="TeamMembers">
      <Form.Label>
        {label} {isRequired && <span className="text-danger">*</span>}
      </Form.Label>
      {userDetails.role === 'member' && (
        <Field
          className="form-select"
          as="select"
          name={fieldName || 'teamMember'}
          disabled={isUpdatingTeamsList}
        >
          <option key={0} value={userData._id}>
            {userData.lastName
              ? `${userData.firstName} ${userData.lastName}`
              : userData.firstName}
          </option>
        </Field>
      )}
      {userDetails.role !== 'member' &&
        (isDependentFiled ? (
          <>
            <Field
              className="form-select"
              as="select"
              name={fieldName || 'teamMember'}
              onChange={(e) => {
                handleChange(e);
                setTimeout(() => {
                  handleTeamMemberChange(e);
                }, 0);
              }}
            >
              {isValidate ? (
                <option value="">Select a Team Member</option>
              ) : (
                <option value="all">All</option>
              )}
              {defaultPlaceholder && (
                <option value="">{defaultPlaceholder}</option>
              )}
              {teamMembers}
            </Field>
            {isValidate && (
              <ErrorMessage
                name="teamMember"
                render={(msg) => <small className="text-danger">{msg}</small>}
              />
            )}
          </>
        ) : (
          <Field
            className="form-select"
            as="select"
            name={fieldName || 'teamMember'}
          >
            {isAllOptionRequired && <option value="all">All</option>}
            {defaultPlaceholder && (
              <option value="">{defaultPlaceholder}</option>
            )}
            {teamMembers}
          </Field>
        ))}
    </Form.Group>
  );
};

TeamMembersDropdown.propTypes = {
  teamMembersList: PropTypes.array,
  handleChange: PropTypes.func,
  handleTeamMemberChange: PropTypes.func,
  isDependentFiled: PropTypes.bool,
  isValidate: PropTypes.bool,
  isAllOptionRequired: PropTypes.bool,
  isUpdatingTeamsList: PropTypes.bool,
  fieldName: PropTypes.string,
  isRequired: PropTypes.bool,
  defaultPlaceholder: PropTypes.string,
  fieldLabel: PropTypes.string,
};

export default TeamMembersDropdown;
