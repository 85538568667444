import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import {
  fetchProjectsList,
  setProjectSearchParam,
} from '../../actions/projects';

const ProjectSearch = () => {
  const dispatch = useDispatch();
  const activeProjects = useSelector((state) => state.projectsList);
  const handleSearch = () => {
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      page: activeProjects.page,
      limit: 9,
      summaryList: false,
      status: 'active',
      filter: JSON.stringify({
        projectTitle: activeProjects.searchParam,
      }),
    };
    dispatch(fetchProjectsList(params));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="input-group mb-3">
      <input
        className="form-control"
        placeholder="Search project"
        type="text"
        aria-label="Search project"
        aria-describedby="Search project"
        onChange={(e) => dispatch(setProjectSearchParam(e.target.value))}
        onKeyDown={handleKeyDown}
      />
      <Button variant="primary" onClick={handleSearch}>
        <span className="mdi mdi-magnify" />
      </Button>
    </div>
  );
};

export default ProjectSearch;
