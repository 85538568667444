import { useState } from 'react';
import { Card, Form, Row, Col, Button, Collapse } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { settingsService } from '../../services';
import Loader from '../Loader';
import { updateLocalStorage } from '../../helpers';
import {
  UPDATE_FAILURE_ERROR,
  LOCAL_STORAGE_USER_DATA,
  SETTINGS_BILLING,
} from '../../constants';

const TrialChangeEmails = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openAccEmail, setOpenAccEmail] = useState(false);
  const [openBillEmail, setOpenBillEmail] = useState(false);
  const userdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));

  return (
    <Formik
      initialValues={{
        accountCheck: false,
        accEmail: '',
        accEmailConfirm: '',
        billingCheck: false,
        billingEmail: '',
        billingEmailConfirm: '',
      }}
      validationSchema={Yup.object({
        accEmail: Yup.string().when('accountCheck', {
          is: true,
          then: Yup.string()
            .email('Please enter a valid New Account Email Address')
            .required('Please enter your New Account Email Address')
            .notOneOf(
              [userdata.email],
              'New and current Account Email Address are matching'
            ),
        }),
        accEmailConfirm: Yup.string().when('accountCheck', {
          is: true,
          then: Yup.string()
            .email('Please enter a valid Confirm Account Email Address')
            .required('Please enter your Confirm Account Email Address')
            .equals(
              [Yup.ref('accEmail')],
              'The New Account Email Address and Confirm Email Address do not match'
            ),
        }),
        billingEmail: Yup.string().when('billingCheck', {
          is: true,
          then: Yup.string()
            .email('Please enter a valid Billing Email Address')
            .required('Please enter your Billing Email Address'),
        }),
        billingEmailConfirm: Yup.string().when('billingCheck', {
          is: true,
          then: Yup.string()
            .email('Please enter a valid Billing Email Address')
            .required('Please enter your Billing Email Address')
            .equals(
              [Yup.ref('billingEmail')],
              'The New Billing Email Address and Confirm Email Address do not match'
            ),
        }),
      })}
      onSubmit={(values) => {
        setIsSubmitting(true);
        if (values.accountCheck) {
          const newFormData = { email: values.accEmail };
          settingsService
            .user_update({ ...newFormData })
            .then(() => {
              setIsSubmitting(false);

              // Update user details in storage
              updateLocalStorage(newFormData);
              toast.success(SETTINGS_BILLING.TRIAL_AEMAIL_UPDATE);
            })
            .catch((errResponse) => {
              if ('data' in errResponse) {
                errResponse.data.forEach((elem) => {
                  toast.error(`${elem.msg} ${elem.param}`);
                });
              } else if ('message' in errResponse) {
                toast.error(errResponse.message);
              } else {
                toast.error(errResponse);
              }

              setIsSubmitting(false);
            });
        }
        if (values.billingCheck) {
          const data = {
            teamOwnerId: localStorage.getItem('teamOwnerId'),
            email: values.billingEmail,
          };
          settingsService
            .update_billing_email(data)
            .then(() => {
              setIsSubmitting(false);
              toast.success(SETTINGS_BILLING.TRIAL_BEMAIL_UPDATE);
            })
            .catch(() => {
              setIsSubmitting(false);
              toast.error(UPDATE_FAILURE_ERROR);
            });
        }
      }}
    >
      {(formikprops) => (
        <Card>
          <Card.Body>
            {isSubmitting && <Loader contentAreaOnly />}
            <Form
              id="change-emails"
              onSubmit={formikprops.handleSubmit}
              className="needs-validation"
            >
              <Row className="align-items-center">
                <Col>
                  <div className="custom-control custom-checkbox mb-3">
                    <Field
                      className="form-check-input form-check-border"
                      name="accountCheck"
                      type="checkbox"
                      onClick={() => {
                        setOpenAccEmail(!openAccEmail);
                        if (!openAccEmail) {
                          formikprops.setFieldValue('accEmail', '');
                          formikprops.setFieldValue('accEmailConfirm', '');
                        }
                      }}
                      aria-controls="example-collapse-text"
                      aria-expanded={openAccEmail}
                    />
                    <Form.Label
                      className="custom-control-label ms-2"
                      htmlFor="accountCheck"
                    >
                      Would you like to change your Email Address for Account?
                    </Form.Label>
                  </div>
                  <Collapse in={openAccEmail}>
                    <div>
                      <div className="form-group">
                        <Form.Label className="form-control-label">
                          Add new email address
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Enter email address"
                          name="accEmail"
                        />
                        <ErrorMessage
                          name="accEmail"
                          render={(msg) => (
                            <small className="text-danger">{msg}</small>
                          )}
                        />
                      </div>
                      <div className="form-group">
                        <Form.Label className="form-control-label">
                          Confirm account email address
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Confirm email address"
                          name="accEmailConfirm"
                        />
                        <ErrorMessage
                          name="accEmailConfirm"
                          render={(msg) => (
                            <small className="text-danger">{msg}</small>
                          )}
                        />
                        <div className="small text-muted">
                          Account Email Address is Hivedesk username. Please add
                          new email address, If you want to add new email
                          address for paid account.
                        </div>
                      </div>
                    </div>
                  </Collapse>

                  <div className="custom-control custom-checkbox pt-3 mb-3">
                    <Field
                      className="form-check-input form-check-border"
                      name="billingCheck"
                      type="checkbox"
                      onClick={() => {
                        setOpenBillEmail(!openBillEmail);
                        if (!openBillEmail) {
                          formikprops.setFieldValue('billingEmail', '');
                          formikprops.setFieldValue('billingEmailConfirm', '');
                        }
                      }}
                      aria-controls="example-collapse-text"
                      aria-expanded={openBillEmail}
                    />
                    <Form.Label
                      className="custom-control-label ms-2"
                      htmlFor="billingCheck"
                    >
                      Would you like to change your Email Address for Billing?
                    </Form.Label>
                  </div>
                  <Collapse in={openBillEmail}>
                    <div className="">
                      <div className="form-group">
                        <Form.Label className="form-control-label">
                          Add new Email Address for Billing
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Enter billing email address"
                          name="billingEmail"
                        />
                        <ErrorMessage
                          name="billingEmail"
                          render={(msg) => (
                            <small className="text-danger">{msg}</small>
                          )}
                        />
                      </div>
                      <div className="form-group">
                        <Form.Label className="form-control-label">
                          Confirm Billing Email address
                        </Form.Label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Confirm billing email address"
                          name="billingEmailConfirm"
                        />
                        <ErrorMessage
                          name="billingEmailConfirm"
                          render={(msg) => (
                            <small className="text-danger">{msg}</small>
                          )}
                        />
                        <div className="small text-muted">
                          Email Address for billing communication.
                        </div>
                      </div>
                    </div>
                  </Collapse>
                  <div className="float-end">
                    <Button
                      form="change-emails"
                      type="submit"
                      disabled={
                        formikprops.values.accountCheck === false &&
                        formikprops.values.billingCheck === false
                      }
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      )}
    </Formik>
  );
};

export default TrialChangeEmails;
