import { jsPDF as JSPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { sToHMSColonSeparated } from '../../helpers';

const GeneratePDF = (
  worksessions,
  grandTotal,
  reportInfo,
  isMobileTracking,
  showNotes
) => {
  // initialize jsPDF
  const doc = new JSPDF('l', 'pt', 'a4');
  const totalPagesExp = '{total_pages_count_string}';
  const pageWidth =
    doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const defaultCurrency =
    reportInfo.timesheetMetadata.defaultCurrency == null
      ? 'USD'
      : reportInfo.timesheetMetadata.defaultCurrency;
  const startDate = moment(reportInfo.dateRange.startDate).format(
    'MMM DD, YYYY'
  );
  const endDate = moment(reportInfo.dateRange.endDate).format('MMM DD, YYYY');

  // Table header columns
  const tableColumn = [
    'Project',
    'Team Member',
    'Task',
    'Worksession Date',
    'Worksession Time',
  ];
  if (isMobileTracking) {
    tableColumn.push('Worksession Type');
  }
  tableColumn.push(
    'Duration',
    'Active Time',
    'Activity',
    `Cost (${defaultCurrency})`
  );
  if (showNotes) {
    tableColumn.push('Notes');
  }

  // Table foot data and columns
  const totalDuraion = sToHMSColonSeparated(grandTotal.duration);
  const totalActiveTime = sToHMSColonSeparated(grandTotal.activeTime);
  const totalActivity = `${Math.round(grandTotal.activity)}%`;
  const totalCost = grandTotal.cost.toFixed(2);
  const tableFoot = ['', '', '', ''];
  if (isMobileTracking) {
    tableFoot.push('');
  }
  tableFoot.push(
    'Grand Total',
    totalDuraion,
    totalActiveTime,
    totalActivity,
    totalCost
  );

  // Table body columns
  const tableRows = [];

  // for each worksession pass all its data into an array
  worksessions.forEach((worksession) => {
    const worksessionData = [
      worksession.projectTitle +
        (worksession.projectStatus === 'close' ? ' (Closed)' : ''),
      worksession.userName +
        (worksession.userTeamStatus === 'inactive' ? ' (Inactive)' : ''),
      worksession.task ? worksession.task : '--',
      `${moment(worksession.checkInDate).format('LL')}\n${
        worksession.checkOutDate
          ? moment(worksession.checkOutDate).format('LL')
          : ''
      }`,
      `${moment(worksession.checkInDate).format('hh:mm:ss A')}\n${
        worksession.checkOutDate
          ? moment(worksession.checkOutDate).format('hh:mm:ss A')
          : ''
      }`,
    ];
    if (isMobileTracking) {
      worksessionData.push(
        worksession.workSessionType
          ? worksession.workSessionType.charAt(0).toUpperCase() +
              worksession.workSessionType.substr(1).toLowerCase()
          : 'Desktop'
      );
    }

    let costTotal = '--';

    if (worksession.activeSeconds && worksession.rate.hourlyCostRate) {
      costTotal = (
        Math.round(
          ((Math.round(worksession.workSessionSeconds) / 3600) *
            worksession.rate.hourlyCostRate +
            Number.EPSILON) *
            100
        ) / 100
      ).toFixed(2);
    }

    worksessionData.push(
      sToHMSColonSeparated(Math.round(worksession.workSessionSeconds)),
      sToHMSColonSeparated(Math.round(worksession.activeSeconds)),
      `${
        worksession.workSessionType === 'desktop'
          ? `${
              worksession.activity > 0 ? Math.round(worksession.activity) : 0
            }%`
          : 'N/A'
      } `,
      costTotal
    );
    if (showNotes) {
      worksessionData.push(
        worksession.notes.map((noteEntry) => noteEntry.note).join('\n') || '--'
      );
    }
    // push each session's info into a row
    tableRows.push(worksessionData);
  });

  // Initialize autoTable
  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    foot: [tableFoot],
    startY: 50,
    styles: { cellPadding: 10 },
    headStyles: {
      fillColor: [237, 242, 249],
      fontSize: 9,
      textColor: [40, 62, 89],
    },
    footStyles: {
      fillColor: [237, 242, 249],
      textColor: [40, 62, 89],
    },
    alternateRowStyles: {
      fillColor: [249, 251, 253],
    },
    tableLineColor: [237, 242, 249],
    tableLineWidth: 1,
    showFoot: 'lastPage',
    didDrawPage(data) {
      // Header
      doc.setFontSize(14);
      doc.setTextColor(40);
      doc.text(
        'Team Member Timesheet Detail Report',
        pageWidth / 2,
        23,
        'center'
      );
      doc.setFontSize(10);
      doc.text(
        `Date Range : ${startDate} - ${endDate}`,
        pageWidth / 2,
        38,
        'center'
      );
      // Footer
      let str = `Page ${doc.internal.getNumberOfPages()}`;
      // Total page number
      if (typeof doc.putTotalPages === 'function') {
        str = `${str} of ${totalPagesExp}`;
      }
      doc.setFontSize(10);
      const { pageSize } = doc.internal;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(str, 750, pageHeight - 22);
      // Account Owner TimeZone
      doc.text(
        reportInfo.timesheetMetadata.timeZoneFormatted,
        data.settings.margin.left,
        pageHeight - 22
      );
      doc.text('*Average only for desktop sessions', 550, pageHeight - 22);
    },
  });

  // Total page number
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  // Date string to generate our filename.
  const dateStr = `from_${startDate}_to_${endDate}`.replace(
    /[^A-Z0-9]+/gi,
    '_'
  );
  // Name of PDF file.
  doc.save(`timesheet_report_${dateStr}.pdf`);
};

export default GeneratePDF;
