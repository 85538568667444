import { toast } from 'react-toastify';
import {
  AUTH_TOKEN_NAME,
  SESSION_EXPIRED,
  TIMESHEET_TIMEOUT,
} from '../constants';
import { getSessionVariable, clearSession } from '../helpers';

export const AppService = {
  /**
   * Set http options
   * Default: POST, application/json
   * @default method POST
   * @default credentials 'include' required to accept and send cookies
   */
  options: () => ({
    method: 'POST',
  }),

  /**
   * Make a fetch API call
   * @param {string} url fully qualified url
   * @param {object} requestOptions options object from options()
   */

  makeRequest: async (url, requestOptions) => {
    const response = await fetch(url, requestOptions);
    let result = null;

    if (!(response.error && response.error.name === 'AbortError')) {
      try {
        result = await response.json();
      } catch (error) {
        throw response;
      }
    } else {
      throw response;
    }
    if (response.status >= 400) {
      // Handle expired session
      if (response.status === 401) {
        toast.error(SESSION_EXPIRED);
        clearSession();
        window.location.href = '/login';
      }
      if (
        (response.status === 500 || response.status === 403) &&
        'message' in result &&
        !('data' in result) &&
        !('info' in result)
      ) {
        toast.error(result.message);
        return false;
      }
      // Handle timesheet api timeout
      if (response.status === 504 && url.includes('/timesheet')) {
        return toast.error(TIMESHEET_TIMEOUT);
      }
      throw result;
    }

    return result;
  },
};

/**
 * Sets HTTP Headers with Authorization token
 * @returns {object} headers
 */
export const HTTPHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getSessionVariable(AUTH_TOKEN_NAME)}`,
  },
});
