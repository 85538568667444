export const LOGOUT_SUCCESSFUL = 'You have been logged out!';
export const INVALID_LOGIN = 'The login information you entered is incorrect.';
export const INVALID_WEB_LOGIN =
  'Web login failed. Please try login with your user name and password.';
export const FORGOT_PW_SUCCESS =
  'Reset password link has been sent to your email. The link will expire in 15 minutes.';
export const CHANGE_PW_SUCCESS = 'Password changed successfully';
export const RESET_PW_SUCCESS = 'Password is reset successfully';
export const SUPPORT_EMAIL = 'support@hivedesk.com';

export const SUPPORT_LINK = 'http://support.hivedesk.com/';
export const SUPPORT_CONTACT = `If you have any questions, please feel free to contact`;
export const GENERAL = {
  AVATAR_SUCCESS: 'Your Avatar is uploaded',
  AVATAR_DELETE: 'Your Avatar is deleted',
  USER_UPDATE_SUCCESS: 'Profile is updated',
  AVATAR_DELETE_MODAL:
    'Are you sure you want to clear the avatar selected, Continue?',
  AVATAR_SIZE: 'The avatar you selected is too big (max size 300K)',
  LOGO_SIZE: 'The logo you selected is too big (max size 100K)',
  MOBILE_TRACKING:
    'Due to the limitations placed by mobile providers, the app can only track time and locations and cannot record screenshots or measure activity levels.',
  AVATAR_SPEC: ' PNG or JPG no bigger than 1000px width and height.',
  MEMBER_SIGNUP: 'Registration completed successfully',
  AVATAR_TYPE:
    'The avatar image type you selected is not supported (JPG, PNG only)',
  ACCOUNT_CONFIRM_SUCCESS: 'Your HiveDesk account confirmed successfully',
  ACCOUNT_CONFIRM_ERROR:
    'Sorry, Your account confirm process is failed. Please contact HiveDesk Support',
};
export const IMPORT_FILE = {
  XLS_SIZE: 'The file you selected is too big (max size 512K)',
  XLS_TYPE: 'The file type you selected is not supported (XLS, XLSX, ODS only)',
  CSV_SIZE: 'The file you selected is too big (max size 512K)',
  CSV_TYPE: 'The file type you selected is not supported (CSV only)',
};
export const NO_DATA = {
  MANUAL_SESSION: 'Manual worksession. No screenshot available',
  SCREENSHOTS: 'No screenshots have been taken for this work session',
  WORKSESSION: 'No worksession for selected date range',
  WORKSESSION_CHART: 'No worksession chart for selected date range',
  PERFORMANCE: 'No performance chart for selected date range',
  DELETED_SCREENSHOT: 'Screenshot deleted',
  ACTIVE_PROJECTS: 'No active project available',
  WORKSESSION_EDIT_LOG: 'No edits to worksession have been made.',
  PROJECT_WORKSESSION_DATA: 'No work session logged for this project',
  MOBILE_SESSION: 'Mobile work session. Screenshot not applicable.',
  CLIENTS: 'No clients available',
  EXTENSION_SESSION: 'Extension work session. Screenshot not applicable.',
  ATTENDANCE: 'No attendance data for selected date range',
  WEB_SESSION: 'Web work session. Screenshot not applicable.',
  INVOICE: 'No invoices found.',
  WS_CHANGE_REQUESTS: 'No requests created – no data available.',
  ONGOING_SESSIONS: 'No ongoing sessions available.',
};
export const DEFAULT_INV_NOTE =
  'Thank you for your business! If you have any questions regarding this invoice, please contact [contact].';
export const CONFIRM_LOGOUT_MODAL_TITLE = 'Log out of HiveDesk?';
export const SETTINGS_TEAMS = {
  UPDATE_TEAM_SET: 'Default team saved successfully',
  UPDATE_TEAM_UNSET: 'Default team removed successfully',
  UPDATE_AUTOCHECKOUT_SET: 'Auto checkout settings saved successfully',
  UPDATE_AUTOCHECKOUT_UNSET: 'Auto checkout settings removed successfully',
  TIMESHEET_APPROVAL_SET: 'Timesheet approval enabled successfully',
  TIMESHEET_APPROVAL_UNSET: 'Timesheet approval disabled successfully',
  MOBILE_APPROVAL_SET: 'Mobile Tracking approval enabled successfully',
  MOBILE_APPROVAL_UNSET: 'Mobile Tracking approval disabled successfully',
  UPDATE_OVERTIME_SET: 'Overtime settings saved successfully',
  UPDATE_OVERTIME_UNSET: 'Overtime settings disabled successfully',
  UPDATE_SCHEDULE_SET: 'Schedule settings saved successfully',
  UPDATE_SCHEDULE_UNSET: 'Schedule settings disabled successfully',
  UPDATE_NOTIFICATION_SETTING_SET: 'Notification settings saved successfully',
  UPDATE_NOTIFICATION_SETTING_UNSET:
    'Notification settings disabled successfully',
  INVITATION_APPROVAL_SET:
    'Add all existing team members directly to the projects without invitation process enabled successfully',
  INVITATION_APPROVAL_UNSET:
    'Add all existing team members directly to the projects without invitation process disabled successfully',
  UPDATE_ERROR: 'There was an error updating.Please try after some time',
  TEAM_CHANGE: 'Your team has been changed successfully',
  UPDATE_DAILY_LIMIT: 'Daily Limit saved successfully',
};
export const SETTINGS_INVOICE = {
  SET: 'Invoice feature enabled successfully',
  UNSET: 'Invoice feature disabled successfully',
  SAVE: 'Invoice settings saved successfully',
  DELETE_LOGO: 'Logo deleted successfully',
};
export const NOT_FOUND = {
  HEADING: 'There’s no page here',
  TEXT: 'Looks like you ended up here by accident?',
};
export const SS_DATE_RANGE = {
  LIMIT: 'Screenshot reports are limited to a maximum range of one week.',
  ERROR: 'Please try within a week range.',
};
export const OT_DATE_RANGE = {
  LIMIT: 'Overtime reports are limited to a maximum range of one week.',
  ERROR: 'Please try within a week range.',
};
export const TIMESHEET_DATE_RANGE = {
  LIMIT: 'Timesheet reports are limited to a maximum range of 90 days.',
  ERROR: 'Please try within 90 days range.',
};
export const ATENDANCE_DATE_RANGE = {
  LIMIT: 'Attendance reports are limited to a maximum range of one month.',
  ERROR: 'Please try within a month range.',
};
export const ACTIVITY_DATE_RANGE = {
  DAYS: 30,
  LIMIT: 'Team activity can be generated up to a maximum of 1 month data.',
  ERROR: 'Please try within a month range.',
};
export const PROJECT = {
  DELETE_PROJECT:
    'You are about to delete this project. All data will be deleted including time logs and screenshots',
  ARCHIVE_PROJECT_SUCCESS: 'The project is archived successfully',
};
export const TEAM = {
  ROLE_UPDATE_SUCCESS: 'Role updated successfully',
  STATUS_UPDATE_SUCCESS:
    'Team Member is archived successfully from all the Project(s)',
  STATUS_UPDATE_ACTIVATE_SUCCESS: 'Team member activated successfully',
  MEMBER_DELETE_SUCCESS:
    'Team member removed successfully from all the project(s)',
  RATE_UPDATE_SUCCESS: 'Rate updated successfully',
  DAILY_LIMIT_UPDATE_SUCCESS: 'Daily limit updated successfully',
};
export const SETTINGS_BILLING = {
  EMAIL_UPDATE: 'Your Email Address is updated successfully',
  EMAIL_EXISTS: 'This Email Address already exists',
  INVALID_EMAIL: 'Please enter valid Email Address',
  PLAN_UPDATE: 'Successfully Updated Your Plan',
  UPDATE_ERROR: `The requested operation is not successfully processed. Please contact ${SUPPORT_EMAIL}`,
  TRIAL_MODAL: `We hope you enjoyed your free trial! If you would like to continue to manage your projects or remote Team Members, please choose your plan.`,
  PAID_MODAL: `Please upgrade your account to manage your projects or remote Team Members.`,
  ISMEMBER_MODAL:
    'Please click the "Continue as a Member" link to work as a member without upgrading your account. In this case, you can work as a remote Team Member, but not as an account owner.',
  TRIAL_BEMAIL_UPDATE: 'Billing Email Address updated successfully',
  TRIAL_AEMAIL_UPDATE: 'Account Email Address updated successfully',
  CARD_EXPIRED:
    'We regret to inform you that the card on file for your account has expired.\n\nYou can update your payment details by clicking "Update My Credit Card" button for uninterrupted HiveDesk Service',
  PAYMENT_DUE:
    'Your account is currently suspended as your credit card payment was not processed or is invalid. Please update your card details to resume your service.',
  THANK_YOU: 'https://www.hivedesk.com/registration-typ',
};
export const API_FAILURE_ERROR =
  'There was an error getting the data. Please try after some time.';
export const UPDATE_FAILURE_ERROR =
  'Something didn’t work quite right. Please try after some time.';
export const SESSION_EXPIRED =
  'Your session has been expired. Please login again.';
export const TIMESHEET_TIMEOUT =
  'Server under heavy load, resulting in a timeout. Please try filtering by team member or project.';
export const INVITATIONS = {
  ACCEPT_INVITATION_SUCCESS: 'You have successfully accepted the invite',
  DECLINE_INVITATION_SUCCESS:
    'You have successfully declined the project invite',
  DELETE_INVITATION:
    'Are you sure you want to delete the invitation selected, Continue?',
  DELETE_INVITATION_SUCCESS: 'Invitation deleted successfully',
  RESEND_INVITATION_SUCCESS: 'Invitation has been resent successfully',
  INVITATION_SUCCESS: 'Invitation has been sent successfully',
  INVALID_INVITATION: 'Invalid Invitation',
  ADDED_TO_PROJECT: 'Members added successfully to project',
};

export const SETTINGS_SUMMMARIES = {
  DAILY_SUMMARY:
    'User will receive daily summary email if there is no worksession logged for the day',
  NO_DAILY_SUMMARY:
    'User will not receive daily summary email if there is no worksession logged for the day',
  MEMBER_EDIT: 'Recipient options updated successfully',
  MEMBER_DEL: 'Member deleted successfully',
  MEMBER_ADD: 'Member(s) added successfully to project',
};
export const RECAPTCHA_ERROR =
  'There was an error with reCaptcha. Please try after some time.';
export const SETTINGS_UPDATE_GENERIC = 'Settings updated successfully';
export const TOUR_INPUT = 'Thank you for your feedback.';
export const SCHEDULING = {
  NO_SHIFT_TO_COPY:
    'No shifts found to copy. Please ensure there is a shift available to copy from.',
};
export const HELPER_TEXT_BILLABLE_BREAKS =
  '(*) indicate billable break sessions';
