import PropTypes from 'prop-types';
import DeleteLink from '../DeleteLink';
import { WithConfirmation } from '../common';

const DeleteProjectButton = ({ handleDelete, projectId }) => {
  const DeleteProjectWithConfirmation = WithConfirmation(DeleteLink);

  const DeleteModalProps = {
    title: `Delete Project`,
    action: handleDelete,
    params: projectId,
    content: [
      'You are about to delete this project.',
      'All data will be deleted including time logs and screenshots',
    ],
    actionBtnText: 'Delete',
  };

  return <DeleteProjectWithConfirmation modalProps={DeleteModalProps} />;
};

DeleteProjectButton.propTypes = {
  handleDelete: PropTypes.func,
  projectId: PropTypes.string,
};

export default DeleteProjectButton;
