import React, { useState } from 'react';
import {
  Button,
  Card,
  Accordion,
  useAccordionToggle,
  Row,
  Col,
  Nav,
  Alert,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { WeeklyStatusToggle, ExportOptions } from './index';
import { msToHMS, sToHMSColonSeparated } from '../../helpers';

const ViewTimesheetContent = ({
  isLoading,
  timesheetDetails,
  loadMoreData,
  submitTimesheet,
  statusUpdate,
}) => {
  const [panelIndex, setPanelIndex] = useState();

  const CustomToggle = ({ children, eventKey }) => {
    const customOnClick = useAccordionToggle(eventKey, () => {
      setPanelIndex(eventKey === panelIndex ? null : eventKey);
    });

    const customClass = eventKey === panelIndex ? 'opened' : 'collapsed';

    return (
      <Nav.Link
        className={`${customClass} hd-accordion-button`}
        onClick={customOnClick}
      >
        {children}
      </Nav.Link>
    );
  };

  CustomToggle.propTypes = {
    children: PropTypes.any,
    eventKey: PropTypes.any,
  };

  return (
    <>
      {timesheetDetails.length > 0 ? (
        <Card>
          <section>
            <ExportOptions timesheetDetails={timesheetDetails} />
            <Accordion>
              {timesheetDetails
                .sort((a, b) =>
                  a.wsWeekDetails.startDate < b.wsWeekDetails.startDate ? 1 : -1
                )
                .map((week) => {
                  const weekDates = week.data[0].wsDetails;
                  return (
                    <section key={`week-ts-${week._id}`}>
                      <Card.Header>
                        <Row className="align-items-center">
                          <Col>
                            <CustomToggle
                              eventKey={week._id}
                              className="text-start"
                            >
                              {moment
                                .utc(week.wsWeekDetails.startDate)
                                .format('MMM D')}{' '}
                              -{' '}
                              {moment
                                .utc(week.wsWeekDetails.endDate)
                                .format('MMM D')}
                              <span className="text-muted">
                                {' '}
                                ...{' '}
                                {msToHMS(
                                  Math.round(week.totalWorkSessionSeconds) *
                                    1000
                                )}
                              </span>
                            </CustomToggle>
                          </Col>
                          <Col className="col-auto p-0">
                            <WeeklyStatusToggle
                              status={week.status}
                              logs={week.approvalLogs}
                              wsWeekDetails={week.wsWeekDetails}
                              submitTimesheet={submitTimesheet}
                              wsWeekStatusUpdate={statusUpdate}
                              weeklySubmissionId={
                                week.approvalId !== null ? week.approvalId : ''
                              }
                            />
                          </Col>
                        </Row>
                      </Card.Header>
                      <Accordion.Collapse eventKey={week._id}>
                        <Card.Body className="p-0">
                          <div className="table-responsive">
                            <table className="table table-sm table-hover card-table time-log-table">
                              <thead className="thead-light">
                                <tr>
                                  <th>Project</th>
                                  {weekDates.map((day) => (
                                    <th
                                      className="text-center"
                                      key={`th-${day.date}`}
                                    >
                                      {moment(day.date).format('ddd, MMM DD')}
                                    </th>
                                  ))}
                                  <th className="text-end">Total</th>
                                  <th className="text-end">&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                                {week.data.map((project, pIndex) => {
                                  const taskList =
                                    project.task.length > 0
                                      ? project.task.filter(
                                          (tItem) =>
                                            '_id' in tItem && 'task' in tItem
                                        )
                                      : [];
                                  return (
                                    <React.Fragment
                                      key={`${pIndex}-${project.title}`}
                                    >
                                      <tr
                                        className={
                                          project.task &&
                                          project.task.length > 1
                                            ? 'tasks-active'
                                            : 'no-tasks'
                                        }
                                      >
                                        <td>
                                          <strong>{project.title}</strong>
                                        </td>
                                        {project.wsDetails.map((day) => (
                                          <td
                                            className="text-center"
                                            key={`td-${day.date}`}
                                          >
                                            <strong>
                                              {day.totalWorkSessionSeconds === 0
                                                ? '-'
                                                : sToHMSColonSeparated(
                                                    day.totalWorkSessionSeconds,
                                                    'hh:mm:ss'
                                                  )}
                                            </strong>
                                          </td>
                                        ))}
                                        <td className="text-end">
                                          <strong>
                                            {sToHMSColonSeparated(
                                              project.totalWorkSessionSeconds,
                                              'hh:mm:ss'
                                            )}
                                          </strong>
                                        </td>
                                        <td className="text-end">
                                          {project &&
                                            project.permissions &&
                                            project.permissions.canEditHours &&
                                            week.status === null && (
                                              <Link
                                                to={{
                                                  pathname: `/projects/${project._id}/edit-worksession`,
                                                  search: `?startDate=${moment(
                                                    week.wsWeekDetails.startDate
                                                  ).format(
                                                    'YYYY-MM-DD'
                                                  )}&endDate=${moment(
                                                    week.wsWeekDetails.endDate
                                                  ).format('YYYY-MM-DD')}`,
                                                }}
                                                className="btn btn-link btn-sm"
                                              >
                                                <i className="mdi mdi-pencil me-1" />
                                                Edit
                                              </Link>
                                            )}
                                        </td>
                                      </tr>
                                      {taskList.length > 0 && (
                                        <>
                                          {taskList.map((taskItem, tIndex) => {
                                            const taskTotalTime =
                                              taskItem.wsDetails.reduce(
                                                (pValue, cValue) =>
                                                  pValue +
                                                  cValue.totalWorkSessionSeconds,
                                                0
                                              );
                                            return (
                                              <tr
                                                className="tasksRow"
                                                key={`${tIndex}-${taskItem._id}`}
                                              >
                                                <td>
                                                  <small className="ps-3">
                                                    {taskItem.task}
                                                  </small>
                                                </td>
                                                {taskItem.wsDetails.map(
                                                  (tasksWs, tWsIndex) => (
                                                    <td
                                                      className="text-center"
                                                      key={`${tWsIndex}-${tasksWs.date}`}
                                                    >
                                                      <small>
                                                        {tasksWs.totalWorkSessionSeconds ===
                                                        0
                                                          ? '-'
                                                          : sToHMSColonSeparated(
                                                              tasksWs.totalWorkSessionSeconds,
                                                              'hh:mm:ss'
                                                            )}
                                                      </small>
                                                    </td>
                                                  )
                                                )}
                                                <td className="text-end">
                                                  <small>
                                                    {sToHMSColonSeparated(
                                                      taskTotalTime,
                                                      'hh:mm:ss'
                                                    )}
                                                  </small>
                                                </td>
                                                <td className="text-end">
                                                  &nbsp;
                                                </td>
                                              </tr>
                                            );
                                          })}
                                          <br />
                                        </>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            </table>
                            <hr className="m-0" />
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </section>
                  );
                })}
            </Accordion>
            {!isLoading && (
              <Card.Header>
                <Button
                  variant="link"
                  className="text-start text-muted p-0"
                  onClick={() => loadMoreData()}
                >
                  Load more ...
                </Button>
              </Card.Header>
            )}
          </section>
        </Card>
      ) : (
        !isLoading && (
          <Alert variant="info" className="text-center">
            No worksession available
          </Alert>
        )
      )}
    </>
  );
};

ViewTimesheetContent.propTypes = {
  timesheetDetails: PropTypes.array,
  isLoading: PropTypes.bool,
  loadMoreData: PropTypes.func,
  submitTimesheet: PropTypes.func,
  statusUpdate: PropTypes.func,
};

export default ViewTimesheetContent;
