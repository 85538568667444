import { Form, Button, Row, Col, Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar.jsx';
import { GENERAL } from '../../constants';
import '../../assets/styles/avatar-upload.scss';

const AvatarUpload = ({ avatarState, uploadAvatar, showDelete }) => {
  const userDetails = useSelector((state) => state.userReducer);
  const { role } = userDetails;

  return (
    <Row className="row align-items-center">
      <Col className="col-auto">
        <div className="avatar-upload">
          <div className="avatar-edit">
            <input
              type="file"
              name="avatar"
              id="imageUpload"
              accept=".png, .jpg, .jpeg"
              onChange={uploadAvatar}
            />
            <input
              type="hidden"
              id="MAX_FILE_SIZE"
              name="MAX_FILE_SIZE"
              value="307200"
            />
            <input type="hidden" id="initials" name="initials" value="" />

            <Tippy content="Change avatar" theme="grey">
              <Form.Label htmlFor="imageUpload" />
            </Tippy>
          </div>
          <div className="avatar-preview avatar avatar-lg">
            <Avatar
              firstName={avatarState.fname}
              lastName={avatarState.lname}
              imgPath={avatarState.imgPath}
              defaultBg=""
            />
          </div>

          {avatarState.imgPath !== '' && (
            <Button
              id="btnRemove"
              type="button"
              className="btn btn-sm btn-icon rounded-circle btn-danger"
              data-toggle="tooltip"
              title="Remove avatar"
              onClick={showDelete}
            >
              <i className="mdi mdi-close" />
            </Button>
          )}
        </div>
      </Col>
      <Col className="ml-n2">
        <h4 className="mb-1">
          <span> Your avatar </span>
          {role && (
            <Badge
              variant="light"
              bg="info"
              className="badge bg-primary-soft text-capitalize ms-3"
            >
              {role === 'member' && 'Member'}
              {role === 'creator' && 'Administrator'}
              {role === 'full_manager' && 'Full Manager'}
              {role === 'manager' && 'Basic Manager'}
            </Badge>
          )}
        </h4>
        <small className="text-muted">{GENERAL.AVATAR_SPEC}</small>
      </Col>
    </Row>
  );
};

AvatarUpload.propTypes = {
  avatarState: PropTypes.object,
  uploadAvatar: PropTypes.func,
  showDelete: PropTypes.func,
};
export default AvatarUpload;
