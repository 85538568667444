import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Loading from '../Loader';
import { teamService } from '../../services';
import { API_FAILURE_ERROR, INVITATIONS, WEB_URL } from '../../constants';
import { encrypt } from '../../helpers';

// Invitation delete component
const DeleteInvitation = ({ InvitationDelete }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="link" size="sm" onClick={handleShow}>
        <i className="mdi mdi-window-close " />
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="md"
        centered
        backdrop={false}
        className="modal-with-shadow"
      >
        <Modal.Header>
          <Modal.Title className="m-0">
            Are you sure you want to delete the invitation selected, Continue?
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="link" onClick={handleClose}>
            No
          </Button>
          <Button
            variant="danger"
            className=" ml-auto"
            onClick={InvitationDelete}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

DeleteInvitation.propTypes = {
  InvitationDelete: PropTypes.func,
};

function copyUrl(copyText) {
  const textArea = document.createElement('textarea');
  textArea.value = copyText;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('Copy');
  textArea.remove();
  toast.success('Invite link copied successfully');
}
function InvitationsListModal() {
  const [invitationDetails, setInvitationDetails] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isLoading, setLoading] = useState(false);
  const teamOwnerId = localStorage.getItem('teamOwnerId');

  const resendInvite = (inviteId, inviteProjectId) => {
    setLoading(true);
    const params = {
      teamOwnerId,
      id: inviteId,
    };
    if (inviteProjectId) {
      params.project = inviteProjectId;
    }
    teamService
      .resend_invitation(params)
      .then((response) => {
        if (response.message === 'Success') {
          setLoading(false);
          toast.success(INVITATIONS.RESEND_INVITATION_SUCCESS);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const deleteInvitation = (data) => {
    setLoading(true);
    teamService
      .delete_invitations({
        teamOwnerId,
        projectId: data.projectId,
        id: data.inviteId,
      })
      .then((response) => {
        setLoading(false);
        if (response.message === 'Success') {
          toast.success(INVITATIONS.DELETE_INVITATION_SUCCESS);
          handleClose();
        }
      })
      .catch((errResponse) => {
        setLoading(false);

        if ('message' in errResponse) {
          toast.error(errResponse.message);
        } else {
          toast.error(API_FAILURE_ERROR);
        }
      });
  };

  useEffect(() => {
    if (show) {
      setLoading(true);
      teamService
        .get_team_invitations_data({
          teamOwnerId: localStorage.getItem('teamOwnerId'),
        })
        .then((response) => {
          const teamInvites = response.data;
          if ('data' in response) setInvitationDetails(teamInvites);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error);
        });
    }
  }, [show]);

  // Style for invitation options display grid
  const optionsGrid = {
    display: 'grid',
    gridTemplateColumns: '200px 100px 70px 60px',
    gridGap: '0 10px',
    alignItems: 'center',
  };

  return (
    <>
      <Button
        variant="white"
        className="btn-white lift mb-1 me-2"
        onClick={handleShow}
        title="Pending invitations"
      >
        Pending invitations
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="lg"
        centered
      >
        <Modal.Header>
          <Modal.Title className="m-0">Pending invitations</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <section className="modal-scroll-content">
          {invitationDetails.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-sm table-hover card-table">
                <thead className="thead-light">
                  <tr>
                    <th>Email</th>
                    <th colSpan={4}>
                      <div style={optionsGrid}>
                        <span>Project</span>
                        <span>Resend Invite</span>
                        <span>Copy URL</span>
                        <span className="text-center">Remove</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invitationDetails.map((invitee, index) => {
                    const inviteURL =
                      invitee.userId !== undefined
                        ? `${WEB_URL}login/invite?id=${invitee._id}`
                        : `${WEB_URL}register?email=${encrypt(
                            JSON.stringify({
                              teamOwnerId,
                              email: invitee.email,
                            })
                          )}`;
                    return (
                      <tr key={index} id={invitee.email}>
                        <td>
                          <span data-id={`${invitee.email}`}>
                            {invitee.email}
                          </span>
                        </td>

                        {invitee.projects ? (
                          <td colSpan={4}>
                            <div style={optionsGrid}>
                              <span>{invitee.projects.title}</span>
                              <span>
                                <Button
                                  variant="white"
                                  size="sm"
                                  data-id={`${invitee.projects.projectId}_${invitee.email}`}
                                  className="btn-white"
                                  onClick={() => {
                                    resendInvite(
                                      invitee._id,
                                      invitee.projects.projectId
                                    );
                                  }}
                                >
                                  Resend
                                </Button>
                              </span>
                              <span>
                                <Button
                                  variant="white"
                                  size="sm"
                                  data-id={`${invitee.projects.projectId}_${invitee.email}`}
                                  className="btn-white clipboard"
                                  onClick={() => {
                                    copyUrl(inviteURL);
                                  }}
                                >
                                  Copy
                                </Button>
                              </span>
                              <span className="text-center">
                                <DeleteInvitation
                                  InvitationDelete={() => {
                                    deleteInvitation({
                                      projectId: invitee.projects.projectId,
                                      inviteId: invitee._id,
                                    });
                                  }}
                                />
                              </span>
                            </div>
                          </td>
                        ) : (
                          <td colSpan={4}>
                            <div style={optionsGrid}>
                              <span className="text-muted"> - </span>
                              <span>
                                <Button
                                  variant="white"
                                  size="sm"
                                  className="btn-white"
                                  onClick={() => {
                                    resendInvite(invitee._id);
                                  }}
                                >
                                  Resend
                                </Button>
                              </span>
                              <span>
                                <Button
                                  variant="white"
                                  size="sm"
                                  className="btn-white clipboard"
                                  onClick={() => {
                                    copyUrl(inviteURL);
                                  }}
                                >
                                  Copy
                                </Button>
                              </span>
                              <span className="text-center">
                                <DeleteInvitation
                                  InvitationDelete={() => {
                                    deleteInvitation({
                                      inviteId: invitee._id,
                                    });
                                  }}
                                />
                              </span>
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            !isLoading && (
              <div className="card">
                <div className="card-body d-flex justify-content-left">
                  No pending invitations
                </div>
              </div>
            )
          )}
        </section>
      </Modal>
      {isLoading && <Loading contentAreaOnly />}
    </>
  );
}
export default InvitationsListModal;
