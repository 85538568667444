import { memo } from 'react';
import PropTypes from 'prop-types';
import { Line, Bar } from 'react-chartjs-2';
import moment from 'moment';
import { DATE_AND_TIME } from '../../constants';

const WorksessionChart = ({ worksessionData, graphType }) => {
  // Labels
  const chartLabels = worksessionData.map((item) =>
    moment(item.checkInDate).format(DATE_AND_TIME.MONTH_AND_DAY)
  );
  // Total Work Session in hours
  const totalWorkSession = worksessionData.map(
    (item) => item.totalWorkSessionSeconds / 3600
  );
  // Active Work Session in hours
  const activeWorkSession = worksessionData.map((item) =>
    item.totalWorkSessionSeconds - item.totalIdleSeconds < 0
      ? 0
      : (item.totalWorkSessionSeconds - item.totalIdleSeconds) / 3600
  );

  const options = {
    cornerRadius: 4,
    elements: {
      point: {
        radius: 0,
      },
    },
    hover: {
      intersect: false,
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        backgroundColor: 'rgba(255,255,255,0.95)',
        titleAlign: 'center',
        titleFont: {
          size: 16,
        },
        titleColor: '#1C3047',
        titleMarginBottom: 10,
        bodyFont: {
          size: 14,
        },
        bodyColor: '#94aac8',
        bodyAlign: 'center',
        bodySpacing: 10,
        borderColor: '#d5d9de',
        borderWidth: 1,
        caretPadding: 10,
        caretSize: 8,
        padding: 15,
        xAlign: 'center',
        yAlign: 'bottom',
        boxWidth: 8,
        boxHeight: 8,
        usePointStyle: true,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: true,
        },
        ticks: {
          color: '#96ACC9',
        },
      },
      y: {
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: true,
          borderDash: [2],
          borderDashOffset: [2],
          color: '#E3EBF6',
        },
        ticks: {
          callback(val) {
            return `${val} hrs`;
          },
          color: '#96ACC9',
          fontSize: 14,
          fontStyle: 'oblique',
        },
      },
    },
  };

  const data = {
    labels: chartLabels,
    datasets: [
      {
        barPercentage: 0.2,
        barRoundness: 3,
        label: 'Total time',
        data: totalWorkSession,
        borderColor: '#0649B3',
        backgroundColor: '#0649B3',
        tension: 0.4,
        borderCapStyle: 'rounded',
        capBezierPoints: true,
        borderWidth: 3,
      },
      {
        barPercentage: 0.2,
        label: ' Active time',
        data: activeWorkSession,
        borderColor: '#d2ddec',
        backgroundColor: '#d2ddec',
        tension: 0.4,
        borderCapStyle: 'rounded',
        capBezierPoints: true,
        borderWidth: 3,
      },
    ],
  };
  return (
    <div>
      {graphType === 'line' && (
        <div>
          <Line data={data} options={options} height={350} />
        </div>
      )}
      {graphType === 'bar' && (
        <div>
          <Bar data={data} options={options} height={350} />
        </div>
      )}
    </div>
  );
};

WorksessionChart.propTypes = {
  worksessionData: PropTypes.array,
  graphType: PropTypes.string,
};

export default memo(WorksessionChart);
