const initialState = {
  requestNotification: [],
  isLoading: true,
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCHING_NOTIFICATION_LIST':
      return {
        ...state,
        isLoading: true,
      };
    case 'SET_NOTIFICATION_LIST':
      return {
        ...state,
        isLoading: false,
        requestNotification: [...state.requestNotification, ...action.payload],
      };
    case 'SET_NOTIFICATION_ALL_LIST':
      return {
        ...state,
        isLoading: false,
        requestNotification: [
          ...state.requestNotification,
          ...action.payload,
        ].filter((notification) => notification.read === false),
      };
    case 'SET_NOTIFICATION_AS_READ':
      return {
        ...state,
        isLoading: false,
        requestNotification: [
          ...state.requestNotification.map((item) => {
            if (action.payload.indexOf(item._id) > -1) {
              item.read = true;
            }

            return item;
          }),
        ],
      };
    case 'FETCH_NOTIFICATION_LIST_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
