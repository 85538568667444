import React, { useState } from 'react';
import {
  Card,
  Accordion,
  useAccordionToggle,
  Row,
  Col,
  Nav,
  Alert,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Avatar from '../Avatar';
import { WeeklyStatusApprovalToggle, ExportOptions } from './index';
import { msToHMS, sToHMSColonSeparated } from '../../helpers';
import { NO_DATA } from '../../constants';
import { NoDataAlert } from '../common';

const ApproveTimesheetContent = ({
  isLoading,
  timesheetDetails,
  statusUpdate,
}) => {
  const [panelIndex, setPanelIndex] = useState();

  const CustomToggle = ({ children, eventKey }) => {
    const customOnClick = useAccordionToggle(eventKey, () => {
      setPanelIndex(eventKey === panelIndex ? null : eventKey);
    });

    const customClass = eventKey === panelIndex ? 'opened' : 'collapsed';

    return (
      <Nav.Link
        className={`${customClass} hd-accordion-button`}
        onClick={customOnClick}
      >
        {children}
      </Nav.Link>
    );
  };

  CustomToggle.propTypes = {
    children: PropTypes.any,
    eventKey: PropTypes.any,
  };

  return (
    <Card>
      <section>
        <ExportOptions timesheetDetails={timesheetDetails} />
        {timesheetDetails.length > 0 ? (
          <Accordion>
            {timesheetDetails.map((week) => {
              const weekDates = week.data[0].wsDetails;
              return (
                <section
                  key={`approve-ts-${week._id}`}
                  className="position-relative"
                >
                  <Card.Header>
                    <Row className="align-items-center">
                      <Col>
                        <CustomToggle
                          eventKey={week._id}
                          className="text-start"
                        >
                          <div className="d-flex">
                            {week.user && (
                              <div className="avatar avatar-xs me-2">
                                <Avatar
                                  firstName={week.user.firstName}
                                  lastName={week.user.lastName}
                                  defaultBg={week.user.bgColor}
                                />
                              </div>
                            )}{' '}
                            <span>
                              {week.user.lastName
                                ? `${week.user.firstName} ${week.user.lastName}`
                                : week.user.firstName}
                            </span>
                            <span className="text-muted mx-2">
                              {' '}
                              ...{' '}
                              {msToHMS(
                                Math.round(week.totalWorkSessionSeconds) * 1000
                              )}
                            </span>
                          </div>
                        </CustomToggle>
                      </Col>
                      <Col className="col-auto p-0">
                        <WeeklyStatusApprovalToggle
                          status={week.status}
                          logs={week.approvalLogs}
                          wsWeekDetails={week.wsWeekDetails}
                          wsWeekStatusUpdate={statusUpdate}
                          weeklySubmissionId={
                            week.approvalId !== null ? week.approvalId : ''
                          }
                          userId={week.user._id}
                        />
                      </Col>
                    </Row>
                  </Card.Header>
                  <Accordion.Collapse eventKey={week._id}>
                    <Card.Body className="p-0">
                      <div className="table-responsive">
                        <table className="table table-sm table-hover card-table time-log-table">
                          <thead className="thead-light">
                            <tr>
                              <th>Project</th>
                              {weekDates.map((day) => (
                                <th
                                  className="text-center"
                                  key={`th-${day.date}`}
                                >
                                  {moment(day.date).format('ddd, MMM DD')}
                                </th>
                              ))}
                              <th className="text-end">Total</th>
                              <th className="text-end">&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {week.data.map((project, pIndex) => {
                              const taskList =
                                project.task.length > 0
                                  ? project.task.filter(
                                      (tItem) =>
                                        '_id' in tItem && 'task' in tItem
                                    )
                                  : [];
                              return (
                                <React.Fragment
                                  key={`${pIndex}-${project.title}`}
                                >
                                  <tr
                                    className={
                                      project.task && project.task.length > 1
                                        ? 'tasks-active'
                                        : 'no-tasks'
                                    }
                                  >
                                    <td>{project.title}</td>
                                    {project.wsDetails.map((day) => (
                                      <td
                                        className="text-center"
                                        key={`td-${day.date}`}
                                      >
                                        {day.totalWorkSessionSeconds === 0
                                          ? '-'
                                          : sToHMSColonSeparated(
                                              day.totalWorkSessionSeconds,
                                              'hh:mm:ss'
                                            )}
                                      </td>
                                    ))}
                                    <td className="text-end">
                                      {sToHMSColonSeparated(
                                        project.totalWorkSessionSeconds,
                                        'hh:mm:ss'
                                      )}
                                    </td>
                                    <td className="text-end">
                                      {week.status !== 'approve' && (
                                        <Link
                                          to={{
                                            pathname: `/team/worksession/${week.user._id}`,
                                            search: `?startDate=${moment(
                                              week.wsWeekDetails.startDate
                                            ).format(
                                              'YYYY-MM-DD'
                                            )}&endDate=${moment(
                                              week.wsWeekDetails.endDate
                                            ).format('YYYY-MM-DD')}`,
                                          }}
                                          className="btn btn-link btn-sm"
                                        >
                                          <i className="mdi mdi-pencil me-1" />
                                          Edit
                                        </Link>
                                      )}
                                    </td>
                                  </tr>
                                  {taskList.length > 0 && (
                                    <>
                                      {taskList.map((taskItem, tIndex) => {
                                        const taskTotalTime =
                                          taskItem.wsDetails.reduce(
                                            (pValue, cValue) =>
                                              pValue +
                                              cValue.totalWorkSessionSeconds,
                                            0
                                          );
                                        return (
                                          <tr
                                            className="tasksRow"
                                            key={`${tIndex}-${taskItem._id}`}
                                          >
                                            <td>
                                              <small className="ps-3">
                                                {taskItem.task}
                                              </small>
                                            </td>
                                            {taskItem.wsDetails.map(
                                              (tasksWs, tWsIndex) => (
                                                <td
                                                  className="text-center"
                                                  key={`${tWsIndex}-${tasksWs.date}`}
                                                >
                                                  <small>
                                                    {tasksWs.totalWorkSessionSeconds ===
                                                    0
                                                      ? '-'
                                                      : sToHMSColonSeparated(
                                                          tasksWs.totalWorkSessionSeconds,
                                                          'hh:mm:ss'
                                                        )}
                                                  </small>
                                                </td>
                                              )
                                            )}
                                            <td className="text-end">
                                              <small>
                                                {sToHMSColonSeparated(
                                                  taskTotalTime,
                                                  'hh:mm:ss'
                                                )}
                                              </small>
                                            </td>
                                            <td className="text-end">&nbsp;</td>
                                          </tr>
                                        );
                                      })}
                                      <br />
                                    </>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                        <hr className="m-0" />
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </section>
              );
            })}
          </Accordion>
        ) : (
          !isLoading && <NoDataAlert content={NO_DATA.WORKSESSION} />
        )}
      </section>
    </Card>
  );
};

ApproveTimesheetContent.propTypes = {
  isLoading: PropTypes.bool,
  timesheetDetails: PropTypes.array,
  statusUpdate: PropTypes.func,
};

export default ApproveTimesheetContent;
