import { Component } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Card, ListGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../DashboardLayout';
import Loader from '../../components/Loader';
import AutoCheckout from '../../components/settings/AutoCheckout';
import OverTime from '../../components/settings/OverTime';
import ScheduleSettings from '../../components/settings/ShiftSettings';
import TimesheetApproval from '../../components/settings/TimesheetApproval';
import NotificationSettings from '../../components/settings/NotificationSettings';
import MobileTrackingApproval from '../../components/settings/mobileTrackingApproval';
import ManageActiveSessions from '../../components/settings/ManageActiveSessions';
import SettingsTabs from '../../components/settings/Tabs';
import { setUserRole } from '../../actions/user';
import InvoiceSettings from '../../components/settings/Invoice';
import DeleteModal from '../../components/DeleteModal';
import {
  enableTimesheetApproval,
  disableTimesheetApproval,
  enableOvertime,
  disableOvertime,
  enableSchedule,
  disableSchedule,
  enableInvoice,
  disableInvoice,
  enableTaskNotification,
  disableTaskNotification,
  enableManageActiveSessions,
  disableManageActiveSessions,
} from '../../actions';

import { teamService, settingsService, userService } from '../../services';

import {
  GENERAL,
  LOGO_SIZE,
  LOCAL_STORAGE_USER_DATA,
  SETTINGS_TEAMS,
  SETTINGS_INVOICE,
} from '../../constants';
import { updateLocalStorage, getUserData } from '../../helpers';

class FeatureSettings extends Component {
  pageHeading = {
    preTitle: 'Settings',
    title: 'General',
  };

  /* properties for delete confirmation modal */
  modalProps = {
    title: 'Are you sure you want to delete the logo?',
    size: 'sm',
    style: 'width:500px',
    body: false,
  };

  userdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));

  state = {
    defaultTeam:
      this.userdata.defaultTeam != null
        ? `${this.userdata.defaultTeam.firstName} ${this.userdata.defaultTeam.lastName}`
        : '',
    defaultChecked: this.userdata.defaultTeam.isDefault,
    autoCheckOut: false,
    autoCheckOutMinutes: '',
    weeklyOTlimit: 40,
    OTpayRate: 1.5,
    overTimeEnable: getUserData('isOverTimeEnabled'),
    timeSheetApproval: getUserData('isTimesheetApproval'),
    isExcludeInvitation: getUserData('isExcludeInvitation'),
    isMobileTracking: getUserData('isMobileTracking'),
    SchedulingEnabled: getUserData('isSchedulingEnabled'),
    invoiceEnabled: getUserData('isInvoiceEnabled'),
    isTaskNotificationEnabled:
      getUserData('isEmailNotificationEnabled') || false,
    isSessionManagementEnabled: getUserData('isSessionMgmtEnabled'),
    latenessLimit: 15,
    toleranceLimit: 15,
    isLoading: false,
    teamList: [],
    memberList: '',
    role:
      this.userdata.defaultTeam != null
        ? this.userdata.defaultTeam.role
        : 'creator',
    image: '',
    showDelModal: false,
    dueDateEmailEnabled: false,
    ndaysBeforeDuedate: 1,
    mailOnTaskCreation: false,
  };

  componentDidMount() {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    this.getMembers(localStorage.getItem('teamOwnerId'));
    if (
      this.userdata.defaultTeam &&
      this.userdata.defaultTeam.role === 'creator'
    ) {
      this.getAutoCheckOutSettings();
      this.getSettings();
    }
    this.getTeams();
  }

  /* get the auto checkout settings */
  getAutoCheckOutSettings = () => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));

    settingsService
      .get_settings()
      .then((response) => {
        if (Object.keys(response.data).length > 0) {
          this.setState((state) => ({
            ...state,
            isLoading: false,
            autoCheckOut:
              response.data.autoCheckOut === null
                ? false
                : response.data.autoCheckOut,
            autoCheckOutMinutes:
              response.data.autoCheckOutMinutes === 0
                ? ''
                : response.data.autoCheckOutMinutes,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            isLoading: false,
          }));
        }
      })
      .catch((errResp) => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  /* Get settings */
  getSettings = () => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    settingsService
      .get_settings()
      .then((response) => {
        if (
          response.data.overTime !== undefined &&
          response.data.overTime !== null
        ) {
          this.setState((state) => ({
            ...state,
            overTimeEnable: getUserData('isOverTimeEnabled'),
            weeklyOTlimit:
              response.data.overTime.weeklyOvertimeLimitHours === null
                ? '40'
                : response.data.overTime.weeklyOvertimeLimitHours,
            OTpayRate:
              response.data.overTime.overTimePayRate === null
                ? '1.5'
                : response.data.overTime.overTimePayRate,
          }));
        }

        if (
          response.data.scheduling !== undefined &&
          response.data.scheduling !== null
        ) {
          this.setState((state) => ({
            ...state,
            SchedulingEnabled: getUserData('isSchedulingEnabled'),
            latenessLimit:
              response.data.scheduling.lateThreshold === null
                ? '15'
                : response.data.scheduling.lateThreshold,
            toleranceLimit:
              response.data.scheduling.shiftTolerance === null
                ? '15'
                : response.data.scheduling.shiftTolerance,
          }));
        }

        if (
          response.data.emailNotifications !== undefined &&
          response.data.emailNotifications !== null
        ) {
          this.setState((state) => ({
            ...state,
            isTaskNotificationEnabled: getUserData(
              'isEmailNotificationEnabled'
            ),
            dueDateEmailEnabled:
              response.data.emailNotifications.dueDateEmailEnabled,
            ndaysBeforeDuedate:
              response.data.emailNotifications.ndaysBeforeDuedate,
            mailOnTaskCreation:
              response.data.emailNotifications.mailOnTaskCreation,
          }));
        }

        if (
          response.data.invoice !== undefined &&
          response.data.invoice !== null
        ) {
          this.setState((state) => ({
            ...state,
            invoiceEnabled: getUserData('isInvoiceEnabled'),
            companyLogo: response.data.invoice.companyLogo,
            companyName: response.data.invoice.companyName,
            billingAddress: response.data.invoice.billingAddress,
            taxRate: response.data.invoice.taxRate,
            taxNo: response.data.invoice.taxNo,
            teamOwnerId: localStorage.getItem('teamOwnerId'),
          }));
        }

        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      })
      .catch((errResp) => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  /* Update current team as default team */
  handleUpdateDefault = () => {
    const { defaultChecked } = this.state;

    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));

    teamService
      .update_default_team({
        teamOwnerId: !defaultChecked ? localStorage.getItem('teamOwnerId') : '',
      })
      .then((response) => {
        if (defaultChecked) {
          toast.success(SETTINGS_TEAMS.UPDATE_TEAM_UNSET);
        } else {
          toast.success(SETTINGS_TEAMS.UPDATE_TEAM_SET);
        }

        this.setState((state) => ({
          ...state,
          isLoading: false,
          defaultChecked: !defaultChecked,
        }));

        /* update default team in local storage */
        const { defaultTeam } = this.userdata;
        defaultTeam.isDefault = !defaultChecked;
        updateLocalStorage({
          defaultTeam,
        });
      })
      .catch((error) => {
        toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  /* update Schedule settings value */
  handleUpdateNotificationSetting = (formData) => {
    formData.isNotificationEnabled = formData.isTaskNotificationEnabled;
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const formValues = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      ...formData,
    };
    if (!formData.isTaskNotificationEnabled) {
      formValues.dueDateEmailEnabled = false;
      formValues.ndaysBeforeDuedate = 1;
      formValues.mailOnTaskCreation = false;
    }

    settingsService
      .update_notification_setting(formValues)
      .then((res) => {
        if (formData.isTaskNotificationEnabled) {
          toast.success(SETTINGS_TEAMS.UPDATE_NOTIFICATION_SETTING_SET);
        } else {
          toast.success(SETTINGS_TEAMS.UPDATE_NOTIFICATION_SETTING_UNSET);
        }
        if (res.status) {
          userService
            .get_account_details({
              teamOwnerId: localStorage.getItem('teamOwnerId'),
            })
            .then((response) => {
              if (response.data) {
                updateLocalStorage({
                  isEmailNotificationEnabled:
                    response.data.isEmailNotificationEnabled,
                  mailOnTaskCreation: response.data.mailOnTaskCreation,
                });
              }
            });
        }

        this.setState((state) => ({
          ...state,
          isLoading: false,
          SchedulingEnabled: formData.ScheduleSettings === true,
          LateLimit: formData.LateLimit,
          ShiftTolerance: formData.ShiftTolerance,
        }));
      })

      .catch((err) => {
        toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  handleNotificationSettingToggle = (toggleValue) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const { handleEnableNotification, handleDisableNotification } = this.props;

    if (toggleValue === true) {
      handleDisableNotification();
      const formValues = {
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        isTaskNotificationEnabled: false,
        dueDateEmailEnabled: false,
        ndaysBeforeDuedate: 1,
        mailOnTaskCreation: false,
      };
      this.handleUpdateNotificationSetting(formValues);
    } else {
      handleEnableNotification();
      this.setState({
        dueDateEmailEnabled: true,
        ndaysBeforeDuedate: 1,
        mailOnTaskCreation: true,
        isTaskNotificationEnabled: true,
      });
      const formValues = {
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        isTaskNotificationEnabled: true,
        dueDateEmailEnabled: true,
        ndaysBeforeDuedate: 1,
        mailOnTaskCreation: true,
      };
      this.handleUpdateNotificationSetting(formValues);
    }

    this.setState((state) => ({
      ...state,
      isLoading: false,
      isTaskNotificationEnabled: toggleValue === false,
    }));
  };

  /* handle auto checkout toggle */
  handleAutoCheckoutToggle = (toggleValue) => {
    if (toggleValue === true) {
      const fieldValues = { autoCheckOut: false, autoCheckOutMinutes: 0 };
      this.handleUpdateAutoCheckout(fieldValues);
    }

    this.setState((state) => ({
      ...state,
      isLoading: false,
      autoCheckOut: toggleValue === false,
    }));
  };

  /* update auto checkout value */
  handleUpdateAutoCheckout = (formData) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    if (formData.autoCheckOut === false) {
      formData.autoCheckOutMinutes = 0;
    }

    settingsService
      .update_autocheckout(formData)
      .then(() => {
        if (formData.autoCheckOut === false) {
          toast.success(SETTINGS_TEAMS.UPDATE_AUTOCHECKOUT_UNSET);
        } else {
          toast.success(SETTINGS_TEAMS.UPDATE_AUTOCHECKOUT_SET);
        }

        this.setState((state) => ({
          ...state,
          isLoading: false,
          autoCheckOut: formData.autoCheckOut,
          autoCheckOutMinutes:
            formData.autoCheckOutMinutes === 0
              ? ''
              : formData.autoCheckOutMinutes,
        }));
      })
      .catch((err) => {
        toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  /* update OverTime value */
  handleUpdateOverTime = (formData) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const formValues = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      OverTimeSettings: formData.OverTimeSettings,
      OTWeeklyLimit: formData.WeeklyLimit,
      OTPayRate: formData.PayRate,
    };

    settingsService
      .update_overtime(formValues)
      .then((res) => {
        if (formData.OverTimeSettings === true) {
          toast.success(SETTINGS_TEAMS.UPDATE_OVERTIME_SET);
        } else {
          toast.success(SETTINGS_TEAMS.UPDATE_OVERTIME_UNSET);
        }
        if (res.status) {
          userService
            .get_account_details({
              teamOwnerId: localStorage.getItem('teamOwnerId'),
            })
            .then((response) => {
              if (response.data) {
                updateLocalStorage({
                  isOverTimeEnabled: response.data.isOverTimeEnabled,
                });
              }
            });
        }

        this.setState((state) => ({
          ...state,
          isLoading: false,
          overTimeEnable: formData.OverTimeSettings === true,
          weeklyOTlimit: formData.WeeklyLimit,
          OTpayRate: formData.PayRate,
        }));
      })

      .catch((err) => {
        toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  /* update Schedule settings value */
  handleUpdateSchedule = (formData) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const formValues = {
      lateThreshold: formData.LateLimit,
      shiftTolerance: formData.ShiftTolerance,
      schedulingEnabled: formData.ScheduleSettings,
      teamOwnerId: localStorage.getItem('teamOwnerId'),
    };

    settingsService
      .update_schedule(formValues)
      .then((res) => {
        if (formData.ScheduleSettings === true) {
          toast.success(SETTINGS_TEAMS.UPDATE_SCHEDULE_SET);
        } else {
          toast.success(SETTINGS_TEAMS.UPDATE_SCHEDULE_UNSET);
        }
        if (res.status) {
          userService
            .get_account_details({
              teamOwnerId: localStorage.getItem('teamOwnerId'),
            })
            .then((response) => {
              if (response.data) {
                updateLocalStorage({
                  isSchedulingEnabled: response.data.isSchedulingEnabled,
                });
              }
            });
        }

        this.setState((state) => ({
          ...state,
          isLoading: false,
          SchedulingEnabled: formData.ScheduleSettings === true,
          LateLimit: formData.LateLimit,
          ShiftTolerance: formData.ShiftTolerance,
        }));
      })

      .catch((err) => {
        toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  handleOverTimeToggle = (toggleValue) => {
    const { enableOT, disableOT } = this.props;
    if (toggleValue === true) {
      disableOT();
      const formValues = {
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        OverTimeSettings: false,
        OTWeeklyLimit: 0,
        OTPayRate: 0,
      };
      this.handleUpdateOverTime(formValues);
    } else {
      enableOT();
    }

    this.setState((state) => ({
      ...state,
      isLoading: false,
      overTimeEnable: toggleValue === false,
    }));
  };

  handleScheduleToggle = (toggleValue) => {
    const { enableSch, disableSch } = this.props;

    if (toggleValue === true) {
      disableSch();
      const formValues = {
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        ScheduleSettings: false,
        LateLimit: 0,
        ShiftTolerance: 0,
      };
      this.handleUpdateSchedule(formValues);
    } else {
      enableSch();
    }

    this.setState((state) => ({
      ...state,
      isLoading: false,
      SchedulingEnabled: toggleValue === false,
    }));
  };

  UpdateMobileTrackingApproval = (formData) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const data = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      status: !formData,
      settingField: 'MobileTracking',
    };
    settingsService
      .settings_approval(data)
      .then((res) => {
        if (res.status) {
          userService
            .get_account_details({
              teamOwnerId: localStorage.getItem('teamOwnerId'),
            })
            .then((response) => {
              if (response.data) {
                updateLocalStorage({
                  isMobileTracking: response.data.isMobileTracking,
                });
              }
            });
        }
        if (formData === false) {
          toast.success(SETTINGS_TEAMS.MOBILE_APPROVAL_SET);
        } else {
          toast.success(SETTINGS_TEAMS.MOBILE_APPROVAL_UNSET);
        }

        this.setState((state) => ({
          ...state,
          isLoading: false,
          isMobileTracking: formData === false,
        }));
      })
      .catch((err) => {
        toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  handleManageOngoingSessions = () => {
    const { isSessionManagementEnabled } = this.state;
    const { enableManageOngoingSessions, disableManageOngoingSessions } =
      this.props;
    this.setState((state) => ({
      ...state,
      isLoading: true,
      isSessionManagementEnabled: !isSessionManagementEnabled,
    }));
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      sessionMgmt: !isSessionManagementEnabled,
      // access: 'full_manager',
    };

    settingsService
      .manage_ongoing_sessions(params)
      .then((res) => {
        if (res.status) {
          userService
            .get_account_details({
              teamOwnerId: localStorage.getItem('teamOwnerId'),
            })
            .then((response) => {
              if (response.data) {
                updateLocalStorage({
                  isSessionMgmtEnabled: response.data.isSessionMgmtEnabled,
                });
                if (response.data.isSessionMgmtEnabled) {
                  toast.success('Manage ongoing sessions enabled');
                  enableManageOngoingSessions();
                } else {
                  toast.success('Manage ongoing sessions disabled');
                  disableManageOngoingSessions();
                }
              }
            });
        }
      })
      .finally(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  handleUpdateTimesheetApproval = (formData) => {
    const { enableTsApproval, disableTsApproval } = this.props;
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const data = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      status: !formData,
      settingField: 'TimesheetApproval',
    };
    settingsService
      .settings_approval(data)
      .then((res) => {
        if (res.status) {
          userService
            .get_account_details({
              teamOwnerId: localStorage.getItem('teamOwnerId'),
            })
            .then((response) => {
              if (response.data) {
                updateLocalStorage({
                  isTimesheetApproval: response.data.isTimesheetApproval,
                });
              }
            });
        }
        if (formData === true) {
          toast.success(SETTINGS_TEAMS.TIMESHEET_APPROVAL_UNSET);
          disableTsApproval();
        } else {
          toast.success(SETTINGS_TEAMS.TIMESHEET_APPROVAL_SET);
          enableTsApproval();
        }

        this.setState((state) => ({
          ...state,
          isLoading: false,
          timeSheetApproval: formData === false,
        }));
      })
      .catch((err) => {
        toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  handleExcludeInviteProcess = (formData) => {
    const { enableTsApproval, disableTsApproval } = this.props;
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const data = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      status: !formData,
      settingField: 'ExcludeInvitation',
    };
    settingsService
      .settings_approval(data)
      .then((res) => {
        if (res.status) {
          userService
            .get_account_details({
              teamOwnerId: localStorage.getItem('teamOwnerId'),
            })
            .then((response) => {
              if (response.data) {
                updateLocalStorage({
                  isExcludeInvitation: response.data.isExcludeInvitation,
                });
              }
            });
        }
        if (formData.excludeInvitationProcess === true) {
          toast.success(SETTINGS_TEAMS.INVITATION_APPROVAL_UNSET);
          disableTsApproval();
        } else {
          toast.success(SETTINGS_TEAMS.INVITATION_APPROVAL_SET);
          enableTsApproval();
        }

        this.setState((state) => ({
          ...state,
          isLoading: false,
          isExcludeInvitation: formData === false,
        }));
      })
      .catch((err) => {
        toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  /* refresh components after team change */
  handleTeamSelection = (formData) => {
    localStorage.setItem('teamOwnerId', formData.teamOwners);
    toast.success(SETTINGS_TEAMS.TEAM_CHANGE);
    formData.teamDetails[0].isDefault = false;
    updateLocalStorage({ defaultTeam: formData.teamDetails[0] });

    const { dispatch } = this.props;
    dispatch(
      setUserRole({
        role: formData.teamDetails[0].role,
        ownerId: formData.teamOwners,
      })
    );

    this.setState((state) => ({
      ...state,
      defaultTeam: `${formData.teamDetails[0].firstName} ${formData.teamDetails[0].lastName}`,
      defaultChecked: false,
      autoCheckOut: false,
      autoCheckOutMinutes: '',
      isLoading: false,
      memberList: [],
      role: formData.teamDetails[0].role,
    }));

    if (formData.teamDetails[0].role === 'creator') {
      this.getAutoCheckOutSettings();
    }
    delete formData.teamDetails;
    this.getMembers(formData.teamOwners);
  };

  /* Data for listing teams in change team */
  getTeams = () => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));

    teamService
      .get_teams_data()
      .then((resp) => {
        if (resp.data.length > 1) {
          this.setState((state) => ({
            ...state,
            teamList: resp.data,
            isLoading: false,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            teamList: [],
            isLoading: false,
          }));
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  getMembers = (ownerId) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    teamService
      .get_team_members_data({
        teamOwnerId: ownerId,
        summaryList: true,
        filter: '{ "memberStatus": "active" }',
      })
      .then((response) => {
        this.setState((state) => ({
          ...state,
          memberList:
            response && 'data' in response ? response.data.members : [],
          isLoading: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          memberList: [],
          isLoading: false,
        }));
      });
  };

  /* update settings value */
  handleUpdateInvoice = (formData) => {
    this.setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const formValues = {
      invoiceEnabled: formData.invoiceEnabled,
      companyName: formData.companyNameField,
      billingAddress: formData.billingAddressField,
      taxRate: formData.taxRateField,
      taxNo: formData.taxNoField,
      teamOwnerId: localStorage.getItem('teamOwnerId'),
    };
    if (!formValues.taxRate) {
      delete formValues.taxRate;
    }
    if (!formValues.taxNo) {
      delete formValues.taxNo;
    }
    if (!formValues.companyName) {
      delete formValues.companyName;
    }
    if (!formValues.billingAddress) {
      delete formValues.billingAddress;
    }
    const { image } = this.state;
    if (image) {
      formValues.companyLogo = image;
    }
    settingsService
      .update_invoice(formValues)
      .then((res) => {
        if (formData.invoiceEnabled === true) {
          if (Object.keys(formData).length === 2) {
            toast.success(SETTINGS_INVOICE.SET);
          } else {
            toast.success(SETTINGS_INVOICE.SAVE);
          }
        } else {
          toast.success(SETTINGS_INVOICE.UNSET);
        }
        if (res.status) {
          userService
            .get_account_details({
              teamOwnerId: localStorage.getItem('teamOwnerId'),
            })
            .then((response) => {
              if (response.data) {
                updateLocalStorage({
                  isInvoiceEnabled: response.data.isInvoiceEnabled,
                });
              }
            });
        }

        this.setState((state) => ({
          ...state,
          isLoading: false,
          invoiceEnabled: formData.invoiceEnabled === true,
          companyName: formData.companyNameField,
          companyLogo: formData.companyLogo,
          billingAddress: formData.billingAddressField,
          taxRate: formData.taxRateField,
          taxNo: formData.taxNoField,
        }));
      })

      .catch((err) => {
        toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  handleInvoiceToggle = (toggleValue) => {
    const { enableInv, disableInv } = this.props;
    const formValues = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
    };
    if (toggleValue === true) {
      disableInv();
      formValues.invoiceEnabled = false;
    } else {
      formValues.invoiceEnabled = true;
      enableInv();
    }
    this.handleUpdateInvoice(formValues);
    this.setState((state) => ({
      ...state,
      isLoading: false,
      invoiceEnabled: toggleValue === false,
    }));
  };

  handleImgUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      const { files } = event.target;
      if (!files[0].name.match(/.(jpg|jpeg|png)$/i)) {
        toast.error(GENERAL.AVATAR_TYPE);
        return;
      }
      if (files[0].size > LOGO_SIZE) {
        toast.error(GENERAL.LOGO_SIZE);
        return;
      }
      reader.readAsDataURL(files[0]);
      this.setState((state) => ({
        ...state,
        isSubmitting: true,
      }));

      reader.onload = (e) => {
        /* resize the image */
        const img = document.createElement('img');
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          const { width, height } = img;
          canvas.width = width;
          canvas.height = height;
          ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          const dataUrl = canvas.toDataURL('image/png');
          /* set image in state for upload */
          this.setState((state) => ({
            ...state,
            image: dataUrl,
          }));
        };
        img.src = e.target.result;
      }; /* end file reader */
    } /* end if */
  }; /* end handle img */

  /* show delete confirmation modal */
  showDeleteModal = () => {
    this.setState((state) => ({
      ...state,
      showDelModal: true,
    }));
  };

  /* hide delete confirmation modal */
  hideDeleteModal = () => {
    this.setState((state) => ({
      ...state,
      showDelModal: false,
    }));
  };

  deleteCompanyLogo = () => {
    this.hideDeleteModal();
    this.setState((state) => ({
      ...state,
      isSubmitting: true,
    }));
    settingsService
      .delete_company_logo(localStorage.getItem('teamOwnerId'))
      .then((res) => {
        if (res.status) {
          toast.success(SETTINGS_INVOICE.DELETE_LOGO);
        }
        this.setState((state) => ({
          ...state,
          isSubmitting: false,
          image: '',
          companyLogo: '',
        }));
      })
      .catch((err) => {
        toast.error(SETTINGS_TEAMS.UPDATE_ERROR);
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  render() {
    const {
      autoCheckOut,
      autoCheckOutMinutes,
      isMobileTracking,
      timeSheetApproval,
      overTimeEnable,
      SchedulingEnabled,
      weeklyOTlimit,
      latenessLimit,
      toleranceLimit,
      OTpayRate,
      isLoading,
      invoiceEnabled,
      companyName,
      companyLogo,
      billingAddress,
      taxRate,
      taxNo,
      showDelModal,
      isTaskNotificationEnabled,
      dueDateEmailEnabled,
      ndaysBeforeDuedate,
      mailOnTaskCreation,
      isSessionManagementEnabled,
    } = this.state;

    return (
      <Layout pageHeading={this.pageHeading}>
        {isLoading && <Loader contentAreaOnly />}
        <DeleteModal
          modalProps={this.modalProps}
          showModal={showDelModal}
          handleHideModal={this.hideDeleteModal}
          handleDelete={this.deleteCompanyLogo}
        />
        <Row className="justify-content-center">
          <Col className="col-12 col-lg-10 col-xl-8">
            <SettingsTabs selectedTab={this.pageHeading.title} />
            <div className="tab-content">
              <div className="tab-pane show active">
                <Card>
                  <Card.Header>
                    <strong>Features</strong>
                  </Card.Header>
                  <Card.Body>
                    <ListGroup variant="flush">
                      <ListGroup.Item className="pt-0">
                        <MobileTrackingApproval
                          isEnabledMobileTracking={isMobileTracking}
                          UpdateMobileTrackingApproval={
                            this.UpdateMobileTrackingApproval
                          }
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <AutoCheckout
                          checkoutSetting={autoCheckOut}
                          checkoutMinutes={autoCheckOutMinutes}
                          handleUpdate={this.handleUpdateAutoCheckout}
                          handleAutoCheckOutToggle={
                            this.handleAutoCheckoutToggle
                          }
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <TimesheetApproval
                          isTsheeApprovalEnabled={timeSheetApproval}
                          handleUpdateTSApproval={
                            this.handleUpdateTimesheetApproval
                          }
                        />
                      </ListGroup.Item>
                      {/*           <ListGroup.Item>
                        <AddingExistingTMApproval
                          isEnabledExcludeInvitation={isExcludeInvitation}
                          handleExcludeInviteProcess={
                            this.handleExcludeInviteProcess
                          }
                        />
                      </ListGroup.Item> */}
                      <ListGroup.Item className="pb-3">
                        <OverTime
                          OverTimeSettings={overTimeEnable}
                          OTweeklyLimit={weeklyOTlimit}
                          OTpayRate={OTpayRate}
                          handleUpdate={this.handleUpdateOverTime}
                          handleOTtoggle={this.handleOverTimeToggle}
                        />
                      </ListGroup.Item>
                      <ListGroup.Item className="pb-3">
                        <ScheduleSettings
                          ScheduleSettings={SchedulingEnabled}
                          LateLimit={latenessLimit}
                          ShiftTolerance={toleranceLimit}
                          handleUpdate={this.handleUpdateSchedule}
                          handleScheduletoggle={this.handleScheduleToggle}
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <NotificationSettings
                          isTaskNotificationEnabled={isTaskNotificationEnabled}
                          dueDateEmailEnabled={dueDateEmailEnabled}
                          ndaysBeforeDuedate={ndaysBeforeDuedate}
                          mailOnTaskCreation={mailOnTaskCreation}
                          handleNotificationSettingToggle={
                            this.handleNotificationSettingToggle
                          }
                          handleUpdateNotificationSetting={
                            this.handleUpdateNotificationSetting
                          }
                        />
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <ManageActiveSessions
                          isSessionManagementEnabled={
                            isSessionManagementEnabled
                          }
                          handleManageOngoingSessions={
                            this.handleManageOngoingSessions
                          }
                        />
                      </ListGroup.Item>
                      <ListGroup.Item className="pb-3">
                        <InvoiceSettings
                          invoiceEnabled={invoiceEnabled}
                          companyName={companyName}
                          companyLogo={companyLogo}
                          billingAddress={billingAddress}
                          taxRate={taxRate}
                          taxNo={taxNo}
                          handleUpdate={this.handleUpdateInvoice}
                          handleInvoiceToggle={this.handleInvoiceToggle}
                          handleImgUpload={this.handleImgUpload}
                          showDeleteModal={this.showDeleteModal}
                        />
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  isTimesheetApproval: state.timesheetApproval,
});

const mapDispatchToProps = () => ({
  enableTsApproval: enableTimesheetApproval,
  disableTsApproval: disableTimesheetApproval,
  enableOT: enableOvertime,
  disableOT: disableOvertime,
  enableSch: enableSchedule,
  disableSch: disableSchedule,
  enableInv: enableInvoice,
  disableInv: disableInvoice,
  handleEnableNotification: enableTaskNotification,
  handleDisableNotification: disableTaskNotification,
  enableManageOngoingSessions: enableManageActiveSessions,
  disableManageOngoingSessions: disableManageActiveSessions,
});

FeatureSettings.propTypes = {
  dispatch: PropTypes.any,
  enableTsApproval: PropTypes.func,
  disableTsApproval: PropTypes.func,
  enableOT: PropTypes.func,
  disableOT: PropTypes.func,
  enableSch: PropTypes.func,
  disableSch: PropTypes.func,
  enableInv: PropTypes.func,
  disableInv: PropTypes.func,
  handleEnableNotification: PropTypes.func,
  handleDisableNotification: PropTypes.func,
  enableManageOngoingSessions: PropTypes.func,
  disableManageOngoingSessions: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps())(FeatureSettings);
