import { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { Formik, Field, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { DateAndTimePickerField } from '../common';

const WorkSessionEditOnApprovalModal = ({
  wsItem,
  editOnApprovalDisplay,
  handleEditOnApprovalModal,
  handleWorkSessionEditRequest,
  allBreaksList,
}) => {
  const handleClose = () => handleEditOnApprovalModal(false);
  const [checkInDate, setCheckInDate] = useState(wsItem.newCheckInDate);
  const [checkOutDate, setCheckOutDate] = useState(wsItem.newCheckOutDate);
  const checkInDateISOformat = new Date(wsItem.newCheckInDate).toISOString();
  const checkOutDateISOformat = new Date(wsItem.newCheckOutDate).toISOString();
  const [sessionBreaks] = useState(
    wsItem.newBreak.map((item) => {
      const breakDetails = {
        type: item.type !== null ? item.type._id : 'other',
        startTime: moment(item.startTime).format('YYYY-MM-DD HH:mm:ss'),
        endTime: item.endTime
          ? moment(item.endTime).format('YYYY-MM-DD HH:mm:ss')
          : '',
      };
      return breakDetails;
    })
  );

  const handleUpdateWorkSession = (formdata) => {
    if (Date.parse(checkOutDate) <= Date.parse(checkInDate)) {
      toast.error('Check in time is greater or equal to check out time');
    } else {
      const params = {
        workSessionId: wsItem.workSessionId,
        requestId: wsItem._id,
        status: 'approve',
        updateRemark: formdata.remark,
        workSessionDetails: {
          checkInDate: moment(checkInDate).format('YYYY-MM-DD HH:mm:ss'),
          checkOutDate: moment(checkOutDate).format('YYYY-MM-DD HH:mm:ss'),
        },
      };

      if (formdata.breaks && formdata.breaks.length > 0) {
        params.workSessionDetails.break = formdata.breaks.map((wsBreak) => {
          const selectedBreak = {
            type: wsBreak.type === 'other' ? null : wsBreak.type,
            startTime: wsBreak.startTime,
            endTime: wsBreak.endTime,
          };
          return selectedBreak;
        });
      }

      handleWorkSessionEditRequest(params);
    }
  };

  return (
    <Modal show={editOnApprovalDisplay} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title className="m-0">Edit Worksession</Modal.Title>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={handleClose}
        />
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{
          checkInDate,
          checkOutDate,
          remark: wsItem.remark || '',
          breaks: sessionBreaks,
        }}
        validationSchema={Yup.object({
          checkInDate: Yup.date().required('Please enter check in information'),
          checkOutDate: Yup.date().required(
            'Please enter check out information'
          ),
        }).shape({
          breaks: Yup.array().of(
            Yup.object().shape({
              type: Yup.string().required('Please select a break').nullable(),
              startTime: Yup.date().required('Required'),
              endTime: Yup.date().required('Required'),
            })
          ),
        })}
        onSubmit={(values) => {
          handleUpdateWorkSession(values);
        }}
      >
        {(formikprops) => (
          <Form onSubmit={formikprops.handleSubmit}>
            <Modal.Body>
              <section>
                <Form.Group controlId="memberName">
                  <Form.Label>Team Member</Form.Label>
                  <h3>{`${wsItem.user.firstName} ${
                    wsItem.user.lastName || ''
                  }`}</h3>
                </Form.Group>
                <DateAndTimePickerField
                  label="Check In"
                  handleDateSelect={setCheckInDate}
                  name="checkInDate"
                  defaultValue={checkInDateISOformat}
                  defaultValueDisplay
                />
                <DateAndTimePickerField
                  label="Check Out"
                  handleDateSelect={setCheckOutDate}
                  name="checkOutDate"
                  defaultValue={checkOutDateISOformat}
                  defaultValueDisplay
                />
                <h4 className="my-3">Manage Breaks</h4>
                {formikprops.values.breaks &&
                  formikprops.values.breaks.length > 0 && (
                    <Row className="mb-2">
                      <Col md={5}>Break Type</Col>
                      <Col md={3}>Start Time</Col>
                      <Col md={3}>End Time</Col>
                      <Col md={1} />
                    </Row>
                  )}

                <FieldArray
                  name="breaks"
                  render={(arrayHelpers) => (
                    <div>
                      {formikprops.values.breaks &&
                        formikprops.values.breaks.length > 0 &&
                        formikprops.values.breaks.map((breakItem, index) => (
                          <Row key={index} className="mb-2">
                            <Col md={5}>
                              <Form.Group
                                controlId="selectBreak"
                                className="mb-2"
                              >
                                <Field
                                  as="select"
                                  name={`breaks.${index}.type`}
                                  className="form-select form-select-sm"
                                  placeholder="Select break"
                                  onChange={(event) => {
                                    formikprops.setFieldValue(
                                      `breaks.${index}.type`,
                                      event.target.value
                                    );
                                  }}
                                >
                                  <option>Select Break</option>
                                  {allBreaksList.map((bItem) => (
                                    <option
                                      value={bItem._id}
                                      selected={breakItem.type === bItem._id}
                                      key={bItem._id}
                                    >
                                      {bItem.name}
                                    </option>
                                  ))}
                                  <option
                                    value="other"
                                    selected={breakItem.type === 'other'}
                                  >
                                    Other
                                  </option>
                                </Field>
                                <ErrorMessage
                                  name={`breaks.${index}.type`}
                                  render={(msg) => (
                                    <small className="text-danger">{msg}</small>
                                  )}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={3}>
                              <Field
                                component={Flatpickr}
                                name={`breaks.${index}.startTime`}
                                placeholder="Start Time"
                                onChange={([date]) => {
                                  formikprops.setFieldValue(
                                    `breaks.${index}.startTime`,
                                    moment(date).format('YYYY-MM-DD HH:mm:ss')
                                  );
                                }}
                                value={
                                  breakItem.startTime &&
                                  new Date(breakItem.startTime).toISOString()
                                }
                                options={{
                                  defaultDate:
                                    breakItem.startTime &&
                                    new Date(breakItem.startTime).toISOString(),
                                  allowInvalidPreload: true,
                                  dateFormat: 'M j, h:i K',
                                  maxDate: 'today',
                                  monthSelectorType: 'static',
                                  minuteIncrement: 1,
                                  static: true,
                                  position: 'above left',
                                  enableTime: true,
                                }}
                                className="form-control form-control-sm"
                              />
                              <ErrorMessage
                                name={`breaks.${index}.startTime`}
                                render={(msg) => (
                                  <small className="text-danger">{msg}</small>
                                )}
                              />
                            </Col>
                            <Col md={3}>
                              <Field
                                component={Flatpickr}
                                name={`breaks.${index}.endTime`}
                                placeholder="End Time"
                                onChange={([date]) => {
                                  formikprops.setFieldValue(
                                    `breaks.${index}.endTime`,
                                    moment(date).format('YYYY-MM-DD HH:mm:ss')
                                  );
                                }}
                                value={
                                  breakItem.endTime &&
                                  new Date(breakItem.endTime).toISOString()
                                }
                                options={{
                                  defaultDate:
                                    breakItem.endTime &&
                                    new Date(breakItem.endTime).toISOString(),
                                  allowInvalidPreload: true,
                                  dateFormat: 'M j, h:i K',
                                  maxDate: 'today',
                                  monthSelectorType: 'static',
                                  minuteIncrement: 1,
                                  static: true,
                                  position: 'above left',
                                  enableTime: true,
                                }}
                                className="form-control form-control-sm"
                              />
                              <ErrorMessage
                                name={`breaks.${index}.endTime`}
                                render={(msg) => (
                                  <small className="text-danger">{msg}</small>
                                )}
                              />
                            </Col>
                            <Col xs={1} className="px-0">
                              <Button
                                variant="link"
                                size="sm"
                                onClick={() => arrayHelpers.remove(index)}
                                title="Remove Break"
                              >
                                <i className="mdi mdi-window-close" />
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      <div className="mb-4">
                        <Button
                          variant="outline-primary"
                          type="button"
                          size="sm"
                          onClick={() =>
                            arrayHelpers.push({
                              type: '',
                              startTime: '',
                              endTime: '',
                            })
                          }
                        >
                          Add Break To Worksession
                        </Button>
                      </div>
                    </div>
                  )}
                />
                <Form.Group controlId="remark">
                  <Form.Label>Remarks</Form.Label>
                  <Field
                    className="form-control"
                    type="textarea"
                    placeholder=""
                    name="remark"
                  />
                </Form.Group>
              </section>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
              <Button variant="link" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save & Approve
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

WorkSessionEditOnApprovalModal.propTypes = {
  wsItem: PropTypes.object,
  editOnApprovalDisplay: PropTypes.bool,
  handleEditOnApprovalModal: PropTypes.func,
  handleWorkSessionEditRequest: PropTypes.func,
  allBreaksList: PropTypes.array,
};

export default WorkSessionEditOnApprovalModal;
