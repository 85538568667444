import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

const ManageActiveSessions = ({
  isSessionManagementEnabled,
  handleManageOngoingSessions,
}) => (
  <Row>
    <Col>
      <h4 className="font-weight-base mb-1">Manage Ongoing Sessions</h4>
      <small className="text-muted">
        Enable this setting to allow managers to view and manage active user
        sessions on the Activity -{'>'} Ongoing Sessions page. Managers can
        check out users, and add, edit, or delete breaks within a session.
      </small>
    </Col>
    <Col className="col-auto">
      <div className="form-check form-switch">
        <input
          className="form-check-input toggle-switch"
          type="checkbox"
          id="manageSession"
          name="manageSession"
          checked={isSessionManagementEnabled}
          onChange={handleManageOngoingSessions}
        />
      </div>
    </Col>
  </Row>
);
ManageActiveSessions.propTypes = {
  isSessionManagementEnabled: PropTypes.bool,
  handleManageOngoingSessions: PropTypes.func,
};
export default ManageActiveSessions;
