import { Form, Button, Row, Col } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

const CreateProjectStep1 = ({ nextStep, formProps, clientsData }) => {
  // Reset step one fields and validate
  const resetStepOne = () => {
    formProps.setFieldValue('client', '');
    formProps.setFieldValue('title', '');
    formProps.setFieldValue('description', '');
    formProps.setFieldValue('note', '');
    setTimeout(() => {
      formProps.validateForm();
      formProps.setTouched({});
    }, 0);
  };
  return (
    <section>
      <div className="text-center">
        <h5 className="text-uppercase text-muted fw-light">Step 1 of 3</h5>
        <h2>Let’s start with the basics.</h2>
        <p className="text-muted font-14 font-weight-normal mb-5">
          Help your team members with the project details
        </p>
      </div>

      {clientsData.length > 0 && (
        <Form.Group controlId="client">
          <Form.Label>Client</Form.Label>
          <Field className="form-select" as="select" name="client">
            <option value="">Select client</option>
            {clientsData.map((item) => (
              <option value={item._id} key={item._id}>
                {item.name}
              </option>
            ))}
          </Field>
          <ErrorMessage
            name="client"
            render={(msg) => <small className="text-danger">{msg}</small>}
          />
        </Form.Group>
      )}
      <Form.Group controlId="projectName">
        <Form.Label>
          Project name <span className="text-danger">*</span>
        </Form.Label>
        <Field
          className="form-control"
          type="text"
          placeholder="Enter project name"
          name="title"
        />
        <ErrorMessage
          name="title"
          render={(msg) => <small className="text-danger">{msg}</small>}
        />
      </Form.Group>
      <Form.Group controlId="projectDescription">
        <Form.Label>Project description</Form.Label>
        <div className="text-muted mb-1">
          This is how your team members will learn about the project
        </div>
        <Field
          as="textarea"
          maxLength="305"
          name="description"
          rows="2"
          className="form-control"
          placeholder="Enter project description"
        />
        <ErrorMessage
          name="description"
          render={(msg) => <small className="text-danger">{msg}</small>}
        />
      </Form.Group>
      <Form.Group controlId="projectNotes">
        <Form.Label>Project notes</Form.Label>
        <div className="text-muted mb-1">
          This is the place for reminders or specifications for your project so
          team members can see them while working
        </div>
        <Field
          as="textarea"
          maxLength="305"
          name="note"
          rows="3"
          className="form-control"
          placeholder="Enter project notes"
        />
        <ErrorMessage
          name="note"
          render={(msg) => <small className="text-danger">{msg}</small>}
        />
      </Form.Group>

      <hr className="my-5" />
      <Row className="align-items-center">
        <Col className="col-auto">
          <Button
            className="btn btn-lg btn-white"
            type="button"
            onClick={resetStepOne}
          >
            Cancel
          </Button>
        </Col>
        <Col className="text-center">
          <h6 className="text-uppercase text-muted mb-0">Step 1 of 3</h6>
        </Col>
        <Col className="col-auto">
          <Button
            variant="primary"
            size="lg"
            type="button"
            onClick={() => {
              nextStep(formProps);
            }}
            disabled={!formProps.isValid && 'disabled'}
          >
            Continue
          </Button>
        </Col>
      </Row>
    </section>
  );
};

CreateProjectStep1.propTypes = {
  nextStep: PropTypes.func,
  formProps: PropTypes.any,
  clientsData: PropTypes.array,
};

export default CreateProjectStep1;
