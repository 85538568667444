import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { WithConfirmation } from '.';
import { workSessionService } from '../../services';
import { UPDATE_FAILURE_ERROR } from '../../constants';
import { refreshPage } from '../../helpers';

// Delete Button
const DeleteButton = ({ handleShow }) => (
  <Button
    variant="danger"
    size="sm"
    className="ms-2"
    onClick={handleShow}
    title="Delete"
  >
    Delete
  </Button>
);
DeleteButton.propTypes = {
  handleShow: PropTypes.func,
};

// HOC for section deletion confirmation
const DeleteWorkSession = ({ workSessionId }) => {
  const handleDeleteWorkSession = () => {
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      workSessionId,
    };

    workSessionService
      .delete_worksession(params)
      .then((res) => {
        if (res.status) {
          refreshPage();
        }
      })
      .catch((error) => {
        toast.error(UPDATE_FAILURE_ERROR);
      });
  };
  const DeleteSectionWithConfirmation = WithConfirmation(DeleteButton);
  const DeleteModalProps = {
    title: `Worksession deletion`,
    action: handleDeleteWorkSession,
    content: [
      `You're about to delete this worksession. All data will be permanently deleted, including worksession screenshots and notes. You will not be able to restore it later.`,
      'Do you want to delete it?',
    ],
    actionBtnText: 'Delete',
  };
  return <DeleteSectionWithConfirmation modalProps={DeleteModalProps} />;
};
DeleteWorkSession.propTypes = {
  workSessionId: PropTypes.string.isRequired,
};

export default DeleteWorkSession;
