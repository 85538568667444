/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { usePagination, DOTS } from '../../hooks/usePagination';
import '../../assets/styles/pagination.scss';

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  // eslint-disable-next-line prefer-const
  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={`pagination-container ${className} mb-0`}>
      <li
        className={
          currentPage === 1 ? 'pagination-item disabled' : 'pagination-item'
        }
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber, pIndex) => {
        if (pageNumber === DOTS) {
          return (
            <li className="pagination-item dots" key={pIndex}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            className={`pagination-item ${
              pageNumber === currentPage && 'selected'
            }`}
            onClick={() => onPageChange(pageNumber)}
            key={pIndex}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={`pagination-item ${currentPage === lastPage && 'disabled'}`}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

Pagination.propTypes = {
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  className: PropTypes.string,
};

export default Pagination;
