import { useRef, memo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { MemberMultiSelectDropdown, AddMemberWithoutRole } from '../common';
import { calculateInviteFieldwithoutRole } from '../../helpers';

const AddMembers = ({ handleListUpdate, teamList }) => {
  const sortedMembersList = teamList;
  const existingTeamMembers = [];
  let newTeamMembers = [];
  const selectInputRef = useRef(null);
  newTeamMembers = calculateInviteFieldwithoutRole(1);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      event.preventDefault();
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        addExistingTeamMembers: existingTeamMembers,
        addNewTeamMembers: newTeamMembers,
      }}
      validationSchema={Yup.object().shape({
        addNewTeamMembers: Yup.array().of(
          Yup.object().shape({
            email: Yup.string()
              .trim()
              .email('Please enter a valid email address'),
          })
        ),
      })}
      onSubmit={(values, { resetForm }) => {
        handleListUpdate(values);
        resetForm();
        // Clear input values
        selectInputRef.current.select.clearValue();
      }}
    >
      {(formikprops) => (
        <Form
          onSubmit={formikprops.handleSubmit}
          onKeyDown={(e) => {
            handleKeyDown(e);
          }}
        >
          <section>
            <MemberMultiSelectDropdown
              id="selectInputRef"
              name="addExistingTeamMembers"
              labelText="Add existing Team Members"
              fromArray={sortedMembersList}
              formProps={formikprops}
              isDisabled={sortedMembersList.length < 1 && true}
              innerRef={selectInputRef}
            />
            <AddMemberWithoutRole
              formProps={formikprops}
              showPlanText=""
              maxInputField={0}
              formLabel="Add new members"
              fieldName="addNewTeamMembers"
            />
          </section>

          <div className="d-flex justify-content-end align-items-center my-5">
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={!(formikprops.isValid && formikprops.dirty)}
            >
              Add Member Report{' '}
              {formikprops.isSubmitting && (
                <span
                  className="spinner-border spinner-border-sm me-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddMembers.propTypes = {
  handleListUpdate: PropTypes.func,
  teamList: PropTypes.array,
};

export default memo(AddMembers);
