import { BASE_API_URL, SETTINGS_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

export const settingsService = {
  get_settings: async () => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const reqOptions = {
      method: 'GET',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.GET_SETTINGS,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_autocheckout: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.UPDATE_AUTOCHECKOUT,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_overtime: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'POST',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.UPDATE_OVERTIME,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_schedule: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'POST',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.UPDATE_SCHEDULE_SETTINGS,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  settings_approval: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.SETTINGS_APPROVAL,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  change_password: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.CHANGE_PASSWORD,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  user_update: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };

    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.USER_UPDATE,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  timezone_list: async () => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.TIMEZONE,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_avatar: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.UPDATE_AVATAR,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  delete_avatar: async () => {
    const headers = HTTPHeaders();
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.DELETE_AVATAR,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  payment_info: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + SETTINGS_URLS.PAYMENT_INFO}/${data}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  get_hosted_page_url: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + SETTINGS_URLS.CARD_UPDATE}/${data.ownerId}/${
        data.pageType
      }/${data.planId}/${data.memberCount}/${data.planType}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  invoice_list: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + SETTINGS_URLS.INVOICE_LIST}/${data.ownerId}/${
        data.limit
      }/${data.offset[0]}/${data.offset[1]}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_billing_email: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.EMAIL_UPDATE,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  download_invoice: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + SETTINGS_URLS.DOWNLOAD_INVOICE}/${data.ownerId}/${
        data.id
      }`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  get_plans: async () => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + SETTINGS_URLS.GET_PLANS}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  reactivate_account: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.REACTIVATE_ACCOUNT,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_daily_limit: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + SETTINGS_URLS.UPDATE_DAILY_LIMIT}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  get_email_settings: async (ownerId) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + SETTINGS_URLS.EMAIL_SUMMARIES}/${ownerId}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_email_settings: async (data, method) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method,
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + SETTINGS_URLS.EMAIL_SUMMARIES}`,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  delete_email_settings: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      method: 'DELETE',
    };

    const response = await AppService.makeRequest(
      `${BASE_API_URL + SETTINGS_URLS.EMAIL_SUMMARIES}/${data.ownerId}/${
        data.id
      }`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_invoice: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'POST',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.UPDATE_INVOICE_SETTINGS,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  update_daily_limit: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'POST',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.UPDATE_DAILY_LIMIT,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  delete_company_logo: async (teamOwnerId) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'DELETE',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL}${SETTINGS_URLS.DELETE_COMPANY_LOGO}/${teamOwnerId}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_notification_setting: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'POST',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.NOTIFICATION_SETTING,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  manage_ongoing_sessions: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'POST',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + SETTINGS_URLS.ONGOING_SESSIONS,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
};
