import { Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Select from 'react-select';
import {
  MemberMultiSelectDropdown,
  AllowedMembersAlert,
  InviteNewTeamMember,
} from '../common';
import { getUserData } from '../../helpers';

const CreateProjectStep3 = ({
  prevStep,
  formProps,
  teamMembersList,
  isSubmitting,
  teamGroupList,
}) => {
  // Filter inactive members
  const activeMembersList = teamMembersList.filter(function (item) {
    return item.status !== 'inactive';
  });
  const activeMembers = getUserData('activeMembers') || 0;
  const maxMembersAllowed = getUserData('maxMembersAllowed') || 0;

  const groupNames =
    teamGroupList.length > 0
      ? teamGroupList.map((tGroup) => {
          const tGroupItem = { value: tGroup._id, label: tGroup.name };
          return tGroupItem;
        })
      : [];
  return (
    <section>
      <div className="text-center">
        <h5 className="text-uppercase text-muted fw-light">Step 3 of 3</h5>
        <h2 className="mt-5">Finally, let’s invite the team.</h2>
        <p className="text-muted font-14 font-weight-normal mb-5">
          {activeMembers > 0 ? `Add team members from other projects or` : ``}
          {activeMembers > 0 && <br />}
          {activeMembers > 0
            ? ` invite new team members via email.`
            : `Invite new team members via email.`}
        </p>
      </div>
      {groupNames.length > 0 && (
        <section className="mb-5">
          <p>Select Team Group</p>
          <Select
            name="teams"
            options={groupNames}
            className="basic-multi-select"
            placeholder="Select Group"
            isMulti
            onChange={(sOptions) => {
              formProps.setFieldValue(
                'teams',
                sOptions.map((item) => item.value)
              );
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#0649b5',
                neutral10: '#edf2f9',
                neutral20: '#d2ddec',
                neutral30: '#d2ddec',
                neutral50: '#b1c2da',
                neutral80: '#12263f',
                dangerLight: '#DEEBFF',
                danger: '#12263f',
              },
            })}
          />
        </section>
      )}
      {teamMembersList.length > 0 && (
        <MemberMultiSelectDropdown
          id="selectInputRef"
          name="addExistingTeamMembers"
          labelText="Add existing team members"
          fromArray={activeMembersList}
          formProps={formProps}
          requiredValueAs="userId"
        />
      )}
      {maxMembersAllowed - activeMembers > 0 && (
        <InviteNewTeamMember
          formProps={formProps}
          showPlanText=""
          maxInputField={0}
          fieldName="addNewTeamMembers"
          maxFieldAllowed={maxMembersAllowed - activeMembers}
        />
      )}
      <AllowedMembersAlert />
      <hr className="my-5" />
      <Row className="align-items-center">
        <Col className="col-auto">
          <Button className="btn btn-lg btn-white" type="button">
            Cancel
          </Button>
        </Col>
        <Col className="text-center">
          <h6 className="text-uppercase text-muted mb-0">Step 3 of 3</h6>
        </Col>
        <Col className="col-auto">
          <Button
            variant="secondary"
            size="lg"
            type="button"
            className="me-2"
            onClick={prevStep}
          >
            Back
          </Button>
          <Button
            variant="primary"
            size="lg"
            type="Submit"
            disabled={isSubmitting}
          >
            Create Project{' '}
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Col>
      </Row>
    </section>
  );
};

CreateProjectStep3.propTypes = {
  prevStep: PropTypes.func,
  teamMembersList: PropTypes.array,
  formProps: PropTypes.any,
  isSubmitting: PropTypes.bool,
  teamGroupList: PropTypes.array,
};

export default CreateProjectStep3;
