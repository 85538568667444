import { BASE_API_URL, TEAM_URLS, SCHEDULE_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

import { makeEncodeFormData } from '../helpers';

const options = AppService.options();

export const policyService = {
  get_team_members: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const response = await AppService.makeRequest(
      BASE_API_URL + TEAM_URLS.MEMBERS_AND_PROJECTS,
      {
        ...options,
        ...headers,
        body: makeEncodeFormData(data),
      }
    );
    return response;
  },
  create_schedule: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + SCHEDULE_URLS.SCHEDULES,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_policy_list: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + TEAM_URLS.TEAM_SENT_INVITATIONS}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  get_policy: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + TEAM_URLS.TEAM_SENT_INVITATIONS}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  update_one_policy: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'PUT',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + TEAM_URLS.TEAM_SENT_INVITATIONS}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
};
