import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Row, Col, Button, Spinner, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MemberStatus } from '../common';
import { MemberRole, TeamKababMenu, MemberName } from '.';
import Avatar from '../Avatar';
import Loading from '../Loader';
import { PER_PAGE_LIMIT } from '../../constants';
import { convertSecondsToHoursAndMinutes } from '../../helpers';
import { fetchMembers } from '../../actions/team';

const TeamMember = ({ isListView }) => {
  const dispatch = useDispatch();
  const teamData = useSelector((state) => state.team);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMembers = () => {
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      page: teamData.page,
      limit: PER_PAGE_LIMIT,
      summaryList: false,
      filter: JSON.stringify({
        memberStatus: 'active',
        keyword: teamData.searchParam,
      }),
    };
    dispatch(fetchMembers(params));
  };

  const dataFetcher = React.useRef(getMembers);
  const observer = React.useRef(
    new IntersectionObserver(
      (entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          dataFetcher.current();
        }
      },
      { threshold: 1 }
    )
  );
  const [element, setElement] = React.useState(null);
  // Update snapshot of getMembers function whenever page number changes.
  React.useEffect(() => {
    dataFetcher.current = getMembers;
  }, [getMembers]);

  // Observe for the load more spinner element
  React.useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;
    if (currentElement) {
      currentObserver.observe(currentElement);
    }
    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  return (
    <>
      {teamData.activeMembers.length > 0 ? (
        <section className={isListView ? 'card-list-view' : 'card-thumb-view'}>
          {teamData.activeMembers.map((el, i) => {
            // Get the totalTasks count
            const totalTasks =
              (el.tasks &&
                el.tasks.reduce((total, task) => total + task.tasks, 0)) ||
              0;
            // Get the inProgress task details
            const memberTasks =
              (el.tasks &&
                el.tasks.filter((task) => task.status === 'complete')) ||
              [];
            // Get the inProgress task count
            const tasksCompleted =
              memberTasks.length >= 1 ? memberTasks[0].tasks : 0;
            const openTasks = totalTasks - tasksCompleted;
            const taskProgressValue = (tasksCompleted / totalTasks) * 100;

            const progressWidth = {
              width: `${taskProgressValue}%`,
            };
            const costRate = el.rates && el.rates.costRate;
            return (
              <div className="card" key={i}>
                <div className="card-body">
                  <div className="avatar avatar-xl card-avatar card-avatar-top">
                    <Avatar
                      firstName={el.firstName}
                      lastName={el.lastName}
                      imgPath={el.avatar ? el.avatar : ''}
                    />
                  </div>
                  {/* Dropdown */}
                  <TeamKababMenu
                    userId={el.userId}
                    userRole={el.role}
                    userRate={costRate}
                    currency={teamData.metadata.defaultCurrency}
                    totalProjects={el.activeProjectsCount}
                  />
                  <div className="member-info">
                    <Row>
                      <Col>
                        <MemberName
                          memberName={
                            el.lastName && el.lastName !== null
                              ? `${el.firstName} ${el.lastName}`
                              : `${el.firstName}`
                          }
                          hourlyRate={el.rates.costRate}
                          defaultCurrency={teamData.metadata.defaultCurrency}
                        />
                        <p className="small text-muted mb-3">{el.email}</p>
                        <p className="mb-4 role">
                          <MemberRole role={el.role} />
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <div className="row no-gutters border-top border-bottom task-info">
                    <div className="col py-4 text-center align-to-left">
                      {el.workSession &&
                      el.workSession.weekWorksessionSeconds > 0 ? (
                        <>
                          <h6 className="text-uppercase text-muted">
                            Total Hours
                          </h6>
                          <h2 className="mb-0">
                            {convertSecondsToHoursAndMinutes(
                              el.workSession.weekWorksessionSeconds
                            )}
                          </h2>
                          <h6 className="text-uppercase text-muted mt-2">
                            (last 7 days)
                          </h6>
                        </>
                      ) : (
                        <span className="badge bg-secondary-soft">
                          No hours logged
                          <br />
                          (last 7 days)
                        </span>
                      )}
                    </div>
                    <div className="col py-4 text-center border-left align-to-left">
                      <h6 className="text-uppercase text-muted">Open tasks</h6>
                      {openTasks >= 1 ? (
                        <h2 className="mb-0">{openTasks}</h2>
                      ) : (
                        <span className="badge bg-secondary-soft">
                          No open tasks
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="list-group list-group-flush mb-4 worksession-info">
                    <div className="list-group-item">
                      <div className="row align-items-center last-worked">
                        <div className="col">
                          <small>Last worked</small>
                        </div>
                        <div className="col-auto">
                          {el.workSession &&
                          el.workSession.lastWorkSession !== null ? (
                            <time className="small text-muted">
                              {moment(el.workSession.lastWorkSession).fromNow()}
                            </time>
                          ) : (
                            <span className="badge bg-secondary-soft">
                              Not available
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item">
                      <div className="row align-items-center progress-info">
                        <div className="col">
                          <small>Progress</small>
                        </div>
                        {totalTasks >= 1 ? (
                          <div className="col">
                            <div className="row align-items-center no-gutters progress-info">
                              <div className="col-auto">
                                <div className="small mr-2">
                                  {tasksCompleted}/{totalTasks}
                                </div>
                              </div>
                              <div className="col">
                                <div className="progress progress-sm">
                                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={progressWidth}
                                    aria-valuenow={progressWidth}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-auto">
                            <span className="badge bg-secondary-soft">
                              Not available
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Footer */}
                  <div className="row align-items-center justify-content-between">
                    <div className="col-auto">
                      <MemberStatus memberId={el.userId} />
                    </div>
                    <div className="col-auto">
                      {el.activeProjectsCount > 0 && (
                        <Link
                          to={`/team/projects/${el.userId}`}
                          className="btn btn-sm btn-primary"
                        >
                          Projects
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      ) : (
        !teamData.isLoading && (
          <Col>
            <Alert variant="info" className="text-center">
              No active Members
            </Alert>
          </Col>
        )
      )}

      {/* Loading spinner overlay */}
      {teamData.isLoading && <Loading contentAreaOnly />}

      {/* Observing button to check if more data available to fetch */}
      {!teamData.isLoading && teamData.loadMore && (
        <div className="d-flex justify-content-center mb-5">
          <Button variant="primary" disabled ref={setElement}>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{' '}
            <span>Loading...</span>
          </Button>
        </div>
      )}
    </>
  );
};

TeamMember.propTypes = {
  isListView: PropTypes.bool,
};

export default TeamMember;
