import PropTypes from 'prop-types';
import { Card, Col } from 'react-bootstrap';

const TextCard = ({
  cardTitle,
  md = 4,
  cardDetails,
  cardStyle = '',
  displayStyle = '',
}) => (
  <Col md={md} className="col-12">
    <Card className={`${cardStyle} mb-0`}>
      <Card.Body>
        <Card.Title>
          {displayStyle !== 'compact' ? (
            <h4 className="m-0">{cardTitle}</h4>
          ) : (
            <h6 className="text-uppercase text-muted mb-2">{cardTitle}</h6>
          )}
        </Card.Title>
        <Card.Text
          className={displayStyle !== 'compact' ? 'text-muted mb-2' : 'h2 mb-0'}
        >
          {cardDetails}
        </Card.Text>
      </Card.Body>
    </Card>
  </Col>
);
TextCard.propTypes = {
  cardTitle: PropTypes.string,
  cardDetails: PropTypes.string,
  md: PropTypes.number,
  cardStyle: PropTypes.string,
  displayStyle: PropTypes.string,
};
export default TextCard;
