import { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import { toast } from 'react-toastify';
import Layout from '../DashboardLayout';
import Loader from '../../components/Loader';

import SettingsTabs from '../../components/settings/Tabs';
import EmailSummariesForm from '../../components/settings/EmailSummariesForm';
import AddMembers from '../../components/settings/AddMembers';
import { settingsService, teamService } from '../../services';
import { updateLocalStorage } from '../../helpers';
import {
  UPDATE_FAILURE_ERROR,
  LOCAL_STORAGE_USER_DATA,
  SETTINGS_SUMMMARIES,
} from '../../constants';
import { trackJune } from '../../utilities/analytics';

class EmailSummaries extends Component {
  userdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));

  /* Save the original list to manipulate dropdown when user is deleted fron email list */
  teamListOrginal = [];

  pageHeading = {
    preTitle: 'Settings',
    title: 'Email Summaries',
  };

  state = {
    isSubmitting: false,
    settingsList: [],
    teamList: [],
    dailySummaryNoWorkSession: this.userdata.dailySummaryNoWorkSession,
  };

  componentDidMount() {
    this.setState((state) => ({
      ...state,
      isSubmitting: true,
    }));
    /* fetch the email settings for the owner
      Remove mail field from the keys, to match with the edit settings api keys
    */
    settingsService
      .get_email_settings(localStorage.getItem('teamOwnerId'))
      .then((resp) => {
        const newArray = resp.data.map((item) => {
          const newItem = {};
          Object.keys(item).forEach((key) => {
            if (key === 'projectId') {
              /* projectId key should be projectIds for edit/add API */
              newItem.projectIds = item[[key]];
            } else {
              /* edit/add API json keys shouldn't contain 'Mail' text */
              const newKey = key.replace('Mail', '');

              const found = ['daily', 'weekly', 'bimonthly'].find((v) =>
                v.includes(newKey)
              );
              if (found) {
                /* Api values are having null instead of false, which wont work in formik
                  and api doesn't accept
                */
                if (item[[key]]) {
                  newItem[newKey] = item[[key]];
                } else {
                  newItem[newKey] = false;
                }
              } else {
                newItem[newKey] = item[[key]];
              }
            }
          });
          newItem.id = item._id;
          return newItem;
        });
        this.setState((state) => ({
          ...state,
          isSubmitting: false,
          settingsList: newArray,
        }));
      })
      .then(() => {
        /* Fetch the team members of the owner */
        this.getTeamData();
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isSubmitting: false,
        }));
      });
  }

  getEmailSettings = () => {
    settingsService
      .get_email_settings(localStorage.getItem('teamOwnerId'))
      .then((resp) => {
        const newArray = resp.data.map((item) => {
          const newItem = {};
          Object.keys(item).forEach((key) => {
            if (key === 'projectId') {
              /* projectId key should be projectIds for edit/add API */
              newItem.projectIds = item[[key]];
            } else {
              /* edit/add API json keys shouldn't contain 'Mail' text */
              const newKey = key.replace('Mail', '');

              const found = ['daily', 'weekly', 'bimonthly'].find((v) =>
                v.includes(newKey)
              );
              if (found) {
                /* Api values are having null instead of false, which wont work in formik
                  and api doesn't accept
                */
                if (item[[key]]) {
                  newItem[newKey] = item[[key]];
                } else {
                  newItem[newKey] = false;
                }
              } else {
                newItem[newKey] = item[[key]];
              }
            }
          });
          newItem.id = item._id;
          return newItem;
        });
        this.setState((state) => ({
          ...state,
          isSubmitting: false,
          settingsList: newArray,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isSubmitting: false,
        }));
      });
  };

  getTeamData = () => {
    /* Fetch the team members of the owner */
    teamService
      .get_team_members_data({
        teamOwnerId: localStorage.getItem('teamOwnerId'), // @ToDo: Move this to reducer or react-context API
        summaryList: true,
        filter: '{ "memberStatus": "active" }',
      })
      .then((response) => {
        const { members } = response.data;
        const { settingsList } = this.state;
        /* remove users from dropdown, already existing in email list */
        const result = members.filter(function (o1) {
          return !settingsList.some(function (o2) {
            return o1.email === o2.email;
          });
        });

        this.setState((state) => ({
          ...state,
          teamList: result,
          isSubmitting: false,
        }));

        this.teamListOrginal = members;
      });
  };

  /* update email list state array and trigger api to add user to email settings */
  handleListUpdate = (formValues) => {
    const newMembers = [];
    this.setState((state) => ({
      ...state,
      isSubmitting: true,
    }));

    if (formValues) {
      if (formValues.addExistingTeamMembers) {
        formValues.addExistingTeamMembers.forEach((elem) => {
          newMembers.push({
            _id: 0,
            id: 0,
            projectIds: null,
            email: elem,
            daily: false,
            weekly: false,
            biMonthly: false,
            ownerId: localStorage.getItem('teamOwnerId'),
          });
        });
      }
      if (formValues.addNewTeamMembers)
        formValues.addNewTeamMembers
          .filter((item) => item.email !== '')
          .forEach((elem) => {
            newMembers.push({
              _id: 0,
              id: 0,
              projectIds: null,
              email: elem.email,
              daily: false,
              weekly: false,
              biMonthly: false,
              ownerId: localStorage.getItem('teamOwnerId'),
            });
          });

      /* send api request to add new user to email list */
      const data = {
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        settings: newMembers,
      };

      settingsService
        .update_email_settings(data, 'POST')
        .then((succData) => {
          if (succData.status) {
            trackJune('email configuration set up');
            toast.success(SETTINGS_SUMMMARIES.MEMBER_ADD);
            this.getEmailSettings();
            const { teamList } = this.state;

            /* remove users from dropdown, added to email list */
            const result = teamList.filter(function (o1) {
              return !succData.data.some(function (o2) {
                return o1.email === o2.email;
              });
            });

            /* add new user(s) to state array of email listing
            remove from member dropdown list
          */
            this.setState((state) => ({
              ...state,
              isSubmitting: false,
              teamList: result,
            }));
          }
        })
        .catch(() => {
          this.setState((state) => ({
            ...state,
            isSubmitting: false,
          }));
          toast.error(UPDATE_FAILURE_ERROR);
        });
    }
  };

  /* submit delete request for one settings */
  handleDeleteSummary = (id) => {
    this.setState((state) => ({
      ...state,
      isSubmitting: true,
    }));

    const data = {
      ownerId: localStorage.getItem('teamOwnerId'),
      id,
    };
    settingsService
      .delete_email_settings(data)
      .then(() => {
        trackJune('email configuration set up');
        this.getEmailSettings();
        const { settingsList } = this.state;
        const newMembers = settingsList.filter(
          (el) => el.id !== id
        ); /* remove id from state array */

        /* return elements found in new members to update dropdown */
        const memDropDown = this.teamListOrginal.filter(function (o1) {
          return !newMembers.find((element) => element.email === o1.email);
        });

        this.setState((state) => ({
          ...state,
          teamList: [...memDropDown],
          isSubmitting: false,
        }));

        toast.success(SETTINGS_SUMMMARIES.MEMBER_DEL);
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isSubmitting: false,
        }));
        toast.error(UPDATE_FAILURE_ERROR);
      });
  };

  /* Updating options of added users */
  handleUpdateSummaries = (values) => {
    this.setState((state) => ({
      ...state,
      isSubmitting: true,
    }));

    if (values.settingsList.length > 0) {
      const data = {
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        settings: values.settingsList,
      };

      settingsService
        .update_email_settings(data, 'PATCH')
        .then((response) => {
          trackJune('email configuration set up');
          if (response.status) {
            this.getEmailSettings();
            toast.success(SETTINGS_SUMMMARIES.MEMBER_EDIT);
          }
        })
        .catch(() => {
          this.setState((state) => ({
            ...state,
            isSubmitting: false,
          }));

          toast.error(UPDATE_FAILURE_ERROR);
        });
    }
  };

  /* on click of checkbox */
  handleNoSummaryChange = (value) => {
    this.setState((state) => ({
      ...state,
      isSubmitting: true,
    }));

    settingsService
      .user_update({ dailySummaryNoWorkSession: value.target.checked })
      .then(() => {
        this.setState((state) => ({
          ...state,
          isSubmitting: false,
          dailySummaryNoWorkSession: value.target.checked,
        }));

        /* update flag in local storage */
        updateLocalStorage({ dailySummaryNoWorkSession: value.target.checked });

        if (value.target.checked) {
          toast.success(SETTINGS_SUMMMARIES.DAILY_SUMMARY);
        } else {
          toast.success(SETTINGS_SUMMMARIES.NO_DAILY_SUMMARY);
        }
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isSubmitting: false,
        }));

        toast.error(UPDATE_FAILURE_ERROR);
      });
  };

  render() {
    const { isSubmitting, settingsList, teamList, dailySummaryNoWorkSession } =
      this.state;

    return (
      <Layout pageHeading={this.pageHeading}>
        {isSubmitting && <Loader contentAreaOnly />}
        <Row className="justify-content-center">
          <Col className="col-12 col-lg-10 col-xl-8">
            <SettingsTabs selectedTab={this.pageHeading.title} />
            <div className="tab-content">
              <div className="tab-pane show active">
                <AddMembers
                  handleListUpdate={this.handleListUpdate}
                  teamList={teamList}
                />
                <div className="custom-control custom-checkbox">
                  <label
                    className="custom-control-label"
                    htmlFor="noWorksession"
                  >
                    <input
                      className="form-check-input form-check-border m-2"
                      id="noWorksession"
                      type="checkbox"
                      defaultChecked={dailySummaryNoWorkSession}
                      style={{ float: 'left' }}
                      onChange={this.handleNoSummaryChange}
                    />
                    <div
                      style={{
                        overflow: 'hidden',
                        width: 'auto',
                        marginTop: '3px',
                      }}
                    >
                      Send daily summary even if no worksessions were logged
                    </div>
                  </label>
                </div>
                <hr className="my-5" />
                {settingsList.length > 0 && (
                  <EmailSummariesForm
                    settingsList={settingsList}
                    handleDelete={this.handleDeleteSummary}
                    handleAddEdit={this.handleUpdateSummaries}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}
export default EmailSummaries;
