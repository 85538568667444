import PropTypes from 'prop-types';
import moment from 'moment';
import { Alert, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { useSelector } from 'react-redux';
import { NO_DATA, PAGE_URLS, PAGINATION_LIMIT } from '../../constants';
import Avatar from '../Avatar.jsx';
import { msToHMS } from '../../helpers';
import '../../assets/styles/activity-day-table.scss';
import Pagination from '../common/Pagination';

const ActivityListByDay = ({
  activityList,
  getActivityData,
  pageNumber,
  setCurrentPage,
}) => {
  const onlineMembers = useSelector((state) => state.onlineStatus.members);
  const w120 = {
    minWidth: '120px',
  };
  const { activities, metadata } = activityList;
  const records = metadata && metadata.total ? metadata.total : 0;
  if (records) {
    return (
      <>
        <div className="table-responsive">
          <table
            id="teamActivityDateRange"
            className="table table-sm table-hover card-table table-nowrap activity-by-day"
            style={{ overflow: 'hidden' }}
          >
            <thead className="thead-light">
              <tr>
                <th className="member-info">Team Member</th>
                <th style={w120}>12am</th>
                <th style={w120}>2am</th>
                <th style={w120}>4am</th>
                <th style={w120}>6am</th>
                <th style={w120}>8am</th>
                <th style={w120}>10am</th>
                <th style={w120}>12pm</th>
                <th style={w120}>2pm</th>
                <th style={w120}>4pm</th>
                <th style={w120}>6pm</th>
                <th style={w120}>8pm</th>
                <th style={w120}>10pm</th>
              </tr>
            </thead>

            <tbody>
              {activities.map((activityDetails, index) => {
                const { firstName, lastName, _id, userTeamStatus, avatar } =
                  activityDetails;
                return (
                  <tr key={`${index}-team-activity`}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div
                          className={
                            onlineMembers.includes(_id)
                              ? `avatar me-2 avatar-online`
                              : `avatar me-2 avatar-offline`
                          }
                        >
                          <Avatar
                            firstName={firstName}
                            lastName={lastName}
                            imgPath={avatar || ''}
                          />
                        </div>
                        <Col>
                          <h4 className="font-weight-bold mb-1">
                            {lastName && lastName !== null
                              ? `${firstName} ${lastName}`
                              : `${firstName}`}{' '}
                            {userTeamStatus === 'inactive' && ' (Inactive)'}
                          </h4>
                          <small className="text-muted">
                            {msToHMS(
                              activityDetails.totalWorkSessionSeconds * 1000
                            )}
                          </small>
                        </Col>
                      </div>
                    </td>
                    <td colSpan={12} className="pill-container">
                      {activityDetails.activity.length > 0 &&
                        activityDetails.activity.map((session, sIndex) => {
                          const {
                            totalIdleSeconds,
                            workSessionSeconds,
                            checkInDate,
                            project,
                            workSessionType,
                            isManual,
                            activityPercentage,
                          } = session;
                          const sessionLength = workSessionSeconds / 60;
                          const pillSize =
                            sessionLength > 0 ? sessionLength : 1;
                          const sessionPercentage =
                            workSessionType === 'mobile' ||
                            isManual ||
                            activityPercentage <= 0
                              ? 0
                              : activityPercentage;
                          const checkIn = moment(checkInDate).format('LT');
                          const startTime = checkIn.split(/[ :]+/);
                          let startFrom;
                          if (checkIn.includes('AM')) {
                            if (startTime[0] === '12') {
                              startFrom = parseInt(startTime[1]);
                            } else {
                              startFrom =
                                parseInt(startTime[0]) * 60 +
                                parseInt(startTime[1]);
                            }
                          } else if (
                            checkIn.includes('PM') &&
                            startTime[0] === '12'
                          ) {
                            startFrom =
                              parseInt(startTime[0]) * 60 +
                              parseInt(startTime[1]);
                          } else {
                            startFrom =
                              parseInt(startTime[0]) * 60 +
                              parseInt(startTime[1]) +
                              720;
                          }
                          return (
                            <Tippy
                              key={`${sIndex}-session`}
                              content={
                                <div
                                  style={{ maxWidth: '200px' }}
                                  className="px-2 pt-1 pb-2"
                                >
                                  <h5 className="text-center mt-2">
                                    {project}
                                  </h5>
                                  <Row>
                                    <Col>
                                      <i className="text-primary mr-2">●</i>{' '}
                                      <small>Total time</small>
                                    </Col>
                                    <Col className="col-auto">
                                      <small>
                                        {workSessionSeconds > 0
                                          ? msToHMS(
                                              Math.round(workSessionSeconds) *
                                                1000
                                            )
                                          : '0s'}
                                      </small>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <i className="text-secondary mr-2">●</i>{' '}
                                      <small>Idle time</small>
                                    </Col>
                                    <Col className="col-auto">
                                      <small>
                                        {totalIdleSeconds > 0
                                          ? msToHMS(
                                              Math.round(totalIdleSeconds) *
                                                1000
                                            )
                                          : '0s'}
                                      </small>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <i className="text-secondary mr-2">●</i>{' '}
                                      <small>Activity</small>
                                    </Col>
                                    <Col className="col-auto">
                                      {workSessionType === 'desktop' ? (
                                        <small>
                                          {workSessionSeconds > 0
                                            ? Math.ceil(sessionPercentage)
                                            : 0}
                                          %
                                        </small>
                                      ) : (
                                        <small>N/A</small>
                                      )}
                                    </Col>
                                  </Row>
                                </div>
                              }
                              placement="left-end"
                              theme="light"
                            >
                              <Link
                                className="bg-primary py-3 rounded-3 d-inline-block"
                                to={{
                                  pathname: PAGE_URLS.ACTIVITY.MEMBER,
                                  search: `?teamMember=${_id}&fromDate=${moment(
                                    session.checkInDate
                                  ).format('YYYY-MM-DD')}&toDate=${moment(
                                    session.checkInDate
                                  ).format('YYYY-MM-DD')}`,
                                  state: {
                                    bredCrumbs: [
                                      {
                                        name: 'Team Activity',
                                        path: `/activity/team/day/${session.date}`,
                                      },
                                      {
                                        name: `${firstName} ${lastName}`,
                                        path: '',
                                      },
                                    ],
                                  },
                                }}
                                style={{
                                  width: `${pillSize}px`,
                                  marginLeft: `${startFrom}px`,
                                }}
                              />
                            </Tippy>
                          );
                        })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <>
          {metadata && metadata.total && metadata.total > PAGINATION_LIMIT && (
            <section className="d-flex justify-content-center py-3">
              <Pagination
                className="pagination-bar"
                currentPage={pageNumber}
                totalCount={metadata.total}
                pageSize={PAGINATION_LIMIT}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  getActivityData({
                    page,
                  });
                }}
              />
            </section>
          )}
        </>
      </>
    );
  }
  return (
    <Alert variant="info" className="text-center mx-4">
      {NO_DATA.WORKSESSION}
    </Alert>
  );
};

ActivityListByDay.propTypes = {
  activityList: PropTypes.object,
  getActivityData: PropTypes.func,
  pageNumber: PropTypes.number,
  setCurrentPage: PropTypes.func,
};

export default ActivityListByDay;
