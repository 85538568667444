import { Component } from 'react';
import { Alert, Spinner } from 'react-bootstrap';

import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect, batch } from 'react-redux';
import PropTypes from 'prop-types';
import LoginForm from '../components/LoginForm';
import { userService } from '../services';
import { setPolicyLength, setTypesLength } from '../actions/timeOff';
import {
  INVALID_LOGIN,
  LOCAL_STORAGE_USER_DATA,
  LOCAL_STORAGE_USER_STATE,
  GENERAL,
  INVALID_WEB_LOGIN,
} from '../constants';
import hiveDeskLogoBlue from '../assets/img/HiveDesk_Logo_blue.svg';
import loginGraphic from '../assets/img/illustrations/login.svg';
import { ErrorBoundary, RedirectionAfterLogin } from '../components/common';
import { setAccountStatus, setCurrentPlan } from '../actions/billing';
import {
  setUserRole,
  setPrivacyAcceptanceStatus,
  setSubscription,
  fetchOnBoardProgress,
  fetchAccountDetails,
} from '../actions/user';
import { fetchTeamMembers } from '../actions/team';
import { trackJune } from '../utilities/analytics';
import {
  fetchNotification,
  fetchNotificationAll,
} from '../actions/notification';
import { fetchMemberProjects } from '../actions/projects';
import { resetWidget } from '../actions/timer';
import { fetchWsRequests } from '../actions/worksession';

class Login extends Component {
  state = {
    loggedIn: false,
    isSubmitting: false,
    isServerError: '',
    userData: {},
    selectTeam: true,
    isWebLogin: false,
  };

  componentDidMount() {
    const url = new URL(window.location.href);
    const webCode = url.searchParams.get('key');

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const cnfCode = params.get('cnf');
    if (cnfCode !== null) {
      userService
        .confirm_account({ cnfCode })
        .then((response) => {
          if (response.message === 'Success') {
            toast.success(GENERAL.ACCOUNT_CONFIRM_SUCCESS);
          } else {
            toast.error(response.message);
          }
        })
        .catch(() => {
          toast.error(GENERAL.ACCOUNT_CONFIRM_ERROR);
        });
    }
    if (webCode) {
      this.setState((state) => ({
        ...state,
        isWebLogin: true,
      }));
      userService
        .web_login({ webcode: webCode })
        .then((response) => {
          // Remove user details if exists
          localStorage.removeItem(LOCAL_STORAGE_USER_DATA);
          localStorage.removeItem(LOCAL_STORAGE_USER_STATE);

          if (response.userDetails) {
            const userData = response.userDetails;
            // Store user details
            localStorage.setItem(
              LOCAL_STORAGE_USER_DATA,
              JSON.stringify(userData)
            );

            let userRole = '';
            if (
              userData.subscriptionDetails.currentPlan !== null &&
              userData.subscriptionDetails.currentPlan.toLowerCase() ===
                'trial' &&
              userData.userStatus === 'cancel'
            ) {
              // if the account is cancelled, need to notify the user irrespective of default team
              // set default team as own id so that it will redirect to own dashboard
              userData.defaultTeam = {
                email: userData.email,
                firstName: userData.firstName,
                isMobileTracking: userData.isMobileTracking,
                lastName: userData.lastName,
                role: 'creator',
                teamOwnerId: userData._id,
                isDefault: true,
              };
            }
            /* if default team exists, default checkbox in settings need to be checked */
            if (userData.defaultTeam != null) {
              localStorage.setItem(
                'teamOwnerId',
                userData.defaultTeam.teamOwnerId
              );
              userData.defaultTeam.isDefault = true;
              userRole = userData.defaultTeam.role;
            }

            // Set state
            this.setState((state) => ({
              ...state,
              loggedIn: true,
              isSubmitting: false,
              isWebLogin: false,
              selectTeam: userData.defaultTeam === null,
            }));

            // Dispatch required actions
            const { dispatch } = this.props;
            batch(() => {
              // dispatch(fetchNotification());

              dispatch(fetchNotificationAll(userRole));

              dispatch(
                setPolicyLength({ policyLength: userData.timeOffPolicy })
              );
              dispatch(setTypesLength({ typesLength: userData.timeOffType }));
              dispatch(
                setAccountStatus({ accountStatus: userData.userStatus })
              );
              dispatch(setUserRole({ role: userRole }));
              dispatch(
                setPrivacyAcceptanceStatus({
                  policyAcceptanceStatus: userData.policyAcceptanceStatus,
                })
              );
              dispatch(setSubscription({ ...userData.subscriptionDetails }));
              dispatch(
                setCurrentPlan({
                  planName: userData.subscriptionDetails.currentPlan,
                })
              );
              // Reset timer widget
              dispatch(resetWidget());
            });

            // Check if it is a trail user and update LocalStorage USER_DATA
            if (
              userData.subscriptionDetails.currentPlan !== null &&
              userData.subscriptionDetails.currentPlan.toLowerCase() === 'trial'
            ) {
              dispatch(fetchOnBoardProgress());
            }

            // Get seat count and store to LS
            setTimeout(() => {
              dispatch(fetchMemberProjects());
              dispatch(fetchAccountDetails());
              // june
              trackJune('login');
            }, 3000);
          }
        })
        .catch((error) => {
          this.setState((state) => ({
            ...state,
            isSubmitting: false,
            loggedIn: false,
            isWebLogin: false,
            isServerError: INVALID_WEB_LOGIN,
          }));
        });
    }
  }

  handleLogin = (formdata) => {
    this.setState((state) => ({
      ...state,
      isSubmitting: true,
    }));

    userService
      .login({ ...formdata })
      .then((response) => {
        // Remove user details if exists
        localStorage.removeItem(LOCAL_STORAGE_USER_DATA);
        localStorage.removeItem(LOCAL_STORAGE_USER_STATE);
        if (response.userDetails) {
          const userData = response.userDetails;
          // Store user details
          localStorage.setItem(
            LOCAL_STORAGE_USER_DATA,
            JSON.stringify(userData)
          );

          let userRole = '';
          if (
            userData.subscriptionDetails.currentPlan !== null &&
            userData.subscriptionDetails.currentPlan.toLowerCase() ===
              'trial' &&
            userData.userStatus === 'cancel'
          ) {
            // if the account is cancelled, need to notify the user irrespective of default team
            // set default team as own id so that it will redirect to own dashboard
            userData.defaultTeam = {
              email: userData.email,
              firstName: userData.firstName,
              isMobileTracking: userData.isMobileTracking,
              lastName: userData.lastName,
              role: 'creator',
              teamOwnerId: userData._id,
              isDefault: true,
            };
          }
          /* if default team exists, default checkbox in settings need to be checked */
          if (userData.defaultTeam != null) {
            localStorage.setItem(
              'teamOwnerId',
              userData.defaultTeam.teamOwnerId
            );
            userData.defaultTeam.isDefault = true;
            userRole = userData.defaultTeam.role;
          }

          // Set state
          this.setState((state) => ({
            ...state,
            loggedIn: true,
            isSubmitting: false,
            selectTeam: userData.defaultTeam === null,
          }));

          // Dispatch required actions
          const { dispatch } = this.props;
          batch(() => {
            // dispatch(fetchNotification());

            dispatch(fetchNotificationAll(userRole));

            dispatch(setPolicyLength({ policyLength: userData.timeOffPolicy }));
            dispatch(setTypesLength({ typesLength: userData.timeOffType }));

            dispatch(setAccountStatus({ accountStatus: userData.userStatus }));
            dispatch(setUserRole({ role: userRole }));
            dispatch(
              setPrivacyAcceptanceStatus({
                policyAcceptanceStatus: userData.policyAcceptanceStatus,
              })
            );
            dispatch(setSubscription({ ...userData.subscriptionDetails }));
            dispatch(
              setCurrentPlan({
                planName: userData.subscriptionDetails.currentPlan,
              })
            );

            dispatch(
              fetchWsRequests({
                teamOwnerId: localStorage.getItem('teamOwnerId'),
              })
            );
            // Reset timer widget
            dispatch(resetWidget());
          });
          // Check if it is a trail user and update LocalStorage USER_DATA
          if (
            userData.subscriptionDetails.currentPlan !== null &&
            userData.subscriptionDetails.currentPlan.toLowerCase() === 'trial'
          ) {
            dispatch(fetchOnBoardProgress());
          }

          setTimeout(() => {
            dispatch(fetchMemberProjects());
            // Get seat count and store to LS
            dispatch(fetchAccountDetails());
            // Get team members and add to store
            if (
              userData.defaultTeam &&
              userData.defaultTeam.role !== 'member'
            ) {
              dispatch(fetchTeamMembers());
            }
            if (userData.defaultTeam !== null) {
              trackJune('login');
            }
          }, 5000);
        }
      })
      .catch((error) => {
        this.setState((state) => ({
          ...state,
          isSubmitting: false,
          loggedIn: false,
          isServerError: INVALID_LOGIN,
        }));
      });
  };

  render() {
    const { loggedIn, isSubmitting, isServerError, selectTeam, isWebLogin } =
      this.state;
    document.title = 'Login : HiveDesk';
    /* if default exists */
    if (!selectTeam) {
      return <RedirectionAfterLogin />;
    }
    if (!isSubmitting && loggedIn) {
      return <Redirect to="/team-selection" />;
    }

    return isWebLogin ? (
      <section className="d-flex align-items-center justify-content-center content-loader">
        <Spinner
          animation="border"
          className="text-primary"
          role="status"
          size="md"
        />
      </section>
    ) : (
      <ErrorBoundary>
        <section className="d-flex align-items-center bg-auth border-top border-top-2 border-primary vh-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 order-md-1 mb-5 mb-md-0 d-none d-sm-block">
                <div className="text-center">
                  <img
                    src={loginGraphic}
                    alt="HiveDesk"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-12 col-md-5 col-xl-4 offset-xl-2 offset-md-1 order-md-2 my-5">
                {isServerError && (
                  <Alert variant="danger">{isServerError}</Alert>
                )}
                <div className="text-center mb-5">
                  <img
                    src={hiveDeskLogoBlue}
                    alt="HiveDesk"
                    className="img-fluid"
                    width="250"
                  />
                </div>
                <LoginForm
                  handleLogin={this.handleLogin}
                  isSubmitting={isSubmitting}
                />
              </div>
            </div>
          </div>
        </section>
      </ErrorBoundary>
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func,
};
export default connect()(Login);
