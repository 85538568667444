import { toast } from 'react-toastify';
import {
  AUTH_KEY,
  AUTH_REFRESH_TOKEN_NAME,
  BASE_API_URL,
  USER_URLS,
  THIRD_PARTY_AUTH_KEY,
} from '../constants';
import { AppService, HTTPHeaders } from './app.service';
import {
  installTokens,
  makeEncodeFormData,
  clearSession,
  getSessionVariable,
} from '../helpers';

const options = AppService.options();

export const userService = {
  web_login: async (data) => {
    const URL = BASE_API_URL + USER_URLS.WEB_LOGIN;

    const headers = {
      headers: {
        Authorization: `Basic ${AUTH_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const response = await AppService.makeRequest(URL, {
      ...options,
      ...headers,
      body: makeEncodeFormData(data),
    });
    if (!response) {
      return;
    }

    if (response.code && response.code >= 400) {
      toast.error(response.message);
      return false;
    }
    installTokens(response);
    return response;
  },
  login: async (data) => {
    const URL = BASE_API_URL + USER_URLS.LOGIN;

    const headers = {
      headers: {
        Authorization: `Basic ${AUTH_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const response = await AppService.makeRequest(URL, {
      ...options,
      ...headers,
      body: makeEncodeFormData(data),
    });
    if (!response) {
      return;
    }

    if (response.code && response.code >= 400) {
      toast.error(response.message);
      return false;
    }
    installTokens(response);
    return response;
  },

  other_login: async (data) => {
    const URL = BASE_API_URL + USER_URLS.LOGIN;

    const headers = {
      headers: {
        Authorization: `Basic ${THIRD_PARTY_AUTH_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const response = await AppService.makeRequest(URL, {
      ...options,
      ...headers,
      body: makeEncodeFormData(data),
    });
    if (!response) {
      return;
    }

    if (response.code && response.code >= 400) {
      toast.error(response.message);
      return false;
    }
    installTokens(response);
    return response;
  },

  refresh_token: async () => {
    const headers = {
      headers: {
        Authorization: `Basic ${AUTH_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const data = {
      grant_type: AUTH_REFRESH_TOKEN_NAME,
      refresh_token: getSessionVariable(AUTH_REFRESH_TOKEN_NAME),
    };

    AppService.makeRequest(BASE_API_URL + USER_URLS.LOGIN, {
      ...options,
      ...headers,
      body: makeEncodeFormData(data),
    })
      .then((response) => {
        installTokens(response);
        return response;
      })
      .catch((error) => {
        clearSession();
        window.location.href = '/login';
        throw error;
      });
  },

  logout: async () => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const data = {
      refresh_token: getSessionVariable(AUTH_REFRESH_TOKEN_NAME),
    };
    await AppService.makeRequest(BASE_API_URL + USER_URLS.LOGOUT, {
      ...options,
      ...headers,
      body: makeEncodeFormData(data),
    });
    clearSession();
    window.location.href = '/login';
  },
  logoutWithRedirectFreeTrial: async () => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/x-www-form-urlencoded';
    const data = {
      refresh_token: getSessionVariable(AUTH_REFRESH_TOKEN_NAME),
    };
    await AppService.makeRequest(BASE_API_URL + USER_URLS.LOGOUT, {
      ...options,
      ...headers,
      body: makeEncodeFormData(data),
    });
    clearSession();
    window.location.href = '/freetrial';
  },
  forgot_password: async (data) => {
    const headers = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + USER_URLS.FORGOT_PW,
      {
        ...options,
        ...headers,
        body: makeEncodeFormData(data),
      }
    );
    return response;
  },
  user_details: async () => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + USER_URLS.USER,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  reset_password: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + USER_URLS.RESET_PW,
      {
        ...headers,
        ...options,
        body: JSON.stringify(data),
      }
    );
    return response;
  },

  member_registration: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + USER_URLS.MEMBER_REG,
      {
        ...headers,
        ...options,
        body: JSON.stringify(data),
      }
    );
    return response;
  },

  timezone_list: async () => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + USER_URLS.TIMEZONE,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },

  policy_acceptance: async () => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + USER_URLS.POLICY_ACCEPT,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  free_trial_registration: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + USER_URLS.FREE_TRIAL_REG,
      {
        ...headers,
        ...options,
        body: JSON.stringify(data),
      }
    );
    return response;
  },

  onboarding_progress: async () => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + USER_URLS.ONBOARDING_PROGRESS,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  confirm_account: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PATCH',
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + USER_URLS.CONFIRM_ACCOUNT}/${data.cnfCode}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  get_account_details: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL}${USER_URLS.ACCOUNT_DETAILS}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  card_status: async () => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + USER_URLS.CARD_STATUS,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  login_count: async () => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + USER_URLS.WEB_LOGIN_COUNT,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  crisp_event_update: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + USER_URLS.CRISP_EVENT_UPDATE,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
};
