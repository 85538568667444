import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ClientSearch = ({ updateClientList }) => {
  const handleSearch = (value) => {
    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      status: 'active',
      name: value,
    };
    updateClientList(params);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch(event.target.value);
    }
  };

  return (
    <div className="input-group mb-3">
      <input
        id="client-search"
        className="form-control"
        placeholder="Search"
        type="text"
        aria-label="Search"
        aria-describedby="Search"
        onKeyDown={(e) => handleKeyDown(e)}
      />
      <Button
        variant="primary"
        onClick={() =>
          handleSearch(document.getElementById('client-search').value)
        }
      >
        <span className="mdi mdi-magnify" />
      </Button>
    </div>
  );
};

ClientSearch.propTypes = {
  updateClientList: PropTypes.func,
};

export default ClientSearch;
