import { useLayoutEffect, useRef, memo } from 'react';
import { Collapse } from 'bootstrap';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import { setCollpasedSideNavbarToggle } from '../actions/collpasedSideNavbarToggle';
import { updateLocalStorage, getUserData } from '../helpers';
import { MASTER_LOGIN, PAGE_URLS, LOCAL_STORAGE_USER_DATA } from '../constants';
import hiveDeskLogoWhite from '../assets/img/hd-logo-white.png';
import hiveDeskLogoSmall from '../assets/img/hd-logo-white-small.png';
import hiveDeskLogo from '../assets/img/hd-logo-dark.svg';
import MyAccountDropdown from './MyAccountDropdown';
import OnboardingProgress from './OnboardingProgress';

const Navigation = ({ category }) => {
  const collapses = document.querySelectorAll('.navbar-nav .collapse');

  collapses.forEach((collapse) => {
    // Init collapses
    const collapseInstance = new Collapse(collapse, {
      toggle: false,
    });

    // Hide sibling collapses on `show.bs.collapse`
    collapse.addEventListener('show.bs.collapse', (e) => {
      e.stopPropagation();

      const closestCollapse = collapse.parentElement.closest('.collapse');
      const siblingCollapses = closestCollapse.querySelectorAll('.collapse');

      siblingCollapses.forEach((siblingCollapse) => {
        const siblingCollapseInstance = Collapse.getInstance(siblingCollapse);

        if (siblingCollapseInstance === collapseInstance) {
          return;
        }

        siblingCollapseInstance.hide();
      });
    });

    // Hide nested collapses on `hide.bs.collapse`
    collapse.addEventListener('hide.bs.collapse', (e) => {
      e.stopPropagation();

      const childCollapses = collapse.querySelectorAll('.collapse');

      childCollapses.forEach((childCollapse) => {
        const childCollapseInstance = Collapse.getInstance(childCollapse);

        childCollapseInstance.hide();
      });
    });
  });
  const dispatch = useDispatch();
  const checkWindowSize = () => {
    window.addEventListener('resize', (e) => {
      if (
        e.target.innerWidth > 767 &&
        getUserData('isSideNavbarCollapsedView')
      ) {
        dispatch(setCollpasedSideNavbarToggle(true));
      }
      if (
        e.target.innerWidth < 768 &&
        getUserData('isSideNavbarCollapsedView')
      ) {
        dispatch(setCollpasedSideNavbarToggle(false));
      }
    });
  };

  const ref = useRef(null);
  useLayoutEffect(() => {
    checkWindowSize();
    dispatch({ type: 'FETCHING_SIDE_NAVBAR_COLLAPSED_VIEW' });
    ref.current.style.setProperty('margin-top', '100%', '!important');
  }, []);

  const userDetails = useSelector((state) => state.userReducer);
  const timeOffDetails = useSelector((state) => state.timeOffReducer);
  const wsDetails = useSelector((state) => state.wsReducer);

  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  const billingDetails = useSelector((state) => state.billingReducer);
  const currentPlan = (
    (userDetails &&
      userDetails.subscription.currentPlan !== null &&
      userDetails.subscription.currentPlan) ||
    ''
  ).toLowerCase();
  const { accountStatus } = billingDetails;
  const isFreeTrial =
    (currentPlan === 'trial' && accountStatus !== 'cancel') || false;
  const isMasterPassword =
    sessionStorage.getItem(MASTER_LOGIN) === 'true' || false;
  const isTimesheetApproval = useSelector((state) => state.timesheetApproval);
  const isOverTimeEnabled = useSelector((state) => state.overTimeEnabled);
  const isSchedulingEnabled = useSelector((state) => state.scheduleEnabled);
  const isInvoiceEnabled = useSelector((state) => state.invoiceEnabled);
  const isManageOngoingSessions = useSelector(
    (state) => state.manageOngoingSessions
  );
  const { sideNavbarCollapsedView } = useSelector(
    (state) => state.collpasedSideNavbarToggle
  );
  // When menu & icon is single without submenu list
  const refs = useRef();
  const MenuWithoutSubMenuSingleInCollpasedView = ({ icon, text, link }) => (
    <>
      <li className="nav-item">
        <Link
          to={{ hash: '#' }}
          className="dropdown-toggle nav-link"
          ref={refs}
        >
          <i className={icon} />
        </Link>
        <Tippy
          placement="right"
          interactive
          interactiveBorder={20}
          content={
            <ul className="nav nav-sm flex-column">
              <li className="nav-item">
                <Link
                  to={link}
                  className="nav-link nav-link-color-grey ps-3 text-nowrap"
                >
                  {text}
                </Link>
              </li>
            </ul>
          }
          reference={refs}
          theme="light-border"
          {...(text === 'Dashboard' && {
            offset: [5],
          })}
        />
      </li>
    </>
  );

  MenuWithoutSubMenuSingleInCollpasedView.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
  };
  // When menu & icon with submenu list
  const refm = useRef();
  const MenuWithSubMenuInCollpasedView = ({
    icon,
    dropdownHeader,
    dropdownListItem = [],
  }) => (
    <li className="nav-item">
      <Link to={{ hash: '#' }} className="dropdown-toggle nav-link" ref={refm}>
        <i className={icon} />
      </Link>
      <Tippy
        placement="right"
        interactive
        content={
          <ul className="nav nav-sm flex-column">
            <li className="nav-item">
              <Link
                to={{ hash: '#' }}
                className="dropdown-header nav-link ps-3 pb-0 pt-0"
                style={{ fontSize: '15px', color: 'rgb(110, 132, 163)' }}
              >
                {dropdownHeader}
                <hr />
              </Link>
            </li>
            {dropdownListItem.map((item, i) => (
              <li className="nav-item" key={i}>
                {item.target ? (
                  <Link
                    to={{ pathname: item.link }}
                    className="nav-link nav-link-color-grey ps-3 text-nowrap"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.text}
                  </Link>
                ) : (
                  <Link
                    to={item.link}
                    className="nav-link nav-link-color-grey ps-3 text-nowrap"
                  >
                    {item.text}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        }
        reference={refm}
        theme="light-border"
      />
    </li>
  );

  MenuWithSubMenuInCollpasedView.propTypes = {
    icon: PropTypes.string,
    dropdownHeader: PropTypes.string,
    dropdownListItem: PropTypes.array,
  };

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      id="sidebar"
      className={
        isFreeTrial &&
        userDetails.role !== 'member' &&
        userDetails.freeTrialStatus
          ? `${
              sideNavbarCollapsedView
                ? 'navbar navbar-vertical navbar-vertical-sm fixed-start navbar-expand-md free-trial'
                : 'navbar-vertical fixed-left free-trial'
            }`
          : `${
              sideNavbarCollapsedView
                ? 'navbar navbar-vertical navbar-vertical-sm fixed-start navbar-expand-md'
                : 'navbar-vertical fixed-left'
            }`
      }
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Brand href="#home">
        {!sideNavbarCollapsedView && (
          <img
            src={hiveDeskLogoWhite}
            alt="HiveDesk"
            className="d-none d-md-block img-fluid"
          />
        )}

        <div className="col-auto">
          {!sideNavbarCollapsedView ? (
            <Link
              to={{ hash: '#' }}
              className="d-none d-md-block img-fluid"
              onClick={() => {
                updateLocalStorage({ isSideNavbarCollapsedView: true });
                dispatch(setCollpasedSideNavbarToggle(true));
              }}
            >
              <i
                className="mdi mdi-format-indent-decrease text-white"
                style={{ fontSize: '20px', marginLeft: '16px' }}
              />
            </Link>
          ) : (
            <Link
              to={{ hash: '#' }}
              className="d-none d-md-block img-fluid position-relative bound-for-navbar-open-icon"
              onClick={() => {
                updateLocalStorage({ isSideNavbarCollapsedView: false });
                dispatch(setCollpasedSideNavbarToggle(false));
              }}
            >
              <img
                src={hiveDeskLogoSmall}
                alt="HiveDesk"
                className="d-none d-md-block img-fluid brand-hd-small-icon"
              />
              <i className="mdi mdi-format-indent-increase text-white navbar-open-icon" />
            </Link>
          )}
        </div>
        <img
          src={hiveDeskLogo}
          alt="HiveDesk"
          className="d-block d-md-none img-fluid"
        />
      </Navbar.Brand>
      <span className="d-block d-sm-none">
        <MyAccountDropdown />
      </span>
      <Navbar.Collapse
        id="basic-navbar-nav"
        className={`${
          sideNavbarCollapsedView ? 'navbar-icon-view-scroll' : ''
        }`}
      >
        {sideNavbarCollapsedView ? (
          <ul className="navbar-nav">
            {userDetails.role !== 'member' && (
              <MenuWithoutSubMenuSingleInCollpasedView
                icon="mdi mdi-view-dashboard"
                text="Dashboard"
                link={PAGE_URLS.DASHBOARD}
              />
            )}

            <MenuWithoutSubMenuSingleInCollpasedView
              icon="mdi mdi-timelapse"
              text="Track"
              link={PAGE_URLS.TRACK}
            />

            {userDetails.role !== 'member' && (
              <MenuWithoutSubMenuSingleInCollpasedView
                icon="mdi mdi-chart-timeline"
                text="Timeline"
                link={PAGE_URLS.TIMELINE}
              />
            )}

            {userDetails.role === 'creator' && (
              <MenuWithoutSubMenuSingleInCollpasedView
                icon="mdi mdi-account-multiple-outline"
                text="Clients"
                link={PAGE_URLS.CLIENTS}
              />
            )}

            <MenuWithSubMenuInCollpasedView
              icon="mdi mdi-folder-open"
              dropdownHeader="Projects"
              dropdownListItem={[
                { text: 'Active', link: PAGE_URLS.PROJECTS.ACTIVE },
                { text: 'Archived', link: '/projects/archived' },
              ]}
            />
            {userDetails.role !== 'member' && (
              <MenuWithSubMenuInCollpasedView
                icon="mdi mdi-account-group-outline"
                dropdownHeader="Team"
                dropdownListItem={[
                  { text: 'Active Members', link: PAGE_URLS.TEAM.ACTIVE },
                  { text: 'Inactive Members', link: PAGE_URLS.TEAM.INACTIVE },
                  { text: 'Team Groups', link: PAGE_URLS.TEAM.TEAM_GROUPS },
                ]}
              />
            )}

            {isSchedulingEnabled && (
              <MenuWithSubMenuInCollpasedView
                icon="mdi mdi-timetable"
                dropdownHeader="Schedules"
                dropdownListItem={[
                  ...[
                    { text: 'Shifts', link: `${PAGE_URLS.SCHEDULING}/shifts` },
                  ],
                  ...(userDetails.role !== 'member'
                    ? [
                        {
                          text: 'Holidays',
                          link: `${PAGE_URLS.SCHEDULING}/holidays`,
                        },
                        {
                          text: 'Attendance',
                          link: `${PAGE_URLS.REPORTS}/attendance`,
                        },
                      ]
                    : []),
                ]}
              />
            )}

            <MenuWithSubMenuInCollpasedView
              icon="mdi mdi-folder-clock-outline"
              dropdownHeader="Timesheet"
              dropdownListItem={[
                ...[
                  { text: 'Detailed timesheet', link: '/timesheet/detailed' },
                  {
                    text: 'Summarized timesheet',
                    link: '/timesheet/summarized',
                  },
                ],
                ...(isTimesheetApproval
                  ? [
                      {
                        text: 'View',
                        link: `/timesheet/view`,
                      },
                    ]
                  : []),
                ...(isTimesheetApproval &&
                (userDetails.role === 'creator' ||
                  userDetails.role === 'full_manager')
                  ? [
                      {
                        text: 'Approval',
                        link: `/timesheet/approval`,
                      },
                    ]
                  : []),
                ...(userDetails.role !== 'member'
                  ? [
                      {
                        text: `Worksession Requests (${
                          wsDetails.pendingRequests || 0
                        })`,
                        link: `/timesheet/change-requests`,
                      },
                    ]
                  : []),
              ]}
            />

            {userDetails.role === 'creator' ||
            userDetails.role === 'full_manager' ? (
              <MenuWithSubMenuInCollpasedView
                icon="mdi mdi-timer-off"
                dropdownHeader="Time Off"
                dropdownListItem={[
                  ...[
                    {
                      text: 'Policy',
                      link: `${PAGE_URLS.TIMEOFF.POLICY}`,
                    },
                  ],
                  ...(timeOffDetails && timeOffDetails.policyLength > 0
                    ? [
                        {
                          text: 'Types',
                          link: `${PAGE_URLS.TIMEOFF.TYPES}`,
                        },
                      ]
                    : []),
                  ...(timeOffDetails &&
                  timeOffDetails.policyLength > 0 &&
                  timeOffDetails.typesLength > 0
                    ? [
                        {
                          text: 'Requests',
                          link: `${PAGE_URLS.TIMEOFF.REQUESTS}`,
                        },
                      ]
                    : []),
                ]}
              />
            ) : (
              timeOffDetails &&
              timeOffDetails.policyLength > 0 &&
              timeOffDetails.typesLength > 0 && (
                <MenuWithSubMenuInCollpasedView
                  icon="mdi mdi-timer-off"
                  dropdownHeader="Time Off"
                  dropdownListItem={[
                    ...[
                      {
                        text: 'Requests',
                        link: `${PAGE_URLS.TIMEOFF.REQUESTS}`,
                      },
                    ],
                  ]}
                />
              )
            )}

            <MenuWithSubMenuInCollpasedView
              icon="mdi mdi-clipboard-pulse-outline"
              dropdownHeader="Activity"
              dropdownListItem={[
                ...[
                  {
                    text: 'Member Activity',
                    link: `${PAGE_URLS.ACTIVITY.MEMBER}`,
                  },
                ],
                ...(userDetails.role !== 'member'
                  ? [
                      {
                        text: 'Team Activity',
                        link: `${PAGE_URLS.ACTIVITY.TEAM}`,
                      },
                    ]
                  : []),
                ...(isManageOngoingSessions &&
                (userDetails.role === 'creator' ||
                  userDetails.role === 'full_manager')
                  ? [
                      {
                        text: 'Ongoing Sessions',
                        link: `${PAGE_URLS.ACTIVITY.ONGOING_SESSIONS}`,
                      },
                    ]
                  : []),
              ]}
            />

            <MenuWithSubMenuInCollpasedView
              icon="mdi mdi-file-document-box-multiple-outline"
              dropdownHeader="Reports"
              dropdownListItem={[
                ...(isOverTimeEnabled && userDetails.role !== 'member'
                  ? [
                      {
                        text: 'Overtime report',
                        link: `/reports/overtime`,
                      },
                    ]
                  : []),
                ...(userDetails.role !== 'member'
                  ? [
                      {
                        text: 'Screenshot report',
                        link: `/reports/screenshot`,
                      },
                    ]
                  : []),
                ...[
                  {
                    text: 'Charts',
                    link: `/reports/charts`,
                  },
                ],
              ]}
            />
            {isInvoiceEnabled && userDetails.role === 'creator' && (
              <MenuWithoutSubMenuSingleInCollpasedView
                icon="mdi mdi-cash-usd"
                text="Invoices"
                link={PAGE_URLS.INVOICES}
              />
            )}

            <MenuWithSubMenuInCollpasedView
              icon="mdi mdi-shield-account-outline"
              dropdownHeader="Settings"
              dropdownListItem={[
                ...(userDetails.role === 'creator'
                  ? [
                      {
                        text: 'General',
                        link: `/settings/general`,
                      },
                    ]
                  : []),
                ...[
                  {
                    text: 'Profile',
                    link: `/settings/profile`,
                  },
                  {
                    text: 'Password',
                    link: `/settings/password`,
                  },
                ],
                ...(userDetails.role === 'creator'
                  ? [
                      {
                        text: 'Billing',
                        link: `/settings/billing`,
                      },
                    ]
                  : []),
                ...[
                  {
                    text: 'Teams',
                    link: `/settings/teams`,
                  },
                ],
                ...(userDetails.role !== 'member'
                  ? [
                      {
                        text: 'Email summaries',
                        link: `/settings/email-summaries`,
                      },
                    ]
                  : []),

                ...(userDetails.role === 'creator'
                  ? [
                      ...(userData &&
                      userData.integrations &&
                      userData.integrations.length > 0
                        ? [
                            {
                              text: 'Integrations',
                              link: `/settings/integration-status`,
                            },
                          ]
                        : [
                            {
                              text: 'Integrations',
                              link: `/settings/integration-list`,
                            },
                          ]),
                    ]
                  : []),
              ]}
            />
            <MenuWithSubMenuInCollpasedView
              icon="mdi mdi mdi-help-circle-outline mt-1"
              dropdownHeader="Help"
              dropdownListItem={[
                ...(userDetails.role !== 'member'
                  ? [
                      {
                        text: 'Admin Guide',
                        link: `https://www.hivedesk.com/admin-user-guide/`,
                        target: '_blank',
                      },
                    ]
                  : []),
                ...[
                  {
                    text: 'Member Guide',
                    link: `https://www.hivedesk.com/team-member-user-guide/`,
                    target: '_blank',
                  },
                  {
                    text: 'Support',
                    link: `https://support.hivedesk.com/`,
                    target: '_blank',
                  },
                ],
              ]}
            />
            {isFreeTrial && userDetails.role !== 'member' && (
              <li className="nav-item">
                <Link to={{ hash: '#' }} className="nav-link">
                  <Tippy
                    placement="right"
                    content={<OnboardingProgress />}
                    interactive
                    theme="light-border"
                  >
                    <i className="mdi mdi-progress-clock" />
                  </Tippy>
                </Link>
              </li>
            )}
          </ul>
        ) : (
          <ul className="navbar-nav hd-sidebar-nav">
            {userDetails.role !== 'member' && (
              <li className="nav-item">
                <Link to={PAGE_URLS.DASHBOARD} className="nav-link">
                  <i className="mdi mdi-view-dashboard" /> Dashboard
                </Link>
              </li>
            )}
            <li className="nav-item">
              <Link to={PAGE_URLS.TRACK} className="nav-link">
                <i className="mdi mdi-timelapse" /> Track
              </Link>
            </li>

            {userDetails.role !== 'member' && (
              <li className="nav-item">
                <Link to={PAGE_URLS.TIMELINE} className="nav-link">
                  <i className="mdi mdi-chart-timeline" /> Timeline
                </Link>
              </li>
            )}

            {userDetails.role === 'creator' && (
              <li className="nav-item">
                <Link to={PAGE_URLS.CLIENTS} className="nav-link">
                  <i className="mdi mdi-account-multiple-outline" /> Clients
                </Link>
              </li>
            )}
            <li className="nav-item nav-projects">
              <a
                className="nav-link"
                href="#projects"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded={category === 'Projects'}
                aria-controls="projects"
              >
                <i className="mdi mdi-folder-open" /> Projects
              </a>
              <div
                className={
                  category === 'Projects' ? 'collapse show' : 'collapse'
                }
                id="projects"
              >
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to={PAGE_URLS.PROJECTS.ACTIVE}
                      className="nav-link nav-link-color-grey "
                    >
                      Active
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      to="/projects/archived"
                      className="nav-link nav-link-color-grey"
                    >
                      Archived
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            {userDetails.role !== 'member' && (
              <li className="nav-item nav-team">
                <a
                  className="nav-link"
                  href="#team"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded={category === 'Team'}
                  aria-controls="projects"
                >
                  <i className="mdi mdi-account-group-outline" /> Team
                </a>
                <div
                  className={category === 'Team' ? 'collapse show' : 'collapse'}
                  id="team"
                >
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to={PAGE_URLS.TEAM.ACTIVE}
                        className="nav-link nav-link-color-grey"
                      >
                        Active Members
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={PAGE_URLS.TEAM.INACTIVE}
                        className="nav-link nav-link-color-grey"
                      >
                        Inactive Members
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={PAGE_URLS.TEAM.TEAM_GROUPS}
                        className="nav-link nav-link-color-grey"
                      >
                        Team Groups
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            )}
            {isSchedulingEnabled && (
              <li className="nav-item nav-scheduling">
                <a
                  className="nav-link"
                  href="#schedules"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded={category === 'Schedules'}
                  aria-controls="schedules"
                >
                  <i className="mdi mdi-timetable" /> Schedules
                </a>
                <div
                  className={
                    category === 'Schedules' ? 'collapse show' : 'collapse'
                  }
                  id="schedules"
                >
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        to={`${PAGE_URLS.SCHEDULING}/shifts`}
                        className="nav-link nav-link-color-grey"
                      >
                        Shifts
                      </Link>
                    </li>
                    {userDetails.role !== 'member' && (
                      <li className="nav-item">
                        <Link
                          to={`${PAGE_URLS.SCHEDULING}/holidays`}
                          className="nav-link nav-link-color-grey"
                        >
                          Holidays
                        </Link>
                        <li className="nav-item">
                          <Link
                            to={`${PAGE_URLS.REPORTS}/attendance`}
                            className="nav-link nav-link-color-grey"
                          >
                            Attendance
                          </Link>
                        </li>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            )}

            <li className="nav-item nav-timesheet">
              <a
                className="nav-link"
                href="#timesheet"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded={category === 'Timesheet'}
                aria-controls="timesheet"
              >
                <i className="mdi mdi-folder-clock-outline" /> Timesheet
              </a>
              <div
                className={
                  category === 'Timesheet' ? 'collapse show' : 'collapse'
                }
                id="timesheet"
              >
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/timesheet/detailed"
                      className="nav-link nav-link-color-grey"
                    >
                      Detailed timesheet
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/timesheet/summarized"
                      className="nav-link nav-link-color-grey"
                    >
                      Summarized timesheet
                    </Link>
                  </li>
                  {isTimesheetApproval && (
                    <>
                      <li className="nav-item">
                        <Link
                          to="/timesheet/view"
                          className="nav-link nav-link-color-grey"
                        >
                          View
                        </Link>
                      </li>
                      {(userDetails.role === 'creator' ||
                        userDetails.role === 'full_manager') && (
                        <li className="nav-item">
                          <Link
                            to="/timesheet/approval"
                            className="nav-link nav-link-color-grey"
                          >
                            Approval
                          </Link>
                        </li>
                      )}
                    </>
                  )}
                  {userDetails.role !== 'member' && (
                    <li className="nav-item">
                      <Link
                        to="/timesheet/change-requests"
                        className="nav-link nav-link-color-grey"
                      >
                        Worksession Requests ({wsDetails.pendingRequests || 0})
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
            {userDetails.role === 'creator' ||
            userDetails.role === 'full_manager' ? (
              <>
                {/* For account owner and full manager login */}
                <li className="nav-item nav-timeoff">
                  <a
                    className="nav-link"
                    href="#timeOff"
                    data-bs-toggle="collapse"
                    role="button"
                    aria-expanded={category === 'Time Off'}
                    aria-controls="timeOff"
                  >
                    <i className="mdi mdi-timer-off" /> Time Off
                  </a>
                  <div
                    className={
                      category === 'Time Off' ? 'collapse show' : 'collapse'
                    }
                    id="timeOff"
                  >
                    <ul className="nav nav-sm flex-column">
                      <li className="nav-item">
                        <Link
                          to={`${PAGE_URLS.TIMEOFF.POLICY}`}
                          className="nav-link nav-link-color-grey"
                        >
                          Policy
                        </Link>
                      </li>
                      {timeOffDetails && timeOffDetails.policyLength > 0 && (
                        <li className="nav-item">
                          <Link
                            to={`${PAGE_URLS.TIMEOFF.TYPES}`}
                            className="nav-link nav-link-color-grey"
                          >
                            Types
                          </Link>
                        </li>
                      )}
                      {timeOffDetails &&
                        timeOffDetails.policyLength > 0 &&
                        timeOffDetails.typesLength > 0 && (
                          <li className="nav-item">
                            <Link
                              to={`${PAGE_URLS.TIMEOFF.REQUESTS}`}
                              className="nav-link nav-link-color-grey"
                            >
                              Requests
                            </Link>
                          </li>
                        )}
                    </ul>
                  </div>
                </li>
              </>
            ) : (
              <>
                {/* For member and manager login */}
                {timeOffDetails &&
                  timeOffDetails.policyLength > 0 &&
                  timeOffDetails.typesLength > 0 && (
                    <li className="nav-item nav-timeoff">
                      <a
                        className="nav-link"
                        href="#timeoff"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded={category === 'Timeoff'}
                        aria-controls="timeoff"
                      >
                        <i className="mdi mdi-timelapse" /> Time Off
                      </a>
                      <div
                        className={
                          category === 'Timeoff' ? 'collapse show' : 'collapse'
                        }
                        id="timeoff"
                      >
                        <ul className="nav nav-sm flex-column">
                          <li className="nav-item">
                            <Link
                              to={`${PAGE_URLS.TIMEOFF.REQUESTS}`}
                              className="nav-link nav-link-color-grey"
                            >
                              Requests
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}
              </>
            )}
            <li className="nav-item nav-activity">
              <a
                className="nav-link"
                href="#activity"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded={category === 'Activity'}
                aria-controls="activity"
              >
                <i className="mdi mdi-clipboard-pulse-outline" /> Activity
              </a>
              <div
                className={
                  category === 'Activity' ? 'collapse show' : 'collapse'
                }
                id="activity"
              >
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to={PAGE_URLS.ACTIVITY.MEMBER}
                      className="nav-link nav-link-color-grey"
                    >
                      Member Activity
                    </Link>
                  </li>
                  {userDetails.role !== 'member' && (
                    <li className="nav-item">
                      <Link
                        to={PAGE_URLS.ACTIVITY.TEAM}
                        className="nav-link nav-link-color-grey"
                      >
                        Team Activity
                      </Link>
                    </li>
                  )}
                  {isManageOngoingSessions &&
                    (userDetails.role === 'creator' ||
                      userDetails.role === 'full_manager') && (
                      <li className="nav-item">
                        <Link
                          to={PAGE_URLS.ACTIVITY.ONGOING_SESSIONS}
                          className="nav-link nav-link-color-grey"
                        >
                          Ongoing Sessions
                        </Link>
                      </li>
                    )}
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#reports"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded={category === 'Reports'}
                aria-controls="reports"
              >
                <i className="mdi mdi-file-document-box-multiple-outline" />{' '}
                Reports
              </a>
              <div
                className={
                  category === 'Reports' ? 'collapse show' : 'collapse'
                }
                id="reports"
              >
                <ul className="nav nav-sm flex-column">
                  {isOverTimeEnabled && userDetails.role !== 'member' && (
                    <li className="nav-item">
                      <Link
                        to="/reports/overtime"
                        className="nav-link nav-link-color-grey "
                      >
                        Overtime report
                      </Link>
                    </li>
                  )}
                  {userDetails.role !== 'member' && (
                    <li className="nav-item">
                      <Link
                        to="/reports/screenshot"
                        className="nav-link nav-link-color-grey"
                      >
                        Screenshot report
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link
                      to="/reports/charts"
                      className="nav-link nav-link-color-grey "
                    >
                      Charts
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            {isInvoiceEnabled && userDetails.role === 'creator' && (
              <li className="nav-item">
                <Link to={PAGE_URLS.INVOICES} className="nav-link">
                  <i className="mdi mdi-cash-usd" /> Invoices
                </Link>
              </li>
            )}
            <li className="nav-item">
              <a
                className="nav-link"
                href="#settings"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded={category === 'Settings'}
                aria-controls="settings"
              >
                <i className="mdi mdi-shield-account-outline" /> Settings
              </a>
              <div
                className={
                  category === 'Settings' ? 'collapse show' : 'collapse'
                }
                id="settings"
              >
                <ul className="nav nav-sm flex-column">
                  {userDetails.role === 'creator' && (
                    <li className="nav-item">
                      <Link
                        to="/settings/general"
                        className="nav-link nav-link-color-grey"
                      >
                        General
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link
                      to="/settings/profile"
                      className="nav-link nav-link-color-grey"
                    >
                      Profile
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/settings/password"
                      className="nav-link nav-link-color-grey"
                    >
                      Password
                    </Link>
                  </li>
                  {userDetails.role === 'creator' && (
                    <li className="nav-item">
                      <Link
                        to="/settings/billing"
                        className="nav-link nav-link-color-grey"
                      >
                        Billing
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link
                      to="/settings/teams"
                      className="nav-link nav-link-color-grey"
                    >
                      Teams
                    </Link>
                  </li>
                  {userDetails.role !== 'member' && (
                    <li className="nav-item">
                      <Link
                        to="/settings/email-summaries"
                        className="nav-link nav-link-color-grey"
                      >
                        Email summaries
                      </Link>
                    </li>
                  )}
                  {userDetails.role === 'creator' && (
                    <li className="nav-item">
                      {userData &&
                      userData.integrations &&
                      userData.integrations.length > 0 ? (
                        <Link
                          to="/settings/integration-status"
                          className="nav-link nav-link-color-grey"
                        >
                          Integrations
                        </Link>
                      ) : (
                        <Link
                          to="/settings/integration-list"
                          className="nav-link nav-link-color-grey"
                        >
                          Integrations
                        </Link>
                      )}
                    </li>
                  )}
                </ul>
              </div>
            </li>
            <li className="nav-item nav-help">
              <a
                className="nav-link"
                href="#help"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded={category === 'Help'}
                aria-controls="help"
              >
                <i className="mdi mdi-help-circle-outline mt-1" /> Help
              </a>
              <div
                className={category === 'Help' ? 'collapse show' : 'collapse'}
                id="help"
              >
                <ul className="nav nav-sm flex-column">
                  {userDetails.role !== 'member' && (
                    <li className="nav-item">
                      <a
                        href="https://www.hivedesk.com/admin-user-guide/"
                        className="nav-link nav-link-color-grey"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Admin Guide
                      </a>
                    </li>
                  )}
                  <li className="nav-item">
                    <a
                      href="https://www.hivedesk.com/team-member-user-guide/"
                      className="nav-link nav-link-color-grey"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Member Guide
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://support.hivedesk.com/"
                      className="nav-link nav-link-color-grey"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Support
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            {isFreeTrial && userDetails.role !== 'member' && (
              <OnboardingProgress />
            )}
          </ul>
        )}

        <div className="mt-auto" ref={ref} />
        {isMasterPassword && (
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/changelog" className="nav-link">
                <i className="mdi mdi-clock-alert-outline" /> Changelog
                <span
                  className=" badge bg-primary capitalize"
                  style={{ marginLeft: '.3rem' }}
                >
                  v4.1.5
                </span>
              </Link>
            </li>
          </ul>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};
Navigation.propTypes = {
  category: PropTypes.string,
};

export default memo(Navigation);
