import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PAGE_URLS } from '../constants';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Loading from '../components/Loader';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Clients from '../pages/Clients';
import Project from '../pages/Project';
import ArchivedProject from '../pages/ArchivedProject';
import Team from '../pages/Team';
import TeamGroups from '../pages/TeamGroups';
import Inactiveteam from '../pages/InactiveTeam';
import DetailedTimesheet from '../pages/Timesheet';
import SummarizedTimesheet from '../pages/SummarizedTimesheet';
import TimesheetView from '../pages/TimesheetView';
import TimesheetApproval from '../pages/TimesheetApproval';
import WorksessionEditRequests from '../pages/WorksessionEditRequests';
import MemberActivity from '../pages/MemberActivity';
import TeamDateRangeActivity from '../pages/TeamDateRangeActivity';
import ScreenshotReport from '../pages/ScreenshotReport';
import Charts from '../pages/Charts';
import Profile from '../pages/account_settings/Profile';
import ChangePassword from '../pages/account_settings/ChangePassword';
import TeamSettings from '../pages/account_settings/Teams';
import FeatureSettings from '../pages/account_settings/FeatureSettings';
import Billing from '../pages/account_settings/Billing';
import EmailSummaries from '../pages/account_settings/EmailSummaries';
import OvertimeWeeklyReport from '../pages/features/overtime/Report';
import Attendance from '../pages/features/attendance/Report';
// Tracking
import Tracking from '../pages/Tracking';

const ForgotPassword = lazy(() => import('../pages/user/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/user/ResetPassword'));
const MemberRegistration = lazy(() =>
  import('../pages/user/MemberRegistration')
);
const FreeTrialRegistration = lazy(() =>
  import('../pages/user/FreetrialRegistration')
);

const FreeTrialRegistrationNew = lazy(() =>
  import('../pages/user/FreetrialRegistrationNew')
);

// Team selection - Multi team user
const TeamSelection = lazy(() => import('../pages/TeamSelection'));

// Projects
const CreateProject = lazy(() => import('../pages/CreateProject'));
const ProjectOverview = lazy(() => import('../pages/ProjectOverview'));
const ProjectTasks = lazy(() => import('../pages/ProjectTasks'));
const ProjectTasksBoard = lazy(() => import('../pages/ProjectTasksBoard'));
const ProjectTimeline = lazy(() => import('../pages/ProjectTimeline'));
const OnboardSurvey = lazy(() => import('../pages/OnboardSurvey'));
const ProjectSettings = lazy(() => import('../pages/ProjectSettings'));
const ProjectTeamMembers = lazy(() => import('../pages/ProjectTeamMembers'));
const ProjectWorkSessions = lazy(() => import('../pages/ProjectWorkSessions'));
const ProjectEditWorkSession = lazy(() =>
  import('../pages/ProjectEditWorkSession')
);

// Team
const Invite = lazy(() => import('../pages/Invite'));
const MemberProjects = lazy(() => import('../pages/MemberProjects'));
const MemberEditWorkSession = lazy(() =>
  import('../pages/MemberEditWorkSession')
);

// Scheduling
const Scheduling = lazy(() => import('../pages/Scheduling'));
const Timeline = lazy(() => import('../pages/Timeline'));
const Holidays = lazy(() => import('../pages/Holidays'));

const Leaves = lazy(() => import('../pages/LeaveManagement'));
const PolicyMangement = lazy(() => import('../pages/PolicyManagement'));
const TimeOffTypes = lazy(() => import('../pages/TimeOffTypes'));

// Activity
const TeamDayActivity = lazy(() => import('../pages/TeamDayActivity'));
const OngoingSessions = lazy(() => import('../pages/OngoingSessions'));

// Reports
const ViewScreenshots = lazy(() => import('../pages/ViewScreenshots'));

// Settings
const CardUpdateReturn = lazy(() =>
  import('../pages/account_settings/CardUpdateReturn')
);
const AccountClosed = lazy(() =>
  import('../pages/account_settings/AccountClosed')
);
const TrialUpgradeReturn = lazy(() =>
  import('../pages/account_settings/FreeTrialUpgradeReturn')
);
const ReactivateReturn = lazy(() =>
  import('../pages/account_settings/ReactivateReturn')
);
const AccountReactivated = lazy(() =>
  import('../pages/account_settings/AccountReactivated')
);

// Asana Integration
const AsanaReturn = lazy(() => import('../pages/integrations/AsanaReturn'));
const IntegrationList = lazy(() =>
  import('../pages/integrations/IntegrationList')
);
const IntegrationStatus = lazy(() =>
  import('../pages/integrations/IntegratedFiles')
);

// Zapier Integration
const ZapierReturn = lazy(() =>
  import('../pages/integrations/ZapierReturnUrl')
);

// Invoice
const Invoice = lazy(() => import('../pages/features/invoice/List'));
const EditInvoice = lazy(() => import('../pages/features/invoice/Edit'));
const ViewInvoice = lazy(() => import('../pages/features/invoice/View'));

// Change log
const Changelog = lazy(() => import('../pages/Changelog'));

// Not Found
const NotFound = lazy(() => import('../pages/NotFound'));

const Routes = () => {
  const userDetails = useSelector((state) => state.userReducer);
  const billing = useSelector((state) => state.billingReducer);
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute exact path="/login/invite" component={Invite} />
          <PublicRoute exact path="/register" component={MemberRegistration} />
          <PublicRoute exact path="/user/confirm/:cnfCode" component={Login} />
          <PublicRoute exact path="/weblogin" component={Login} />
          <PublicRoute exact path="/zapier-return" component={ZapierReturn} />
          <PublicRoute
            exact
            path="/freetrial"
            component={FreeTrialRegistration}
          />
          <PublicRoute
            exact
            path="/freetrial-new"
            component={FreeTrialRegistrationNew}
          />
          <PublicRoute exact path="/error" component={NotFound} />
          <PrivateRoute
            exact
            path="/team-selection"
            component={TeamSelection}
          />
          {userDetails.role !== 'member' && (
            <PrivateRoute
              exact
              path={PAGE_URLS.DASHBOARD}
              component={Dashboard}
            />
          )}
          {userDetails.role === 'creator' && (
            <PrivateRoute exact path={PAGE_URLS.CLIENTS} component={Clients} />
          )}
          <PrivateRoute exact path="/changelog" component={Changelog} />
          <PrivateRoute exact path={PAGE_URLS.TEAM.ACTIVE} component={Team} />
          <PrivateRoute
            exact
            path={PAGE_URLS.TEAM.INACTIVE}
            component={Inactiveteam}
          />
          <PrivateRoute
            exact
            path={PAGE_URLS.TEAM.TEAM_GROUPS}
            component={TeamGroups}
          />
          <PrivateRoute
            exact
            path="/team/projects/:id"
            component={MemberProjects}
          />
          {userDetails.role !== 'member' && (
            <PrivateRoute
              exact
              path="/team/worksession/:id"
              component={MemberEditWorkSession}
            />
          )}
          <PrivateRoute
            exact
            path={PAGE_URLS.PROJECTS.ACTIVE}
            component={Project}
          />
          <PrivateRoute
            exact
            path="/projects/archived"
            component={ArchivedProject}
          />
          {userDetails.role !== 'member' && (
            <PrivateRoute
              exact
              path="/projects/create"
              component={CreateProject}
            />
          )}
          <PrivateRoute
            exact
            path="/projects/:id/overview"
            component={ProjectOverview}
          />{' '}
          <PrivateRoute
            exact
            path="/projects/:id/tasks"
            component={ProjectTasks}
          />
          <PrivateRoute exact path="/onboarding" component={OnboardSurvey} />
          <PrivateRoute
            exact
            path="/projects/:id/task-list"
            component={ProjectTasks}
          />
          <PrivateRoute
            exact
            path="/projects/:id/task-board"
            component={ProjectTasksBoard}
          />
          <PrivateRoute
            exact
            path="/projects/:id/timeline"
            component={ProjectTimeline}
          />
          <PrivateRoute
            exact
            path="/projects/:id/team-members"
            component={ProjectTeamMembers}
          />
          <PrivateRoute
            exact
            path="/projects/:id/worksessions"
            component={ProjectWorkSessions}
          />
          <PrivateRoute
            exact
            path="/projects/:id/edit-worksession"
            component={ProjectEditWorkSession}
          />
          <PrivateRoute
            exact
            path="/projects/:id/settings"
            component={ProjectSettings}
          />
          <PrivateRoute
            exact
            path="/projects/:id/settings"
            component={ProjectSettings}
          />
          <PrivateRoute
            exact
            path="/timesheet/detailed"
            component={DetailedTimesheet}
          />
          <PrivateRoute
            exact
            path="/timesheet/summarized"
            component={SummarizedTimesheet}
          />
          <PrivateRoute
            exact
            path="/timesheet/view"
            component={TimesheetView}
          />
          {(userDetails.role === 'creator' ||
            userDetails.role === 'full_manager') && (
            <PrivateRoute
              exact
              path="/timesheet/approval"
              component={TimesheetApproval}
            />
          )}
          {userDetails.role !== 'member' && (
            <PrivateRoute
              exact
              path="/timesheet/change-requests"
              component={WorksessionEditRequests}
            />
          )}
          <PrivateRoute
            exact
            path={PAGE_URLS.ACTIVITY.MEMBER}
            component={MemberActivity}
          />
          <PrivateRoute
            exact
            path="/activity/worksession"
            component={ViewScreenshots}
          />
          {userDetails.role !== 'member' && (
            <PrivateRoute
              exact
              path={PAGE_URLS.ACTIVITY.TEAM}
              component={TeamDateRangeActivity}
            />
          )}
          {userDetails.role !== 'member' && (
            <PrivateRoute
              exact
              path={PAGE_URLS.ACTIVITY.ONGOING_SESSIONS}
              component={OngoingSessions}
            />
          )}
          <PrivateRoute exact path="/schedules/shifts" component={Scheduling} />
          <PrivateRoute exact path="/timeline" component={Timeline} />
          <PrivateRoute exact path="/track" component={Tracking} />
          <PrivateRoute exact path="/timeoff/requests" component={Leaves} />
          <PrivateRoute
            exact
            path="/timeoff/policy"
            component={PolicyMangement}
          />
          <PrivateRoute exact path="/timeoff/types" component={TimeOffTypes} />
          {userDetails.role !== 'member' && (
            <PrivateRoute
              exact
              path="/schedules/holidays"
              component={Holidays}
            />
          )}
          {userDetails.role !== 'member' && (
            <PrivateRoute
              exact
              path="/activity/team/day"
              component={TeamDayActivity}
            />
          )}
          {userDetails.role !== 'member' && (
            <PrivateRoute
              exact
              path="/activity/team/day/:date"
              component={TeamDayActivity}
            />
          )}
          <PrivateRoute
            exact
            path="/settings/password"
            component={ChangePassword}
          />
          <PrivateRoute exact path="/settings/profile" component={Profile} />
          <PrivateRoute exact path="/settings/teams" component={TeamSettings} />
          <PrivateRoute
            exact
            path="/settings/general"
            component={FeatureSettings}
          />
          <PrivateRoute
            exact
            path="/reports/overtime"
            component={OvertimeWeeklyReport}
          />
          <PrivateRoute
            exact
            path="/reports/attendance"
            component={Attendance}
          />
          <PrivateRoute
            exact
            path="/reports/screenshot"
            component={ScreenshotReport}
          />
          <PrivateRoute exact path="/reports/charts" component={Charts} />
          {userDetails.role === 'creator' && (
            <PrivateRoute exact path="/settings/billing" component={Billing} />
          )}
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <PublicRoute exact path="/reset-password" component={ResetPassword} />
          <PrivateRoute
            exact
            path="/settings/card-update-return"
            component={CardUpdateReturn}
          />
          <PrivateRoute
            exact
            path="/settings/trial-upgrade-return"
            component={TrialUpgradeReturn}
          />
          <PrivateRoute exact path="/asana-return" component={AsanaReturn} />
          <PrivateRoute
            exact
            path="/settings/integration-status"
            component={IntegrationStatus}
          />
          <PrivateRoute
            exact
            path="/settings/integration-list"
            component={IntegrationList}
          />
          {userDetails.role === 'creator' &&
            billing.accountStatus === 'cancel' && (
              <PrivateRoute
                exact
                path="/settings/account-closed"
                component={AccountClosed}
              />
            )}
          {userDetails.role === 'creator' &&
            billing.accountStatus === 'reactivated' && (
              <PrivateRoute
                exact
                path="/settings/account-reactivated"
                component={AccountReactivated}
              />
            )}
          <PublicRoute
            exact
            path="/settings/reactivate-return"
            component={ReactivateReturn}
          />
          <PublicRoute exact path="/zapier-return" component={ZapierReturn} />
          {userDetails.role !== 'member' && (
            <PrivateRoute
              exact
              path="/settings/email-summaries"
              component={EmailSummaries}
            />
          )}
          {userDetails.role !== 'member' && (
            <PrivateRoute exact path={PAGE_URLS.INVOICES} component={Invoice} />
          )}
          {userDetails.role !== 'member' && (
            <PrivateRoute
              exact
              path="/invoice/edit/:id"
              component={EditInvoice}
            />
          )}
          {userDetails.role !== 'member' && (
            <PrivateRoute
              exact
              path="/invoice/view/:id"
              component={ViewInvoice}
            />
          )}
          <PrivateRoute component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};
export default Routes;
