import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// HOC for show more and show less for texts
const ReadMore = ({ note }) => {
  const text = note || '';
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      {isReadMore ? text && text.slice(0, 60) : text}
      {text.length > 60 && (
        <Link
          to={{ hash: '#' }}
          className="btn-link"
          onClick={toggleReadMore}
          onKeyDown={toggleReadMore}
        >
          {isReadMore ? '... read more' : ' show less'}
        </Link>
      )}
    </>
  );
};
ReadMore.propTypes = {
  note: PropTypes.string,
};

export default ReadMore;
