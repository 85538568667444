import PropTypes from 'prop-types';
import React from 'react';

const MemberRole = ({ role }) => {
  const getBadgeClass = (word) => {
    if (word === 'full_manager') {
      return 'badge bg-full-manager capitalize';
    }
    if (word === 'manager') {
      return 'badge bg-info capitalize';
    }
    if (word === 'creator') {
      return 'badge bg-primary capitalize';
    }
    return 'badge bg-secondary-soft capitalize';
  };

  const getBadgeText = (word) => {
    if (word === 'full_manager') {
      return 'full manager';
    }
    if (word === 'manager') {
      return 'basic manager';
    }
    if (word === 'creator') {
      return 'administrator';
    }
    return word;
  };
  return <span className={getBadgeClass(role)}>{getBadgeText(role)}</span>;
};

MemberRole.propTypes = {
  role: PropTypes.string,
};

export default MemberRole;
