import { BASE_API_URL, CLIENTS_URLS } from '../constants';
import { AppService, HTTPHeaders } from './app.service';

const options = AppService.options();

export const clientsService = {
  get_clients: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + CLIENTS_URLS.CLIENTS}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
  add_client: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + CLIENTS_URLS.CLIENTS,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  edit_client: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'PUT',
    };
    const response = await AppService.makeRequest(
      BASE_API_URL + CLIENTS_URLS.CLIENTS,
      {
        ...reqOptions,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  add_break: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + CLIENTS_URLS.BREAK_TYPE,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  import_client: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const response = await AppService.makeRequest(
      BASE_API_URL + CLIENTS_URLS.IMPORT_CLIENT,
      {
        ...options,
        ...headers,
        body: JSON.stringify(data),
      }
    );
    return response;
  },
  get_member_project_list: async (data) => {
    const headers = HTTPHeaders();
    headers.headers['Content-type'] = 'application/json';
    const reqOptions = {
      method: 'GET',
    };
    const URL = `${
      BASE_API_URL + CLIENTS_URLS.MEMBERPROJLIST
    }?${new URLSearchParams(data).toString()}`;
    const response = await AppService.makeRequest(URL, {
      ...reqOptions,
      ...headers,
    });
    return response;
  },
  get_all_breaks: async (data) => {
    const headers = HTTPHeaders();
    const reqOptions = {
      options: {
        method: 'GET',
      },
    };
    const response = await AppService.makeRequest(
      `${BASE_API_URL + CLIENTS_URLS.BREAK_TYPE}?${new URLSearchParams(
        data
      ).toString()}`,
      {
        ...reqOptions,
        ...headers,
      }
    );
    return response;
  },
};
