import { PureComponent } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../components/Header';
import FreeTrialHeader from '../components/FreeTrialHeader';
import Navigation from '../components/Navigation';
import { ErrorBoundary } from '../components/common';
import PolicyAcceptanceModal from '../components/PolicyAcceptanceModal';
import BillingModal from '../components/settings/BillingModals';
import BillingModalHelper from '../components/settings/BillingModalHelper';
import { trackJune } from '../utilities/analytics';
// import Clarity from '../utilities/Clarity'; // Disabled clarity tracking for the time being

class Layout extends PureComponent {
  billingModalProps = BillingModalHelper();

  componentDidMount() {
    trackJune();
  }

  render() {
    const {
      pageHeading,
      bredCrumbLinks,
      children,
      userRole,
      accountStatus,
      policyStatus,
      plan,
      freeTrialStatus,
      sideNavbarCollapsedView,
    } = this.props;
    document.title = pageHeading.preTitle;
    const url = new URL(window.location.href);
    const isFreeTrail =
      plan &&
      plan !== null &&
      plan.toLowerCase().includes('trial') &&
      accountStatus !== 'cancel' &&
      freeTrialStatus;
    return (
      <>
        <Navigation category={pageHeading.preTitle} />
        {userRole === 'creator' &&
          accountStatus === 'cancel' &&
          url.pathname.split('/')[2] !== 'account-closed' &&
          url.pathname.split('/')[2] !== 'billing' &&
          this.billingModalProps.title && (
            <BillingModal modalProps={this.billingModalProps} />
          )}

        {isFreeTrail && userRole === 'creator' && (
          <>
            <FreeTrialHeader />
            {/* Disabled clarity tracking for the time being */}
            {/* <Clarity /> */}
          </>
        )}
        <div
          className={
            isFreeTrail && userRole === 'creator'
              ? `main-content free-trial ${
                  sideNavbarCollapsedView && 'collapsed-sidebar'
                }`
              : `main-content ${sideNavbarCollapsedView && 'collapsed-sidebar'}`
          }
        >
          <Header pageHeading={pageHeading} bredCrumbLinks={bredCrumbLinks} />
          {/* Trigger Policy Acceptance request model if there is any change in policy or terms */}
          {!policyStatus && <PolicyAcceptanceModal />}
          {(accountStatus === 'cancel' &&
            (url.pathname.split('/')[2] === 'billing' ||
              url.pathname.split('/')[2] === 'account-closed')) ||
          accountStatus !== 'cancel' ? (
            <Container fluid className="pt-4 content-wrapper">
              <ErrorBoundary>{children}</ErrorBoundary>
            </Container>
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { userReducer, billingReducer, collpasedSideNavbarToggle } = state;
  return {
    userRole: userReducer.role,
    accountStatus: billingReducer.accountStatus,
    policyStatus: userReducer.policyAcceptanceStatus,
    plan: userReducer.subscription.currentPlan,
    freeTrialStatus: userReducer.freeTrialStatus,
    sideNavbarCollapsedView: collpasedSideNavbarToggle.sideNavbarCollapsedView,
  };
};

Layout.propTypes = {
  freeTrialStatus: PropTypes.bool,
  userRole: PropTypes.string,
  accountStatus: PropTypes.string,
  pageHeading: PropTypes.object,
  bredCrumbLinks: PropTypes.array,
  children: PropTypes.any,
  policyStatus: PropTypes.bool,
  plan: PropTypes.string,
  sideNavbarCollapsedView: PropTypes.bool,
};

export default connect(mapStateToProps)(Layout);
