import { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { AllowedMembersAlert, InviteNewTeamMember } from '../common';
import { LOCAL_STORAGE_USER_DATA, INVITATIONS } from '../../constants';
import { fetchOnBoardProgress } from '../../actions/user';
import { teamService, userService } from '../../services';
import { trackJune } from '../../utilities/analytics';
import Loading from '../Loader';

function AddMemberModal({ showInviteModal, toggleShowInviteModal }) {
  const location = useLocation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [newTeamMembers, setNewTeamMembers] = useState([]);
  const [newTeamMembersItem, setNewTeamMembersItem] = useState(0);
  const [initFuncionCall, setInitFunctionCall] = useState(false);
  const handleClose = () => toggleShowInviteModal(false);
  const teamOwnerId = localStorage.getItem('teamOwnerId');
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));

  const updateTeamInvitations = (formdata) => {
    setSubmitting(true);
    const newMembers =
      [...formdata.addNewTeamMembers].length > 0
        ? [...formdata.addNewTeamMembers].filter((item) => item.email !== '')
        : [];
    const inviteesList = [...newMembers];
    if (inviteesList.length <= 0) {
      setSubmitting(false);
      toast.error('Please enter any email address');
    } else {
      teamService
        .update_team_invitations({
          teamOwnerId,
          newMembers: [...inviteesList],
        })
        .then((response) => {
          if (newMembers.length > 0) {
            trackJune('member invite');
          }
          if (userData.subscriptionDetails.currentPlan === 'trial') {
            dispatch(fetchOnBoardProgress());
          }
          setSubmitting(false);
          toggleShowInviteModal(false);
          if (response.message === 'Success') {
            toast.success(INVITATIONS.INVITATION_SUCCESS);
          }
        })
        .catch((response) => {
          setSubmitting(false);
          toggleShowInviteModal(false);
        });
    }
  };
  const maxNewMember = [];

  const getAccountInfo = () => {
    setIsLoading(true);
    userService
      .get_account_details({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
      })
      .then((response) => {
        if (response.data) {
          const maxFieldAllowed =
            response.data.maxMembersAllowed - response.data.activeMembers;
          setNewTeamMembersItem(maxFieldAllowed);

          if (maxFieldAllowed === 0) {
            setNewTeamMembers([]);
            toggleShowInviteModal(true);
            setIsLoading(false);
          } else {
            for (
              let i = 0;
              i < maxFieldAllowed;
              // eslint-disable-next-line no-plusplus
              i++
            ) {
              maxNewMember.push({ email: '', role: 'member' });
              if (maxFieldAllowed >= 5 && i === 4) {
                setNewTeamMembers(maxNewMember);
                toggleShowInviteModal(true);
                setIsLoading(false);
                break;
              } else if (maxFieldAllowed < 5 && i === maxFieldAllowed - 1) {
                setNewTeamMembers(maxNewMember);
                toggleShowInviteModal(true);
                setIsLoading(false);
                break;
              }
            }
          }
        }
      })
      .catch((error) => {
        setNewTeamMembers([]);
        setIsLoading(false);
      });
  };
  if (
    location.state &&
    location.state.action &&
    location.state.action === 'showInviteModal' &&
    !initFuncionCall
  ) {
    setInitFunctionCall(true);
    getAccountInfo();
  }

  useEffect(() => {
    setNewTeamMembers([]);
  }, 0);

  return (
    <>
      <button
        className="btn btn-primary lift mb-1 me-2"
        onClick={getAccountInfo}
        title="view all"
        type="button"
      >
        Add Member
      </button>
      {isLoading && <Loading contentAreaOnly />}
      <Modal
        show={showInviteModal}
        onHide={handleClose}
        animation={false}
        size="md"
      >
        <Modal.Header>
          <Modal.Title className="m-0">Invite New Members</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        {newTeamMembersItem > 0 ? (
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={{
              addNewTeamMembers: newTeamMembers,
            }}
            validationSchema={Yup.object().shape({
              addNewTeamMembers: Yup.array().of(
                Yup.object().shape({
                  email: Yup.string()
                    .trim()
                    .email('Please enter a valid email address'),
                })
              ),
            })}
            onSubmit={(values) => {
              updateTeamInvitations(values);
            }}
          >
            {(formikprops) => (
              <Form
                onSubmit={formikprops.handleSubmit}
                className="needs-validation"
              >
                <>
                  <Modal.Body>
                    <AllowedMembersAlert />
                    <section>
                      <InviteNewTeamMember
                        formProps={formikprops}
                        showPlanText=""
                        maxInputField={0}
                        fieldName="addNewTeamMembers"
                        maxFieldAllowed={newTeamMembersItem}
                      />
                    </section>
                  </Modal.Body>

                  <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="link" onClick={handleClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={
                        !(formikprops.isValid && formikprops.dirty) ||
                        isSubmitting
                      }
                    >
                      {isSubmitting && (
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Invite
                    </Button>
                  </Modal.Footer>
                </>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={{
              addNewTeamMembers: newTeamMembers,
            }}
            onSubmit={(values) => {
              updateTeamInvitations(values);
            }}
          >
            {(formikprops) => (
              <Form
                onSubmit={formikprops.handleSubmit}
                className="needs-validation"
              >
                <>
                  <Modal.Body>
                    <AllowedMembersAlert />
                    <section />
                  </Modal.Body>

                  <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="link" onClick={handleClose}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={
                        !(formikprops.isValid && formikprops.dirty) ||
                        isSubmitting
                      }
                    >
                      {isSubmitting && (
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Invite
                    </Button>
                  </Modal.Footer>
                </>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
}
AddMemberModal.propTypes = {
  showInviteModal: PropTypes.bool,
  toggleShowInviteModal: PropTypes.func,
};
export default AddMemberModal;
