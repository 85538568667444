import { LOCAL_STORAGE_USER_DATA } from '../constants';

let userStatus = '';
let userPlan = '';
if (localStorage.getItem(LOCAL_STORAGE_USER_DATA) != null) {
  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  userStatus = userData.userStatus;

  userPlan = userData.subscriptionDetails.currentPlan;
}

const initialState = {
  address: '',
  digits: '',
  email: '',
  accountStatus: userStatus,
  pageStatus: '',
  planName: userPlan,
};

const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BILLING_DETAILS':
      return {
        ...state,
        address: action.payload.address,
        digits: action.payload.digits,
        email: action.payload.email,
      };
    case 'SET_ACCOUNT_STATUS':
      return {
        ...state,
        accountStatus: action.payload.accountStatus,
      };
    case 'SET_CURRENT_PLAN':
      return {
        ...state,
        planName: action.payload.planName,
      };
    case 'SET_PAGE_STATUS':
      return {
        ...state,
        pageStatus: action.payload.pageStatus,
      };
    default:
      return state;
  }
};

export default billingReducer;
