import { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { DateAndTimePickerField } from '../common';

const EditSession = ({
  sessionInfo,
  showEditSessionModal,
  handleShowEditSessionModal,
  handleUpdateWorkSession,
  userPermissions,
  userRole,
}) => {
  const [checkInDate, setCheckInDate] = useState(sessionInfo.start);
  const [checkOutDate, setCheckOutDate] = useState(sessionInfo.end);
  const checkInDateISOformat = new Date(sessionInfo.start).toISOString();
  const checkOutDateISOformat = new Date(sessionInfo.end).toISOString();

  return (
    <>
      <Modal
        show={showEditSessionModal}
        onHide={() => {
          handleShowEditSessionModal(false);
        }}
        centered
        backdrop="static"
      >
        <Modal.Header>
          {userRole === 'member' &&
          userPermissions &&
          userPermissions.canEditHours === false ? (
            <Modal.Title className="m-0">Edit Session Request</Modal.Title>
          ) : (
            <Modal.Title className="m-0">Edit Session</Modal.Title>
          )}

          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => {
              handleShowEditSessionModal(false);
            }}
          />
        </Modal.Header>
        {sessionInfo && sessionInfo.extendedProps && (
          <Formik
            enableReinitialize
            initialValues={{
              scheduleId: sessionInfo.id,
              checkInDate,
              checkOutDate,
              reason: '',
              project: sessionInfo.extendedProps.projectId,
            }}
            validationSchema={Yup.object({
              checkInDate: Yup.date().required(
                'Please enter check in information'
              ),
              checkOutDate: Yup.date().required(
                'Please enter check out information'
              ),
              reason: Yup.string().required(
                'Please enter a reason for your worksession'
              ),
            })}
            onSubmit={(values, { resetForm }) => {
              handleUpdateWorkSession(values);
              resetForm();
            }}
          >
            {(formikprops) => (
              <Form onSubmit={formikprops.handleSubmit}>
                <Modal.Body>
                  <section>
                    <Form.Group>
                      <Row className="align-items-center mb-3">
                        <Col>
                          <h4 className="mb-2">
                            {sessionInfo.extendedProps.project}
                          </h4>
                          {sessionInfo.extendedProps.task && (
                            <p>{sessionInfo.extendedProps.task}</p>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                    <Row>
                      <Col>
                        <DateAndTimePickerField
                          label="Check In"
                          handleDateSelect={setCheckInDate}
                          name="checkInDate"
                          defaultValue={checkInDateISOformat}
                          defaultValueDisplay
                          showRequiredField={false}
                        />
                      </Col>
                      <Col>
                        <DateAndTimePickerField
                          label="Check Out"
                          handleDateSelect={setCheckOutDate}
                          name="checkOutDate"
                          defaultValue={checkOutDateISOformat}
                          defaultValueDisplay
                          showRequiredField={false}
                        />
                      </Col>
                    </Row>

                    <Form.Group controlId="reason">
                      <Form.Label>Reason</Form.Label>
                      <Field
                        className="form-control"
                        type="text"
                        placeholder=""
                        name="reason"
                      />
                      <ErrorMessage
                        name="reason"
                        render={(msg) => (
                          <small className="text-danger">{msg}</small>
                        )}
                      />
                    </Form.Group>
                  </section>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                  <Button
                    variant="link"
                    onClick={() => {
                      handleShowEditSessionModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  {userRole === 'member' &&
                  userPermissions &&
                  userPermissions.canEditHours === false ? (
                    <Button variant="primary" type="submit">
                      Submit Request
                    </Button>
                  ) : (
                    <Button variant="primary" type="submit">
                      Update
                    </Button>
                  )}
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};

EditSession.propTypes = {
  sessionInfo: PropTypes.object,
  showEditSessionModal: PropTypes.bool,
  handleShowEditSessionModal: PropTypes.func,
  handleUpdateWorkSession: PropTypes.func,
  userPermissions: PropTypes.object,
  userRole: PropTypes.string,
};

export default EditSession;
