import { memo } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { TeamMembersDropdown, DateRangePickerField } from '../common';
import { NO_DATA, DATE_RANGE_OPTIONS, DATE_AND_TIME } from '../../constants';
import { WorksessionChart } from '.';
import { getUserData } from '../../helpers';

const MembersessionChart = ({
  teamMembersList,
  handleDateChange,
  handleGenerateData,
  worksessionData,
  isGenerated,
}) => {
  const userId = getUserData('_id');
  /**
   * handleMemberChartData Handle Generate Data
   * @param  Array
   * @return void
   */
  const handleMemberChartData = (values) => {
    handleGenerateData({
      ...values,
      fromDate: values.fromDate.concat(' 00:00:00'),
      toDate: values.toDate.concat(' 23:59:59'),
    });
  };
  const userData = getUserData('defaultTeam');
  return (
    <>
      <Card>
        <Card.Header
          href="#worksessionChart"
          type="button"
          data-bs-toggle="collapse"
          aria-expanded="true"
          className="accordion-button border-bottom px-4"
        >
          Worksession chart
        </Card.Header>
        {userId && (
          <Card.Body id="worksessionChart" className="show collapse">
            <Formik
              initialValues={{
                teamOwnerId: localStorage.getItem('teamOwnerId'),
                teamMember: userData.role === 'member' ? userId : '',
                fromDate: moment()
                  .subtract(1, 'days')
                  .format(DATE_AND_TIME.DATE_RANGE_IN_STATE),
                toDate: moment().format(DATE_AND_TIME.DATE_RANGE_IN_STATE),
              }}
              validationSchema={Yup.object({
                teamMember: Yup.string().required(
                  'Please select any team member'
                ),
              })}
              onSubmit={(values) => {
                handleMemberChartData(values);
              }}
            >
              {(formikprops) => (
                <Form onSubmit={formikprops.handleSubmit}>
                  <Row>
                    <Col md={6} lg={4}>
                      <TeamMembersDropdown
                        isValidate
                        isRequired
                        isDependentFiled
                        handleTeamMemberChange={(e) => {
                          formikprops.setFieldValue(
                            'teamMember',
                            e.target.value
                          );
                        }}
                        handleChange={formikprops.handleChange}
                        teamMembersList={teamMembersList}
                      />
                    </Col>
                    <Col md={6} lg={4}>
                      <DateRangePickerField
                        showRangeOptions
                        startFrom="yesterday"
                        preDefinedRanges={DATE_RANGE_OPTIONS}
                        handleDateSelect={(date, picker) => {
                          formikprops.setFieldValue(
                            'fromDate',
                            handleDateChange(date, picker, true)
                          );
                          formikprops.setFieldValue(
                            'toDate',
                            handleDateChange(date, picker, false)
                          );
                        }}
                      />
                    </Col>
                    <Col md={6} lg={4}>
                      <Form.Group controlId="TeamMembers">
                        <Form.Label className="w-100">&nbsp;</Form.Label>
                        <Button variant="primary" type="Submit">
                          Generate
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            {worksessionData && worksessionData.length > 0 && (
              <WorksessionChart
                worksessionData={worksessionData}
                graphType="line"
              />
            )}
            {isGenerated && worksessionData && worksessionData.length === 0 && (
              <Alert variant="info" className="text-center mx-4">
                {NO_DATA.WORKSESSION_CHART}
              </Alert>
            )}
          </Card.Body>
        )}
      </Card>
    </>
  );
};

MembersessionChart.propTypes = {
  teamMembersList: PropTypes.array,
  handleDateChange: PropTypes.func,
  handleGenerateData: PropTypes.func,
  worksessionData: PropTypes.array,
  isGenerated: PropTypes.bool,
};

export default memo(MembersessionChart);
