import { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { userService } from '../services';
import { UPDATE_FAILURE_ERROR } from '../constants';
import { setPrivacyAcceptanceStatus } from '../actions/user';
import { updateLocalStorage } from '../helpers';

const PolicyAcceptanceModal = () => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();
  const handlePolicyAcceptance = () => {
    userService
      .policy_acceptance()
      .then(() => {
        dispatch(setPrivacyAcceptanceStatus({ policyAcceptanceStatus: true }));
        updateLocalStorage({ policyAcceptanceStatus: true });
        handleClose();
      })
      .catch(() => {
        toast.error(UPDATE_FAILURE_ERROR);
      });
  };
  const handlePolicyAcceptanceDenial = () => {
    userService.logout();
  };
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" centered>
      <Modal.Header>
        <Modal.Title className="mb-0">
          Terms of service and Privacy policy accept
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={{
          policyAcceptance: false,
        }}
        onSubmit={() => {
          handlePolicyAcceptance();
        }}
      >
        {(formikprops) => (
          <Form onSubmit={formikprops.handleSubmit}>
            <Modal.Body>
              <p>We have updated our privacy policy</p>
              <Form.Group>
                <label className="form-check-label" htmlFor="policy_acceptance">
                  <input
                    className="form-check-input form-check-border"
                    id="policy_acceptance"
                    name="policyAcceptance"
                    type="checkbox"
                    onChange={() =>
                      formikprops.setFieldValue(
                        'policyAcceptance',
                        !formikprops.values.policyAcceptance
                      )
                    }
                    checked={formikprops.values.policyAcceptance}
                  />{' '}
                  I have read and agree to the{' '}
                  <a
                    href="https://www.hivedesk.com/terms/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.hivedesk.com/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy policy
                  </a>{' '}
                </label>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              <Button
                variant="link"
                onClick={handlePolicyAcceptanceDenial}
                className="ms-0 ps-0"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                className="ml-auto"
                disabled={!formikprops.values.policyAcceptance}
              >
                Continue
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PolicyAcceptanceModal;
