import PropTypes from 'prop-types';
import { Card, Row, Col, Button, Nav, Tab, Alert } from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { NO_DATA } from '../../constants';
import { groupBy } from '../../helpers';
import {
  GroupByMember,
  GroupByProject,
  GroupByDate,
  GroupByTask,
  ExportReport,
  GeneratePDF,
} from '.';
import { trackJune } from '../../utilities/analytics';

const TimesheetTable = ({
  timesheetMetadata,
  timesheet,
  startDate,
  endDate,
  isSummarizedReport,
  handleEmailReport,
  userRole,
  isMobileTracking,
  isSendingEmail,
}) => {
  if (timesheetMetadata.total > 0) {
    const reportInfo = {
      timesheetMetadata,
      dateRange: {
        startDate,
        endDate,
      },
    };

    // Group by team member
    const timesheetGroupedByMember = groupBy(timesheet, 'userId');
    // Group by team project
    const timesheetGroupedByProject = groupBy(timesheet, 'projectId');
    // Array with checkout date - for Grouping byDate
    const timesheetDetailsDate = timesheet.map((item) => {
      const checkInDate = moment(item.checkInDate).format('LL');
      item.checkIn = checkInDate;
      return item;
    });
    // Group by date
    const timesheetGroupedByDate = groupBy(timesheetDetailsDate, 'checkIn');

    // Group by tasks
    const timesheetGroupedByTask = groupBy(timesheet, 'taskId');

    // Grand totalDuration
    const gTotalDuration = timesheet.reduce(function (sum, val) {
      return sum + Math.round(val.workSessionSeconds);
    }, 0);
    const gTotalActivityDuration = timesheet.reduce(function (sum, val) {
      if (!val.isManual && val.workSessionType.toLowerCase() === 'desktop') {
        return sum + Math.round(val.workSessionSeconds);
      }
      return sum;
    }, 0);
    // Grand totalActiveTime
    const gTotalActiveTime = timesheet.reduce(function (sum, val) {
      return sum + Math.round(val.activeSeconds);
    }, 0);
    const gTotalActivityActiveTime = timesheet.reduce(function (sum, val) {
      if (!val.isManual && val.workSessionType.toLowerCase() === 'desktop') {
        return sum + Math.round(val.activeSeconds);
      }
      return sum;
    }, 0);
    // Grand totalActivity
    const gTotalActivity =
      gTotalActivityDuration > 0
        ? (gTotalActivityActiveTime / gTotalActivityDuration) * 100
        : 0;

    // Grand totalCost
    const gTotalCost = timesheet.reduce(function (a, val) {
      const cost =
        !val.workSessionSeconds || !val.rate.hourlyCostRate
          ? 0
          : Math.round(
              (Math.round(val.workSessionSeconds) / 3600) *
                val.rate.hourlyCostRate *
                100
            ) / 100;
      return a + Math.round((cost + Number.EPSILON) * 100) / 100;
    }, 0);
    // Check if notes available
    const sessionNotes = !!timesheet.find((el) => el.notes);
    // Grand total values
    const gTotal = {
      duration: gTotalDuration || 0,
      activeTime: gTotalActiveTime || 0,
      activity: gTotalActivity || 0,
      cost: gTotalCost || 0,
    };
    return (
      <Card>
        <Tab.Container
          id="TimeSheetPreview"
          defaultActiveKey={userRole !== 'member' ? 'team' : 'project'}
        >
          <Card.Header>
            <Row className="align-items-center my-3">
              <Col xs="auto">
                <div className="card-title h4 m-0">Group by</div>
              </Col>
              <Col>
                <Nav variant="pills" className="timesheet-group-by-report">
                  {userRole !== 'member' && (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="team"
                        className="py-1 px-3"
                        onClick={() => {
                          // june
                          trackJune('timesheet - group by team member');
                        }}
                      >
                        Team member
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link
                      eventKey="project"
                      className="py-1 px-3"
                      onClick={() => {
                        // june
                        trackJune('timesheet - group by projects');
                      }}
                    >
                      Projects
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="task"
                      className="py-1 px-3"
                      onClick={() => {
                        // june
                        trackJune('timesheet - group by task');
                      }}
                    >
                      Tasks
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="date"
                      className="py-1 px-3"
                      onClick={() => {
                        // june
                        trackJune('timesheet - group by date');
                      }}
                    >
                      Date
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>

              <Col xs="auto" className="ml-auto">
                Download as{' '}
                {!isSummarizedReport && (
                  <Button
                    variant="white"
                    type="button"
                    size="sm"
                    className="mx-1"
                    onClick={() =>
                      GeneratePDF(
                        timesheet,
                        gTotal,
                        reportInfo,
                        isMobileTracking,
                        sessionNotes
                      )
                    }
                  >
                    PDF
                  </Button>
                )}
                <ReactHTMLTableToExcel
                  className="mx-1 btn btn-white btn-sm"
                  table="ExportTimesheetReport"
                  filename={`timesheet-${
                    isSummarizedReport ? 'summary' : 'detail'
                  }-from-${startDate}-to-${endDate}`}
                  sheet="Worksheet"
                  buttonText="Excel"
                />{' '}
                or{' '}
                <Button
                  disabled={isSendingEmail}
                  variant="white"
                  type="button"
                  size="sm"
                  className="mx-1"
                  onClick={() => handleEmailReport()}
                >
                  Email
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <div className="card-body py-1 bg-light">
            <p className="small text-end mb-0">
              {timesheetMetadata.timeZoneFormatted}
            </p>
          </div>

          <Tab.Content>
            {userRole !== 'member' && (
              <Tab.Pane eventKey="team">
                <GroupByMember
                  timesheetMetadata={timesheetMetadata}
                  memberTimesheet={timesheetGroupedByMember}
                  grandTotal={gTotal}
                  showNotes={sessionNotes}
                  isSummarizedReport={isSummarizedReport}
                  isMobileTracking={isMobileTracking}
                />
              </Tab.Pane>
            )}
            <Tab.Pane eventKey="project">
              <GroupByProject
                timesheetMetadata={timesheetMetadata}
                projectTimesheet={timesheetGroupedByProject}
                grandTotal={gTotal}
                showNotes={sessionNotes}
                isSummarizedReport={isSummarizedReport}
                isMobileTracking={isMobileTracking}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="task">
              <GroupByTask
                timesheetMetadata={timesheetMetadata}
                taskTimesheet={timesheetGroupedByTask}
                grandTotal={gTotal}
                showNotes={sessionNotes}
                isSummarizedReport={isSummarizedReport}
                isMobileTracking={isMobileTracking}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="date">
              <GroupByDate
                timesheetMetadata={timesheetMetadata}
                dateTimesheet={timesheetGroupedByDate}
                grandTotal={gTotal}
                showNotes={sessionNotes}
                isSummarizedReport={isSummarizedReport}
                isMobileTracking={isMobileTracking}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        {/* For Report Generation only */}
        <ExportReport
          reportInfo={reportInfo}
          timesheet={timesheet}
          grandTotal={gTotal}
          isSummarizedReport={isSummarizedReport}
          isMobileTracking={isMobileTracking}
          showNotes={sessionNotes}
        />
      </Card>
    );
  }
  return (
    <Alert variant="info" className="text-center">
      {NO_DATA.WORKSESSION}
    </Alert>
  );
};
TimesheetTable.propTypes = {
  timesheet: PropTypes.any,
  timesheetMetadata: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  isSummarizedReport: PropTypes.bool,
  handleEmailReport: PropTypes.func,
  userRole: PropTypes.string,
  isMobileTracking: PropTypes.bool,
  isSendingEmail: PropTypes.bool,
};

export default TimesheetTable;
