import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CustomOption = ({ innerRef, innerProps, isFocused, data }) => (
  <div
    ref={innerRef}
    {...innerProps}
    style={{
      backgroundColor: isFocused ? '#d5e5fa' : 'white',
      padding: 10,
      cursor: 'pointer',
    }}
  >
    <h4 className="mb-1">{data.label}</h4>
    <p className="small text-muted mb-0">{data.summary}</p>
  </div>
);

CustomOption.propTypes = {
  innerRef: PropTypes.any,
  innerProps: PropTypes.any,
  isFocused: PropTypes.any,
  isSelected: PropTypes.any,
  data: PropTypes.any,
};

const QuickSearch = () => {
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');

  const userDetails = useSelector((state) => state.userReducer);
  const isTimesheetApproval = useSelector((state) => state.timesheetApproval);
  const isOverTimeEnabled = useSelector((state) => state.overTimeEnabled);
  const isSchedulingEnabled = useSelector((state) => state.scheduleEnabled);
  const isInvoiceEnabled = useSelector((state) => state.invoiceEnabled);

  const getAvailableOptions = (options, userRole) => {
    if (userRole === 'creator') {
      return options; // Account owner get all options
    }
    return options.filter((option) => !option.isCreatorOnly);
  };

  const HD_PAGES_LIST = [
    {
      id: 1,
      title: 'Dashboard',
      summary: 'Track your projects, tasks, and recent activities at a glance',
      route: '/dashboard',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 2,
      title: 'Track',
      summary:
        'Log and manage time efficiently with direct entries in calendar view',
      route: '/track',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 3,
      title: 'Timeline',
      summary: 'Visualize task progress and deadlines with a timeline view',
      route: '/timeline',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 4,
      title: 'Clients',
      summary: 'Streamline client management with essential details',
      route: '/clients',
      isCreatorOnly: true,
      isEnabled: true,
    },
    {
      id: 5,
      title: 'Active Projects',
      summary:
        'Detailed list of active projects featuring total hours logged, tasks, and members',
      route: '/projects/active',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 6,
      title: 'Archived Projects',
      summary: 'Browse archived projects with options to restore or delete',
      route: '/projects/archived',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 7,
      title: 'Active Team Members',
      summary:
        'Manage active team members, view their hours, track task progress, and adjust roles all in one place',
      route: '/team/active',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 8,
      title: 'Inactive Team Members',
      summary:
        'Manage inactive team members with options to reactivate or delete',
      route: '/team/inactive',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 9,
      title: 'Team Groups',
      summary: 'Organize team members into groups',
      route: '/team/groups',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 10,
      title: 'Detailed Timesheet Report',
      summary:
        'Generate detailed timesheet reports to analyze work hours and productivity',
      route: '/timesheet/detailed',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 11,
      title: 'Summarized Timesheet Report',
      summary:
        'Access summarized timesheet reports for a quick overview of team productivity',
      route: '/timesheet/summarized',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 12,
      title: 'Member Activity',
      summary:
        'Review detailed work session logs to track member activity and engagement',
      route: '/activity/member',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 13,
      title: 'Team Activity',
      summary: 'Analyze overall team activity and productivity',
      route: '/activity/team/date-range',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 14,
      title: 'Overtime Report',
      summary: 'To track and analyze overtime hours across your team',
      route: '/reports/overtime',
      isCreatorOnly: false,
      isEnabled: isOverTimeEnabled || false,
    },
    {
      id: 15,
      title: 'Screenshot Report',
      summary: 'Review and analyze screenshots to monitor activity',
      route: '/reports/screenshot',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 16,
      title: 'Worksession Chart',
      summary: 'Explore worksession data through chart reports',
      route: '/reports/charts',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 17,
      title: 'Performance Chart',
      summary: 'Explore performance data through chart reports',
      route: '/reports/charts',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 18,
      title: 'Invoices',
      summary: 'Manage and generate client invoices',
      route: '/invoices',
      isCreatorOnly: true,
      isEnabled: isInvoiceEnabled || false,
    },
    {
      id: 19,
      title: 'Settings > General',
      summary: 'Configure preferences from the General Settings page',
      route: '/settings/general',
      isCreatorOnly: true,
      isEnabled: true,
    },
    {
      id: 20,
      title: 'Settings > Features',
      summary:
        'Adjust functionality and access with customizable options in Feature Settings',
      route: '/settings/general',
      isCreatorOnly: true,
      isEnabled: true,
    },
    {
      id: 21,
      title: 'Settings > Profile',
      summary: 'Update and manage your personal profile settings',
      route: '/settings/profile',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 22,
      title: 'Settings > Password',
      summary: 'Secure your account by updating your password',
      route: '/settings/password',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 23,
      title: 'Billing',
      summary: 'Control and update your billing information',
      route: '/settings/billing',
      isCreatorOnly: true,
      isEnabled: true,
    },
    {
      id: 24,
      title: 'Settings > Teams',
      summary:
        'View team members and roles, and manage daily work session limit',
      route: '/settings/teams',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 25,
      title: 'Email Summaries',
      summary:
        'Subscribe to email summaries with options to select daily, weekly, or bimonthly reports',
      route: '/settings/email-summaries',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 26,
      title: 'Integrations',
      summary: 'Explore and manage third-party integrations',
      route: '/settings/integration-status',
      isCreatorOnly: true,
      isEnabled: true,
    },
    {
      id: 27,
      title: 'Timesheet / Timecard View',
      summary: 'View and track your daily work sessions on a weekly basis',
      route: '/timesheet/view',
      isCreatorOnly: false,
      isEnabled: isTimesheetApproval || false,
    },
    {
      id: 28,
      title: 'Timesheet / Timecard Approval',
      summary: 'Review and approve submitted timesheet',
      route: '/timesheet/approval',
      isCreatorOnly: false,
      isEnabled: isTimesheetApproval || false,
    },
    {
      id: 29,
      title: 'Schedules > Shifts',
      summary: 'Plan and manage shifts for team members',
      route: '/schedules/shifts',
      isCreatorOnly: false,
      isEnabled: isSchedulingEnabled || false,
    },
    {
      id: 30,
      title: 'Schedules > Holidays',
      summary: 'View and manage holiday schedules',
      route: '/schedules/holidays',
      isCreatorOnly: false,
      isEnabled: isSchedulingEnabled || false,
    },
    {
      id: 31,
      title: 'Reports > Attendance',
      summary: 'To monitor and analyze team presence and punctuality',
      route: '/reports/attendance',
      isCreatorOnly: false,
      isEnabled: isSchedulingEnabled || false,
    },
    {
      id: 32,
      title: 'Time Off',
      summary:
        'Create and manage time-off policies and types to requesting leaves',
      route: '/timeoff/policy',
      isCreatorOnly: false,
      isEnabled: true,
    },
    {
      id: 33,
      title: 'Leave Policy',
      summary: 'Create and manage time-off policies',
      route: '/timeoff/policy',
      isCreatorOnly: false,
      isEnabled: true,
    },
  ];

  const availableOptions = getAvailableOptions(HD_PAGES_LIST, userDetails.role);

  // Prepare the options in the format that react-select expects
  const qsOptions = availableOptions.map((page) => {
    const QS_OPTION = {
      value: page.id,
      label: page.title,
      route: page.route,
      summary: page.summary,
      isEnabled: page.isEnabled,
    };
    if (page.isEnabled) {
      return QS_OPTION;
    }
    return null;
  });

  // Dynamically set options based on input value
  const filteredOptions = inputValue
    ? qsOptions.filter(
        (option) =>
          option !== null &&
          option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    : [];

  const handleChange = (option) => {
    setSelectedOption(option);
    history.push(option.route);
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    return newValue;
  };

  const customComponents = {
    Option: CustomOption,
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '48px',
      height: '48px',
      fontSize: '16px',
      padding: '0 8px',
    }),
    // To remove the dropdown indicator (arrow)
    dropdownIndicator: (base) => ({
      ...base,
      display: 'none',
    }),
    // To remove the separator line
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
  };

  const getNoOptionsMessage = () => (inputValue ? 'No pages found' : null);

  return (
    <Select
      className="quick-search-field"
      value={selectedOption}
      styles={customStyles}
      onChange={handleChange}
      options={filteredOptions}
      components={customComponents}
      onInputChange={handleInputChange}
      placeholder="Quick Search"
      isClearable
      noOptionsMessage={getNoOptionsMessage}
    />
  );
};

export default QuickSearch;
