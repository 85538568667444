import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StopWatch, TimerWidget } from '.';
import {
  setShowTimerWidget,
  updateTimer,
  enableScreenshots,
} from '../../actions/timer';
import '../../assets/styles/timer.scss';
import { SEND_SS_DELAY } from '../../constants';

const HeaderTimer = () => {
  const timer = useSelector((state) => state.timerReducer);
  const { isRunning, showTimerWidget, currentTime, worksessionId } = timer;
  const dispatch = useDispatch();

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => {
        dispatch(updateTimer());
      }, 1000);
    }
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning, currentTime]);

  // Log time with send screenshot api request on specified interval
  // If timer is running
  useEffect(() => {
    let ssIntervalId;
    if (isRunning && worksessionId !== '') {
      ssIntervalId = setInterval(() => {
        dispatch(enableScreenshots({ wsId: worksessionId }));
      }, SEND_SS_DELAY);
    } else {
      clearInterval(ssIntervalId);
    }
    return () => clearInterval(ssIntervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning, worksessionId]);

  return (
    <>
      <div className="position-relative">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className="top-bar-timer py-1 px-3 d-flex align-items-center bg-white rounded-pill"
          style={{ cursor: 'pointer' }}
          onClick={() => dispatch(setShowTimerWidget())}
        >
          <span className="mdi mdi-av-timer" style={{ fontSize: '20px' }} />
          <div className="mx-3">
            <StopWatch />
          </div>
          <span className="mdi mdi-arrow-top-right text-muted" />
        </div>
        {showTimerWidget && <TimerWidget />}
      </div>
    </>
  );
};

export default HeaderTimer;
