import { toast } from 'react-toastify';
import { tasksService } from '../services';
import { API_FAILURE_ERROR } from '../constants';

export const fetchTasks = () => async (dispatch) => {
  dispatch({ type: 'FETCHING_D_TASKS' });
  try {
    const response = await tasksService.get_tasks_data({
      teamOwnerId: localStorage.getItem('teamOwnerId'),
    });
    if (response.status) {
      dispatch({ type: 'SET_D_TASKS', payload: response.data });
    }
  } catch {
    dispatch({ type: 'FETCH_D_TASKS_ERROR' });
  }
};

export const updateTaskDudeDate = (params) => async (dispatch) => {
  dispatch({ type: 'FETCHING_D_TASKS' });
  try {
    const response = await tasksService.change_due_date(params);
    if (response.status) {
      dispatch(fetchTasks());
      toast.success('Task due date updated successfully');
    }
  } catch {
    toast.error(API_FAILURE_ERROR);
    dispatch({ type: 'FETCH_D_TASKS_ERROR' });
  }
};
