import PropTypes from 'prop-types';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import { ToggleSwitch } from '../common';

const ShiftSettings = ({
  ScheduleSettings,
  LateLimit,
  ShiftTolerance,
  handleUpdate,
  handleScheduletoggle,
}) => (
  <Formik
    initialValues={{
      ScheduleSettings,
      LateLimit,
      ShiftTolerance,
    }}
    enableReinitialize
    validationSchema={Yup.object({
      LateLimit: Yup.number().when('ScheduleSettings', {
        is: true,
        then: Yup.number()
          .typeError('Late threshold limit must be less than 100')
          .positive()
          .required('Please enter your tolerance limit for lateness')
          .test(
            'maxDigits',
            'Tolerance limit must have 2 digits value',
            (LateLimit1) => String(LateLimit1).length === 2
          ),
      }),
      ShiftTolerance: Yup.number().when('ScheduleSettings', {
        is: true,
        then: Yup.number()
          .typeError('Shift Tolerance limit must be less than 100')
          .positive()
          .required('Please enter your Shift tolerance')
          .test(
            'maxDigits',
            'Shift tolerance must have 2 digits value',
            (ShiftTolerance1) => String(ShiftTolerance1).length <= 2
          ),
      }),
    })}
    onSubmit={(values) => {
      if (values.ScheduleSettings === false) {
        values.LateLimit = '';
      }

      handleUpdate(values);
    }}
  >
    {(formikprops) => (
      <div className="col-12 col-md-12">
        <Form onSubmit={formikprops.handleSubmit} className="needs-validation">
          <Row>
            <Col>
              <h4 className="font-weight-base mb-1">Schedule</h4>
              <small className="text-muted">
                By enabling this feature lets you assign shifts to your team
                members and generate attendance report.
              </small>
            </Col>
            <Col className="col-auto">
              <ToggleSwitch
                id="ScheduleSettings"
                name="ScheduleSettings"
                handleChangeforChecked={handleScheduletoggle}
                checked={ScheduleSettings}
              />
            </Col>
          </Row>
          <br />
          {formikprops.values.ScheduleSettings && (
            <div>
              <Row>
                <Col className="col-auto pt-2">
                  <small>Late threshold</small>
                </Col>
                <Col className="px-2 col-auto">
                  <div className="form-group mb-2">
                    <Field
                      type="text"
                      className="form-control form-control-sm"
                      style={{
                        maxWidth: '50px',
                        marginTop: '6px',
                        marginLeft: '18px',
                      }}
                      name="LateLimit"
                    />
                  </div>
                </Col>
                <Col className="col-auto ps-0">
                  <span className="pt-2 d-inline-block">
                    {' '}
                    <small> Minutes</small>
                  </span>
                </Col>
                <Col>
                  <Tippy
                    content={`${'How many minutes employees can be late to start their work'}`}
                    theme="light"
                    key="2"
                  >
                    <span className="pt-2 d-inline-block">
                      <i className="mdi mdi-information-outline" />
                    </span>
                  </Tippy>
                </Col>
                <ErrorMessage
                  name="LateLimit"
                  render={(msg) => <small className="text-danger">{msg}</small>}
                />
              </Row>
              <Row>
                <Col className="col-auto pt-2">
                  <small>Shift tolerance</small>
                </Col>

                <Col className="px-2 col-auto">
                  <div className="form-group mb-2">
                    <Field
                      type="text"
                      className="form-control form-control-sm"
                      style={{
                        maxWidth: '50px',
                        marginTop: '6px',
                        marginLeft: '18px',
                      }}
                      name="ShiftTolerance"
                    />
                  </div>
                </Col>
                <Col className="col-auto ps-0">
                  <span className="pt-2 d-inline-block">
                    {' '}
                    <small> Minutes</small>
                  </span>
                </Col>
                <Col>
                  <Tippy
                    content={`${'How many minutes less than the duration of a shift is tolerated for employees'}`}
                    theme="light"
                    key="2"
                  >
                    <span className="pt-2 d-inline-block">
                      <i className="mdi mdi-information-outline" />
                    </span>
                  </Tippy>
                </Col>
                <ErrorMessage
                  name="ShiftTolerance"
                  render={(msg) => <small className="text-danger">{msg}</small>}
                />
              </Row>

              <br />
              <Row>
                <Col md="auto">
                  <Button
                    size="sm"
                    type="submit"
                    style={{ height: '26px' }}
                    disabled={!formikprops.isValid}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Form>
      </div>
    )}
  </Formik>
);
ShiftSettings.propTypes = {
  ScheduleSettings: PropTypes.bool,
  LateLimit: PropTypes.any,
  ShiftTolerance: PropTypes.any,
  handleUpdate: PropTypes.func,
  handleScheduletoggle: PropTypes.func,
};
export default ShiftSettings;
