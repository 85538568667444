import { Card, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { LOCAL_STORAGE_USER_DATA } from '../../constants';
import { getPlanAndType } from '../../helpers/functions';

const CurrentPlan = () => {
  const userdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_DATA));
  let userPlan = getPlanAndType(userdata.subscriptionDetails.currentPlan);
  let maxMembersAllowed = 0;
  if (userdata.maxMembersAllowed)
    maxMembersAllowed = userdata.maxMembersAllowed;

  /* Get the current plan from state when updated/ local storage */
  const billingDetails = useSelector((state) => state.billingReducer);
  userPlan = billingDetails.currentPlan
    ? `${billingDetails.currentPlan} (${maxMembersAllowed}) Members`
    : `${userPlan[0]} ${userPlan[1]} (${maxMembersAllowed}) Members`;
  return (
    <Col xs={12} md={8}>
      <Card>
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <h6 className="text-uppercase text-muted mb-2">Current plan</h6>
              <span className="h2 mb-0 capitalize">{userPlan}</span>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};
export default CurrentPlan;
