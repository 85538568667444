import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
} from 'react-bootstrap';
import Tippy from '@tippyjs/react';
import { MemberRole } from '../team';
import Avatar from '../Avatar.jsx';
import Pagination from '../common/Pagination';
import { PAGINATION_LIMIT } from '../../constants';

const TeamList = ({ memberList }) => {
  const [searchParam, setSearchParam] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(memberList.length);
  const [teamMembers, setTeamMembers] = useState([...memberList]);

  const handleSearch = (keyword) => {
    if (keyword === '') {
      setTeamMembers([...memberList]);
      setTotalItems(memberList.length);
    } else {
      const searchResult = [...memberList].filter((item) =>
        item.lastName && item.lastName != null
          ? item.firstName.toLowerCase().includes(keyword.toLowerCase()) ||
            item.lastName.toLowerCase().includes(keyword.toLowerCase())
          : item.firstName.toLowerCase().includes(keyword.toLowerCase())
      );
      setTeamMembers([...searchResult]);
      setTotalItems(searchResult.length);
      setCurrentPage(1);
    }
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGINATION_LIMIT;
    const lastPageIndex = firstPageIndex + PAGINATION_LIMIT;
    return teamMembers.slice(firstPageIndex, lastPageIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchParam]);

  return (
    <Card>
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h4 className="m-0">Team members</h4>
          </Col>
          <Col lg={3}>
            <div className="input-group">
              <input
                type="text"
                value={searchParam}
                className="form-control form-control-sm"
                onChange={(e) => {
                  setSearchParam(e.target.value);
                  handleSearch(e.target.value);
                }}
                placeholder="Search"
                autoComplete="off"
              />
              <Button variant="primary" size="sm">
                <span className="mdi mdi-magnify" />
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <ListGroup variant="flush" className="my-n3 settings-teams-list">
          {currentTableData.length > 0 ? (
            currentTableData.map((member, i) => (
              <ListGroupItem key={i}>
                <>
                  <Row className="align-items-center">
                    <Col md="auto">
                      <div className="avatar">
                        <Avatar
                          firstName={member.firstName}
                          lastName={member.lastName}
                          imgPath={member.avatar ? member.avatar : ''}
                        />
                      </div>
                    </Col>
                    <Col className="col-5 ml-n2">
                      <span className="mb-1">
                        <>
                          {member.firstName} {member.lastName || ''} {}
                          <Tippy
                            content={
                              <>
                                <div className=" dropdown-menu-right">
                                  <div className="d-flex flex-column bd-highlight">
                                    <div className="p-2 bd-highlight m-1">
                                      Email{' '}
                                      <span className="p-3">
                                        {' '}
                                        {member.email}
                                      </span>
                                    </div>
                                    {member.phone && (
                                      <div className="p-2 bd-highlight m-1">
                                        Phone Number
                                        <span className="p-3">
                                          {' '}
                                          {member.phone}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            }
                            theme="light"
                          >
                            <i className="mdi mdi-information-outline text-muted" />
                          </Tippy>
                        </>
                      </span>
                    </Col>
                    <Col className="col-auto ms-auto me-n3">
                      <MemberRole role={member.role} />
                    </Col>
                  </Row>
                </>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem>
              <Row className="align-items-center">
                <Col>
                  <p className="text-center text-muted py-3 mb-0">
                    Not members found
                  </p>
                </Col>
              </Row>
            </ListGroupItem>
          )}
        </ListGroup>
      </Card.Body>
      {totalItems > 10 && (
        <Card.Footer>
          <section className="d-flex justify-content-center">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={totalItems}
              pageSize={PAGINATION_LIMIT}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </section>
        </Card.Footer>
      )}
    </Card>
  );
};
TeamList.propTypes = {
  memberList: PropTypes.any,
};

export default TeamList;
