import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ArchiveLink from '../ArchiveLink';
import { WithConfirmation } from '../common';

const ProjectKababMenu = ({ handleArchive, projectId }) => {
  const ArchiveWithConfirmation = WithConfirmation(ArchiveLink);
  const ArchiveModalProps = {
    title: `Archive Project`,
    action: handleArchive,
    params: projectId,
    content: [
      'You are about to archive this project.',
      'All data will be archived including time logs and screenshots.',
      'Continue?',
    ],
    actionBtnText: 'Archive',
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={Link}
        to={{ hash: '#' }}
        className="project-kabab-menu dropdown-ellipses dropdown-toggle"
      >
        <i className="mdi mdi-dots-horizontal" />
      </Dropdown.Toggle>

      <Dropdown.Menu align="right">
        <Dropdown.Item href={`/projects/${projectId}/settings`}>
          Edit
        </Dropdown.Item>
        <ArchiveWithConfirmation modalProps={ArchiveModalProps} />
      </Dropdown.Menu>
    </Dropdown>
  );
};
ProjectKababMenu.propTypes = {
  handleArchive: PropTypes.func,
  projectId: PropTypes.string,
};

export default ProjectKababMenu;
