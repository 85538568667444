import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';

const ProjectListDropdown = ({
  projectsList,
  handleChange,
  handleProjectChange,
  isDependedField = false,
  isUpdatingProjectsList,
  isAllOptionRequired = true,
  fieldName = 'project',
  defaultPlaceholder,
  isValidate,
  isRequired,
  label,
}) => {
  // Projects list
  const projects = projectsList.map((project, index) => {
    if (project.status !== 'delete') {
      return (
        <option key={index} value={project._id}>
          {project.title}
          {project.status === 'close' && ' (Closed)'}
        </option>
      );
    }
    return false;
  });
  return (
    <Form.Group controlId="Project">
      {isRequired ? (
        <Form.Label>
          {label} {isRequired && <span className="text-danger">*</span>}
        </Form.Label>
      ) : (
        <Form.Label>Projects</Form.Label>
      )}
      {isDependedField ? (
        <Field
          className="form-select"
          as="select"
          name={fieldName}
          disabled={isUpdatingProjectsList}
          onChange={(e) => {
            handleChange(e);
            setTimeout(() => {
              handleProjectChange(e);
            }, 0);
          }}
        >
          {isAllOptionRequired && <option value="all">All</option>}
          {defaultPlaceholder && <option value="">{defaultPlaceholder}</option>}
          {projects}
        </Field>
      ) : (
        <Field
          className="form-select"
          as="select"
          name={fieldName}
          disabled={isUpdatingProjectsList}
        >
          {isAllOptionRequired && <option value="all">All</option>}
          {defaultPlaceholder && <option value="">{defaultPlaceholder}</option>}
          {projects}
        </Field>
      )}

      {isValidate && (
        <ErrorMessage
          name="project"
          render={(msg) => <small className="text-danger">{msg}</small>}
        />
      )}
    </Form.Group>
  );
};

ProjectListDropdown.propTypes = {
  projectsList: PropTypes.array.isRequired,
  handleChange: PropTypes.func,
  handleProjectChange: PropTypes.func,
  isDependedField: PropTypes.bool,
  isUpdatingProjectsList: PropTypes.bool,
  isAllOptionRequired: PropTypes.bool,
  fieldName: PropTypes.string,
  isValidate: PropTypes.bool,
  defaultPlaceholder: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
};

export default ProjectListDropdown;
