import React from 'react';
import { Route } from 'react-router-dom';
import { getSessionVariable } from '../helpers';
import { AUTH_TOKEN_NAME } from '../constants';
import { RedirectionAfterLogin } from '../components/common';

// const loggedIn = false;
// eslint-disable-next-line react/prop-types
const PublicRoute = ({ component: Component, restricted, ...rest }) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) => {
      const url = new URL(window.location.href);
      /* reactivate return path need not have auth token, when redirected from pricing checkout page */
      if (
        getSessionVariable(AUTH_TOKEN_NAME) &&
        url.pathname.split('/')[2] !== 'reactivate-return'
      ) {
        return <RedirectionAfterLogin />;
      }
      return <Component {...props} />;
    }}
  />
);

export default PublicRoute;
