import PropTypes from 'prop-types';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ToggleSwitch } from '../common';

const OvertimeSettings = ({
  OverTimeSettings,
  OTweeklyLimit,
  OTpayRate,
  handleUpdate,
  handleOTtoggle,
}) => (
  <Formik
    initialValues={{
      OverTimeSettings,
      WeeklyLimit: OTweeklyLimit,
      PayRate: OTpayRate,
    }}
    enableReinitialize
    validationSchema={Yup.object({
      // autoCheckOut: Yup.boolean(),
      WeeklyLimit: Yup.number().when('OverTimeSettings', {
        is: true,
        then: Yup.number()
          .typeError('Weekly limit must be greater than or equal to 40')
          .positive()
          .required('Please enter your weekly limit in hours')
          .test(
            'maxDigits',
            'Weekly limit must have 2 digits value',
            (WeeklyLimit) => String(WeeklyLimit).length === 2
          ),
      }),
      PayRate: Yup.number().when('OverTimeSettings', {
        is: true,
        then: Yup.number()
          .typeError('Please enter your over time pay rate multiplication')
          .positive()
          .required('Please enter your over time pay rate multiplication')
          .test(
            'maxDigitsx',
            'Pay rate must have 2 digits value',
            (PayRate) => String(PayRate).length <= 3
          ),
      }),
    })}
    onSubmit={(values) => {
      if (values.OverTimeSettings === false) {
        values.OTWeeklyLimit1 = '';
      }

      handleUpdate(values);
    }}
  >
    {(formikprops) => (
      <div className="col-12 col-md-12">
        <Form onSubmit={formikprops.handleSubmit} className="needs-validation">
          <Row>
            <Col>
              <h4 className="font-weight-base mb-1">Overtime</h4>
              <small className="text-muted">
                By enabling this feature lets you track the overtime hours on
                weekly basis, display them on separate report and calculate the
                overtime pay. Overtime calculation is based on team member's
                account wise hourly rate.
              </small>
            </Col>
            <Col className="col-auto">
              <ToggleSwitch
                id="OverTimeSettings"
                name="OverTimeSettings"
                handleChangeforChecked={handleOTtoggle}
                checked={OverTimeSettings}
              />
            </Col>
          </Row>
          <br />
          {formikprops.values.OverTimeSettings && (
            <div className=" ">
              <Row>
                <Col className="col-auto pt-2">
                  <small>Weekly overtime after</small>
                </Col>
                <Col className="px-0 col-auto">
                  <div className="form-group mb-2">
                    <Field
                      type="text"
                      className="form-control form-control-sm"
                      style={{
                        maxWidth: '50px',
                        marginTop: '6px',
                        marginLeft: '32px',
                      }}
                      name="WeeklyLimit"
                    />
                  </div>
                </Col>
                <Col>
                  <span className="pt-2 d-inline-block">
                    {' '}
                    <small> Hours</small>
                  </span>
                </Col>

                <ErrorMessage
                  name="WeeklyLimit"
                  render={(msg) => <small className="text-danger">{msg}</small>}
                />
              </Row>
              <Row>
                <Col className="col-auto pt-2">
                  <small>Overtime pay rate multiplier</small>
                </Col>
                <Col className="px-0 col-auto">
                  <div className="form-group mb-2">
                    <Field
                      type="text"
                      className="form-control form-control-sm"
                      style={{ maxWidth: '50px', marginTop: '6px' }}
                      name="PayRate"
                    />
                  </div>
                </Col>
                <Col>
                  <span className="pt-2 d-inline-block">
                    {' '}
                    <small>
                      {' '}
                      <span className="text-muted">X</span> Members's hourly
                      rate
                    </small>
                  </span>
                </Col>

                <ErrorMessage
                  name="PayRate"
                  render={(msg) => <small className="text-danger">{msg}</small>}
                />
              </Row>

              <br />
              <Row>
                <Col md="auto">
                  <Button size="sm" type="submit" style={{ height: '26px' }}>
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </Form>
      </div>
    )}
  </Formik>
);
OvertimeSettings.propTypes = {
  OverTimeSettings: PropTypes.bool,
  OTweeklyLimit: PropTypes.any,
  OTpayRate: PropTypes.any,
  handleUpdate: PropTypes.func,
  handleOTtoggle: PropTypes.func,
};
export default OvertimeSettings;
