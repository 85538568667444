import moment from 'moment';
import XLSX from 'sheetjs-style';
import { sToHMSColonSeparated } from '../../helpers';

const ApprovalTimesheetExcel = (timesheetDetails) => {
  let ws = [];
  let user = '';
  const taskRowColor = 'FF555555';
  const subHeadBgColor = 'C9C0BB';
  const wb = XLSX.utils.book_new();
  const excelRows = [];
  let subheading = '';
  const weeklyStatus = (status) => {
    switch (status) {
      case 'submit':
        return 'Submitted';
      case 'approve':
        return 'Approved';
      case 'reject':
        return 'Rejected';
      case 'withdraw':
        return 'Withdrawn';
      default:
        return 'Not Submitted';
    }
  };
  // starting row
  const dataStartingRow = 3;
  let rowCount = dataStartingRow;
  const statusHead = [dataStartingRow];
  const taskRows = [];
  let colCount = 0;
  timesheetDetails.forEach((memberData) => {
    const weekDates = memberData.data[0].wsDetails;
    const weekDayOne = moment(weekDates[0].date).format(` ddd, MMM DD`);
    const weekDayTwo = moment(weekDates[1].date).format(` ddd, MMM DD`);
    const weekDayThree = moment(weekDates[2].date).format(` ddd, MMM DD`);
    const weekDayFour = moment(weekDates[3].date).format(` ddd, MMM DD`);
    const weekDayFive = moment(weekDates[4].date).format(` ddd, MMM DD`);
    const weekDaySix = moment(weekDates[5].date).format(` ddd, MMM DD`);
    const weekDaySeven = moment(weekDates[6].date).format(` ddd, MMM DD`);
    if (rowCount !== dataStartingRow) {
      excelRows.push({});
      rowCount += 1;
      statusHead.push(rowCount);
    }

    if (memberData.user) {
      excelRows.push({
        name: `Week: ${weekDayOne}-${weekDaySeven}`,
        project: '',
        weekDayOne: '',
        weekDayTwo: '',
        weekDayThree: '',
        weekDayFour: `Status: ${weeklyStatus(memberData.status)}`,
        weekDayFive: '',
        weekDaySix: '',
        weekDaySeven: '',
        total: '',
      });
      subheading = {
        name: 'Team member',
        project: 'Project',
        weekDayOne,
        weekDayTwo,
        weekDayThree,
        weekDayFour,
        weekDayFive,
        weekDaySix,
        weekDaySeven,
        total: 'Total',
      };
      colCount = 10;
    } else {
      excelRows.push({
        project: `Week: ${weekDayOne}-${weekDaySeven}`,
        weekDayOne: '',
        weekDayTwo: '',
        weekDayThree: '',
        weekDayFour: '',
        weekDayFive: `Status: ${weeklyStatus(memberData.status)}`,
        weekDaySix: '',
        weekDaySeven: '',
        total: '',
      });
      subheading = {
        project: 'Project',
        weekDayOne,
        weekDayTwo,
        weekDayThree,
        weekDayFour,
        weekDayFive,
        weekDaySix,
        weekDaySeven,
        total: 'Total',
      };
      colCount = 9;
    }
    rowCount += 1;
    excelRows.push(subheading);
    memberData.data.forEach((project) => {
      // For Account Owner Timesheet excel
      if (memberData.user) {
        user = {
          name: memberData.user.firstName,
          project: project.title,
          weekDayOne:
            project.wsDetails[0].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[0].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
          weekDayTwo:
            project.wsDetails[1].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[1].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),

          weekDayThree:
            project.wsDetails[2].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[2].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
          weekDayFour:
            project.wsDetails[3].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[3].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
          weekDayFive:
            project.wsDetails[4].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[4].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
          weekDaySix:
            project.wsDetails[5].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[5].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
          weekDaySeven:
            project.wsDetails[6].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[6].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
          total: sToHMSColonSeparated(
            project.totalWorkSessionSeconds,
            'hh:mm:ss'
          ),
        };
        rowCount += 1;
        excelRows.push(user);
        if ('task' in project) {
          project.task.forEach((task) => {
            if ('task' in task) {
              user = {
                name: '',
                project: `    ${task.task}`,
                weekDayOne:
                  task.wsDetails[0].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[0].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),
                weekDayTwo:
                  task.wsDetails[1].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[1].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),

                weekDayThree:
                  task.wsDetails[2].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[2].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),
                weekDayFour:
                  task.wsDetails[3].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[3].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),
                weekDayFive:
                  task.wsDetails[4].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[4].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),
                weekDaySix:
                  task.wsDetails[5].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[5].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),
                weekDaySeven:
                  task.wsDetails[6].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[6].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),
                total: sToHMSColonSeparated(
                  task.wsDetails[0].totalWorkSessionSeconds +
                    task.wsDetails[1].totalWorkSessionSeconds +
                    task.wsDetails[2].totalWorkSessionSeconds +
                    task.wsDetails[3].totalWorkSessionSeconds +
                    task.wsDetails[4].totalWorkSessionSeconds +
                    task.wsDetails[5].totalWorkSessionSeconds +
                    task.wsDetails[6].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
              };
              rowCount += 1;
              taskRows.push(rowCount);
              excelRows.push(user);
            }
          });
        }
        // For teammember timesheet excel
      } else {
        user = {
          project: project.title,
          weekDayOne:
            project.wsDetails[0].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[0].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
          weekDayTwo:
            project.wsDetails[1].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[1].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),

          weekDayThree:
            project.wsDetails[2].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[2].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
          weekDayFour:
            project.wsDetails[3].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[3].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
          weekDayFive:
            project.wsDetails[4].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[4].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
          weekDaySix:
            project.wsDetails[5].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[5].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
          weekDaySeven:
            project.wsDetails[6].totalWorkSessionSeconds === 0
              ? '-'
              : sToHMSColonSeparated(
                  project.wsDetails[6].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
          total: sToHMSColonSeparated(
            project.totalWorkSessionSeconds,
            'hh:mm:ss'
          ),
        };
        rowCount += 1;
        excelRows.push(user);
        if ('task' in project) {
          project.task.forEach((task) => {
            if ('task' in task) {
              user = {
                project: `    ${task.task}`,
                weekDayOne:
                  task.wsDetails[0].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[0].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),
                weekDayTwo:
                  task.wsDetails[1].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[1].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),

                weekDayThree:
                  task.wsDetails[2].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[2].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),
                weekDayFour:
                  task.wsDetails[3].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[3].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),
                weekDayFive:
                  task.wsDetails[4].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[4].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),
                weekDaySix:
                  task.wsDetails[5].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[5].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),
                weekDaySeven:
                  task.wsDetails[6].totalWorkSessionSeconds === 0
                    ? '-'
                    : sToHMSColonSeparated(
                        task.wsDetails[6].totalWorkSessionSeconds,
                        'hh:mm:ss'
                      ),
                total: sToHMSColonSeparated(
                  task.wsDetails[0].totalWorkSessionSeconds +
                    task.wsDetails[1].totalWorkSessionSeconds +
                    task.wsDetails[2].totalWorkSessionSeconds +
                    task.wsDetails[3].totalWorkSessionSeconds +
                    task.wsDetails[4].totalWorkSessionSeconds +
                    task.wsDetails[5].totalWorkSessionSeconds +
                    task.wsDetails[6].totalWorkSessionSeconds,
                  'hh:mm:ss'
                ),
              };
              rowCount += 1;
              taskRows.push(rowCount);
              excelRows.push(user);
            }
          });
        }
      }
    });

    ws = XLSX.utils.json_to_sheet(excelRows, {
      skipHeader: true,
      origin: 'A3',
    });
    const MainHeading = ['Timesheet', '', '', '', '', '', '', '', '', ''];
    ws['!merges'] = [
      {
        s: { r: 0, c: 0 },
        e: { r: 0, c: colCount - 1 },
      },
    ];
    XLSX.utils.sheet_add_aoa(ws, [MainHeading], {
      skipHeader: true,
      origin: 'A1',
    });
    rowCount += 1;
  });
  // Add styling to the cell
  statusHead.forEach((rowNo) => {
    ws[`A${rowNo}`].s = {
      font: { bold: true },
    };
    ws[`F${rowNo}`].s = {
      font: { bold: true },
      alignment: { horizontal: 'right' },
    };
    ws[`G${rowNo}`].s = {
      font: { bold: true },
      alignment: { horizontal: 'right' },
    };
    ws['!merges'].push({
      s: { r: rowNo - 1, c: 0 },
      e: { r: rowNo - 1, c: 4 },
    });
    ws['!merges'].push({
      s: { r: rowNo - 1, c: colCount - (colCount === 10) ? 5 : 4 },
      e: { r: rowNo - 1, c: colCount - 1 },
    });
    ws[`A${rowNo + 1}`].s = {
      fill: {
        fgColor: { rgb: subHeadBgColor },
      },
    };
    ws[`B${rowNo + 1}`].s = {
      fill: {
        fgColor: { rgb: subHeadBgColor },
      },
    };
    ws[`C${rowNo + 1}`].s = {
      fill: {
        fgColor: { rgb: subHeadBgColor },
      },
    };
    ws[`D${rowNo + 1}`].s = {
      fill: {
        fgColor: { rgb: subHeadBgColor },
      },
    };
    ws[`E${rowNo + 1}`].s = {
      fill: {
        fgColor: { rgb: subHeadBgColor },
      },
    };
    ws[`F${rowNo + 1}`].s = {
      fill: {
        fgColor: { rgb: subHeadBgColor },
      },
    };
    ws[`G${rowNo + 1}`].s = {
      fill: {
        fgColor: { rgb: subHeadBgColor },
      },
    };
    ws[`H${rowNo + 1}`].s = {
      fill: {
        fgColor: { rgb: subHeadBgColor },
      },
    };
    ws[`I${rowNo + 1}`].s = {
      fill: {
        fgColor: { rgb: subHeadBgColor },
      },
    };
    if (colCount === 10) {
      ws[`J${rowNo + 1}`].s = {
        fill: {
          fgColor: { rgb: 'C9C0BB' },
        },
      };
    }
  });
  taskRows.forEach((rowNo) => {
    ws[`A${rowNo}`].s = {
      font: { color: { rgb: taskRowColor } },
    };
    ws[`B${rowNo}`].s = {
      font: { color: { rgb: taskRowColor } },
    };
    ws[`C${rowNo}`].s = {
      font: { color: { rgb: taskRowColor } },
    };
    ws[`D${rowNo}`].s = {
      font: { color: { rgb: taskRowColor } },
    };
    ws[`E${rowNo}`].s = {
      font: { color: { rgb: taskRowColor } },
    };
    ws[`F${rowNo}`].s = {
      font: { color: { rgb: taskRowColor } },
    };
    ws[`G${rowNo}`].s = {
      font: { color: { rgb: taskRowColor } },
    };
    ws[`H${rowNo}`].s = {
      font: { color: { rgb: taskRowColor } },
    };
    ws[`I${rowNo}`].s = {
      font: { color: { rgb: taskRowColor } },
    };
  });
  ws.A1.s = { font: { bold: true }, alignment: { horizontal: 'center' } };
  ws.F1.s = {
    font: { bold: true },
    alignment: { horizontal: 'center' },
    defaultColWidth: '100px',
    fill: {
      fgColor: { rgb: 'e3c2bb' }, // add background color
    },
  };

  XLSX.utils.book_append_sheet(wb, ws, 'mySheet');
  XLSX.writeFile(wb, 'timesheet_report.xlsx');
};

export default ApprovalTimesheetExcel;
