import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ArchiveLink = ({ handleShow }) => (
  <Dropdown.Item onClick={handleShow}>Archive</Dropdown.Item>
);

ArchiveLink.propTypes = {
  handleShow: PropTypes.func,
};

export default ArchiveLink;
