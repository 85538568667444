import React, { Component } from 'react';
import { Card, Tab, Nav, Row, Col, Form, Button } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from './DashboardLayout';
import {
  projectService,
  activityService,
  tasksService,
  teamService,
} from '../services';
import Loading from '../components/Loader';
import { TeamActivityList } from '../components/activity';
import {
  ProjectListDropdown,
  PercentageRangeListDropdown,
  DateRangePickerField,
  WorkSessionTypeDropdown,
} from '../components/common';
import {
  DATE_RANGE_OPTIONS,
  STATUS_CHECK_DELAY,
  PAGINATION_LIMIT,
  ACTIVITY_DATE_RANGE,
} from '../constants';
import { fetchOnlineMembers } from '../actions/team';
import { getUserData } from '../helpers';
import { trackJune } from '../utilities/analytics';

class TeamDateRangeActivity extends Component {
  userData = JSON.parse(localStorage.getItem('USER_DATA'));

  state = {
    isLoading: true,
    teamGroupList: [],
    projectsList: [],
    tasksList: [],
    startDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    activityList: {},
    isFetchingActivityData: false,
    isUpdatingProjectsList: false,
    isUpdatingTasksList: false,
    page: 1,
    activityFormData: {},
    numberOfDaysSelected: 1,
    daysLimit: ACTIVITY_DATE_RANGE.DAYS,
  };

  pageHeading = {
    preTitle: 'Activity',
    title: 'Team Activity',
  };

  teamActivityFormRef = React.createRef();

  componentDidMount() {
    this.getTeamGroupList();
    this.getProjectData();
    this.getTaskData({
      teamOwnerId: localStorage.getItem('teamOwnerId'),
    });
    const { startDate, endDate, page } = this.state;
    this.handleGenerateData({
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      fromDate: startDate,
      toDate: endDate,
      team: 'all',
      activity: 'all',
      project: 'all',
      task: 'all',
      workSessionType: 'all',
      page,
      limit: PAGINATION_LIMIT,
    });
    const { getOnlineMembers } = this.props;
    // Fetch online members list onMount
    getOnlineMembers();
    // Check for online members every 2 minutes
    this.statusTimer = setInterval(
      () => getOnlineMembers(),
      STATUS_CHECK_DELAY
    );
    // track june
    trackJune(null, this.pageHeading.title);
  }

  componentWillUnmount() {
    // Clear interval on un mount
    clearInterval(this.statusTimer);
  }

  handleDateSelect = (event, picker) => {
    this.setState((state) => ({
      ...state,
      startDate: moment(picker.startDate).format('YYYY-MM-DD'),
      endDate: moment(picker.endDate).format('YYYY-MM-DD'),
    }));
  };

  handleDateRangeValidation = (start, end) => {
    const { daysLimit } = this.state;
    const daysSelected = Math.abs(moment(start).diff(moment(end), 'd'));
    if (daysSelected > daysLimit) {
      toast.error(`${ACTIVITY_DATE_RANGE.LIMIT} ${ACTIVITY_DATE_RANGE.ERROR}`);
    }
    this.setState((state) => ({
      ...state,
      numberOfDaysSelected: daysSelected,
    }));
  };

  getTeamGroupList = () => {
    teamService
      .get_team_group_list({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
      })
      .then((response) => {
        if (response.data) {
          this.setState(() => ({
            teamGroupList: response.data,
          }));
        }
      });
  };

  getProjectData = (data) => {
    this.setState((state) => ({
      ...state,
      isUpdatingProjectsList: true,
    }));

    const params = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      summaryList: true,
      status: 'active',
    };

    if (data && data.members) {
      params.filter = JSON.stringify({
        teamMembers: [...data.members],
      });
    }

    projectService
      .get_projects_data(params)
      .then((response) => {
        const {
          data: { projects },
        } = response;
        this.setState((state) => ({
          ...state,
          isLoading: false,
          projectsList: [...projects],
          isUpdatingProjectsList: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isUpdatingProjectsList: false,
        }));
      });
  };

  getTaskData = (data) => {
    this.setState((state) => ({
      ...state,
      isUpdatingTasksList: true,
    }));
    tasksService
      .get_tasks_list(data)
      .then((result) => {
        const { tasks } = result.data;
        this.setState((state) => ({
          ...state,
          isLoading: false,
          tasksList: [...tasks],
          isUpdatingTasksList: false,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
          isUpdatingTasksList: false,
        }));
      });
  };

  // Update project list on changing team members selection
  handleTeamMemberChange = () => {
    const { teamGroupList } = this.state;
    const { team, project } = this.teamActivityFormRef.current.values;
    let selectedGroupMembers = [];
    let allSelectedMembers = [];
    // if team is selected create array of selected group members
    if (team !== 'all' && team !== '') {
      const selectedGroup = teamGroupList.find((tGroup) => tGroup._id === team);
      selectedGroupMembers =
        selectedGroup.members && selectedGroup.members.length > 0
          ? selectedGroup.members.map((sMember) => sMember._id)
          : [];
    }
    if (team !== 'all') {
      allSelectedMembers = [...selectedGroupMembers];
    }

    // Update projects list based on selected members
    this.getProjectData({ members: [...allSelectedMembers] });

    const taskParams = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      members: [...allSelectedMembers],
      projects: [project],
    };
    if (team === 'all') {
      delete taskParams.members;
    }
    if (project === 'all') {
      delete taskParams.projects;
    }
    this.getTaskData(taskParams);
  };

  handleProjectChange = (event) => {
    const { teamGroupList } = this.state;
    const { team, project } = this.teamActivityFormRef.current.values;
    let selectedGroupMembers = [];
    let allSelectedMembers = [];

    // if team is selected create array of selected group members
    if (team !== 'all' && team !== '') {
      const selectedGroup = teamGroupList.find((tGroup) => tGroup._id === team);
      selectedGroupMembers =
        selectedGroup.members && selectedGroup.members.length > 0
          ? selectedGroup.members.map((sMember) => sMember._id)
          : [];
    }

    if (team !== 'all') {
      allSelectedMembers = [...selectedGroupMembers];
    }

    const taskParams = {
      teamOwnerId: localStorage.getItem('teamOwnerId'),
      projects: [event.target.value],
      members: [...allSelectedMembers],
    };
    if (project === 'all') {
      delete taskParams.projects;
    }
    if (team === 'all') {
      delete taskParams.members;
    }
    this.getTaskData(taskParams);
  };

  setCurrentPage = (pageN) => {
    this.setState((state) => ({
      ...state,
      page: pageN,
    }));
  };

  handleGenerateData = (formdata) => {
    this.setState((state) => ({
      ...state,
      isFetchingActivityData: true,
    }));
    const { startDate, endDate, page } = this.state;

    const params = {
      teamOwnerId: formdata.teamOwnerId,
      fromDate: startDate.concat(' 00:00:00'),
      toDate: endDate.concat(' 23:59:59'),
      activityPercentageRange: formdata.activity,
      teamId: formdata.team,
      projectId: formdata.project,
      taskId: formdata.task,
      workSessionType: formdata.workSessionType,
      page: formdata.page || page,
      limit: formdata.limit || PAGINATION_LIMIT,
    };
    const locationData = new URLSearchParams(window.location.search);
    const teamMemberId = locationData.get('teamMember');
    if (teamMemberId) {
      params.teamMemberId = teamMemberId;
    }
    if (formdata.activity === 'all') {
      delete params.activityPercentageRange;
    }
    if (formdata.team === 'all') {
      delete params.teamId;
    }
    if (formdata.project === 'all') {
      delete params.projectId;
    }
    if (formdata.task === 'all') {
      delete params.taskId;
    }
    if (formdata.workSessionType === 'all') {
      delete params.workSessionType;
    }

    activityService
      .get_team_activity_data(params)
      .then((response) => {
        trackJune('view activity report (team)');
        if (response.data.metadata.total) {
          const activityData = response.data;
          this.setState((state) => ({
            ...state,
            isFetchingActivityData: false,
            activityList: activityData,
            activityFormData: formdata,
            page: activityData.metadata.page,
          }));
        } else {
          this.setState((state) => ({
            ...state,
            isFetchingActivityData: false,
            activityList: {},
            page: 1,
          }));
        }
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isFetchingActivityData: false,
        }));
      });
  };

  render() {
    const {
      projectsList,
      tasksList,
      startDate,
      endDate,
      isFetchingActivityData,
      isLoading,
      activityList,
      isUpdatingProjectsList,
      isUpdatingTasksList,
      activityFormData,
      numberOfDaysSelected,
      daysLimit,
      page,
      teamGroupList,
    } = this.state;
    const {
      history,
      location: { state: fromState },
    } = this.props;
    // Team owner ID
    const teamOwnerId = localStorage.getItem('teamOwnerId');
    const timeZone = getUserData('timeZoneFormatted');
    const { isMobileTracking } = getUserData('defaultTeam');
    // Top header bredCrumb links
    const bredCrumbLinks =
      fromState && fromState.bredCrumbs ? fromState.bredCrumbs : [];
    return (
      <Layout pageHeading={this.pageHeading} bredCrumbLinks={bredCrumbLinks}>
        {isLoading && <Loading contentAreaOnly />}
        <Card>
          <Card.Header>
            <Tab.Container id="TimeSheetPreview" defaultActiveKey="date-range">
              <Row className="align-items-center my-3">
                <Col xs="auto">
                  <div className="card-title h4 m-0">View by</div>
                </Col>
                <Col>
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="day"
                        className="py-1 px-3"
                        onClick={() => history.push('/activity/team/day')}
                      >
                        Day
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="date-range" className="py-1 px-3">
                        Date range
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col xs="auto" className="ml-auto">
                  {timeZone}
                </Col>
              </Row>
            </Tab.Container>
          </Card.Header>
          <Card.Body>
            <Formik
              initialValues={{
                teamOwnerId,
                fromDate: startDate,
                toDate: endDate,
                team: 'all',
                activity: 'all',
                project: 'all',
                task: 'all',
                workSessionType: 'all',
                page: 1,
                limit: PAGINATION_LIMIT,
              }}
              onSubmit={(values) => {
                this.handleGenerateData(values);
              }}
              innerRef={this.teamActivityFormRef}
            >
              {(formikprops) => (
                <Form onSubmit={formikprops.handleSubmit}>
                  <Row>
                    <Col md={6} lg={3}>
                      <Form.Group controlId="team">
                        <Form.Label>Team Group</Form.Label>
                        <Field
                          className="form-select"
                          as="select"
                          name="team"
                          onChange={(e) => {
                            formikprops.handleChange(e);
                            setTimeout(() => {
                              this.handleTeamMemberChange();
                            }, 0);
                          }}
                        >
                          <option value="all">All</option>
                          {teamGroupList &&
                            teamGroupList.length > 0 &&
                            teamGroupList.map((teamGroup, index) => (
                              <option
                                key={`teamGroup${index}`}
                                value={teamGroup._id}
                              >
                                {teamGroup.name}
                              </option>
                            ))}
                        </Field>
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={3}>
                      <ProjectListDropdown
                        projectsList={projectsList}
                        isUpdatingProjectsList={isUpdatingProjectsList}
                        handleChange={formikprops.handleChange}
                        handleProjectChange={this.handleProjectChange}
                        isDependedField
                      />
                    </Col>
                    <Col md={6} lg={3}>
                      <Form.Group controlId="task">
                        <Form.Label>Task</Form.Label>
                        <Field
                          className="form-select"
                          as="select"
                          name="task"
                          disabled={
                            isUpdatingTasksList || !tasksList.length > 0
                          }
                        >
                          <option value="all">All</option>
                          {tasksList &&
                            tasksList.length > 0 &&
                            tasksList.map((item, index) => (
                              <option
                                key={`${index}-${item._id}`}
                                value={item._id}
                              >
                                {item.task}
                              </option>
                            ))}
                        </Field>
                      </Form.Group>
                    </Col>
                    <Col md={6} lg={3}>
                      <DateRangePickerField
                        handleDateSelect={this.handleDateSelect}
                        startFrom="last 7 days"
                        showRangeOptions
                        preDefinedRanges={DATE_RANGE_OPTIONS}
                        handleDateRangeValidation={
                          this.handleDateRangeValidation
                        }
                        isValidate
                        numberOfDaysSelected={numberOfDaysSelected}
                        daysLimit={daysLimit}
                      />
                    </Col>
                    <Col md={6} lg={3}>
                      <PercentageRangeListDropdown />
                    </Col>

                    {isMobileTracking && (
                      <Col md={6} lg={3}>
                        <WorkSessionTypeDropdown />
                      </Col>
                    )}
                    <Col md={6} lg={3}>
                      <Form.Group>
                        <Form.Label>&nbsp;</Form.Label>
                        <div>
                          <Button
                            variant="primary"
                            type="Submit"
                            disabled={isUpdatingProjectsList}
                          >
                            Generate
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Card.Body>
          {isFetchingActivityData && <Loading contentAreaOnly />}
          {activityList && (
            <TeamActivityList
              activityList={activityList}
              getActivityData={this.handleGenerateData}
              activityFormData={activityFormData}
              pageNumber={page}
              setCurrentPage={this.setCurrentPage}
            />
          )}
        </Card>
      </Layout>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
  getOnlineMembers: fetchOnlineMembers,
});

TeamDateRangeActivity.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  getOnlineMembers: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(TeamDateRangeActivity);
