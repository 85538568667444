import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  UPDATE_FAILURE_ERROR,
  LOCAL_STORAGE_USER_DATA,
  API_FAILURE_ERROR,
  REACTIVATE_URL,
} from '../../constants';
import { settingsService, userService } from '../../services';
import { setAccountStatus, setCurrentPlan } from '../../actions/billing';
import Loader from '../Loader';
import { updateLocalStorage } from '../../helpers';
import { setSubscription } from '../../actions/user';

function BillingModal({ modalProps }) {
  const checkModalType = () => (modalProps.type ? 'static' : 'true');
  const [show, setShow] = useState(modalProps.showClose);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isMemSuccess, setIsMemSuccess] = useState(false);
  const [staticType] = useState(
    checkModalType()
  ); /* to prevent closing of modal */
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = () => {
    /* onclick of update card */
    if (modalProps.type === 'expired' || modalProps.type === 'due') {
      setIsSubmitting(true);
      const data = {
        ownerId: localStorage.getItem('teamOwnerId'),
        pageType: 1,
        planId: 0,
        memberCount: 0,
        planType: 0,
      };
      settingsService
        .get_hosted_page_url(data)
        .then((response) => {
          if ('data' in response) {
            window.location.href = response.data.url;
          }
          setIsSubmitting(false);
        })
        .catch((errResp) => {
          toast.error(API_FAILURE_ERROR);
          setIsSubmitting(false);
        });
    } else if (modalProps.type === 'ismember') {
      /* onclick of continue as a member */
      const data = {
        continueAsMember: 1,
        teamOwnerId: localStorage.getItem('teamOwnerId'),
      };
      setIsSubmitting(true);

      settingsService
        .user_update(data)
        .then((response) => {
          handleClose(true);

          /* get user details */
          userService.user_details().then((succResp) => {
            // Store user details
            localStorage.setItem(
              LOCAL_STORAGE_USER_DATA,
              JSON.stringify(succResp.data)
            );
            dispatch({ type: 'SET_ONBOARD_PROGRESS', payload: {} });
            dispatch(
              setCurrentPlan({
                planName: succResp.data.subscriptionDetails.currentPlan,
              })
            );
            dispatch(setSubscription({ ...succResp.data.subscriptionDetails }));
            setIsMemSuccess(true);
            dispatch(setAccountStatus({ accountStatus: null }));
          });
        })
        .catch((errResp) => {
          if ('message' in errResp) toast.error(errResp.message);
          else toast.error(UPDATE_FAILURE_ERROR);
          setIsSubmitting(false);
        });
    } else {
      /* onclick of cancel account */
      if (document.getElementById('cancelReason').value === '') {
        document.getElementById('confmsg').style.display = 'inline';
        return;
      }

      const data = {
        cancelAccount: 1,
        cancelReason: document.getElementById('cancelReason').value,
        teamOwnerId: localStorage.getItem('teamOwnerId'),
      };
      setIsSubmitting(true);
      settingsService
        .user_update(data)
        .then((response) => {
          handleClose(true);
          setIsSubmitting(false);
          if ('message' in response && response.status === 0) {
            toast.error(response.message);
          } else {
            setIsSuccess(true);

            dispatch(setAccountStatus({ accountStatus: 'cancel' }));
            updateLocalStorage({
              maxMembersAllowed: 0,
              userStatus: 'cancel',
              subscriptionDetails: {
                currentPlan: 'cancelled',
              },
            });
          }
        })
        .catch((errResp) => {
          if ('message' in errResp) toast.error(errResp.message);
          else toast.error(UPDATE_FAILURE_ERROR);
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      {isMemSuccess && <Redirect to="/team-selection" />}
      {isSuccess && <Redirect to="/settings/account-closed" />}
      {isSubmitting && <Loader contentAreaOnly />}
      {!modalProps.showClose && (
        <p className="text-center mb-4">
          <small className="text-muted">
            Don’t need HiveDesk anymore?
            <Button
              variant="link"
              onClick={handleShow}
              size="sm"
              style={{ padding: '0px 0px 2px 2px' }}
            >
              Cancel your account
            </Button>
          </small>
        </p>
      )}

      <Form>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          animation
          backdrop={staticType}
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalProps.title}</Modal.Title>
          </Modal.Header>
          {modalProps.content && (
            <Modal.Body className="br-newline">
              {modalProps.content}
              {modalProps.type && (
                <a
                  href="http://support.hivedesk.com/"
                  target="blank"
                  className="p-2"
                >
                  support
                </a>
              )}
            </Modal.Body>
          )}
          <Modal.Footer>
            {modalProps.showClose && (
              <div className="me-auto">
                <Button variant="link" onClick={handleClose}>
                  Close
                </Button>
              </div>
            )}
            {modalProps.type === 'ismember' && (
              <Button
                form="billing"
                type="button"
                variant={modalProps.actionVariant}
                onClick={handleSubmit}
                className="ml-auto"
              >
                {modalProps.secondaryActionText}
              </Button>
            )}

            <Button
              form="billing"
              type="button"
              variant={modalProps.actionVariant}
              onClick={() => {
                if (
                  (modalProps.type === 'ismember' ||
                    modalProps.type === 'trial') &&
                  modalProps.currentPlan === 'trial'
                ) {
                  history.push('/settings/billing');
                  handleClose();
                } else if (modalProps.type === 'paid') {
                  setIsSubmitting(true);
                  window.location.href = REACTIVATE_URL;
                } else {
                  handleSubmit();
                }
              }}
              className="ml-auto"
            >
              {modalProps.actionText}
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
}

BillingModal.propTypes = {
  modalProps: PropTypes.object,
};
export default BillingModal;
