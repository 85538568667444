import { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import PropTypes from 'prop-types';
import Loading from '../Loader';

function ReactivateMemberModal({ userId, handleStatusUpdate }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isLoading] = useState(false);

  return isLoading ? (
    <Loading contentAreaOnly />
  ) : (
    <>
      <Button
        variant="primary"
        size="sm"
        className="ml-auto me-2"
        onClick={handleShow}
      >
        Reactivate
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        size="md"
        centered
      >
        <Modal.Header>
          <Modal.Title className="m-0">Activate Team Member</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={handleClose}
          />
        </Modal.Header>
        <Formik
          initialValues={{
            activateReason: '',
          }}
          validationSchema={Yup.object({
            activateReason: Yup.string()
              .trim()
              .required('Please enter reason for activation'),
          })}
          onSubmit={(values) => {
            handleStatusUpdate(userId, '', values.activateReason);
            handleClose();
          }}
        >
          {(formikprops) => (
            <Form
              onSubmit={formikprops.handleSubmit}
              className="needs-validation"
            >
              <section>
                <div id="load_members">
                  <div className="modal-body">
                    <p className="text-muted text-sm">
                      Are you sure you want to activate this Team Member? This
                      member will be able to work in your projects! Continue?
                    </p>
                    <div className="form-group">
                      <Form.Label className="form-control-label">
                        Reason for activation
                      </Form.Label>
                      <Field
                        name="activateReason"
                        type="text"
                        className="form-control"
                        as={Form.Control}
                        isInvalid={
                          !!formikprops.touched.activateReason &&
                          !!formikprops.errors.activateReason
                        }
                        isValid={
                          !!formikprops.touched.currentPassword &&
                          !formikprops.errors.activateReason
                        }
                      />
                      <ErrorMessage
                        name="activateReason"
                        render={(msg) => (
                          <small className="text-danger">{msg}</small>
                        )}
                      />
                    </div>
                  </div>
                  <div className="modal-footer d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-link"
                      data-dismiss="modal"
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="ml-auto me-2 btn btn-primary"
                      name="btnDeactivateMemberCard"
                    >
                      Activate
                    </button>
                  </div>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
ReactivateMemberModal.propTypes = {
  handleStatusUpdate: PropTypes.func,
  userId: PropTypes.string,
};
export default ReactivateMemberModal;
