import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import PropTypes from 'prop-types';
import monthSelect from 'flatpickr/dist/plugins/monthSelect';
import { getStartAndEndDates } from '../../helpers';
import 'flatpickr/dist/plugins/monthSelect/style.css';

const MonthYearPicker = ({ onDateChange }) => {
  const [date, setDate] = useState(new Date());

  const handleChange = (selectedDates) => {
    const selectedDate = selectedDates[0];
    setDate(selectedDate);
    const { startDate, endDate } = getStartAndEndDates(selectedDate);
    if (onDateChange) {
      onDateChange({ startDate, endDate });
    }
  };

  const options = {
    dateFormat: 'F, Y',
    defaultDate: date,
    onChange: handleChange,
    plugins: [
      // eslint-disable-next-line new-cap
      new monthSelect({
        shorthand: true,
        dateFormat: 'F Y',
        theme: 'light',
      }),
    ],
  };

  return (
    <Flatpickr
      value={date}
      options={options}
      className="form-control month-year-picker"
    />
  );
};

MonthYearPicker.propTypes = {
  onDateChange: PropTypes.func,
};

export default MonthYearPicker;
