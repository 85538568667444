import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { WithConfirmation } from '../common';

// Delete screenshot button
const DeleteScreenshotLink = ({ handleShow }) => (
  <Button variant="danger" size="sm" className=" ml-auto" onClick={handleShow}>
    Delete Screenshot
  </Button>
);
DeleteScreenshotLink.propTypes = {
  handleShow: PropTypes.func,
};

// Confirmation modal HOC and its props
const DeleteScreenshotButton = ({ handleDelete, selectedCount }) => {
  const DeleteProjectWithConfirmation = WithConfirmation(DeleteScreenshotLink);
  const screenshotWording = selectedCount > 1 ? 'screenshots' : 'screenshot';
  const DeleteModalProps = {
    title: `Delete ${screenshotWording}`,
    action: handleDelete,
    content: [
      `You are about to delete ${
        selectedCount > 1 ? 'these screenshots' : 'this screenshot'
      }.`,
      `${
        selectedCount > 1 ? 'These screenshots' : 'This screenshot'
      } will be permanently deleted. It cannot be restored at a later time!`,
    ],
    actionBtnText: 'Delete',
  };

  return <DeleteProjectWithConfirmation modalProps={DeleteModalProps} />;
};
DeleteScreenshotButton.propTypes = {
  handleDelete: PropTypes.func,
  selectedCount: PropTypes.number,
};

export default DeleteScreenshotButton;
