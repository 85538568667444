import PropTypes from 'prop-types';

const NoDataAlert = ({
  content,
  bgColor = 'bg-transparent',
  displayBorder = false,
}) => (
  <div
    className={`text-center text-muted my-4 p-3 rounded ${bgColor} ${
      displayBorder ? 'border' : ''
    }`}
  >
    {content}
  </div>
);

NoDataAlert.propTypes = {
  content: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  displayBorder: PropTypes.bool,
};

export default NoDataAlert;
