import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';
import Layout from './DashboardLayout';
import Loading from '../components/Loader';
import { projectService, activityService, teamService } from '../services';
import { TextCard, ProgressCard } from '../components/project/overview';
import { ProjectCard, TaskChart } from '../components/team/project';
import ProjectRecentActivityCard from '../components/project/ProjectRecentActivity';
import { PAGE_URLS } from '../constants';
import { convertSecondsToHoursAndMinutes } from '../helpers';

class MemberProjects extends Component {
  state = {
    memberData: [],
    projectData: [],
    activityData: [],
    isLoading: true,
    totalWorkSessionSeconds: 0,
  };

  componentDidMount() {
    const {
      match: {
        params: { id: memberId },
      },
    } = this.props;
    this.getMemberData(memberId);
  }

  getMemberData = (memberId) => {
    Promise.all([
      teamService.get_team_members_data({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        summaryList: false,
        // filter: JSON.stringify({
        //   teamMemberId: memberId,
        // }),
      }),
      projectService.get_projects_data({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        page: 1,
        summaryList: false,
        status: 'active',
        filter: JSON.stringify({
          teamMembers: [memberId],
        }),
      }),
      activityService.get_projectRecentActivity_data({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        teamMemberId: memberId,
      }),
      teamService.get_team_member_total_hours({
        teamOwnerId: localStorage.getItem('teamOwnerId'),
        teamMemberId: memberId,
      }),
    ])
      .then((responses) => {
        const [member, projects, recentActivity, wsTotal] = responses;
        this.setState((state) => ({
          ...state,
          isLoading: false,
          memberData: member.data.members,
          projectData: projects.data.projects,
          activityData: recentActivity.data,
          totalWorkSessionSeconds: wsTotal.data.totalWorkSessionSeconds,
        }));
      })
      .catch(() => {
        this.setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  render() {
    const {
      match: {
        params: { id: userId },
      },
    } = this.props;
    const {
      isLoading,
      memberData,
      projectData,
      activityData,
      totalWorkSessionSeconds,
    } = this.state;
    const teamMember =
      memberData && memberData.find((person) => userId === person.userId);
    const teamMemberName =
      teamMember && `${teamMember.firstName} ${teamMember.lastName || ''}`;

    // Heading on blue header on top
    const pageHeading = {
      preTitle: 'Team',
      title: teamMemberName,
    };

    // Breadcrumbs
    const bredCrumbLinks = [
      { name: 'Active Team Members', path: PAGE_URLS.TEAM.ACTIVE },
      { name: teamMemberName, path: '' },
    ];

    const isTasksAvailable =
      teamMember && teamMember.tasks && teamMember.tasks.length > 0;

    return (
      <Layout pageHeading={pageHeading} bredCrumbLinks={bredCrumbLinks}>
        {isLoading ? (
          <Loading contentAreaOnly />
        ) : (
          <>
            <Row className="mb-4">
              {teamMember && teamMember.workSession && (
                <>
                  <TextCard
                    cardTitle="Total Time"
                    md={isTasksAvailable ? 3 : 4}
                    cardDetails={
                      totalWorkSessionSeconds > 0
                        ? convertSecondsToHoursAndMinutes(
                            totalWorkSessionSeconds
                          )
                        : '0h 0m'
                    }
                    displayStyle="compact"
                  />
                  <TextCard
                    cardTitle="This week"
                    md={isTasksAvailable ? 3 : 4}
                    cardDetails={
                      teamMember.workSession.weekWorksessionSeconds > 0
                        ? convertSecondsToHoursAndMinutes(
                            teamMember.workSession.weekWorksessionSeconds
                          )
                        : '0h 0m'
                    }
                    displayStyle="compact"
                  />
                </>
              )}
              {teamMember && teamMember.tasks && (
                <TextCard
                  cardTitle="Total Tasks"
                  md={isTasksAvailable ? 3 : 4}
                  cardDetails={(teamMember.tasks
                    ? teamMember.tasks.reduce(
                        (total, task) => total + task.tasks,
                        0
                      )
                    : 0
                  ).toString()}
                  displayStyle="compact"
                />
              )}
              {isTasksAvailable && (
                <ProgressCard
                  md={3}
                  cardTitle="Tasks"
                  cardDetails={teamMember.tasks}
                  displayStyle="compact"
                />
              )}
            </Row>
            <Row>
              <div className="col-12 col-xl-8">
                <div className="table-responsive">
                  <ProjectCard
                    memberProjectData={projectData}
                    isLoading={isLoading}
                  />
                </div>
              </div>
              {teamMember &&
                teamMember.tasks &&
                teamMember.tasks.length > 0 && (
                  <div className="col-12 col-xl-4">
                    <TaskChart cardTitle="Tasks" taskData={teamMember.tasks} />
                  </div>
                )}
            </Row>
            <Row>
              <div className="col-12 col-xl-12">
                <ProjectRecentActivityCard
                  cardTitle="Recent activity"
                  recentActivity={activityData}
                  userId={userId}
                  memberName={teamMemberName}
                />
              </div>
            </Row>
          </>
        )}
      </Layout>
    );
  }
}
MemberProjects.propTypes = {
  match: PropTypes.object,
};
export default MemberProjects;
