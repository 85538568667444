const initialState = {
  isLoading: false,
  tasks: [],
  metadata: {},
  error: null,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCHING_D_TASKS':
      return {
        ...state,
        isLoading: true,
      };
    case 'UPDATING_D_TASKS':
      return {
        ...state,
        isUpdating: true,
      };
    case 'SET_D_TASKS':
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        tasks: action.payload.tasks,
        metadata: action.payload.metadata,
      };
    case 'FETCH_D_TASKS_ERROR':
      return {
        ...state,
        isLoading: false,
        isUpdating: false,
        error: action.error,
      };
    default:
      return state;
  }
};
