import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PAGE_URLS } from '../constants';

const OnboardingProgress = () => {
  const userDetails = useSelector((state) => state.userReducer);
  const completedSteps = Object.values(userDetails.progress).filter(
    (item) => item === true
  );
  const progressValue = 20 * completedSteps.length;
  const progressWidth = {
    width: `${progressValue}%`,
  };

  return (
    <li className="nav-item">
      <Link to="#" className="nav-link">
        <i className="mdi mdi-progress-clock" /> Progress
      </Link>
      <div className="nav-open">
        <ul className="nav nav-sm flex-column onboarding-progress">
          <li className="nav-item px-4 py-2">
            <div className="progress progress-sm">
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <div
                className="progress-bar"
                role="progressbar"
                style={progressWidth}
                aria-valuenow={progressWidth}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          </li>
          <li className="nav-item">
            <Link
              to="/projects/create"
              className="nav-link ps-4 text-nowrap nav-link-color-grey"
            >
              <i
                className={`${
                  userDetails.progress.project ? 'mdi mdi-check' : 'mdi'
                }`}
              />
              Create a project
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link ps-4 text-nowrap nav-link-color-grey"
              to={{
                pathname: PAGE_URLS.TEAM.ACTIVE,
                state: { action: 'showInviteModal' },
              }}
            >
              <i
                className={`${
                  userDetails.progress.teamMember ? 'mdi mdi-check' : 'mdi'
                }`}
              />
              Invite a team member
            </Link>
          </li>
          <li className="nav-item align-items-center">
            <Link
              className="nav-link ps-4 text-nowrap nav-link-color-grey"
              to={{
                pathname: PAGE_URLS.TRACK,
                state: { action: 'showInviteModal' },
              }}
            >
              <i
                className={`${
                  userDetails.progress.teamMember ? 'mdi mdi-check' : 'mdi'
                }`}
              />
              Track time
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/timesheet/detailed"
              className="nav-link ps-4 text-nowrap nav-link-color-grey"
            >
              <i
                className={`${
                  userDetails.progress.report ? 'mdi mdi-check' : 'mdi'
                }`}
              />
              View reports
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/settings/billing"
              className="nav-link ps-4 text-nowrap nav-link-color-grey"
            >
              <i
                className={`${
                  userDetails.progress.plan ? 'mdi mdi-check' : 'mdi'
                }`}
              />{' '}
              Choose a plan
            </Link>
          </li>
        </ul>
      </div>
    </li>
  );
};

export default OnboardingProgress;
