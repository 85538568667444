import { Form } from 'react-bootstrap';
import { Field } from 'formik';

const WorkSessionTypeDropdown = () => (
  <Form.Group controlId="worksessionType">
    <Form.Label>Worksession Type</Form.Label>
    <Field className="form-select" as="select" name="workSessionType">
      <option value="all">All</option>
      <option value="desktop">Desktop</option>
      <option value="mobile">Mobile</option>
      <option value="extension">Extension</option>
      <option value="web">Web</option>
    </Field>
  </Form.Group>
);

export default WorkSessionTypeDropdown;
