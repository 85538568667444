/* eslint-disable class-methods-use-this */
import {
  AUTH_TOKEN_NAME,
  AUTH_REFRESH_TOKEN_NAME,
  LOCAL_STORAGE_USER_DATA,
  LOCAL_STORAGE_USER_STATE,
  LOCAL_STORAGE_TEAM_OWNER,
  AUTH_EXPIRY,
  TOKEN_CREATED_AT,
  MASTER_LOGIN,
} from '../constants';

/**
 * Session storage class to set and get the session variables.
 * At the moment token and refresh_token are managed in session storage
 */
class Storage {
  constructor(token = null, refreshToken = null, masterLogin = false) {
    this.token = token;
    this.refreshToken = refreshToken;
    this.masterLogin = masterLogin;
  }

  /**
   * Setter for token received from API.
   * Additionally updating class variable as well.
   * @param {string} token token string
   */
  set authToken(token) {
    this.token = token;
    sessionStorage.setItem(AUTH_TOKEN_NAME, this.token);
  }

  set checkmasterLogin(masterLogin) {
    this.masterLogin = masterLogin;
    sessionStorage.setItem(MASTER_LOGIN, this.masterLogin);
  }

  /**
   * Setter for refresh token received from API.
   * Additionally updating class variable as well.
   * @param {string} refreshToken refresh token string
   */
  set authRefreshToken(refreshToken) {
    this.refreshToken = refreshToken;
    sessionStorage.setItem(AUTH_REFRESH_TOKEN_NAME, this.refreshToken);
  }

  set authExpiry(expiresIn) {
    this.expiresIn = expiresIn;
    sessionStorage.setItem(AUTH_EXPIRY, this.expiresIn);
  }

  set tokenCreatedAt(createdAt) {
    this.createdAt = createdAt;
    sessionStorage.setItem(TOKEN_CREATED_AT, this.createdAt);
  }

  /**
   * Getter for token variable that is set in sessionStorage.
   * @returns {string} token string from session storage
   */
  get authToken() {
    return sessionStorage.getItem(AUTH_TOKEN_NAME);
  }

  /**
   * Getter for refresh token variable that is set in sessionStorage.
   * @returns {string} refresh token string from session storage
   */
  get authRefreshToken() {
    return sessionStorage.getItem(AUTH_REFRESH_TOKEN_NAME);
  }

  get checkmasterLogin() {
    return sessionStorage.getItem(MASTER_LOGIN);
  }

  /**
   * Delete all session variables (token, refresh_token)
   * @returns {boolean} returns true after deleting
   */
  deleteTokens() {
    sessionStorage.clear();
    return true;
  }

  /**
   * Delete localStorage USER_DATA
   * @returns {boolean} returns true after deleting
   */
  deleteUserData() {
    localStorage.removeItem(LOCAL_STORAGE_USER_DATA);
    localStorage.removeItem(LOCAL_STORAGE_USER_STATE);
    localStorage.removeItem(LOCAL_STORAGE_TEAM_OWNER);
    return true;
  }

  /**
   * Store any data into session with key and value
   * @param {string} key storage key identifier
   * @param {any} value storage value. Will be JSON.stringified
   * @returns {string} value of the recent called key
   */
  store(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
    return this.retrieve(key);
  }

  /**
   * Returns value of the storage key
   * @param {string} key storage key identifier
   * @returns {string} value of the recent called key
   */
  retrieve(key) {
    return sessionStorage.getItem(key);
  }

  /**
   * Removes value a session storage by key
   * @param {string} key storage key identifier
   * @returns {string} value of the recent called key
   */
  remove(key) {
    return sessionStorage.removeItem(key);
  }

  /**
   * Clears entire session storage
   * @returns {string} value of the recent called key
   */
  clear() {
    return sessionStorage.clear();
  }
}

export const storage = new Storage();
