import PropTypes from 'prop-types';
import { Row, Col, Button, Card, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import {
  TeamMembersDropdown,
  DateRangePickerField,
  WorkSessionTypeDropdown,
} from '../../common';
import Loading from '../../Loader';
import { TextCard } from '.';
import { convertSecondsToHoursAndMinutes } from '../../../helpers';
import { DATE_RANGE_OPTIONS } from '../../../constants';

const SummaryCard = ({
  cardTitle,
  initialFormValue,
  handleGenerateData,
  teamMembersList,
  handleDateSelect,
  isFetchingSummaryData,
  summaryList,
  isMobileTracking,
}) => (
  <Card>
    <Card.Header>
      <h4 className="m-0">{cardTitle}</h4>
    </Card.Header>
    <Card.Body>
      <Formik
        initialValues={initialFormValue}
        onSubmit={(values) => {
          handleGenerateData(values);
        }}
      >
        {(formikprops) => (
          <Form onSubmit={formikprops.handleSubmit}>
            <Row>
              <Col md={6} lg={3}>
                <TeamMembersDropdown
                  teamMembersList={teamMembersList}
                  isAllOptionRequired
                  fieldName="teamMemberId"
                />
              </Col>
              <Col md={6} lg={3}>
                <DateRangePickerField
                  handleDateSelect={handleDateSelect}
                  startFrom="yesterday"
                  showRangeOptions
                  preDefinedRanges={DATE_RANGE_OPTIONS}
                />
              </Col>
              {isMobileTracking && (
                <Col md={6} lg={3}>
                  <WorkSessionTypeDropdown />
                </Col>
              )}
              <Col md={6} lg={3}>
                <Form.Group>
                  <Form.Label>&nbsp;</Form.Label>
                  <div>
                    <Button
                      variant="primary"
                      type="Submit"
                      disabled={isFetchingSummaryData}
                    >
                      Generate
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      {isFetchingSummaryData ? (
        <Loading contentAreaOnly />
      ) : (
        <Row>
          <TextCard
            cardTitle="Total Time"
            cardStyle="bg-light"
            cardDetails={
              summaryList.workSession.totalWorkSessionSeconds > 0
                ? `${convertSecondsToHoursAndMinutes(
                    summaryList.workSession.totalWorkSessionSeconds
                  )}`
                : 'Not available'
            }
            md={3}
          />
          <TextCard
            cardTitle="Total Tasks"
            cardStyle="bg-light"
            md={3}
            cardDetails={
              summaryList.totalTasks > 0 ? `${summaryList.totalTasks}` : `${0}`
            }
          />
          <TextCard
            cardTitle="Finished Tasks"
            cardStyle="bg-light"
            md={3}
            cardDetails={
              summaryList.tasks.filter((task) => task.status === 'complete')
                .length >= 1
                ? `${
                    summaryList.tasks.filter(
                      (task) => task.status === 'complete'
                    )[0].tasks
                  }`
                : `${0}`
            }
          />
          <TextCard
            cardTitle="Late Tasks"
            cardStyle="bg-light"
            md={3}
            cardDetails={
              summaryList.tasks.filter((task) => task.status === 'late')
                .length >= 1
                ? `${
                    summaryList.tasks.filter(
                      (task) => task.status === 'late'
                    )[0].tasks
                  }`
                : `${0}`
            }
          />
        </Row>
      )}
    </Card.Body>
  </Card>
);
SummaryCard.propTypes = {
  cardTitle: PropTypes.string,
  handleGenerateData: PropTypes.func,
  handleDateSelect: PropTypes.func,
  initialFormValue: PropTypes.object,
  teamMembersList: PropTypes.array,
  isFetchingSummaryData: PropTypes.bool,
  summaryList: PropTypes.object,
  isMobileTracking: PropTypes.bool,
};
export default SummaryCard;
